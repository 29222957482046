import React, { useEffect, useState } from "react"
import { useSpring, a, useChain, useSpringRef } from "@react-spring/web"
import IntroStates from "../../State/IntroStates"

function SvgComponent(props) {

  const useIntroStates = IntroStates()

  const springRef1 = useSpringRef()
  const springRef2 = useSpringRef()
  const springRef3 = useSpringRef()
  const springRef4 = useSpringRef()

  const [topic1, setTopic1] = useState(false)
  const [topic2, setTopic2] = useState(false)
  const [topic3, setTopic3] = useState(false)
  const [topic4, setTopic4] = useState(false)

  const spring1 = useSpring({ opacity: topic1 ? 1 : 0, ref: springRef1 })
  const spring2 = useSpring({ opacity: topic2 ? 1 : 0, ref: springRef2 })
  const spring3 = useSpring({ opacity: topic3 ? 1 : 0, ref: springRef3 })
  const spring4 = useSpring({ opacity: topic4 ? 1 : 0, ref: springRef4 })

  useChain([{ current: springRef1.current }, { current: springRef2.current }, { current: springRef3.current }, { current: springRef4.current }], [0.5, 1, 1.5, 2])

  useEffect(() => {

    //forzing react component to re-render
    setTopic1(useIntroStates.topicsViewed.inequalities)
    setTopic2(useIntroStates.topicsViewed.specialization)
    setTopic3(useIntroStates.topicsViewed.complexity)
    setTopic4(useIntroStates.topicsViewed.interdependencies)

  }, [topic1, topic2, topic3, topic4, useIntroStates])

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 51.9 52.21"
    >
      <defs>
        <linearGradient
          id="prefix__b2"
          x1={375.51}
          y1={-921.15}
          x2={417.59}
          y2={-921.15}
          gradientTransform="matrix(.92 -.38 -.38 -.92 -685.14 -660.77)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#2b3990" />
          <stop offset={0.23} stopColor="#2b518a" />
          <stop offset={0.71} stopColor="#2b8e7c" />
          <stop offset={1} stopColor="#2bb673" />
        </linearGradient>
        <linearGradient
          id="prefix__c2"
          x1={-333.87}
          y1={-3363.79}
          x2={-313.78}
          y2={-3339.61}
          gradientTransform="matrix(-.01 -1 -1 .01 -3314.54 -265.07)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#39b54a" />
          <stop offset={1} stopColor="#27aae1" />
        </linearGradient>
        <linearGradient
          id="prefix__d2"
          x1={-349.52}
          y1={-3357.54}
          x2={-323.26}
          y2={-3357.54}
          gradientTransform="matrix(-.01 -1 -1 .01 -3314.54 -265.07)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#d7df23" />
          <stop offset={1} stopColor="#00b4d4" />
        </linearGradient>
        <linearGradient
          id="prefix__e2"
          x1={-3195.88}
          y1={-3311.37}
          x2={-3163.23}
          y2={-3284.83}
          gradientTransform="matrix(-.98 -.18 -.18 .98 -3684.95 2703.33)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#27aae1" />
          <stop offset={0.22} stopColor="#3294d3" />
          <stop offset={0.66} stopColor="#4e5caf" />
          <stop offset={1} stopColor="#662d91" />
        </linearGradient>
        <linearGradient
          id="prefix__f2"
          x1={-3204.65}
          y1={-3306.77}
          x2={-3180.39}
          y2={-3306.77}
          gradientTransform="matrix(-.98 -.18 -.18 .98 -3684.95 2703.33)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#2b3990" />
          <stop offset={0.01} stopColor="#2a51a1" />
          <stop offset={0.02} stopColor="#288ecd" />
          <stop offset={0.03} stopColor="#27aae1" />
          <stop offset={1} stopColor="#92278f" />
        </linearGradient>
        <linearGradient
          id="prefix__g2"
          x1={-3756.84}
          y1={-637.76}
          x2={-3731.47}
          y2={-604.19}
          gradientTransform="matrix(-.41 .91 .91 .41 -953.11 3697.67)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#92278f" />
          <stop offset={0.26} stopColor="#e71888" />
          <stop offset={1} stopColor="#f7941d" />
        </linearGradient>
        <linearGradient
          id="prefix__h2"
          x1={-3770}
          y1={-633.8}
          x2={-3745.71}
          y2={-633.8}
          gradientTransform="matrix(-.41 .91 .91 .41 -953.11 3697.67)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#662d91" />
          <stop offset={1} stopColor="#ee728c" />
        </linearGradient>
        <linearGradient
          id="prefix__i2"
          x1={-1325.41}
          y1={557.62}
          x2={-1285.18}
          y2={569.96}
          gradientTransform="matrix(.79 .62 .62 -.79 695.86 1294.27)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#f9ed32" />
          <stop offset={1} stopColor="#f7941d" />
        </linearGradient>
        <linearGradient
          id="prefix__a2"
          x1={-1292.69}
          y1={558.05}
          x2={-1284.15}
          y2={582.28}
          gradientTransform="matrix(.79 .62 .62 -.79 695.86 1294.27)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#fbb040" />
          <stop offset={0.27} stopColor="#f89847" />
          <stop offset={0.84} stopColor="#f15b59" />
          <stop offset={1} stopColor="#ef495e" />
        </linearGradient>
        <linearGradient
          id="prefix__j2"
          x1={-1328.44}
          y1={563.41}
          x2={-1304.73}
          y2={560.82}
          xlinkHref="#prefix__a2"
        />
      </defs>
      <title>{"seerri-logo-min"}</title>
      <g data-name="Layer 2">
        <g data-name="Layer 1">
          <a.path
            style={spring4}
            d="M43.58 44.44c-1.9 1.51 0-1.24 1.4-6.07.94-3.11 1.82-10.8-2.55-16.63L39.5 23a1.42 1.42 0 00-.89 1.76c1.52 6.33.65 11-1.34 14.48l3.24 2.56s2.42 1.53-.41 3.82a24.76 24.76 0 01-11.2 6 21.8 21.8 0 0014.31-6 24 24 0 005.69-8 24.87 24.87 0 01-5.32 6.82z"
            fill="url(#prefix__b2)"
          />
          <a.path
            style={spring4}
            d="M28 8.11v5.24a1.42 1.42 0 001.3 1.48c6.63 1 10.71 3.65 13.14 6.91l4.67-1.91s2.53-1.35 3.13 2.24a24.82 24.82 0 01-1.34 15.54 22.36 22.36 0 001.47-19.32 24.22 24.22 0 00-4.16-7c-.89-.55-2-1.2-3.2-1.84C40.43 8.07 34 6 28 8.11z"
            fill="url(#prefix__c2)"
          />
          <a.path
            style={spring4}
            d="M50.23 22.07c-.6-3.59-3.13-2.24-3.13-2.24l-4.67 1.91c4.37 5.83 3.49 13.52 2.57 16.63-1.45 4.83-3.3 7.58-1.4 6.07a25.12 25.12 0 005.32-6.8 24.77 24.77 0 001.31-15.57z"
            fill="url(#prefix__d2)"
          />
          <a.path
            style={spring1}
            d="M14.28 6.11c-2 2.21-5.49 7.88-5 14.26l6 1.09a1.44 1.44 0 001.7-1c2.54-7.33 6.69-10.87 11-12.34v-3s-.31-2.85 3.24-2.07a24.71 24.71 0 0115 8.22c2.31 1.43 3.32 2.22 2.5.67C47.59 9.78 39.05-2.31 24.33.39a24.59 24.59 0 00-6.52 2.15c-.88.84-2.16 2.07-3.53 3.57z"
            fill="url(#prefix__e2)"
          />
          <a.path
            style={spring4}
            d="M31.17 3.06c-3.55-.78-3.24 2.07-3.24 2.07v3C34 6.05 40.37 8.13 43 9.46c1.24.64 2.31 1.29 3.2 1.84a24.74 24.74 0 00-15.03-8.24z"
            fill="url(#prefix__f2)"
          />
          <a.path
            style={spring2} 
            d="M16.21 42.45c.82-1.8 2.53-5.54 3-6.49a1.43 1.43 0 00-.56-1.9c-6.5-4.26-8.91-9.15-9.29-13.69L7.22 20s-2.86-.23-1.42-3.57a24.61 24.61 0 0112-13.86c1.28-1.22 1.71-1.69.31-1.35C15.74 1.77 1.94 7.1 1 22a23.86 23.86 0 001.28 9.66c.47.79 1 1.67 1.66 2.62 1.61 2.4 6.13 7.04 12.27 8.17z"
            fill="url(#prefix__g2)"
          />
          <a.path
            style={spring1}
            d="M5.75 16.41C4.31 19.75 7.17 20 7.17 20l2.14.39c-.52-6.38 3-12 5-14.26 1.34-1.5 2.62-2.73 3.49-3.56A24.63 24.63 0 005.75 16.41z"
            fill="url(#prefix__h2)"
          />
          <a.path
            style={spring3}
            d="M16.43 49c-2.12-1.21 1.13-.52 6.16-1 3.28-.3 10.93-2.39 14.66-8.85L34 36.62a1.45 1.45 0 00-2 .09c-5.84 5.44-11.3 6.54-15.87 5.7l-.45 1s-.92 2.72-3.82.51A24.8 24.8 0 012.2 31.65C.6 29 0 27.5 0 29.38 0 31.82 1.88 46.49 16.15 51a23.62 23.62 0 0012.75.61A26.22 26.22 0 0116.43 49z"
            fill="url(#prefix__i2)"
          />
          <a.path
            style={spring3}
            d="M40.1 45.61c2.83-2.29.41-3.82.41-3.82l-3.24-2.56c-3.73 6.46-11.38 8.55-14.66 8.85-5 .44-8.28-.25-6.16 1a26.25 26.25 0 0012.45 2.53 24.76 24.76 0 0011.2-6z"
            fill="url(#prefix__a2)"
          />
          <a.path
            style={spring2}
            d="M11.94 44c2.9 2.21 3.82-.51 3.82-.51l.45-1c-6.14-1.18-10.66-5.82-12.31-8.2-.65-.95-1.19-1.83-1.66-2.62A24.82 24.82 0 0011.94 44z"
            fill="url(#prefix__j2)"
          />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
