import create from 'zustand'

const IntroStates = create(set => ({
  //isFirstTouch: false,
  topicsViewed: {
    complexity: false,
    interdependencies: false,
    specialization: false,
    inequalities: false
  },
  topicsHidden: {
    complexity: false,
    interdependencies: false,
    specialization: false,
    inequalities: false
  },
  viewTopic: (topic) => { 
    set(state => {
      state.topicsViewed[topic] = true
      return { topicsViewed: { ...state.topicsViewed } }
    }) 
  },
  hideTopic: (topic) => {
    set(state => {
      state.topicsHidden[topic] = true
      return { topicsHidden: { ...state.topicsHidden } }
    })
  },
  /* toggleFirstTouch: () => {
    set(state => {
      return { isFirstTouch: !state.isFirstTouch }
    })
  } */
}))

export default IntroStates