import React, { createContext, useState } from 'react'

export const NavStateContext = createContext()

const NavStateProvider = ({ children }) => {

  const [navState, setNavState] = useState({
    complexity: false,
    socialInequities: false, 
    interdependencies: false,
    smartSpecializationStrategies: false,
    counter: 0
  })

  return (
    <NavStateContext.Provider value={[navState, setNavState]} >
      {children}
    </NavStateContext.Provider>
  )
}

export default NavStateProvider
