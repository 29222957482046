import { useRef } from 'react'

const useRefsArray = () => {
  
  const refArray = useRef([])
  refArray.current = []

  const refFunction = (el) => {
    if (el && !refArray.current.includes(el)) {
      refArray.current.push(el)
    }
  }

  return [refArray, refFunction]
}

export default useRefsArray