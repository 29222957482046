import React, { useEffect, useRef } from 'react'
import './InterdependenciesIntroPage.css'
import anime from 'animejs'

import IntroStates from '../../State/IntroStates'

const InterdependenciesIntroPage = (props) => {

  //state handling
  const viewTopic = IntroStates(state => state.viewTopic)

  useEffect(() => {
    viewTopic("interdependencies")
  }, [viewTopic])

  //animation handling
  //const lineAnimationRef = useRef()
  const pathAnimationRef = useRef()

  useEffect(() => {

    /* lineAnimationRef.current = anime({
      targets: '#the-seerri-project-container path',
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: 'easeInOutCubic',
      duration: 2000,
      begin: function(anim) {
        $('#the-seerri-project-container svg path').css("stroke", "#235666");
        $('#the-seerri-project-container svg line').css("stroke", "#235666");
      },
      complete: function(anim) {},
      autoplay: true
    }) */

    pathAnimationRef.current = anime({
      targets: '.container .background svg path',
      easing: 'easeInOutCubic',
      duration: 1000,
      opacity: 1,
      delay: anime.stagger(100),
      begin: function(anim) {},
      complete: function(anim) {},
      autoplay: true
    })

  }, [])

  const goBack = () => {
    window.appHistory.push('/')
  }

  return (
    <div className="container" >
      <div className="background" >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 2250 1850"
        >
          <defs>
            <linearGradient
              id="prefix__Degradado_sin_nombre_3"
              x1={1727.5}
              y1={759.23}
              x2={1882.29}
              y2={759.23}
              gradientUnits="userSpaceOnUse"
            >
              <stop offset={0} stopColor="#fcec29" />
              <stop offset={1} stopColor="#f09600" />
            </linearGradient>
            <linearGradient
              id="prefix__Degradado_sin_nombre_672"
              x1={1849.17}
              y1={1596.78}
              x2={1863.61}
              y2={1596.78}
              gradientUnits="userSpaceOnUse"
            >
              <stop offset={0} stopColor="#650e80" />
              <stop offset={1} stopColor="#e77b91" />
            </linearGradient>
            <linearGradient
              id="prefix__Degradado_sin_nombre_672-2"
              x1={1830.19}
              y1={1609.13}
              x2={1844.64}
              y2={1609.13}
              xlinkHref="#prefix__Degradado_sin_nombre_672"
            />
            <linearGradient
              id="prefix__Degradado_sin_nombre_672-3"
              x1={1848.31}
              y1={1624.18}
              x2={1861.24}
              y2={1624.18}
              xlinkHref="#prefix__Degradado_sin_nombre_672"
            />
            <linearGradient
              id="prefix__Degradado_sin_nombre_672-4"
              x1={1878.45}
              y1={1601.19}
              x2={1894.73}
              y2={1601.19}
              xlinkHref="#prefix__Degradado_sin_nombre_672"
            />
            <linearGradient
              id="prefix__Degradado_sin_nombre_672-5"
              x1={1849.27}
              y1={1554.3}
              x2={1865.56}
              y2={1554.3}
              xlinkHref="#prefix__Degradado_sin_nombre_672"
            />
            <linearGradient
              id="prefix__Degradado_sin_nombre_672-6"
              x1={1875.14}
              y1={1622.99}
              x2={1894.79}
              y2={1622.99}
              xlinkHref="#prefix__Degradado_sin_nombre_672"
            />
            <linearGradient
              id="prefix__Degradado_sin_nombre_672-7"
              x1={1833.79}
              y1={1655.51}
              x2={1851.31}
              y2={1655.51}
              xlinkHref="#prefix__Degradado_sin_nombre_672"
            />
            <linearGradient
              id="prefix__Degradado_sin_nombre_672-8"
              x1={1867.42}
              y1={1667.74}
              x2={1880.35}
              y2={1667.74}
              xlinkHref="#prefix__Degradado_sin_nombre_672"
            />
            <linearGradient
              id="prefix__Degradado_sin_nombre_672-9"
              x1={1909.69}
              y1={1573.73}
              x2={1922.62}
              y2={1573.73}
              xlinkHref="#prefix__Degradado_sin_nombre_672"
            />
            <linearGradient
              id="prefix__Degradado_sin_nombre_672-10"
              x1={1903.28}
              y1={1705.67}
              x2={1916.21}
              y2={1705.67}
              xlinkHref="#prefix__Degradado_sin_nombre_672"
            />
            <linearGradient
              id="prefix__Degradado_sin_nombre_672-11"
              x1={1917.59}
              y1={1606.69}
              x2={1933.88}
              y2={1606.69}
              xlinkHref="#prefix__Degradado_sin_nombre_672"
            />
            <linearGradient
              id="prefix__Degradado_sin_nombre_3-2"
              x1={866.11}
              y1={-8441.82}
              x2={1032.29}
              y2={-8441.82}
              gradientTransform="matrix(.97 -.25 -.25 -.97 -1089.27 -6517.5)"
              xlinkHref="#prefix__Degradado_sin_nombre_3"
            />
            <linearGradient
              id="prefix__Degradado_sin_nombre_23"
              x1={1891.34}
              y1={1779.52}
              x2={1930.54}
              y2={1779.52}
              gradientUnits="userSpaceOnUse"
            >
              <stop offset={0} stopColor="#831e7d" />
              <stop offset={0.26} stopColor="#c83280" />
              <stop offset={1} stopColor="#e3992b" />
            </linearGradient>
            <linearGradient
              id="prefix__Degradado_sin_nombre_3-3"
              x1={1776.01}
              y1={1456.56}
              x2={1991.88}
              y2={1456.56}
              xlinkHref="#prefix__Degradado_sin_nombre_3"
            />
            <linearGradient
              id="prefix__Degradado_sin_nombre_19"
              x1={3030.79}
              y1={-8284.44}
              x2={4341.58}
              y2={-8284.44}
              gradientTransform="matrix(-.72 -.7 .7 -.72 10924.89 -2891.73)"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset={0} stopColor="#ecb756" />
              <stop offset={0.3} stopColor="#e59f59" />
              <stop offset={0.93} stopColor="#d4625f" />
              <stop offset={1} stopColor="#d25b60" />
            </linearGradient>
            <linearGradient
              id="prefix__Degradado_sin_nombre_23-2"
              x1={2127.68}
              y1={848.14}
              x2={2237.89}
              y2={848.14}
              gradientTransform="rotate(18.31 2357.455 -4680.02)"
              xlinkHref="#prefix__Degradado_sin_nombre_23"
            />
            <linearGradient
              id="prefix__Degradado_sin_nombre_29"
              x1={2714.3}
              y1={845.2}
              x2={2740.91}
              y2={845.2}
              gradientTransform="rotate(18.31 2357.455 -4680.02)"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset={0} stopColor="#900280" />
              <stop offset={0.26} stopColor="#da1382" />
              <stop offset={1} stopColor="#f09600" />
            </linearGradient>
            <linearGradient
              id="prefix__Degradado_sin_nombre_29-2"
              x1={2709.62}
              y1={844.34}
              x2={2737.7}
              y2={844.34}
              xlinkHref="#prefix__Degradado_sin_nombre_29"
            />
            <linearGradient
              id="prefix__Degradado_sin_nombre_23-3"
              x1={2255.73}
              y1={2481.74}
              x2={2597}
              y2={2481.74}
              gradientTransform="rotate(51 3022.593 -361.114)"
              xlinkHref="#prefix__Degradado_sin_nombre_23"
            />
            <linearGradient
              id="prefix__Degradado_sin_nombre_23-4"
              x1={2336.46}
              y1={2470.23}
              x2={2595.77}
              y2={2470.23}
              gradientTransform="rotate(51 3022.593 -361.114)"
              xlinkHref="#prefix__Degradado_sin_nombre_23"
            />
            <linearGradient
              id="prefix__Degradado_sin_nombre_23-5"
              x1={-20.98}
              y1={2144.48}
              x2={314.98}
              y2={2144.48}
              gradientTransform="rotate(32.9 2328.491 1995.095)"
              xlinkHref="#prefix__Degradado_sin_nombre_23"
            />
            <linearGradient
              id="prefix__Degradado_sin_nombre_656"
              x1={2178.65}
              y1={1006.39}
              x2={2191.91}
              y2={1006.39}
              gradientTransform="rotate(18.31 2357.455 -4680.02)"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset={0} stopColor="#002984" />
              <stop offset={0.01} stopColor="#104194" />
              <stop offset={0.02} stopColor="#387ebe" />
              <stop offset={0.03} stopColor="#55abdd" />
              <stop offset={1} stopColor="#900280" />
            </linearGradient>
            <linearGradient
              id="prefix__Degradado_sin_nombre_656-2"
              x1={2164.51}
              y1={1024.07}
              x2={2177.77}
              y2={1024.07}
              xlinkHref="#prefix__Degradado_sin_nombre_656"
            />
            <linearGradient
              id="prefix__Degradado_sin_nombre_656-3"
              x1={2185.95}
              y1={1032.91}
              x2={2198.75}
              y2={1032.91}
              xlinkHref="#prefix__Degradado_sin_nombre_656"
            />
            <linearGradient
              id="prefix__Degradado_sin_nombre_656-4"
              x1={2207.16}
              y1={1001.09}
              x2={2223.5}
              y2={1001.09}
              xlinkHref="#prefix__Degradado_sin_nombre_656"
            />
            <linearGradient
              id="prefix__Degradado_sin_nombre_656-5"
              x1={2164.73}
              y1={965.73}
              x2={2181.08}
              y2={965.73}
              xlinkHref="#prefix__Degradado_sin_nombre_656"
            />
            <linearGradient
              id="prefix__Degradado_sin_nombre_656-6"
              x1={2210.7}
              y1={1022.3}
              x2={2230.57}
              y2={1022.3}
              xlinkHref="#prefix__Degradado_sin_nombre_656"
            />
            <linearGradient
              id="prefix__Degradado_sin_nombre_656-7"
              x1={2153.9}
              y1={1066.49}
              x2={2167.16}
              y2={1066.49}
              xlinkHref="#prefix__Degradado_sin_nombre_656"
            />
            <linearGradient
              id="prefix__Degradado_sin_nombre_656-8"
              x1={2125.84}
              y1={1032.91}
              x2={2138.65}
              y2={1032.91}
              xlinkHref="#prefix__Degradado_sin_nombre_656"
            />
            <linearGradient
              id="prefix__Degradado_sin_nombre_656-9"
              x1={2182.18}
              y1={1066.49}
              x2={2198.99}
              y2={1066.49}
              xlinkHref="#prefix__Degradado_sin_nombre_656"
            />
            <linearGradient
              id="prefix__Degradado_sin_nombre_656-10"
              x1={2132.69}
              y1={1073.56}
              x2={2145.95}
              y2={1073.56}
              xlinkHref="#prefix__Degradado_sin_nombre_656"
            />
            <linearGradient
              id="prefix__Degradado_sin_nombre_656-11"
              x1={2136.23}
              y1={1101.85}
              x2={2149.49}
              y2={1101.85}
              xlinkHref="#prefix__Degradado_sin_nombre_656"
            />
            <linearGradient
              id="prefix__Degradado_sin_nombre_656-12"
              x1={2171.81}
              y1={1103.62}
              x2={2184.61}
              y2={1103.62}
              xlinkHref="#prefix__Degradado_sin_nombre_656"
            />
            <linearGradient
              id="prefix__Degradado_sin_nombre_656-13"
              x1={2097.56}
              y1={1085.94}
              x2={2110.37}
              y2={1085.94}
              xlinkHref="#prefix__Degradado_sin_nombre_656"
            />
            <linearGradient
              id="prefix__Degradado_sin_nombre_656-14"
              x1={2154.13}
              y1={1011.69}
              x2={2166.94}
              y2={1011.69}
              xlinkHref="#prefix__Degradado_sin_nombre_656"
            />
            <linearGradient
              id="prefix__Degradado_sin_nombre_656-15"
              x1={2108.17}
              y1={994.02}
              x2={2120.97}
              y2={994.02}
              xlinkHref="#prefix__Degradado_sin_nombre_656"
            />
            <linearGradient
              id="prefix__Degradado_sin_nombre_656-16"
              x1={2065.74}
              y1={1071.8}
              x2={2078.55}
              y2={1071.8}
              xlinkHref="#prefix__Degradado_sin_nombre_656"
            />
            <linearGradient
              id="prefix__Degradado_sin_nombre_656-17"
              x1={2101.1}
              y1={1124.83}
              x2={2113.91}
              y2={1124.83}
              xlinkHref="#prefix__Degradado_sin_nombre_656"
            />
            <linearGradient
              id="prefix__Degradado_sin_nombre_656-18"
              x1={2217.77}
              y1={1068.26}
              x2={2230.57}
              y2={1068.26}
              xlinkHref="#prefix__Degradado_sin_nombre_656"
            />
            <linearGradient
              id="prefix__Degradado_sin_nombre_656-19"
              x1={2228.37}
              y1={965.73}
              x2={2241.18}
              y2={965.73}
              xlinkHref="#prefix__Degradado_sin_nombre_656"
            />
            <linearGradient
              id="prefix__Degradado_sin_nombre_656-20"
              x1={2263.73}
              y1={1093.01}
              x2={2276.54}
              y2={1093.01}
              xlinkHref="#prefix__Degradado_sin_nombre_656"
            />
            <linearGradient
              id="prefix__Degradado_sin_nombre_656-21"
              x1={2246.05}
              y1={994.01}
              x2={2262.39}
              y2={994.01}
              xlinkHref="#prefix__Degradado_sin_nombre_656"
            />
            <linearGradient
              id="prefix__Degradado_sin_nombre_3-4"
              x1={1684.01}
              y1={1116.13}
              x2={1698.45}
              y2={1116.13}
              xlinkHref="#prefix__Degradado_sin_nombre_3"
            />
            <linearGradient
              id="prefix__Degradado_sin_nombre_9"
              x1={1640.82}
              y1={1215.42}
              x2={1655.27}
              y2={1215.42}
              gradientUnits="userSpaceOnUse"
            >
              <stop offset={0} stopColor="#ddda3e" />
              <stop offset={1} stopColor="#53afd2" />
            </linearGradient>
            <linearGradient
              id="prefix__Degradado_sin_nombre_11"
              x1={1709.11}
              y1={1120.28}
              x2={1722.04}
              y2={1120.28}
              gradientUnits="userSpaceOnUse"
            >
              <stop offset={0} stopColor="#2d307e" />
              <stop offset={0.24} stopColor="#35487a" />
              <stop offset={0.74} stopColor="#4b8571" />
              <stop offset={1} stopColor="#57a86c" />
            </linearGradient>
            <linearGradient
              id="prefix__Degradado_sin_nombre_3-5"
              x1={1718.72}
              y1={1078.92}
              x2={1735}
              y2={1078.92}
              xlinkHref="#prefix__Degradado_sin_nombre_3"
            />
            <linearGradient
              id="prefix__Degradado_sin_nombre_3-6"
              x1={1689.54}
              y1={1032.03}
              x2={1705.83}
              y2={1032.03}
              xlinkHref="#prefix__Degradado_sin_nombre_3"
            />
            <linearGradient
              id="prefix__Degradado_sin_nombre_11-2"
              x1={1699.75}
              y1={1166.09}
              x2={1719.39}
              y2={1166.09}
              xlinkHref="#prefix__Degradado_sin_nombre_11"
            />
            <linearGradient
              id="prefix__Degradado_sin_nombre_9-2"
              x1={1665.44}
              y1={1287.48}
              x2={1679.88}
              y2={1287.48}
              xlinkHref="#prefix__Degradado_sin_nombre_9"
            />
            <linearGradient
              id="prefix__Degradado_sin_nombre_3-7"
              x1={1631.52}
              y1={1083.03}
              x2={1644.45}
              y2={1083.03}
              xlinkHref="#prefix__Degradado_sin_nombre_3"
            />
            <linearGradient
              id="prefix__Degradado_sin_nombre_9-3"
              x1={1735.11}
              y1={1290.98}
              x2={1752.62}
              y2={1290.98}
              xlinkHref="#prefix__Degradado_sin_nombre_9"
            />
            <linearGradient
              id="prefix__Degradado_sin_nombre_9-4"
              x1={1797.03}
              y1={1208.13}
              x2={1811.48}
              y2={1208.13}
              xlinkHref="#prefix__Degradado_sin_nombre_9"
            />
            <linearGradient
              id="prefix__Degradado_sin_nombre_9-5"
              x1={1721.15}
              y1={1406.26}
              x2={1735.59}
              y2={1406.26}
              xlinkHref="#prefix__Degradado_sin_nombre_9"
            />
            <linearGradient
              id="prefix__Degradado_sin_nombre_9-6"
              x1={1718.31}
              y1={1217.54}
              x2={1731.24}
              y2={1217.54}
              xlinkHref="#prefix__Degradado_sin_nombre_9"
            />
            <linearGradient
              id="prefix__Degradado_sin_nombre_11-3"
              x1={1649.05}
              y1={1138.54}
              x2={1661.99}
              y2={1138.54}
              xlinkHref="#prefix__Degradado_sin_nombre_11"
            />
            <linearGradient
              id="prefix__Degradado_sin_nombre_16"
              x1={1665.04}
              y1={1071.78}
              x2={1677.97}
              y2={1071.78}
              gradientUnits="userSpaceOnUse"
            >
              <stop offset={0.49} stopColor="#ddda3e" />
              <stop offset={0.53} stopColor="#d4d748" />
              <stop offset={1} stopColor="#53afd2" />
            </linearGradient>
            <linearGradient
              id="prefix__Degradado_sin_nombre_3-8"
              x1={1626.95}
              y1={1040.56}
              x2={1639.88}
              y2={1040.56}
              xlinkHref="#prefix__Degradado_sin_nombre_3"
            />
            <linearGradient
              id="prefix__Degradado_sin_nombre_3-9"
              x1={1597.72}
              y1={1510.35}
              x2={1610.65}
              y2={1510.35}
              xlinkHref="#prefix__Degradado_sin_nombre_3"
            />
            <linearGradient
              id="prefix__Degradado_sin_nombre_3-10"
              x1={1614.63}
              y1={1571.81}
              x2={1627.56}
              y2={1571.81}
              xlinkHref="#prefix__Degradado_sin_nombre_3"
            />
            <linearGradient
              id="prefix__Degradado_sin_nombre_11-4"
              x1={1758.47}
              y1={1138.99}
              x2={1771.4}
              y2={1138.99}
              xlinkHref="#prefix__Degradado_sin_nombre_11"
            />
            <linearGradient
              id="prefix__Degradado_sin_nombre_3-11"
              x1={787.16}
              y1={1264.76}
              x2={800.09}
              y2={1264.76}
              xlinkHref="#prefix__Degradado_sin_nombre_3"
            />
            <linearGradient
              id="prefix__Degradado_sin_nombre_9-7"
              x1={643.54}
              y1={1519.87}
              x2={656.47}
              y2={1519.87}
              xlinkHref="#prefix__Degradado_sin_nombre_9"
            />
            <linearGradient
              id="prefix__Degradado_sin_nombre_9-8"
              x1={1796.75}
              y1={1080.1}
              x2={1813.04}
              y2={1080.1}
              xlinkHref="#prefix__Degradado_sin_nombre_9"
            />
            <linearGradient
              id="prefix__Degradado_sin_nombre_29-3"
              x1={-813.28}
              y1={-3936.21}
              x2={-647.1}
              y2={-3936.21}
              gradientTransform="rotate(-66.53 3957.851 -3623.101)"
              xlinkHref="#prefix__Degradado_sin_nombre_29"
            />
            <linearGradient
              id="prefix__Degradado_sin_nombre_3-12"
              x1={2609.31}
              y1={871.25}
              x2={2724.97}
              y2={871.25}
              gradientTransform="rotate(6.9 1317.665 -7567.649)"
              xlinkHref="#prefix__Degradado_sin_nombre_3"
            />
            <linearGradient
              id="prefix__Degradado_sin_nombre_21"
              x1={-1179.36}
              y1={-3772.29}
              x2={-1169.9}
              y2={-3772.29}
              gradientTransform="rotate(-66.53 3957.851 -3623.101)"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset={0} stopColor="#f9eb58" />
              <stop offset={1} stopColor="#e3992b" />
            </linearGradient>
            <linearGradient
              id="prefix__Degradado_sin_nombre_672-12"
              x1={2190.97}
              y1={447.84}
              x2={2244.22}
              y2={447.84}
              gradientTransform="rotate(18.31 2357.455 -4680.02)"
              xlinkHref="#prefix__Degradado_sin_nombre_672"
            />
            <linearGradient
              id="prefix__Degradado_sin_nombre_25"
              x1={2063.95}
              y1={686.23}
              x2={2206.65}
              y2={686.23}
              gradientTransform="rotate(18.31 2357.455 -4680.02)"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset={0} stopColor="#6baad8" />
              <stop offset={0.19} stopColor="#6994ca" />
              <stop offset={0.59} stopColor="#635ba5" />
              <stop offset={1} stopColor="#5c1c7c" />
            </linearGradient>
            <linearGradient
              id="prefix__Degradado_sin_nombre_27"
              x1={-838.12}
              y1={-1509.39}
              x2={-788.58}
              y2={-1509.39}
              gradientTransform="rotate(-41.9 3036.248 -3827.96)"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset={0} stopColor="#64a541" />
              <stop offset={1} stopColor="#6baad8" />
            </linearGradient>
            <linearGradient
              id="prefix__Degradado_sin_nombre_29-4"
              x1={2430.56}
              y1={3970.65}
              x2={2575.13}
              y2={3970.65}
              gradientTransform="rotate(-17.51 -5758.025 9635.148)"
              xlinkHref="#prefix__Degradado_sin_nombre_29"
            />
            <linearGradient
              id="prefix__Degradado_sin_nombre_3-13"
              x1={-270.51}
              y1={3561.15}
              x2={31.54}
              y2={3561.15}
              gradientTransform="rotate(-35.81 -2734.441 1676.905)"
              xlinkHref="#prefix__Degradado_sin_nombre_3"
            />
            <linearGradient
              id="prefix__Degradado_sin_nombre_5"
              x1={-335.36}
              y1={-6262.18}
              x2={89.9}
              y2={-6262.18}
              gradientTransform="rotate(-67 5457.085 -3239.292)"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset={0} stopColor="#5e2b7e" />
              <stop offset={1} stopColor="#dc768c" />
            </linearGradient>
            <linearGradient
              id="prefix__Degradado_sin_nombre_5-2"
              x1={-179.42}
              y1={-5007.52}
              x2={403.16}
              y2={-5007.52}
              xlinkHref="#prefix__Degradado_sin_nombre_5"
            />
            <linearGradient
              id="prefix__Degradado_sin_nombre_23-6"
              x1={-1454.9}
              y1={2715.69}
              x2={-90.96}
              y2={2715.69}
              gradientTransform="matrix(-.3 -.82 .76 -.37 -2025.78 1026.12)"
              xlinkHref="#prefix__Degradado_sin_nombre_23"
            />
            <linearGradient
              id="prefix__Degradado_sin_nombre_3-14"
              x1={2442.27}
              y1={4036.14}
              x2={2504.79}
              y2={4036.14}
              gradientTransform="rotate(-17.51 -5758.025 9635.148)"
              xlinkHref="#prefix__Degradado_sin_nombre_3"
            />
            <linearGradient
              id="prefix__Degradado_sin_nombre_3-15"
              x1={-846.61}
              y1={-1619.91}
              x2={-798.61}
              y2={-1619.91}
              gradientTransform="rotate(-41.9 3036.248 -3827.96)"
              xlinkHref="#prefix__Degradado_sin_nombre_3"
            />
            <style>{".prefix__cls-69{fill:#344c47}"}</style>
          </defs>
          <title>{"interdependencies-background"}</title>
          <g id="prefix__Layer_2" data-name="Layer 2">
            <g id="prefix__Layer_1-2" data-name="Layer 1">
              <path
                d="M1727.5 756.73a350.38 350.38 0 0136.67-29.2c6.57-4.55 13.4-8.92 21-11.26s16.25-2.46 23.3 1.3c8.94 4.76 13.71 14.58 20.29 22.28a56.07 56.07 0 0051.75 18.42c4.57 12-.16 26.64-10.49 34.32-8.23 6.13-18.91 7.6-29.08 8.87-16.34 2-33.11 4.07-49.1.16-25.37-6.21-48.68-23.95-64.34-44.89z"
                fill="url(#prefix__Degradado_sin_nombre_3)"
              />
              <path
                d="M1862.13 1600.54l1.11-3.35c2.68-8.1-9.9-12.28-12.58-4.17l-1.11 3.36c-2.68 8.1 9.9 12.28 12.58 4.16z"
                fill="url(#prefix__Degradado_sin_nombre_672)"
              />
              <path
                d="M1843.15 1612.89l1.11-3.36c2.68-8.1-9.9-12.28-12.58-4.16l-1.11 3.35c-2.68 8.1 9.9 12.28 12.58 4.17z"
                fill="url(#prefix__Degradado_sin_nombre_672-2)"
              />
              <path
                d="M1852.7 1630.47c8.1 2.68 12.27-9.9 4.16-12.58s-12.28 9.9-4.16 12.58z"
                fill="url(#prefix__Degradado_sin_nombre_672-3)"
              />
              <path
                d="M1890.35 1595.45l-3.36-1.11c-8.09-2.68-12.27 9.9-4.16 12.59l3.36 1.11c8.1 2.68 12.27-9.91 4.16-12.59z"
                fill="url(#prefix__Degradado_sin_nombre_672-4)"
              />
              <path
                d="M1853.66 1560l3.35 1.11c8.1 2.67 12.28-9.91 4.17-12.59l-3.36-1.11c-8.1-2.68-12.28 9.9-4.16 12.59z"
                fill="url(#prefix__Degradado_sin_nombre_672-5)"
              />
              <path
                d="M1890.4 1617.81l-6.71-2.22c-8.1-2.68-12.28 9.9-4.17 12.59l6.72 2.22c8.1 2.68 12.27-9.91 4.16-12.59z"
                fill="url(#prefix__Degradado_sin_nombre_672-6)"
              />
              <path
                d="M1843.3 1662.56l4.46-2.25c7.64-3.84 1.68-15.68-6-11.84l-4.47 2.24c-7.63 3.84-1.68 15.69 6 11.85z"
                fill="url(#prefix__Degradado_sin_nombre_672-7)"
              />
              <path
                d="M1871.8 1674c8.1 2.68 12.28-9.9 4.17-12.58s-12.28 9.9-4.17 12.58z"
                fill="url(#prefix__Degradado_sin_nombre_672-8)"
              />
              <path
                d="M1914.07 1580c8.1 2.68 12.28-9.9 4.17-12.58s-12.28 9.9-4.17 12.58z"
                fill="url(#prefix__Degradado_sin_nombre_672-9)"
              />
              <path
                d="M1907.67 1712c8.09 2.68 12.27-9.9 4.16-12.58s-12.28 9.9-4.16 12.58z"
                fill="url(#prefix__Degradado_sin_nombre_672-10)"
              />
              <path
                d="M1922 1612.43l3.36 1.11c8.1 2.68 12.28-9.91 4.16-12.59l-3.35-1.11c-8.1-2.68-12.28 9.9-4.17 12.59z"
                fill="url(#prefix__Degradado_sin_nombre_672-11)"
              />
              <path
                d="M1882.9 1184.45c-3.45 1.83-7 3.64-9.63 6.56s-4 7.28-2.31 10.81c1.93 4.06 6.88 5.46 11.16 6.85 12.3 4 24.14 12.6 27.44 25.1 2.23 8.46.27 17.45-2.39 25.78s-6.06 16.57-6.76 25.29 1.89 18.33 9 23.43c4.91 3.52 11.34 4.42 17.31 3.51s11.56-3.5 16.84-6.43c8-4.45 16-10.4 18.69-19.11 2.11-6.68.79-13.92-.82-20.74-2.44-10.31-5.52-20.51-6.87-31s-.85-21.55 3.63-31.16c6.87-14.76 22-24.13 30-38.32 4.23-7.54 6.1-17.39 1.35-24.61-3.87-5.88-11.2-8.58-18.21-9.25-8-.77-18.34.33-25.21 4.85-6.54 4.31-12 11.94-18 17.23a233.32 233.32 0 01-45.22 31.21z"
                fill="#d96a3f"
              />
              <path
                d="M1884.1 1453.31c-11.92-6-25.44-9.47-35.57-18.18-5-4.27-9.09-10.22-9-16.76.21-9.65 9.51-16.78 18.81-19.36s19.24-2.16 28.52-4.79 18.52-9.88 18.57-19.53c0-8.65-7-15.42-13.34-21.34-.71-.67-1.43-1.36-2.13-2.07-7.64-7.69-1.63-20.69 9.19-20a155.14 155.14 0 0182.26 29.66c10.08 7.43 19.77 17.06 21.78 29.41 3.77 23.26-20.58 42.2-23 65.63-.92 9 1.51 18 2.5 27s.25 19-5.83 25.72c-7 7.78-19.37 8.71-29.19 5-14.43-5.39-20.87-15-28.23-27.33a87.77 87.77 0 00-35.34-33.06z"
                fill="url(#prefix__Degradado_sin_nombre_3-2)"
              />
              <path
                d="M1887.65 1791.59c-22.27 4.88-40.27 21.4-53.85 39.71s-23.84 38.91-37.61 57.08c-7.51 9.9-16.23 19.19-27.15 25.12s-24.33 8.16-35.93 3.7c-16.11-6.19-25.46-24.28-25.36-41.53s8.07-33.59 18.12-47.63c19.67-27.49 60-27 90.7-41.17 12.19-5.65 24.76-11.4 38.18-12.08s26.78 4.83 32.9 16.8z"
                fill="#fadf9e"
              />
              <path
                d="M1901.09 1763.23c5.16-8.26 12.45-15.47 21.5-19.06 1.45-.58 3.36-1 4.38.23a3.71 3.71 0 01.62 2.54c-.19 6.58-3.59 12.63-7.39 18s-8.13 10.49-10.66 16.57c-.88 2.12-1.43 4.85.16 6.51a7.57 7.57 0 002.51 1.45 31.49 31.49 0 0118.26 23.65 2.12 2.12 0 01-.29 1.8 2.07 2.07 0 01-1.42.47c-7.14.33-13.52-4.6-17.93-10.23-2.2-2.81-4.11-5.87-6.67-8.36-3.61-3.53-12.9-4.94-12.82-10.75.09-7.05 6.12-17 9.75-22.82z"
                fill="url(#prefix__Degradado_sin_nombre_23)"
              />
              <path
                d="M1785.53 1302.6c7.53-24.54 18.24-44.25 30.15-55.48 15.27-14.42 35.91-21.11 58.1-18.86 25.22 2.56 49.64 16.62 63.72 36.69 16.58 23.62 26.16 52 32.66 74 18.51 62.79 34.67 156.92 6.49 248.74-1.4 4.57-2.92 9.15-4.51 13.62-10.94 30.61-24.8 52.41-42.4 66.64-18.82 15.22-48.87 24.41-73.55 10.62-23.63-13.19-36.7-45.29-35-85.85.44-10.42 1.59-21 2.7-31.3 2.34-21.59 4.77-43.91.6-65.22-4-20.24-13.77-38.89-23.24-56.93-9.9-18.84-20.14-38.32-23.88-59.79-3.43-19.84-.31-49.29 8.16-76.88zm188.38 284.22c28-91.14 11.9-184.67-6.5-247.09-6.44-21.82-15.92-49.86-32.26-73.14-13.62-19.41-37.24-33-61.66-35.48-21.35-2.17-41.18 4.25-55.84 18.09-11.55 10.9-22 30.16-29.37 54.23-8.35 27.2-11.43 56.13-8.06 75.53 3.66 21 13.79 40.3 23.59 59 9.57 18.22 19.47 37.06 23.51 57.71 4.26 21.73 1.81 44.28-.55 66.08-1.11 10.22-2.25 20.78-2.69 31.11-1.68 39.46 10.85 70.57 33.51 83.22 23.51 13.13 52.27 4.27 70.34-10.34 17.18-13.89 30.75-35.28 41.5-65.38 1.57-4.47 3.09-9.02 4.48-13.54z"
                fill="url(#prefix__Degradado_sin_nombre_3-3)"
              />
              <path
                d="M1954.76 546c-62.12-49-106.81-101.76-125.81-148.5-24.39-60-20.62-126.74 10.62-188 35.48-69.6 103.09-124.07 176.43-142.16C2102.39 46 2194.47 50.09 2265 56c201.06 16.82 489.54 75.37 722 258.73 11.57 9.13 23 18.6 34.09 28.16C3096.87 408.44 3144 472.6 3165.3 539c22.74 71.06 15.64 167.63-51.49 223.18-64.26 53.18-171.07 55-285.71 4.78-29.45-12.89-58.66-28-86.9-42.67-59.43-30.8-120.88-62.66-186.75-74.43-62.58-11.19-127.1-3.83-189.49 3.29-65.18 7.43-132.58 15.12-198.44 1.91-60.76-12.14-141.91-53.95-211.76-109.06zm1026.76-224.32c-230.69-182-517.36-240.14-717.23-256.86-69.89-5.85-161-9.87-246.15 11.12-70.94 17.48-136.35 70.21-170.7 137.59-30 58.93-33.7 123.08-10.3 180.64 18.44 45.34 62.15 96.8 123.09 144.88 68.83 54.3 148.54 95.44 208 107.36 64.51 12.93 131.21 5.33 195.7-2 63-7.19 128.2-14.62 192.06-3.21 67.19 12 129.24 44.18 189.26 75.29 28.12 14.57 57.19 29.65 86.39 42.42 111.5 48.82 214.87 47.51 276.5-3.49 64-52.91 70.54-145.44 48.71-213.66-20.75-64.84-67.06-127.69-141.57-192.14a898.24 898.24 0 00-33.76-27.94z"
                opacity={0.5}
                fill="url(#prefix__Degradado_sin_nombre_19)"
              />
              <path
                d="M514.82 508a268.46 268.46 0 01-23.28 27.12c-4.27 4.34-8.76 8.6-14.14 11.44s-11.82 4.17-17.64 2.36c-7.37-2.28-12.35-9-18.38-13.78a42.8 42.8 0 00-41.4-6.38c-5.14-8.36-3.68-20 2.95-27.2 5.28-5.77 13.07-8.4 20.51-10.81 11.94-3.87 24.21-7.79 36.74-7.15 19.91 1.03 39.91 10.98 54.64 24.4z"
                fill="url(#prefix__Degradado_sin_nombre_23-2)"
              />
              <path
                d="M999.88 642q-9.61 14.1-19.21 28.22-9.67 14.28-19.35 28.55l-15.2 22.39.46.15 12.75-18.77 19.4-28.54q9.71-14.25 19.36-28.54l2.13-3.15z"
                fill="url(#prefix__Degradado_sin_nombre_29)"
              />
              <path
                d="M997.26 639.29q-9.4 13.05-18.66 26.19-10.12 14.38-20 28.92-8.6 12.6-16.92 25.25l.84.28q7.07-10.77 14.24-21.46 9.81-14.6 19.85-29T997 640.76l.74-1z"
                fill="url(#prefix__Degradado_sin_nombre_29-2)"
              />
              <path
                d="M433 949.24q21.44 51.46 42.6 103 13.65 33.18 27.25 66.4l1.19-1.11q-10.38-26.58-20.88-53.13-20.67-52.12-41.75-104.07-21-51.71-42.21-103.3-11.74-28.56-23.88-57l-9-9.72q11.66 28.05 23.47 56 21.79 51.46 43.21 102.93z"
                fill="url(#prefix__Degradado_sin_nombre_23-3)"
              />
              <path
                d="M421.28 849.82q5.88 15.87 11.74 31.75 28 75.76 56.08 151.53l27 73.3 1.2-1.11q-10-27.12-19.94-54.24-27.94-75.82-55.69-151.73-8.34-22.71-16.67-45.44z"
                fill="url(#prefix__Degradado_sin_nombre_23-4)"
              />
              <path
                d="M442.36 1021.47c14.77 37.1 29.35 74.39 45.82 110.79l2.18-2c-13-28.9-24.84-58.35-36.52-87.79q-37-93.24-75-186.08-19.65-47.71-39.63-95.28l-6.21-6.72q16.92 40.26 33.64 80.61 38.45 93 75.72 186.47z"
                fill="url(#prefix__Degradado_sin_nombre_23-5)"
              />
              <path
                d="M413.43 668.07l1.11-3.36c2.68-8.1-9.9-12.28-12.59-4.16l-1.11 3.35c-2.68 8.1 9.91 12.28 12.59 4.17z"
                fill="url(#prefix__Degradado_sin_nombre_656)"
              />
              <path
                d="M394.45 680.41l1.11-3.36c2.68-8.1-9.9-12.28-12.58-4.16-.37 1.12-.74 2.23-1.12 3.35-2.67 8.1 9.91 12.28 12.59 4.17z"
                fill="url(#prefix__Degradado_sin_nombre_656-2)"
              />
              <path
                d="M404 698c8.09 2.68 12.27-9.9 4.16-12.58S395.88 695.31 404 698z"
                fill="url(#prefix__Degradado_sin_nombre_656-3)"
              />
              <path
                d="M441.65 663l-3.36-1.11c-8.1-2.68-12.27 9.9-4.16 12.59l3.36 1.11c8.09 2.68 12.27-9.91 4.16-12.59z"
                fill="url(#prefix__Degradado_sin_nombre_656-4)"
              />
              <path
                d="M405 627.56l3.36 1.11c8.1 2.68 12.28-9.91 4.16-12.59l-3.4-1.08c-8.1-2.68-12.28 9.9-4.17 12.59z"
                fill="url(#prefix__Degradado_sin_nombre_656-5)"
              />
              <path
                d="M441.7 685.33l-6.7-2.22c-8.1-2.68-12.28 9.9-4.17 12.59l6.72 2.22c8.1 2.68 12.27-9.9 4.16-12.59z"
                fill="url(#prefix__Degradado_sin_nombre_656-6)"
              />
              <path
                d="M359.58 709.83l-1.11 3.36c-2.68 8.1 9.9 12.28 12.59 4.17l1.11-3.36c2.68-8.1-9.91-12.28-12.59-4.17z"
                fill="url(#prefix__Degradado_sin_nombre_656-7)"
              />
              <path
                d="M346.93 679.12c8.1 2.68 12.28-9.91 4.17-12.59s-12.28 9.9-4.17 12.59z"
                fill="url(#prefix__Degradado_sin_nombre_656-8)"
              />
              <path
                d="M394.59 730.08l4.47-2.25c7.63-3.84 1.68-15.68-5.95-11.84l-4.47 2.24c-7.63 3.84-1.68 15.69 5.95 11.85z"
                fill="url(#prefix__Degradado_sin_nombre_656-9)"
              />
              <path
                d="M337.22 709.89l-1.11 3.35c-2.68 8.1 9.9 12.28 12.59 4.17l1.11-3.36c2.68-8.1-9.91-12.28-12.59-4.16z"
                fill="url(#prefix__Degradado_sin_nombre_656-10)"
              />
              <path
                d="M343.17 745.37l1.11-3.36c2.68-8.1-9.9-12.27-12.59-4.16l-1.11 3.35c-2.68 8.1 9.91 12.28 12.59 4.17z"
                fill="url(#prefix__Degradado_sin_nombre_656-11)"
              />
              <path
                d="M368.36 760.68c8.1 2.68 12.28-9.9 4.16-12.58s-12.27 9.9-4.16 12.58z"
                fill="url(#prefix__Degradado_sin_nombre_656-12)"
              />
              <path
                d="M303.42 720.58c8.1 2.68 12.28-9.9 4.17-12.58s-12.28 9.9-4.17 12.58z"
                fill="url(#prefix__Degradado_sin_nombre_656-13)"
              />
              <path
                d="M380.45 667.86c8.1 2.68 12.28-9.9 4.16-12.59s-12.27 9.91-4.16 12.59z"
                fill="url(#prefix__Degradado_sin_nombre_656-14)"
              />
              <path
                d="M342.36 636.64c8.1 2.68 12.28-9.9 4.17-12.58s-12.28 9.9-4.17 12.58z"
                fill="url(#prefix__Degradado_sin_nombre_656-15)"
              />
              <path
                d="M277.66 697.16c8.1 2.68 12.27-9.9 4.16-12.58s-12.28 9.9-4.16 12.58z"
                fill="url(#prefix__Degradado_sin_nombre_656-16)"
              />
              <path
                d="M294.57 758.62c8.09 2.68 12.27-9.91 4.16-12.59s-12.28 9.9-4.16 12.59z"
                fill="url(#prefix__Degradado_sin_nombre_656-17)"
              />
              <path
                d="M423.1 741.55c8.1 2.68 12.28-9.9 4.16-12.58s-12.27 9.9-4.16 12.58z"
                fill="url(#prefix__Degradado_sin_nombre_656-18)"
              />
              <path
                d="M465.37 647.54c8.1 2.68 12.28-9.9 4.17-12.58s-12.28 9.9-4.17 12.58z"
                fill="url(#prefix__Degradado_sin_nombre_656-19)"
              />
              <path
                d="M459 779.49c8.1 2.68 12.28-9.91 4.17-12.59s-12.28 9.9-4.17 12.59z"
                fill="url(#prefix__Degradado_sin_nombre_656-20)"
              />
              <path
                d="M473.27 680l3.36 1.11c8.1 2.68 12.28-9.9 4.16-12.59l-3.35-1.11c-8.1-2.68-12.28 9.91-4.17 12.59z"
                fill="url(#prefix__Degradado_sin_nombre_656-21)"
              />
              <path
                d="M1697 1119.89l1.11-3.35c2.68-8.1-9.9-12.28-12.59-4.17l-1.11 3.36c-2.68 8.1 9.91 12.28 12.59 4.16z"
                fill="url(#prefix__Degradado_sin_nombre_3-4)"
              />
              <path
                d="M1653.78 1219.18l1.11-3.36c2.68-8.1-9.9-12.28-12.58-4.16l-1.11 3.35c-2.68 8.1 9.9 12.28 12.58 4.17z"
                fill="url(#prefix__Degradado_sin_nombre_9)"
              />
              <path
                d="M1713.49 1126.57c8.1 2.68 12.28-9.9 4.17-12.59s-12.28 9.91-4.17 12.59z"
                fill="url(#prefix__Degradado_sin_nombre_11)"
              />
              <path
                d="M1730.62 1073.18l-3.36-1.11c-8.09-2.68-12.27 9.9-4.16 12.59l3.36 1.11c8.1 2.68 12.27-9.91 4.16-12.59z"
                fill="url(#prefix__Degradado_sin_nombre_3-5)"
              />
              <path
                d="M1693.93 1037.76l3.35 1.12c8.1 2.67 12.28-9.91 4.17-12.59l-3.36-1.11c-8.1-2.68-12.28 9.9-4.16 12.58z"
                fill="url(#prefix__Degradado_sin_nombre_3-6)"
              />
              <path
                d="M1715 1160.9l-6.72-2.22c-8.1-2.68-12.27 9.91-4.16 12.59l6.71 2.22c8.1 2.68 12.28-9.9 4.17-12.59z"
                fill="url(#prefix__Degradado_sin_nombre_11-2)"
              />
              <path
                d="M1666.92 1283.72l-1.11 3.36c-2.68 8.1 9.9 12.28 12.59 4.16l1.11-3.35c2.68-8.1-9.91-12.28-12.59-4.17z"
                fill="url(#prefix__Degradado_sin_nombre_9-2)"
              />
              <path
                d="M1635.91 1089.33c8.09 2.68 12.27-9.91 4.16-12.59s-12.28 9.9-4.16 12.59z"
                fill="url(#prefix__Degradado_sin_nombre_3-7)"
              />
              <path
                d="M1744.61 1298l4.47-2.25c7.63-3.84 1.68-15.68-6-11.85l-4.47 2.25c-7.63 3.84-1.68 15.69 6 11.85z"
                fill="url(#prefix__Degradado_sin_nombre_9-3)"
              />
              <path
                d="M1798.52 1204.36l-1.11 3.36c-2.68 8.1 9.9 12.28 12.58 4.17l1.11-3.36c2.68-8.1-9.9-12.28-12.58-4.17z"
                fill="url(#prefix__Degradado_sin_nombre_9-4)"
              />
              <path
                d="M1734.11 1410l1.11-3.35c2.68-8.1-9.9-12.28-12.59-4.17l-1.11 3.36c-2.68 8.1 9.91 12.28 12.59 4.16z"
                fill="url(#prefix__Degradado_sin_nombre_9-5)"
              />
              <path
                d="M1722.7 1223.83c8.1 2.68 12.27-9.9 4.16-12.58s-12.28 9.9-4.16 12.58z"
                fill="url(#prefix__Degradado_sin_nombre_9-6)"
              />
              <path
                d="M1653.44 1144.84c8.1 2.68 12.28-9.91 4.16-12.59s-12.27 9.9-4.16 12.59z"
                fill="url(#prefix__Degradado_sin_nombre_11-3)"
              />
              <path
                d="M1669.42 1078.07c8.1 2.68 12.28-9.9 4.16-12.59s-12.27 9.91-4.16 12.59z"
                fill="url(#prefix__Degradado_sin_nombre_16)"
              />
              <path
                d="M1631.34 1046.85c8.1 2.68 12.27-9.9 4.16-12.59s-12.28 9.91-4.16 12.59z"
                fill="url(#prefix__Degradado_sin_nombre_3-8)"
              />
              <path
                d="M1602.1 1516.65c8.1 2.68 12.28-9.91 4.17-12.59s-12.28 9.9-4.17 12.59z"
                fill="url(#prefix__Degradado_sin_nombre_3-9)"
              />
              <path
                d="M1619 1578.1c8.1 2.68 12.28-9.9 4.17-12.59s-12.28 9.91-4.17 12.59z"
                fill="url(#prefix__Degradado_sin_nombre_3-10)"
              />
              <path
                d="M1762.85 1145.28c8.1 2.68 12.28-9.9 4.17-12.59s-12.28 9.91-4.17 12.59z"
                fill="url(#prefix__Degradado_sin_nombre_11-4)"
              />
              <path
                d="M791.55 1271.06c8.1 2.67 12.27-9.91 4.16-12.59s-12.28 9.9-4.16 12.59z"
                fill="url(#prefix__Degradado_sin_nombre_3-11)"
              />
              <path
                d="M647.93 1526.16c8.1 2.68 12.27-9.9 4.16-12.58s-12.28 9.9-4.16 12.58z"
                fill="url(#prefix__Degradado_sin_nombre_9-7)"
              />
              <path
                d="M1801.14 1085.84l3.36 1.11c8.09 2.68 12.27-9.91 4.16-12.59l-3.36-1.11c-8.1-2.68-12.27 9.9-4.16 12.59z"
                fill="url(#prefix__Degradado_sin_nombre_9-8)"
              />
              <path
                d="M1768.18 589.41c4.08 12.73 5.33 26.62 12.33 38 3.43 5.58 8.65 10.59 15.14 11.48 9.56 1.32 18.07-6.73 22.08-15.51s5.17-18.65 9.24-27.4 12.69-16.73 22.22-15.25c8.55 1.32 14.11 9.38 19 16.55.55.8 1.12 1.62 1.71 2.43 6.39 8.76 20.18 4.88 21.25-5.91a155.2 155.2 0 00-16.29-85.92c-5.75-11.12-13.72-22.21-25.6-26.15-22.36-7.4-44.92 13.65-68.44 12.32-9-.51-17.54-4.33-26.28-6.74s-18.72-3.24-26.32 1.7c-8.79 5.72-11.66 17.74-9.59 28 3 15.11 11.5 23 22.52 32.2a87.76 87.76 0 0127.03 40.2z"
                fill="url(#prefix__Degradado_sin_nombre_29-3)"
              />
              <path
                d="M1600.69 937.46c-3.46 1.83-7 3.63-9.64 6.55s-4 7.29-2.31 10.81c1.94 4.07 6.89 5.46 11.16 6.86 12.3 4 24.14 12.6 27.44 25.1 2.24 8.46.27 17.44-2.39 25.78s-6.06 16.57-6.75 25.29 1.89 18.32 9 23.43c4.91 3.52 11.35 4.42 17.32 3.5s11.55-3.49 16.83-6.43c8-4.44 16-10.39 18.7-19.11 2.1-6.68.79-13.92-.82-20.74-2.44-10.31-5.53-20.5-6.87-31s-.85-21.55 3.62-31.15c6.87-14.77 22-24.13 30-38.33 4.24-7.54 6.1-17.38 1.35-24.6-3.87-5.89-11.2-8.58-18.21-9.26-8-.76-18.33.33-25.21 4.86-6.54 4.3-12 11.94-18 17.23a234.82 234.82 0 01-45.22 31.21z"
                fill="url(#prefix__Degradado_sin_nombre_3-12)"
              />
              <path
                className="prefix__cls-69"
                d="M1738.69 1036.89l-.54-.23a1.11 1.11 0 00-.89 2l.54.23a1.11 1.11 0 00.89-2zM1740.47 1040.25l-.55-.24a1.12 1.12 0 00-.88 2l.54.23a1.11 1.11 0 00.89-2zM1743.55 1038.92a1.11 1.11 0 00-2-.89 1.11 1.11 0 002 .89zM1738.26 1032.1l-.23.54a1.11 1.11 0 002 .89l.24-.55a1.12 1.12 0 00-2-.88zM1731.79 1037.7l.24-.55a1.11 1.11 0 00-2-.88l-.24.54a1.11 1.11 0 002 .89zM1742 1032.43c-.15.36-.31.72-.47 1.09a1.11 1.11 0 002 .88c.16-.36.32-.72.47-1.09a1.11 1.11 0 00-2-.88zM1744.86 1046.52l.54.24a1.11 1.11 0 00.89-2l-.55-.24a1.11 1.11 0 00-.88 2zM1739.53 1048.17a1.11 1.11 0 00-2-.88 1.11 1.11 0 002 .88zM1748.77 1041c-.1-.26-.2-.52-.31-.78a1.11 1.11 0 00-2.07.81l.31.78a1.11 1.11 0 002.07-.81zM1744.53 1050.26l.54.24a1.11 1.11 0 00.89-2l-.55-.24a1.11 1.11 0 00-.88 2zM1750.55 1049.8l-.54-.23a1.11 1.11 0 00-.89 2l.54.24a1.12 1.12 0 00.89-2zM1753.49 1045.83a1.11 1.11 0 00-2-.89 1.11 1.11 0 002 .89zM1745.81 1056.07a1.12 1.12 0 00-2-.88 1.12 1.12 0 002 .88zM1738.16 1042.4a1.11 1.11 0 00-2-.88 1.11 1.11 0 002 .88zM1732.36 1048.3a1.11 1.11 0 00-2-.89 1.11 1.11 0 002 .89zM1741.5 1060a1.11 1.11 0 00-2-.89 1.11 1.11 0 002 .89zM1752 1058.13a1.11 1.11 0 00-2-.89 1.11 1.11 0 002 .89zM1751.12 1036.39a1.11 1.11 0 00-2-.89 1.11 1.11 0 002 .89zM1736 1027.9a1.11 1.11 0 00-2-.89 1.11 1.11 0 002 .89zM1758 1031a1.11 1.11 0 00-2-.88 1.11 1.11 0 002 .88zM1741.58 1027.07l.24-.55a1.11 1.11 0 00-2-.88l-.24.54a1.11 1.11 0 002 .89z"
              />
              <path
                d="M1779.25 1021.23a4.85 4.85 0 011.41 6.82 5 5 0 01-6.93.89 5.06 5.06 0 01-.54-7s2.19-2.8 6.06-.71z"
                fill="url(#prefix__Degradado_sin_nombre_21)"
              />
              <path
                className="prefix__cls-69"
                d="M1696.12 992.67a30.07 30.07 0 0018.35 8.47 30.88 30.88 0 0021.66-7c8.39-7 13.12-19.23 12-31.21a40.22 40.22 0 00-4.51-15.45l.74-.48a40.88 40.88 0 014.58 15.7 40.29 40.29 0 01-2.2 17 34.46 34.46 0 01-10.22 15 31.74 31.74 0 01-22.34 7.22 30.74 30.74 0 01-18.74-8.63z"
              />
              <path
                d="M587.08 140.46c-4.48 10.94-3.11 22.73 9 29 7.35 3.81 16.53 2.75 23.87-1.08 9.92-5.16 19.54-15.28 22.55-26.2 2.64-9.55-3-18.25-11.35-23.16-16.83-9.9-37.24 4.78-44.07 21.44z"
                fill="url(#prefix__Degradado_sin_nombre_672-12)"
              />
              <path
                d="M472.8 268.88a61.17 61.17 0 0134.07 8.58c25 14.71 36.94 39.3 30.49 62.63-7.36 26.78-31.36 54.67-59.67 69.41a80.25 80.25 0 01-33.53 9.16 59.88 59.88 0 01-30.48-6.38C384.6 397.2 375.51 368 389.36 334.2c14.7-35.88 50.57-63.97 83.44-65.32zM444 414.25a75.91 75.91 0 0031.67-8.66c27.28-14.21 50.37-41 57.46-66.68 7.16-25.91-10-46.77-28.48-57.65a56.8 56.8 0 00-31.65-8c-31.25 1.28-65.45 28.19-79.54 62.59-12.89 31.46-4.56 58.56 22.27 72.49a55.63 55.63 0 0028.27 5.91z"
                fill="url(#prefix__Degradado_sin_nombre_25)"
              />
              <path
                d="M1700.11 618.84c6.54 8.4 16.38 12.61 26.69 6 6.28-4 9.56-11.69 9.86-19.15.4-10.08-3.21-22.13-10.4-29.38-6.29-6.34-15.63-5.81-23.21-1.49-15.26 8.68-12.92 31.18-2.94 44.02z"
                opacity={0.3}
                fill="#fadf9e"
              />
              <path
                d="M1698.51 452.06a21.26 21.26 0 018.46-8.79c8.74-5 18.21-4.35 24.13 1.62 6.79 6.85 11.06 18.88 10.62 29.95a27.86 27.86 0 01-3 11.68 20.71 20.71 0 01-7.18 8.08c-9.56 6.16-19.92 3.86-27.71-6.14-8.31-10.62-10.58-26.27-5.32-36.4zm38.83 33.75a26.54 26.54 0 002.85-11c.42-10.67-3.67-22.25-10.18-28.81s-15.82-5.05-22.28-1.37a19.68 19.68 0 00-7.86 8.16c-5 9.64-2.8 24.58 5.14 34.76 7.25 9.31 16.85 11.48 25.68 5.79a19.32 19.32 0 006.65-7.53z"
                fill="url(#prefix__Degradado_sin_nombre_27)"
              />
              <path
                d="M475.14 1696.5a349.42 349.42 0 01-3.95 46.7c-1.2 7.9-2.71 15.87-6.25 23s-9.38 13.5-16.95 16c-9.6 3.22-20 0-30.13-.56a56.14 56.14 0 00-48.93 25c-11.89-4.94-19.25-18.41-17.75-31.19 1.2-10.18 7.46-19 13.52-27.23 9.73-13.28 19.78-26.87 33.6-35.81 21.96-14.15 50.86-18.91 76.84-15.91z"
                fill="url(#prefix__Degradado_sin_nombre_29-4)"
              />
              <path
                d="M525.07 1560c15.62-6.82 30.18-10 41-8.81 13.87 1.46 26.17 9 34.63 21.12 9.62 13.82 13.1 32.2 9.08 48-4.74 18.58-14.86 35.66-23.16 48.46-23.62 36.41-64.07 85.23-122.53 110.77-2.9 1.27-5.87 2.49-8.81 3.61-20.15 7.74-37.14 10.18-51.92 7.46-15.81-2.9-33.43-14.06-37.16-32.46-3.57-17.61 6.95-38.09 28.13-54.76 5.44-4.29 11.29-8.29 16.94-12.17 11.89-8.16 24.19-16.6 33.12-27.91 8.49-10.75 13.65-23.76 18.63-36.33 5.22-13.14 10.61-26.72 19.81-37.89 8.49-10.31 24.68-21.47 42.24-29.09zm-61.74 217.84c58-25.32 98.2-73.87 121.69-110.14 8.22-12.68 18.24-29.58 22.91-47.89 3.89-15.25.52-33-8.79-46.41-8.14-11.69-20-18.91-33.27-20.31-10.49-1.11-24.7 2-40 8.66-17.31 7.55-33.23 18.51-41.54 28.6-9 10.94-14.34 24.38-19.5 37.38-5 12.7-10.25 25.84-18.91 36.81-9.11 11.54-21.53 20.06-33.54 28.3-5.63 3.86-11.44 7.85-16.84 12.1-20.61 16.22-30.86 36-27.44 52.88 3.55 17.52 20.46 28.18 35.64 31 14.42 2.64 31.07.24 50.89-7.37 2.88-1.19 5.82-2.4 8.7-3.66z"
                fill="url(#prefix__Degradado_sin_nombre_3-13)"
              />
              <path
                d="M411 543.26c7.53-24.54 18.23-44.25 30.14-55.48 15.28-14.42 35.91-21.11 58.11-18.86 25.22 2.57 49.63 16.62 63.71 36.69 16.58 23.62 26.17 51.95 32.66 74 18.52 62.79 34.67 156.92 6.5 248.74a339.3 339.3 0 01-4.52 13.62c-10.93 30.61-24.8 52.41-42.4 66.64-18.82 15.22-48.86 24.41-73.54 10.63-23.63-13.2-36.71-45.3-35-85.86.44-10.42 1.59-21 2.71-31.29 2.34-21.59 4.76-43.92.59-65.23-4-20.24-13.76-38.89-23.24-56.92-9.9-18.84-20.14-38.33-23.87-59.8-3.43-19.84-.31-49.29 8.15-76.88zm188.38 284.22c28-91.14 11.9-184.67-6.5-247.09-6.44-21.82-15.92-49.86-32.26-73.14-13.62-19.41-37.24-33-61.65-35.48-21.35-2.17-41.19 4.26-55.85 18.09-11.55 10.9-22 30.16-29.37 54.24-8.34 27.19-11.43 56.13-8.05 75.52 3.66 21 13.79 40.31 23.58 59 9.58 18.22 19.47 37.06 23.52 57.71 4.25 21.74 1.81 44.28-.56 66.08-1.1 10.22-2.25 20.78-2.69 31.11-1.67 39.46 10.85 70.57 33.52 83.22 23.51 13.14 52.26 4.27 70.34-10.34 17.17-13.89 30.75-35.28 41.49-65.38 1.59-4.47 3.1-9.02 4.48-13.54z"
                fill="url(#prefix__Degradado_sin_nombre_5)"
              />
              <path
                d="M1623.28 763.6c10.32-33.62 25-60.62 41.3-76 20.93-19.75 49.2-28.92 79.6-25.83 34.55 3.51 68 22.77 87.29 50.26 22.71 32.36 35.84 71.16 44.74 101.35 25.36 86 47.49 215 8.89 340.75-1.92 6.26-4 12.54-6.18 18.65-15 41.94-34 71.81-58.08 91.3-25.79 20.85-66.94 33.43-100.76 14.56-32.37-18.08-50.28-62-47.92-117.62.6-14.28 2.18-28.81 3.7-42.87 3.21-29.58 6.53-60.16.81-89.35-5.43-27.73-18.85-53.28-31.83-78-13.56-25.8-27.59-52.5-32.71-81.91-4.72-27.14-.45-67.49 11.15-105.29zm258.06 389.4c38.31-124.85 16.3-253-8.9-338.49-8.82-29.9-21.81-68.31-44.2-100.2-18.65-26.58-51-45.21-84.45-48.6-29.26-3-56.42 5.83-76.51 24.78-15.82 14.93-30.11 41.31-40.23 74.3-11.44 37.25-15.67 76.89-11 103.45 5 28.81 18.89 55.22 32.31 80.77 13.12 24.95 26.68 50.76 32.21 79.06 5.83 29.77 2.48 60.65-.75 90.52-1.52 14-3.09 28.47-3.69 42.62-2.3 54 14.87 96.67 45.91 114 32.21 18 71.6 5.84 96.36-14.18 23.53-19 42.12-48.32 56.85-89.56 2.13-6.09 4.19-12.31 6.09-18.47z"
                fill="url(#prefix__Degradado_sin_nombre_5-2)"
              />
              <path
                d="M520.81 1041c-18.77 69-45.58 125.09-75.47 158-38.34 42.26-90.24 64.07-146.12 61.42-63.51-3-125.07-37.87-160.67-91-41.92-62.54-66.26-139.18-82.78-199C8.68 800.18-32.7 543 37.53 284.94c3.49-12.84 7.28-25.73 11.26-38.31 27.29-86.27 62-148.69 106.22-190.8 47.27-45 122.82-75.25 185.06-41.19 59.58 32.62 92.75 119.11 88.72 231.36-1 28.84-3.85 58.32-6.57 86.82-5.73 60-11.65 122-1 180.14 10.13 55.23 34.94 105.14 58.93 153.4 25.06 50.41 51 102.54 60.55 161.21 8.85 54.12 1.22 135.92-19.89 213.43zM44.45 286.83c-69.71 256.09-28.55 511.69 18.24 681 16.37 59.21 40.46 135.08 81.77 196.72 34.42 51.36 94 85.08 155.47 88 53.76 2.54 103.64-18.39 140.44-58.94 29-31.95 55.11-86.8 73.52-154.45 20.8-76.41 28.35-156.79 19.7-209.77-9.38-57.46-35-109-59.82-158.94-24.24-48.74-49.3-99.15-59.63-155.52-10.88-59.31-4.9-121.93.89-182.5 2.71-28.38 5.51-57.72 6.54-86.31 3.92-109.19-27.86-193-85-224.33C277.28-10.66 205 18.45 159.58 61.7 116.45 102.81 82.44 164 55.61 248.86c-3.94 12.47-7.7 25.25-11.16 37.97z"
                fill="url(#prefix__Degradado_sin_nombre_23-6)"
              />
              <path
                d="M393.64 1844.28a31.41 31.41 0 0028 1.56 28.92 28.92 0 0016.68-22.31 36.64 36.64 0 00-1.22-13.61c-2.37-9.06-7.8-18.11-16.62-21.23-7.88-2.79-16.65-.25-24.2 3.33-10.36 4.92-20.69 13.46-21.16 24.91-.34 8 4.32 15.37 9.31 21.67s12 12.66 19.83 10.94"
                fill="url(#prefix__Degradado_sin_nombre_3-14)"
              />
              <path
                d="M1623.69 411.93c6.55 8.4 16.39 12.61 26.7 6 6.28-4 9.56-11.69 9.85-19.15.4-10.07-3.2-22.12-10.39-29.38-6.29-6.34-15.63-5.81-23.21-1.49-15.26 8.67-12.93 31.21-2.95 44.02z"
                fill="url(#prefix__Degradado_sin_nombre_3-15)"
              />
            </g>
          </g>
        </svg>
      </div>
      <div className="wrapper" >
        <div className="page-title" >This is why SeeRRI brings in a solid conceptual framework...</div>
        <div className="content" >
          <h2 className="content-title" >Interdependencies: </h2>
          <p>Human and natural systems are complex. They exhibit “emergent” behaviours, not reducible to those of their parts. System vitality depends on the interdependencies between the parts and with related systems. RRI is the recognition of interdependencies between R&I processes and: the way they are framed (Reflexivity); society at large (Inclusion); the evolution of societal values, needs and expectations (Responsiveness); the future consequences of our actions (Anticipation). Putting interdependencies at the core helps to ask questions relevant to more than just individual actors and understand better the overall dynamics of evolution.</p>
          <h2 className="content-title" >Innovation ecosystems: </h2>
          <p>R&I activities do not happen as if they were external to society, and are not the product of individual entities, whatever the importance of certain individuals and organizations. R&I ecosystems involve whole chains of actors and processes immersed in society, linked together through nutrient cycles and energy flows, as in biological ecosystems. The vitality of R&I ecosystems in territorial contexts requires the participation of multiple actors, R&I organizations as well as public administrations, educational institutions, the entrepreneurial class, civil society organizations and citizens at large. </p>
          <h2 className="content-title" >Transformative drive: </h2>
          <p>RRI can be leveraged to reframe the roles of science, innovation and technology in response to existential challenges, and hence to ignite processes of social transformation in democratic ways. This requires considering territorial R&I activities as whole ecosystems. To that end the Process Dimensions of RRI are useful: Reflexivity, Inclusion, Responsiveness, and Anticipation are qualities of responsible processes taking into account interdependencies. A decade after its inception, RRI shows its responsiveness to the deficits in the global R&I system that inhibit innovations delivering on socially desirable objectives.</p>
          <h2 className="content-title" >Communities working together:</h2>
          <p> People are part of communities tied together by geography, history, culture and shared activities or goals. Global R&I centres produce waves of innovation to which local communities had usually no option but to adapt. But RRI is about aligning R&I with social values, needs and expectations which are intrinsically local. SeeRRI gives a central role to communities and mobilizes them around shared visions and agendas towards sustainable futures. Then R&I processes become a capacity to achieve those visions, and their intertwining with local and regional dynamics adopts a different meaning, as an enabler of autonomous pathways. </p>
          <h2 className="content-title" >Wellbeing within the biosphere:</h2>
          <p>The aspiration to equitable human wellbeing within a healthy biosphere is widely shared. But for now it is an aspiration. Scientific capacities can contribute to the many challenges of sustainability, but R&I activities are not aligned with that purpose. RRI cannot be conceived in our time without addressing this gap. This framing is useful at different scales. At territorial level it can mobilize communities and help understand the values, needs and expectations to which R&I should respond. And local and regional learnings can feed larger processes happening at national, EU and global levels</p>
          <h2 className="content-title" >Mutual learning: </h2>
          <p>Transformation towards sustainable wellbeing through RRI is an  exploratory process, a learning adventure in which we do not exactly know in advance what or how we are going to learn. This is how complex systems can change, by creating the conditions for the actors to start thinking differently and learn together how to create pathways towards a shared vision. Mutual learning is a central piece of SeeRRI, and it is facilitated through a number of methods and tools. Ultimately, learning happens through endless cycles of questions and responses, which make patterns evolve.</p>
        </div>
        <div className="back-button" onClick={goBack} > 
          <img src={process.env.PUBLIC_URL + "/assets/back-arrow.svg"} alt="back" /> BACK
        </div>
      </div>
    </div>
  )
}

export default InterdependenciesIntroPage
