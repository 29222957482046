import React from 'react'
import { Html } from '@react-three/drei'

import './LoadingIndicator.css'

const LoadingIndicator = () => {
  return (
    <Html>
      <div className="loader">Loading...</div>
    </Html>
  )
}

export default LoadingIndicator
