import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
//import reportWebVitals from './reportWebVitals';
import { Router } from "react-router";
import { Route } from "react-router-dom";
import { createBrowserHistory } from "history";

const customHistory = createBrowserHistory({
  // basename: config.urlBasename || ""
});

// remember to modify the ID on /public/index.html to use the dev environment
const target = document.getElementById('wedo-root');

if (target) {
  ReactDOM.render(
      <Router history={customHistory}>
        <Route
          component={({ history }) => {
            window.appHistory = history;
            return <App />;
          }}
        />
      </Router>,
    target
  );
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
