import './App.css';
import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation
} from "react-router-dom";
import { TransitionGroup, CSSTransition } from 'react-transition-group'

import DropdownNav from './Components/DropdownNav/DropdownNav';
import SeerriLogo from './Components/SeerriLogo/SeerriLogo';

import NavStateProvider from './Providers/NavStateProvider';

import IntroShapesPage from './Pages/IntroShapesPage';
import ComplexityIntroPage from './Pages/ComplexityIntroPage/ComplexityIntroPage';
import InterdependenciesIntroPage from './Pages/InterdependenciesIntroPage/InterdependenciesIntroPage';
import SpecializationIntroPage from './Pages/Specialization/SpecializationIntroPage';
import InequalitiesIntroPage from './Pages/Inequalities/InequalitiesIntroPage';
import EndingPage from './Pages/EndingPage/EndingPage';

function App() {

  const location = useLocation()

  return (
    <div className="Wedo3dModule">
      <SeerriLogo></SeerriLogo>
      <DropdownNav></DropdownNav>
      <NavStateProvider>
        <Router> 
          <TransitionGroup className="transition-group-container" >
            <CSSTransition
              timeout={1000}
              classNames="fade"
              key={location.key}
            >
              <Switch location={location} >
                <Route exact path="/">
                  <IntroShapesPage></IntroShapesPage>
                </Route>
                <Route exact path="/complexity-is-everywhere"> 
                  <ComplexityIntroPage></ComplexityIntroPage>
                </Route>
                <Route exact path="/interdependencies"> 
                  <InterdependenciesIntroPage></InterdependenciesIntroPage>
                </Route>
                <Route exact path="/smart-specialization-strategies"> 
                  <SpecializationIntroPage></SpecializationIntroPage>
                </Route>
                <Route exact path="/social-inequalities"> 
                  <InequalitiesIntroPage></InequalitiesIntroPage>
                </Route>
                <Route exact path="/ending" >
                  <EndingPage></EndingPage>
                </Route>
              </Switch>
            </CSSTransition>
          </TransitionGroup>
        </Router>
      </NavStateProvider>
    </div>
  );
}

export default App;
