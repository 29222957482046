import React, { useEffect, useRef } from 'react'
import anime from 'animejs'

const EndingContent = () => {

  const pathAnimationRef = useRef()

  useEffect(() => {

    pathAnimationRef.current = anime({
      targets: '#ending-content svg path.cls-1',
      easing: 'easeInOutCubic',
      duration: 1000,
      opacity: 1,
      delay: anime.stagger(30),
      begin: function(anim) {},
      complete: function(anim) {},
      autoplay: true
    })

  }, [])

  return (
    <div id="ending-content" >
      <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 485.53 580.94">
        <defs>
          <style>{".cls-1{fill:#305768}"}</style>
        </defs>
        <title>pathway-ending-text</title>
        <g id="Layer_2" data-name="Layer 2">
          <g id="Capa_1" data-name="Capa 1">
            <path className="cls-1" d="M41.18,1.29a.68.68,0,0,1,.13-.42.42.42,0,0,1,.37-.19h8a.43.43,0,0,1,.37.18.65.65,0,0,1,.13.41.72.72,0,0,1-.13.44.42.42,0,0,1-.37.19H46.36V14.78a.44.44,0,0,1-.2.38.81.81,0,0,1-.46.14.79.79,0,0,1-.47-.14.44.44,0,0,1-.19-.38V1.9H41.68a.43.43,0,0,1-.36-.18A.67.67,0,0,1,41.18,1.29Z"/>
            <path className="cls-1" d="M51.76,14.78V.54A.42.42,0,0,1,51.94.2.74.74,0,0,1,52.4.06a.79.79,0,0,1,.47.14.4.4,0,0,1,.19.34V7.26A3,3,0,0,1,55.7,5.72a3.34,3.34,0,0,1,2.49,1.1,3.68,3.68,0,0,1,1.05,2.64v5.32a.44.44,0,0,1-.19.38.79.79,0,0,1-.47.14.77.77,0,0,1-.44-.14.44.44,0,0,1-.2-.38V9.47a2.49,2.49,0,0,0-.74-1.75,2.34,2.34,0,0,0-3.41-.08,2.4,2.4,0,0,0-.73,1.83v5.31a.48.48,0,0,1-.2.36.7.7,0,0,1-.46.16.67.67,0,0,1-.45-.16A.47.47,0,0,1,51.76,14.78Z"/>
            <path className="cls-1" d="M61,12.8a2.25,2.25,0,0,1,1.19-2.19A7.76,7.76,0,0,1,65.68,10h1V9.53a3.09,3.09,0,0,0-.58-1.88,2.08,2.08,0,0,0-1.78-.79,3.57,3.57,0,0,0-1.45.28c-.44.19-.66.29-.69.29a.42.42,0,0,1-.38-.22.86.86,0,0,1-.14-.47c0-.25.29-.49.86-.7a5.31,5.31,0,0,1,1.84-.32,3.3,3.3,0,0,1,2.75,1.09A4.31,4.31,0,0,1,68,9.58v5.2a.46.46,0,0,1-.18.37.69.69,0,0,1-.46.15.63.63,0,0,1-.43-.15.48.48,0,0,1-.17-.37V13.9a3.93,3.93,0,0,1-3,1.54,2.84,2.84,0,0,1-2-.67A2.48,2.48,0,0,1,61,12.8Zm1.3-.12a1.55,1.55,0,0,0,.48,1.25,1.89,1.89,0,0,0,1.26.41,2.76,2.76,0,0,0,1.79-.7,1.9,1.9,0,0,0,.85-1.4V11h-.84a12.84,12.84,0,0,0-1.35.06,5.85,5.85,0,0,0-1.09.22,1.6,1.6,0,0,0-.82.52A1.44,1.44,0,0,0,62.32,12.68Z"/>
            <path className="cls-1" d="M70.44,14.78V6.32A.41.41,0,0,1,70.62,6a.74.74,0,0,1,.46-.13A.68.68,0,0,1,71.5,6a.44.44,0,0,1,.18.35v.93a2.85,2.85,0,0,1,1-1.09,3,3,0,0,1,1.64-.45,3.38,3.38,0,0,1,2.5,1.1,3.65,3.65,0,0,1,1.06,2.64v5.32a.44.44,0,0,1-.2.38.83.83,0,0,1-.46.14.77.77,0,0,1-.44-.14.44.44,0,0,1-.2-.38V9.47a2.49,2.49,0,0,0-.74-1.75,2.34,2.34,0,0,0-1.76-.78,2.29,2.29,0,0,0-1.66.7,2.43,2.43,0,0,0-.72,1.83v5.31a.48.48,0,0,1-.2.36.7.7,0,0,1-.46.16.65.65,0,0,1-.46-.16A.48.48,0,0,1,70.44,14.78Z"/>
            <path className="cls-1" d="M80.36,14.78V.52a.42.42,0,0,1,.18-.34A.74.74,0,0,1,81,0a.79.79,0,0,1,.47.14.43.43,0,0,1,.19.34v9.74l4.88-4.34a.65.65,0,0,1,.3-.12.65.65,0,0,1,.45.19.57.57,0,0,1,.21.43.46.46,0,0,1-.14.36L84.42,9.36l3.46,5.06a.71.71,0,0,1,.08.28.77.77,0,0,1-.7.66.46.46,0,0,1-.4-.24L83.5,10.18,81.66,11.8v3a.5.5,0,0,1-.19.36.69.69,0,0,1-.47.16.65.65,0,0,1-.45-.16A.47.47,0,0,1,80.36,14.78Z"/>
            <path className="cls-1" d="M88.88,14a.72.72,0,0,1,.14-.4.4.4,0,0,1,.32-.2,1,1,0,0,1,.44.23,4.35,4.35,0,0,0,.86.47,3.38,3.38,0,0,0,1.34.24,2.47,2.47,0,0,0,1.56-.4,1.27,1.27,0,0,0,.5-1,1.43,1.43,0,0,0-.51-1.15,3.44,3.44,0,0,0-1.23-.65l-1.45-.44a2.72,2.72,0,0,1-1.24-.77,2.09,2.09,0,0,1-.51-1.47,2.64,2.64,0,0,1,.79-1.91,3.17,3.17,0,0,1,2.37-.81A5.25,5.25,0,0,1,94.09,6c.57.21.85.43.85.67a.79.79,0,0,1-.16.44.47.47,0,0,1-.38.24,5.24,5.24,0,0,1-.74-.28,3.55,3.55,0,0,0-1.38-.28,2.12,2.12,0,0,0-1.5.47,1.44,1.44,0,0,0-.5,1.06,1.11,1.11,0,0,0,.52,1A4.43,4.43,0,0,0,92,9.87l1.46.44a2.33,2.33,0,0,1,1.24.87,2.69,2.69,0,0,1,.5,1.68,2.32,2.32,0,0,1-.85,1.89,3.68,3.68,0,0,1-2.37.69A4.87,4.87,0,0,1,89.79,15C89.18,14.66,88.88,14.33,88.88,14Z"/>
            <path className="cls-1" d="M101.14,12.36V1.84a.39.39,0,0,1,.2-.34.77.77,0,0,1,.44-.14.81.81,0,0,1,.46.14.42.42,0,0,1,.2.34v4H105a.41.41,0,0,1,.33.16.65.65,0,0,1,0,.78.41.41,0,0,1-.33.16h-2.58v5.39a1.83,1.83,0,0,0,.41,1.36,2.1,2.1,0,0,0,1.45.39h.56a.57.57,0,0,1,.43.18.57.57,0,0,1,.17.42.61.61,0,0,1-.17.43.54.54,0,0,1-.43.19h-.56Q101.14,15.3,101.14,12.36Z"/>
            <path className="cls-1" d="M106.56,11.64V9.46a3.57,3.57,0,0,1,1.12-2.63,3.65,3.65,0,0,1,2.66-1.11A3.74,3.74,0,0,1,113,6.82a3.53,3.53,0,0,1,1.14,2.64v2.18A3.63,3.63,0,0,1,113,14.29a3.66,3.66,0,0,1-2.67,1.15,3.6,3.6,0,0,1-2.64-1.15A3.66,3.66,0,0,1,106.56,11.64Zm1.3,0a2.54,2.54,0,0,0,.73,1.8,2.29,2.29,0,0,0,1.75.79,2.34,2.34,0,0,0,1.78-.79,2.55,2.55,0,0,0,.74-1.8V9.47a2.44,2.44,0,0,0-.75-1.75,2.38,2.38,0,0,0-1.77-.78,2.32,2.32,0,0,0-1.75.78,2.46,2.46,0,0,0-.73,1.75Z"/>
            <path className="cls-1" d="M120.26,12.36V1.84a.42.42,0,0,1,.2-.34.77.77,0,0,1,.44-.14.81.81,0,0,1,.46.14.42.42,0,0,1,.2.34v4h2.58a.41.41,0,0,1,.33.16.65.65,0,0,1,0,.78.41.41,0,0,1-.33.16h-2.58v5.39a1.83,1.83,0,0,0,.41,1.36,2.1,2.1,0,0,0,1.45.39H124a.57.57,0,0,1,.43.18.57.57,0,0,1,.17.42.61.61,0,0,1-.17.43.54.54,0,0,1-.43.19h-.56Q120.26,15.3,120.26,12.36Z"/>
            <path className="cls-1" d="M126.24,14.78V.54a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.79.79,0,0,1,.47.14.43.43,0,0,1,.19.34V7.26a2.82,2.82,0,0,1,1-1.09,2.89,2.89,0,0,1,1.61-.45,3.34,3.34,0,0,1,2.49,1.1,3.68,3.68,0,0,1,1.05,2.64v5.32a.44.44,0,0,1-.19.38.79.79,0,0,1-.47.14.77.77,0,0,1-.44-.14.44.44,0,0,1-.2-.38V9.47a2.49,2.49,0,0,0-.74-1.75,2.34,2.34,0,0,0-3.41-.08,2.4,2.4,0,0,0-.73,1.83v5.31a.48.48,0,0,1-.2.36.72.72,0,0,1-.46.16.64.64,0,0,1-.45-.16A.47.47,0,0,1,126.24,14.78Z"/>
            <path className="cls-1" d="M135.8,11.64V9.4a3.48,3.48,0,0,1,1.12-2.59,3.87,3.87,0,0,1,5.33,0,3.43,3.43,0,0,1,1.11,2.6,2.45,2.45,0,0,1-.13.9.84.84,0,0,1-.42.48,2.21,2.21,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0H137.1v.74a2.32,2.32,0,0,0,.85,1.89,3.7,3.7,0,0,0,3.38.51,3.47,3.47,0,0,0,.78-.4.84.84,0,0,1,.43-.2.5.5,0,0,1,.39.21.69.69,0,0,1,.17.41c0,.28-.29.57-.86.87a4.58,4.58,0,0,1-2.14.45,4.53,4.53,0,0,1-3.1-1A3.48,3.48,0,0,1,135.8,11.64ZM137.1,10h4a1.62,1.62,0,0,0,.8-.13.63.63,0,0,0,.2-.55,2.47,2.47,0,0,0-.71-1.74,2.33,2.33,0,0,0-1.79-.76,2.44,2.44,0,0,0-1.77.72,2.27,2.27,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M149.32,14.8V3.69a3.6,3.6,0,0,1,1.06-2.6A3.35,3.35,0,0,1,152.88,0h.5a.5.5,0,0,1,.4.19.65.65,0,0,1,.16.43.61.61,0,0,1-.16.42.52.52,0,0,1-.4.18H153a2.3,2.3,0,0,0-1.71.71,2.4,2.4,0,0,0-.71,1.69V5.84h2.86a.41.41,0,0,1,.33.16.56.56,0,0,1,.13.37.63.63,0,0,1-.13.41.41.41,0,0,1-.33.16h-2.86V14.8a.42.42,0,0,1-.2.36.83.83,0,0,1-.48.14.72.72,0,0,1-.44-.14A.43.43,0,0,1,149.32,14.8Z"/>
            <path className="cls-1" d="M154.7,11.64V9.46a3.57,3.57,0,0,1,1.12-2.63,3.63,3.63,0,0,1,2.66-1.11,3.76,3.76,0,0,1,2.68,1.1,3.53,3.53,0,0,1,1.14,2.64v2.18a3.63,3.63,0,0,1-1.15,2.65,3.66,3.66,0,0,1-2.67,1.15,3.6,3.6,0,0,1-2.64-1.15A3.66,3.66,0,0,1,154.7,11.64Zm1.3,0a2.54,2.54,0,0,0,.73,1.8,2.28,2.28,0,0,0,1.75.79,2.36,2.36,0,0,0,1.78-.79,2.55,2.55,0,0,0,.74-1.8V9.47a2.44,2.44,0,0,0-.75-1.75,2.38,2.38,0,0,0-1.77-.78,2.3,2.3,0,0,0-1.75.78A2.46,2.46,0,0,0,156,9.47Z"/>
            <path className="cls-1" d="M164.34,11.68V6.34a.45.45,0,0,1,.19-.34.67.67,0,0,1,.47-.16.68.68,0,0,1,.44.16.46.46,0,0,1,.2.34v5.33a2.48,2.48,0,0,0,.74,1.77,2.38,2.38,0,0,0,3.52,0,2.52,2.52,0,0,0,.74-1.77V6.32a.39.39,0,0,1,.2-.34.78.78,0,0,1,.46-.14.77.77,0,0,1,.45.14.43.43,0,0,1,.19.34v5.36a3.61,3.61,0,0,1-1.13,2.63,3.69,3.69,0,0,1-2.67,1.13,3.64,3.64,0,0,1-2.68-1.13A3.6,3.6,0,0,1,164.34,11.68Z"/>
            <path className="cls-1" d="M174.36,14.78V6.32a.41.41,0,0,1,.18-.35.74.74,0,0,1,.46-.13.68.68,0,0,1,.42.14.44.44,0,0,1,.18.35v.93a2.77,2.77,0,0,1,1-1.09,2.94,2.94,0,0,1,1.64-.45,3.38,3.38,0,0,1,2.5,1.1,3.69,3.69,0,0,1,1.06,2.64v5.32a.44.44,0,0,1-.2.38.83.83,0,0,1-.46.14.77.77,0,0,1-.44-.14.42.42,0,0,1-.2-.38V9.47a2.49,2.49,0,0,0-.74-1.75A2.36,2.36,0,0,0,178,6.94a2.29,2.29,0,0,0-1.66.7,2.43,2.43,0,0,0-.72,1.83v5.31a.48.48,0,0,1-.2.36.72.72,0,0,1-.46.16.65.65,0,0,1-.46-.16A.48.48,0,0,1,174.36,14.78Z"/>
            <path className="cls-1" d="M183.92,11.64V9.46A3.68,3.68,0,0,1,185,6.82a3.38,3.38,0,0,1,2.53-1.1,2.92,2.92,0,0,1,2.64,1.54V.54a.39.39,0,0,1,.2-.34.83.83,0,0,1,.48-.14.73.73,0,0,1,.45.14.44.44,0,0,1,.17.34V14.78a.48.48,0,0,1-.18.36.63.63,0,0,1-.44.16.62.62,0,0,1-.43-.16.47.47,0,0,1-.19-.37v-.89a2.87,2.87,0,0,1-1.09,1.1,3.07,3.07,0,0,1-1.65.46A3.25,3.25,0,0,1,185,14.29,3.79,3.79,0,0,1,183.92,11.64Zm1.3,0a2.52,2.52,0,0,0,.74,1.79,2.3,2.3,0,0,0,1.74.8,2.38,2.38,0,0,0,1.7-.72,1.94,1.94,0,0,0,.74-1.25V9.47a2.42,2.42,0,0,0-.73-1.83,2.38,2.38,0,0,0-3.45.08,2.43,2.43,0,0,0-.74,1.75Z"/>
            <path className="cls-1" d="M193.3,12.8a2.24,2.24,0,0,1,1.19-2.19A7.76,7.76,0,0,1,198,10h1V9.53a3.16,3.16,0,0,0-.58-1.88,2.08,2.08,0,0,0-1.78-.79,3.54,3.54,0,0,0-1.45.28l-.69.29a.42.42,0,0,1-.38-.22.79.79,0,0,1-.14-.47c0-.25.28-.49.86-.7a5.27,5.27,0,0,1,1.84-.32,3.3,3.3,0,0,1,2.75,1.09,4.31,4.31,0,0,1,.87,2.77v5.2a.46.46,0,0,1-.18.37.71.71,0,0,1-.46.15.61.61,0,0,1-.43-.15.45.45,0,0,1-.17-.37V13.9a3.94,3.94,0,0,1-3,1.54,2.83,2.83,0,0,1-2-.67A2.48,2.48,0,0,1,193.3,12.8Zm1.3-.12a1.55,1.55,0,0,0,.48,1.25,1.89,1.89,0,0,0,1.26.41,2.78,2.78,0,0,0,1.79-.7,1.93,1.93,0,0,0,.85-1.4V11h-.84a12.58,12.58,0,0,0-1.35.06,5.85,5.85,0,0,0-1.09.22,1.56,1.56,0,0,0-.82.52A1.44,1.44,0,0,0,194.6,12.68Z"/>
            <path className="cls-1" d="M202.68,12.36V1.84a.39.39,0,0,1,.2-.34.77.77,0,0,1,.44-.14.83.83,0,0,1,.46.14.42.42,0,0,1,.2.34v4h2.58a.41.41,0,0,1,.33.16.65.65,0,0,1,0,.78.41.41,0,0,1-.33.16H204v5.39a1.78,1.78,0,0,0,.41,1.36,2.08,2.08,0,0,0,1.45.39h.56a.58.58,0,0,1,.43.18.57.57,0,0,1,.17.42.61.61,0,0,1-.17.43.56.56,0,0,1-.43.19h-.56Q202.68,15.3,202.68,12.36Z"/>
            <path className="cls-1" d="M208.66,2.79a.77.77,0,0,1-.26-.59.73.73,0,0,1,.26-.58.89.89,0,0,1,.64-.24.85.85,0,0,1,.61.24.79.79,0,0,1,.25.58.83.83,0,0,1-.25.59.82.82,0,0,1-.61.25A.86.86,0,0,1,208.66,2.79Zm0,12V6.32a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.82.82,0,0,1,.47.14.43.43,0,0,1,.19.34v8.46a.48.48,0,0,1-.2.36.72.72,0,0,1-.46.16.64.64,0,0,1-.45-.16A.47.47,0,0,1,208.66,14.78Z"/>
            <path className="cls-1" d="M212.1,11.64V9.46a3.57,3.57,0,0,1,1.12-2.63,3.63,3.63,0,0,1,2.66-1.11,3.76,3.76,0,0,1,2.68,1.1,3.53,3.53,0,0,1,1.14,2.64v2.18a3.67,3.67,0,0,1-1.15,2.65,3.66,3.66,0,0,1-2.67,1.15,3.6,3.6,0,0,1-2.64-1.15A3.66,3.66,0,0,1,212.1,11.64Zm1.3,0a2.54,2.54,0,0,0,.73,1.8,2.28,2.28,0,0,0,1.75.79,2.36,2.36,0,0,0,1.78-.79,2.55,2.55,0,0,0,.74-1.8V9.47a2.44,2.44,0,0,0-.75-1.75,2.38,2.38,0,0,0-1.77-.78,2.3,2.3,0,0,0-1.75.78,2.46,2.46,0,0,0-.73,1.75Z"/>
            <path className="cls-1" d="M221.84,14.78V6.32A.41.41,0,0,1,222,6a.74.74,0,0,1,.46-.13.68.68,0,0,1,.42.14.44.44,0,0,1,.18.35v.93a2.77,2.77,0,0,1,1-1.09,2.94,2.94,0,0,1,1.64-.45,3.38,3.38,0,0,1,2.5,1.1,3.69,3.69,0,0,1,1.06,2.64v5.32a.44.44,0,0,1-.2.38.83.83,0,0,1-.46.14.77.77,0,0,1-.44-.14.42.42,0,0,1-.2-.38V9.47a2.49,2.49,0,0,0-.74-1.75,2.36,2.36,0,0,0-1.76-.78,2.29,2.29,0,0,0-1.66.7,2.43,2.43,0,0,0-.72,1.83v5.31a.48.48,0,0,1-.2.36.72.72,0,0,1-.46.16.65.65,0,0,1-.46-.16A.48.48,0,0,1,221.84,14.78Z"/>
            <path className="cls-1" d="M231.16,14a.65.65,0,0,1,.14-.4.4.4,0,0,1,.32-.2,1,1,0,0,1,.44.23,4.08,4.08,0,0,0,.86.47,3.38,3.38,0,0,0,1.34.24,2.49,2.49,0,0,0,1.56-.4,1.27,1.27,0,0,0,.5-1,1.43,1.43,0,0,0-.51-1.15,3.44,3.44,0,0,0-1.23-.65l-1.45-.44a2.72,2.72,0,0,1-1.24-.77,2.09,2.09,0,0,1-.51-1.47,2.64,2.64,0,0,1,.79-1.91,3.15,3.15,0,0,1,2.37-.81,5.25,5.25,0,0,1,1.83.31c.56.21.85.43.85.67a.88.88,0,0,1-.16.44.47.47,0,0,1-.38.24,5.24,5.24,0,0,1-.74-.28,3.58,3.58,0,0,0-1.38-.28,2.12,2.12,0,0,0-1.5.47,1.4,1.4,0,0,0-.5,1.06,1.12,1.12,0,0,0,.51,1,4.57,4.57,0,0,0,1.24.56l1.47.44a2.37,2.37,0,0,1,1.24.87,2.69,2.69,0,0,1,.5,1.68,2.32,2.32,0,0,1-.85,1.89,3.68,3.68,0,0,1-2.37.69,4.84,4.84,0,0,1-2.23-.47C231.46,14.66,231.16,14.33,231.16,14Z"/>
            <path className="cls-1" d="M243.12,11.64V9.5a3.81,3.81,0,0,1,1-2.73,3.74,3.74,0,0,1,2.88-1,4.21,4.21,0,0,1,2,.43q.81.44.81.87a.65.65,0,0,1-.18.43.53.53,0,0,1-.4.21.75.75,0,0,1-.37-.18,4.26,4.26,0,0,0-.71-.35,2.85,2.85,0,0,0-1.1-.19,2.61,2.61,0,0,0-1.95.68,2.55,2.55,0,0,0-.67,1.89v2.12a2.57,2.57,0,0,0,.67,1.91,2.64,2.64,0,0,0,2,.68,3,3,0,0,0,1.15-.19,3,3,0,0,0,.72-.37.73.73,0,0,1,.39-.18.57.57,0,0,1,.43.2.67.67,0,0,1,.17.44c0,.31-.28.6-.84.89a4.51,4.51,0,0,1-2.08.43,3.81,3.81,0,0,1-2.9-1A3.82,3.82,0,0,1,243.12,11.64Z"/>
            <path className="cls-1" d="M251.68,14.78V6.32a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.72.72,0,0,1,.44.14.44.44,0,0,1,.18.35v1A3.08,3.08,0,0,1,254,6.19a2.83,2.83,0,0,1,1.62-.47h.7a.48.48,0,0,1,.36.18.66.66,0,0,1,0,.86.48.48,0,0,1-.36.18h-.7a2.5,2.5,0,0,0-1.84.81,2.83,2.83,0,0,0-.8,2.1v4.93a.5.5,0,0,1-.19.36.71.71,0,0,1-.47.16.64.64,0,0,1-.45-.16A.47.47,0,0,1,251.68,14.78Z"/>
            <path className="cls-1" d="M257.58,11.64V9.4a3.48,3.48,0,0,1,1.12-2.59,3.7,3.7,0,0,1,2.7-1.09,3.74,3.74,0,0,1,2.63,1,3.43,3.43,0,0,1,1.11,2.6,2.68,2.68,0,0,1-.13.9.84.84,0,0,1-.42.48,2.37,2.37,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0h-4.52v.74a2.32,2.32,0,0,0,.85,1.89,3.29,3.29,0,0,0,2.19.71,3.33,3.33,0,0,0,1.19-.2,3.47,3.47,0,0,0,.78-.4.84.84,0,0,1,.43-.2.51.51,0,0,1,.39.21.69.69,0,0,1,.17.41c0,.28-.29.57-.86.87a4.61,4.61,0,0,1-2.14.45,4.53,4.53,0,0,1-3.1-1A3.48,3.48,0,0,1,257.58,11.64Zm1.3-1.64h4a1.62,1.62,0,0,0,.8-.13.63.63,0,0,0,.2-.55,2.47,2.47,0,0,0-.71-1.74,2.34,2.34,0,0,0-1.79-.76,2.41,2.41,0,0,0-1.77.72,2.27,2.27,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M266.44,12.8a2.24,2.24,0,0,1,1.19-2.19A7.76,7.76,0,0,1,271.1,10h1V9.53a3.16,3.16,0,0,0-.58-1.88,2.08,2.08,0,0,0-1.78-.79,3.54,3.54,0,0,0-1.45.28l-.69.29a.42.42,0,0,1-.38-.22.79.79,0,0,1-.14-.47c0-.25.28-.49.86-.7a5.27,5.27,0,0,1,1.84-.32,3.3,3.3,0,0,1,2.75,1.09,4.31,4.31,0,0,1,.87,2.77v5.2a.46.46,0,0,1-.18.37.71.71,0,0,1-.46.15.61.61,0,0,1-.43-.15.45.45,0,0,1-.17-.37V13.9a3.94,3.94,0,0,1-3,1.54,2.83,2.83,0,0,1-2-.67A2.48,2.48,0,0,1,266.44,12.8Zm1.3-.12a1.55,1.55,0,0,0,.48,1.25,1.89,1.89,0,0,0,1.26.41,2.78,2.78,0,0,0,1.79-.7,1.93,1.93,0,0,0,.85-1.4V11h-.84a12.58,12.58,0,0,0-1.35.06,5.85,5.85,0,0,0-1.09.22,1.56,1.56,0,0,0-.82.52A1.44,1.44,0,0,0,267.74,12.68Z"/>
            <path className="cls-1" d="M275.82,12.36V1.84a.39.39,0,0,1,.2-.34.77.77,0,0,1,.44-.14.83.83,0,0,1,.46.14.42.42,0,0,1,.2.34v4h2.58A.41.41,0,0,1,280,6a.65.65,0,0,1,0,.78.41.41,0,0,1-.33.16h-2.58v5.39a1.78,1.78,0,0,0,.41,1.36,2.08,2.08,0,0,0,1.45.39h.56a.58.58,0,0,1,.43.18.57.57,0,0,1,.17.42.61.61,0,0,1-.17.43.56.56,0,0,1-.43.19H279Q275.82,15.3,275.82,12.36Z"/>
            <path className="cls-1" d="M281.26,11.64V9.4a3.48,3.48,0,0,1,1.12-2.59,3.7,3.7,0,0,1,2.7-1.09,3.74,3.74,0,0,1,2.63,1,3.43,3.43,0,0,1,1.11,2.6,2.68,2.68,0,0,1-.13.9.84.84,0,0,1-.42.48,2.37,2.37,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0h-4.52v.74a2.32,2.32,0,0,0,.85,1.89,3.29,3.29,0,0,0,2.19.71,3.33,3.33,0,0,0,1.19-.2,3.47,3.47,0,0,0,.78-.4.84.84,0,0,1,.43-.2.51.51,0,0,1,.39.21.69.69,0,0,1,.17.41c0,.28-.29.57-.86.87a4.61,4.61,0,0,1-2.14.45,4.53,4.53,0,0,1-3.1-1A3.48,3.48,0,0,1,281.26,11.64Zm1.3-1.64h4a1.62,1.62,0,0,0,.8-.13.63.63,0,0,0,.2-.55,2.47,2.47,0,0,0-.71-1.74,2.34,2.34,0,0,0-1.79-.76,2.41,2.41,0,0,0-1.77.72,2.27,2.27,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M290.42,11.64V9.46a3.68,3.68,0,0,1,1.05-2.64A3.38,3.38,0,0,1,294,5.72a2.92,2.92,0,0,1,2.64,1.54V.54a.39.39,0,0,1,.2-.34.83.83,0,0,1,.48-.14.73.73,0,0,1,.45.14.44.44,0,0,1,.17.34V14.78a.48.48,0,0,1-.18.36.63.63,0,0,1-.44.16.62.62,0,0,1-.43-.16.47.47,0,0,1-.19-.37v-.89a2.87,2.87,0,0,1-1.09,1.1,3.07,3.07,0,0,1-1.65.46,3.25,3.25,0,0,1-2.48-1.15A3.79,3.79,0,0,1,290.42,11.64Zm1.3,0a2.52,2.52,0,0,0,.74,1.79,2.3,2.3,0,0,0,1.74.8,2.38,2.38,0,0,0,1.7-.72,1.94,1.94,0,0,0,.74-1.25V9.47a2.42,2.42,0,0,0-.73-1.83,2.38,2.38,0,0,0-3.45.08,2.43,2.43,0,0,0-.74,1.75Z"/>
            <path className="cls-1" d="M304.46,14.78V.54a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.82.82,0,0,1,.47.14.43.43,0,0,1,.19.34V7.26a2.91,2.91,0,0,1,1.05-1.1,2.94,2.94,0,0,1,1.61-.44A3.39,3.39,0,0,1,311,6.82,3.68,3.68,0,0,1,312,9.46v2.18a3.8,3.8,0,0,1-1.07,2.65,3.29,3.29,0,0,1-2.47,1.15A3.1,3.1,0,0,1,306.8,15a2.65,2.65,0,0,1-1.08-1.1v.89a.49.49,0,0,1-.18.37.63.63,0,0,1-.44.16.64.64,0,0,1-.45-.16A.47.47,0,0,1,304.46,14.78Zm1.3-2.53a1.92,1.92,0,0,0,.75,1.25,2.4,2.4,0,0,0,1.71.72,2.33,2.33,0,0,0,1.74-.8,2.56,2.56,0,0,0,.74-1.79V9.47A2.47,2.47,0,0,0,310,7.72a2.36,2.36,0,0,0-1.76-.78,2.46,2.46,0,0,0-2.44,2.53Z"/>
            <path className="cls-1" d="M313.08,6.34a.52.52,0,0,1,.26-.43.87.87,0,0,1,.54-.19.42.42,0,0,1,.46.28l2.74,7.34L319.66,6a.43.43,0,0,1,.44-.28.91.91,0,0,1,.53.19.55.55,0,0,1,.27.45.28.28,0,0,1,0,.16l-3.1,8.4-.36,1-.39.94a7.9,7.9,0,0,1-.49,1l-.52.8a3.16,3.16,0,0,1-.65.72,4.35,4.35,0,0,1-.75.41,2.24,2.24,0,0,1-.9.19.53.53,0,0,1-.6-.6c0-.41.13-.62.4-.62a2.18,2.18,0,0,0,1.71-1.11,8,8,0,0,0,1.19-2.74l-3.26-8.35A.38.38,0,0,1,313.08,6.34Z"/>
            <path className="cls-1" d="M326.28,12.18v-10a.55.55,0,0,1,.32-.48,1.34,1.34,0,0,1,.72-.2,1.43,1.43,0,0,1,.73.2.56.56,0,0,1,.33.48V5.82h2.44a.51.51,0,0,1,.42.23,1,1,0,0,1,0,1.1.51.51,0,0,1-.42.23h-2.44v4.8a1.37,1.37,0,0,0,.31,1,1.54,1.54,0,0,0,1.09.3h.7a.87.87,0,0,1,.66.26,1,1,0,0,1,0,1.28.87.87,0,0,1-.66.26h-.7C327.44,15.3,326.28,14.26,326.28,12.18Z"/>
            <path className="cls-1" d="M332.9,14.6V.88a.56.56,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.39,1.39,0,0,1,.75.19.58.58,0,0,1,.31.49V7.24a2.73,2.73,0,0,1,1-1.1,2.81,2.81,0,0,1,1.57-.44A3.2,3.2,0,0,1,340,6.86a4.07,4.07,0,0,1,1,2.78v5a.59.59,0,0,1-.32.51,1.41,1.41,0,0,1-.74.19,1.34,1.34,0,0,1-.72-.19.57.57,0,0,1-.32-.51V9.65a2,2,0,0,0-.6-1.43,1.88,1.88,0,0,0-1.42-.64,1.84,1.84,0,0,0-1.34.57,2,2,0,0,0-.58,1.5V14.6a.62.62,0,0,1-.32.48,1.26,1.26,0,0,1-.74.22,1.18,1.18,0,0,1-.74-.22A.62.62,0,0,1,332.9,14.6Z"/>
            <path className="cls-1" d="M342.8,11.48V9.58A3.61,3.61,0,0,1,344,6.85a4.32,4.32,0,0,1,5.75-.11,3.36,3.36,0,0,1,1.17,2.63,3.06,3.06,0,0,1-.14,1,1,1,0,0,1-.44.53,2.06,2.06,0,0,1-.56.19,4.27,4.27,0,0,1-.68,0H344.9v.46a2,2,0,0,0,.72,1.61,2.88,2.88,0,0,0,1.88.59,3.38,3.38,0,0,0,1.55-.38,3.12,3.12,0,0,1,.89-.39.71.71,0,0,1,.58.33,1,1,0,0,1,.24.62c0,.33-.32.66-1,1a5.12,5.12,0,0,1-2.36.5,5,5,0,0,1-3.34-1.1A3.57,3.57,0,0,1,342.8,11.48Zm2.1-1.7h3.32a1,1,0,0,0,.59-.12.57.57,0,0,0,.15-.47,1.8,1.8,0,0,0-.58-1.34,2,2,0,0,0-1.44-.55,2.11,2.11,0,0,0-1.44.52,1.69,1.69,0,0,0-.6,1.33Z"/>
            <path className="cls-1" d="M356.72,11V5.14A4.28,4.28,0,0,1,358,1.76,4.69,4.69,0,0,1,361.16.68a5.11,5.11,0,0,1,3.29,1A3.2,3.2,0,0,1,365.7,4.3a1.19,1.19,0,0,1-.24.87,1.2,1.2,0,0,1-.82.23c-.7,0-1.05-.23-1.06-.7a2.05,2.05,0,0,0-2.34-2.14q-2.4,0-2.4,2.58V11c0,1.72.81,2.58,2.44,2.58a3,3,0,0,0,1.2-.21,1.34,1.34,0,0,0,.69-.61,4,4,0,0,0,.29-.7,5.45,5.45,0,0,0,.12-.74c0-.47.38-.7,1-.7a1.25,1.25,0,0,1,.83.23,1.19,1.19,0,0,1,.25.87,3.33,3.33,0,0,1-1.26,2.7,5,5,0,0,1-3.3,1,4.64,4.64,0,0,1-3.2-1.08A4.27,4.27,0,0,1,356.72,11Z"/>
            <path className="cls-1" d="M367.08,11.48V9.64a3.71,3.71,0,0,1,1.22-2.76,4,4,0,0,1,2.92-1.18,4.11,4.11,0,0,1,2.9,1.17,3.7,3.7,0,0,1,1.24,2.77v1.84a3.77,3.77,0,0,1-1.25,2.77,4.08,4.08,0,0,1-5.79,0A3.75,3.75,0,0,1,367.08,11.48Zm2.1,0a2.08,2.08,0,0,0,.6,1.46,1.9,1.9,0,0,0,1.44.65,1.93,1.93,0,0,0,1.43-.65,2.09,2.09,0,0,0,.61-1.46V9.65a2,2,0,0,0-.61-1.43,1.92,1.92,0,0,0-1.43-.64,1.89,1.89,0,0,0-1.44.64,2,2,0,0,0-.6,1.43Z"/>
            <path className="cls-1" d="M377.2,14.6V6.5a.56.56,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.13,1.13,0,0,1,.67.19.6.6,0,0,1,.27.5v.73a2.64,2.64,0,0,1,1-1.1,3.08,3.08,0,0,1,1.65-.44,3.2,3.2,0,0,1,2.45,1.16,4.07,4.07,0,0,1,1,2.78v5a.6.6,0,0,1-.31.51,1.39,1.39,0,0,1-.75.19,1.34,1.34,0,0,1-.72-.19.57.57,0,0,1-.32-.51V9.65a2,2,0,0,0-.6-1.43,1.88,1.88,0,0,0-1.42-.64,1.84,1.84,0,0,0-1.34.57,2,2,0,0,0-.58,1.5V14.6a.62.62,0,0,1-.32.48,1.26,1.26,0,0,1-.74.22,1.18,1.18,0,0,1-.74-.22A.62.62,0,0,1,377.2,14.6Z"/>
            <path className="cls-1" d="M387.14,11.48V9.68a4,4,0,0,1,1-2.88,4.07,4.07,0,0,1,3.1-1.1,4.78,4.78,0,0,1,2.18.45c.58.3.88.64.88,1a1.19,1.19,0,0,1-.25.68.73.73,0,0,1-.59.34,3.07,3.07,0,0,1-.71-.3,3,3,0,0,0-1.35-.3c-1.47,0-2.2.7-2.2,2.11v1.78c0,1.41.74,2.11,2.22,2.11a3,3,0,0,0,1.37-.32,2.91,2.91,0,0,1,.73-.32.81.81,0,0,1,.65.32,1.12,1.12,0,0,1,.25.68c0,.37-.31.72-.92,1a4.81,4.81,0,0,1-2.24.48,4.1,4.1,0,0,1-3.12-1.09A4,4,0,0,1,387.14,11.48Z"/>
            <path className="cls-1" d="M395.52,11.48V9.58a3.61,3.61,0,0,1,1.22-2.73,4.32,4.32,0,0,1,5.75-.11,3.36,3.36,0,0,1,1.17,2.63,3.06,3.06,0,0,1-.14,1,1,1,0,0,1-.44.53,2.06,2.06,0,0,1-.56.19,4.27,4.27,0,0,1-.68,0h-4.22v.46a2,2,0,0,0,.72,1.61,2.88,2.88,0,0,0,1.88.59,3.38,3.38,0,0,0,1.55-.38,3.12,3.12,0,0,1,.89-.39.71.71,0,0,1,.58.33,1,1,0,0,1,.24.62c0,.33-.32.66-1,1a5.12,5.12,0,0,1-2.36.5,5,5,0,0,1-3.34-1.1A3.57,3.57,0,0,1,395.52,11.48Zm2.1-1.7h3.32a1,1,0,0,0,.59-.12.57.57,0,0,0,.15-.47,1.8,1.8,0,0,0-.58-1.34,2,2,0,0,0-1.44-.55,2.11,2.11,0,0,0-1.44.52,1.69,1.69,0,0,0-.6,1.33Z"/>
            <path className="cls-1" d="M405.38,19.1V6.5a.56.56,0,0,1,.29-.49,1.23,1.23,0,0,1,.71-.19,1.2,1.2,0,0,1,.7.19.61.61,0,0,1,.28.5v.73a3,3,0,0,1,1.07-1.1A3,3,0,0,1,410,5.7a3.32,3.32,0,0,1,2.55,1.16,4,4,0,0,1,1.05,2.78v1.84a4.12,4.12,0,0,1-1.06,2.77,3.19,3.19,0,0,1-2.46,1.21,2.88,2.88,0,0,1-1.59-.47,2.77,2.77,0,0,1-1-1.11V19.1a.61.61,0,0,1-.32.49,1.38,1.38,0,0,1-.76.21,1.19,1.19,0,0,1-.73-.21A.58.58,0,0,1,405.38,19.1Zm2.1-7.17a1.63,1.63,0,0,0,.61,1,1.9,1.9,0,0,0,1.41.61,1.94,1.94,0,0,0,1.42-.65,2.06,2.06,0,0,0,.62-1.46V9.65a2,2,0,0,0-.62-1.43,2,2,0,0,0-1.46-.64,2,2,0,0,0-2,2.07Z"/>
            <path className="cls-1" d="M415.46,12.18v-10a.55.55,0,0,1,.32-.48,1.34,1.34,0,0,1,.72-.2,1.43,1.43,0,0,1,.73.2.56.56,0,0,1,.33.48V5.82H420a.51.51,0,0,1,.42.23,1,1,0,0,1,0,1.1.51.51,0,0,1-.42.23h-2.44v4.8a1.37,1.37,0,0,0,.31,1,1.54,1.54,0,0,0,1.09.3h.7a.87.87,0,0,1,.66.26,1,1,0,0,1,0,1.28.87.87,0,0,1-.66.26H419C416.62,15.3,415.46,14.26,415.46,12.18Z"/>
            <path className="cls-1" d="M422,11.52v-5A.57.57,0,0,1,422.3,6a1.31,1.31,0,0,1,.74-.21,1.28,1.28,0,0,1,.73.21.61.61,0,0,1,.31.49v5a2,2,0,0,0,.61,1.44,1.89,1.89,0,0,0,1.43.64,1.92,1.92,0,0,0,1.44-.63,2,2,0,0,0,.6-1.45v-5a.56.56,0,0,1,.31-.48,1.35,1.35,0,0,1,.75-.2A1.3,1.3,0,0,1,430,6a.58.58,0,0,1,.29.48v5A3.74,3.74,0,0,1,429,14.28a4.17,4.17,0,0,1-5.81,0A3.72,3.72,0,0,1,422,11.52Z"/>
            <path className="cls-1" d="M431.76,12.82a2.3,2.3,0,0,1,1.2-2.23,7.66,7.66,0,0,1,3.48-.63h.88V9.59A2.54,2.54,0,0,0,436.83,8a1.75,1.75,0,0,0-1.49-.66,3.26,3.26,0,0,0-1.51.33,5.36,5.36,0,0,1-.71.33.63.63,0,0,1-.55-.33,1.32,1.32,0,0,1-.21-.73c0-.35.32-.65,1-.9a5.61,5.61,0,0,1,2.05-.38,3.89,3.89,0,0,1,3.05,1.11,4,4,0,0,1,1,2.77v5a.6.6,0,0,1-.29.49,1.23,1.23,0,0,1-.73.21,1.08,1.08,0,0,1-.68-.21.59.59,0,0,1-.28-.49v-.7a3.94,3.94,0,0,1-3,1.56,2.74,2.74,0,0,1-1.91-.68A2.51,2.51,0,0,1,431.76,12.82Zm2.12-.26a1.25,1.25,0,0,0,.36,1,1.33,1.33,0,0,0,.94.33,2.11,2.11,0,0,0,1.45-.64,1.86,1.86,0,0,0,.69-1.36v-.74h-.5a11.54,11.54,0,0,0-1.47.08,2.1,2.1,0,0,0-1,.42A1.12,1.12,0,0,0,433.88,12.56Z"/>
            <path className="cls-1" d="M441.74,14.6V.86A.56.56,0,0,1,442,.37a1.3,1.3,0,0,1,.75-.19,1.39,1.39,0,0,1,.75.19.58.58,0,0,1,.31.49V14.6a.62.62,0,0,1-.32.48,1.26,1.26,0,0,1-.74.22,1.18,1.18,0,0,1-.74-.22A.62.62,0,0,1,441.74,14.6Z"/>
            <path className="cls-1" d="M5.51,47.6V34.38a.59.59,0,0,1,.28-.51,1.14,1.14,0,0,1,.66-.19h7A.56.56,0,0,1,14,34a1.35,1.35,0,0,1,0,1.33.56.56,0,0,1-.5.28H7.63v4.58h3.1a.58.58,0,0,1,.5.25,1.08,1.08,0,0,1,0,1.18.56.56,0,0,1-.51.25H7.63V47.6a.56.56,0,0,1-.31.52,1.49,1.49,0,0,1-.75.18,1.47,1.47,0,0,1-.74-.18A.57.57,0,0,1,5.51,47.6Z"/>
            <path className="cls-1" d="M13.47,47.6V39.5a.58.58,0,0,1,.29-.49,1.28,1.28,0,0,1,.73-.19,1.16,1.16,0,0,1,.68.19.58.58,0,0,1,.28.5v.89a3.19,3.19,0,0,1,1-1.2A2.53,2.53,0,0,1,18,38.7h.64a.73.73,0,0,1,.56.27,1,1,0,0,1,.24.66,1,1,0,0,1-.24.67.71.71,0,0,1-.56.28H18a2.2,2.2,0,0,0-1.69.82,3,3,0,0,0-.73,2.11V47.6a.62.62,0,0,1-.32.48,1.28,1.28,0,0,1-.76.22,1.15,1.15,0,0,1-.73-.22A.63.63,0,0,1,13.47,47.6Z"/>
            <path className="cls-1" d="M20.07,45.82a2.3,2.3,0,0,1,1.2-2.23A7.66,7.66,0,0,1,24.75,43h.88v-.37A2.47,2.47,0,0,0,25.14,41a1.74,1.74,0,0,0-1.49-.66,3.29,3.29,0,0,0-1.51.33,4.76,4.76,0,0,1-.71.33.63.63,0,0,1-.55-.33,1.32,1.32,0,0,1-.21-.73c0-.35.32-.65,1-.9a5.65,5.65,0,0,1,2.05-.38,3.89,3.89,0,0,1,3,1.11,4,4,0,0,1,1,2.77v5a.6.6,0,0,1-.29.49,1.21,1.21,0,0,1-.73.21,1.1,1.1,0,0,1-.68-.21.59.59,0,0,1-.28-.49v-.7a3.92,3.92,0,0,1-3,1.56,2.76,2.76,0,0,1-1.91-.68A2.51,2.51,0,0,1,20.07,45.82Zm2.12-.26a1.25,1.25,0,0,0,.36,1,1.33,1.33,0,0,0,.94.33,2.1,2.1,0,0,0,1.45-.64,1.86,1.86,0,0,0,.69-1.36v-.74h-.5a11.54,11.54,0,0,0-1.47.08,2.1,2.1,0,0,0-1,.42A1.12,1.12,0,0,0,22.19,45.56Z"/>
            <path className="cls-1" d="M29.87,47.6V39.5a.58.58,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.14,1.14,0,0,1,.66.19.58.58,0,0,1,.28.5v.73a2.84,2.84,0,0,1,2.66-1.54,2.44,2.44,0,0,1,1.57.56,3.44,3.44,0,0,1,1.05,1.46,3.75,3.75,0,0,1,1.31-1.51,3.16,3.16,0,0,1,1.71-.51,3.3,3.3,0,0,1,2.45,1.07,3.94,3.94,0,0,1,1,2.87v5a.62.62,0,0,1-.32.48,1.24,1.24,0,0,1-.74.22,1.2,1.2,0,0,1-.72-.22.62.62,0,0,1-.32-.48v-5A2.07,2.07,0,0,0,41,41.12a1.8,1.8,0,0,0-1.3-.56,1.84,1.84,0,0,0-1.31.56,2,2,0,0,0-.57,1.49v5a.54.54,0,0,1-.31.51,1.6,1.6,0,0,1-.75.17,1.53,1.53,0,0,1-.72-.17.55.55,0,0,1-.32-.51v-5a2,2,0,0,0-.55-1.47,1.73,1.73,0,0,0-1.31-.58,1.79,1.79,0,0,0-1.31.56A2,2,0,0,0,32,42.65v5a.62.62,0,0,1-.32.48,1.24,1.24,0,0,1-.74.22,1.2,1.2,0,0,1-.74-.22A.62.62,0,0,1,29.87,47.6Z"/>
            <path className="cls-1" d="M45.39,44.48v-1.9a3.65,3.65,0,0,1,1.22-2.73,4.32,4.32,0,0,1,5.75-.11,3.36,3.36,0,0,1,1.17,2.63,3.06,3.06,0,0,1-.14,1,.94.94,0,0,1-.44.53,2.06,2.06,0,0,1-.56.19,4.2,4.2,0,0,1-.68,0H47.49v.46a2,2,0,0,0,.72,1.61,2.9,2.9,0,0,0,1.88.59,3.35,3.35,0,0,0,1.55-.38,3.25,3.25,0,0,1,.89-.39.7.7,0,0,1,.58.33,1,1,0,0,1,.24.62c0,.33-.32.66-1,1a5.12,5.12,0,0,1-2.36.5,5,5,0,0,1-3.34-1.1A3.57,3.57,0,0,1,45.39,44.48Zm2.1-1.7h3.32a1,1,0,0,0,.59-.12.57.57,0,0,0,.15-.47A1.77,1.77,0,0,0,51,40.85a2,2,0,0,0-1.44-.55,2.11,2.11,0,0,0-1.44.52,1.69,1.69,0,0,0-.6,1.33Z"/>
            <path className="cls-1" d="M54.33,39.62a.74.74,0,0,1,.44-.62,1.76,1.76,0,0,1,.9-.26.61.61,0,0,1,.64.38l2.22,6.22,1.7-5.52a.83.83,0,0,1,1.48,0l1.68,5.56,2.24-6.26a.57.57,0,0,1,.62-.38,1.81,1.81,0,0,1,.89.26.75.75,0,0,1,.45.64.76.76,0,0,1-.08.32l-3.1,8.12q-.16.42-.84.42c-.45,0-.74-.13-.86-.4L61,42.94,59.25,48c-.12.31-.42.46-.9.46a.79.79,0,0,1-.84-.46L54.41,40A1.39,1.39,0,0,1,54.33,39.62Z"/>
            <path className="cls-1" d="M68.53,44.48V42.64a3.74,3.74,0,0,1,1.22-2.76,4,4,0,0,1,2.92-1.18,4.09,4.09,0,0,1,2.9,1.17,3.67,3.67,0,0,1,1.24,2.77v1.84a3.77,3.77,0,0,1-1.25,2.77,4.08,4.08,0,0,1-5.79,0A3.75,3.75,0,0,1,68.53,44.48Zm2.1,0a2.08,2.08,0,0,0,.6,1.46,1.91,1.91,0,0,0,2.87,0,2,2,0,0,0,.61-1.46V42.65a2,2,0,0,0-.61-1.43,1.93,1.93,0,0,0-2.87,0,2,2,0,0,0-.6,1.43Z"/>
            <path className="cls-1" d="M78.65,47.6V39.5a.58.58,0,0,1,.29-.49,1.28,1.28,0,0,1,.73-.19,1.16,1.16,0,0,1,.68.19.58.58,0,0,1,.28.5v.89a3.19,3.19,0,0,1,1-1.2,2.53,2.53,0,0,1,1.51-.5h.64a.73.73,0,0,1,.56.27,1,1,0,0,1,.24.66,1,1,0,0,1-.24.67.71.71,0,0,1-.56.28h-.64a2.2,2.2,0,0,0-1.69.82,3,3,0,0,0-.73,2.11V47.6a.62.62,0,0,1-.32.48,1.28,1.28,0,0,1-.76.22,1.15,1.15,0,0,1-.73-.22A.63.63,0,0,1,78.65,47.6Z"/>
            <path className="cls-1" d="M85.87,47.6V33.86a.57.57,0,0,1,.28-.49,1.27,1.27,0,0,1,.74-.19,1.42,1.42,0,0,1,.77.19.56.56,0,0,1,.31.49v8.9l4.18-3.84a.6.6,0,0,1,.44-.18,1,1,0,0,1,.68.32.92.92,0,0,1,.34.68.6.6,0,0,1-.18.44l-2.58,2.3L94,47a.65.65,0,0,1,.12.36,1,1,0,0,1-.4.71,1.23,1.23,0,0,1-.78.35.67.67,0,0,1-.56-.3l-2.94-4.34L88,45.1v2.5a.62.62,0,0,1-.32.48,1.28,1.28,0,0,1-.76.22,1.15,1.15,0,0,1-.73-.22A.63.63,0,0,1,85.87,47.6Z"/>
            <path className="cls-1" d="M95,47.39a1.08,1.08,0,0,1,.35-.8,1.2,1.2,0,0,1,.86-.33,1.25,1.25,0,0,1,.86.33,1,1,0,0,1,.37.8,14.26,14.26,0,0,1-1,2.51.48.48,0,0,1-.48.28A1,1,0,0,1,95.5,50a.46.46,0,0,1-.23-.39.43.43,0,0,1,.06-.24l.44-1A1,1,0,0,1,95,47.39Z"/>
            <path className="cls-1" d="M102.77,46.3a.8.8,0,0,1,.19-.49.52.52,0,0,1,.41-.25c.11,0,.24.09.4.26l.56.58a2.67,2.67,0,0,0,1,.57,4.12,4.12,0,0,0,1.51.25,3.16,3.16,0,0,0,2.2-.71,2.63,2.63,0,0,0,.78-2,2.94,2.94,0,0,0-.37-1.53,2.68,2.68,0,0,0-1-1,7.28,7.28,0,0,0-1.28-.64l-1.44-.56a6.52,6.52,0,0,1-1.29-.67,3,3,0,0,1-.94-1.1,3.57,3.57,0,0,1-.37-1.69,3.86,3.86,0,0,1,.39-1.8,2.77,2.77,0,0,1,1-1.14,5.19,5.19,0,0,1,1.35-.55,6.19,6.19,0,0,1,1.45-.17,7.42,7.42,0,0,1,2.29.35c.71.23,1.07.52,1.07.87a.91.91,0,0,1-.17.49.48.48,0,0,1-.41.25,1.22,1.22,0,0,1-.46-.19,6.1,6.1,0,0,0-1-.37,4.93,4.93,0,0,0-1.39-.18,3.72,3.72,0,0,0-2.08.54,2,2,0,0,0-.82,1.78,2.15,2.15,0,0,0,.49,1.44,2.77,2.77,0,0,0,1.23.84c.49.17,1,.38,1.6.62a17,17,0,0,1,1.6.77,3.23,3.23,0,0,1,1.23,1.3,4.43,4.43,0,0,1,.49,2.19A3.9,3.9,0,0,1,110,47.41a4.41,4.41,0,0,1-3.08,1A5.43,5.43,0,0,1,104,47.7C103.16,47.2,102.77,46.74,102.77,46.3Z"/>
            <path className="cls-1" d="M112.83,44.64V42.4A3.48,3.48,0,0,1,114,39.81a3.87,3.87,0,0,1,5.33-.05,3.43,3.43,0,0,1,1.11,2.6,2.45,2.45,0,0,1-.13.9.84.84,0,0,1-.42.48,2.21,2.21,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0h-4.52v.74a2.32,2.32,0,0,0,.85,1.89,3.7,3.7,0,0,0,3.38.51,3.47,3.47,0,0,0,.78-.4.84.84,0,0,1,.43-.2.49.49,0,0,1,.39.21.69.69,0,0,1,.17.41c0,.28-.29.57-.86.87a4.58,4.58,0,0,1-2.14.45,4.53,4.53,0,0,1-3.1-1A3.48,3.48,0,0,1,112.83,44.64Zm1.3-1.64h4a1.59,1.59,0,0,0,.8-.13.63.63,0,0,0,.2-.55,2.47,2.47,0,0,0-.71-1.74,2.33,2.33,0,0,0-1.79-.76,2.44,2.44,0,0,0-1.77.72,2.27,2.27,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M122,44.64V42.4a3.48,3.48,0,0,1,1.12-2.59,3.87,3.87,0,0,1,5.33-.05,3.43,3.43,0,0,1,1.11,2.6,2.45,2.45,0,0,1-.13.9.84.84,0,0,1-.42.48,2.21,2.21,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0h-4.52v.74a2.32,2.32,0,0,0,.85,1.89,3.7,3.7,0,0,0,3.38.51,3.47,3.47,0,0,0,.78-.4.84.84,0,0,1,.43-.2.49.49,0,0,1,.39.21.69.69,0,0,1,.17.41c0,.28-.29.57-.86.87a4.58,4.58,0,0,1-2.14.45,4.53,4.53,0,0,1-3.1-1A3.48,3.48,0,0,1,122,44.64Zm1.3-1.64h4a1.59,1.59,0,0,0,.8-.13.63.63,0,0,0,.2-.55,2.47,2.47,0,0,0-.71-1.74,2.33,2.33,0,0,0-1.79-.76,2.44,2.44,0,0,0-1.77.72,2.27,2.27,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M131.57,47.6V34.36a.66.66,0,0,1,.2-.48.69.69,0,0,1,.5-.2h3.8q4.6,0,4.6,4.08a4.19,4.19,0,0,1-.76,2.66,3.63,3.63,0,0,1-2.06,1.28l2.8,5.28a.61.61,0,0,1,.08.3,1,1,0,0,1-.41.74,1.31,1.31,0,0,1-.87.34,1,1,0,0,1-.84-.62L135.69,42h-2v5.6a.58.58,0,0,1-.31.51,1.39,1.39,0,0,1-.75.19,1.35,1.35,0,0,1-.73-.19A.58.58,0,0,1,131.57,47.6Zm2.1-7.28h2.4c1.65,0,2.48-.79,2.48-2.38s-.83-2.38-2.48-2.38h-2.4Z"/>
            <path className="cls-1" d="M142.75,47.6V34.36a.66.66,0,0,1,.2-.48.69.69,0,0,1,.5-.2h3.8q4.6,0,4.6,4.08a4.19,4.19,0,0,1-.76,2.66A3.63,3.63,0,0,1,149,41.7l2.8,5.28a.61.61,0,0,1,.08.3,1,1,0,0,1-.41.74,1.31,1.31,0,0,1-.87.34,1,1,0,0,1-.84-.62L146.87,42h-2v5.6a.58.58,0,0,1-.31.51,1.39,1.39,0,0,1-.75.19,1.35,1.35,0,0,1-.73-.19A.58.58,0,0,1,142.75,47.6Zm2.1-7.28h2.4c1.65,0,2.48-.79,2.48-2.38s-.83-2.38-2.48-2.38h-2.4Z"/>
            <path className="cls-1" d="M153.93,47.6V34.36a.54.54,0,0,1,.31-.51,1.74,1.74,0,0,1,1.5,0,.54.54,0,0,1,.31.51V47.6a.58.58,0,0,1-.31.51,1.56,1.56,0,0,1-1.49,0A.59.59,0,0,1,153.93,47.6Z"/>
            <path className="cls-1" d="M162.63,35.79a.8.8,0,0,1-.26-.59.77.77,0,0,1,.26-.58.91.91,0,0,1,.64-.24.85.85,0,0,1,.61.24.76.76,0,0,1,.25.58.79.79,0,0,1-.25.59.82.82,0,0,1-.61.25A.87.87,0,0,1,162.63,35.79Zm0,12V39.32a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.79.79,0,0,1,.47.14.4.4,0,0,1,.19.34v8.46a.48.48,0,0,1-.2.36.7.7,0,0,1-.46.16.67.67,0,0,1-.45-.16A.47.47,0,0,1,162.63,47.78Z"/>
            <path className="cls-1" d="M165.85,47a.72.72,0,0,1,.14-.4.4.4,0,0,1,.32-.2,1,1,0,0,1,.44.23,4.35,4.35,0,0,0,.86.47,3.38,3.38,0,0,0,1.34.24,2.45,2.45,0,0,0,1.56-.4,1.27,1.27,0,0,0,.5-1,1.43,1.43,0,0,0-.51-1.15,3.44,3.44,0,0,0-1.23-.65l-1.45-.44a2.72,2.72,0,0,1-1.24-.77,2.09,2.09,0,0,1-.51-1.47,2.64,2.64,0,0,1,.79-1.91,3.18,3.18,0,0,1,2.37-.81,5.25,5.25,0,0,1,1.83.31c.57.21.85.43.85.67a.79.79,0,0,1-.16.44.46.46,0,0,1-.38.24,5.24,5.24,0,0,1-.74-.28,3.51,3.51,0,0,0-1.38-.28,2.12,2.12,0,0,0-1.5.47,1.44,1.44,0,0,0-.5,1.06,1.11,1.11,0,0,0,.52,1,4.43,4.43,0,0,0,1.24.56l1.46.44a2.33,2.33,0,0,1,1.24.87,2.69,2.69,0,0,1,.5,1.68,2.29,2.29,0,0,1-.85,1.89,3.68,3.68,0,0,1-2.37.69,4.87,4.87,0,0,1-2.23-.47C166.15,47.66,165.85,47.33,165.85,47Z"/>
            <path className="cls-1" d="M177.49,45.8a2.25,2.25,0,0,1,1.19-2.19,7.76,7.76,0,0,1,3.47-.61h1v-.47a3.09,3.09,0,0,0-.58-1.88,2.08,2.08,0,0,0-1.78-.79,3.57,3.57,0,0,0-1.45.28,7.44,7.44,0,0,1-.69.29.42.42,0,0,1-.38-.22.86.86,0,0,1-.14-.47c0-.25.29-.49.86-.7a5.31,5.31,0,0,1,1.84-.32,3.3,3.3,0,0,1,2.75,1.09,4.31,4.31,0,0,1,.87,2.77v5.2a.46.46,0,0,1-.18.37.69.69,0,0,1-.46.15.63.63,0,0,1-.43-.15.48.48,0,0,1-.17-.37V46.9a3.93,3.93,0,0,1-3,1.54,2.84,2.84,0,0,1-2-.67A2.48,2.48,0,0,1,177.49,45.8Zm1.3-.12a1.55,1.55,0,0,0,.48,1.25,1.89,1.89,0,0,0,1.26.41,2.76,2.76,0,0,0,1.79-.7,1.9,1.9,0,0,0,.85-1.4V44h-.84A12.84,12.84,0,0,0,181,44a5.85,5.85,0,0,0-1.09.22,1.6,1.6,0,0,0-.82.52A1.44,1.44,0,0,0,178.79,45.68Z"/>
            <path className="cls-1" d="M186.91,47.78V33.54a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.79.79,0,0,1,.47.14.4.4,0,0,1,.19.34v6.72a2.91,2.91,0,0,1,1-1.1,3,3,0,0,1,1.61-.44,3.38,3.38,0,0,1,2.53,1.1,3.68,3.68,0,0,1,1,2.64v2.18a3.8,3.8,0,0,1-1.07,2.65,3.27,3.27,0,0,1-2.47,1.15,3.12,3.12,0,0,1-1.66-.46,2.65,2.65,0,0,1-1.08-1.1v.89a.49.49,0,0,1-.18.37.63.63,0,0,1-.44.16.67.67,0,0,1-.45-.16A.47.47,0,0,1,186.91,47.78Zm1.3-2.53A1.92,1.92,0,0,0,189,46.5a2.4,2.4,0,0,0,1.71.72,2.3,2.3,0,0,0,1.74-.8,2.56,2.56,0,0,0,.74-1.79V42.47a2.47,2.47,0,0,0-.74-1.75,2.34,2.34,0,0,0-1.76-.78,2.46,2.46,0,0,0-2.44,2.53Z"/>
            <path className="cls-1" d="M196.75,47.78V33.52a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.79.79,0,0,1,.47.14.4.4,0,0,1,.19.34V47.78a.47.47,0,0,1-.19.36.69.69,0,0,1-.47.16.67.67,0,0,1-.45-.16A.47.47,0,0,1,196.75,47.78Z"/>
            <path className="cls-1" d="M200.37,44.64V42.4a3.48,3.48,0,0,1,1.12-2.59,3.87,3.87,0,0,1,5.33-.05,3.43,3.43,0,0,1,1.11,2.6,2.45,2.45,0,0,1-.13.9.84.84,0,0,1-.42.48,2.21,2.21,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0h-4.52v.74a2.32,2.32,0,0,0,.85,1.89,3.7,3.7,0,0,0,3.38.51,3.47,3.47,0,0,0,.78-.4.84.84,0,0,1,.43-.2.5.5,0,0,1,.39.21.69.69,0,0,1,.17.41c0,.28-.29.57-.86.87a4.58,4.58,0,0,1-2.14.45,4.53,4.53,0,0,1-3.1-1A3.48,3.48,0,0,1,200.37,44.64Zm1.3-1.64h4a1.62,1.62,0,0,0,.8-.13.63.63,0,0,0,.2-.55,2.47,2.47,0,0,0-.71-1.74,2.33,2.33,0,0,0-1.79-.76,2.44,2.44,0,0,0-1.77.72,2.27,2.27,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M213.85,45.36V34.84a.42.42,0,0,1,.2-.34.77.77,0,0,1,.44-.14.81.81,0,0,1,.46.14.42.42,0,0,1,.2.34v4h2.58a.41.41,0,0,1,.33.16.65.65,0,0,1,0,.78.41.41,0,0,1-.33.16h-2.58v5.39a1.83,1.83,0,0,0,.41,1.36,2.1,2.1,0,0,0,1.45.39h.56a.57.57,0,0,1,.43.18.57.57,0,0,1,.17.42.61.61,0,0,1-.17.43.54.54,0,0,1-.43.19H217Q213.85,48.3,213.85,45.36Z"/>
            <path className="cls-1" d="M219.27,44.64V42.46a3.57,3.57,0,0,1,1.12-2.63,3.63,3.63,0,0,1,2.66-1.11,3.74,3.74,0,0,1,2.68,1.1,3.53,3.53,0,0,1,1.14,2.64v2.18a3.93,3.93,0,0,1-3.82,3.8,3.6,3.6,0,0,1-2.64-1.15A3.66,3.66,0,0,1,219.27,44.64Zm1.3,0a2.54,2.54,0,0,0,.73,1.8,2.29,2.29,0,0,0,1.75.79,2.36,2.36,0,0,0,1.78-.79,2.55,2.55,0,0,0,.74-1.8V42.47a2.44,2.44,0,0,0-.75-1.75,2.38,2.38,0,0,0-1.77-.78,2.32,2.32,0,0,0-1.75.78,2.46,2.46,0,0,0-.73,1.75Z"/>
            <path className="cls-1" d="M233,47.8V36.69a3.6,3.6,0,0,1,1.06-2.6,3.35,3.35,0,0,1,2.5-1.09h.5a.5.5,0,0,1,.4.19.65.65,0,0,1,.16.43.61.61,0,0,1-.16.42.52.52,0,0,1-.4.18h-.34a2.3,2.3,0,0,0-1.71.71,2.4,2.4,0,0,0-.71,1.69v2.22h2.86a.41.41,0,0,1,.33.16.62.62,0,0,1,.13.37.69.69,0,0,1-.13.41.41.41,0,0,1-.33.16h-2.86V47.8a.42.42,0,0,1-.2.36.87.87,0,0,1-.48.14.7.7,0,0,1-.44-.14A.43.43,0,0,1,233,47.8Z"/>
            <path className="cls-1" d="M238.45,45.8a2.24,2.24,0,0,1,1.19-2.19,7.76,7.76,0,0,1,3.47-.61h1v-.47a3.16,3.16,0,0,0-.58-1.88,2.08,2.08,0,0,0-1.78-.79,3.54,3.54,0,0,0-1.45.28l-.69.29a.42.42,0,0,1-.38-.22.79.79,0,0,1-.14-.47c0-.25.28-.49.86-.7a5.27,5.27,0,0,1,1.84-.32,3.3,3.3,0,0,1,2.75,1.09,4.31,4.31,0,0,1,.87,2.77v5.2a.46.46,0,0,1-.18.37.71.71,0,0,1-.46.15.61.61,0,0,1-.43-.15.45.45,0,0,1-.17-.37V46.9a3.94,3.94,0,0,1-3,1.54,2.83,2.83,0,0,1-2-.67A2.48,2.48,0,0,1,238.45,45.8Zm1.3-.12a1.55,1.55,0,0,0,.48,1.25,1.89,1.89,0,0,0,1.26.41,2.78,2.78,0,0,0,1.79-.7,1.93,1.93,0,0,0,.85-1.4V44h-.84a12.58,12.58,0,0,0-1.35.06,5.85,5.85,0,0,0-1.09.22,1.56,1.56,0,0,0-.82.52A1.44,1.44,0,0,0,239.75,45.68Z"/>
            <path className="cls-1" d="M247.53,44.64V42.5a3.81,3.81,0,0,1,1-2.73,3.74,3.74,0,0,1,2.88-1.05,4.21,4.21,0,0,1,2,.43q.81.43.81.87a.65.65,0,0,1-.18.43.53.53,0,0,1-.4.21.75.75,0,0,1-.37-.18,4.26,4.26,0,0,0-.71-.35,2.85,2.85,0,0,0-1.1-.19,2.61,2.61,0,0,0-1.95.68,2.55,2.55,0,0,0-.67,1.89v2.12a2.57,2.57,0,0,0,.67,1.91,2.64,2.64,0,0,0,2,.68,3,3,0,0,0,1.15-.19,3,3,0,0,0,.72-.37.73.73,0,0,1,.39-.18.57.57,0,0,1,.43.2.67.67,0,0,1,.17.44c0,.31-.28.6-.84.89a4.51,4.51,0,0,1-2.08.43,3.51,3.51,0,0,1-3.88-3.8Z"/>
            <path className="cls-1" d="M256.09,35.79a.77.77,0,0,1-.26-.59.73.73,0,0,1,.26-.58.89.89,0,0,1,.64-.24.85.85,0,0,1,.61.24.79.79,0,0,1,.25.58.83.83,0,0,1-.25.59.82.82,0,0,1-.61.25A.86.86,0,0,1,256.09,35.79Zm0,12V39.32a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.82.82,0,0,1,.47.14.43.43,0,0,1,.19.34v8.46a.48.48,0,0,1-.2.36.72.72,0,0,1-.46.16.64.64,0,0,1-.45-.16A.47.47,0,0,1,256.09,47.78Z"/>
            <path className="cls-1" d="M260.07,47.78V33.52a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.82.82,0,0,1,.47.14.43.43,0,0,1,.19.34V47.78a.5.5,0,0,1-.19.36.71.71,0,0,1-.47.16.64.64,0,0,1-.45-.16A.47.47,0,0,1,260.07,47.78Z"/>
            <path className="cls-1" d="M264.05,35.79a.77.77,0,0,1-.26-.59.73.73,0,0,1,.26-.58.89.89,0,0,1,.64-.24.85.85,0,0,1,.61.24.79.79,0,0,1,.25.58.83.83,0,0,1-.25.59.82.82,0,0,1-.61.25A.86.86,0,0,1,264.05,35.79Zm0,12V39.32a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.82.82,0,0,1,.47.14.43.43,0,0,1,.19.34v8.46a.48.48,0,0,1-.2.36.72.72,0,0,1-.46.16.64.64,0,0,1-.45-.16A.47.47,0,0,1,264.05,47.78Z"/>
            <path className="cls-1" d="M267.83,45.36V34.84a.39.39,0,0,1,.2-.34.77.77,0,0,1,.44-.14.83.83,0,0,1,.46.14.42.42,0,0,1,.2.34v4h2.58A.41.41,0,0,1,272,39a.65.65,0,0,1,0,.78.41.41,0,0,1-.33.16h-2.58v5.39a1.78,1.78,0,0,0,.41,1.36,2.08,2.08,0,0,0,1.45.39h.56a.58.58,0,0,1,.43.18.57.57,0,0,1,.17.42.61.61,0,0,1-.17.43.56.56,0,0,1-.43.19H271Q267.83,48.3,267.83,45.36Z"/>
            <path className="cls-1" d="M273.15,45.8a2.24,2.24,0,0,1,1.19-2.19,7.76,7.76,0,0,1,3.47-.61h1v-.47a3.16,3.16,0,0,0-.58-1.88,2.08,2.08,0,0,0-1.78-.79,3.54,3.54,0,0,0-1.45.28l-.69.29a.42.42,0,0,1-.38-.22.79.79,0,0,1-.14-.47c0-.25.28-.49.86-.7a5.27,5.27,0,0,1,1.84-.32,3.3,3.3,0,0,1,2.75,1.09,4.31,4.31,0,0,1,.87,2.77v5.2a.46.46,0,0,1-.18.37.71.71,0,0,1-.46.15.61.61,0,0,1-.43-.15.45.45,0,0,1-.17-.37V46.9a3.94,3.94,0,0,1-3,1.54,2.83,2.83,0,0,1-2-.67A2.48,2.48,0,0,1,273.15,45.8Zm1.3-.12a1.55,1.55,0,0,0,.48,1.25,1.89,1.89,0,0,0,1.26.41,2.78,2.78,0,0,0,1.79-.7,1.93,1.93,0,0,0,.85-1.4V44H278a12.58,12.58,0,0,0-1.35.06,5.85,5.85,0,0,0-1.09.22,1.56,1.56,0,0,0-.82.52A1.44,1.44,0,0,0,274.45,45.68Z"/>
            <path className="cls-1" d="M282.53,45.36V34.84a.39.39,0,0,1,.2-.34.77.77,0,0,1,.44-.14.83.83,0,0,1,.46.14.42.42,0,0,1,.2.34v4h2.58a.41.41,0,0,1,.33.16.65.65,0,0,1,0,.78.41.41,0,0,1-.33.16h-2.58v5.39a1.78,1.78,0,0,0,.41,1.36,2.08,2.08,0,0,0,1.45.39h.56a.58.58,0,0,1,.43.18.57.57,0,0,1,.17.42.61.61,0,0,1-.17.43.56.56,0,0,1-.43.19h-.56Q282.52,48.3,282.53,45.36Z"/>
            <path className="cls-1" d="M288,44.64V42.4a3.48,3.48,0,0,1,1.12-2.59,3.7,3.7,0,0,1,2.7-1.09,3.74,3.74,0,0,1,2.63,1,3.43,3.43,0,0,1,1.11,2.6,2.68,2.68,0,0,1-.13.9.84.84,0,0,1-.42.48,2.37,2.37,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0h-4.52v.74a2.32,2.32,0,0,0,.85,1.89,3.29,3.29,0,0,0,2.19.71,3.33,3.33,0,0,0,1.19-.2,3.47,3.47,0,0,0,.78-.4.84.84,0,0,1,.43-.2.51.51,0,0,1,.39.21.69.69,0,0,1,.17.41c0,.28-.29.57-.86.87a4.61,4.61,0,0,1-2.14.45,4.53,4.53,0,0,1-3.1-1A3.48,3.48,0,0,1,288,44.64Zm1.3-1.64h4a1.62,1.62,0,0,0,.8-.13.63.63,0,0,0,.2-.55,2.47,2.47,0,0,0-.71-1.74,2.34,2.34,0,0,0-1.79-.76,2.41,2.41,0,0,0-1.77.72,2.27,2.27,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M301.45,45.36V34.84a.39.39,0,0,1,.2-.34.77.77,0,0,1,.44-.14.83.83,0,0,1,.46.14.42.42,0,0,1,.2.34v4h2.58a.41.41,0,0,1,.33.16.65.65,0,0,1,0,.78.41.41,0,0,1-.33.16h-2.58v5.39a1.78,1.78,0,0,0,.41,1.36,2.08,2.08,0,0,0,1.45.39h.56a.58.58,0,0,1,.43.18.57.57,0,0,1,.17.42.61.61,0,0,1-.17.43.56.56,0,0,1-.43.19h-.56Q301.45,48.3,301.45,45.36Z"/>
            <path className="cls-1" d="M307.43,47.78V33.54a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.82.82,0,0,1,.47.14.43.43,0,0,1,.19.34v6.72a2.82,2.82,0,0,1,1-1.09,2.87,2.87,0,0,1,1.61-.45,3.34,3.34,0,0,1,2.49,1.1,3.68,3.68,0,0,1,1.05,2.64v5.32a.46.46,0,0,1-.19.38.82.82,0,0,1-.47.14.77.77,0,0,1-.44-.14.42.42,0,0,1-.2-.38V42.47a2.49,2.49,0,0,0-.74-1.75,2.34,2.34,0,0,0-1.74-.78,2.32,2.32,0,0,0-1.67.7,2.4,2.4,0,0,0-.73,1.83v5.31a.48.48,0,0,1-.2.36.72.72,0,0,1-.46.16.64.64,0,0,1-.45-.16A.47.47,0,0,1,307.43,47.78Z"/>
            <path className="cls-1" d="M317,44.64V42.4a3.48,3.48,0,0,1,1.12-2.59,3.7,3.7,0,0,1,2.7-1.09,3.74,3.74,0,0,1,2.63,1,3.43,3.43,0,0,1,1.11,2.6,2.68,2.68,0,0,1-.13.9.84.84,0,0,1-.42.48,2.37,2.37,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0h-4.52v.74a2.32,2.32,0,0,0,.85,1.89,3.29,3.29,0,0,0,2.19.71,3.33,3.33,0,0,0,1.19-.2,3.47,3.47,0,0,0,.78-.4.84.84,0,0,1,.43-.2.51.51,0,0,1,.39.21.69.69,0,0,1,.17.41c0,.28-.29.57-.86.87a4.61,4.61,0,0,1-2.14.45,4.53,4.53,0,0,1-3.1-1A3.48,3.48,0,0,1,317,44.64Zm1.3-1.64h4a1.62,1.62,0,0,0,.8-.13.63.63,0,0,0,.2-.55,2.47,2.47,0,0,0-.71-1.74,2.34,2.34,0,0,0-1.79-.76,2.41,2.41,0,0,0-1.77.72,2.27,2.27,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M330.17,44.64V42.5a3.81,3.81,0,0,1,1-2.73A3.74,3.74,0,0,1,334,38.72a4.21,4.21,0,0,1,2,.43q.81.43.81.87a.65.65,0,0,1-.18.43.53.53,0,0,1-.4.21.75.75,0,0,1-.37-.18,4.26,4.26,0,0,0-.71-.35,2.85,2.85,0,0,0-1.1-.19,2.61,2.61,0,0,0-1.95.68,2.55,2.55,0,0,0-.67,1.89v2.12a2.57,2.57,0,0,0,.67,1.91,2.64,2.64,0,0,0,2,.68,3,3,0,0,0,1.15-.19,3,3,0,0,0,.72-.37.73.73,0,0,1,.39-.18.57.57,0,0,1,.43.2.67.67,0,0,1,.17.44c0,.31-.28.6-.84.89a4.51,4.51,0,0,1-2.08.43,3.51,3.51,0,0,1-3.88-3.8Z"/>
            <path className="cls-1" d="M338.25,44.64V42.46a3.57,3.57,0,0,1,1.12-2.63A3.63,3.63,0,0,1,342,38.72a3.76,3.76,0,0,1,2.68,1.1,3.53,3.53,0,0,1,1.14,2.64v2.18a3.93,3.93,0,0,1-3.82,3.8,3.6,3.6,0,0,1-2.64-1.15A3.66,3.66,0,0,1,338.25,44.64Zm1.3,0a2.54,2.54,0,0,0,.73,1.8,2.28,2.28,0,0,0,1.75.79,2.36,2.36,0,0,0,1.78-.79,2.55,2.55,0,0,0,.74-1.8V42.47a2.44,2.44,0,0,0-.75-1.75,2.38,2.38,0,0,0-1.77-.78,2.3,2.3,0,0,0-1.75.78,2.46,2.46,0,0,0-.73,1.75Z"/>
            <path className="cls-1" d="M348,47.78V39.32a.41.41,0,0,1,.18-.35.74.74,0,0,1,.46-.13.68.68,0,0,1,.42.14.44.44,0,0,1,.18.35v.93a2.77,2.77,0,0,1,1-1.09,2.94,2.94,0,0,1,1.64-.45,3.38,3.38,0,0,1,2.5,1.1,3.69,3.69,0,0,1,1.06,2.64v5.32a.44.44,0,0,1-.2.38.83.83,0,0,1-.46.14.77.77,0,0,1-.44-.14.42.42,0,0,1-.2-.38V42.47a2.49,2.49,0,0,0-.74-1.75,2.36,2.36,0,0,0-1.76-.78,2.29,2.29,0,0,0-1.66.7,2.43,2.43,0,0,0-.72,1.83v5.31a.48.48,0,0,1-.2.36.72.72,0,0,1-.46.16.65.65,0,0,1-.46-.16A.48.48,0,0,1,348,47.78Z"/>
            <path className="cls-1" d="M357.55,44.64V42.46a3.68,3.68,0,0,1,1.05-2.64,3.38,3.38,0,0,1,2.53-1.1,2.92,2.92,0,0,1,2.64,1.54V33.54a.39.39,0,0,1,.2-.34.83.83,0,0,1,.48-.14.73.73,0,0,1,.45.14.44.44,0,0,1,.17.34V47.78a.48.48,0,0,1-.18.36.63.63,0,0,1-.44.16.62.62,0,0,1-.43-.16.47.47,0,0,1-.19-.37v-.89a2.87,2.87,0,0,1-1.09,1.1,3.07,3.07,0,0,1-1.65.46,3.25,3.25,0,0,1-2.48-1.15A3.79,3.79,0,0,1,357.55,44.64Zm1.3,0a2.52,2.52,0,0,0,.74,1.79,2.3,2.3,0,0,0,1.74.8,2.38,2.38,0,0,0,1.7-.72,1.94,1.94,0,0,0,.74-1.25V42.47a2.42,2.42,0,0,0-.73-1.83,2.38,2.38,0,0,0-3.45.08,2.43,2.43,0,0,0-.74,1.75Z"/>
            <path className="cls-1" d="M367.58,35.79a.79.79,0,0,1-.25-.59.76.76,0,0,1,.25-.58.93.93,0,0,1,.65-.24.83.83,0,0,1,.6.24.76.76,0,0,1,.25.58.85.85,0,0,1-.85.84A.9.9,0,0,1,367.58,35.79Zm0,12V39.32a.43.43,0,0,1,.19-.34.72.72,0,0,1,.46-.14.82.82,0,0,1,.47.14.42.42,0,0,1,.18.34v8.46a.47.47,0,0,1-.19.36.72.72,0,0,1-.46.16.64.64,0,0,1-.45-.16A.48.48,0,0,1,367.58,47.78Z"/>
            <path className="cls-1" d="M371.37,45.36V34.84a.39.39,0,0,1,.2-.34.77.77,0,0,1,.44-.14.83.83,0,0,1,.46.14.42.42,0,0,1,.2.34v4h2.58a.41.41,0,0,1,.33.16.69.69,0,0,1,0,.78.41.41,0,0,1-.33.16h-2.58v5.39a1.78,1.78,0,0,0,.41,1.36,2.08,2.08,0,0,0,1.45.39h.56a.58.58,0,0,1,.43.18.57.57,0,0,1,.17.42.61.61,0,0,1-.17.43.56.56,0,0,1-.43.19h-.56Q371.37,48.3,371.37,45.36Z"/>
            <path className="cls-1" d="M377.35,35.79a.77.77,0,0,1-.26-.59.73.73,0,0,1,.26-.58.89.89,0,0,1,.64-.24.85.85,0,0,1,.61.24.79.79,0,0,1,.25.58.83.83,0,0,1-.25.59A.82.82,0,0,1,378,36,.86.86,0,0,1,377.35,35.79Zm0,12V39.32a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.82.82,0,0,1,.47.14.43.43,0,0,1,.19.34v8.46a.48.48,0,0,1-.2.36.72.72,0,0,1-.46.16.64.64,0,0,1-.45-.16A.47.47,0,0,1,377.35,47.78Z"/>
            <path className="cls-1" d="M380.79,44.64V42.46a3.57,3.57,0,0,1,1.12-2.63,3.63,3.63,0,0,1,2.66-1.11,3.76,3.76,0,0,1,2.68,1.1,3.53,3.53,0,0,1,1.14,2.64v2.18a3.93,3.93,0,0,1-3.82,3.8,3.6,3.6,0,0,1-2.64-1.15A3.66,3.66,0,0,1,380.79,44.64Zm1.3,0a2.54,2.54,0,0,0,.73,1.8,2.28,2.28,0,0,0,1.75.79,2.36,2.36,0,0,0,1.78-.79,2.55,2.55,0,0,0,.74-1.8V42.47a2.44,2.44,0,0,0-.75-1.75,2.38,2.38,0,0,0-1.77-.78,2.3,2.3,0,0,0-1.75.78,2.46,2.46,0,0,0-.73,1.75Z"/>
            <path className="cls-1" d="M390.53,47.78V39.32a.41.41,0,0,1,.18-.35.74.74,0,0,1,.46-.13.68.68,0,0,1,.42.14.44.44,0,0,1,.18.35v.93a2.77,2.77,0,0,1,1-1.09,2.94,2.94,0,0,1,1.64-.45,3.38,3.38,0,0,1,2.5,1.1A3.69,3.69,0,0,1,398,42.46v5.32a.44.44,0,0,1-.2.38.83.83,0,0,1-.46.14.77.77,0,0,1-.44-.14.42.42,0,0,1-.2-.38V42.47a2.49,2.49,0,0,0-.74-1.75,2.36,2.36,0,0,0-1.76-.78,2.29,2.29,0,0,0-1.66.7,2.43,2.43,0,0,0-.72,1.83v5.31a.48.48,0,0,1-.2.36.72.72,0,0,1-.46.16.65.65,0,0,1-.46-.16A.48.48,0,0,1,390.53,47.78Z"/>
            <path className="cls-1" d="M399.85,47a.65.65,0,0,1,.14-.4.4.4,0,0,1,.32-.2,1,1,0,0,1,.44.23,4.08,4.08,0,0,0,.86.47,3.38,3.38,0,0,0,1.34.24,2.49,2.49,0,0,0,1.56-.4,1.27,1.27,0,0,0,.5-1,1.43,1.43,0,0,0-.51-1.15,3.44,3.44,0,0,0-1.23-.65l-1.45-.44a2.72,2.72,0,0,1-1.24-.77,2.09,2.09,0,0,1-.51-1.47,2.64,2.64,0,0,1,.79-1.91,3.15,3.15,0,0,1,2.37-.81,5.25,5.25,0,0,1,1.83.31c.56.21.85.43.85.67a.88.88,0,0,1-.16.44.47.47,0,0,1-.38.24,5.24,5.24,0,0,1-.74-.28,3.58,3.58,0,0,0-1.38-.28,2.12,2.12,0,0,0-1.5.47,1.4,1.4,0,0,0-.5,1.06,1.12,1.12,0,0,0,.51,1,4.57,4.57,0,0,0,1.24.56l1.47.44a2.37,2.37,0,0,1,1.24.87,2.68,2.68,0,0,1,.49,1.68,2.31,2.31,0,0,1-.84,1.89,3.68,3.68,0,0,1-2.37.69,4.84,4.84,0,0,1-2.23-.47C400.15,47.66,399.85,47.33,399.85,47Z"/>
            <path className="cls-1" d="M412.15,47.8V36.69a3.6,3.6,0,0,1,1.06-2.6,3.35,3.35,0,0,1,2.5-1.09h.5a.5.5,0,0,1,.4.19.65.65,0,0,1,.16.43.61.61,0,0,1-.16.42.52.52,0,0,1-.4.18h-.34a2.3,2.3,0,0,0-1.71.71,2.4,2.4,0,0,0-.71,1.69v2.22h2.86a.41.41,0,0,1,.33.16.62.62,0,0,1,.13.37.69.69,0,0,1-.13.41.41.41,0,0,1-.33.16h-2.86V47.8a.42.42,0,0,1-.2.36.87.87,0,0,1-.48.14.7.7,0,0,1-.44-.14A.43.43,0,0,1,412.15,47.8Z"/>
            <path className="cls-1" d="M417.53,44.64V42.46a3.57,3.57,0,0,1,1.12-2.63,3.63,3.63,0,0,1,2.66-1.11,3.76,3.76,0,0,1,2.68,1.1,3.53,3.53,0,0,1,1.14,2.64v2.18a3.93,3.93,0,0,1-3.82,3.8,3.6,3.6,0,0,1-2.64-1.15A3.66,3.66,0,0,1,417.53,44.64Zm1.3,0a2.54,2.54,0,0,0,.73,1.8,2.28,2.28,0,0,0,1.75.79,2.36,2.36,0,0,0,1.78-.79,2.55,2.55,0,0,0,.74-1.8V42.47a2.44,2.44,0,0,0-.75-1.75,2.38,2.38,0,0,0-1.77-.78,2.3,2.3,0,0,0-1.75.78,2.46,2.46,0,0,0-.73,1.75Z"/>
            <path className="cls-1" d="M427.26,47.78V39.32a.43.43,0,0,1,.19-.34.72.72,0,0,1,.46-.14.69.69,0,0,1,.43.14.44.44,0,0,1,.18.35v1a3.17,3.17,0,0,1,1.06-1.13,2.88,2.88,0,0,1,1.62-.47h.71a.45.45,0,0,1,.35.18.66.66,0,0,1,0,.86.45.45,0,0,1-.35.18h-.71a2.49,2.49,0,0,0-1.83.81,2.84,2.84,0,0,0-.81,2.1v4.93a.48.48,0,0,1-.18.36.71.71,0,0,1-.47.16.68.68,0,0,1-.46-.16A.5.5,0,0,1,427.26,47.78Z"/>
            <path className="cls-1" d="M437.22,45.8a2.24,2.24,0,0,1,1.2-2.19,7.74,7.74,0,0,1,3.46-.61h1v-.47a3.16,3.16,0,0,0-.58-1.88,2.1,2.1,0,0,0-1.79-.79,3.61,3.61,0,0,0-1.45.28l-.68.29a.42.42,0,0,1-.38-.22.8.8,0,0,1-.15-.47c0-.25.29-.49.87-.7a5.25,5.25,0,0,1,1.83-.32,3.29,3.29,0,0,1,2.75,1.09,4.25,4.25,0,0,1,.87,2.77v5.2a.48.48,0,0,1-.17.37.71.71,0,0,1-.46.15.65.65,0,0,1-.44-.15.48.48,0,0,1-.17-.37V46.9a3.91,3.91,0,0,1-3,1.54,2.81,2.81,0,0,1-2-.67A2.48,2.48,0,0,1,437.22,45.8Zm1.31-.12a1.54,1.54,0,0,0,.47,1.25,1.93,1.93,0,0,0,1.26.41,2.74,2.74,0,0,0,1.79-.7,1.91,1.91,0,0,0,.86-1.4V44h-.84a12.91,12.91,0,0,0-1.36.06,5.92,5.92,0,0,0-1.08.22,1.59,1.59,0,0,0-.83.52A1.5,1.5,0,0,0,438.53,45.68Z"/>
            <path className="cls-1" d="M450.64,47.78V39.32a.4.4,0,0,1,.19-.35.72.72,0,0,1,.46-.13.68.68,0,0,1,.41.14.41.41,0,0,1,.18.35v.93a2.92,2.92,0,0,1,1-1.09,3,3,0,0,1,1.64-.45,3.36,3.36,0,0,1,2.5,1.1,3.65,3.65,0,0,1,1.07,2.64v5.32a.43.43,0,0,1-.21.38.79.79,0,0,1-.9,0,.44.44,0,0,1-.19-.38V42.47a2.46,2.46,0,0,0-.75-1.75,2.33,2.33,0,0,0-1.75-.78,2.28,2.28,0,0,0-1.66.7,2.4,2.4,0,0,0-.73,1.83v5.31a.47.47,0,0,1-.19.36.72.72,0,0,1-.46.16.63.63,0,0,1-.46-.16A.47.47,0,0,1,450.64,47.78Z"/>
            <path className="cls-1" d="M460.2,44.64V42.4a3.52,3.52,0,0,1,1.12-2.59,3.87,3.87,0,0,1,5.33-.05,3.43,3.43,0,0,1,1.11,2.6,2.45,2.45,0,0,1-.13.9.78.78,0,0,1-.42.48,2.21,2.21,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0H461.5v.74a2.35,2.35,0,0,0,.85,1.89,3.33,3.33,0,0,0,2.19.71,3.29,3.29,0,0,0,1.19-.2,3.47,3.47,0,0,0,.78-.4.87.87,0,0,1,.43-.2.49.49,0,0,1,.39.21.63.63,0,0,1,.17.41c0,.28-.28.57-.86.87a5.18,5.18,0,0,1-5.24-.6A3.48,3.48,0,0,1,460.2,44.64ZM461.5,43h4a1.59,1.59,0,0,0,.8-.13c.14-.09.2-.27.2-.55a2.43,2.43,0,0,0-.71-1.74,2.33,2.33,0,0,0-1.79-.76,2.44,2.44,0,0,0-1.77.72,2.31,2.31,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M468.76,39.38A.5.5,0,0,1,469,39a1,1,0,0,1,.53-.17.42.42,0,0,1,.46.3l2.56,7.12,2.07-6.5a.39.39,0,0,1,.43-.29.41.41,0,0,1,.44.34l2,6.58,2.62-7.25c0-.2.19-.3.44-.3a.94.94,0,0,1,.53.16.48.48,0,0,1,.25.42.56.56,0,0,1-.08.3l-3.12,8.46a.48.48,0,0,1-.52.32.42.42,0,0,1-.41-.29l-2.08-6.52-2.06,6.4a.57.57,0,0,1-1.09,0l-3.12-8.38A.75.75,0,0,1,468.76,39.38Z"/>
            <path className="cls-1" d="M34.54,80.78V66.52a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.79.79,0,0,1,.47.14.4.4,0,0,1,.19.34v9.74l4.88-4.34a.65.65,0,0,1,.3-.12.65.65,0,0,1,.45.19.57.57,0,0,1,.21.43.46.46,0,0,1-.14.36L38.6,75.36l3.46,5.06a.71.71,0,0,1,.08.28.62.62,0,0,1-.22.44.67.67,0,0,1-.48.22.47.47,0,0,1-.4-.24l-3.36-4.94L35.84,77.8v3a.47.47,0,0,1-.19.36.69.69,0,0,1-.47.16.67.67,0,0,1-.45-.16A.47.47,0,0,1,34.54,80.78Z"/>
            <path className="cls-1" d="M43.66,68.79a.8.8,0,0,1-.26-.59.77.77,0,0,1,.26-.58.91.91,0,0,1,.64-.24.85.85,0,0,1,.61.24.76.76,0,0,1,.25.58.79.79,0,0,1-.25.59.82.82,0,0,1-.61.25A.87.87,0,0,1,43.66,68.79Zm0,12V72.32a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.79.79,0,0,1,.47.14.4.4,0,0,1,.19.34v8.46a.48.48,0,0,1-.2.36.7.7,0,0,1-.46.16.67.67,0,0,1-.45-.16A.47.47,0,0,1,43.66,80.78Z"/>
            <path className="cls-1" d="M47.48,80.78V72.32a.41.41,0,0,1,.18-.35.74.74,0,0,1,.46-.13.68.68,0,0,1,.42.14.44.44,0,0,1,.18.35v.93a2.85,2.85,0,0,1,1-1.09,3,3,0,0,1,1.64-.45,3.38,3.38,0,0,1,2.5,1.1A3.65,3.65,0,0,1,55,75.46v5.32a.44.44,0,0,1-.2.38.81.81,0,0,1-.46.14.77.77,0,0,1-.44-.14.44.44,0,0,1-.2-.38V75.47a2.49,2.49,0,0,0-.74-1.75,2.34,2.34,0,0,0-1.76-.78,2.29,2.29,0,0,0-1.66.7,2.43,2.43,0,0,0-.72,1.83v5.31a.48.48,0,0,1-.2.36.7.7,0,0,1-.46.16.65.65,0,0,1-.46-.16A.48.48,0,0,1,47.48,80.78Z"/>
            <path className="cls-1" d="M57,77.64V75.46a3.68,3.68,0,0,1,1.05-2.64,3.38,3.38,0,0,1,2.53-1.1,2.92,2.92,0,0,1,2.64,1.54V66.54a.42.42,0,0,1,.2-.34.83.83,0,0,1,.48-.14.69.69,0,0,1,.45.14.44.44,0,0,1,.17.34V80.78a.48.48,0,0,1-.18.36.63.63,0,0,1-.44.16.64.64,0,0,1-.43-.16.47.47,0,0,1-.19-.37v-.89A2.87,2.87,0,0,1,62.23,81a3.07,3.07,0,0,1-1.65.46,3.26,3.26,0,0,1-2.48-1.15A3.79,3.79,0,0,1,57,77.64Zm1.3,0a2.56,2.56,0,0,0,.74,1.79,2.3,2.3,0,0,0,1.74.8,2.36,2.36,0,0,0,1.7-.72,1.94,1.94,0,0,0,.74-1.25V75.47a2.39,2.39,0,0,0-.73-1.83,2.38,2.38,0,0,0-3.45.08,2.47,2.47,0,0,0-.74,1.75Z"/>
            <path className="cls-1" d="M70.7,77.64V75.46a3.57,3.57,0,0,1,1.12-2.63,3.65,3.65,0,0,1,2.66-1.11,3.74,3.74,0,0,1,2.68,1.1,3.53,3.53,0,0,1,1.14,2.64v2.18a3.93,3.93,0,0,1-3.82,3.8,3.6,3.6,0,0,1-2.64-1.15A3.66,3.66,0,0,1,70.7,77.64Zm1.3,0a2.54,2.54,0,0,0,.73,1.8,2.29,2.29,0,0,0,1.75.79,2.34,2.34,0,0,0,1.78-.79,2.55,2.55,0,0,0,.74-1.8V75.47a2.44,2.44,0,0,0-.75-1.75,2.38,2.38,0,0,0-1.77-.78,2.32,2.32,0,0,0-1.75.78A2.46,2.46,0,0,0,72,75.47Z"/>
            <path className="cls-1" d="M80.44,80.8V69.69a3.6,3.6,0,0,1,1.06-2.6A3.37,3.37,0,0,1,84,66h.5a.5.5,0,0,1,.4.19.6.6,0,0,1,.16.43.57.57,0,0,1-.16.42.52.52,0,0,1-.4.18h-.34a2.3,2.3,0,0,0-1.71.71,2.4,2.4,0,0,0-.71,1.69v2.22H84.6a.41.41,0,0,1,.33.16.56.56,0,0,1,.13.37.63.63,0,0,1-.13.41.41.41,0,0,1-.33.16H81.74V80.8a.42.42,0,0,1-.2.36.83.83,0,0,1-.48.14.72.72,0,0,1-.44-.14A.43.43,0,0,1,80.44,80.8Z"/>
            <path className="cls-1" d="M90.54,80.78V72.32a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.72.72,0,0,1,.44.14.44.44,0,0,1,.18.35v1a3.08,3.08,0,0,1,1.06-1.13,2.88,2.88,0,0,1,1.62-.47h.7a.48.48,0,0,1,.36.18.66.66,0,0,1,0,.86.48.48,0,0,1-.36.18h-.7a2.51,2.51,0,0,0-1.84.81,2.87,2.87,0,0,0-.8,2.1v4.93a.47.47,0,0,1-.19.36.69.69,0,0,1-.47.16.67.67,0,0,1-.45-.16A.47.47,0,0,1,90.54,80.78Z"/>
            <path className="cls-1" d="M96.44,77.64V75.4a3.48,3.48,0,0,1,1.12-2.59,3.87,3.87,0,0,1,5.33,0,3.43,3.43,0,0,1,1.11,2.6,2.45,2.45,0,0,1-.13.9.84.84,0,0,1-.42.48,2.21,2.21,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0H97.74v.74a2.32,2.32,0,0,0,.85,1.89,3.7,3.7,0,0,0,3.38.51,3.47,3.47,0,0,0,.78-.4.84.84,0,0,1,.43-.2.49.49,0,0,1,.39.21.69.69,0,0,1,.17.41c0,.28-.29.57-.86.87a4.58,4.58,0,0,1-2.14.45,4.53,4.53,0,0,1-3.1-1A3.48,3.48,0,0,1,96.44,77.64ZM97.74,76h4a1.59,1.59,0,0,0,.8-.13.63.63,0,0,0,.2-.55,2.47,2.47,0,0,0-.71-1.74,2.33,2.33,0,0,0-1.79-.76,2.44,2.44,0,0,0-1.77.72,2.27,2.27,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M105.36,80a.72.72,0,0,1,.14-.4.4.4,0,0,1,.32-.2,1,1,0,0,1,.44.23,4.35,4.35,0,0,0,.86.47,3.38,3.38,0,0,0,1.34.24,2.45,2.45,0,0,0,1.56-.4,1.27,1.27,0,0,0,.5-1,1.43,1.43,0,0,0-.51-1.15,3.44,3.44,0,0,0-1.23-.65l-1.45-.44a2.72,2.72,0,0,1-1.24-.77,2.09,2.09,0,0,1-.51-1.47,2.64,2.64,0,0,1,.79-1.91,3.18,3.18,0,0,1,2.37-.81,5.25,5.25,0,0,1,1.83.31c.57.21.85.43.85.67a.79.79,0,0,1-.16.44.46.46,0,0,1-.38.24,5.24,5.24,0,0,1-.74-.28,3.51,3.51,0,0,0-1.38-.28,2.12,2.12,0,0,0-1.5.47,1.44,1.44,0,0,0-.5,1.06,1.11,1.11,0,0,0,.52,1,4.43,4.43,0,0,0,1.24.56l1.46.44a2.33,2.33,0,0,1,1.24.87,2.69,2.69,0,0,1,.5,1.68,2.29,2.29,0,0,1-.85,1.89,3.68,3.68,0,0,1-2.37.69,4.87,4.87,0,0,1-2.23-.47C105.66,80.66,105.36,80.33,105.36,80Z"/>
            <path className="cls-1" d="M113.66,85.44V72.32a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.72.72,0,0,1,.44.14.44.44,0,0,1,.18.35v.93a3.09,3.09,0,0,1,1.06-1.1,3,3,0,0,1,1.62-.44,3.42,3.42,0,0,1,2.54,1.1,3.65,3.65,0,0,1,1.06,2.64v2.18a3.8,3.8,0,0,1-1.07,2.65,3.27,3.27,0,0,1-2.47,1.15A3,3,0,0,1,116,81a2.91,2.91,0,0,1-1.08-1.1v5.56a.45.45,0,0,1-.19.34.69.69,0,0,1-.47.16.67.67,0,0,1-.45-.16A.45.45,0,0,1,113.66,85.44Zm1.3-7.19a1.92,1.92,0,0,0,.75,1.25,2.4,2.4,0,0,0,1.71.72,2.33,2.33,0,0,0,1.73-.8,2.53,2.53,0,0,0,.75-1.79V75.47a2.44,2.44,0,0,0-.75-1.75A2.42,2.42,0,0,0,115,75.47Z"/>
            <path className="cls-1" d="M123,77.64V75.46a3.57,3.57,0,0,1,1.12-2.63,3.65,3.65,0,0,1,2.66-1.11,3.74,3.74,0,0,1,2.68,1.1,3.53,3.53,0,0,1,1.14,2.64v2.18a3.93,3.93,0,0,1-3.82,3.8,3.6,3.6,0,0,1-2.64-1.15A3.66,3.66,0,0,1,123,77.64Zm1.3,0a2.54,2.54,0,0,0,.73,1.8,2.29,2.29,0,0,0,1.75.79,2.34,2.34,0,0,0,1.78-.79,2.55,2.55,0,0,0,.74-1.8V75.47a2.44,2.44,0,0,0-.75-1.75,2.38,2.38,0,0,0-1.77-.78,2.32,2.32,0,0,0-1.75.78,2.46,2.46,0,0,0-.73,1.75Z"/>
            <path className="cls-1" d="M132.7,80.78V72.32a.41.41,0,0,1,.18-.35.74.74,0,0,1,.46-.13.68.68,0,0,1,.42.14.44.44,0,0,1,.18.35v.93a2.85,2.85,0,0,1,1-1.09,3,3,0,0,1,1.64-.45,3.38,3.38,0,0,1,2.5,1.1,3.65,3.65,0,0,1,1.06,2.64v5.32a.44.44,0,0,1-.2.38.81.81,0,0,1-.46.14.77.77,0,0,1-.44-.14.44.44,0,0,1-.2-.38V75.47a2.49,2.49,0,0,0-.74-1.75,2.34,2.34,0,0,0-1.76-.78,2.29,2.29,0,0,0-1.66.7,2.43,2.43,0,0,0-.72,1.83v5.31a.48.48,0,0,1-.2.36.7.7,0,0,1-.46.16.65.65,0,0,1-.46-.16A.48.48,0,0,1,132.7,80.78Z"/>
            <path className="cls-1" d="M142,80a.72.72,0,0,1,.14-.4.4.4,0,0,1,.32-.2,1,1,0,0,1,.44.23,4.35,4.35,0,0,0,.86.47,3.38,3.38,0,0,0,1.34.24,2.45,2.45,0,0,0,1.56-.4,1.27,1.27,0,0,0,.5-1,1.43,1.43,0,0,0-.51-1.15,3.44,3.44,0,0,0-1.23-.65L144,76.68a2.72,2.72,0,0,1-1.24-.77,2.09,2.09,0,0,1-.51-1.47,2.64,2.64,0,0,1,.79-1.91,3.18,3.18,0,0,1,2.37-.81,5.25,5.25,0,0,1,1.83.31c.57.21.85.43.85.67a.79.79,0,0,1-.16.44.46.46,0,0,1-.38.24,5.24,5.24,0,0,1-.74-.28,3.51,3.51,0,0,0-1.38-.28,2.12,2.12,0,0,0-1.5.47,1.44,1.44,0,0,0-.5,1.06,1.11,1.11,0,0,0,.52,1,4.43,4.43,0,0,0,1.24.56l1.46.44a2.33,2.33,0,0,1,1.24.87,2.69,2.69,0,0,1,.5,1.68,2.29,2.29,0,0,1-.85,1.89,3.68,3.68,0,0,1-2.37.69,4.87,4.87,0,0,1-2.23-.47C142.32,80.66,142,80.33,142,80Z"/>
            <path className="cls-1" d="M150.32,68.79a.8.8,0,0,1-.26-.59.77.77,0,0,1,.26-.58.91.91,0,0,1,.64-.24.85.85,0,0,1,.61.24.76.76,0,0,1,.25.58.79.79,0,0,1-.25.59A.82.82,0,0,1,151,69,.87.87,0,0,1,150.32,68.79Zm0,12V72.32a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.79.79,0,0,1,.47.14.4.4,0,0,1,.19.34v8.46a.48.48,0,0,1-.2.36.7.7,0,0,1-.46.16.67.67,0,0,1-.45-.16A.47.47,0,0,1,150.32,80.78Z"/>
            <path className="cls-1" d="M154.14,80.78V66.54a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.79.79,0,0,1,.47.14.4.4,0,0,1,.19.34v6.72a2.91,2.91,0,0,1,1.05-1.1,3,3,0,0,1,1.61-.44,3.38,3.38,0,0,1,2.53,1.1,3.68,3.68,0,0,1,1.05,2.64v2.18a3.8,3.8,0,0,1-1.07,2.65,3.27,3.27,0,0,1-2.47,1.15,3.12,3.12,0,0,1-1.66-.46,2.65,2.65,0,0,1-1.08-1.1v.89a.49.49,0,0,1-.18.37.63.63,0,0,1-.44.16.67.67,0,0,1-.45-.16A.47.47,0,0,1,154.14,80.78Zm1.3-2.53a1.92,1.92,0,0,0,.75,1.25,2.4,2.4,0,0,0,1.71.72,2.3,2.3,0,0,0,1.74-.8,2.56,2.56,0,0,0,.74-1.79V75.47a2.47,2.47,0,0,0-.74-1.75,2.34,2.34,0,0,0-1.76-.78,2.46,2.46,0,0,0-2.44,2.53Z"/>
            <path className="cls-1" d="M164,80.78V66.52a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.79.79,0,0,1,.47.14.4.4,0,0,1,.19.34V80.78a.47.47,0,0,1-.19.36.69.69,0,0,1-.47.16.67.67,0,0,1-.45-.16A.47.47,0,0,1,164,80.78Z"/>
            <path className="cls-1" d="M167.6,77.64V75.4a3.48,3.48,0,0,1,1.12-2.59,3.87,3.87,0,0,1,5.33,0,3.43,3.43,0,0,1,1.11,2.6,2.45,2.45,0,0,1-.13.9.84.84,0,0,1-.42.48,2.21,2.21,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0H168.9v.74a2.32,2.32,0,0,0,.85,1.89,3.7,3.7,0,0,0,3.38.51,3.47,3.47,0,0,0,.78-.4.84.84,0,0,1,.43-.2.49.49,0,0,1,.39.21.69.69,0,0,1,.17.41c0,.28-.29.57-.86.87a4.58,4.58,0,0,1-2.14.45,4.53,4.53,0,0,1-3.1-1A3.48,3.48,0,0,1,167.6,77.64ZM168.9,76h4a1.59,1.59,0,0,0,.8-.13.63.63,0,0,0,.2-.55,2.47,2.47,0,0,0-.71-1.74,2.33,2.33,0,0,0-1.79-.76,2.44,2.44,0,0,0-1.77.72,2.27,2.27,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M181.12,80.78V72.32a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.72.72,0,0,1,.44.14.44.44,0,0,1,.18.35v1a3.08,3.08,0,0,1,1.06-1.13,2.88,2.88,0,0,1,1.62-.47h.7a.48.48,0,0,1,.36.18.66.66,0,0,1,0,.86.48.48,0,0,1-.36.18h-.7a2.51,2.51,0,0,0-1.84.81,2.87,2.87,0,0,0-.8,2.1v4.93a.47.47,0,0,1-.19.36.69.69,0,0,1-.47.16.67.67,0,0,1-.45-.16A.47.47,0,0,1,181.12,80.78Z"/>
            <path className="cls-1" d="M187,77.64V75.4a3.48,3.48,0,0,1,1.12-2.59,3.87,3.87,0,0,1,5.33,0,3.43,3.43,0,0,1,1.11,2.6,2.45,2.45,0,0,1-.13.9.84.84,0,0,1-.42.48,2.21,2.21,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0h-4.52v.74a2.32,2.32,0,0,0,.85,1.89,3.7,3.7,0,0,0,3.38.51,3.47,3.47,0,0,0,.78-.4.84.84,0,0,1,.43-.2.49.49,0,0,1,.39.21.69.69,0,0,1,.17.41c0,.28-.29.57-.86.87a4.58,4.58,0,0,1-2.14.45,4.53,4.53,0,0,1-3.1-1A3.48,3.48,0,0,1,187,77.64Zm1.3-1.64h4a1.59,1.59,0,0,0,.8-.13.63.63,0,0,0,.2-.55,2.47,2.47,0,0,0-.71-1.74,2.33,2.33,0,0,0-1.79-.76,2.44,2.44,0,0,0-1.77.72,2.27,2.27,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M195.78,83.28a2.29,2.29,0,0,1,1.7-2.18,1.4,1.4,0,0,1-1.1-1.37,1.59,1.59,0,0,1,1-1.43,3.14,3.14,0,0,1-1.1-2.53V75a3.16,3.16,0,0,1,.95-2.38,3.79,3.79,0,0,1,4.83-.08,3,3,0,0,1,.68-.91,1.11,1.11,0,0,1,.74-.34.58.58,0,0,1,.44.16.5.5,0,0,1,.16.36.4.4,0,0,1-.34.44,1.64,1.64,0,0,0-1.12.84,3.09,3.09,0,0,1,.56,1.89v.76a3.2,3.2,0,0,1-1,2.39,3.45,3.45,0,0,1-2.5.94,3.64,3.64,0,0,1-1.54-.33c-.41.22-.62.48-.62.77a.69.69,0,0,0,.46.64,4.08,4.08,0,0,0,1.14.31l1.5.19a9.14,9.14,0,0,1,1.51.31,2.37,2.37,0,0,1,1.15.73,2.07,2.07,0,0,1,.46,1.4,2.39,2.39,0,0,1-1.08,2.1,5,5,0,0,1-2.84.74,5.37,5.37,0,0,1-2.93-.72A2.21,2.21,0,0,1,195.78,83.28Zm1.26-.12a1.51,1.51,0,0,0,.75,1.32,3.65,3.65,0,0,0,2,.5,3.39,3.39,0,0,0,2-.5,1.58,1.58,0,0,0,.71-1.34,1.28,1.28,0,0,0-.2-.71,1.47,1.47,0,0,0-.47-.46,3.28,3.28,0,0,0-.71-.28,4.41,4.41,0,0,0-.78-.15l-.84-.09-.68-.07C197.63,81.75,197,82.35,197,83.16Zm.5-7.42a2.53,2.53,0,0,0,.59,1.72,2,2,0,0,0,1.61.66,2,2,0,0,0,1.57-.66,2.49,2.49,0,0,0,.59-1.7V75a2.39,2.39,0,0,0-.59-1.66,2.25,2.25,0,0,0-3.18,0,2.39,2.39,0,0,0-.59,1.66Z"/>
            <path className="cls-1" d="M205.58,68.79a.8.8,0,0,1-.26-.59.77.77,0,0,1,.26-.58.91.91,0,0,1,.64-.24.85.85,0,0,1,.61.24.76.76,0,0,1,.25.58.79.79,0,0,1-.25.59.82.82,0,0,1-.61.25A.87.87,0,0,1,205.58,68.79Zm0,12V72.32a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.79.79,0,0,1,.47.14.4.4,0,0,1,.19.34v8.46a.48.48,0,0,1-.2.36.7.7,0,0,1-.46.16.67.67,0,0,1-.45-.16A.47.47,0,0,1,205.58,80.78Z"/>
            <path className="cls-1" d="M209,77.64V75.46a3.57,3.57,0,0,1,1.12-2.63,3.65,3.65,0,0,1,2.66-1.11,3.74,3.74,0,0,1,2.68,1.1,3.53,3.53,0,0,1,1.14,2.64v2.18a3.93,3.93,0,0,1-3.82,3.8,3.6,3.6,0,0,1-2.64-1.15A3.66,3.66,0,0,1,209,77.64Zm1.3,0a2.54,2.54,0,0,0,.73,1.8,2.29,2.29,0,0,0,1.75.79,2.34,2.34,0,0,0,1.78-.79,2.55,2.55,0,0,0,.74-1.8V75.47a2.44,2.44,0,0,0-.75-1.75,2.38,2.38,0,0,0-1.77-.78,2.32,2.32,0,0,0-1.75.78,2.46,2.46,0,0,0-.73,1.75Z"/>
            <path className="cls-1" d="M218.76,80.78V72.32a.41.41,0,0,1,.18-.35.74.74,0,0,1,.46-.13.68.68,0,0,1,.42.14.44.44,0,0,1,.18.35v.93a2.85,2.85,0,0,1,1-1.09,3,3,0,0,1,1.64-.45,3.38,3.38,0,0,1,2.5,1.1,3.65,3.65,0,0,1,1.06,2.64v5.32a.44.44,0,0,1-.2.38.81.81,0,0,1-.46.14.77.77,0,0,1-.44-.14.44.44,0,0,1-.2-.38V75.47a2.49,2.49,0,0,0-.74-1.75,2.34,2.34,0,0,0-1.76-.78,2.29,2.29,0,0,0-1.66.7,2.43,2.43,0,0,0-.72,1.83v5.31a.48.48,0,0,1-.2.36.7.7,0,0,1-.46.16.65.65,0,0,1-.46-.16A.48.48,0,0,1,218.76,80.78Z"/>
            <path className="cls-1" d="M228,78.8a2.25,2.25,0,0,1,1.19-2.19,7.76,7.76,0,0,1,3.47-.61h1v-.47a3.09,3.09,0,0,0-.58-1.88,2.08,2.08,0,0,0-1.78-.79,3.57,3.57,0,0,0-1.45.28c-.44.19-.66.29-.69.29a.42.42,0,0,1-.38-.22.86.86,0,0,1-.14-.47c0-.25.29-.49.86-.7a5.31,5.31,0,0,1,1.84-.32,3.3,3.3,0,0,1,2.75,1.09,4.31,4.31,0,0,1,.87,2.77v5.2a.46.46,0,0,1-.18.37.69.69,0,0,1-.46.15.63.63,0,0,1-.43-.15.48.48,0,0,1-.17-.37V79.9a3.93,3.93,0,0,1-3,1.54,2.84,2.84,0,0,1-2-.67A2.48,2.48,0,0,1,228,78.8Zm1.3-.12a1.55,1.55,0,0,0,.48,1.25,1.89,1.89,0,0,0,1.26.41,2.76,2.76,0,0,0,1.79-.7,1.9,1.9,0,0,0,.85-1.4V77h-.84a12.84,12.84,0,0,0-1.35.06,5.85,5.85,0,0,0-1.09.22,1.6,1.6,0,0,0-.82.52A1.44,1.44,0,0,0,229.32,78.68Z"/>
            <path className="cls-1" d="M237.6,80.78V66.52a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.82.82,0,0,1,.47.14.43.43,0,0,1,.19.34V80.78a.5.5,0,0,1-.19.36.71.71,0,0,1-.47.16.67.67,0,0,1-.45-.16A.47.47,0,0,1,237.6,80.78Z"/>
            <path className="cls-1" d="M245.58,85.44V72.32a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.72.72,0,0,1,.44.14.44.44,0,0,1,.18.35v.93a3.09,3.09,0,0,1,1.06-1.1,3,3,0,0,1,1.62-.44,3.43,3.43,0,0,1,2.54,1.1,3.69,3.69,0,0,1,1.06,2.64v2.18a3.8,3.8,0,0,1-1.07,2.65,3.29,3.29,0,0,1-2.47,1.15A3,3,0,0,1,248,81a2.77,2.77,0,0,1-1.08-1.1v5.56a.48.48,0,0,1-.19.34.69.69,0,0,1-.47.16.64.64,0,0,1-.45-.16A.45.45,0,0,1,245.58,85.44Zm1.3-7.19a1.92,1.92,0,0,0,.75,1.25,2.4,2.4,0,0,0,1.71.72,2.33,2.33,0,0,0,1.73-.8,2.53,2.53,0,0,0,.75-1.79V75.47a2.44,2.44,0,0,0-.75-1.75,2.38,2.38,0,0,0-1.77-.78,2.34,2.34,0,0,0-1.69.7,2.39,2.39,0,0,0-.73,1.83Z"/>
            <path className="cls-1" d="M255.42,80.78V66.52a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.82.82,0,0,1,.47.14.43.43,0,0,1,.19.34V80.78a.5.5,0,0,1-.19.36.71.71,0,0,1-.47.16.64.64,0,0,1-.45-.16A.47.47,0,0,1,255.42,80.78Z"/>
            <path className="cls-1" d="M258.74,78.8a2.24,2.24,0,0,1,1.19-2.19A7.76,7.76,0,0,1,263.4,76h1v-.47a3.16,3.16,0,0,0-.58-1.88,2.08,2.08,0,0,0-1.78-.79,3.54,3.54,0,0,0-1.45.28l-.69.29a.42.42,0,0,1-.38-.22.79.79,0,0,1-.14-.47c0-.25.28-.49.86-.7a5.27,5.27,0,0,1,1.84-.32,3.3,3.3,0,0,1,2.75,1.09,4.31,4.31,0,0,1,.87,2.77v5.2a.46.46,0,0,1-.18.37.71.71,0,0,1-.46.15.61.61,0,0,1-.43-.15.45.45,0,0,1-.17-.37V79.9a3.94,3.94,0,0,1-3,1.54,2.83,2.83,0,0,1-2-.67A2.48,2.48,0,0,1,258.74,78.8Zm1.3-.12a1.55,1.55,0,0,0,.48,1.25,1.89,1.89,0,0,0,1.26.41,2.78,2.78,0,0,0,1.79-.7,1.93,1.93,0,0,0,.85-1.4V77h-.84a12.58,12.58,0,0,0-1.35.06,5.85,5.85,0,0,0-1.09.22,1.56,1.56,0,0,0-.82.52A1.44,1.44,0,0,0,260,78.68Z"/>
            <path className="cls-1" d="M268.16,80.78V72.32a.41.41,0,0,1,.18-.35.74.74,0,0,1,.46-.13.68.68,0,0,1,.42.14.44.44,0,0,1,.18.35v.93a2.77,2.77,0,0,1,1-1.09,2.94,2.94,0,0,1,1.64-.45,3.38,3.38,0,0,1,2.5,1.1,3.69,3.69,0,0,1,1.06,2.64v5.32a.44.44,0,0,1-.2.38.83.83,0,0,1-.46.14.77.77,0,0,1-.44-.14.42.42,0,0,1-.2-.38V75.47a2.49,2.49,0,0,0-.74-1.75,2.36,2.36,0,0,0-1.76-.78,2.29,2.29,0,0,0-1.66.7,2.43,2.43,0,0,0-.72,1.83v5.31a.48.48,0,0,1-.2.36.72.72,0,0,1-.46.16.65.65,0,0,1-.46-.16A.48.48,0,0,1,268.16,80.78Z"/>
            <path className="cls-1" d="M278.08,80.78V72.32a.41.41,0,0,1,.18-.35.74.74,0,0,1,.46-.13.68.68,0,0,1,.42.14.44.44,0,0,1,.18.35v.93a2.77,2.77,0,0,1,1-1.09,2.94,2.94,0,0,1,1.64-.45,3.38,3.38,0,0,1,2.5,1.1,3.69,3.69,0,0,1,1.06,2.64v5.32a.44.44,0,0,1-.2.38.83.83,0,0,1-.46.14.77.77,0,0,1-.44-.14.42.42,0,0,1-.2-.38V75.47a2.49,2.49,0,0,0-.74-1.75,2.36,2.36,0,0,0-1.76-.78,2.29,2.29,0,0,0-1.66.7,2.43,2.43,0,0,0-.72,1.83v5.31a.48.48,0,0,1-.2.36.72.72,0,0,1-.46.16.65.65,0,0,1-.46-.16A.48.48,0,0,1,278.08,80.78Z"/>
            <path className="cls-1" d="M288,68.79a.77.77,0,0,1-.26-.59.73.73,0,0,1,.26-.58.89.89,0,0,1,.64-.24.85.85,0,0,1,.61.24.79.79,0,0,1,.25.58.83.83,0,0,1-.25.59.82.82,0,0,1-.61.25A.86.86,0,0,1,288,68.79Zm0,12V72.32a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.82.82,0,0,1,.47.14.43.43,0,0,1,.19.34v8.46a.48.48,0,0,1-.2.36.72.72,0,0,1-.46.16.64.64,0,0,1-.45-.16A.47.47,0,0,1,288,80.78Z"/>
            <path className="cls-1" d="M291.82,80.78V72.32A.41.41,0,0,1,292,72a.74.74,0,0,1,.46-.13.68.68,0,0,1,.42.14.44.44,0,0,1,.18.35v.93a2.77,2.77,0,0,1,1-1.09,2.94,2.94,0,0,1,1.64-.45,3.38,3.38,0,0,1,2.5,1.1,3.69,3.69,0,0,1,1.06,2.64v5.32a.44.44,0,0,1-.2.38.83.83,0,0,1-.46.14.77.77,0,0,1-.44-.14.42.42,0,0,1-.2-.38V75.47a2.49,2.49,0,0,0-.74-1.75,2.36,2.36,0,0,0-1.76-.78,2.29,2.29,0,0,0-1.66.7,2.43,2.43,0,0,0-.72,1.83v5.31a.48.48,0,0,1-.2.36.72.72,0,0,1-.46.16.65.65,0,0,1-.46-.16A.48.48,0,0,1,291.82,80.78Z"/>
            <path className="cls-1" d="M301,83.28a2.28,2.28,0,0,1,1.7-2.18,1.39,1.39,0,0,1-1.1-1.37,1.59,1.59,0,0,1,1-1.43,3.11,3.11,0,0,1-1.1-2.53V75a3.13,3.13,0,0,1,.95-2.38,3.79,3.79,0,0,1,4.83-.08,2.89,2.89,0,0,1,.68-.91,1.11,1.11,0,0,1,.74-.34.6.6,0,0,1,.44.16.54.54,0,0,1,.16.36.41.41,0,0,1-.34.44,1.64,1.64,0,0,0-1.12.84,3.09,3.09,0,0,1,.56,1.89v.76a3.2,3.2,0,0,1-1,2.39,3.47,3.47,0,0,1-2.5.94,3.64,3.64,0,0,1-1.54-.33c-.42.22-.62.48-.62.77a.69.69,0,0,0,.46.64,4,4,0,0,0,1.14.31l1.5.19a9.36,9.36,0,0,1,1.51.31,2.37,2.37,0,0,1,1.15.73,2.12,2.12,0,0,1,.46,1.4,2.39,2.39,0,0,1-1.08,2.1,5,5,0,0,1-2.84.74,5.37,5.37,0,0,1-2.93-.72A2.19,2.19,0,0,1,301,83.28Zm1.26-.12a1.51,1.51,0,0,0,.75,1.32,3.63,3.63,0,0,0,2,.5,3.42,3.42,0,0,0,2-.5,1.58,1.58,0,0,0,.71-1.34,1.28,1.28,0,0,0-.2-.71A1.58,1.58,0,0,0,307,82a3.28,3.28,0,0,0-.71-.28,4.58,4.58,0,0,0-.78-.15l-.84-.09-.68-.07C302.83,81.75,302.24,82.35,302.24,83.16Zm.5-7.42a2.48,2.48,0,0,0,.59,1.72,2,2,0,0,0,1.61.66,2,2,0,0,0,1.57-.66,2.49,2.49,0,0,0,.59-1.7V75a2.39,2.39,0,0,0-.59-1.66,2,2,0,0,0-1.59-.66,2,2,0,0,0-1.59.66,2.34,2.34,0,0,0-.59,1.66Z"/>
            <path className="cls-1" d="M310.32,80.43a1,1,0,0,1,.25-.72.82.82,0,0,1,.64-.29.8.8,0,0,1,.61.3,1.07,1.07,0,0,1,.26.71,1,1,0,0,1-.26.69.8.8,0,0,1-.61.3.82.82,0,0,1-.64-.29A1,1,0,0,1,310.32,80.43Z"/>
            <path className="cls-1" d="M317.66,79.3a.8.8,0,0,1,.19-.49.5.5,0,0,1,.41-.25.68.68,0,0,1,.4.26l.56.58a2.58,2.58,0,0,0,.95.57,4.08,4.08,0,0,0,1.51.25,3.18,3.18,0,0,0,2.2-.71,2.63,2.63,0,0,0,.78-2.05,3,3,0,0,0-.37-1.53,2.76,2.76,0,0,0-1-1,7.64,7.64,0,0,0-1.28-.64l-1.44-.56a6.52,6.52,0,0,1-1.29-.67,2.92,2.92,0,0,1-.94-1.1A3.57,3.57,0,0,1,318,70.3a3.86,3.86,0,0,1,.39-1.8,2.77,2.77,0,0,1,1-1.14,5.19,5.19,0,0,1,1.35-.55,6.19,6.19,0,0,1,1.45-.17,7.47,7.47,0,0,1,2.29.35c.71.23,1.07.52,1.07.87a.91.91,0,0,1-.17.49.49.49,0,0,1-.41.25,1.16,1.16,0,0,1-.46-.19,6.53,6.53,0,0,0-.95-.37,4.93,4.93,0,0,0-1.39-.18,3.69,3.69,0,0,0-2.08.54,2,2,0,0,0-.82,1.78,2.15,2.15,0,0,0,.49,1.44,2.66,2.66,0,0,0,1.23.84c.49.17,1,.38,1.6.62s1.1.5,1.6.77a3.23,3.23,0,0,1,1.23,1.3,4.55,4.55,0,0,1,.49,2.19,3.9,3.9,0,0,1-1.14,3.07,4.41,4.41,0,0,1-3.08,1,5.41,5.41,0,0,1-2.92-.74C318.05,80.2,317.66,79.74,317.66,79.3Z"/>
            <path className="cls-1" d="M327.72,77.64V75.4a3.48,3.48,0,0,1,1.12-2.59,3.7,3.7,0,0,1,2.7-1.09,3.74,3.74,0,0,1,2.63,1,3.43,3.43,0,0,1,1.11,2.6,2.68,2.68,0,0,1-.13.9.84.84,0,0,1-.42.48,2.37,2.37,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0H329v.74a2.32,2.32,0,0,0,.85,1.89,3.29,3.29,0,0,0,2.19.71,3.33,3.33,0,0,0,1.19-.2,3.47,3.47,0,0,0,.78-.4.84.84,0,0,1,.43-.2.51.51,0,0,1,.39.21.69.69,0,0,1,.17.41c0,.28-.29.57-.86.87a4.61,4.61,0,0,1-2.14.45,4.53,4.53,0,0,1-3.1-1A3.48,3.48,0,0,1,327.72,77.64ZM329,76h4a1.62,1.62,0,0,0,.8-.13.63.63,0,0,0,.2-.55,2.47,2.47,0,0,0-.71-1.74,2.34,2.34,0,0,0-1.79-.76,2.41,2.41,0,0,0-1.77.72,2.27,2.27,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M336.88,77.64V75.4A3.48,3.48,0,0,1,338,72.81a3.7,3.7,0,0,1,2.7-1.09,3.74,3.74,0,0,1,2.63,1,3.43,3.43,0,0,1,1.11,2.6,2.68,2.68,0,0,1-.13.9.84.84,0,0,1-.42.48,2.37,2.37,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0h-4.52v.74a2.32,2.32,0,0,0,.85,1.89,3.29,3.29,0,0,0,2.19.71,3.33,3.33,0,0,0,1.19-.2,3.47,3.47,0,0,0,.78-.4.84.84,0,0,1,.43-.2.51.51,0,0,1,.39.21.69.69,0,0,1,.17.41c0,.28-.29.57-.86.87a4.61,4.61,0,0,1-2.14.45,4.53,4.53,0,0,1-3.1-1A3.48,3.48,0,0,1,336.88,77.64Zm1.3-1.64h4a1.62,1.62,0,0,0,.8-.13.63.63,0,0,0,.2-.55,2.47,2.47,0,0,0-.71-1.74,2.34,2.34,0,0,0-1.79-.76,2.41,2.41,0,0,0-1.77.72,2.27,2.27,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M346.46,80.6V67.36a.62.62,0,0,1,.2-.48.69.69,0,0,1,.5-.2H351c3.06,0,4.6,1.36,4.6,4.08a4.25,4.25,0,0,1-.76,2.66,3.67,3.67,0,0,1-2.06,1.28l2.8,5.28a.61.61,0,0,1,.08.3,1,1,0,0,1-.41.74,1.35,1.35,0,0,1-.87.34,1,1,0,0,1-.84-.62L350.58,75h-2v5.6a.6.6,0,0,1-.31.51,1.39,1.39,0,0,1-.75.19,1.32,1.32,0,0,1-.73-.19A.58.58,0,0,1,346.46,80.6Zm2.1-7.28H351c1.65,0,2.48-.79,2.48-2.38s-.83-2.38-2.48-2.38h-2.4Z"/>
            <path className="cls-1" d="M357.64,80.6V67.36a.62.62,0,0,1,.2-.48.69.69,0,0,1,.5-.2h3.8c3.06,0,4.6,1.36,4.6,4.08a4.25,4.25,0,0,1-.76,2.66,3.67,3.67,0,0,1-2.06,1.28l2.8,5.28a.61.61,0,0,1,.08.3,1,1,0,0,1-.41.74,1.35,1.35,0,0,1-.87.34,1,1,0,0,1-.84-.62L361.76,75h-2v5.6a.6.6,0,0,1-.31.51,1.39,1.39,0,0,1-.75.19,1.32,1.32,0,0,1-.73-.19A.58.58,0,0,1,357.64,80.6Zm2.1-7.28h2.4c1.65,0,2.48-.79,2.48-2.38s-.83-2.38-2.48-2.38h-2.4Z"/>
            <path className="cls-1" d="M368.82,80.6V67.36a.54.54,0,0,1,.31-.51,1.54,1.54,0,0,1,.75-.17,1.6,1.6,0,0,1,.75.17.55.55,0,0,1,.31.51V80.6a.6.6,0,0,1-.31.51,1.56,1.56,0,0,1-1.49,0A.57.57,0,0,1,368.82,80.6Z"/>
            <path className="cls-1" d="M377.16,77.64V75.46a3.68,3.68,0,0,1,1-2.64,3.38,3.38,0,0,1,2.53-1.1,2.92,2.92,0,0,1,2.64,1.54V66.54a.39.39,0,0,1,.2-.34.83.83,0,0,1,.48-.14.73.73,0,0,1,.45.14.44.44,0,0,1,.17.34V80.78a.48.48,0,0,1-.18.36.63.63,0,0,1-.44.16.62.62,0,0,1-.43-.16.47.47,0,0,1-.19-.37v-.89a2.87,2.87,0,0,1-1.09,1.1,3.07,3.07,0,0,1-1.65.46,3.25,3.25,0,0,1-2.48-1.15A3.79,3.79,0,0,1,377.16,77.64Zm1.3,0a2.52,2.52,0,0,0,.74,1.79,2.3,2.3,0,0,0,1.74.8,2.38,2.38,0,0,0,1.7-.72,1.94,1.94,0,0,0,.74-1.25V75.47a2.42,2.42,0,0,0-.73-1.83,2.38,2.38,0,0,0-3.45.08,2.43,2.43,0,0,0-.74,1.75Z"/>
            <path className="cls-1" d="M386.84,77.64V75.4A3.48,3.48,0,0,1,388,72.81a3.7,3.7,0,0,1,2.7-1.09,3.74,3.74,0,0,1,2.63,1,3.43,3.43,0,0,1,1.11,2.6,2.68,2.68,0,0,1-.13.9.84.84,0,0,1-.42.48,2.37,2.37,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0h-4.52v.74a2.32,2.32,0,0,0,.85,1.89,3.29,3.29,0,0,0,2.19.71,3.33,3.33,0,0,0,1.19-.2,3.47,3.47,0,0,0,.78-.4.84.84,0,0,1,.43-.2.51.51,0,0,1,.39.21.69.69,0,0,1,.17.41c0,.28-.29.57-.86.87a4.61,4.61,0,0,1-2.14.45,4.53,4.53,0,0,1-3.1-1A3.48,3.48,0,0,1,386.84,77.64Zm1.3-1.64h4a1.62,1.62,0,0,0,.8-.13.63.63,0,0,0,.2-.55,2.47,2.47,0,0,0-.71-1.74,2.34,2.34,0,0,0-1.79-.76,2.41,2.41,0,0,0-1.77.72,2.27,2.27,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M395.7,78.8a2.24,2.24,0,0,1,1.19-2.19,7.76,7.76,0,0,1,3.47-.61h1v-.47a3.16,3.16,0,0,0-.58-1.88,2.08,2.08,0,0,0-1.78-.79,3.54,3.54,0,0,0-1.45.28l-.69.29a.42.42,0,0,1-.38-.22.79.79,0,0,1-.14-.47c0-.25.28-.49.86-.7a5.27,5.27,0,0,1,1.84-.32,3.3,3.3,0,0,1,2.75,1.09,4.31,4.31,0,0,1,.87,2.77v5.2a.46.46,0,0,1-.18.37.71.71,0,0,1-.46.15.61.61,0,0,1-.43-.15.45.45,0,0,1-.17-.37V79.9a3.94,3.94,0,0,1-3,1.54,2.83,2.83,0,0,1-2-.67A2.48,2.48,0,0,1,395.7,78.8Zm1.3-.12a1.55,1.55,0,0,0,.48,1.25,1.89,1.89,0,0,0,1.26.41,2.78,2.78,0,0,0,1.79-.7,1.93,1.93,0,0,0,.85-1.4V77h-.84a12.58,12.58,0,0,0-1.35.06,5.85,5.85,0,0,0-1.09.22,1.56,1.56,0,0,0-.82.52A1.44,1.44,0,0,0,397,78.68Z"/>
            <path className="cls-1" d="M405.28,80.78V66.52a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.82.82,0,0,1,.47.14.43.43,0,0,1,.19.34V80.78a.5.5,0,0,1-.19.36.71.71,0,0,1-.47.16.64.64,0,0,1-.45-.16A.47.47,0,0,1,405.28,80.78Z"/>
            <path className="cls-1" d="M408.66,80a.65.65,0,0,1,.14-.4.4.4,0,0,1,.32-.2,1,1,0,0,1,.44.23,4.08,4.08,0,0,0,.86.47,3.38,3.38,0,0,0,1.34.24,2.49,2.49,0,0,0,1.56-.4,1.27,1.27,0,0,0,.5-1,1.43,1.43,0,0,0-.51-1.15,3.44,3.44,0,0,0-1.23-.65l-1.45-.44a2.72,2.72,0,0,1-1.24-.77,2.09,2.09,0,0,1-.51-1.47,2.64,2.64,0,0,1,.79-1.91,3.15,3.15,0,0,1,2.37-.81,5.25,5.25,0,0,1,1.83.31c.56.21.85.43.85.67a.88.88,0,0,1-.16.44.47.47,0,0,1-.38.24,5.24,5.24,0,0,1-.74-.28,3.58,3.58,0,0,0-1.38-.28,2.12,2.12,0,0,0-1.5.47,1.4,1.4,0,0,0-.5,1.06,1.12,1.12,0,0,0,.51,1,4.57,4.57,0,0,0,1.24.56l1.47.44a2.37,2.37,0,0,1,1.24.87,2.69,2.69,0,0,1,.5,1.68,2.32,2.32,0,0,1-.85,1.89,3.68,3.68,0,0,1-2.37.69,4.84,4.84,0,0,1-2.23-.47C409,80.66,408.66,80.33,408.66,80Z"/>
            <path className="cls-1" d="M420,72.38a.48.48,0,0,1,.25-.41.93.93,0,0,1,.53-.17.43.43,0,0,1,.46.3l2.56,7.12,2.06-6.5a.39.39,0,0,1,.43-.29.4.4,0,0,1,.44.34l2,6.58,2.62-7.25c0-.2.19-.3.44-.3a.92.92,0,0,1,.53.16.52.52,0,0,1,.17.72l-3.12,8.46a.47.47,0,0,1-.52.32.42.42,0,0,1-.41-.29l-2.08-6.52-2,6.4a.52.52,0,0,1-.55.41.51.51,0,0,1-.54-.4l-3.12-8.38A.61.61,0,0,1,420,72.38Z"/>
            <path className="cls-1" d="M434.3,68.79a.77.77,0,0,1-.26-.59.73.73,0,0,1,.26-.58.89.89,0,0,1,.64-.24.85.85,0,0,1,.61.24.79.79,0,0,1,.25.58.83.83,0,0,1-.25.59.82.82,0,0,1-.61.25A.86.86,0,0,1,434.3,68.79Zm0,12V72.32a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.82.82,0,0,1,.47.14.43.43,0,0,1,.19.34v8.46a.48.48,0,0,1-.2.36.72.72,0,0,1-.46.16.64.64,0,0,1-.45-.16A.47.47,0,0,1,434.3,80.78Z"/>
            <path className="cls-1" d="M438.08,78.36V67.84a.39.39,0,0,1,.2-.34.77.77,0,0,1,.44-.14.83.83,0,0,1,.46.14.42.42,0,0,1,.2.34v4H442a.41.41,0,0,1,.33.16.65.65,0,0,1,0,.78.41.41,0,0,1-.33.16h-2.58v5.39a1.78,1.78,0,0,0,.41,1.36,2.08,2.08,0,0,0,1.45.39h.56a.58.58,0,0,1,.43.18.57.57,0,0,1,.17.42.61.61,0,0,1-.17.43.56.56,0,0,1-.43.19h-.56Q438.08,81.3,438.08,78.36Z"/>
            <path className="cls-1" d="M444.06,80.78V66.54a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.82.82,0,0,1,.47.14.43.43,0,0,1,.19.34v6.72a2.82,2.82,0,0,1,1-1.09,2.87,2.87,0,0,1,1.61-.45,3.34,3.34,0,0,1,2.49,1.1,3.68,3.68,0,0,1,1.05,2.64v5.32a.46.46,0,0,1-.19.38.82.82,0,0,1-.47.14.77.77,0,0,1-.44-.14.42.42,0,0,1-.2-.38V75.47a2.49,2.49,0,0,0-.74-1.75,2.34,2.34,0,0,0-1.74-.78,2.32,2.32,0,0,0-1.67.7,2.4,2.4,0,0,0-.73,1.83v5.31a.48.48,0,0,1-.2.36.72.72,0,0,1-.46.16.64.64,0,0,1-.45-.16A.47.47,0,0,1,444.06,80.78Z"/>
            <path className="cls-1" d="M6.48,110.48v-1.8a4,4,0,0,1,1-2.88,4.08,4.08,0,0,1,3.1-1.1,4.76,4.76,0,0,1,2.18.45c.59.3.88.64.88,1a1.12,1.12,0,0,1-.25.68.71.71,0,0,1-.59.34,3.28,3.28,0,0,1-.71-.3,3,3,0,0,0-1.35-.3c-1.47,0-2.2.7-2.2,2.11v1.78c0,1.41.74,2.11,2.22,2.11a2.94,2.94,0,0,0,1.37-.32,3.09,3.09,0,0,1,.73-.32.8.8,0,0,1,.65.32,1.06,1.06,0,0,1,.25.68c0,.37-.31.72-.92,1a4.78,4.78,0,0,1-2.24.48,4.1,4.1,0,0,1-3.12-1.09A4,4,0,0,1,6.48,110.48Z"/>
            <path className="cls-1" d="M14.84,110.48v-1.84a3.74,3.74,0,0,1,1.22-2.76A4,4,0,0,1,19,104.7a4.09,4.09,0,0,1,2.9,1.17,3.67,3.67,0,0,1,1.24,2.77v1.84a3.77,3.77,0,0,1-1.25,2.77,4.08,4.08,0,0,1-5.79,0A3.75,3.75,0,0,1,14.84,110.48Zm2.1,0a2.08,2.08,0,0,0,.6,1.46,1.91,1.91,0,0,0,2.87,0,2,2,0,0,0,.61-1.46v-1.82a2,2,0,0,0-.61-1.43,1.93,1.93,0,0,0-2.87,0,2,2,0,0,0-.6,1.43Z"/>
            <path className="cls-1" d="M25,113.6v-8.1a.58.58,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.14,1.14,0,0,1,.66.19.58.58,0,0,1,.28.5v.73a2.84,2.84,0,0,1,2.66-1.54,2.44,2.44,0,0,1,1.57.56,3.44,3.44,0,0,1,1,1.46,3.75,3.75,0,0,1,1.31-1.51,3.16,3.16,0,0,1,1.71-.51,3.3,3.3,0,0,1,2.45,1.07,3.94,3.94,0,0,1,1,2.87v5a.62.62,0,0,1-.32.48,1.24,1.24,0,0,1-.74.22,1.2,1.2,0,0,1-.72-.22.62.62,0,0,1-.32-.48v-4.95a2.07,2.07,0,0,0-.56-1.53,1.8,1.8,0,0,0-1.3-.56,1.84,1.84,0,0,0-1.31.56,2,2,0,0,0-.57,1.49v5a.54.54,0,0,1-.31.51,1.6,1.6,0,0,1-.75.17,1.53,1.53,0,0,1-.72-.17.55.55,0,0,1-.32-.51v-5a2,2,0,0,0-.55-1.47,1.73,1.73,0,0,0-1.31-.58,1.79,1.79,0,0,0-1.31.56,2,2,0,0,0-.55,1.51v4.95a.62.62,0,0,1-.32.48,1.24,1.24,0,0,1-.74.22,1.2,1.2,0,0,1-.74-.22A.62.62,0,0,1,25,113.6Z"/>
            <path className="cls-1" d="M40.86,118.1V105.5a.58.58,0,0,1,.29-.49,1.23,1.23,0,0,1,.71-.19,1.2,1.2,0,0,1,.7.19.58.58,0,0,1,.28.5v.73a3,3,0,0,1,2.68-1.54,3.32,3.32,0,0,1,2.55,1.16,4,4,0,0,1,1,2.78v1.84a4.07,4.07,0,0,1-1.06,2.77,3.19,3.19,0,0,1-2.46,1.21A2.92,2.92,0,0,1,44,114a2.77,2.77,0,0,1-1-1.11v5.22a.61.61,0,0,1-.32.49,1.35,1.35,0,0,1-.76.21,1.21,1.21,0,0,1-.73-.21A.6.6,0,0,1,40.86,118.1Zm2.1-7.17a1.63,1.63,0,0,0,.61,1,1.9,1.9,0,0,0,2.83,0,2.06,2.06,0,0,0,.62-1.46v-1.82a2,2,0,0,0-.62-1.43A2,2,0,0,0,43,108.65Z"/>
            <path className="cls-1" d="M51.14,113.6V99.86a.58.58,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.39,1.39,0,0,1,.75.19.56.56,0,0,1,.31.49V113.6a.62.62,0,0,1-.32.48,1.24,1.24,0,0,1-.74.22,1.2,1.2,0,0,1-.74-.22A.62.62,0,0,1,51.14,113.6Z"/>
            <path className="cls-1" d="M55.26,110.48v-1.9a3.65,3.65,0,0,1,1.22-2.73,4.32,4.32,0,0,1,5.75-.11,3.36,3.36,0,0,1,1.17,2.63,3.06,3.06,0,0,1-.14,1,.94.94,0,0,1-.44.53,2.06,2.06,0,0,1-.56.19,4.2,4.2,0,0,1-.68,0H57.36v.46a2,2,0,0,0,.72,1.61,2.9,2.9,0,0,0,1.88.59,3.35,3.35,0,0,0,1.55-.38,3.25,3.25,0,0,1,.89-.39.7.7,0,0,1,.58.33,1,1,0,0,1,.24.62c0,.33-.32.66-1,1a5.12,5.12,0,0,1-2.36.5,5,5,0,0,1-3.34-1.1A3.57,3.57,0,0,1,55.26,110.48Zm2.1-1.7h3.32a1,1,0,0,0,.59-.12.57.57,0,0,0,.15-.47,1.77,1.77,0,0,0-.58-1.34,2,2,0,0,0-1.44-.55,2.11,2.11,0,0,0-1.44.52,1.69,1.69,0,0,0-.6,1.33Z"/>
            <path className="cls-1" d="M64.4,113.42a.64.64,0,0,1,.14-.38L67,109.48l-2.36-3.36a.61.61,0,0,1-.14-.36,1,1,0,0,1,.47-.71,1.56,1.56,0,0,1,.91-.35.53.53,0,0,1,.5.24l2,3.2,2-3.18a.57.57,0,0,1,.52-.26,1.55,1.55,0,0,1,.88.35.94.94,0,0,1,.48.71.66.66,0,0,1-.14.36l-2.38,3.38,2.5,3.54a.64.64,0,0,1,.14.38.92.92,0,0,1-.46.69,1.5,1.5,0,0,1-.88.35.61.61,0,0,1-.54-.24l-2.16-3.3-2.16,3.3a.66.66,0,0,1-.56.24,1.48,1.48,0,0,1-.85-.34A.93.93,0,0,1,64.4,113.42Z"/>
            <path className="cls-1" d="M78,113.6v-8.1a.58.58,0,0,1,.29-.49,1.28,1.28,0,0,1,.73-.19,1.16,1.16,0,0,1,.68.19.58.58,0,0,1,.28.5v.89a3.19,3.19,0,0,1,1-1.2,2.53,2.53,0,0,1,1.51-.5h.64a.73.73,0,0,1,.56.27,1,1,0,0,1,.24.66,1,1,0,0,1-.24.67.71.71,0,0,1-.56.28H82.5a2.2,2.2,0,0,0-1.69.82,3,3,0,0,0-.73,2.11v4.09a.62.62,0,0,1-.32.48,1.28,1.28,0,0,1-.76.22,1.15,1.15,0,0,1-.73-.22A.63.63,0,0,1,78,113.6Z"/>
            <path className="cls-1" d="M84.58,110.48v-1.9a3.65,3.65,0,0,1,1.22-2.73,4.32,4.32,0,0,1,5.75-.11,3.36,3.36,0,0,1,1.17,2.63,3.06,3.06,0,0,1-.14,1,.94.94,0,0,1-.44.53,2.06,2.06,0,0,1-.56.19,4.2,4.2,0,0,1-.68,0H86.68v.46a2,2,0,0,0,.72,1.61,2.9,2.9,0,0,0,1.88.59,3.35,3.35,0,0,0,1.55-.38,3.25,3.25,0,0,1,.89-.39.7.7,0,0,1,.58.33,1,1,0,0,1,.24.62c0,.33-.32.66-1,1a5.12,5.12,0,0,1-2.36.5,5,5,0,0,1-3.34-1.1A3.57,3.57,0,0,1,84.58,110.48Zm2.1-1.7H90a1,1,0,0,0,.59-.12.57.57,0,0,0,.15-.47,1.77,1.77,0,0,0-.58-1.34,2,2,0,0,0-1.44-.55,2.11,2.11,0,0,0-1.44.52,1.69,1.69,0,0,0-.6,1.33Z"/>
            <path className="cls-1" d="M93.82,111.82a2.3,2.3,0,0,1,1.2-2.23A7.66,7.66,0,0,1,98.5,109h.88v-.37a2.47,2.47,0,0,0-.49-1.55,1.74,1.74,0,0,0-1.49-.66,3.29,3.29,0,0,0-1.51.33,4.76,4.76,0,0,1-.71.33.63.63,0,0,1-.55-.33,1.32,1.32,0,0,1-.21-.73c0-.35.32-.65,1-.9a5.65,5.65,0,0,1,2-.38,3.89,3.89,0,0,1,3,1.11,4,4,0,0,1,1,2.77v5a.6.6,0,0,1-.29.49,1.21,1.21,0,0,1-.73.21,1.1,1.1,0,0,1-.68-.21.59.59,0,0,1-.28-.49v-.7a3.92,3.92,0,0,1-3,1.56,2.76,2.76,0,0,1-1.91-.68A2.51,2.51,0,0,1,93.82,111.82Zm2.12-.26a1.25,1.25,0,0,0,.36,1,1.33,1.33,0,0,0,.94.33,2.1,2.1,0,0,0,1.45-.64,1.86,1.86,0,0,0,.69-1.36v-.74h-.5a11.54,11.54,0,0,0-1.47.08,2.1,2.1,0,0,0-1,.42A1.12,1.12,0,0,0,95.94,111.56Z"/>
            <path className="cls-1" d="M103.8,113.6V99.86a.58.58,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.39,1.39,0,0,1,.75.19.56.56,0,0,1,.31.49V113.6a.62.62,0,0,1-.32.48,1.24,1.24,0,0,1-.74.22,1.2,1.2,0,0,1-.74-.22A.62.62,0,0,1,103.8,113.6Z"/>
            <path className="cls-1" d="M108.1,101.34a1,1,0,0,1,.36-.78,1.32,1.32,0,0,1,1.72,0,1,1,0,0,1,.36.78,1,1,0,0,1-.36.77,1.29,1.29,0,0,1-1.72,0A1,1,0,0,1,108.1,101.34Zm.18,12.26v-8.1a.58.58,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.36,1.36,0,0,1,.76.19.57.57,0,0,1,.3.49v8.1a.61.61,0,0,1-.31.48,1.25,1.25,0,0,1-.75.22,1.2,1.2,0,0,1-.74-.22A.62.62,0,0,1,108.28,113.6Z"/>
            <path className="cls-1" d="M112.56,111.18v-10a.57.57,0,0,1,.32-.48,1.34,1.34,0,0,1,.72-.2,1.4,1.4,0,0,1,.73.2.56.56,0,0,1,.33.48v3.66h2.44a.51.51,0,0,1,.42.23,1,1,0,0,1,0,1.1.51.51,0,0,1-.42.23h-2.44v4.8a1.37,1.37,0,0,0,.31,1,1.56,1.56,0,0,0,1.09.3h.7a.87.87,0,0,1,.66.26,1,1,0,0,1,0,1.28.87.87,0,0,1-.66.26h-.7Q112.57,114.3,112.56,111.18Z"/>
            <path className="cls-1" d="M119,101.34a1,1,0,0,1,.36-.78,1.32,1.32,0,0,1,1.72,0,1,1,0,0,1,.36.78,1,1,0,0,1-.36.77,1.29,1.29,0,0,1-1.72,0A1,1,0,0,1,119,101.34Zm.18,12.26v-8.1a.58.58,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.36,1.36,0,0,1,.76.19.57.57,0,0,1,.3.49v8.1a.61.61,0,0,1-.31.48,1.25,1.25,0,0,1-.75.22,1.2,1.2,0,0,1-.74-.22A.62.62,0,0,1,119.16,113.6Z"/>
            <path className="cls-1" d="M123.1,110.48v-1.9a3.65,3.65,0,0,1,1.22-2.73,4.32,4.32,0,0,1,5.75-.11,3.36,3.36,0,0,1,1.17,2.63,3.06,3.06,0,0,1-.14,1,.94.94,0,0,1-.44.53,2.06,2.06,0,0,1-.56.19,4.2,4.2,0,0,1-.68,0H125.2v.46a2,2,0,0,0,.72,1.61,2.9,2.9,0,0,0,1.88.59,3.35,3.35,0,0,0,1.55-.38,3.25,3.25,0,0,1,.89-.39.7.7,0,0,1,.58.33,1,1,0,0,1,.24.62c0,.33-.32.66-1,1a5.12,5.12,0,0,1-2.36.5,5,5,0,0,1-3.34-1.1A3.57,3.57,0,0,1,123.1,110.48Zm2.1-1.7h3.32a1,1,0,0,0,.59-.12.57.57,0,0,0,.15-.47,1.77,1.77,0,0,0-.58-1.34,2,2,0,0,0-1.44-.55,2.11,2.11,0,0,0-1.44.52,1.69,1.69,0,0,0-.6,1.33Z"/>
            <path className="cls-1" d="M132.34,112.86a1.12,1.12,0,0,1,.21-.62.62.62,0,0,1,.52-.32,1,1,0,0,1,.5.23,4.23,4.23,0,0,0,.87.48,3.41,3.41,0,0,0,1.31.23c1.14,0,1.71-.36,1.71-1.09a1,1,0,0,0-.5-.91,4.21,4.21,0,0,0-1.21-.53c-.47-.12-.94-.27-1.41-.43a2.67,2.67,0,0,1-1.2-.82,2.27,2.27,0,0,1-.5-1.51,2.75,2.75,0,0,1,.84-2,3.47,3.47,0,0,1,2.58-.85,5.91,5.91,0,0,1,2.07.35c.62.23.93.51.93.83a1.31,1.31,0,0,1-.23.65.65.65,0,0,1-.57.37,4.08,4.08,0,0,1-.82-.3,3.4,3.4,0,0,0-1.34-.3,1.78,1.78,0,0,0-1.21.36,1.07,1.07,0,0,0-.41.81.86.86,0,0,0,.5.76,4.79,4.79,0,0,0,1.22.46,12.29,12.29,0,0,1,1.42.45,2.58,2.58,0,0,1,1.68,2.55,2.46,2.46,0,0,1-.92,2,4.07,4.07,0,0,1-2.6.74,5.45,5.45,0,0,1-2.43-.5C132.68,113.63,132.34,113.26,132.34,112.86Z"/>
            <path className="cls-1" d="M144.12,105.62a.74.74,0,0,1,.44-.62,1.76,1.76,0,0,1,.9-.26.61.61,0,0,1,.64.38l2.22,6.22,1.7-5.52a.83.83,0,0,1,1.48,0l1.68,5.56,2.24-6.26a.57.57,0,0,1,.62-.38,1.81,1.81,0,0,1,.89.26.75.75,0,0,1,.45.64.76.76,0,0,1-.08.32l-3.1,8.12q-.16.42-.84.42c-.45,0-.74-.13-.86-.4l-1.74-5.16L149,114c-.12.31-.42.46-.9.46a.79.79,0,0,1-.84-.46L144.2,106A1.39,1.39,0,0,1,144.12,105.62Z"/>
            <path className="cls-1" d="M158.52,101.34a1,1,0,0,1,.36-.78,1.32,1.32,0,0,1,1.72,0,1,1,0,0,1,.36.78,1,1,0,0,1-.36.77,1.29,1.29,0,0,1-1.72,0A1,1,0,0,1,158.52,101.34Zm.18,12.26v-8.1A.58.58,0,0,1,159,105a1.3,1.3,0,0,1,.75-.19,1.36,1.36,0,0,1,.76.19.57.57,0,0,1,.3.49v8.1a.61.61,0,0,1-.31.48,1.25,1.25,0,0,1-.75.22,1.2,1.2,0,0,1-.74-.22A.62.62,0,0,1,158.7,113.6Z"/>
            <path className="cls-1" d="M163,111.18v-10a.57.57,0,0,1,.32-.48,1.34,1.34,0,0,1,.72-.2,1.4,1.4,0,0,1,.73.2.56.56,0,0,1,.33.48v3.66h2.44a.51.51,0,0,1,.42.23,1,1,0,0,1,0,1.1.51.51,0,0,1-.42.23h-2.44v4.8a1.37,1.37,0,0,0,.31,1,1.56,1.56,0,0,0,1.09.3h.7a.87.87,0,0,1,.66.26,1,1,0,0,1,0,1.28.87.87,0,0,1-.66.26h-.7Q163,114.3,163,111.18Z"/>
            <path className="cls-1" d="M169.6,113.6V99.88a.58.58,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.39,1.39,0,0,1,.75.19.56.56,0,0,1,.31.49v6.36a2.73,2.73,0,0,1,1-1.1,2.83,2.83,0,0,1,1.57-.44,3.18,3.18,0,0,1,2.45,1.16,4,4,0,0,1,1,2.78v5a.59.59,0,0,1-.32.51,1.38,1.38,0,0,1-.74.19,1.34,1.34,0,0,1-.72-.19.59.59,0,0,1-.32-.51v-4.95a2,2,0,0,0-.6-1.43,1.88,1.88,0,0,0-2.76-.07,2,2,0,0,0-.58,1.5v4.95a.62.62,0,0,1-.32.48,1.24,1.24,0,0,1-.74.22,1.2,1.2,0,0,1-.74-.22A.62.62,0,0,1,169.6,113.6Z"/>
            <path className="cls-1" d="M179.48,110.48v-1.84a3.74,3.74,0,0,1,1.22-2.76,4,4,0,0,1,2.92-1.18,4.09,4.09,0,0,1,2.9,1.17,3.67,3.67,0,0,1,1.24,2.77v1.84a3.77,3.77,0,0,1-1.25,2.77,4.08,4.08,0,0,1-5.79,0A3.75,3.75,0,0,1,179.48,110.48Zm2.1,0a2.08,2.08,0,0,0,.6,1.46,1.91,1.91,0,0,0,2.87,0,2,2,0,0,0,.61-1.46v-1.82a2,2,0,0,0-.61-1.43,1.93,1.93,0,0,0-2.87,0,2,2,0,0,0-.6,1.43Z"/>
            <path className="cls-1" d="M189.52,110.52v-5a.6.6,0,0,1,.32-.49,1.31,1.31,0,0,1,.74-.21,1.28,1.28,0,0,1,.73.21.58.58,0,0,1,.31.49v5a2,2,0,0,0,.61,1.44,1.94,1.94,0,0,0,2.87,0,2,2,0,0,0,.6-1.45v-5A.56.56,0,0,1,196,105a1.4,1.4,0,0,1,.75-.2,1.28,1.28,0,0,1,.73.2.58.58,0,0,1,.29.48v5a3.74,3.74,0,0,1-1.22,2.76,4.17,4.17,0,0,1-5.81,0A3.72,3.72,0,0,1,189.52,110.52Z"/>
            <path className="cls-1" d="M199.88,111.18v-10a.57.57,0,0,1,.32-.48,1.34,1.34,0,0,1,.72-.2,1.4,1.4,0,0,1,.73.2.56.56,0,0,1,.33.48v3.66h2.44a.51.51,0,0,1,.42.23,1,1,0,0,1,0,1.1.51.51,0,0,1-.42.23H202v4.8a1.37,1.37,0,0,0,.31,1,1.56,1.56,0,0,0,1.09.3h.7a.87.87,0,0,1,.66.26,1,1,0,0,1,0,1.28.87.87,0,0,1-.66.26h-.7Q199.89,114.3,199.88,111.18Z"/>
            <path className="cls-1" d="M210.46,111.18v-10a.57.57,0,0,1,.32-.48,1.34,1.34,0,0,1,.72-.2,1.4,1.4,0,0,1,.73.2.56.56,0,0,1,.33.48v3.66H215a.51.51,0,0,1,.42.23,1,1,0,0,1,0,1.1.51.51,0,0,1-.42.23h-2.44v4.8a1.37,1.37,0,0,0,.31,1,1.56,1.56,0,0,0,1.09.3h.7a.87.87,0,0,1,.66.26,1,1,0,0,1,0,1.28.87.87,0,0,1-.66.26H214Q210.46,114.3,210.46,111.18Z"/>
            <path className="cls-1" d="M217.06,113.6v-8.1a.58.58,0,0,1,.29-.49,1.28,1.28,0,0,1,.73-.19,1.16,1.16,0,0,1,.68.19.58.58,0,0,1,.28.5v.89a3.19,3.19,0,0,1,1-1.2,2.53,2.53,0,0,1,1.51-.5h.64a.73.73,0,0,1,.56.27,1,1,0,0,1,.24.66,1,1,0,0,1-.24.67.71.71,0,0,1-.56.28h-.64a2.2,2.2,0,0,0-1.69.82,3,3,0,0,0-.73,2.11v4.09a.62.62,0,0,1-.32.48,1.28,1.28,0,0,1-.76.22,1.15,1.15,0,0,1-.73-.22A.63.63,0,0,1,217.06,113.6Z"/>
            <path className="cls-1" d="M223.36,117.9c0-.6.19-.9.58-.9a2.59,2.59,0,0,0,1.78-.86,3.93,3.93,0,0,0,1.08-2.42L223.44,106a.47.47,0,0,1-.08-.28.86.86,0,0,1,.46-.69,1.61,1.61,0,0,1,.92-.31.61.61,0,0,1,.64.38l2.52,6.5,2.16-6.5a.62.62,0,0,1,.6-.38,1.71,1.71,0,0,1,.91.31.87.87,0,0,1,.49.73.45.45,0,0,1,0,.24l-3,7.82-.34.9-.37.86a8.13,8.13,0,0,1-.43.88l-.48.74a2.65,2.65,0,0,1-.6.69l-.68.48a2.43,2.43,0,0,1-.84.36,4.39,4.39,0,0,1-1,.11C223.69,118.8,223.36,118.5,223.36,117.9Z"/>
            <path className="cls-1" d="M233.22,101.34a1,1,0,0,1,.36-.78,1.32,1.32,0,0,1,1.72,0,1,1,0,0,1,.36.78,1,1,0,0,1-.36.77,1.29,1.29,0,0,1-1.72,0A1,1,0,0,1,233.22,101.34Zm.18,12.26v-8.1a.58.58,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.36,1.36,0,0,1,.76.19.57.57,0,0,1,.3.49v8.1a.61.61,0,0,1-.31.48,1.25,1.25,0,0,1-.75.22,1.2,1.2,0,0,1-.74-.22A.62.62,0,0,1,233.4,113.6Z"/>
            <path className="cls-1" d="M237.7,113.6v-8.1A.58.58,0,0,1,238,105a1.3,1.3,0,0,1,.75-.19,1.11,1.11,0,0,1,.67.19.6.6,0,0,1,.27.5v.73a2.7,2.7,0,0,1,1-1.1,3.08,3.08,0,0,1,1.65-.44,3.18,3.18,0,0,1,2.45,1.16,4,4,0,0,1,1,2.78v5a.58.58,0,0,1-.31.51,1.39,1.39,0,0,1-.75.19,1.34,1.34,0,0,1-.72-.19.59.59,0,0,1-.32-.51v-4.95a2,2,0,0,0-.6-1.43,1.88,1.88,0,0,0-2.76-.07,2,2,0,0,0-.58,1.5v4.95a.62.62,0,0,1-.32.48,1.24,1.24,0,0,1-.74.22,1.2,1.2,0,0,1-.74-.22A.62.62,0,0,1,237.7,113.6Z"/>
            <path className="cls-1" d="M247.24,116.09a2.35,2.35,0,0,1,1.56-2.17,1.37,1.37,0,0,1-.88-1.33,1.84,1.84,0,0,1,.94-1.53,3,3,0,0,1-1.2-2.52V108a3,3,0,0,1,1-2.36,3.9,3.9,0,0,1,2.69-.92,3.82,3.82,0,0,1,2.32.68c.39-.89.82-1.34,1.3-1.34a.88.88,0,0,1,.62.23.68.68,0,0,1,.24.49.55.55,0,0,1-.34.5,2.29,2.29,0,0,0-1,.8,3,3,0,0,1,.64,1.92v.56a3,3,0,0,1-1.06,2.38,3.93,3.93,0,0,1-2.72.94,3.7,3.7,0,0,1-1.38-.24.71.71,0,0,0-.38.56c0,.27.21.47.63.59a8.83,8.83,0,0,0,1.52.28c.59.07,1.19.17,1.78.3a3,3,0,0,1,1.52.81,2.24,2.24,0,0,1,.63,1.66,2.47,2.47,0,0,1-1.19,2.23,5.55,5.55,0,0,1-3,.75,5.8,5.8,0,0,1-3-.74A2.23,2.23,0,0,1,247.24,116.09Zm2-.23a1.33,1.33,0,0,0,.63,1.16,2.8,2.8,0,0,0,1.63.44,2.71,2.71,0,0,0,1.58-.42,1.36,1.36,0,0,0,.6-1.16,1.12,1.12,0,0,0-.28-.78,1.74,1.74,0,0,0-.83-.46,6.7,6.7,0,0,0-.94-.2,10.33,10.33,0,0,0-1.05-.09C249.67,114.68,249.22,115.19,249.22,115.86Zm.48-7.34a2.16,2.16,0,0,0,.46,1.44,1.56,1.56,0,0,0,1.26.56,1.53,1.53,0,0,0,1.24-.55,2.14,2.14,0,0,0,.46-1.43V108a2.08,2.08,0,0,0-.46-1.39,1.55,1.55,0,0,0-1.26-.55,1.53,1.53,0,0,0-1.24.55,2.08,2.08,0,0,0-.46,1.39Z"/>
            <path className="cls-1" d="M261.2,111.18v-10a.57.57,0,0,1,.32-.48,1.34,1.34,0,0,1,.72-.2,1.4,1.4,0,0,1,.73.2.56.56,0,0,1,.33.48v3.66h2.44a.51.51,0,0,1,.42.23,1,1,0,0,1,0,1.1.51.51,0,0,1-.42.23H263.3v4.8a1.37,1.37,0,0,0,.31,1,1.56,1.56,0,0,0,1.09.3h.7a.87.87,0,0,1,.66.26,1,1,0,0,1,0,1.28.87.87,0,0,1-.66.26h-.7Q261.21,114.3,261.2,111.18Z"/>
            <path className="cls-1" d="M267.28,110.48v-1.84a3.74,3.74,0,0,1,1.22-2.76,4,4,0,0,1,2.92-1.18,4.09,4.09,0,0,1,2.9,1.17,3.67,3.67,0,0,1,1.24,2.77v1.84a3.77,3.77,0,0,1-1.25,2.77,4.08,4.08,0,0,1-5.79,0A3.75,3.75,0,0,1,267.28,110.48Zm2.1,0a2.08,2.08,0,0,0,.6,1.46,1.91,1.91,0,0,0,2.87,0,2,2,0,0,0,.61-1.46v-1.82a2,2,0,0,0-.61-1.43,1.93,1.93,0,0,0-2.87,0,2,2,0,0,0-.6,1.43Z"/>
            <path className="cls-1" d="M281.4,113.6v-8.1a.58.58,0,0,1,.29-.49,1.28,1.28,0,0,1,.73-.19,1.16,1.16,0,0,1,.68.19.58.58,0,0,1,.28.5v.89a3.19,3.19,0,0,1,1-1.2,2.53,2.53,0,0,1,1.51-.5h.64a.73.73,0,0,1,.56.27,1,1,0,0,1,.24.66,1,1,0,0,1-.24.67.71.71,0,0,1-.56.28h-.64a2.2,2.2,0,0,0-1.69.82,3,3,0,0,0-.73,2.11v4.09a.62.62,0,0,1-.32.48,1.28,1.28,0,0,1-.76.22,1.15,1.15,0,0,1-.73-.22A.63.63,0,0,1,281.4,113.6Z"/>
            <path className="cls-1" d="M288,110.48v-1.9a3.65,3.65,0,0,1,1.22-2.73,4.32,4.32,0,0,1,5.75-.11,3.36,3.36,0,0,1,1.17,2.63,3.06,3.06,0,0,1-.14,1,.94.94,0,0,1-.44.53,2.06,2.06,0,0,1-.56.19,4.2,4.2,0,0,1-.68,0H290.1v.46a2,2,0,0,0,.72,1.61,2.9,2.9,0,0,0,1.88.59,3.35,3.35,0,0,0,1.55-.38,3.25,3.25,0,0,1,.89-.39.7.7,0,0,1,.58.33,1,1,0,0,1,.24.62c0,.33-.32.66-1,1a5.12,5.12,0,0,1-2.36.5,5,5,0,0,1-3.34-1.1A3.57,3.57,0,0,1,288,110.48Zm2.1-1.7h3.32a1,1,0,0,0,.59-.12.57.57,0,0,0,.15-.47,1.77,1.77,0,0,0-.58-1.34,2,2,0,0,0-1.44-.55,2.11,2.11,0,0,0-1.44.52,1.69,1.69,0,0,0-.6,1.33Z"/>
            <path className="cls-1" d="M297.48,110.48v-1.84a4,4,0,0,1,1-2.78,3.3,3.3,0,0,1,2.53-1.16,2.82,2.82,0,0,1,1.56.44,3.12,3.12,0,0,1,1,1.1V99.88a.57.57,0,0,1,.3-.49,1.36,1.36,0,0,1,.76-.19,1.3,1.3,0,0,1,.75.19.58.58,0,0,1,.29.49V113.6a.62.62,0,0,1-.3.48,1.2,1.2,0,0,1-.74.22,1.06,1.06,0,0,1-.67-.22.63.63,0,0,1-.29-.49v-.71a2.77,2.77,0,0,1-1.09,1.12,3.25,3.25,0,0,1-1.67.46,3.19,3.19,0,0,1-2.46-1.21A4.07,4.07,0,0,1,297.48,110.48Zm2.1,0a2.08,2.08,0,0,0,.6,1.46,1.88,1.88,0,0,0,1.42.65A2,2,0,0,0,303,112a1.61,1.61,0,0,0,.64-1v-2.28a2,2,0,0,0-.62-1.49,2,2,0,0,0-1.42-.58,1.9,1.9,0,0,0-1.43.64,2,2,0,0,0-.61,1.43Z"/>
            <path className="cls-1" d="M307.9,110.52v-5a.6.6,0,0,1,.32-.49,1.31,1.31,0,0,1,.74-.21,1.28,1.28,0,0,1,.73.21.58.58,0,0,1,.31.49v5a2,2,0,0,0,.61,1.44,1.94,1.94,0,0,0,2.87,0,2,2,0,0,0,.6-1.45v-5a.56.56,0,0,1,.31-.48,1.4,1.4,0,0,1,.75-.2,1.28,1.28,0,0,1,.73.2.58.58,0,0,1,.29.48v5a3.74,3.74,0,0,1-1.22,2.76,4.17,4.17,0,0,1-5.81,0A3.72,3.72,0,0,1,307.9,110.52Z"/>
            <path className="cls-1" d="M318,110.48v-1.8a4,4,0,0,1,1-2.88,4.08,4.08,0,0,1,3.1-1.1,4.78,4.78,0,0,1,2.18.45c.59.3.88.64.88,1a1.12,1.12,0,0,1-.25.68.73.73,0,0,1-.59.34,3.28,3.28,0,0,1-.71-.3,3,3,0,0,0-1.35-.3c-1.47,0-2.2.7-2.2,2.11v1.78c0,1.41.74,2.11,2.22,2.11a2.94,2.94,0,0,0,1.37-.32,3.09,3.09,0,0,1,.73-.32.8.8,0,0,1,.65.32,1.06,1.06,0,0,1,.25.68c0,.37-.31.72-.92,1a4.78,4.78,0,0,1-2.24.48,4.1,4.1,0,0,1-3.12-1.09A4,4,0,0,1,318,110.48Z"/>
            <path className="cls-1" d="M326.34,110.48v-1.9a3.65,3.65,0,0,1,1.22-2.73,4.32,4.32,0,0,1,5.75-.11,3.36,3.36,0,0,1,1.17,2.63,3.06,3.06,0,0,1-.14,1,.94.94,0,0,1-.44.53,2.06,2.06,0,0,1-.56.19,4.2,4.2,0,0,1-.68,0h-4.22v.46a2,2,0,0,0,.72,1.61,2.9,2.9,0,0,0,1.88.59,3.38,3.38,0,0,0,1.55-.38,3.25,3.25,0,0,1,.89-.39.71.71,0,0,1,.58.33,1,1,0,0,1,.24.62c0,.33-.32.66-1,1a5.12,5.12,0,0,1-2.36.5,5,5,0,0,1-3.34-1.1A3.57,3.57,0,0,1,326.34,110.48Zm2.1-1.7h3.32a1,1,0,0,0,.59-.12.57.57,0,0,0,.15-.47,1.77,1.77,0,0,0-.58-1.34,2,2,0,0,0-1.44-.55,2.11,2.11,0,0,0-1.44.52,1.69,1.69,0,0,0-.6,1.33Z"/>
            <path className="cls-1" d="M340.16,111.18v-10a.57.57,0,0,1,.32-.48,1.34,1.34,0,0,1,.72-.2,1.4,1.4,0,0,1,.73.2.56.56,0,0,1,.33.48v3.66h2.44a.51.51,0,0,1,.42.23,1,1,0,0,1,0,1.1.51.51,0,0,1-.42.23h-2.44v4.8a1.37,1.37,0,0,0,.31,1,1.56,1.56,0,0,0,1.09.3h.7a.87.87,0,0,1,.66.26,1,1,0,0,1,0,1.28.87.87,0,0,1-.66.26h-.7Q340.17,114.3,340.16,111.18Z"/>
            <path className="cls-1" d="M346.78,113.6V99.88a.58.58,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.39,1.39,0,0,1,.75.19.56.56,0,0,1,.31.49v6.36a2.73,2.73,0,0,1,1-1.1,2.83,2.83,0,0,1,1.57-.44,3.18,3.18,0,0,1,2.45,1.16,4,4,0,0,1,1,2.78v5a.59.59,0,0,1-.32.51,1.38,1.38,0,0,1-.74.19,1.34,1.34,0,0,1-.72-.19.59.59,0,0,1-.32-.51v-4.95a2,2,0,0,0-.6-1.43,1.88,1.88,0,0,0-2.76-.07,2,2,0,0,0-.58,1.5v4.95a.62.62,0,0,1-.32.48,1.24,1.24,0,0,1-.74.22,1.2,1.2,0,0,1-.74-.22A.62.62,0,0,1,346.78,113.6Z"/>
            <path className="cls-1" d="M356.68,110.48v-1.9a3.65,3.65,0,0,1,1.22-2.73,4.32,4.32,0,0,1,5.75-.11,3.36,3.36,0,0,1,1.17,2.63,3.06,3.06,0,0,1-.14,1,.94.94,0,0,1-.44.53,2.06,2.06,0,0,1-.56.19,4.2,4.2,0,0,1-.68,0h-4.22v.46a2,2,0,0,0,.72,1.61,2.9,2.9,0,0,0,1.88.59,3.35,3.35,0,0,0,1.55-.38,3.25,3.25,0,0,1,.89-.39.7.7,0,0,1,.58.33,1,1,0,0,1,.24.62c0,.33-.32.66-1,1a5.12,5.12,0,0,1-2.36.5,5,5,0,0,1-3.34-1.1A3.57,3.57,0,0,1,356.68,110.48Zm2.1-1.7h3.32a1,1,0,0,0,.59-.12.57.57,0,0,0,.15-.47,1.77,1.77,0,0,0-.58-1.34,2,2,0,0,0-1.44-.55,2.11,2.11,0,0,0-1.44.52,1.69,1.69,0,0,0-.6,1.33Z"/>
            <path className="cls-1" d="M366.52,113.6v-8.1a.58.58,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.14,1.14,0,0,1,.66.19.58.58,0,0,1,.28.5v.73a2.84,2.84,0,0,1,2.66-1.54,2.44,2.44,0,0,1,1.57.56,3.44,3.44,0,0,1,1,1.46,3.75,3.75,0,0,1,1.31-1.51,3.16,3.16,0,0,1,1.71-.51,3.3,3.3,0,0,1,2.45,1.07,3.94,3.94,0,0,1,1,2.87v5a.62.62,0,0,1-.32.48,1.24,1.24,0,0,1-.74.22,1.2,1.2,0,0,1-.72-.22.62.62,0,0,1-.32-.48v-4.95a2.07,2.07,0,0,0-.56-1.53,1.8,1.8,0,0,0-1.3-.56,1.84,1.84,0,0,0-1.31.56,2,2,0,0,0-.57,1.49v5a.54.54,0,0,1-.31.51,1.6,1.6,0,0,1-.75.17,1.53,1.53,0,0,1-.72-.17.55.55,0,0,1-.32-.51v-5a2,2,0,0,0-.55-1.47,1.73,1.73,0,0,0-1.31-.58,1.79,1.79,0,0,0-1.31.56,2,2,0,0,0-.55,1.51v4.95a.62.62,0,0,1-.32.48,1.24,1.24,0,0,1-.74.22,1.2,1.2,0,0,1-.74-.22A.62.62,0,0,1,366.52,113.6Z"/>
            <path className="cls-1" d="M386.38,111.18v-10a.57.57,0,0,1,.32-.48,1.34,1.34,0,0,1,.72-.2,1.4,1.4,0,0,1,.73.2.56.56,0,0,1,.33.48v3.66h2.44a.51.51,0,0,1,.42.23,1,1,0,0,1,0,1.1.51.51,0,0,1-.42.23h-2.44v4.8a1.37,1.37,0,0,0,.31,1,1.56,1.56,0,0,0,1.09.3h.7a.87.87,0,0,1,.66.26,1,1,0,0,1,0,1.28.87.87,0,0,1-.66.26h-.7C387.54,114.3,386.38,113.26,386.38,111.18Z"/>
            <path className="cls-1" d="M392.46,110.48v-1.84a3.74,3.74,0,0,1,1.22-2.76,4,4,0,0,1,2.92-1.18,4.09,4.09,0,0,1,2.9,1.17,3.67,3.67,0,0,1,1.24,2.77v1.84a3.77,3.77,0,0,1-1.25,2.77,4.08,4.08,0,0,1-5.79,0A3.75,3.75,0,0,1,392.46,110.48Zm2.1,0a2.08,2.08,0,0,0,.6,1.46,1.91,1.91,0,0,0,2.87,0,2,2,0,0,0,.61-1.46v-1.82a2,2,0,0,0-.61-1.43,1.93,1.93,0,0,0-2.87,0,2,2,0,0,0-.6,1.43Z"/>
            <path className="cls-1" d="M406,112.86a1.12,1.12,0,0,1,.21-.62.61.61,0,0,1,.51-.32,1,1,0,0,1,.51.23,4.23,4.23,0,0,0,.87.48,3.41,3.41,0,0,0,1.31.23c1.14,0,1.71-.36,1.71-1.09a1,1,0,0,0-.5-.91,4.21,4.21,0,0,0-1.21-.53c-.47-.12-.94-.27-1.41-.43a2.67,2.67,0,0,1-1.2-.82,2.27,2.27,0,0,1-.5-1.51,2.75,2.75,0,0,1,.84-2,3.47,3.47,0,0,1,2.58-.85,5.91,5.91,0,0,1,2.07.35c.62.23.93.51.93.83a1.31,1.31,0,0,1-.23.65.65.65,0,0,1-.57.37,4.08,4.08,0,0,1-.82-.3,3.4,3.4,0,0,0-1.34-.3,1.78,1.78,0,0,0-1.21.36,1.07,1.07,0,0,0-.41.81.86.86,0,0,0,.5.76,4.79,4.79,0,0,0,1.22.46,12.29,12.29,0,0,1,1.42.45,2.58,2.58,0,0,1,1.68,2.55,2.46,2.46,0,0,1-.92,2,4.07,4.07,0,0,1-2.6.74A5.45,5.45,0,0,1,407,114C406.32,113.63,406,113.26,406,112.86Z"/>
            <path className="cls-1" d="M414.48,101.34a1,1,0,0,1,.36-.78,1.32,1.32,0,0,1,1.72,0,1,1,0,0,1,.36.78,1,1,0,0,1-.36.77,1.29,1.29,0,0,1-1.72,0A1,1,0,0,1,414.48,101.34Zm.18,12.26v-8.1A.58.58,0,0,1,415,105a1.3,1.3,0,0,1,.75-.19,1.36,1.36,0,0,1,.76.19.57.57,0,0,1,.3.49v8.1a.61.61,0,0,1-.31.48,1.25,1.25,0,0,1-.75.22,1.2,1.2,0,0,1-.74-.22A.62.62,0,0,1,414.66,113.6Z"/>
            <path className="cls-1" d="M419,113.6v-8.1a.58.58,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.14,1.14,0,0,1,.66.19.58.58,0,0,1,.28.5v.73a2.84,2.84,0,0,1,2.66-1.54,2.44,2.44,0,0,1,1.57.56,3.44,3.44,0,0,1,1.05,1.46,3.75,3.75,0,0,1,1.31-1.51,3.16,3.16,0,0,1,1.71-.51,3.3,3.3,0,0,1,2.45,1.07,3.94,3.94,0,0,1,1,2.87v5a.62.62,0,0,1-.32.48,1.24,1.24,0,0,1-.74.22,1.2,1.2,0,0,1-.72-.22.62.62,0,0,1-.32-.48v-4.95a2.07,2.07,0,0,0-.56-1.53,1.8,1.8,0,0,0-1.3-.56,1.84,1.84,0,0,0-1.31.56,2,2,0,0,0-.57,1.49v5a.54.54,0,0,1-.31.51,1.6,1.6,0,0,1-.75.17,1.53,1.53,0,0,1-.72-.17.55.55,0,0,1-.32-.51v-5a2,2,0,0,0-.55-1.47,1.73,1.73,0,0,0-1.31-.58,1.79,1.79,0,0,0-1.31.56,2,2,0,0,0-.55,1.51v4.95a.62.62,0,0,1-.32.48,1.24,1.24,0,0,1-.74.22,1.2,1.2,0,0,1-.74-.22A.62.62,0,0,1,419,113.6Z"/>
            <path className="cls-1" d="M434.86,118.1V105.5a.58.58,0,0,1,.29-.49,1.23,1.23,0,0,1,.71-.19,1.2,1.2,0,0,1,.7.19.58.58,0,0,1,.28.5v.73a3,3,0,0,1,2.68-1.54,3.32,3.32,0,0,1,2.55,1.16,4,4,0,0,1,1.05,2.78v1.84a4.07,4.07,0,0,1-1.06,2.77,3.19,3.19,0,0,1-2.46,1.21A2.92,2.92,0,0,1,438,114a2.77,2.77,0,0,1-1.05-1.11v5.22a.61.61,0,0,1-.32.49,1.35,1.35,0,0,1-.76.21,1.21,1.21,0,0,1-.73-.21A.6.6,0,0,1,434.86,118.1Zm2.1-7.17a1.63,1.63,0,0,0,.61,1,1.9,1.9,0,0,0,2.83,0,2.06,2.06,0,0,0,.62-1.46v-1.82a2,2,0,0,0-.62-1.43,2,2,0,0,0-3.44,1.43Z"/>
            <path className="cls-1" d="M445.14,113.6V99.86a.58.58,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.39,1.39,0,0,1,.75.19.56.56,0,0,1,.31.49V113.6a.62.62,0,0,1-.32.48,1.24,1.24,0,0,1-.74.22,1.2,1.2,0,0,1-.74-.22A.62.62,0,0,1,445.14,113.6Z"/>
            <path className="cls-1" d="M449.44,101.34a1,1,0,0,1,.36-.78,1.32,1.32,0,0,1,1.72,0,1,1,0,0,1,.36.78,1,1,0,0,1-.36.77,1.29,1.29,0,0,1-1.72,0A1,1,0,0,1,449.44,101.34Zm.18,12.26v-8.1a.58.58,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.36,1.36,0,0,1,.76.19.57.57,0,0,1,.3.49v8.1a.61.61,0,0,1-.31.48,1.25,1.25,0,0,1-.75.22,1.2,1.2,0,0,1-.74-.22A.62.62,0,0,1,449.62,113.6Z"/>
            <path className="cls-1" d="M453.32,112.86a1.12,1.12,0,0,1,.21-.62.61.61,0,0,1,.51-.32,1,1,0,0,1,.51.23,4.23,4.23,0,0,0,.87.48,3.38,3.38,0,0,0,1.31.23c1.14,0,1.71-.36,1.71-1.09a1,1,0,0,0-.5-.91,4.21,4.21,0,0,0-1.21-.53c-.47-.12-.94-.27-1.41-.43a2.67,2.67,0,0,1-1.2-.82,2.27,2.27,0,0,1-.5-1.51,2.75,2.75,0,0,1,.84-2,3.47,3.47,0,0,1,2.58-.85,5.91,5.91,0,0,1,2.07.35c.62.23.93.51.93.83a1.31,1.31,0,0,1-.23.65.65.65,0,0,1-.57.37,4.08,4.08,0,0,1-.82-.3,3.4,3.4,0,0,0-1.34-.3,1.78,1.78,0,0,0-1.21.36,1.07,1.07,0,0,0-.41.81.86.86,0,0,0,.5.76,4.79,4.79,0,0,0,1.22.46,12.29,12.29,0,0,1,1.42.45,2.58,2.58,0,0,1,1.68,2.55,2.46,2.46,0,0,1-.92,2,4.07,4.07,0,0,1-2.6.74,5.45,5.45,0,0,1-2.43-.5C453.66,113.63,453.32,113.26,453.32,112.86Z"/>
            <path className="cls-1" d="M462,111.18v-10a.57.57,0,0,1,.32-.48,1.34,1.34,0,0,1,.72-.2,1.4,1.4,0,0,1,.73.2.56.56,0,0,1,.33.48v3.66h2.44a.51.51,0,0,1,.42.23,1,1,0,0,1,0,1.1.51.51,0,0,1-.42.23h-2.44v4.8a1.37,1.37,0,0,0,.31,1,1.56,1.56,0,0,0,1.09.3h.7a.87.87,0,0,1,.66.26,1,1,0,0,1,0,1.28.87.87,0,0,1-.66.26h-.7Q462,114.3,462,111.18Z"/>
            <path className="cls-1" d="M468.4,101.34a1,1,0,0,1,.36-.78,1.32,1.32,0,0,1,1.72,0,1,1,0,0,1,.36.78,1,1,0,0,1-.36.77,1.29,1.29,0,0,1-1.72,0A1,1,0,0,1,468.4,101.34Zm.18,12.26v-8.1a.58.58,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.36,1.36,0,0,1,.76.19.57.57,0,0,1,.3.49v8.1a.61.61,0,0,1-.31.48,1.25,1.25,0,0,1-.75.22,1.2,1.2,0,0,1-.74-.22A.62.62,0,0,1,468.58,113.6Z"/>
            <path className="cls-1" d="M472.56,110.48v-1.8a4,4,0,0,1,1-2.88,4.08,4.08,0,0,1,3.1-1.1,4.76,4.76,0,0,1,2.18.45c.59.3.88.64.88,1a1.12,1.12,0,0,1-.25.68.71.71,0,0,1-.59.34,3.28,3.28,0,0,1-.71-.3,3,3,0,0,0-1.35-.3c-1.47,0-2.2.7-2.2,2.11v1.78c0,1.41.74,2.11,2.22,2.11a2.94,2.94,0,0,0,1.37-.32,3.09,3.09,0,0,1,.73-.32.8.8,0,0,1,.65.32,1.06,1.06,0,0,1,.25.68c0,.37-.31.72-.92,1a4.78,4.78,0,0,1-2.24.48,4.1,4.1,0,0,1-3.12-1.09A4,4,0,0,1,472.56,110.48Z"/>
            <path className="cls-1" d="M3.85,144.82a2.3,2.3,0,0,1,1.2-2.23A7.66,7.66,0,0,1,8.53,142h.88v-.37A2.47,2.47,0,0,0,8.92,140a1.74,1.74,0,0,0-1.49-.66,3.29,3.29,0,0,0-1.51.33,4.76,4.76,0,0,1-.71.33.63.63,0,0,1-.55-.33,1.32,1.32,0,0,1-.21-.73c0-.35.32-.65,1-.9a5.65,5.65,0,0,1,2.05-.38,3.89,3.89,0,0,1,3.05,1.11,4,4,0,0,1,1,2.77v5a.6.6,0,0,1-.29.49,1.21,1.21,0,0,1-.73.21,1.1,1.1,0,0,1-.68-.21.59.59,0,0,1-.28-.49v-.7a3.92,3.92,0,0,1-3,1.56,2.76,2.76,0,0,1-1.91-.68A2.51,2.51,0,0,1,3.85,144.82ZM6,144.56a1.25,1.25,0,0,0,.36,1,1.33,1.33,0,0,0,.94.33,2.1,2.1,0,0,0,1.45-.64,1.86,1.86,0,0,0,.69-1.36v-.74h-.5a11.54,11.54,0,0,0-1.47.08,2.1,2.1,0,0,0-1,.42A1.12,1.12,0,0,0,6,144.56Z"/>
            <path className="cls-1" d="M13.65,146.6v-8.1a.58.58,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.11,1.11,0,0,1,.67.19.6.6,0,0,1,.27.5v.73a2.7,2.7,0,0,1,1-1.1,3.08,3.08,0,0,1,1.65-.44,3.18,3.18,0,0,1,2.45,1.16,4,4,0,0,1,1,2.78v5a.58.58,0,0,1-.31.51,1.39,1.39,0,0,1-.75.19,1.34,1.34,0,0,1-.72-.19.59.59,0,0,1-.32-.51v-4.95a2,2,0,0,0-.6-1.43,1.88,1.88,0,0,0-2.76-.07,2,2,0,0,0-.58,1.5v4.95a.62.62,0,0,1-.32.48,1.24,1.24,0,0,1-.74.22,1.2,1.2,0,0,1-.74-.22A.62.62,0,0,1,13.65,146.6Z"/>
            <path className="cls-1" d="M23.55,143.48v-1.84a4,4,0,0,1,1.05-2.78,3.3,3.3,0,0,1,2.53-1.16,2.82,2.82,0,0,1,1.56.44,3.12,3.12,0,0,1,1,1.1v-6.36a.57.57,0,0,1,.3-.49,1.36,1.36,0,0,1,.76-.19,1.3,1.3,0,0,1,.75.19.58.58,0,0,1,.29.49V146.6a.62.62,0,0,1-.3.48,1.2,1.2,0,0,1-.74.22,1.06,1.06,0,0,1-.67-.22.63.63,0,0,1-.29-.49v-.71A2.77,2.77,0,0,1,28.74,147a3.25,3.25,0,0,1-1.67.46,3.19,3.19,0,0,1-2.46-1.21A4.07,4.07,0,0,1,23.55,143.48Zm2.1,0a2.08,2.08,0,0,0,.6,1.46,1.88,1.88,0,0,0,1.42.65,2,2,0,0,0,1.42-.61,1.61,1.61,0,0,0,.64-1v-2.28a2,2,0,0,0-.62-1.49,2,2,0,0,0-1.42-.58,1.9,1.9,0,0,0-1.43.64,2,2,0,0,0-.61,1.43Z"/>
            <path className="cls-1" d="M38.07,146.62V136a3.83,3.83,0,0,1,1-2.71,3.3,3.3,0,0,1,2.5-1.11h.92a.74.74,0,0,1,.6.27,1,1,0,0,1,0,1.26.74.74,0,0,1-.6.27H42a1.79,1.79,0,0,0-1.32.52,1.76,1.76,0,0,0-.52,1.3v2h2.6a.51.51,0,0,1,.42.23,1,1,0,0,1,0,1.1.51.51,0,0,1-.42.23h-2.6v7.24a.57.57,0,0,1-.32.49,1.43,1.43,0,0,1-.76.19,1.28,1.28,0,0,1-.73-.19A.58.58,0,0,1,38.07,146.62Z"/>
            <path className="cls-1" d="M44.61,146.6v-8.1a.56.56,0,0,1,.29-.49,1.26,1.26,0,0,1,.73-.19,1.16,1.16,0,0,1,.68.19.58.58,0,0,1,.28.5v.89a3.19,3.19,0,0,1,1-1.2,2.53,2.53,0,0,1,1.51-.5h.64a.73.73,0,0,1,.56.27,1,1,0,0,1,.24.66,1,1,0,0,1-.24.67.71.71,0,0,1-.56.28h-.64a2.2,2.2,0,0,0-1.69.82,3,3,0,0,0-.73,2.11v4.09a.62.62,0,0,1-.32.48,1.28,1.28,0,0,1-.76.22,1.13,1.13,0,0,1-.73-.22A.6.6,0,0,1,44.61,146.6Z"/>
            <path className="cls-1" d="M51.21,144.82a2.3,2.3,0,0,1,1.2-2.23,7.66,7.66,0,0,1,3.48-.63h.88v-.37a2.47,2.47,0,0,0-.49-1.55,1.75,1.75,0,0,0-1.49-.66,3.29,3.29,0,0,0-1.51.33,4.76,4.76,0,0,1-.71.33.63.63,0,0,1-.55-.33,1.32,1.32,0,0,1-.21-.73c0-.35.32-.65,1-.9a5.65,5.65,0,0,1,2-.38,3.89,3.89,0,0,1,3.05,1.11,4,4,0,0,1,1,2.77v5a.6.6,0,0,1-.29.49,1.21,1.21,0,0,1-.73.21,1.1,1.1,0,0,1-.68-.21.59.59,0,0,1-.28-.49v-.7a3.92,3.92,0,0,1-3,1.56,2.76,2.76,0,0,1-1.91-.68A2.51,2.51,0,0,1,51.21,144.82Zm2.12-.26a1.25,1.25,0,0,0,.36,1,1.33,1.33,0,0,0,.94.33,2.1,2.1,0,0,0,1.45-.64,1.86,1.86,0,0,0,.69-1.36v-.74h-.5a11.54,11.54,0,0,0-1.47.08,2.1,2.1,0,0,0-1,.42A1.12,1.12,0,0,0,53.33,144.56Z"/>
            <path className="cls-1" d="M60.29,149.09a2.35,2.35,0,0,1,1.56-2.17,1.37,1.37,0,0,1-.88-1.33,1.84,1.84,0,0,1,.94-1.53,3,3,0,0,1-1.2-2.52V141a3,3,0,0,1,1-2.36,3.9,3.9,0,0,1,2.69-.92,3.82,3.82,0,0,1,2.32.68c.39-.89.82-1.34,1.3-1.34a.88.88,0,0,1,.62.23.68.68,0,0,1,.24.49.55.55,0,0,1-.34.5,2.29,2.29,0,0,0-1,.8,3,3,0,0,1,.64,1.92v.56a3,3,0,0,1-1.06,2.38,3.93,3.93,0,0,1-2.72.94,3.7,3.7,0,0,1-1.38-.24.71.71,0,0,0-.38.56c0,.27.21.47.63.59a8.83,8.83,0,0,0,1.52.28c.59.07,1.19.17,1.78.3a3,3,0,0,1,1.52.81,2.24,2.24,0,0,1,.63,1.66,2.47,2.47,0,0,1-1.19,2.23,5.55,5.55,0,0,1-3,.75,5.8,5.8,0,0,1-3-.74A2.23,2.23,0,0,1,60.29,149.09Zm2-.23A1.33,1.33,0,0,0,62.9,150a2.8,2.8,0,0,0,1.63.44,2.71,2.71,0,0,0,1.58-.42,1.36,1.36,0,0,0,.6-1.16,1.12,1.12,0,0,0-.28-.78,1.74,1.74,0,0,0-.83-.46,6.7,6.7,0,0,0-.94-.2,10.33,10.33,0,0,0-1-.09C62.72,147.68,62.27,148.19,62.27,148.86Zm.48-7.34a2.16,2.16,0,0,0,.46,1.44,1.56,1.56,0,0,0,1.26.56,1.53,1.53,0,0,0,1.24-.55,2.14,2.14,0,0,0,.46-1.43V141a2.08,2.08,0,0,0-.46-1.39,1.55,1.55,0,0,0-1.26-.55,1.53,1.53,0,0,0-1.24.55,2.08,2.08,0,0,0-.46,1.39Z"/>
            <path className="cls-1" d="M70.27,146.6v-8.1a.58.58,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19A1.14,1.14,0,0,1,72,138a.58.58,0,0,1,.28.5v.73a2.84,2.84,0,0,1,2.66-1.54,2.44,2.44,0,0,1,1.57.56,3.44,3.44,0,0,1,1,1.46,3.75,3.75,0,0,1,1.31-1.51,3.16,3.16,0,0,1,1.71-.51A3.3,3.3,0,0,1,83,138.77a3.94,3.94,0,0,1,1,2.87v5a.62.62,0,0,1-.32.48,1.24,1.24,0,0,1-.74.22,1.2,1.2,0,0,1-.72-.22.62.62,0,0,1-.32-.48v-4.95a2.07,2.07,0,0,0-.56-1.53,1.8,1.8,0,0,0-1.3-.56,1.84,1.84,0,0,0-1.31.56,2,2,0,0,0-.57,1.49v5a.54.54,0,0,1-.31.51,1.6,1.6,0,0,1-.75.17,1.53,1.53,0,0,1-.72-.17.55.55,0,0,1-.32-.51v-5a2,2,0,0,0-.55-1.47,1.73,1.73,0,0,0-1.31-.58,1.79,1.79,0,0,0-1.31.56,2,2,0,0,0-.55,1.51v4.95a.62.62,0,0,1-.32.48,1.24,1.24,0,0,1-.74.22,1.2,1.2,0,0,1-.74-.22A.62.62,0,0,1,70.27,146.6Z"/>
            <path className="cls-1" d="M85.79,143.48v-1.9A3.65,3.65,0,0,1,87,138.85a4.32,4.32,0,0,1,5.75-.11,3.36,3.36,0,0,1,1.17,2.63,3.06,3.06,0,0,1-.14,1,.94.94,0,0,1-.44.53,2.06,2.06,0,0,1-.56.19,4.2,4.2,0,0,1-.68,0H87.89v.46a2,2,0,0,0,.72,1.61,2.9,2.9,0,0,0,1.88.59A3.35,3.35,0,0,0,92,145.4a3.25,3.25,0,0,1,.89-.39.7.7,0,0,1,.58.33,1,1,0,0,1,.24.62c0,.33-.32.66-1,1a5.12,5.12,0,0,1-2.36.5,5,5,0,0,1-3.34-1.1A3.57,3.57,0,0,1,85.79,143.48Zm2.1-1.7h3.32a1,1,0,0,0,.59-.12.57.57,0,0,0,.15-.47,1.77,1.77,0,0,0-.58-1.34,2,2,0,0,0-1.44-.55,2.11,2.11,0,0,0-1.44.52,1.69,1.69,0,0,0-.6,1.33Z"/>
            <path className="cls-1" d="M95.63,146.6v-8.1a.58.58,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.11,1.11,0,0,1,.67.19.6.6,0,0,1,.27.5v.73a2.7,2.7,0,0,1,1-1.1,3.08,3.08,0,0,1,1.65-.44,3.18,3.18,0,0,1,2.45,1.16,4,4,0,0,1,1,2.78v5a.58.58,0,0,1-.31.51,1.39,1.39,0,0,1-.75.19,1.34,1.34,0,0,1-.72-.19.59.59,0,0,1-.32-.51v-4.95a2,2,0,0,0-.6-1.43,1.88,1.88,0,0,0-2.76-.07,2,2,0,0,0-.58,1.5v4.95a.62.62,0,0,1-.32.48,1.24,1.24,0,0,1-.74.22,1.2,1.2,0,0,1-.74-.22A.62.62,0,0,1,95.63,146.6Z"/>
            <path className="cls-1" d="M105.87,144.18v-10a.57.57,0,0,1,.32-.48,1.34,1.34,0,0,1,.72-.2,1.4,1.4,0,0,1,.73.2.56.56,0,0,1,.33.48v3.66h2.44a.51.51,0,0,1,.42.23,1,1,0,0,1,0,1.1.51.51,0,0,1-.42.23H108v4.8a1.37,1.37,0,0,0,.31,1,1.56,1.56,0,0,0,1.09.3h.7a.87.87,0,0,1,.66.26,1,1,0,0,1,0,1.28.87.87,0,0,1-.66.26h-.7Q105.88,147.3,105.87,144.18Z"/>
            <path className="cls-1" d="M111.87,144.82a2.3,2.3,0,0,1,1.2-2.23,7.66,7.66,0,0,1,3.48-.63h.88v-.37a2.47,2.47,0,0,0-.49-1.55,1.74,1.74,0,0,0-1.49-.66,3.29,3.29,0,0,0-1.51.33,4.76,4.76,0,0,1-.71.33.63.63,0,0,1-.55-.33,1.32,1.32,0,0,1-.21-.73c0-.35.32-.65,1-.9a5.65,5.65,0,0,1,2-.38,3.89,3.89,0,0,1,3.05,1.11,4,4,0,0,1,1,2.77v5a.6.6,0,0,1-.29.49,1.21,1.21,0,0,1-.73.21,1.1,1.1,0,0,1-.68-.21.59.59,0,0,1-.28-.49v-.7a3.92,3.92,0,0,1-3,1.56,2.76,2.76,0,0,1-1.91-.68A2.51,2.51,0,0,1,111.87,144.82Zm2.12-.26a1.25,1.25,0,0,0,.36,1,1.33,1.33,0,0,0,.94.33,2.1,2.1,0,0,0,1.45-.64,1.86,1.86,0,0,0,.69-1.36v-.74h-.5a11.54,11.54,0,0,0-1.47.08,2.1,2.1,0,0,0-1,.42A1.12,1.12,0,0,0,114,144.56Z"/>
            <path className="cls-1" d="M121.67,146.6v-8.1A.58.58,0,0,1,122,138a1.28,1.28,0,0,1,.73-.19,1.16,1.16,0,0,1,.68.19.58.58,0,0,1,.28.5v.89a3.19,3.19,0,0,1,1-1.2,2.53,2.53,0,0,1,1.51-.5h.64a.73.73,0,0,1,.56.27,1,1,0,0,1,.24.66,1,1,0,0,1-.24.67.71.71,0,0,1-.56.28h-.64a2.2,2.2,0,0,0-1.69.82,3,3,0,0,0-.73,2.11v4.09a.62.62,0,0,1-.32.48,1.28,1.28,0,0,1-.76.22,1.15,1.15,0,0,1-.73-.22A.63.63,0,0,1,121.67,146.6Z"/>
            <path className="cls-1" d="M128,150.9c0-.6.19-.9.58-.9a2.59,2.59,0,0,0,1.78-.86,3.93,3.93,0,0,0,1.08-2.42L128.05,139a.47.47,0,0,1-.08-.28.86.86,0,0,1,.46-.69,1.61,1.61,0,0,1,.92-.31.61.61,0,0,1,.64.38l2.52,6.5,2.16-6.5a.62.62,0,0,1,.6-.38,1.71,1.71,0,0,1,.91.31.87.87,0,0,1,.49.73.45.45,0,0,1,0,.24l-3,7.82-.34.9-.37.86a8.13,8.13,0,0,1-.43.88l-.48.74a2.65,2.65,0,0,1-.6.69l-.68.48a2.43,2.43,0,0,1-.84.36,4.39,4.39,0,0,1-1,.11C128.3,151.8,128,151.5,128,150.9Z"/>
            <path className="cls-1" d="M142,146.62V136a3.83,3.83,0,0,1,1-2.71,3.3,3.3,0,0,1,2.5-1.11h.92a.74.74,0,0,1,.6.27,1,1,0,0,1,0,1.26.74.74,0,0,1-.6.27H146a1.79,1.79,0,0,0-1.32.52,1.76,1.76,0,0,0-.52,1.3v2h2.6a.51.51,0,0,1,.42.23,1,1,0,0,1,0,1.1.51.51,0,0,1-.42.23h-2.6v7.24a.57.57,0,0,1-.32.49,1.43,1.43,0,0,1-.76.19,1.28,1.28,0,0,1-.73-.19A.58.58,0,0,1,142,146.62Z"/>
            <path className="cls-1" d="M148.57,146.6v-8.1a.58.58,0,0,1,.29-.49,1.26,1.26,0,0,1,.73-.19,1.16,1.16,0,0,1,.68.19.61.61,0,0,1,.28.5v.89a3.19,3.19,0,0,1,1-1.2,2.53,2.53,0,0,1,1.51-.5h.64a.73.73,0,0,1,.56.27,1,1,0,0,1,.24.66,1,1,0,0,1-.24.67.71.71,0,0,1-.56.28h-.64a2.2,2.2,0,0,0-1.69.82,3,3,0,0,0-.73,2.11v4.09a.62.62,0,0,1-.32.48,1.28,1.28,0,0,1-.76.22,1.13,1.13,0,0,1-.73-.22A.63.63,0,0,1,148.57,146.6Z"/>
            <path className="cls-1" d="M155.17,144.82a2.3,2.3,0,0,1,1.2-2.23,7.66,7.66,0,0,1,3.48-.63h.88v-.37a2.54,2.54,0,0,0-.49-1.55,1.75,1.75,0,0,0-1.49-.66,3.26,3.26,0,0,0-1.51.33,5.36,5.36,0,0,1-.71.33.63.63,0,0,1-.55-.33,1.32,1.32,0,0,1-.21-.73c0-.35.32-.65,1-.9a5.61,5.61,0,0,1,2-.38,3.89,3.89,0,0,1,3.05,1.11,4,4,0,0,1,1,2.77v5a.6.6,0,0,1-.29.49,1.23,1.23,0,0,1-.73.21,1.08,1.08,0,0,1-.68-.21.59.59,0,0,1-.28-.49v-.7a3.94,3.94,0,0,1-3,1.56,2.74,2.74,0,0,1-1.91-.68A2.51,2.51,0,0,1,155.17,144.82Zm2.12-.26a1.25,1.25,0,0,0,.36,1,1.33,1.33,0,0,0,.94.33,2.11,2.11,0,0,0,1.45-.64,1.86,1.86,0,0,0,.69-1.36v-.74h-.5a11.54,11.54,0,0,0-1.47.08,2.1,2.1,0,0,0-1,.42A1.12,1.12,0,0,0,157.29,144.56Z"/>
            <path className="cls-1" d="M165,146.6v-8.1a.56.56,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.14,1.14,0,0,1,.66.19.61.61,0,0,1,.28.5v.73a2.84,2.84,0,0,1,2.66-1.54,2.45,2.45,0,0,1,1.57.56,3.44,3.44,0,0,1,1,1.46,3.75,3.75,0,0,1,1.31-1.51,3.16,3.16,0,0,1,1.71-.51,3.31,3.31,0,0,1,2.45,1.07,4,4,0,0,1,1,2.87v5a.62.62,0,0,1-.32.48,1.26,1.26,0,0,1-.74.22,1.2,1.2,0,0,1-.72-.22.6.6,0,0,1-.32-.48v-4.95a2.07,2.07,0,0,0-.56-1.53,1.8,1.8,0,0,0-2.61,0,2,2,0,0,0-.57,1.49v5a.54.54,0,0,1-.31.51,1.6,1.6,0,0,1-.75.17,1.53,1.53,0,0,1-.72-.17.53.53,0,0,1-.32-.51v-5a2.07,2.07,0,0,0-.55-1.47,1.75,1.75,0,0,0-1.31-.58,1.77,1.77,0,0,0-1.31.56,2,2,0,0,0-.55,1.51v4.95a.62.62,0,0,1-.32.48,1.24,1.24,0,0,1-.74.22,1.18,1.18,0,0,1-.74-.22A.62.62,0,0,1,165,146.6Z"/>
            <path className="cls-1" d="M180.49,143.48v-1.9a3.61,3.61,0,0,1,1.22-2.73,4.32,4.32,0,0,1,5.75-.11,3.36,3.36,0,0,1,1.17,2.63,3.06,3.06,0,0,1-.14,1,1,1,0,0,1-.44.53,2.06,2.06,0,0,1-.56.19,4.27,4.27,0,0,1-.68,0h-4.22v.46a2,2,0,0,0,.72,1.61,2.9,2.9,0,0,0,1.88.59,3.38,3.38,0,0,0,1.55-.38,3.12,3.12,0,0,1,.89-.39.71.71,0,0,1,.58.33,1,1,0,0,1,.24.62c0,.33-.32.66-1,1a5.12,5.12,0,0,1-2.36.5,5,5,0,0,1-3.34-1.1A3.57,3.57,0,0,1,180.49,143.48Zm2.1-1.7h3.32a1,1,0,0,0,.59-.12.57.57,0,0,0,.15-.47,1.8,1.8,0,0,0-.58-1.34,2,2,0,0,0-1.44-.55,2.11,2.11,0,0,0-1.44.52,1.69,1.69,0,0,0-.6,1.33Z"/>
            <path className="cls-1" d="M189.73,145.86a1.12,1.12,0,0,1,.21-.62.61.61,0,0,1,.51-.32,1,1,0,0,1,.51.23,4.23,4.23,0,0,0,.87.48,3.36,3.36,0,0,0,1.3.23c1.15,0,1.72-.36,1.72-1.09a1,1,0,0,0-.5-.91,4.34,4.34,0,0,0-1.21-.53c-.47-.12-.94-.27-1.41-.43a2.67,2.67,0,0,1-1.2-.82,2.27,2.27,0,0,1-.5-1.51,2.75,2.75,0,0,1,.84-2,3.47,3.47,0,0,1,2.58-.85,5.91,5.91,0,0,1,2.07.35c.62.23.93.51.93.83a1.31,1.31,0,0,1-.23.65.65.65,0,0,1-.57.37,3.86,3.86,0,0,1-.82-.3,3.4,3.4,0,0,0-1.34-.3,1.77,1.77,0,0,0-1.21.36,1,1,0,0,0-.41.81.86.86,0,0,0,.5.76,4.79,4.79,0,0,0,1.22.46,12.29,12.29,0,0,1,1.42.45,2.65,2.65,0,0,1,1.19.9,2.69,2.69,0,0,1,.49,1.65,2.46,2.46,0,0,1-.92,2,4.07,4.07,0,0,1-2.6.74,5.45,5.45,0,0,1-2.43-.5C190.07,146.63,189.73,146.26,189.73,145.86Z"/>
            <path className="cls-1" d="M198.11,146.43a1,1,0,0,1,.25-.72.82.82,0,0,1,.64-.29.8.8,0,0,1,.61.3,1.07,1.07,0,0,1,.26.71,1,1,0,0,1-.26.69.8.8,0,0,1-.61.3.82.82,0,0,1-.64-.29A1,1,0,0,1,198.11,146.43Z"/>
            <path className="cls-1" d="M206.25,146.78V133.16q0-.48.66-.48t.66.48v13.62a.44.44,0,0,1-.2.38.81.81,0,0,1-.46.14.79.79,0,0,1-.47-.14A.44.44,0,0,1,206.25,146.78Z"/>
            <path className="cls-1" d="M210.31,144.36V133.84a.39.39,0,0,1,.2-.34.77.77,0,0,1,.44-.14.83.83,0,0,1,.46.14.42.42,0,0,1,.2.34v4h2.58a.41.41,0,0,1,.33.16.65.65,0,0,1,0,.78.41.41,0,0,1-.33.16h-2.58v5.39a1.78,1.78,0,0,0,.41,1.36,2.08,2.08,0,0,0,1.45.39H214a.58.58,0,0,1,.43.18.57.57,0,0,1,.17.42.61.61,0,0,1-.17.43.56.56,0,0,1-.43.19h-.56Q210.31,147.3,210.31,144.36Z"/>
            <path className="cls-1" d="M220.29,146.78v-8.46a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.72.72,0,0,1,.44.14.44.44,0,0,1,.18.35v1a3.08,3.08,0,0,1,1.06-1.13,2.83,2.83,0,0,1,1.62-.47h.7a.48.48,0,0,1,.36.18.66.66,0,0,1,0,.86.48.48,0,0,1-.36.18h-.7a2.5,2.5,0,0,0-1.84.81,2.83,2.83,0,0,0-.8,2.1v4.93a.5.5,0,0,1-.19.36.71.71,0,0,1-.47.16.64.64,0,0,1-.45-.16A.47.47,0,0,1,220.29,146.78Z"/>
            <path className="cls-1" d="M226.19,143.64V141.4a3.48,3.48,0,0,1,1.12-2.59,3.7,3.7,0,0,1,2.7-1.09,3.74,3.74,0,0,1,2.63,1,3.43,3.43,0,0,1,1.11,2.6,2.68,2.68,0,0,1-.13.9.84.84,0,0,1-.42.48,2.37,2.37,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0h-4.52v.74a2.32,2.32,0,0,0,.85,1.89,3.29,3.29,0,0,0,2.19.71,3.33,3.33,0,0,0,1.19-.2,3.47,3.47,0,0,0,.78-.4.84.84,0,0,1,.43-.2.51.51,0,0,1,.39.21.69.69,0,0,1,.17.41c0,.28-.29.57-.86.87a4.61,4.61,0,0,1-2.14.45,4.53,4.53,0,0,1-3.1-1.05A3.48,3.48,0,0,1,226.19,143.64Zm1.3-1.64h4a1.62,1.62,0,0,0,.8-.13.63.63,0,0,0,.2-.55,2.47,2.47,0,0,0-.71-1.74,2.34,2.34,0,0,0-1.79-.76,2.41,2.41,0,0,0-1.77.72,2.27,2.27,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M235.37,143.64V141.5a3.81,3.81,0,0,1,1-2.73,3.74,3.74,0,0,1,2.88-1.05,4.21,4.21,0,0,1,2,.43q.81.43.81.87a.65.65,0,0,1-.18.43.52.52,0,0,1-.4.21.75.75,0,0,1-.37-.18,4.26,4.26,0,0,0-.71-.35,2.85,2.85,0,0,0-1.1-.19,2.63,2.63,0,0,0-1.95.68,2.55,2.55,0,0,0-.67,1.89v2.12a2.57,2.57,0,0,0,.67,1.91,2.66,2.66,0,0,0,2,.68,3,3,0,0,0,1.15-.19,3,3,0,0,0,.72-.37.73.73,0,0,1,.39-.18.57.57,0,0,1,.43.2.67.67,0,0,1,.17.44c0,.31-.28.6-.84.89a4.48,4.48,0,0,1-2.08.43,3.51,3.51,0,0,1-3.88-3.8Z"/>
            <path className="cls-1" d="M243.45,143.64v-2.18a3.57,3.57,0,0,1,1.12-2.63,3.63,3.63,0,0,1,2.66-1.11,3.76,3.76,0,0,1,2.68,1.1,3.53,3.53,0,0,1,1.14,2.64v2.18a3.93,3.93,0,0,1-3.82,3.8,3.6,3.6,0,0,1-2.64-1.15A3.66,3.66,0,0,1,243.45,143.64Zm1.3,0a2.54,2.54,0,0,0,.73,1.8,2.28,2.28,0,0,0,1.75.79,2.36,2.36,0,0,0,1.78-.79,2.55,2.55,0,0,0,.74-1.8v-2.16a2.44,2.44,0,0,0-.75-1.75,2.38,2.38,0,0,0-1.77-.78,2.3,2.3,0,0,0-1.75.78,2.46,2.46,0,0,0-.73,1.75Z"/>
            <path className="cls-1" d="M252.43,149.28a2.28,2.28,0,0,1,1.7-2.18,1.39,1.39,0,0,1-1.1-1.37,1.59,1.59,0,0,1,1-1.43,3.11,3.11,0,0,1-1.1-2.53V141a3.13,3.13,0,0,1,.95-2.38,3.79,3.79,0,0,1,4.83-.08,2.89,2.89,0,0,1,.68-.91,1.11,1.11,0,0,1,.74-.34.6.6,0,0,1,.44.16.54.54,0,0,1,.16.36.41.41,0,0,1-.34.44,1.64,1.64,0,0,0-1.12.84,3.09,3.09,0,0,1,.56,1.89v.76a3.2,3.2,0,0,1-1,2.39,3.47,3.47,0,0,1-2.5.94,3.64,3.64,0,0,1-1.54-.33c-.42.22-.62.48-.62.77a.69.69,0,0,0,.46.64,3.9,3.9,0,0,0,1.14.31l1.5.19a9.36,9.36,0,0,1,1.51.31,2.37,2.37,0,0,1,1.15.73,2.12,2.12,0,0,1,.46,1.4,2.39,2.39,0,0,1-1.08,2.1,5,5,0,0,1-2.84.74,5.37,5.37,0,0,1-2.93-.72A2.19,2.19,0,0,1,252.43,149.28Zm1.26-.12a1.51,1.51,0,0,0,.75,1.32,3.63,3.63,0,0,0,2,.5,3.42,3.42,0,0,0,2-.5,1.58,1.58,0,0,0,.71-1.34,1.28,1.28,0,0,0-.2-.71,1.58,1.58,0,0,0-.47-.46,3.28,3.28,0,0,0-.71-.28,4.77,4.77,0,0,0-.78-.15l-.84-.09-.68-.07C254.28,147.75,253.69,148.35,253.69,149.16Zm.5-7.42a2.48,2.48,0,0,0,.59,1.72,2,2,0,0,0,1.61.66,2,2,0,0,0,1.57-.66,2.49,2.49,0,0,0,.59-1.7V141a2.39,2.39,0,0,0-.59-1.66,2,2,0,0,0-1.59-.66,2,2,0,0,0-1.59.66,2.34,2.34,0,0,0-.59,1.66Z"/>
            <path className="cls-1" d="M262.23,146.78v-8.46a.41.41,0,0,1,.18-.35.74.74,0,0,1,.46-.13.68.68,0,0,1,.42.14.44.44,0,0,1,.18.35v.93a2.77,2.77,0,0,1,1-1.09,2.94,2.94,0,0,1,1.64-.45,3.38,3.38,0,0,1,2.5,1.1,3.69,3.69,0,0,1,1.06,2.64v5.32a.44.44,0,0,1-.2.38.83.83,0,0,1-.46.14.77.77,0,0,1-.44-.14.42.42,0,0,1-.2-.38v-5.31a2.49,2.49,0,0,0-.74-1.75,2.36,2.36,0,0,0-1.76-.78,2.29,2.29,0,0,0-1.66.7,2.43,2.43,0,0,0-.72,1.83v5.31a.48.48,0,0,1-.2.36.72.72,0,0,1-.46.16.65.65,0,0,1-.46-.16A.48.48,0,0,1,262.23,146.78Z"/>
            <path className="cls-1" d="M272.15,134.79a.77.77,0,0,1-.26-.59.73.73,0,0,1,.26-.58.89.89,0,0,1,.64-.24.85.85,0,0,1,.61.24.79.79,0,0,1,.25.58.83.83,0,0,1-.25.59.82.82,0,0,1-.61.25A.86.86,0,0,1,272.15,134.79Zm0,12v-8.46a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.82.82,0,0,1,.47.14.43.43,0,0,1,.19.34v8.46a.48.48,0,0,1-.2.36.72.72,0,0,1-.46.16.64.64,0,0,1-.45-.16A.47.47,0,0,1,272.15,146.78Z"/>
            <path className="cls-1" d="M275.19,146.79a.85.85,0,0,1,.2-.5l4.9-7.31h-4.42a.42.42,0,0,1-.34-.17.55.55,0,0,1-.14-.39.59.59,0,0,1,.14-.41.42.42,0,0,1,.34-.17h5.66a.52.52,0,0,1,.35.14.49.49,0,0,1,.15.38.86.86,0,0,1-.16.48L277,146.16h4.84a.45.45,0,0,1,.34.17.64.64,0,0,1,.14.42.59.59,0,0,1-.14.38.45.45,0,0,1-.34.17h-6.06a.5.5,0,0,1-.39-.16A.49.49,0,0,1,275.19,146.79Z"/>
            <path className="cls-1" d="M283.47,143.64V141.4a3.48,3.48,0,0,1,1.12-2.59,3.7,3.7,0,0,1,2.7-1.09,3.74,3.74,0,0,1,2.63,1,3.43,3.43,0,0,1,1.11,2.6,2.68,2.68,0,0,1-.13.9.84.84,0,0,1-.42.48,2.37,2.37,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0h-4.52v.74a2.32,2.32,0,0,0,.85,1.89,3.29,3.29,0,0,0,2.19.71,3.33,3.33,0,0,0,1.19-.2,3.47,3.47,0,0,0,.78-.4.84.84,0,0,1,.43-.2.51.51,0,0,1,.39.21.69.69,0,0,1,.17.41c0,.28-.29.57-.86.87a4.61,4.61,0,0,1-2.14.45,4.53,4.53,0,0,1-3.1-1.05A3.48,3.48,0,0,1,283.47,143.64Zm1.3-1.64h4a1.62,1.62,0,0,0,.8-.13.63.63,0,0,0,.2-.55,2.47,2.47,0,0,0-.71-1.74,2.34,2.34,0,0,0-1.79-.76,2.41,2.41,0,0,0-1.77.72,2.27,2.27,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M292.39,146a.65.65,0,0,1,.14-.4.4.4,0,0,1,.32-.2,1,1,0,0,1,.44.23,4.08,4.08,0,0,0,.86.47,3.38,3.38,0,0,0,1.34.24,2.49,2.49,0,0,0,1.56-.4,1.27,1.27,0,0,0,.5-1,1.43,1.43,0,0,0-.51-1.15,3.44,3.44,0,0,0-1.23-.65l-1.45-.44a2.72,2.72,0,0,1-1.24-.77,2.09,2.09,0,0,1-.51-1.47,2.64,2.64,0,0,1,.79-1.91,3.15,3.15,0,0,1,2.37-.81,5.25,5.25,0,0,1,1.83.31c.56.21.85.43.85.67a.88.88,0,0,1-.16.44.47.47,0,0,1-.38.24,5.24,5.24,0,0,1-.74-.28,3.58,3.58,0,0,0-1.38-.28,2.12,2.12,0,0,0-1.5.47,1.4,1.4,0,0,0-.5,1.06,1.11,1.11,0,0,0,.52,1,4.43,4.43,0,0,0,1.24.56l1.46.44a2.33,2.33,0,0,1,1.24.87,2.69,2.69,0,0,1,.5,1.68,2.32,2.32,0,0,1-.85,1.89,3.68,3.68,0,0,1-2.37.69,4.84,4.84,0,0,1-2.23-.47C292.69,146.66,292.39,146.33,292.39,146Z"/>
            <path className="cls-1" d="M304.65,144.36V133.84a.39.39,0,0,1,.2-.34.77.77,0,0,1,.44-.14.83.83,0,0,1,.46.14.42.42,0,0,1,.2.34v4h2.58a.41.41,0,0,1,.33.16.65.65,0,0,1,0,.78.41.41,0,0,1-.33.16H306v5.39a1.78,1.78,0,0,0,.41,1.36,2.08,2.08,0,0,0,1.45.39h.56a.58.58,0,0,1,.43.18.57.57,0,0,1,.17.42.61.61,0,0,1-.17.43.56.56,0,0,1-.43.19h-.56Q304.65,147.3,304.65,144.36Z"/>
            <path className="cls-1" d="M310.63,146.78V132.54a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.82.82,0,0,1,.47.14.43.43,0,0,1,.19.34v6.72a2.82,2.82,0,0,1,1-1.09,2.87,2.87,0,0,1,1.61-.45,3.34,3.34,0,0,1,2.49,1.1,3.68,3.68,0,0,1,1.05,2.64v5.32a.46.46,0,0,1-.19.38.82.82,0,0,1-.47.14.77.77,0,0,1-.44-.14.42.42,0,0,1-.2-.38v-5.31a2.49,2.49,0,0,0-.74-1.75,2.34,2.34,0,0,0-1.74-.78,2.32,2.32,0,0,0-1.67.7,2.4,2.4,0,0,0-.73,1.83v5.31a.48.48,0,0,1-.2.36.72.72,0,0,1-.46.16.64.64,0,0,1-.45-.16A.47.47,0,0,1,310.63,146.78Z"/>
            <path className="cls-1" d="M319.89,144.8a2.24,2.24,0,0,1,1.19-2.19,7.76,7.76,0,0,1,3.47-.61h1v-.47a3.16,3.16,0,0,0-.58-1.88,2.08,2.08,0,0,0-1.78-.79,3.54,3.54,0,0,0-1.45.28l-.69.29a.42.42,0,0,1-.38-.22.79.79,0,0,1-.14-.47c0-.25.28-.49.86-.7a5.27,5.27,0,0,1,1.84-.32,3.3,3.3,0,0,1,2.75,1.09,4.31,4.31,0,0,1,.87,2.77v5.2a.46.46,0,0,1-.18.37.71.71,0,0,1-.46.15.61.61,0,0,1-.43-.15.45.45,0,0,1-.17-.37v-.88a3.94,3.94,0,0,1-3,1.54,2.83,2.83,0,0,1-2-.67A2.48,2.48,0,0,1,319.89,144.8Zm1.3-.12a1.55,1.55,0,0,0,.48,1.25,1.89,1.89,0,0,0,1.26.41,2.78,2.78,0,0,0,1.79-.7,1.93,1.93,0,0,0,.85-1.4V143h-.84a12.58,12.58,0,0,0-1.35.06,5.85,5.85,0,0,0-1.09.22,1.56,1.56,0,0,0-.82.52A1.44,1.44,0,0,0,321.19,144.68Z"/>
            <path className="cls-1" d="M329.27,144.36V133.84a.39.39,0,0,1,.2-.34.77.77,0,0,1,.44-.14.83.83,0,0,1,.46.14.42.42,0,0,1,.2.34v4h2.58a.41.41,0,0,1,.33.16.65.65,0,0,1,0,.78.41.41,0,0,1-.33.16h-2.58v5.39a1.78,1.78,0,0,0,.41,1.36,2.08,2.08,0,0,0,1.45.39H333a.58.58,0,0,1,.43.18.57.57,0,0,1,.17.42.61.61,0,0,1-.17.43.56.56,0,0,1-.43.19h-.56Q329.27,147.3,329.27,144.36Z"/>
            <path className="cls-1" d="M338.91,143.64V141.5a3.81,3.81,0,0,1,1-2.73,3.74,3.74,0,0,1,2.88-1.05,4.21,4.21,0,0,1,2,.43q.81.43.81.87a.65.65,0,0,1-.18.43.53.53,0,0,1-.4.21.75.75,0,0,1-.37-.18,4.26,4.26,0,0,0-.71-.35,2.85,2.85,0,0,0-1.1-.19,2.61,2.61,0,0,0-1.95.68,2.55,2.55,0,0,0-.67,1.89v2.12a2.57,2.57,0,0,0,.67,1.91,2.64,2.64,0,0,0,2,.68A3,3,0,0,0,344,146a3,3,0,0,0,.72-.37.73.73,0,0,1,.39-.18.57.57,0,0,1,.43.2.67.67,0,0,1,.17.44c0,.31-.28.6-.84.89a4.51,4.51,0,0,1-2.08.43,3.51,3.51,0,0,1-3.88-3.8Z"/>
            <path className="cls-1" d="M347,143.64v-2.18a3.57,3.57,0,0,1,1.12-2.63,3.63,3.63,0,0,1,2.66-1.11,3.76,3.76,0,0,1,2.68,1.1,3.56,3.56,0,0,1,1.14,2.64v2.18a3.93,3.93,0,0,1-3.82,3.8,3.6,3.6,0,0,1-2.64-1.15A3.66,3.66,0,0,1,347,143.64Zm1.3,0a2.54,2.54,0,0,0,.73,1.8,2.28,2.28,0,0,0,1.75.79,2.36,2.36,0,0,0,1.78-.79,2.55,2.55,0,0,0,.74-1.8v-2.16a2.44,2.44,0,0,0-.75-1.75,2.38,2.38,0,0,0-1.77-.78,2.3,2.3,0,0,0-1.75.78,2.46,2.46,0,0,0-.73,1.75Z"/>
            <path className="cls-1" d="M356.72,146.78v-8.46a.43.43,0,0,1,.19-.34.72.72,0,0,1,.46-.14.69.69,0,0,1,.43.14.44.44,0,0,1,.18.35v.93a3,3,0,0,1,2.66-1.54,2.85,2.85,0,0,1,1.73.55,3.26,3.26,0,0,1,1.11,1.47,3.84,3.84,0,0,1,1.32-1.51,3.22,3.22,0,0,1,1.72-.51,3.18,3.18,0,0,1,2.36,1,3.71,3.71,0,0,1,1,2.72v5.32a.48.48,0,0,1-.18.36.71.71,0,0,1-.48.16.67.67,0,0,1-.45-.16.5.5,0,0,1-.19-.36v-5.31a2.47,2.47,0,0,0-.69-1.85,2.2,2.2,0,0,0-1.59-.68,2.29,2.29,0,0,0-1.63.69,2.37,2.37,0,0,0-.71,1.76v5.41c0,.33-.21.5-.66.5s-.63-.17-.63-.5v-5.39a2.49,2.49,0,0,0-.67-1.76,2.17,2.17,0,0,0-1.62-.71,2.32,2.32,0,0,0-1.65.69,2.46,2.46,0,0,0-.71,1.84v5.31a.47.47,0,0,1-.19.36.72.72,0,0,1-.46.16.64.64,0,0,1-.45-.16A.48.48,0,0,1,356.72,146.78Z"/>
            <path className="cls-1" d="M372.32,151.44V138.32a.42.42,0,0,1,.18-.34.76.76,0,0,1,.46-.14.7.7,0,0,1,.44.14.44.44,0,0,1,.18.35v.93a3.17,3.17,0,0,1,1.06-1.1,3,3,0,0,1,1.62-.44,3.4,3.4,0,0,1,2.54,1.1,3.65,3.65,0,0,1,1.06,2.64v2.18a3.75,3.75,0,0,1-1.07,2.65,3.26,3.26,0,0,1-2.47,1.15,3,3,0,0,1-1.62-.46,2.91,2.91,0,0,1-1.08-1.1v5.56a.45.45,0,0,1-.19.34.74.74,0,0,1-.92,0A.48.48,0,0,1,372.32,151.44Zm1.3-7.19a1.92,1.92,0,0,0,.75,1.25,2.4,2.4,0,0,0,1.71.72,2.31,2.31,0,0,0,1.73-.8,2.53,2.53,0,0,0,.75-1.79v-2.16a2.44,2.44,0,0,0-.75-1.75,2.39,2.39,0,0,0-3.46-.08,2.42,2.42,0,0,0-.73,1.83Z"/>
            <path className="cls-1" d="M382.16,146.78V132.52a.42.42,0,0,1,.18-.34.76.76,0,0,1,.46-.14.77.77,0,0,1,.47.14.4.4,0,0,1,.19.34v14.26a.47.47,0,0,1-.19.36.74.74,0,0,1-.92,0A.5.5,0,0,1,382.16,146.78Z"/>
            <path className="cls-1" d="M385.78,143.64V141.4a3.52,3.52,0,0,1,1.12-2.59,3.87,3.87,0,0,1,5.33-.05,3.43,3.43,0,0,1,1.11,2.6,2.45,2.45,0,0,1-.13.9.78.78,0,0,1-.42.48,2.21,2.21,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0h-4.52v.74a2.35,2.35,0,0,0,.85,1.89,3.33,3.33,0,0,0,2.19.71,3.29,3.29,0,0,0,1.19-.2,3.47,3.47,0,0,0,.78-.4.87.87,0,0,1,.43-.2.49.49,0,0,1,.39.21.63.63,0,0,1,.17.41c0,.28-.28.57-.86.87a5.18,5.18,0,0,1-5.24-.6A3.48,3.48,0,0,1,385.78,143.64Zm1.3-1.64h4a1.59,1.59,0,0,0,.8-.13c.14-.09.2-.27.2-.55a2.43,2.43,0,0,0-.71-1.74,2.33,2.33,0,0,0-1.79-.76,2.44,2.44,0,0,0-1.77.72,2.31,2.31,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M394.54,146.8a.71.71,0,0,1,.08-.28l2.66-4-2.6-3.88a1,1,0,0,1-.1-.26.58.58,0,0,1,.27-.45.88.88,0,0,1,.53-.21.43.43,0,0,1,.4.2l2.36,3.66,2.38-3.66a.42.42,0,0,1,.38-.2.85.85,0,0,1,.53.21.58.58,0,0,1,.27.45.5.5,0,0,1-.1.26L399,142.52l2.68,4a.44.44,0,0,1,.08.28.56.56,0,0,1-.25.43.79.79,0,0,1-.53.21.42.42,0,0,1-.38-.2l-2.44-3.74-2.42,3.74a.48.48,0,0,1-.42.2.82.82,0,0,1-.5-.21A.58.58,0,0,1,394.54,146.8Z"/>
            <path className="cls-1" d="M403.5,134.79a.8.8,0,0,1-.26-.59.77.77,0,0,1,.26-.58.93.93,0,0,1,.64-.24.84.84,0,0,1,.61.24.76.76,0,0,1,.25.58.79.79,0,0,1-.25.59.81.81,0,0,1-.61.25A.89.89,0,0,1,403.5,134.79Zm0,12v-8.46a.42.42,0,0,1,.18-.34.76.76,0,0,1,.46-.14.77.77,0,0,1,.47.14.4.4,0,0,1,.19.34v8.46a.45.45,0,0,1-.2.36.68.68,0,0,1-.46.16.67.67,0,0,1-.45-.16A.5.5,0,0,1,403.5,146.78Z"/>
            <path className="cls-1" d="M407.28,144.36V133.84a.42.42,0,0,1,.2-.34.79.79,0,0,1,.9,0,.39.39,0,0,1,.2.34v4h2.58a.38.38,0,0,1,.33.16.65.65,0,0,1,0,.78.38.38,0,0,1-.33.16h-2.58v5.39a1.83,1.83,0,0,0,.41,1.36,2.12,2.12,0,0,0,1.45.39H411a.55.55,0,0,1,.43.18.54.54,0,0,1,.17.42.57.57,0,0,1-.17.43.53.53,0,0,1-.43.19h-.56C408.34,147.3,407.28,146.32,407.28,144.36Z"/>
            <path className="cls-1" d="M412.29,138.34a.54.54,0,0,1,.25-.43.91.91,0,0,1,.54-.19c.26,0,.41.09.46.28l2.75,7.34,2.58-7.34a.42.42,0,0,1,.43-.28.86.86,0,0,1,.53.19.55.55,0,0,1,.27.45.22.22,0,0,1,0,.16l-3.1,8.4-.36,1c-.06.17-.19.49-.39.94a5.81,5.81,0,0,1-.49,1l-.52.8a2.76,2.76,0,0,1-.65.72,3.71,3.71,0,0,1-.75.41,2.16,2.16,0,0,1-.89.19.53.53,0,0,1-.61-.6c0-.41.14-.62.4-.62a2.18,2.18,0,0,0,1.72-1.11,7.83,7.83,0,0,0,1.18-2.74l-3.26-8.35A.35.35,0,0,1,412.29,138.34Z"/>
            <path className="cls-1" d="M425.67,134.79a.77.77,0,0,1-.26-.59.73.73,0,0,1,.26-.58.89.89,0,0,1,.64-.24.87.87,0,0,1,.61.24.79.79,0,0,1,.25.58.85.85,0,0,1-.86.84A.86.86,0,0,1,425.67,134.79Zm0,12v-8.46a.42.42,0,0,1,.18-.34.72.72,0,0,1,.46-.14.82.82,0,0,1,.47.14.42.42,0,0,1,.18.34v8.46a.47.47,0,0,1-.19.36.72.72,0,0,1-.46.16.64.64,0,0,1-.45-.16A.47.47,0,0,1,425.67,146.78Z"/>
            <path className="cls-1" d="M428.89,146a.65.65,0,0,1,.14-.4.4.4,0,0,1,.32-.2,1,1,0,0,1,.44.23,4.08,4.08,0,0,0,.86.47,3.38,3.38,0,0,0,1.34.24,2.49,2.49,0,0,0,1.56-.4,1.27,1.27,0,0,0,.5-1,1.43,1.43,0,0,0-.51-1.15,3.44,3.44,0,0,0-1.23-.65l-1.45-.44a2.72,2.72,0,0,1-1.24-.77,2.09,2.09,0,0,1-.51-1.47,2.64,2.64,0,0,1,.79-1.91,3.15,3.15,0,0,1,2.37-.81,5.25,5.25,0,0,1,1.83.31c.56.21.85.43.85.67a.88.88,0,0,1-.16.44.47.47,0,0,1-.38.24,5.24,5.24,0,0,1-.74-.28,3.58,3.58,0,0,0-1.38-.28,2.12,2.12,0,0,0-1.5.47,1.4,1.4,0,0,0-.5,1.06,1.12,1.12,0,0,0,.51,1,4.57,4.57,0,0,0,1.24.56l1.47.44a2.37,2.37,0,0,1,1.24.87,2.69,2.69,0,0,1,.5,1.68,2.32,2.32,0,0,1-.85,1.89,3.68,3.68,0,0,1-2.37.69,4.84,4.84,0,0,1-2.23-.47C429.19,146.66,428.89,146.33,428.89,146Z"/>
            <path className="cls-1" d="M440.53,144.8a2.24,2.24,0,0,1,1.19-2.19,7.76,7.76,0,0,1,3.47-.61h1v-.47a3.16,3.16,0,0,0-.58-1.88,2.08,2.08,0,0,0-1.78-.79,3.54,3.54,0,0,0-1.45.28l-.69.29a.42.42,0,0,1-.38-.22.79.79,0,0,1-.14-.47c0-.25.28-.49.86-.7a5.27,5.27,0,0,1,1.84-.32,3.3,3.3,0,0,1,2.75,1.09,4.31,4.31,0,0,1,.87,2.77v5.2a.46.46,0,0,1-.18.37.71.71,0,0,1-.46.15.61.61,0,0,1-.43-.15.45.45,0,0,1-.17-.37v-.88a3.94,3.94,0,0,1-3,1.54,2.83,2.83,0,0,1-2-.67A2.48,2.48,0,0,1,440.53,144.8Zm1.3-.12a1.55,1.55,0,0,0,.48,1.25,1.89,1.89,0,0,0,1.26.41,2.78,2.78,0,0,0,1.79-.7,1.93,1.93,0,0,0,.85-1.4V143h-.84A12.58,12.58,0,0,0,444,143a5.85,5.85,0,0,0-1.09.22,1.56,1.56,0,0,0-.82.52A1.44,1.44,0,0,0,441.83,144.68Z"/>
            <path className="cls-1" d="M450.11,146.78V132.52a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.82.82,0,0,1,.47.14.43.43,0,0,1,.19.34v14.26a.5.5,0,0,1-.19.36.71.71,0,0,1-.47.16.64.64,0,0,1-.45-.16A.47.47,0,0,1,450.11,146.78Z"/>
            <path className="cls-1" d="M453.49,146a.65.65,0,0,1,.14-.4.4.4,0,0,1,.32-.2.89.89,0,0,1,.43.23,4.2,4.2,0,0,0,.87.47,3.36,3.36,0,0,0,1.33.24,2.5,2.5,0,0,0,1.57-.4,1.27,1.27,0,0,0,.5-1,1.41,1.41,0,0,0-.52-1.15,3.48,3.48,0,0,0-1.22-.65l-1.46-.44a2.83,2.83,0,0,1-1.24-.77,2.13,2.13,0,0,1-.51-1.47,2.65,2.65,0,0,1,.8-1.91,3.15,3.15,0,0,1,2.37-.81,5.25,5.25,0,0,1,1.83.31c.56.21.84.43.84.67a.79.79,0,0,1-.16.44.44.44,0,0,1-.38.24,5.43,5.43,0,0,1-.73-.28,3.59,3.59,0,0,0-1.39-.28,2.16,2.16,0,0,0-1.5.47,1.44,1.44,0,0,0-.5,1.06,1.13,1.13,0,0,0,.52,1,4.57,4.57,0,0,0,1.24.56l1.47.44a2.37,2.37,0,0,1,1.24.87,2.68,2.68,0,0,1,.49,1.68,2.31,2.31,0,0,1-.84,1.89,3.68,3.68,0,0,1-2.37.69,4.84,4.84,0,0,1-2.23-.47C453.79,146.66,453.49,146.33,453.49,146Z"/>
            <path className="cls-1" d="M461.41,143.64v-2.18a3.57,3.57,0,0,1,1.12-2.63,3.63,3.63,0,0,1,2.66-1.11,3.76,3.76,0,0,1,2.68,1.1,3.53,3.53,0,0,1,1.14,2.64v2.18a3.93,3.93,0,0,1-3.82,3.8,3.63,3.63,0,0,1-2.65-1.15A3.69,3.69,0,0,1,461.41,143.64Zm1.29,0a2.55,2.55,0,0,0,.74,1.8,2.28,2.28,0,0,0,1.75.79,2.36,2.36,0,0,0,1.78-.79,2.54,2.54,0,0,0,.73-1.8v-2.16a2.44,2.44,0,0,0-.75-1.75,2.36,2.36,0,0,0-1.76-.78,2.3,2.3,0,0,0-1.75.78,2.47,2.47,0,0,0-.74,1.75Z"/>
            <path className="cls-1" d="M474.48,144.8a2.25,2.25,0,0,1,1.19-2.19,7.82,7.82,0,0,1,3.47-.61h1v-.47a3.1,3.1,0,0,0-.59-1.88,2.07,2.07,0,0,0-1.78-.79,3.61,3.61,0,0,0-1.45.28l-.68.29a.41.41,0,0,1-.38-.22.8.8,0,0,1-.15-.47c0-.25.29-.49.86-.7a5.35,5.35,0,0,1,1.84-.32,3.29,3.29,0,0,1,2.75,1.09,4.31,4.31,0,0,1,.87,2.77v5.2a.48.48,0,0,1-.17.37.71.71,0,0,1-.46.15.65.65,0,0,1-.44-.15.48.48,0,0,1-.17-.37v-.88a3.91,3.91,0,0,1-3,1.54,2.8,2.8,0,0,1-1.95-.67A2.48,2.48,0,0,1,474.48,144.8Zm1.31-.12a1.54,1.54,0,0,0,.47,1.25,1.89,1.89,0,0,0,1.26.41,2.74,2.74,0,0,0,1.79-.7,1.91,1.91,0,0,0,.86-1.4V143h-.84A12.91,12.91,0,0,0,478,143a6.2,6.2,0,0,0-1.09.22,1.6,1.6,0,0,0-.82.52A1.5,1.5,0,0,0,475.79,144.68Z"/>
            <path className="cls-1" d="M2,179a.72.72,0,0,1,.14-.4.4.4,0,0,1,.32-.2,1,1,0,0,1,.44.23,4.35,4.35,0,0,0,.86.47,3.38,3.38,0,0,0,1.34.24,2.49,2.49,0,0,0,1.56-.4,1.27,1.27,0,0,0,.5-1,1.43,1.43,0,0,0-.51-1.15,3.44,3.44,0,0,0-1.23-.65L4,175.68a2.72,2.72,0,0,1-1.24-.77,2.09,2.09,0,0,1-.51-1.47A2.64,2.64,0,0,1,3,171.53a3.15,3.15,0,0,1,2.37-.81A5.25,5.25,0,0,1,7.2,171c.56.21.85.43.85.67a.79.79,0,0,1-.16.44.47.47,0,0,1-.38.24,5.24,5.24,0,0,1-.74-.28,3.58,3.58,0,0,0-1.38-.28,2.12,2.12,0,0,0-1.5.47,1.4,1.4,0,0,0-.5,1.06,1.11,1.11,0,0,0,.52,1,4.43,4.43,0,0,0,1.24.56l1.46.44a2.33,2.33,0,0,1,1.24.87,2.69,2.69,0,0,1,.5,1.68,2.32,2.32,0,0,1-.85,1.89,3.68,3.68,0,0,1-2.37.69A4.84,4.84,0,0,1,2.9,180C2.29,179.66,2,179.33,2,179Z"/>
            <path className="cls-1" d="M9.91,176.64v-2.18A3.57,3.57,0,0,1,11,171.83a3.63,3.63,0,0,1,2.66-1.11,3.76,3.76,0,0,1,2.68,1.1,3.53,3.53,0,0,1,1.14,2.64v2.18a3.63,3.63,0,0,1-1.15,2.65,3.66,3.66,0,0,1-2.67,1.15,3.6,3.6,0,0,1-2.64-1.15A3.66,3.66,0,0,1,9.91,176.64Zm1.3,0a2.54,2.54,0,0,0,.73,1.8,2.28,2.28,0,0,0,1.75.79,2.36,2.36,0,0,0,1.78-.79,2.55,2.55,0,0,0,.74-1.8v-2.16a2.44,2.44,0,0,0-.75-1.75,2.38,2.38,0,0,0-1.77-.78,2.3,2.3,0,0,0-1.75.78,2.46,2.46,0,0,0-.73,1.75Z"/>
            <path className="cls-1" d="M19.55,176.68v-5.34a.45.45,0,0,1,.19-.34.67.67,0,0,1,.47-.16.68.68,0,0,1,.44.16.46.46,0,0,1,.2.34v5.33a2.48,2.48,0,0,0,.74,1.77,2.38,2.38,0,0,0,3.52,0,2.52,2.52,0,0,0,.74-1.77v-5.35a.39.39,0,0,1,.2-.34.78.78,0,0,1,.46-.14A.77.77,0,0,1,27,171a.43.43,0,0,1,.19.34v5.36A3.61,3.61,0,0,1,26,179.31a3.69,3.69,0,0,1-2.67,1.13,3.64,3.64,0,0,1-2.68-1.13A3.6,3.6,0,0,1,19.55,176.68Z"/>
            <path className="cls-1" d="M29.57,179.78v-8.46a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.72.72,0,0,1,.44.14.44.44,0,0,1,.18.35v1a3.08,3.08,0,0,1,1.06-1.13,2.83,2.83,0,0,1,1.62-.47h.7a.48.48,0,0,1,.36.18.66.66,0,0,1,0,.86.48.48,0,0,1-.36.18h-.7a2.5,2.5,0,0,0-1.84.81,2.83,2.83,0,0,0-.8,2.1v4.93a.5.5,0,0,1-.19.36.71.71,0,0,1-.47.16.64.64,0,0,1-.45-.16A.47.47,0,0,1,29.57,179.78Z"/>
            <path className="cls-1" d="M35.49,176.64V174.5a3.81,3.81,0,0,1,1-2.73,3.74,3.74,0,0,1,2.88-1.05,4.21,4.21,0,0,1,2,.43q.81.43.81.87a.65.65,0,0,1-.18.43.52.52,0,0,1-.4.21.75.75,0,0,1-.37-.18,4.26,4.26,0,0,0-.71-.35,2.85,2.85,0,0,0-1.1-.19,2.63,2.63,0,0,0-1.95.68,2.55,2.55,0,0,0-.67,1.89v2.12a2.57,2.57,0,0,0,.67,1.91,2.66,2.66,0,0,0,2,.68,3,3,0,0,0,1.15-.19,3,3,0,0,0,.72-.37.73.73,0,0,1,.39-.18.57.57,0,0,1,.43.2.67.67,0,0,1,.17.44c0,.31-.28.6-.84.89a4.48,4.48,0,0,1-2.08.43,3.51,3.51,0,0,1-3.88-3.8Z"/>
            <path className="cls-1" d="M43.59,176.64V174.4a3.48,3.48,0,0,1,1.12-2.59,3.7,3.7,0,0,1,2.7-1.09,3.74,3.74,0,0,1,2.63,1,3.43,3.43,0,0,1,1.11,2.6,2.68,2.68,0,0,1-.13.9.84.84,0,0,1-.42.48,2.37,2.37,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0H44.89v.74a2.32,2.32,0,0,0,.85,1.89,3.29,3.29,0,0,0,2.19.71,3.33,3.33,0,0,0,1.19-.2,3.47,3.47,0,0,0,.78-.4.84.84,0,0,1,.43-.2.51.51,0,0,1,.39.21.69.69,0,0,1,.17.41c0,.28-.29.57-.86.87a4.61,4.61,0,0,1-2.14.45,4.53,4.53,0,0,1-3.1-1.05A3.48,3.48,0,0,1,43.59,176.64Zm1.3-1.64h4a1.62,1.62,0,0,0,.8-.13.63.63,0,0,0,.2-.55,2.47,2.47,0,0,0-.71-1.74,2.34,2.34,0,0,0-1.79-.76,2.41,2.41,0,0,0-1.77.72,2.27,2.27,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M56.73,176.64v-2.18a3.57,3.57,0,0,1,1.12-2.63,3.63,3.63,0,0,1,2.66-1.11,3.76,3.76,0,0,1,2.68,1.1,3.53,3.53,0,0,1,1.14,2.64v2.18a3.93,3.93,0,0,1-3.82,3.8,3.6,3.6,0,0,1-2.64-1.15A3.66,3.66,0,0,1,56.73,176.64Zm1.3,0a2.54,2.54,0,0,0,.73,1.8,2.28,2.28,0,0,0,1.75.79,2.36,2.36,0,0,0,1.78-.79,2.55,2.55,0,0,0,.74-1.8v-2.16a2.44,2.44,0,0,0-.75-1.75,2.38,2.38,0,0,0-1.77-.78,2.3,2.3,0,0,0-1.75.78,2.46,2.46,0,0,0-.73,1.75Z"/>
            <path className="cls-1" d="M66.47,179.8V168.69a3.6,3.6,0,0,1,1.06-2.6A3.35,3.35,0,0,1,70,165h.5a.5.5,0,0,1,.4.19.65.65,0,0,1,.16.43.61.61,0,0,1-.16.42.52.52,0,0,1-.4.18h-.34a2.3,2.3,0,0,0-1.71.71,2.4,2.4,0,0,0-.71,1.69v2.22h2.86A.41.41,0,0,1,71,171a.62.62,0,0,1,.13.37.69.69,0,0,1-.13.41.41.41,0,0,1-.33.16H67.77v7.86a.42.42,0,0,1-.2.36.87.87,0,0,1-.48.14.7.7,0,0,1-.44-.14A.43.43,0,0,1,66.47,179.8Z"/>
            <path className="cls-1" d="M76.23,176.64V174.5a3.81,3.81,0,0,1,1-2.73,3.74,3.74,0,0,1,2.88-1.05,4.21,4.21,0,0,1,2,.43q.81.43.81.87a.65.65,0,0,1-.18.43.53.53,0,0,1-.4.21.75.75,0,0,1-.37-.18,4.26,4.26,0,0,0-.71-.35,2.85,2.85,0,0,0-1.1-.19,2.61,2.61,0,0,0-2,.68,2.55,2.55,0,0,0-.67,1.89v2.12a2.57,2.57,0,0,0,.67,1.91,2.64,2.64,0,0,0,2,.68,3,3,0,0,0,1.15-.19,3,3,0,0,0,.72-.37.73.73,0,0,1,.39-.18.57.57,0,0,1,.43.2.67.67,0,0,1,.17.44c0,.31-.28.6-.84.89a4.51,4.51,0,0,1-2.08.43,3.51,3.51,0,0,1-3.88-3.8Z"/>
            <path className="cls-1" d="M84.79,179.78v-8.46A.42.42,0,0,1,85,171a.74.74,0,0,1,.46-.14.72.72,0,0,1,.44.14.44.44,0,0,1,.18.35v1a3.08,3.08,0,0,1,1.06-1.13,2.83,2.83,0,0,1,1.62-.47h.7a.48.48,0,0,1,.36.18.66.66,0,0,1,0,.86.48.48,0,0,1-.36.18h-.7a2.5,2.5,0,0,0-1.84.81,2.83,2.83,0,0,0-.8,2.1v4.93a.5.5,0,0,1-.19.36.71.71,0,0,1-.47.16.64.64,0,0,1-.45-.16A.47.47,0,0,1,84.79,179.78Z"/>
            <path className="cls-1" d="M90.69,176.64V174.4a3.48,3.48,0,0,1,1.12-2.59,3.7,3.7,0,0,1,2.7-1.09,3.74,3.74,0,0,1,2.63,1,3.43,3.43,0,0,1,1.11,2.6,2.68,2.68,0,0,1-.13.9.84.84,0,0,1-.42.48,2.37,2.37,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0H92v.74a2.32,2.32,0,0,0,.85,1.89,3.29,3.29,0,0,0,2.19.71,3.33,3.33,0,0,0,1.19-.2,3.47,3.47,0,0,0,.78-.4.84.84,0,0,1,.43-.2.51.51,0,0,1,.39.21.69.69,0,0,1,.17.41c0,.28-.29.57-.86.87a4.61,4.61,0,0,1-2.14.45,4.53,4.53,0,0,1-3.1-1.05A3.48,3.48,0,0,1,90.69,176.64ZM92,175h4a1.62,1.62,0,0,0,.8-.13.63.63,0,0,0,.2-.55,2.47,2.47,0,0,0-.71-1.74,2.34,2.34,0,0,0-1.79-.76,2.41,2.41,0,0,0-1.77.72,2.27,2.27,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M99.55,177.8a2.24,2.24,0,0,1,1.19-2.19,7.76,7.76,0,0,1,3.47-.61h1v-.47a3.16,3.16,0,0,0-.58-1.88,2.08,2.08,0,0,0-1.78-.79,3.54,3.54,0,0,0-1.45.28l-.69.29a.42.42,0,0,1-.38-.22.79.79,0,0,1-.14-.47c0-.25.28-.49.86-.7a5.27,5.27,0,0,1,1.84-.32,3.3,3.3,0,0,1,2.75,1.09,4.31,4.31,0,0,1,.87,2.77v5.2a.46.46,0,0,1-.18.37.71.71,0,0,1-.46.15.61.61,0,0,1-.43-.15.45.45,0,0,1-.17-.37v-.88a3.94,3.94,0,0,1-3,1.54,2.83,2.83,0,0,1-2-.67A2.48,2.48,0,0,1,99.55,177.8Zm1.3-.12a1.55,1.55,0,0,0,.48,1.25,1.89,1.89,0,0,0,1.26.41,2.78,2.78,0,0,0,1.79-.7,1.93,1.93,0,0,0,.85-1.4V176h-.84A12.58,12.58,0,0,0,103,176a5.85,5.85,0,0,0-1.09.22,1.56,1.56,0,0,0-.82.52A1.44,1.44,0,0,0,100.85,177.68Z"/>
            <path className="cls-1" d="M108.93,177.36V166.84a.39.39,0,0,1,.2-.34.77.77,0,0,1,.44-.14.83.83,0,0,1,.46.14.42.42,0,0,1,.2.34v4h2.58a.41.41,0,0,1,.33.16.65.65,0,0,1,0,.78.41.41,0,0,1-.33.16h-2.58v5.39a1.78,1.78,0,0,0,.41,1.36,2.08,2.08,0,0,0,1.45.39h.56a.58.58,0,0,1,.43.18.57.57,0,0,1,.17.42.61.61,0,0,1-.17.43.56.56,0,0,1-.43.19h-.56Q108.93,180.3,108.93,177.36Z"/>
            <path className="cls-1" d="M114.91,167.79a.77.77,0,0,1-.26-.59.73.73,0,0,1,.26-.58.89.89,0,0,1,.64-.24.85.85,0,0,1,.61.24.79.79,0,0,1,.25.58.83.83,0,0,1-.25.59.82.82,0,0,1-.61.25A.86.86,0,0,1,114.91,167.79Zm0,12v-8.46a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.82.82,0,0,1,.47.14.43.43,0,0,1,.19.34v8.46a.48.48,0,0,1-.2.36.72.72,0,0,1-.46.16.64.64,0,0,1-.45-.16A.47.47,0,0,1,114.91,179.78Z"/>
            <path className="cls-1" d="M117.77,171.4A.51.51,0,0,1,118,171a.87.87,0,0,1,.53-.18.47.47,0,0,1,.48.3l2.64,7.2,2.64-7.12a.51.51,0,0,1,.46-.38,1,1,0,0,1,.52.18.51.51,0,0,1,.28.4.76.76,0,0,1-.08.32l-3.22,8.44c-.08.21-.28.32-.6.32s-.52-.11-.6-.32l-3.22-8.44A.48.48,0,0,1,117.77,171.4Z"/>
            <path className="cls-1" d="M127.13,167.79a.77.77,0,0,1-.26-.59.73.73,0,0,1,.26-.58.89.89,0,0,1,.64-.24.85.85,0,0,1,.61.24.79.79,0,0,1,.25.58.83.83,0,0,1-.25.59.82.82,0,0,1-.61.25A.86.86,0,0,1,127.13,167.79Zm0,12v-8.46a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.82.82,0,0,1,.47.14.43.43,0,0,1,.19.34v8.46a.48.48,0,0,1-.2.36.72.72,0,0,1-.46.16.64.64,0,0,1-.45-.16A.47.47,0,0,1,127.13,179.78Z"/>
            <path className="cls-1" d="M130.91,177.36V166.84a.39.39,0,0,1,.2-.34.77.77,0,0,1,.44-.14.81.81,0,0,1,.46.14.42.42,0,0,1,.2.34v4h2.58a.41.41,0,0,1,.33.16.65.65,0,0,1,0,.78.41.41,0,0,1-.33.16h-2.58v5.39a1.78,1.78,0,0,0,.41,1.36,2.1,2.1,0,0,0,1.45.39h.56a.57.57,0,0,1,.43.18.57.57,0,0,1,.17.42.61.61,0,0,1-.17.43.54.54,0,0,1-.43.19h-.56Q130.91,180.3,130.91,177.36Z"/>
            <path className="cls-1" d="M135.91,171.34a.52.52,0,0,1,.26-.43.87.87,0,0,1,.54-.19.42.42,0,0,1,.46.28l2.74,7.34,2.58-7.34a.43.43,0,0,1,.44-.28.91.91,0,0,1,.53.19.55.55,0,0,1,.27.45.28.28,0,0,1,0,.16l-3.1,8.4-.36,1-.39.94a7.9,7.9,0,0,1-.49,1l-.52.8a3.16,3.16,0,0,1-.65.72,4.35,4.35,0,0,1-.75.41,2.24,2.24,0,0,1-.9.19.53.53,0,0,1-.6-.6c0-.41.13-.62.4-.62a2.18,2.18,0,0,0,1.71-1.11,8,8,0,0,0,1.19-2.74L136,171.52A.38.38,0,0,1,135.91,171.34Z"/>
            <path className="cls-1" d="M144.29,179.43a1,1,0,0,1,.25-.72.82.82,0,0,1,.64-.29.8.8,0,0,1,.61.3,1.07,1.07,0,0,1,.26.71,1,1,0,0,1-.26.69.8.8,0,0,1-.61.3.82.82,0,0,1-.64-.29A1,1,0,0,1,144.29,179.43Z"/>
            <path className="cls-1" d="M152.43,179.78V166.16q0-.48.66-.48t.66.48v13.62a.44.44,0,0,1-.2.38.81.81,0,0,1-.46.14.79.79,0,0,1-.47-.14A.44.44,0,0,1,152.43,179.78Z"/>
            <path className="cls-1" d="M156.43,179.78v-8.46a.41.41,0,0,1,.18-.35.74.74,0,0,1,.46-.13.68.68,0,0,1,.42.14.44.44,0,0,1,.18.35v.93a2.77,2.77,0,0,1,1-1.09,2.94,2.94,0,0,1,1.64-.45,3.38,3.38,0,0,1,2.5,1.1,3.69,3.69,0,0,1,1.06,2.64v5.32a.44.44,0,0,1-.2.38.83.83,0,0,1-.46.14.77.77,0,0,1-.44-.14.42.42,0,0,1-.2-.38v-5.31a2.49,2.49,0,0,0-.74-1.75,2.34,2.34,0,0,0-1.76-.78,2.29,2.29,0,0,0-1.66.7,2.43,2.43,0,0,0-.72,1.83v5.31a.48.48,0,0,1-.2.36.72.72,0,0,1-.46.16.65.65,0,0,1-.46-.16A.48.48,0,0,1,156.43,179.78Z"/>
            <path className="cls-1" d="M166.31,177.36V166.84a.39.39,0,0,1,.2-.34.77.77,0,0,1,.44-.14.83.83,0,0,1,.46.14.42.42,0,0,1,.2.34v4h2.58a.41.41,0,0,1,.33.16.65.65,0,0,1,0,.78.41.41,0,0,1-.33.16h-2.58v5.39a1.78,1.78,0,0,0,.41,1.36,2.08,2.08,0,0,0,1.45.39H170a.58.58,0,0,1,.43.18.57.57,0,0,1,.17.42.61.61,0,0,1-.17.43.56.56,0,0,1-.43.19h-.56Q166.31,180.3,166.31,177.36Z"/>
            <path className="cls-1" d="M171.75,176.64V174.4a3.48,3.48,0,0,1,1.12-2.59,3.7,3.7,0,0,1,2.7-1.09,3.74,3.74,0,0,1,2.63,1,3.43,3.43,0,0,1,1.11,2.6,2.68,2.68,0,0,1-.13.9.84.84,0,0,1-.42.48,2.37,2.37,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0h-4.52v.74a2.32,2.32,0,0,0,.85,1.89,3.29,3.29,0,0,0,2.19.71,3.33,3.33,0,0,0,1.19-.2,3.47,3.47,0,0,0,.78-.4.84.84,0,0,1,.43-.2.51.51,0,0,1,.39.21.69.69,0,0,1,.17.41c0,.28-.29.57-.86.87a4.61,4.61,0,0,1-2.14.45,4.53,4.53,0,0,1-3.1-1.05A3.48,3.48,0,0,1,171.75,176.64Zm1.3-1.64h4a1.62,1.62,0,0,0,.8-.13.63.63,0,0,0,.2-.55,2.47,2.47,0,0,0-.71-1.74,2.34,2.34,0,0,0-1.79-.76,2.41,2.41,0,0,0-1.77.72,2.27,2.27,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M181.27,179.78v-8.46a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.72.72,0,0,1,.44.14.44.44,0,0,1,.18.35v1a3.08,3.08,0,0,1,1.06-1.13,2.83,2.83,0,0,1,1.62-.47h.7a.48.48,0,0,1,.36.18.66.66,0,0,1,0,.86.48.48,0,0,1-.36.18h-.7a2.5,2.5,0,0,0-1.84.81,2.83,2.83,0,0,0-.8,2.1v4.93a.5.5,0,0,1-.19.36.71.71,0,0,1-.47.16.64.64,0,0,1-.45-.16A.47.47,0,0,1,181.27,179.78Z"/>
            <path className="cls-1" d="M187.17,176.64v-2.18a3.68,3.68,0,0,1,1.05-2.64,3.38,3.38,0,0,1,2.53-1.1,2.92,2.92,0,0,1,2.64,1.54v-6.72a.39.39,0,0,1,.2-.34.83.83,0,0,1,.48-.14.73.73,0,0,1,.45.14.44.44,0,0,1,.17.34v14.24a.48.48,0,0,1-.18.36.63.63,0,0,1-.44.16.62.62,0,0,1-.43-.16.47.47,0,0,1-.19-.37v-.89a2.87,2.87,0,0,1-1.09,1.1,3.07,3.07,0,0,1-1.65.46,3.25,3.25,0,0,1-2.48-1.15A3.79,3.79,0,0,1,187.17,176.64Zm1.3,0a2.52,2.52,0,0,0,.74,1.79,2.3,2.3,0,0,0,1.74.8,2.38,2.38,0,0,0,1.7-.72,1.94,1.94,0,0,0,.74-1.25v-2.78a2.42,2.42,0,0,0-.73-1.83,2.38,2.38,0,0,0-3.45.08,2.43,2.43,0,0,0-.74,1.75Z"/>
            <path className="cls-1" d="M196.85,176.64V174.4a3.48,3.48,0,0,1,1.12-2.59,3.7,3.7,0,0,1,2.7-1.09,3.74,3.74,0,0,1,2.63,1,3.43,3.43,0,0,1,1.11,2.6,2.68,2.68,0,0,1-.13.9.84.84,0,0,1-.42.48,2.37,2.37,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0h-4.52v.74a2.32,2.32,0,0,0,.85,1.89,3.29,3.29,0,0,0,2.19.71,3.33,3.33,0,0,0,1.19-.2,3.47,3.47,0,0,0,.78-.4.84.84,0,0,1,.43-.2.51.51,0,0,1,.39.21.69.69,0,0,1,.17.41c0,.28-.29.57-.86.87a4.61,4.61,0,0,1-2.14.45,4.53,4.53,0,0,1-3.1-1.05A3.48,3.48,0,0,1,196.85,176.64Zm1.3-1.64h4a1.62,1.62,0,0,0,.8-.13.63.63,0,0,0,.2-.55,2.47,2.47,0,0,0-.71-1.74,2.34,2.34,0,0,0-1.79-.76,2.41,2.41,0,0,0-1.77.72,2.27,2.27,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M206.37,184.44V171.32a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.72.72,0,0,1,.44.14.44.44,0,0,1,.18.35v.93a3.09,3.09,0,0,1,1.06-1.1,3,3,0,0,1,1.62-.44,3.43,3.43,0,0,1,2.54,1.1,3.69,3.69,0,0,1,1.06,2.64v2.18a3.8,3.8,0,0,1-1.07,2.65,3.29,3.29,0,0,1-2.47,1.15,3,3,0,0,1-1.62-.46,2.77,2.77,0,0,1-1.08-1.1v5.56a.48.48,0,0,1-.19.34.71.71,0,0,1-.47.16.64.64,0,0,1-.45-.16A.45.45,0,0,1,206.37,184.44Zm1.3-7.19a1.92,1.92,0,0,0,.75,1.25,2.4,2.4,0,0,0,1.71.72,2.33,2.33,0,0,0,1.73-.8,2.53,2.53,0,0,0,.75-1.79v-2.16a2.44,2.44,0,0,0-.75-1.75,2.38,2.38,0,0,0-1.77-.78,2.34,2.34,0,0,0-1.69.7,2.39,2.39,0,0,0-.73,1.83Z"/>
            <path className="cls-1" d="M215.69,176.64V174.4a3.48,3.48,0,0,1,1.12-2.59,3.7,3.7,0,0,1,2.7-1.09,3.74,3.74,0,0,1,2.63,1,3.43,3.43,0,0,1,1.11,2.6,2.68,2.68,0,0,1-.13.9.84.84,0,0,1-.42.48,2.37,2.37,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0H217v.74a2.32,2.32,0,0,0,.85,1.89,3.29,3.29,0,0,0,2.19.71,3.33,3.33,0,0,0,1.19-.2,3.47,3.47,0,0,0,.78-.4.84.84,0,0,1,.43-.2.51.51,0,0,1,.39.21.69.69,0,0,1,.17.41c0,.28-.29.57-.86.87a4.61,4.61,0,0,1-2.14.45,4.53,4.53,0,0,1-3.1-1.05A3.48,3.48,0,0,1,215.69,176.64ZM217,175h4a1.62,1.62,0,0,0,.8-.13.63.63,0,0,0,.2-.55,2.47,2.47,0,0,0-.71-1.74,2.34,2.34,0,0,0-1.79-.76,2.41,2.41,0,0,0-1.77.72,2.27,2.27,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M225.21,179.78v-8.46a.41.41,0,0,1,.18-.35.74.74,0,0,1,.46-.13.68.68,0,0,1,.42.14.44.44,0,0,1,.18.35v.93a2.77,2.77,0,0,1,1-1.09,2.94,2.94,0,0,1,1.64-.45,3.38,3.38,0,0,1,2.5,1.1,3.69,3.69,0,0,1,1.06,2.64v5.32a.44.44,0,0,1-.2.38.83.83,0,0,1-.46.14.77.77,0,0,1-.44-.14.42.42,0,0,1-.2-.38v-5.31a2.49,2.49,0,0,0-.74-1.75,2.36,2.36,0,0,0-1.76-.78,2.29,2.29,0,0,0-1.66.7,2.43,2.43,0,0,0-.72,1.83v5.31a.48.48,0,0,1-.2.36.72.72,0,0,1-.46.16.65.65,0,0,1-.46-.16A.48.48,0,0,1,225.21,179.78Z"/>
            <path className="cls-1" d="M234.77,176.64v-2.18a3.68,3.68,0,0,1,1-2.64,3.38,3.38,0,0,1,2.53-1.1,2.92,2.92,0,0,1,2.64,1.54v-6.72a.39.39,0,0,1,.2-.34.83.83,0,0,1,.48-.14.73.73,0,0,1,.45.14.44.44,0,0,1,.17.34v14.24a.48.48,0,0,1-.18.36.63.63,0,0,1-.44.16.62.62,0,0,1-.43-.16.47.47,0,0,1-.19-.37v-.89A2.87,2.87,0,0,1,240,180a3.07,3.07,0,0,1-1.65.46,3.25,3.25,0,0,1-2.48-1.15A3.79,3.79,0,0,1,234.77,176.64Zm1.3,0a2.52,2.52,0,0,0,.74,1.79,2.3,2.3,0,0,0,1.74.8,2.38,2.38,0,0,0,1.7-.72,1.94,1.94,0,0,0,.74-1.25v-2.78a2.42,2.42,0,0,0-.73-1.83,2.38,2.38,0,0,0-3.45.08,2.43,2.43,0,0,0-.74,1.75Z"/>
            <path className="cls-1" d="M244.45,176.64V174.4a3.48,3.48,0,0,1,1.12-2.59,3.7,3.7,0,0,1,2.7-1.09,3.74,3.74,0,0,1,2.63,1,3.43,3.43,0,0,1,1.11,2.6,2.68,2.68,0,0,1-.13.9.84.84,0,0,1-.42.48,2.37,2.37,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0h-4.52v.74a2.32,2.32,0,0,0,.85,1.89,3.29,3.29,0,0,0,2.19.71,3.33,3.33,0,0,0,1.19-.2,3.47,3.47,0,0,0,.78-.4.84.84,0,0,1,.43-.2.51.51,0,0,1,.39.21.69.69,0,0,1,.17.41c0,.28-.29.57-.86.87a4.61,4.61,0,0,1-2.14.45,4.53,4.53,0,0,1-3.1-1.05A3.48,3.48,0,0,1,244.45,176.64Zm1.3-1.64h4a1.62,1.62,0,0,0,.8-.13.63.63,0,0,0,.2-.55,2.47,2.47,0,0,0-.71-1.74,2.34,2.34,0,0,0-1.79-.76,2.41,2.41,0,0,0-1.77.72,2.27,2.27,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M254,179.78v-8.46a.41.41,0,0,1,.18-.35.74.74,0,0,1,.46-.13.68.68,0,0,1,.42.14.44.44,0,0,1,.18.35v.93a2.77,2.77,0,0,1,1-1.09,2.94,2.94,0,0,1,1.64-.45,3.38,3.38,0,0,1,2.5,1.1,3.69,3.69,0,0,1,1.06,2.64v5.32a.44.44,0,0,1-.2.38.83.83,0,0,1-.46.14.77.77,0,0,1-.44-.14.42.42,0,0,1-.2-.38v-5.31a2.49,2.49,0,0,0-.74-1.75,2.36,2.36,0,0,0-1.76-.78,2.29,2.29,0,0,0-1.66.7,2.43,2.43,0,0,0-.72,1.83v5.31a.48.48,0,0,1-.2.36.72.72,0,0,1-.46.16.65.65,0,0,1-.46-.16A.48.48,0,0,1,254,179.78Z"/>
            <path className="cls-1" d="M263.55,176.64V174.5a3.81,3.81,0,0,1,1-2.73,3.74,3.74,0,0,1,2.88-1.05,4.21,4.21,0,0,1,2,.43q.81.43.81.87a.65.65,0,0,1-.18.43.53.53,0,0,1-.4.21.75.75,0,0,1-.37-.18,4.26,4.26,0,0,0-.71-.35,2.85,2.85,0,0,0-1.1-.19,2.61,2.61,0,0,0-2,.68,2.55,2.55,0,0,0-.67,1.89v2.12a2.57,2.57,0,0,0,.67,1.91,2.64,2.64,0,0,0,2,.68,3,3,0,0,0,1.15-.19,3,3,0,0,0,.72-.37.73.73,0,0,1,.39-.18.57.57,0,0,1,.43.2.67.67,0,0,1,.17.44c0,.31-.28.6-.84.89a4.51,4.51,0,0,1-2.08.43,3.51,3.51,0,0,1-3.88-3.8Z"/>
            <path className="cls-1" d="M272.11,167.79a.77.77,0,0,1-.26-.59.73.73,0,0,1,.26-.58.89.89,0,0,1,.64-.24.85.85,0,0,1,.61.24.79.79,0,0,1,.25.58.83.83,0,0,1-.25.59.82.82,0,0,1-.61.25A.86.86,0,0,1,272.11,167.79Zm0,12v-8.46a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.82.82,0,0,1,.47.14.43.43,0,0,1,.19.34v8.46a.48.48,0,0,1-.2.36.72.72,0,0,1-.46.16.64.64,0,0,1-.45-.16A.47.47,0,0,1,272.11,179.78Z"/>
            <path className="cls-1" d="M275.57,176.64V174.4a3.48,3.48,0,0,1,1.12-2.59,3.7,3.7,0,0,1,2.7-1.09,3.74,3.74,0,0,1,2.63,1,3.43,3.43,0,0,1,1.11,2.6,2.68,2.68,0,0,1-.13.9.84.84,0,0,1-.42.48,2.37,2.37,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0h-4.52v.74a2.32,2.32,0,0,0,.85,1.89,3.29,3.29,0,0,0,2.19.71,3.33,3.33,0,0,0,1.19-.2,3.47,3.47,0,0,0,.78-.4.84.84,0,0,1,.43-.2.51.51,0,0,1,.39.21.69.69,0,0,1,.17.41c0,.28-.29.57-.86.87a4.61,4.61,0,0,1-2.14.45,4.53,4.53,0,0,1-3.1-1.05A3.48,3.48,0,0,1,275.57,176.64Zm1.3-1.64h4a1.62,1.62,0,0,0,.8-.13.63.63,0,0,0,.2-.55,2.47,2.47,0,0,0-.71-1.74,2.34,2.34,0,0,0-1.79-.76,2.41,2.41,0,0,0-1.77.72,2.27,2.27,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M284.49,179a.65.65,0,0,1,.14-.4.4.4,0,0,1,.32-.2,1,1,0,0,1,.44.23,4.08,4.08,0,0,0,.86.47,3.38,3.38,0,0,0,1.34.24,2.49,2.49,0,0,0,1.56-.4,1.27,1.27,0,0,0,.5-1,1.43,1.43,0,0,0-.51-1.15,3.44,3.44,0,0,0-1.23-.65l-1.45-.44a2.72,2.72,0,0,1-1.24-.77,2.09,2.09,0,0,1-.51-1.47,2.64,2.64,0,0,1,.79-1.91,3.15,3.15,0,0,1,2.37-.81,5.25,5.25,0,0,1,1.83.31c.56.21.85.43.85.67a.88.88,0,0,1-.16.44.47.47,0,0,1-.38.24,5.24,5.24,0,0,1-.74-.28,3.58,3.58,0,0,0-1.38-.28,2.12,2.12,0,0,0-1.5.47,1.4,1.4,0,0,0-.5,1.06,1.11,1.11,0,0,0,.52,1,4.43,4.43,0,0,0,1.24.56l1.46.44a2.37,2.37,0,0,1,1.24.87,2.69,2.69,0,0,1,.5,1.68,2.32,2.32,0,0,1-.85,1.89,3.68,3.68,0,0,1-2.37.69,4.84,4.84,0,0,1-2.23-.47C284.79,179.66,284.49,179.33,284.49,179Z"/>
            <path className="cls-1" d="M296.13,177.8a2.24,2.24,0,0,1,1.19-2.19,7.76,7.76,0,0,1,3.47-.61h1v-.47a3.16,3.16,0,0,0-.58-1.88,2.08,2.08,0,0,0-1.78-.79,3.54,3.54,0,0,0-1.45.28l-.69.29a.42.42,0,0,1-.38-.22.79.79,0,0,1-.14-.47c0-.25.28-.49.86-.7a5.27,5.27,0,0,1,1.84-.32,3.3,3.3,0,0,1,2.75,1.09,4.31,4.31,0,0,1,.87,2.77v5.2a.46.46,0,0,1-.18.37.71.71,0,0,1-.46.15.61.61,0,0,1-.43-.15.45.45,0,0,1-.17-.37v-.88a3.94,3.94,0,0,1-3,1.54,2.83,2.83,0,0,1-2-.67A2.48,2.48,0,0,1,296.13,177.8Zm1.3-.12a1.55,1.55,0,0,0,.48,1.25,1.89,1.89,0,0,0,1.26.41,2.78,2.78,0,0,0,1.79-.7,1.93,1.93,0,0,0,.85-1.4V176H301a12.58,12.58,0,0,0-1.35.06,5.85,5.85,0,0,0-1.09.22,1.56,1.56,0,0,0-.82.52A1.44,1.44,0,0,0,297.43,177.68Z"/>
            <path className="cls-1" d="M305.55,179.78v-8.46a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.72.72,0,0,1,.44.14.44.44,0,0,1,.18.35v1a3.08,3.08,0,0,1,1.06-1.13,2.83,2.83,0,0,1,1.62-.47h.7a.48.48,0,0,1,.36.18.66.66,0,0,1,0,.86.48.48,0,0,1-.36.18h-.7a2.5,2.5,0,0,0-1.84.81,2.83,2.83,0,0,0-.8,2.1v4.93a.5.5,0,0,1-.19.36.71.71,0,0,1-.47.16.64.64,0,0,1-.45-.16A.47.47,0,0,1,305.55,179.78Z"/>
            <path className="cls-1" d="M311.45,176.64V174.4a3.48,3.48,0,0,1,1.12-2.59,3.7,3.7,0,0,1,2.7-1.09,3.74,3.74,0,0,1,2.63,1,3.43,3.43,0,0,1,1.11,2.6,2.68,2.68,0,0,1-.13.9.84.84,0,0,1-.42.48,2.37,2.37,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0h-4.52v.74a2.32,2.32,0,0,0,.85,1.89,3.29,3.29,0,0,0,2.19.71,3.33,3.33,0,0,0,1.19-.2,3.47,3.47,0,0,0,.78-.4.84.84,0,0,1,.43-.2.51.51,0,0,1,.39.21.69.69,0,0,1,.17.41c0,.28-.29.57-.86.87a4.61,4.61,0,0,1-2.14.45,4.53,4.53,0,0,1-3.1-1.05A3.48,3.48,0,0,1,311.45,176.64Zm1.3-1.64h4a1.62,1.62,0,0,0,.8-.13.63.63,0,0,0,.2-.55,2.47,2.47,0,0,0-.71-1.74,2.34,2.34,0,0,0-1.79-.76,2.41,2.41,0,0,0-1.77.72,2.27,2.27,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M324.93,177.36V166.84a.39.39,0,0,1,.2-.34.77.77,0,0,1,.44-.14.83.83,0,0,1,.46.14.42.42,0,0,1,.2.34v4h2.58a.41.41,0,0,1,.33.16.65.65,0,0,1,0,.78.41.41,0,0,1-.33.16h-2.58v5.39a1.78,1.78,0,0,0,.41,1.36,2.08,2.08,0,0,0,1.45.39h.56a.58.58,0,0,1,.43.18.57.57,0,0,1,.17.42.61.61,0,0,1-.17.43.56.56,0,0,1-.43.19h-.56Q324.93,180.3,324.93,177.36Z"/>
            <path className="cls-1" d="M330.91,179.78V165.54a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.82.82,0,0,1,.47.14.43.43,0,0,1,.19.34v6.72a2.82,2.82,0,0,1,1-1.09,2.87,2.87,0,0,1,1.61-.45,3.34,3.34,0,0,1,2.49,1.1,3.68,3.68,0,0,1,1.05,2.64v5.32a.46.46,0,0,1-.19.38.82.82,0,0,1-.47.14.77.77,0,0,1-.44-.14.42.42,0,0,1-.2-.38v-5.31a2.49,2.49,0,0,0-.74-1.75,2.34,2.34,0,0,0-1.74-.78,2.32,2.32,0,0,0-1.67.7,2.4,2.4,0,0,0-.73,1.83v5.31a.48.48,0,0,1-.2.36.72.72,0,0,1-.46.16.64.64,0,0,1-.45-.16A.47.47,0,0,1,330.91,179.78Z"/>
            <path className="cls-1" d="M340.47,176.64V174.4a3.48,3.48,0,0,1,1.12-2.59,3.7,3.7,0,0,1,2.7-1.09,3.74,3.74,0,0,1,2.63,1,3.43,3.43,0,0,1,1.11,2.6,2.68,2.68,0,0,1-.13.9.84.84,0,0,1-.42.48,2.37,2.37,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0h-4.52v.74a2.32,2.32,0,0,0,.85,1.89,3.29,3.29,0,0,0,2.19.71,3.33,3.33,0,0,0,1.19-.2,3.47,3.47,0,0,0,.78-.4.84.84,0,0,1,.43-.2.51.51,0,0,1,.39.21.69.69,0,0,1,.17.41c0,.28-.29.57-.86.87a4.61,4.61,0,0,1-2.14.45,4.53,4.53,0,0,1-3.1-1.05A3.48,3.48,0,0,1,340.47,176.64Zm1.3-1.64h4a1.62,1.62,0,0,0,.8-.13.63.63,0,0,0,.2-.55,2.47,2.47,0,0,0-.71-1.74,2.34,2.34,0,0,0-1.79-.76,2.41,2.41,0,0,0-1.77.72,2.27,2.27,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M354,179.78V165.52a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.82.82,0,0,1,.47.14.43.43,0,0,1,.19.34v9.74l4.88-4.34a.65.65,0,0,1,.3-.12.65.65,0,0,1,.45.19.57.57,0,0,1,.21.43.46.46,0,0,1-.14.36l-2.94,2.58,3.46,5.06a.71.71,0,0,1,.08.28.77.77,0,0,1-.7.66.46.46,0,0,1-.4-.24l-3.36-4.94-1.84,1.62v3a.5.5,0,0,1-.19.36.71.71,0,0,1-.47.16.64.64,0,0,1-.45-.16A.47.47,0,0,1,354,179.78Z"/>
            <path className="cls-1" d="M362.47,176.64V174.4a3.48,3.48,0,0,1,1.12-2.59,3.7,3.7,0,0,1,2.7-1.09,3.74,3.74,0,0,1,2.63,1,3.43,3.43,0,0,1,1.11,2.6,2.68,2.68,0,0,1-.13.9.84.84,0,0,1-.42.48,2.37,2.37,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0h-4.52v.74a2.32,2.32,0,0,0,.85,1.89,3.29,3.29,0,0,0,2.19.71,3.33,3.33,0,0,0,1.19-.2,3.47,3.47,0,0,0,.78-.4.84.84,0,0,1,.43-.2.51.51,0,0,1,.39.21.69.69,0,0,1,.17.41c0,.28-.29.57-.86.87a4.61,4.61,0,0,1-2.14.45,4.53,4.53,0,0,1-3.1-1.05A3.48,3.48,0,0,1,362.47,176.64Zm1.3-1.64h4a1.62,1.62,0,0,0,.8-.13.63.63,0,0,0,.2-.55,2.47,2.47,0,0,0-.71-1.74,2.34,2.34,0,0,0-1.79-.76,2.41,2.41,0,0,0-1.77.72,2.27,2.27,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M371,171.34a.52.52,0,0,1,.26-.43.87.87,0,0,1,.54-.19.42.42,0,0,1,.46.28l2.74,7.34,2.58-7.34a.43.43,0,0,1,.44-.28.91.91,0,0,1,.53.19.55.55,0,0,1,.27.45.28.28,0,0,1,0,.16l-3.1,8.4-.36,1-.39.94a7.9,7.9,0,0,1-.49,1l-.52.8a3.16,3.16,0,0,1-.65.72,4.35,4.35,0,0,1-.75.41,2.24,2.24,0,0,1-.9.19.53.53,0,0,1-.6-.6c0-.41.13-.62.4-.62a2.18,2.18,0,0,0,1.71-1.11,8,8,0,0,0,1.19-2.74l-3.26-8.35A.38.38,0,0,1,371,171.34Z"/>
            <path className="cls-1" d="M384.05,176.64V174.5a3.81,3.81,0,0,1,1-2.73,3.74,3.74,0,0,1,2.88-1.05,4.21,4.21,0,0,1,2,.43q.81.43.81.87a.65.65,0,0,1-.18.43.53.53,0,0,1-.4.21.75.75,0,0,1-.37-.18,4.26,4.26,0,0,0-.71-.35,2.85,2.85,0,0,0-1.1-.19,2.61,2.61,0,0,0-2,.68,2.55,2.55,0,0,0-.67,1.89v2.12a2.57,2.57,0,0,0,.67,1.91,2.64,2.64,0,0,0,2,.68,3,3,0,0,0,1.15-.19,3,3,0,0,0,.72-.37.73.73,0,0,1,.39-.18.57.57,0,0,1,.43.2.67.67,0,0,1,.17.44c0,.31-.28.6-.84.89a4.51,4.51,0,0,1-2.08.43,3.51,3.51,0,0,1-3.88-3.8Z"/>
            <path className="cls-1" d="M392.61,179.78V165.54a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.82.82,0,0,1,.47.14.43.43,0,0,1,.19.34v6.72a2.82,2.82,0,0,1,1-1.09,2.87,2.87,0,0,1,1.61-.45,3.34,3.34,0,0,1,2.49,1.1,3.68,3.68,0,0,1,1,2.64v5.32a.46.46,0,0,1-.19.38.82.82,0,0,1-.47.14.77.77,0,0,1-.44-.14.42.42,0,0,1-.2-.38v-5.31a2.49,2.49,0,0,0-.74-1.75,2.34,2.34,0,0,0-1.74-.78,2.32,2.32,0,0,0-1.67.7,2.4,2.4,0,0,0-.73,1.83v5.31a.48.48,0,0,1-.2.36.72.72,0,0,1-.46.16.64.64,0,0,1-.45-.16A.47.47,0,0,1,392.61,179.78Z"/>
            <path className="cls-1" d="M401.87,177.8a2.24,2.24,0,0,1,1.19-2.19,7.76,7.76,0,0,1,3.47-.61h1v-.47a3.16,3.16,0,0,0-.58-1.88,2.08,2.08,0,0,0-1.78-.79,3.54,3.54,0,0,0-1.45.28l-.69.29a.42.42,0,0,1-.38-.22.79.79,0,0,1-.14-.47c0-.25.28-.49.86-.7a5.27,5.27,0,0,1,1.84-.32,3.3,3.3,0,0,1,2.75,1.09,4.31,4.31,0,0,1,.87,2.77v5.2a.46.46,0,0,1-.18.37.71.71,0,0,1-.46.15.61.61,0,0,1-.43-.15.45.45,0,0,1-.17-.37v-.88a3.94,3.94,0,0,1-3,1.54,2.83,2.83,0,0,1-2-.67A2.48,2.48,0,0,1,401.87,177.8Zm1.3-.12a1.55,1.55,0,0,0,.48,1.25,1.89,1.89,0,0,0,1.26.41,2.78,2.78,0,0,0,1.79-.7,1.93,1.93,0,0,0,.85-1.4V176h-.84a12.58,12.58,0,0,0-1.35.06,5.85,5.85,0,0,0-1.09.22,1.56,1.56,0,0,0-.82.52A1.44,1.44,0,0,0,403.17,177.68Z"/>
            <path className="cls-1" d="M411.29,179.78v-8.46a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.72.72,0,0,1,.44.14.44.44,0,0,1,.18.35v1a3.08,3.08,0,0,1,1.06-1.13,2.83,2.83,0,0,1,1.62-.47h.7a.48.48,0,0,1,.36.18.66.66,0,0,1,0,.86.48.48,0,0,1-.36.18h-.7a2.5,2.5,0,0,0-1.84.81,2.83,2.83,0,0,0-.8,2.1v4.93a.5.5,0,0,1-.19.36.71.71,0,0,1-.47.16.64.64,0,0,1-.45-.16A.47.47,0,0,1,411.29,179.78Z"/>
            <path className="cls-1" d="M417.25,177.8a2.24,2.24,0,0,1,1.19-2.19,7.76,7.76,0,0,1,3.47-.61h1v-.47a3.16,3.16,0,0,0-.58-1.88,2.08,2.08,0,0,0-1.78-.79,3.54,3.54,0,0,0-1.45.28l-.69.29a.42.42,0,0,1-.38-.22.79.79,0,0,1-.14-.47c0-.25.28-.49.86-.7a5.27,5.27,0,0,1,1.84-.32,3.3,3.3,0,0,1,2.75,1.09,4.31,4.31,0,0,1,.87,2.77v5.2a.46.46,0,0,1-.18.37.71.71,0,0,1-.46.15.61.61,0,0,1-.43-.15.45.45,0,0,1-.17-.37v-.88a3.94,3.94,0,0,1-3,1.54,2.83,2.83,0,0,1-2-.67A2.48,2.48,0,0,1,417.25,177.8Zm1.3-.12a1.55,1.55,0,0,0,.48,1.25,1.89,1.89,0,0,0,1.26.41,2.78,2.78,0,0,0,1.79-.7,1.93,1.93,0,0,0,.85-1.4V176h-.84a12.58,12.58,0,0,0-1.35.06,5.85,5.85,0,0,0-1.09.22,1.56,1.56,0,0,0-.82.52A1.44,1.44,0,0,0,418.55,177.68Z"/>
            <path className="cls-1" d="M426.33,176.64V174.5a3.81,3.81,0,0,1,1-2.73,3.74,3.74,0,0,1,2.88-1.05,4.21,4.21,0,0,1,2,.43q.81.43.81.87a.65.65,0,0,1-.18.43.53.53,0,0,1-.4.21.75.75,0,0,1-.37-.18,4.26,4.26,0,0,0-.71-.35,2.85,2.85,0,0,0-1.1-.19,2.61,2.61,0,0,0-1.95.68,2.55,2.55,0,0,0-.67,1.89v2.12a2.57,2.57,0,0,0,.67,1.91,2.64,2.64,0,0,0,2,.68,3,3,0,0,0,1.15-.19,3,3,0,0,0,.72-.37.73.73,0,0,1,.39-.18.57.57,0,0,1,.43.2.67.67,0,0,1,.17.44c0,.31-.28.6-.84.89a4.51,4.51,0,0,1-2.08.43,3.51,3.51,0,0,1-3.88-3.8Z"/>
            <path className="cls-1" d="M434.85,177.36V166.84a.39.39,0,0,1,.2-.34.77.77,0,0,1,.44-.14.83.83,0,0,1,.46.14.42.42,0,0,1,.2.34v4h2.58a.41.41,0,0,1,.33.16.65.65,0,0,1,0,.78.41.41,0,0,1-.33.16h-2.58v5.39a1.78,1.78,0,0,0,.41,1.36,2.08,2.08,0,0,0,1.45.39h.56a.58.58,0,0,1,.43.18.57.57,0,0,1,.17.42.61.61,0,0,1-.17.43.56.56,0,0,1-.43.19H438Q434.85,180.3,434.85,177.36Z"/>
            <path className="cls-1" d="M440.29,176.64V174.4a3.48,3.48,0,0,1,1.12-2.59,3.7,3.7,0,0,1,2.7-1.09,3.74,3.74,0,0,1,2.63,1,3.43,3.43,0,0,1,1.11,2.6,2.68,2.68,0,0,1-.13.9.84.84,0,0,1-.42.48,2.37,2.37,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0h-4.52v.74a2.32,2.32,0,0,0,.85,1.89,3.29,3.29,0,0,0,2.19.71,3.33,3.33,0,0,0,1.19-.2,3.47,3.47,0,0,0,.78-.4.84.84,0,0,1,.43-.2.51.51,0,0,1,.39.21.69.69,0,0,1,.17.41c0,.28-.29.57-.86.87a4.61,4.61,0,0,1-2.14.45,4.53,4.53,0,0,1-3.1-1.05A3.48,3.48,0,0,1,440.29,176.64Zm1.3-1.64h4a1.62,1.62,0,0,0,.8-.13.63.63,0,0,0,.2-.55,2.47,2.47,0,0,0-.71-1.74,2.34,2.34,0,0,0-1.79-.76,2.41,2.41,0,0,0-1.77.72,2.27,2.27,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M449.81,179.78v-8.46A.42.42,0,0,1,450,171a.74.74,0,0,1,.46-.14.72.72,0,0,1,.44.14.44.44,0,0,1,.18.35v1a3.08,3.08,0,0,1,1.06-1.13,2.83,2.83,0,0,1,1.62-.47h.7a.48.48,0,0,1,.36.18.66.66,0,0,1,0,.86.48.48,0,0,1-.36.18h-.7a2.5,2.5,0,0,0-1.84.81,2.83,2.83,0,0,0-.8,2.1v4.93a.5.5,0,0,1-.19.36.71.71,0,0,1-.47.16.64.64,0,0,1-.45-.16A.47.47,0,0,1,449.81,179.78Z"/>
            <path className="cls-1" d="M456.43,167.79a.77.77,0,0,1-.26-.59.73.73,0,0,1,.26-.58.89.89,0,0,1,.64-.24.85.85,0,0,1,.61.24.79.79,0,0,1,.25.58.83.83,0,0,1-.25.59.82.82,0,0,1-.61.25A.86.86,0,0,1,456.43,167.79Zm0,12v-8.46a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.82.82,0,0,1,.47.14.43.43,0,0,1,.19.34v8.46a.48.48,0,0,1-.2.36.72.72,0,0,1-.46.16.64.64,0,0,1-.45-.16A.47.47,0,0,1,456.43,179.78Z"/>
            <path className="cls-1" d="M459.65,179a.65.65,0,0,1,.14-.4.4.4,0,0,1,.32-.2,1,1,0,0,1,.44.23,4.08,4.08,0,0,0,.86.47,3.38,3.38,0,0,0,1.34.24,2.49,2.49,0,0,0,1.56-.4,1.27,1.27,0,0,0,.5-1,1.43,1.43,0,0,0-.51-1.15,3.44,3.44,0,0,0-1.23-.65l-1.45-.44a2.72,2.72,0,0,1-1.24-.77,2.09,2.09,0,0,1-.51-1.47,2.64,2.64,0,0,1,.79-1.91,3.15,3.15,0,0,1,2.37-.81,5.25,5.25,0,0,1,1.83.31c.56.21.85.43.85.67a.88.88,0,0,1-.16.44.47.47,0,0,1-.38.24,5.24,5.24,0,0,1-.74-.28,3.58,3.58,0,0,0-1.38-.28,2.12,2.12,0,0,0-1.5.47,1.4,1.4,0,0,0-.5,1.06,1.11,1.11,0,0,0,.52,1,4.43,4.43,0,0,0,1.24.56l1.46.44a2.37,2.37,0,0,1,1.24.87,2.69,2.69,0,0,1,.5,1.68,2.32,2.32,0,0,1-.85,1.89,3.68,3.68,0,0,1-2.37.69,4.84,4.84,0,0,1-2.23-.47C460,179.66,459.65,179.33,459.65,179Z"/>
            <path className="cls-1" d="M467.91,177.36V166.84a.39.39,0,0,1,.2-.34.77.77,0,0,1,.44-.14.83.83,0,0,1,.46.14.42.42,0,0,1,.2.34v4h2.58a.41.41,0,0,1,.33.16.65.65,0,0,1,0,.78.41.41,0,0,1-.33.16h-2.58v5.39a1.78,1.78,0,0,0,.41,1.36,2.08,2.08,0,0,0,1.45.39h.56a.58.58,0,0,1,.43.18.57.57,0,0,1,.17.42.61.61,0,0,1-.17.43.56.56,0,0,1-.43.19h-.56Q467.91,180.3,467.91,177.36Z"/>
            <path className="cls-1" d="M473.89,167.79a.77.77,0,0,1-.26-.59.73.73,0,0,1,.26-.58.89.89,0,0,1,.64-.24.85.85,0,0,1,.61.24.79.79,0,0,1,.25.58.83.83,0,0,1-.25.59.82.82,0,0,1-.61.25A.86.86,0,0,1,473.89,167.79Zm0,12v-8.46a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.82.82,0,0,1,.47.14.43.43,0,0,1,.19.34v8.46a.48.48,0,0,1-.2.36.72.72,0,0,1-.46.16.64.64,0,0,1-.45-.16A.47.47,0,0,1,473.89,179.78Z"/>
            <path className="cls-1" d="M477.37,176.64V174.5a3.81,3.81,0,0,1,1-2.73,3.74,3.74,0,0,1,2.88-1.05,4.21,4.21,0,0,1,2,.43q.81.43.81.87a.65.65,0,0,1-.18.43.53.53,0,0,1-.4.21.75.75,0,0,1-.37-.18,4.26,4.26,0,0,0-.71-.35,2.85,2.85,0,0,0-1.1-.19,2.61,2.61,0,0,0-2,.68,2.55,2.55,0,0,0-.67,1.89v2.12a2.57,2.57,0,0,0,.67,1.91,2.64,2.64,0,0,0,2,.68,3,3,0,0,0,1.15-.19,3,3,0,0,0,.72-.37.73.73,0,0,1,.39-.18.57.57,0,0,1,.43.2.67.67,0,0,1,.17.44c0,.31-.28.6-.84.89a4.51,4.51,0,0,1-2.08.43,3.51,3.51,0,0,1-3.88-3.8Z"/>
            <path className="cls-1" d="M13.52,209.64v-2.18a3.57,3.57,0,0,1,1.12-2.63,3.65,3.65,0,0,1,2.66-1.11,3.74,3.74,0,0,1,2.68,1.1,3.53,3.53,0,0,1,1.14,2.64v2.18A3.63,3.63,0,0,1,20,212.29a3.66,3.66,0,0,1-2.67,1.15,3.6,3.6,0,0,1-2.64-1.15A3.66,3.66,0,0,1,13.52,209.64Zm1.3,0a2.54,2.54,0,0,0,.73,1.8,2.29,2.29,0,0,0,1.75.79,2.34,2.34,0,0,0,1.78-.79,2.55,2.55,0,0,0,.74-1.8v-2.16a2.44,2.44,0,0,0-.75-1.75,2.38,2.38,0,0,0-1.77-.78,2.32,2.32,0,0,0-1.75.78,2.46,2.46,0,0,0-.73,1.75Z"/>
            <path className="cls-1" d="M23.26,212.8V201.69a3.6,3.6,0,0,1,1.06-2.6,3.37,3.37,0,0,1,2.5-1.09h.5a.5.5,0,0,1,.4.19.6.6,0,0,1,.16.43.57.57,0,0,1-.16.42.52.52,0,0,1-.4.18H27a2.3,2.3,0,0,0-1.71.71,2.4,2.4,0,0,0-.71,1.69v2.22h2.86a.41.41,0,0,1,.33.16.56.56,0,0,1,.13.37.63.63,0,0,1-.13.41.41.41,0,0,1-.33.16H24.56v7.86a.42.42,0,0,1-.2.36.83.83,0,0,1-.48.14.72.72,0,0,1-.44-.14A.43.43,0,0,1,23.26,212.8Z"/>
            <path className="cls-1" d="M33.36,200.79a.8.8,0,0,1-.26-.59.77.77,0,0,1,.26-.58.91.91,0,0,1,.64-.24.85.85,0,0,1,.61.24.76.76,0,0,1,.25.58.79.79,0,0,1-.25.59A.82.82,0,0,1,34,201,.87.87,0,0,1,33.36,200.79Zm0,12v-8.46a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.79.79,0,0,1,.47.14.43.43,0,0,1,.19.34v8.46a.48.48,0,0,1-.2.36.7.7,0,0,1-.46.16.65.65,0,0,1-.45-.16A.47.47,0,0,1,33.36,212.78Z"/>
            <path className="cls-1" d="M37.18,212.78v-8.46a.41.41,0,0,1,.18-.35.74.74,0,0,1,.46-.13.68.68,0,0,1,.42.14.44.44,0,0,1,.18.35v.93a2.85,2.85,0,0,1,1-1.09,3,3,0,0,1,1.64-.45,3.38,3.38,0,0,1,2.5,1.1,3.65,3.65,0,0,1,1.06,2.64v5.32a.44.44,0,0,1-.2.38.81.81,0,0,1-.46.14.77.77,0,0,1-.44-.14.44.44,0,0,1-.2-.38v-5.31a2.49,2.49,0,0,0-.74-1.75,2.34,2.34,0,0,0-1.76-.78,2.29,2.29,0,0,0-1.66.7,2.43,2.43,0,0,0-.72,1.83v5.31a.48.48,0,0,1-.2.36.7.7,0,0,1-.46.16.65.65,0,0,1-.46-.16A.48.48,0,0,1,37.18,212.78Z"/>
            <path className="cls-1" d="M47.1,212.78v-8.46a.41.41,0,0,1,.18-.35.74.74,0,0,1,.46-.13.68.68,0,0,1,.42.14.44.44,0,0,1,.18.35v.93a2.85,2.85,0,0,1,1-1.09,3,3,0,0,1,1.64-.45,3.38,3.38,0,0,1,2.5,1.1,3.65,3.65,0,0,1,1.06,2.64v5.32a.44.44,0,0,1-.2.38.81.81,0,0,1-.46.14.77.77,0,0,1-.44-.14.44.44,0,0,1-.2-.38v-5.31a2.49,2.49,0,0,0-.74-1.75,2.34,2.34,0,0,0-1.76-.78,2.29,2.29,0,0,0-1.66.7,2.43,2.43,0,0,0-.72,1.83v5.31a.48.48,0,0,1-.2.36.7.7,0,0,1-.46.16.65.65,0,0,1-.46-.16A.48.48,0,0,1,47.1,212.78Z"/>
            <path className="cls-1" d="M56.64,209.64v-2.18a3.57,3.57,0,0,1,1.12-2.63,3.63,3.63,0,0,1,2.66-1.11,3.74,3.74,0,0,1,2.68,1.1,3.53,3.53,0,0,1,1.14,2.64v2.18a3.93,3.93,0,0,1-3.82,3.8,3.6,3.6,0,0,1-2.64-1.15A3.66,3.66,0,0,1,56.64,209.64Zm1.3,0a2.54,2.54,0,0,0,.73,1.8,2.29,2.29,0,0,0,1.75.79,2.36,2.36,0,0,0,1.78-.79,2.55,2.55,0,0,0,.74-1.8v-2.16a2.44,2.44,0,0,0-.75-1.75,2.38,2.38,0,0,0-1.77-.78,2.32,2.32,0,0,0-1.75.78,2.46,2.46,0,0,0-.73,1.75Z"/>
            <path className="cls-1" d="M65.42,204.4a.51.51,0,0,1,.25-.42.87.87,0,0,1,.53-.18.47.47,0,0,1,.48.3l2.64,7.2L72,204.18a.51.51,0,0,1,.46-.38,1,1,0,0,1,.52.18.49.49,0,0,1,.28.4.76.76,0,0,1-.08.32l-3.22,8.44c-.08.21-.28.32-.6.32s-.52-.11-.6-.32L65.5,204.7A.48.48,0,0,1,65.42,204.4Z"/>
            <path className="cls-1" d="M74.12,210.8a2.24,2.24,0,0,1,1.19-2.19,7.76,7.76,0,0,1,3.47-.61h1v-.47a3.09,3.09,0,0,0-.58-1.88,2.08,2.08,0,0,0-1.78-.79,3.57,3.57,0,0,0-1.45.28c-.44.19-.66.29-.69.29a.42.42,0,0,1-.38-.22.86.86,0,0,1-.14-.47c0-.25.29-.49.86-.7a5.31,5.31,0,0,1,1.84-.32,3.3,3.3,0,0,1,2.75,1.09,4.31,4.31,0,0,1,.87,2.77v5.2a.46.46,0,0,1-.18.37.69.69,0,0,1-.46.15.63.63,0,0,1-.43-.15.48.48,0,0,1-.17-.37v-.88a3.94,3.94,0,0,1-3,1.54,2.83,2.83,0,0,1-2-.67A2.48,2.48,0,0,1,74.12,210.8Zm1.3-.12a1.55,1.55,0,0,0,.48,1.25,1.89,1.89,0,0,0,1.26.41,2.76,2.76,0,0,0,1.79-.7,1.93,1.93,0,0,0,.85-1.4V209H79a12.71,12.71,0,0,0-1.35.06,5.85,5.85,0,0,0-1.09.22,1.56,1.56,0,0,0-.82.52A1.44,1.44,0,0,0,75.42,210.68Z"/>
            <path className="cls-1" d="M83.5,210.36V199.84a.39.39,0,0,1,.2-.34.77.77,0,0,1,.44-.14.83.83,0,0,1,.46.14.42.42,0,0,1,.2.34v4h2.58a.41.41,0,0,1,.33.16.65.65,0,0,1,0,.78.41.41,0,0,1-.33.16H84.8v5.39a1.78,1.78,0,0,0,.41,1.36,2.08,2.08,0,0,0,1.45.39h.56a.57.57,0,0,1,.43.18.57.57,0,0,1,.17.42.61.61,0,0,1-.17.43.54.54,0,0,1-.43.19h-.56Q83.5,213.3,83.5,210.36Z"/>
            <path className="cls-1" d="M89.48,200.79a.8.8,0,0,1-.26-.59.77.77,0,0,1,.26-.58.91.91,0,0,1,.64-.24.85.85,0,0,1,.61.24.76.76,0,0,1,.25.58.79.79,0,0,1-.25.59.82.82,0,0,1-.61.25A.87.87,0,0,1,89.48,200.79Zm0,12v-8.46a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.79.79,0,0,1,.47.14.43.43,0,0,1,.19.34v8.46a.48.48,0,0,1-.2.36.7.7,0,0,1-.46.16.65.65,0,0,1-.45-.16A.47.47,0,0,1,89.48,212.78Z"/>
            <path className="cls-1" d="M92.92,209.64v-2.18A3.57,3.57,0,0,1,94,204.83a3.65,3.65,0,0,1,2.66-1.11,3.74,3.74,0,0,1,2.68,1.1,3.53,3.53,0,0,1,1.14,2.64v2.18a3.93,3.93,0,0,1-3.82,3.8,3.6,3.6,0,0,1-2.64-1.15A3.66,3.66,0,0,1,92.92,209.64Zm1.3,0a2.54,2.54,0,0,0,.73,1.8,2.29,2.29,0,0,0,1.75.79,2.34,2.34,0,0,0,1.78-.79,2.55,2.55,0,0,0,.74-1.8v-2.16a2.44,2.44,0,0,0-.75-1.75,2.38,2.38,0,0,0-1.77-.78,2.32,2.32,0,0,0-1.75.78,2.46,2.46,0,0,0-.73,1.75Z"/>
            <path className="cls-1" d="M102.66,212.78v-8.46a.41.41,0,0,1,.18-.35.74.74,0,0,1,.46-.13.68.68,0,0,1,.42.14.44.44,0,0,1,.18.35v.93a2.85,2.85,0,0,1,1-1.09,3,3,0,0,1,1.64-.45,3.38,3.38,0,0,1,2.5,1.1,3.65,3.65,0,0,1,1.06,2.64v5.32a.44.44,0,0,1-.2.38.83.83,0,0,1-.46.14.77.77,0,0,1-.44-.14.44.44,0,0,1-.2-.38v-5.31a2.49,2.49,0,0,0-.74-1.75,2.34,2.34,0,0,0-1.76-.78,2.29,2.29,0,0,0-1.66.7,2.43,2.43,0,0,0-.72,1.83v5.31a.48.48,0,0,1-.2.36.7.7,0,0,1-.46.16.65.65,0,0,1-.46-.16A.48.48,0,0,1,102.66,212.78Z"/>
            <path className="cls-1" d="M116.22,209.64V207.4a3.48,3.48,0,0,1,1.12-2.59,3.87,3.87,0,0,1,5.33-.05,3.43,3.43,0,0,1,1.11,2.6,2.45,2.45,0,0,1-.13.9.84.84,0,0,1-.42.48,2.21,2.21,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0h-4.52v.74a2.32,2.32,0,0,0,.85,1.89,3.7,3.7,0,0,0,3.38.51,3.47,3.47,0,0,0,.78-.4.84.84,0,0,1,.43-.2.5.5,0,0,1,.39.21.69.69,0,0,1,.17.41c0,.28-.29.57-.86.87a4.61,4.61,0,0,1-2.14.45,4.53,4.53,0,0,1-3.1-1.05A3.48,3.48,0,0,1,116.22,209.64Zm1.3-1.64h4a1.62,1.62,0,0,0,.8-.13.63.63,0,0,0,.2-.55,2.47,2.47,0,0,0-.71-1.74,2.34,2.34,0,0,0-1.79-.76,2.44,2.44,0,0,0-1.77.72,2.27,2.27,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M125.4,209.64V207.5a3.81,3.81,0,0,1,1-2.73,3.74,3.74,0,0,1,2.88-1.05,4.21,4.21,0,0,1,2,.43q.81.43.81.87a.65.65,0,0,1-.18.43.52.52,0,0,1-.4.21.75.75,0,0,1-.37-.18,4.26,4.26,0,0,0-.71-.35,2.81,2.81,0,0,0-1.1-.19,2.63,2.63,0,0,0-1.95.68,2.55,2.55,0,0,0-.67,1.89v2.12a2.57,2.57,0,0,0,.67,1.91,2.66,2.66,0,0,0,2,.68,3,3,0,0,0,1.15-.19,3,3,0,0,0,.72-.37.76.76,0,0,1,.39-.18.55.55,0,0,1,.43.2.67.67,0,0,1,.17.44c0,.31-.28.6-.84.89a4.48,4.48,0,0,1-2.08.43,3.51,3.51,0,0,1-3.88-3.8Z"/>
            <path className="cls-1" d="M133.48,209.64v-2.18a3.57,3.57,0,0,1,1.12-2.63,3.63,3.63,0,0,1,2.66-1.11,3.74,3.74,0,0,1,2.68,1.1,3.53,3.53,0,0,1,1.14,2.64v2.18a3.93,3.93,0,0,1-3.82,3.8,3.6,3.6,0,0,1-2.64-1.15A3.66,3.66,0,0,1,133.48,209.64Zm1.3,0a2.54,2.54,0,0,0,.73,1.8,2.29,2.29,0,0,0,1.75.79,2.36,2.36,0,0,0,1.78-.79,2.55,2.55,0,0,0,.74-1.8v-2.16a2.44,2.44,0,0,0-.75-1.75,2.38,2.38,0,0,0-1.77-.78,2.32,2.32,0,0,0-1.75.78,2.46,2.46,0,0,0-.73,1.75Z"/>
            <path className="cls-1" d="M142.62,212a.72.72,0,0,1,.14-.4.4.4,0,0,1,.32-.2,1,1,0,0,1,.44.23,4.08,4.08,0,0,0,.86.47,3.38,3.38,0,0,0,1.34.24,2.49,2.49,0,0,0,1.56-.4,1.27,1.27,0,0,0,.5-1,1.43,1.43,0,0,0-.51-1.15,3.44,3.44,0,0,0-1.23-.65l-1.45-.44a2.72,2.72,0,0,1-1.24-.77,2.09,2.09,0,0,1-.51-1.47,2.64,2.64,0,0,1,.79-1.91,3.15,3.15,0,0,1,2.37-.81,5.25,5.25,0,0,1,1.83.31c.56.21.85.43.85.67a.79.79,0,0,1-.16.44.47.47,0,0,1-.38.24,5.24,5.24,0,0,1-.74-.28,3.58,3.58,0,0,0-1.38-.28,2.12,2.12,0,0,0-1.5.47,1.4,1.4,0,0,0-.5,1.06,1.11,1.11,0,0,0,.52,1,4.43,4.43,0,0,0,1.24.56l1.46.44a2.33,2.33,0,0,1,1.24.87,2.69,2.69,0,0,1,.5,1.68,2.32,2.32,0,0,1-.85,1.89,3.68,3.68,0,0,1-2.37.69,4.84,4.84,0,0,1-2.23-.47C142.92,212.66,142.62,212.33,142.62,212Z"/>
            <path className="cls-1" d="M149.94,204.34a.52.52,0,0,1,.26-.43.87.87,0,0,1,.54-.19.42.42,0,0,1,.46.28l2.74,7.34,2.58-7.34a.44.44,0,0,1,.44-.28.91.91,0,0,1,.53.19.55.55,0,0,1,.27.45.22.22,0,0,1,0,.16l-3.1,8.4-.36,1-.39.94a7.9,7.9,0,0,1-.49,1l-.52.8a3,3,0,0,1-.65.72,4.35,4.35,0,0,1-.75.41,2.17,2.17,0,0,1-.9.19.53.53,0,0,1-.6-.6c0-.41.13-.62.4-.62a2.18,2.18,0,0,0,1.71-1.11,8,8,0,0,0,1.19-2.74L150,204.52A.38.38,0,0,1,149.94,204.34Z"/>
            <path className="cls-1" d="M158.72,212a.72.72,0,0,1,.14-.4.4.4,0,0,1,.32-.2,1,1,0,0,1,.44.23,4.35,4.35,0,0,0,.86.47,3.38,3.38,0,0,0,1.34.24,2.47,2.47,0,0,0,1.56-.4,1.27,1.27,0,0,0,.5-1,1.43,1.43,0,0,0-.51-1.15,3.44,3.44,0,0,0-1.23-.65l-1.45-.44a2.72,2.72,0,0,1-1.24-.77,2.09,2.09,0,0,1-.51-1.47,2.64,2.64,0,0,1,.79-1.91,3.15,3.15,0,0,1,2.37-.81,5.25,5.25,0,0,1,1.83.31c.57.21.85.43.85.67a.79.79,0,0,1-.16.44.47.47,0,0,1-.38.24,5.24,5.24,0,0,1-.74-.28,3.58,3.58,0,0,0-1.38-.28,2.12,2.12,0,0,0-1.5.47,1.44,1.44,0,0,0-.5,1.06,1.11,1.11,0,0,0,.52,1,4.43,4.43,0,0,0,1.24.56l1.46.44a2.33,2.33,0,0,1,1.24.87,2.69,2.69,0,0,1,.5,1.68,2.32,2.32,0,0,1-.85,1.89,3.68,3.68,0,0,1-2.37.69,4.87,4.87,0,0,1-2.23-.47C159,212.66,158.72,212.33,158.72,212Z"/>
            <path className="cls-1" d="M167,210.36V199.84a.39.39,0,0,1,.2-.34.77.77,0,0,1,.44-.14.81.81,0,0,1,.46.14.42.42,0,0,1,.2.34v4h2.58a.41.41,0,0,1,.33.16.65.65,0,0,1,0,.78.41.41,0,0,1-.33.16h-2.58v5.39a1.83,1.83,0,0,0,.41,1.36,2.1,2.1,0,0,0,1.45.39h.56a.57.57,0,0,1,.43.18.57.57,0,0,1,.17.42.61.61,0,0,1-.17.43.54.54,0,0,1-.43.19h-.56Q167,213.3,167,210.36Z"/>
            <path className="cls-1" d="M172.42,209.64V207.4a3.48,3.48,0,0,1,1.12-2.59,3.87,3.87,0,0,1,5.33-.05,3.43,3.43,0,0,1,1.11,2.6,2.45,2.45,0,0,1-.13.9.84.84,0,0,1-.42.48,2.21,2.21,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0h-4.52v.74a2.32,2.32,0,0,0,.85,1.89,3.7,3.7,0,0,0,3.38.51,3.47,3.47,0,0,0,.78-.4.84.84,0,0,1,.43-.2.49.49,0,0,1,.39.21.69.69,0,0,1,.17.41c0,.28-.29.57-.86.87a4.58,4.58,0,0,1-2.14.45,4.53,4.53,0,0,1-3.1-1.05A3.48,3.48,0,0,1,172.42,209.64Zm1.3-1.64h4a1.59,1.59,0,0,0,.8-.13.63.63,0,0,0,.2-.55,2.47,2.47,0,0,0-.71-1.74,2.33,2.33,0,0,0-1.79-.76,2.44,2.44,0,0,0-1.77.72,2.27,2.27,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M181.94,212.78v-8.46a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.72.72,0,0,1,.44.14.44.44,0,0,1,.18.35v.93a2.91,2.91,0,0,1,1.05-1.1,3,3,0,0,1,1.61-.44,2.85,2.85,0,0,1,1.72.55,3.28,3.28,0,0,1,1.12,1.47,3.69,3.69,0,0,1,1.32-1.51,3.21,3.21,0,0,1,4.08.51,3.71,3.71,0,0,1,1,2.72v5.32a.47.47,0,0,1-.19.36.69.69,0,0,1-.47.16.67.67,0,0,1-.45-.16.47.47,0,0,1-.19-.36v-5.31a2.47,2.47,0,0,0-.69-1.85,2.21,2.21,0,0,0-1.59-.68,2.27,2.27,0,0,0-1.63.69,2.37,2.37,0,0,0-.71,1.76v5.41c0,.33-.22.5-.66.5s-.64-.17-.64-.5v-5.39a2.45,2.45,0,0,0-.67-1.76,2.15,2.15,0,0,0-1.61-.71,2.28,2.28,0,0,0-1.65.69,2.46,2.46,0,0,0-.71,1.84v5.31a.48.48,0,0,1-.2.36.7.7,0,0,1-.46.16.67.67,0,0,1-.45-.16A.47.47,0,0,1,181.94,212.78Z"/>
            <path className="cls-1" d="M196.94,212a.72.72,0,0,1,.14-.4.4.4,0,0,1,.32-.2,1,1,0,0,1,.44.23,4.35,4.35,0,0,0,.86.47,3.38,3.38,0,0,0,1.34.24,2.45,2.45,0,0,0,1.56-.4,1.27,1.27,0,0,0,.5-1,1.43,1.43,0,0,0-.51-1.15,3.44,3.44,0,0,0-1.23-.65l-1.45-.44a2.72,2.72,0,0,1-1.24-.77,2.09,2.09,0,0,1-.51-1.47,2.64,2.64,0,0,1,.79-1.91,3.18,3.18,0,0,1,2.37-.81,5.25,5.25,0,0,1,1.83.31c.57.21.85.43.85.67a.79.79,0,0,1-.16.44.46.46,0,0,1-.38.24,5.24,5.24,0,0,1-.74-.28,3.51,3.51,0,0,0-1.38-.28,2.12,2.12,0,0,0-1.5.47,1.44,1.44,0,0,0-.5,1.06,1.11,1.11,0,0,0,.52,1,4.43,4.43,0,0,0,1.24.56l1.46.44a2.33,2.33,0,0,1,1.24.87,2.69,2.69,0,0,1,.5,1.68,2.29,2.29,0,0,1-.85,1.89,3.68,3.68,0,0,1-2.37.69,4.87,4.87,0,0,1-2.23-.47C197.24,212.66,196.94,212.33,196.94,212Z"/>
            <path className="cls-1" d="M204.78,212.5a.93.93,0,0,1,.25-.67.83.83,0,0,1,.62-.27.91.91,0,0,1,.89.94,11.57,11.57,0,0,1-.76,2.24.41.41,0,0,1-.4.24.57.57,0,0,1-.33-.11.32.32,0,0,1-.15-.27,1,1,0,0,1,0-.2l.4-1A.87.87,0,0,1,204.78,212.5Z"/>
            <path className="cls-1" d="M212,210.8a2.25,2.25,0,0,1,1.19-2.19,7.76,7.76,0,0,1,3.47-.61h1v-.47a3.09,3.09,0,0,0-.58-1.88,2.08,2.08,0,0,0-1.78-.79,3.57,3.57,0,0,0-1.45.28,7.44,7.44,0,0,1-.69.29.42.42,0,0,1-.38-.22.86.86,0,0,1-.14-.47c0-.25.29-.49.86-.7a5.31,5.31,0,0,1,1.84-.32,3.3,3.3,0,0,1,2.75,1.09,4.31,4.31,0,0,1,.87,2.77v5.2a.46.46,0,0,1-.18.37.69.69,0,0,1-.46.15.63.63,0,0,1-.43-.15.48.48,0,0,1-.17-.37v-.88a3.93,3.93,0,0,1-3,1.54,2.84,2.84,0,0,1-2-.67A2.48,2.48,0,0,1,212,210.8Zm1.3-.12a1.55,1.55,0,0,0,.48,1.25,1.89,1.89,0,0,0,1.26.41,2.76,2.76,0,0,0,1.79-.7,1.9,1.9,0,0,0,.85-1.4V209h-.84a12.84,12.84,0,0,0-1.35.06,5.85,5.85,0,0,0-1.09.22,1.6,1.6,0,0,0-.82.52A1.44,1.44,0,0,0,213.3,210.68Z"/>
            <path className="cls-1" d="M221.42,212.78v-8.46a.41.41,0,0,1,.18-.35.74.74,0,0,1,.46-.13.68.68,0,0,1,.42.14.44.44,0,0,1,.18.35v.93a2.85,2.85,0,0,1,1-1.09,3,3,0,0,1,1.64-.45,3.38,3.38,0,0,1,2.5,1.1,3.65,3.65,0,0,1,1.06,2.64v5.32a.44.44,0,0,1-.2.38.81.81,0,0,1-.46.14.77.77,0,0,1-.44-.14.44.44,0,0,1-.2-.38v-5.31a2.49,2.49,0,0,0-.74-1.75,2.34,2.34,0,0,0-1.76-.78,2.29,2.29,0,0,0-1.66.7,2.43,2.43,0,0,0-.72,1.83v5.31a.48.48,0,0,1-.2.36.7.7,0,0,1-.46.16.65.65,0,0,1-.46-.16A.48.48,0,0,1,221.42,212.78Z"/>
            <path className="cls-1" d="M231,209.64v-2.18a3.68,3.68,0,0,1,1.05-2.64,3.38,3.38,0,0,1,2.53-1.1,2.92,2.92,0,0,1,2.64,1.54v-6.72a.42.42,0,0,1,.2-.34.83.83,0,0,1,.48-.14.69.69,0,0,1,.45.14.44.44,0,0,1,.17.34v14.24a.48.48,0,0,1-.18.36.63.63,0,0,1-.44.16.64.64,0,0,1-.43-.16.47.47,0,0,1-.19-.37v-.89a2.87,2.87,0,0,1-1.09,1.1,3.07,3.07,0,0,1-1.65.46,3.26,3.26,0,0,1-2.48-1.15A3.79,3.79,0,0,1,231,209.64Zm1.3,0a2.56,2.56,0,0,0,.74,1.79,2.3,2.3,0,0,0,1.74.8,2.36,2.36,0,0,0,1.7-.72,1.94,1.94,0,0,0,.74-1.25v-2.78a2.39,2.39,0,0,0-.73-1.83,2.38,2.38,0,0,0-3.45.08,2.47,2.47,0,0,0-.74,1.75Z"/>
            <path className="cls-1" d="M245,210.36V199.84a.39.39,0,0,1,.2-.34.77.77,0,0,1,.44-.14.81.81,0,0,1,.46.14.42.42,0,0,1,.2.34v4h2.58a.41.41,0,0,1,.33.16.65.65,0,0,1,0,.78.41.41,0,0,1-.33.16h-2.58v5.39a1.83,1.83,0,0,0,.41,1.36,2.1,2.1,0,0,0,1.45.39h.56a.57.57,0,0,1,.43.18.57.57,0,0,1,.17.42.61.61,0,0,1-.17.43.54.54,0,0,1-.43.19h-.56Q245,213.3,245,210.36Z"/>
            <path className="cls-1" d="M251,212.78V198.54a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.79.79,0,0,1,.47.14.4.4,0,0,1,.19.34v6.72a3,3,0,0,1,2.64-1.54,3.34,3.34,0,0,1,2.49,1.1,3.68,3.68,0,0,1,1.05,2.64v5.32a.44.44,0,0,1-.19.38.79.79,0,0,1-.47.14.77.77,0,0,1-.44-.14.44.44,0,0,1-.2-.38v-5.31a2.49,2.49,0,0,0-.74-1.75,2.34,2.34,0,0,0-3.41-.08,2.4,2.4,0,0,0-.73,1.83v5.31a.48.48,0,0,1-.2.36.7.7,0,0,1-.46.16.67.67,0,0,1-.45-.16A.47.47,0,0,1,251,212.78Z"/>
            <path className="cls-1" d="M260.52,209.64V207.4a3.48,3.48,0,0,1,1.12-2.59,3.87,3.87,0,0,1,5.33-.05,3.43,3.43,0,0,1,1.11,2.6,2.45,2.45,0,0,1-.13.9.84.84,0,0,1-.42.48,2.21,2.21,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0h-4.52v.74a2.32,2.32,0,0,0,.85,1.89,3.7,3.7,0,0,0,3.38.51,3.47,3.47,0,0,0,.78-.4.84.84,0,0,1,.43-.2.49.49,0,0,1,.39.21.69.69,0,0,1,.17.41c0,.28-.29.57-.86.87a4.58,4.58,0,0,1-2.14.45,4.53,4.53,0,0,1-3.1-1.05A3.48,3.48,0,0,1,260.52,209.64Zm1.3-1.64h4a1.59,1.59,0,0,0,.8-.13.63.63,0,0,0,.2-.55,2.47,2.47,0,0,0-.71-1.74,2.33,2.33,0,0,0-1.79-.76,2.44,2.44,0,0,0-1.77.72,2.27,2.27,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M273.08,204.38a.48.48,0,0,1,.25-.41,1,1,0,0,1,.53-.17.43.43,0,0,1,.46.3l2.56,7.12,2.06-6.5a.41.41,0,0,1,.44-.29.41.41,0,0,1,.44.34l2,6.58,2.63-7.25c0-.2.18-.3.44-.3a.91.91,0,0,1,.52.16.48.48,0,0,1,.25.42.56.56,0,0,1-.07.3l-3.12,8.46a.48.48,0,0,1-.52.32.41.41,0,0,1-.41-.29l-2.09-6.52-2.05,6.4a.52.52,0,0,1-.55.41.51.51,0,0,1-.54-.4l-3.12-8.38A.75.75,0,0,1,273.08,204.38Z"/>
            <path className="cls-1" d="M286.72,210.8a2.25,2.25,0,0,1,1.19-2.19,7.76,7.76,0,0,1,3.47-.61h1v-.47a3.09,3.09,0,0,0-.58-1.88,2.08,2.08,0,0,0-1.78-.79,3.57,3.57,0,0,0-1.45.28,7.44,7.44,0,0,1-.69.29.42.42,0,0,1-.38-.22.86.86,0,0,1-.14-.47c0-.25.29-.49.86-.7a5.31,5.31,0,0,1,1.84-.32,3.3,3.3,0,0,1,2.75,1.09,4.31,4.31,0,0,1,.87,2.77v5.2a.46.46,0,0,1-.18.37.69.69,0,0,1-.46.15.63.63,0,0,1-.43-.15.48.48,0,0,1-.17-.37v-.88a3.93,3.93,0,0,1-3,1.54,2.84,2.84,0,0,1-2-.67A2.48,2.48,0,0,1,286.72,210.8Zm1.3-.12a1.55,1.55,0,0,0,.48,1.25,1.89,1.89,0,0,0,1.26.41,2.76,2.76,0,0,0,1.79-.7,1.9,1.9,0,0,0,.85-1.4V209h-.84a12.84,12.84,0,0,0-1.35.06,5.85,5.85,0,0,0-1.09.22,1.6,1.6,0,0,0-.82.52A1.44,1.44,0,0,0,288,210.68Z"/>
            <path className="cls-1" d="M295,204.34a.52.52,0,0,1,.26-.43.87.87,0,0,1,.54-.19.42.42,0,0,1,.46.28l2.74,7.34,2.58-7.34a.44.44,0,0,1,.44-.28.91.91,0,0,1,.53.19.55.55,0,0,1,.27.45.22.22,0,0,1,0,.16l-3.1,8.4-.36,1-.39.94a6.69,6.69,0,0,1-.49,1l-.52.8a3,3,0,0,1-.65.72,4.35,4.35,0,0,1-.75.41,2.17,2.17,0,0,1-.9.19.53.53,0,0,1-.6-.6c0-.41.13-.62.4-.62a2.18,2.18,0,0,0,1.71-1.11,7.84,7.84,0,0,0,1.19-2.74l-3.26-8.35A.38.38,0,0,1,295,204.34Z"/>
            <path className="cls-1" d="M308.34,210.36V199.84a.42.42,0,0,1,.2-.34.77.77,0,0,1,.44-.14.81.81,0,0,1,.46.14.42.42,0,0,1,.2.34v4h2.58a.41.41,0,0,1,.33.16.65.65,0,0,1,0,.78.41.41,0,0,1-.33.16h-2.58v5.39a1.83,1.83,0,0,0,.41,1.36,2.1,2.1,0,0,0,1.45.39h.56a.57.57,0,0,1,.43.18.57.57,0,0,1,.17.42.61.61,0,0,1-.17.43.54.54,0,0,1-.43.19h-.56Q308.33,213.3,308.34,210.36Z"/>
            <path className="cls-1" d="M313.76,209.64v-2.18a3.57,3.57,0,0,1,1.12-2.63,3.65,3.65,0,0,1,2.66-1.11,3.74,3.74,0,0,1,2.68,1.1,3.53,3.53,0,0,1,1.14,2.64v2.18a3.93,3.93,0,0,1-3.82,3.8,3.6,3.6,0,0,1-2.64-1.15A3.66,3.66,0,0,1,313.76,209.64Zm1.3,0a2.54,2.54,0,0,0,.73,1.8,2.29,2.29,0,0,0,1.75.79,2.34,2.34,0,0,0,1.78-.79,2.55,2.55,0,0,0,.74-1.8v-2.16a2.44,2.44,0,0,0-.75-1.75,2.38,2.38,0,0,0-1.77-.78,2.32,2.32,0,0,0-1.75.78,2.46,2.46,0,0,0-.73,1.75Z"/>
            <path className="cls-1" d="M327.4,209.68v-5.34a.45.45,0,0,1,.19-.34.67.67,0,0,1,.47-.16.66.66,0,0,1,.44.16.46.46,0,0,1,.2.34v5.33a2.52,2.52,0,0,0,.74,1.77,2.38,2.38,0,0,0,3.52,0,2.52,2.52,0,0,0,.74-1.77v-5.35a.39.39,0,0,1,.2-.34.78.78,0,0,1,.46-.14.77.77,0,0,1,.45.14.43.43,0,0,1,.19.34v5.36a3.61,3.61,0,0,1-1.13,2.63,3.73,3.73,0,0,1-5.35,0A3.6,3.6,0,0,1,327.4,209.68Z"/>
            <path className="cls-1" d="M337.42,212.78v-8.46a.41.41,0,0,1,.18-.35.74.74,0,0,1,.46-.13.68.68,0,0,1,.42.14.44.44,0,0,1,.18.35v.93a2.77,2.77,0,0,1,1-1.09,3,3,0,0,1,1.64-.45,3.38,3.38,0,0,1,2.5,1.1,3.69,3.69,0,0,1,1.06,2.64v5.32a.44.44,0,0,1-.2.38.83.83,0,0,1-.46.14.77.77,0,0,1-.44-.14.44.44,0,0,1-.2-.38v-5.31a2.49,2.49,0,0,0-.74-1.75,2.34,2.34,0,0,0-1.76-.78,2.29,2.29,0,0,0-1.66.7,2.43,2.43,0,0,0-.72,1.83v5.31a.48.48,0,0,1-.2.36.72.72,0,0,1-.46.16.65.65,0,0,1-.46-.16A.48.48,0,0,1,337.42,212.78Z"/>
            <path className="cls-1" d="M347.5,212.78V198.52a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.82.82,0,0,1,.47.14.43.43,0,0,1,.19.34v14.26a.5.5,0,0,1-.19.36.71.71,0,0,1-.47.16.64.64,0,0,1-.45-.16A.47.47,0,0,1,347.5,212.78Z"/>
            <path className="cls-1" d="M351.1,209.64v-2.18a3.57,3.57,0,0,1,1.12-2.63,3.63,3.63,0,0,1,2.66-1.11,3.76,3.76,0,0,1,2.68,1.1,3.53,3.53,0,0,1,1.14,2.64v2.18a3.93,3.93,0,0,1-3.82,3.8,3.6,3.6,0,0,1-2.64-1.15A3.66,3.66,0,0,1,351.1,209.64Zm1.3,0a2.54,2.54,0,0,0,.73,1.8,2.28,2.28,0,0,0,1.75.79,2.36,2.36,0,0,0,1.78-.79,2.55,2.55,0,0,0,.74-1.8v-2.16a2.44,2.44,0,0,0-.75-1.75,2.38,2.38,0,0,0-1.77-.78,2.3,2.3,0,0,0-1.75.78,2.46,2.46,0,0,0-.73,1.75Z"/>
            <path className="cls-1" d="M360.5,209.64V207.5a3.81,3.81,0,0,1,1-2.73,3.74,3.74,0,0,1,2.88-1.05,4.21,4.21,0,0,1,2,.43q.81.43.81.87a.65.65,0,0,1-.18.43.53.53,0,0,1-.4.21.75.75,0,0,1-.37-.18,4.26,4.26,0,0,0-.71-.35,2.85,2.85,0,0,0-1.1-.19,2.61,2.61,0,0,0-1.95.68,2.55,2.55,0,0,0-.67,1.89v2.12a2.57,2.57,0,0,0,.67,1.91,2.64,2.64,0,0,0,2,.68,3,3,0,0,0,1.15-.19,3,3,0,0,0,.72-.37.73.73,0,0,1,.39-.18.57.57,0,0,1,.43.2.67.67,0,0,1,.17.44c0,.31-.28.6-.84.89a4.51,4.51,0,0,1-2.08.43,3.51,3.51,0,0,1-3.88-3.8Z"/>
            <path className="cls-1" d="M369.06,212.78V198.52a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.82.82,0,0,1,.47.14.43.43,0,0,1,.19.34v9.74l4.88-4.34a.65.65,0,0,1,.3-.12.65.65,0,0,1,.45.19.57.57,0,0,1,.21.43.46.46,0,0,1-.14.36l-2.94,2.58,3.46,5.06a.71.71,0,0,1,.08.28.77.77,0,0,1-.7.66.46.46,0,0,1-.4-.24l-3.36-4.94-1.84,1.62v3a.5.5,0,0,1-.19.36.71.71,0,0,1-.47.16.64.64,0,0,1-.45-.16A.47.47,0,0,1,369.06,212.78Z"/>
            <path className="cls-1" d="M382.14,210.36V199.84a.39.39,0,0,1,.2-.34.77.77,0,0,1,.44-.14.83.83,0,0,1,.46.14.42.42,0,0,1,.2.34v4H386a.41.41,0,0,1,.33.16.65.65,0,0,1,0,.78.41.41,0,0,1-.33.16h-2.58v5.39a1.78,1.78,0,0,0,.41,1.36,2.08,2.08,0,0,0,1.45.39h.56a.58.58,0,0,1,.43.18.57.57,0,0,1,.17.42.61.61,0,0,1-.17.43.56.56,0,0,1-.43.19h-.56Q382.14,213.3,382.14,210.36Z"/>
            <path className="cls-1" d="M388.12,212.78V198.54a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.82.82,0,0,1,.47.14.43.43,0,0,1,.19.34v6.72a2.82,2.82,0,0,1,1-1.09,2.87,2.87,0,0,1,1.61-.45,3.34,3.34,0,0,1,2.49,1.1,3.68,3.68,0,0,1,1.05,2.64v5.32a.46.46,0,0,1-.19.38.82.82,0,0,1-.47.14.77.77,0,0,1-.44-.14.42.42,0,0,1-.2-.38v-5.31a2.49,2.49,0,0,0-.74-1.75,2.34,2.34,0,0,0-1.74-.78,2.32,2.32,0,0,0-1.67.7,2.4,2.4,0,0,0-.73,1.83v5.31a.48.48,0,0,1-.2.36.72.72,0,0,1-.46.16.64.64,0,0,1-.45-.16A.47.47,0,0,1,388.12,212.78Z"/>
            <path className="cls-1" d="M397.68,209.64V207.4a3.48,3.48,0,0,1,1.12-2.59,3.7,3.7,0,0,1,2.7-1.09,3.74,3.74,0,0,1,2.63,1,3.43,3.43,0,0,1,1.11,2.6,2.68,2.68,0,0,1-.13.9.84.84,0,0,1-.42.48,2.37,2.37,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0H399v.74a2.32,2.32,0,0,0,.85,1.89,3.29,3.29,0,0,0,2.19.71,3.33,3.33,0,0,0,1.19-.2,3.47,3.47,0,0,0,.78-.4.84.84,0,0,1,.43-.2.51.51,0,0,1,.39.21.69.69,0,0,1,.17.41c0,.28-.29.57-.86.87a4.61,4.61,0,0,1-2.14.45,4.53,4.53,0,0,1-3.1-1.05A3.48,3.48,0,0,1,397.68,209.64ZM399,208h4a1.62,1.62,0,0,0,.8-.13.63.63,0,0,0,.2-.55,2.47,2.47,0,0,0-.71-1.74,2.34,2.34,0,0,0-1.79-.76,2.41,2.41,0,0,0-1.77.72,2.27,2.27,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M410.84,209.64v-2.18a3.68,3.68,0,0,1,1.05-2.64,3.38,3.38,0,0,1,2.53-1.1,2.92,2.92,0,0,1,2.64,1.54v-6.72a.39.39,0,0,1,.2-.34.83.83,0,0,1,.48-.14.73.73,0,0,1,.45.14.44.44,0,0,1,.17.34v14.24a.48.48,0,0,1-.18.36.63.63,0,0,1-.44.16.62.62,0,0,1-.43-.16.47.47,0,0,1-.19-.37v-.89A2.87,2.87,0,0,1,416,213a3.07,3.07,0,0,1-1.65.46,3.25,3.25,0,0,1-2.48-1.15A3.79,3.79,0,0,1,410.84,209.64Zm1.3,0a2.52,2.52,0,0,0,.74,1.79,2.3,2.3,0,0,0,1.74.8,2.38,2.38,0,0,0,1.7-.72,1.94,1.94,0,0,0,.74-1.25v-2.78a2.42,2.42,0,0,0-.73-1.83,2.38,2.38,0,0,0-3.45.08,2.43,2.43,0,0,0-.74,1.75Z"/>
            <path className="cls-1" d="M420.88,212.78v-8.46a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.72.72,0,0,1,.44.14.44.44,0,0,1,.18.35v1a3.08,3.08,0,0,1,1.06-1.13,2.83,2.83,0,0,1,1.62-.47h.7a.48.48,0,0,1,.36.18.66.66,0,0,1,0,.86.48.48,0,0,1-.36.18h-.7a2.5,2.5,0,0,0-1.84.81,2.83,2.83,0,0,0-.8,2.1v4.93a.5.5,0,0,1-.19.36.71.71,0,0,1-.47.16.64.64,0,0,1-.45-.16A.47.47,0,0,1,420.88,212.78Z"/>
            <path className="cls-1" d="M427.5,200.79a.77.77,0,0,1-.26-.59.73.73,0,0,1,.26-.58.89.89,0,0,1,.64-.24.85.85,0,0,1,.61.24.79.79,0,0,1,.25.58.83.83,0,0,1-.25.59.82.82,0,0,1-.61.25A.86.86,0,0,1,427.5,200.79Zm0,12v-8.46a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.82.82,0,0,1,.47.14.43.43,0,0,1,.19.34v8.46a.48.48,0,0,1-.2.36.72.72,0,0,1-.46.16.64.64,0,0,1-.45-.16A.47.47,0,0,1,427.5,212.78Z"/>
            <path className="cls-1" d="M430.36,204.4a.51.51,0,0,1,.25-.42.87.87,0,0,1,.53-.18.47.47,0,0,1,.48.3l2.64,7.2,2.64-7.12a.51.51,0,0,1,.46-.38,1,1,0,0,1,.52.18.51.51,0,0,1,.28.4.76.76,0,0,1-.08.32l-3.22,8.44c-.08.21-.28.32-.6.32s-.52-.11-.6-.32l-3.22-8.44A.48.48,0,0,1,430.36,204.4Z"/>
            <path className="cls-1" d="M439.36,209.64V207.4a3.48,3.48,0,0,1,1.12-2.59,3.87,3.87,0,0,1,5.33-.05,3.43,3.43,0,0,1,1.11,2.6,2.45,2.45,0,0,1-.13.9.84.84,0,0,1-.42.48,2.37,2.37,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0h-4.52v.74a2.32,2.32,0,0,0,.85,1.89,3.29,3.29,0,0,0,2.19.71,3.33,3.33,0,0,0,1.19-.2,3.47,3.47,0,0,0,.78-.4.84.84,0,0,1,.43-.2.5.5,0,0,1,.39.21.69.69,0,0,1,.17.41c0,.28-.29.57-.86.87a4.61,4.61,0,0,1-2.14.45,4.53,4.53,0,0,1-3.1-1.05A3.48,3.48,0,0,1,439.36,209.64Zm1.3-1.64h4a1.62,1.62,0,0,0,.8-.13.63.63,0,0,0,.2-.55,2.47,2.47,0,0,0-.71-1.74,2.34,2.34,0,0,0-1.79-.76,2.42,2.42,0,0,0-1.77.72,2.27,2.27,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M452.88,212.8V201.69a3.6,3.6,0,0,1,1.06-2.6,3.35,3.35,0,0,1,2.5-1.09h.5a.5.5,0,0,1,.4.19.65.65,0,0,1,.16.43.61.61,0,0,1-.16.42.52.52,0,0,1-.4.18h-.34a2.3,2.3,0,0,0-1.71.71,2.4,2.4,0,0,0-.71,1.69v2.22H457a.41.41,0,0,1,.33.16.62.62,0,0,1,.13.37.69.69,0,0,1-.13.41.41.41,0,0,1-.33.16h-2.86v7.86a.42.42,0,0,1-.2.36.87.87,0,0,1-.48.14.72.72,0,0,1-.44-.14A.43.43,0,0,1,452.88,212.8Z"/>
            <path className="cls-1" d="M458.26,209.64v-2.18a3.57,3.57,0,0,1,1.12-2.63,3.63,3.63,0,0,1,2.66-1.11,3.76,3.76,0,0,1,2.68,1.1,3.53,3.53,0,0,1,1.14,2.64v2.18a3.93,3.93,0,0,1-3.82,3.8,3.6,3.6,0,0,1-2.64-1.15A3.66,3.66,0,0,1,458.26,209.64Zm1.3,0a2.54,2.54,0,0,0,.73,1.8,2.28,2.28,0,0,0,1.75.79,2.36,2.36,0,0,0,1.78-.79,2.55,2.55,0,0,0,.74-1.8v-2.16a2.44,2.44,0,0,0-.75-1.75,2.38,2.38,0,0,0-1.77-.78,2.3,2.3,0,0,0-1.75.78,2.46,2.46,0,0,0-.73,1.75Z"/>
            <path className="cls-1" d="M468,212.78v-8.46a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.72.72,0,0,1,.44.14.44.44,0,0,1,.18.35v1a3.08,3.08,0,0,1,1.06-1.13,2.83,2.83,0,0,1,1.62-.47h.7a.48.48,0,0,1,.36.18.66.66,0,0,1,0,.86.48.48,0,0,1-.36.18h-.7a2.5,2.5,0,0,0-1.84.81,2.83,2.83,0,0,0-.8,2.1v4.93a.5.5,0,0,1-.19.36.71.71,0,0,1-.47.16.64.64,0,0,1-.45-.16A.47.47,0,0,1,468,212.78Z"/>
            <path className="cls-1" d="M21.42,242.64V240.5a3.81,3.81,0,0,1,1-2.73,3.74,3.74,0,0,1,2.88-1.05,4.21,4.21,0,0,1,2,.43q.81.43.81.87a.65.65,0,0,1-.18.43.52.52,0,0,1-.4.21.75.75,0,0,1-.37-.18,4.26,4.26,0,0,0-.71-.35,2.85,2.85,0,0,0-1.1-.19,2.61,2.61,0,0,0-1.95.68,2.55,2.55,0,0,0-.67,1.89v2.12a2.57,2.57,0,0,0,.67,1.91,2.64,2.64,0,0,0,2,.68,3.27,3.27,0,0,0,1.15-.18,3.92,3.92,0,0,0,.72-.37.69.69,0,0,1,.39-.19.57.57,0,0,1,.43.2.67.67,0,0,1,.17.44c0,.31-.28.6-.84.89a4.48,4.48,0,0,1-2.08.43,3.51,3.51,0,0,1-3.88-3.8Z"/>
            <path className="cls-1" d="M29.5,242.64v-2.18a3.57,3.57,0,0,1,1.12-2.63,3.63,3.63,0,0,1,2.66-1.11,3.76,3.76,0,0,1,2.68,1.1,3.53,3.53,0,0,1,1.14,2.64v2.18a3.93,3.93,0,0,1-3.82,3.8,3.6,3.6,0,0,1-2.64-1.15A3.66,3.66,0,0,1,29.5,242.64Zm1.3,0a2.54,2.54,0,0,0,.73,1.8,2.28,2.28,0,0,0,1.75.79,2.36,2.36,0,0,0,1.78-.79,2.55,2.55,0,0,0,.74-1.8v-2.16a2.44,2.44,0,0,0-.75-1.75,2.38,2.38,0,0,0-1.77-.78,2.3,2.3,0,0,0-1.75.78,2.46,2.46,0,0,0-.73,1.75Z"/>
            <path className="cls-1" d="M39.24,245.78v-8.46a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.72.72,0,0,1,.44.14.44.44,0,0,1,.18.35v.93a2.91,2.91,0,0,1,1-1.1,2.94,2.94,0,0,1,1.61-.44,2.85,2.85,0,0,1,1.72.55A3.35,3.35,0,0,1,46,238.74a3.69,3.69,0,0,1,1.32-1.51,3.21,3.21,0,0,1,4.08.51,3.75,3.75,0,0,1,1,2.72v5.32a.5.5,0,0,1-.19.36.71.71,0,0,1-.47.16.64.64,0,0,1-.45-.16.47.47,0,0,1-.19-.36v-5.31a2.47,2.47,0,0,0-.69-1.85,2.21,2.21,0,0,0-1.59-.68,2.26,2.26,0,0,0-1.63.69,2.33,2.33,0,0,0-.71,1.76v5.41c0,.33-.22.5-.66.5s-.64-.17-.64-.5v-5.39a2.49,2.49,0,0,0-.67-1.76,2.16,2.16,0,0,0-1.61-.71,2.28,2.28,0,0,0-1.65.69,2.42,2.42,0,0,0-.71,1.84v5.31a.48.48,0,0,1-.2.36.72.72,0,0,1-.46.16.64.64,0,0,1-.45-.16A.47.47,0,0,1,39.24,245.78Z"/>
            <path className="cls-1" d="M54.84,245.78v-8.46A.42.42,0,0,1,55,237a.74.74,0,0,1,.46-.14.72.72,0,0,1,.44.14.44.44,0,0,1,.18.35v.93a2.91,2.91,0,0,1,1-1.1,2.94,2.94,0,0,1,1.61-.44,2.85,2.85,0,0,1,1.72.55,3.35,3.35,0,0,1,1.12,1.47,3.69,3.69,0,0,1,1.32-1.51,3.21,3.21,0,0,1,4.08.51,3.75,3.75,0,0,1,1,2.72v5.32a.5.5,0,0,1-.19.36.71.71,0,0,1-.47.16.64.64,0,0,1-.45-.16.47.47,0,0,1-.19-.36v-5.31a2.47,2.47,0,0,0-.69-1.85,2.21,2.21,0,0,0-1.59-.68,2.26,2.26,0,0,0-1.63.69,2.33,2.33,0,0,0-.71,1.76v5.41c0,.33-.22.5-.66.5s-.64-.17-.64-.5v-5.39a2.49,2.49,0,0,0-.67-1.76,2.16,2.16,0,0,0-1.61-.71,2.28,2.28,0,0,0-1.65.69,2.42,2.42,0,0,0-.71,1.84v5.31a.48.48,0,0,1-.2.36.72.72,0,0,1-.46.16.64.64,0,0,1-.45-.16A.47.47,0,0,1,54.84,245.78Z"/>
            <path className="cls-1" d="M70.34,242.68v-5.34a.45.45,0,0,1,.19-.34.67.67,0,0,1,.47-.16.68.68,0,0,1,.44.16.46.46,0,0,1,.2.34v5.33a2.48,2.48,0,0,0,.74,1.77,2.38,2.38,0,0,0,3.52,0,2.52,2.52,0,0,0,.74-1.77v-5.35a.39.39,0,0,1,.2-.34.78.78,0,0,1,.46-.14.77.77,0,0,1,.45.14.43.43,0,0,1,.19.34v5.36a3.61,3.61,0,0,1-1.13,2.63,3.69,3.69,0,0,1-2.67,1.13,3.64,3.64,0,0,1-2.68-1.13A3.6,3.6,0,0,1,70.34,242.68Z"/>
            <path className="cls-1" d="M80.36,245.78v-8.46a.41.41,0,0,1,.18-.35.74.74,0,0,1,.46-.13.68.68,0,0,1,.42.14.44.44,0,0,1,.18.35v.93a2.77,2.77,0,0,1,1-1.09,2.94,2.94,0,0,1,1.64-.45,3.38,3.38,0,0,1,2.5,1.1,3.69,3.69,0,0,1,1.06,2.64v5.32a.44.44,0,0,1-.2.38.83.83,0,0,1-.46.14.77.77,0,0,1-.44-.14.42.42,0,0,1-.2-.38v-5.31a2.49,2.49,0,0,0-.74-1.75,2.36,2.36,0,0,0-1.76-.78,2.29,2.29,0,0,0-1.66.7,2.43,2.43,0,0,0-.72,1.83v5.31a.48.48,0,0,1-.2.36.72.72,0,0,1-.46.16.65.65,0,0,1-.46-.16A.48.48,0,0,1,80.36,245.78Z"/>
            <path className="cls-1" d="M90.28,233.79a.77.77,0,0,1-.26-.59.73.73,0,0,1,.26-.58.89.89,0,0,1,.64-.24.85.85,0,0,1,.61.24.79.79,0,0,1,.25.58.83.83,0,0,1-.25.59.82.82,0,0,1-.61.25A.86.86,0,0,1,90.28,233.79Zm0,12v-8.46a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.82.82,0,0,1,.47.14.43.43,0,0,1,.19.34v8.46a.48.48,0,0,1-.2.36.72.72,0,0,1-.46.16.64.64,0,0,1-.45-.16A.47.47,0,0,1,90.28,245.78Z"/>
            <path className="cls-1" d="M94.06,243.36V232.84a.39.39,0,0,1,.2-.34.77.77,0,0,1,.44-.14.83.83,0,0,1,.46.14.42.42,0,0,1,.2.34v4h2.58a.41.41,0,0,1,.33.16.65.65,0,0,1,0,.78.41.41,0,0,1-.33.16H95.36v5.39a1.78,1.78,0,0,0,.41,1.36,2.08,2.08,0,0,0,1.45.39h.56a.58.58,0,0,1,.43.18.57.57,0,0,1,.17.42.61.61,0,0,1-.17.43.56.56,0,0,1-.43.19h-.56Q94.06,246.3,94.06,243.36Z"/>
            <path className="cls-1" d="M100,233.79a.77.77,0,0,1-.26-.59.73.73,0,0,1,.26-.58.89.89,0,0,1,.64-.24.85.85,0,0,1,.61.24.79.79,0,0,1,.25.58.83.83,0,0,1-.25.59.82.82,0,0,1-.61.25A.86.86,0,0,1,100,233.79Zm0,12v-8.46a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.82.82,0,0,1,.47.14.43.43,0,0,1,.19.34v8.46a.48.48,0,0,1-.2.36.72.72,0,0,1-.46.16.64.64,0,0,1-.45-.16A.47.47,0,0,1,100,245.78Z"/>
            <path className="cls-1" d="M103.5,242.64V240.4a3.48,3.48,0,0,1,1.12-2.59,3.7,3.7,0,0,1,2.7-1.09,3.74,3.74,0,0,1,2.63,1,3.43,3.43,0,0,1,1.11,2.6,2.68,2.68,0,0,1-.13.9.84.84,0,0,1-.42.48,2.37,2.37,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0H104.8v.74a2.32,2.32,0,0,0,.85,1.89,3.29,3.29,0,0,0,2.19.71,3.33,3.33,0,0,0,1.19-.2,3.47,3.47,0,0,0,.78-.4.84.84,0,0,1,.43-.2.51.51,0,0,1,.39.21.69.69,0,0,1,.17.41c0,.28-.29.57-.86.87a4.61,4.61,0,0,1-2.14.45,4.53,4.53,0,0,1-3.1-1.05A3.48,3.48,0,0,1,103.5,242.64Zm1.3-1.64h4a1.62,1.62,0,0,0,.8-.13c.13-.08.2-.27.2-.55a2.47,2.47,0,0,0-.71-1.74,2.34,2.34,0,0,0-1.79-.76,2.41,2.41,0,0,0-1.77.72,2.27,2.27,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M112.42,245a.65.65,0,0,1,.14-.4.4.4,0,0,1,.32-.2,1,1,0,0,1,.44.23,4.08,4.08,0,0,0,.86.47,3.38,3.38,0,0,0,1.34.24,2.49,2.49,0,0,0,1.56-.4,1.27,1.27,0,0,0,.5-1,1.43,1.43,0,0,0-.51-1.15,3.44,3.44,0,0,0-1.23-.65l-1.45-.44a2.72,2.72,0,0,1-1.24-.77,2.09,2.09,0,0,1-.51-1.47,2.64,2.64,0,0,1,.79-1.91,3.15,3.15,0,0,1,2.37-.81,5.25,5.25,0,0,1,1.83.31c.56.21.85.43.85.67a.88.88,0,0,1-.16.44.47.47,0,0,1-.38.24,5.24,5.24,0,0,1-.74-.28,3.58,3.58,0,0,0-1.38-.28,2.12,2.12,0,0,0-1.5.47,1.4,1.4,0,0,0-.5,1.06,1.11,1.11,0,0,0,.52,1,4.75,4.75,0,0,0,1.24.57l1.46.43a2.33,2.33,0,0,1,1.24.87,2.69,2.69,0,0,1,.5,1.68,2.32,2.32,0,0,1-.85,1.89,3.68,3.68,0,0,1-2.37.69,4.84,4.84,0,0,1-2.23-.47C112.72,245.66,112.42,245.33,112.42,245Z"/>
            <path className="cls-1" d="M124.68,243.36V232.84a.39.39,0,0,1,.2-.34.77.77,0,0,1,.44-.14.83.83,0,0,1,.46.14.42.42,0,0,1,.2.34v4h2.58a.41.41,0,0,1,.33.16.65.65,0,0,1,0,.78.41.41,0,0,1-.33.16H126v5.39a1.78,1.78,0,0,0,.41,1.36,2.08,2.08,0,0,0,1.45.39h.56a.58.58,0,0,1,.43.18.57.57,0,0,1,.17.42.61.61,0,0,1-.17.43.56.56,0,0,1-.43.19h-.56Q124.68,246.3,124.68,243.36Z"/>
            <path className="cls-1" d="M130.1,242.64v-2.18a3.57,3.57,0,0,1,1.12-2.63,3.63,3.63,0,0,1,2.66-1.11,3.74,3.74,0,0,1,2.68,1.1,3.53,3.53,0,0,1,1.14,2.64v2.18a3.93,3.93,0,0,1-3.82,3.8,3.6,3.6,0,0,1-2.64-1.15A3.66,3.66,0,0,1,130.1,242.64Zm1.3,0a2.54,2.54,0,0,0,.73,1.8,2.29,2.29,0,0,0,1.75.79,2.34,2.34,0,0,0,1.78-.79,2.55,2.55,0,0,0,.74-1.8v-2.16a2.44,2.44,0,0,0-.75-1.75,2.38,2.38,0,0,0-1.77-.78,2.32,2.32,0,0,0-1.75.78,2.46,2.46,0,0,0-.73,1.75Z"/>
            <path className="cls-1" d="M142.88,237.38a.48.48,0,0,1,.25-.41.93.93,0,0,1,.53-.17.43.43,0,0,1,.46.3l2.56,7.12,2.06-6.5a.46.46,0,0,1,.87.05l2,6.58,2.62-7.25c0-.2.19-.3.44-.3a.92.92,0,0,1,.53.16.52.52,0,0,1,.17.72l-3.12,8.46a.47.47,0,0,1-.52.32.4.4,0,0,1-.4-.29l-2.09-6.52-2.05,6.4a.52.52,0,0,1-.55.41.51.51,0,0,1-.54-.4L143,237.68A.61.61,0,0,1,142.88,237.38Z"/>
            <path className="cls-1" d="M156.8,242.64v-2.18a3.57,3.57,0,0,1,1.12-2.63,3.63,3.63,0,0,1,2.66-1.11,3.76,3.76,0,0,1,2.68,1.1,3.53,3.53,0,0,1,1.14,2.64v2.18a3.93,3.93,0,0,1-3.82,3.8,3.6,3.6,0,0,1-2.64-1.15A3.66,3.66,0,0,1,156.8,242.64Zm1.3,0a2.54,2.54,0,0,0,.73,1.8,2.28,2.28,0,0,0,1.75.79,2.36,2.36,0,0,0,1.78-.79,2.55,2.55,0,0,0,.74-1.8v-2.16a2.44,2.44,0,0,0-.75-1.75,2.38,2.38,0,0,0-1.77-.78,2.3,2.3,0,0,0-1.75.78,2.46,2.46,0,0,0-.73,1.75Z"/>
            <path className="cls-1" d="M166.54,245.78v-8.46a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.72.72,0,0,1,.44.14.44.44,0,0,1,.18.35v1a3.08,3.08,0,0,1,1.06-1.13,2.83,2.83,0,0,1,1.62-.47h.7a.48.48,0,0,1,.36.18.66.66,0,0,1,0,.86.48.48,0,0,1-.36.18h-.7a2.5,2.5,0,0,0-1.84.81,2.83,2.83,0,0,0-.8,2.1v4.93a.5.5,0,0,1-.19.36.71.71,0,0,1-.47.16.64.64,0,0,1-.45-.16A.47.47,0,0,1,166.54,245.78Z"/>
            <path className="cls-1" d="M173.16,245.78V231.52a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.82.82,0,0,1,.47.14.43.43,0,0,1,.19.34v9.74l4.88-4.34a.65.65,0,0,1,.3-.12.65.65,0,0,1,.45.19.57.57,0,0,1,.21.43.46.46,0,0,1-.14.36l-2.94,2.58,3.46,5.06a.71.71,0,0,1,.08.28.77.77,0,0,1-.7.66.46.46,0,0,1-.4-.24l-3.36-4.94-1.84,1.62v3a.5.5,0,0,1-.19.36.71.71,0,0,1-.47.16.64.64,0,0,1-.45-.16A.47.47,0,0,1,173.16,245.78Z"/>
            <path className="cls-1" d="M186.24,243.36V232.84a.39.39,0,0,1,.2-.34.77.77,0,0,1,.44-.14.83.83,0,0,1,.46.14.42.42,0,0,1,.2.34v4h2.58a.41.41,0,0,1,.33.16.65.65,0,0,1,0,.78.41.41,0,0,1-.33.16h-2.58v5.39a1.78,1.78,0,0,0,.41,1.36,2.08,2.08,0,0,0,1.45.39H190a.58.58,0,0,1,.43.18.57.57,0,0,1,.17.42.61.61,0,0,1-.17.43.56.56,0,0,1-.43.19h-.56Q186.24,246.3,186.24,243.36Z"/>
            <path className="cls-1" d="M191.66,242.64v-2.18a3.57,3.57,0,0,1,1.12-2.63,3.63,3.63,0,0,1,2.66-1.11,3.76,3.76,0,0,1,2.68,1.1,3.53,3.53,0,0,1,1.14,2.64v2.18a3.93,3.93,0,0,1-3.82,3.8,3.6,3.6,0,0,1-2.64-1.15A3.66,3.66,0,0,1,191.66,242.64Zm1.3,0a2.54,2.54,0,0,0,.73,1.8,2.28,2.28,0,0,0,1.75.79,2.36,2.36,0,0,0,1.78-.79,2.55,2.55,0,0,0,.74-1.8v-2.16a2.44,2.44,0,0,0-.75-1.75,2.38,2.38,0,0,0-1.77-.78,2.3,2.3,0,0,0-1.75.78,2.46,2.46,0,0,0-.73,1.75Z"/>
            <path className="cls-1" d="M200.64,248.28a2.28,2.28,0,0,1,1.7-2.18,1.39,1.39,0,0,1-1.1-1.37,1.59,1.59,0,0,1,1-1.43,3.11,3.11,0,0,1-1.1-2.53V240a3.13,3.13,0,0,1,1-2.38,3.79,3.79,0,0,1,4.83-.08,2.89,2.89,0,0,1,.68-.91,1.11,1.11,0,0,1,.74-.34.6.6,0,0,1,.44.16.54.54,0,0,1,.16.36.41.41,0,0,1-.34.44,1.64,1.64,0,0,0-1.12.84A3.09,3.09,0,0,1,208,240v.76a3.2,3.2,0,0,1-1,2.39,3.47,3.47,0,0,1-2.5.94,3.64,3.64,0,0,1-1.54-.33c-.42.22-.62.48-.62.77a.69.69,0,0,0,.46.64,3.9,3.9,0,0,0,1.14.31l1.5.19A9.36,9.36,0,0,1,207,246a2.32,2.32,0,0,1,1.15.74,2.08,2.08,0,0,1,.46,1.39,2.39,2.39,0,0,1-1.08,2.1,5,5,0,0,1-2.84.74,5.37,5.37,0,0,1-2.93-.72A2.19,2.19,0,0,1,200.64,248.28Zm1.26-.12a1.51,1.51,0,0,0,.75,1.32,3.63,3.63,0,0,0,2,.5,3.42,3.42,0,0,0,2-.5,1.58,1.58,0,0,0,.71-1.35,1.27,1.27,0,0,0-.2-.7,1.58,1.58,0,0,0-.47-.46,3.28,3.28,0,0,0-.71-.28,4.77,4.77,0,0,0-.78-.15l-.84-.09-.68-.07C202.49,246.75,201.9,247.35,201.9,248.16Zm.5-7.42a2.48,2.48,0,0,0,.59,1.72,2,2,0,0,0,1.61.66,2,2,0,0,0,1.57-.66,2.49,2.49,0,0,0,.59-1.7V240a2.39,2.39,0,0,0-.59-1.66,2,2,0,0,0-1.59-.66,2,2,0,0,0-1.59.66,2.34,2.34,0,0,0-.59,1.66Z"/>
            <path className="cls-1" d="M210.08,242.64V240.4a3.48,3.48,0,0,1,1.12-2.59,3.7,3.7,0,0,1,2.7-1.09,3.74,3.74,0,0,1,2.63,1,3.43,3.43,0,0,1,1.11,2.6,2.68,2.68,0,0,1-.13.9.84.84,0,0,1-.42.48,2.37,2.37,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0h-4.52v.74a2.32,2.32,0,0,0,.85,1.89,3.29,3.29,0,0,0,2.19.71,3.33,3.33,0,0,0,1.19-.2,3.47,3.47,0,0,0,.78-.4.84.84,0,0,1,.43-.2.51.51,0,0,1,.39.21.69.69,0,0,1,.17.41c0,.28-.29.57-.86.87a4.61,4.61,0,0,1-2.14.45,4.53,4.53,0,0,1-3.1-1.05A3.48,3.48,0,0,1,210.08,242.64Zm1.3-1.64h4a1.62,1.62,0,0,0,.8-.13c.13-.08.2-.27.2-.55a2.47,2.47,0,0,0-.71-1.74,2.34,2.34,0,0,0-1.79-.76,2.41,2.41,0,0,0-1.77.72,2.27,2.27,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M219.56,243.36V232.84a.39.39,0,0,1,.2-.34.77.77,0,0,1,.44-.14.83.83,0,0,1,.46.14.42.42,0,0,1,.2.34v4h2.58a.41.41,0,0,1,.33.16.65.65,0,0,1,0,.78.41.41,0,0,1-.33.16h-2.58v5.39a1.78,1.78,0,0,0,.41,1.36,2.08,2.08,0,0,0,1.45.39h.56a.58.58,0,0,1,.43.18.57.57,0,0,1,.17.42.61.61,0,0,1-.17.43.56.56,0,0,1-.43.19h-.56Q219.56,246.3,219.56,243.36Z"/>
            <path className="cls-1" d="M225.54,245.78V231.54a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.82.82,0,0,1,.47.14.43.43,0,0,1,.19.34v6.72a2.82,2.82,0,0,1,1-1.09,2.87,2.87,0,0,1,1.61-.45,3.34,3.34,0,0,1,2.49,1.1,3.68,3.68,0,0,1,1.05,2.64v5.32a.46.46,0,0,1-.19.38.82.82,0,0,1-.47.14.77.77,0,0,1-.44-.14.42.42,0,0,1-.2-.38v-5.31a2.49,2.49,0,0,0-.74-1.75,2.34,2.34,0,0,0-1.74-.78,2.32,2.32,0,0,0-1.67.7,2.4,2.4,0,0,0-.73,1.83v5.31a.48.48,0,0,1-.2.36.72.72,0,0,1-.46.16.64.64,0,0,1-.45-.16A.47.47,0,0,1,225.54,245.78Z"/>
            <path className="cls-1" d="M235.1,242.64V240.4a3.48,3.48,0,0,1,1.12-2.59,3.7,3.7,0,0,1,2.7-1.09,3.74,3.74,0,0,1,2.63,1,3.43,3.43,0,0,1,1.11,2.6,2.68,2.68,0,0,1-.13.9.84.84,0,0,1-.42.48,2.37,2.37,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0H236.4v.74a2.32,2.32,0,0,0,.85,1.89,3.29,3.29,0,0,0,2.19.71,3.33,3.33,0,0,0,1.19-.2,3.47,3.47,0,0,0,.78-.4.84.84,0,0,1,.43-.2.51.51,0,0,1,.39.21.69.69,0,0,1,.17.41c0,.28-.29.57-.86.87a4.61,4.61,0,0,1-2.14.45,4.53,4.53,0,0,1-3.1-1.05A3.48,3.48,0,0,1,235.1,242.64Zm1.3-1.64h4a1.62,1.62,0,0,0,.8-.13c.13-.08.2-.27.2-.55a2.47,2.47,0,0,0-.71-1.74,2.34,2.34,0,0,0-1.79-.76,2.41,2.41,0,0,0-1.77.72,2.27,2.27,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M244.62,245.78v-8.46a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.72.72,0,0,1,.44.14.44.44,0,0,1,.18.35v1a3.08,3.08,0,0,1,1.06-1.13,2.83,2.83,0,0,1,1.62-.47h.7a.48.48,0,0,1,.36.18.66.66,0,0,1,0,.86.48.48,0,0,1-.36.18h-.7a2.5,2.5,0,0,0-1.84.81,2.83,2.83,0,0,0-.8,2.1v4.93a.5.5,0,0,1-.19.36.71.71,0,0,1-.47.16.64.64,0,0,1-.45-.16A.47.47,0,0,1,244.62,245.78Z"/>
            <path className="cls-1" d="M255.2,243.36V232.84a.39.39,0,0,1,.2-.34.77.77,0,0,1,.44-.14.83.83,0,0,1,.46.14.42.42,0,0,1,.2.34v4h2.58a.41.41,0,0,1,.33.16.65.65,0,0,1,0,.78.41.41,0,0,1-.33.16H256.5v5.39a1.78,1.78,0,0,0,.41,1.36,2.08,2.08,0,0,0,1.45.39h.56a.58.58,0,0,1,.43.18.57.57,0,0,1,.17.42.61.61,0,0,1-.17.43.56.56,0,0,1-.43.19h-.56Q255.2,246.3,255.2,243.36Z"/>
            <path className="cls-1" d="M260.62,242.64v-2.18a3.57,3.57,0,0,1,1.12-2.63,3.63,3.63,0,0,1,2.66-1.11,3.76,3.76,0,0,1,2.68,1.1,3.53,3.53,0,0,1,1.14,2.64v2.18a3.93,3.93,0,0,1-3.82,3.8,3.6,3.6,0,0,1-2.64-1.15A3.66,3.66,0,0,1,260.62,242.64Zm1.3,0a2.54,2.54,0,0,0,.73,1.8,2.28,2.28,0,0,0,1.75.79,2.36,2.36,0,0,0,1.78-.79,2.55,2.55,0,0,0,.74-1.8v-2.16a2.44,2.44,0,0,0-.75-1.75,2.38,2.38,0,0,0-1.77-.78,2.3,2.3,0,0,0-1.75.78,2.46,2.46,0,0,0-.73,1.75Z"/>
            <path className="cls-1" d="M269.4,237.38a.48.48,0,0,1,.25-.41.93.93,0,0,1,.53-.17.43.43,0,0,1,.46.3l2.56,7.12,2.06-6.5a.46.46,0,0,1,.87.05l2,6.58,2.62-7.25c0-.2.19-.3.44-.3a.92.92,0,0,1,.53.16.52.52,0,0,1,.17.72l-3.12,8.46a.47.47,0,0,1-.52.32.4.4,0,0,1-.4-.29l-2.09-6.52-2.05,6.4a.52.52,0,0,1-.55.41.51.51,0,0,1-.54-.4l-3.12-8.38A.61.61,0,0,1,269.4,237.38Z"/>
            <path className="cls-1" d="M283,243.8a2.24,2.24,0,0,1,1.19-2.19,7.76,7.76,0,0,1,3.47-.61h1v-.47a3.16,3.16,0,0,0-.58-1.88,2.08,2.08,0,0,0-1.78-.79,3.54,3.54,0,0,0-1.45.28l-.69.29a.42.42,0,0,1-.38-.22.79.79,0,0,1-.14-.47c0-.25.28-.49.86-.7a5.27,5.27,0,0,1,1.84-.32,3.3,3.3,0,0,1,2.75,1.09,4.31,4.31,0,0,1,.87,2.77v5.2a.46.46,0,0,1-.18.37.71.71,0,0,1-.46.15.61.61,0,0,1-.43-.15.45.45,0,0,1-.17-.37v-.88a3.94,3.94,0,0,1-3,1.54,2.83,2.83,0,0,1-2-.67A2.48,2.48,0,0,1,283,243.8Zm1.3-.12a1.55,1.55,0,0,0,.48,1.25,1.89,1.89,0,0,0,1.26.41,2.78,2.78,0,0,0,1.79-.7,1.93,1.93,0,0,0,.85-1.4V242h-.84a12.58,12.58,0,0,0-1.35.06,5.85,5.85,0,0,0-1.09.22,1.56,1.56,0,0,0-.82.52A1.44,1.44,0,0,0,284.34,243.68Z"/>
            <path className="cls-1" d="M292.46,245.78v-8.46a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.72.72,0,0,1,.44.14.44.44,0,0,1,.18.35v1a3.08,3.08,0,0,1,1.06-1.13,2.83,2.83,0,0,1,1.62-.47h.7a.48.48,0,0,1,.36.18.66.66,0,0,1,0,.86.48.48,0,0,1-.36.18h-.7a2.5,2.5,0,0,0-1.84.81,2.83,2.83,0,0,0-.8,2.1v4.93a.5.5,0,0,1-.19.36.71.71,0,0,1-.47.16.64.64,0,0,1-.45-.16A.47.47,0,0,1,292.46,245.78Z"/>
            <path className="cls-1" d="M298.36,242.64v-2.18a3.68,3.68,0,0,1,1.05-2.64,3.38,3.38,0,0,1,2.53-1.1,2.92,2.92,0,0,1,2.64,1.54v-6.72a.39.39,0,0,1,.2-.34.83.83,0,0,1,.48-.14.73.73,0,0,1,.45.14.44.44,0,0,1,.17.34v14.24a.48.48,0,0,1-.18.36.63.63,0,0,1-.44.16.62.62,0,0,1-.43-.16.47.47,0,0,1-.19-.37v-.89a2.87,2.87,0,0,1-1.09,1.1,3.07,3.07,0,0,1-1.65.46,3.25,3.25,0,0,1-2.48-1.15A3.79,3.79,0,0,1,298.36,242.64Zm1.3,0a2.52,2.52,0,0,0,.74,1.79,2.27,2.27,0,0,0,1.74.8,2.38,2.38,0,0,0,1.7-.72,1.94,1.94,0,0,0,.74-1.25v-2.78a2.42,2.42,0,0,0-.73-1.83,2.38,2.38,0,0,0-3.45.08,2.43,2.43,0,0,0-.74,1.75Z"/>
            <path className="cls-1" d="M307.8,245a.65.65,0,0,1,.14-.4.4.4,0,0,1,.32-.2,1,1,0,0,1,.44.23,4.08,4.08,0,0,0,.86.47,3.38,3.38,0,0,0,1.34.24,2.49,2.49,0,0,0,1.56-.4,1.27,1.27,0,0,0,.5-1,1.43,1.43,0,0,0-.51-1.15,3.44,3.44,0,0,0-1.23-.65l-1.45-.44a2.72,2.72,0,0,1-1.24-.77,2.09,2.09,0,0,1-.51-1.47,2.64,2.64,0,0,1,.79-1.91,3.15,3.15,0,0,1,2.37-.81A5.25,5.25,0,0,1,313,237c.56.21.85.43.85.67a.88.88,0,0,1-.16.44.47.47,0,0,1-.38.24,5.24,5.24,0,0,1-.74-.28,3.58,3.58,0,0,0-1.38-.28,2.12,2.12,0,0,0-1.5.47,1.4,1.4,0,0,0-.5,1.06,1.12,1.12,0,0,0,.51,1,4.9,4.9,0,0,0,1.24.57l1.47.43a2.37,2.37,0,0,1,1.24.87,2.69,2.69,0,0,1,.5,1.68,2.32,2.32,0,0,1-.85,1.89,3.68,3.68,0,0,1-2.37.69,4.84,4.84,0,0,1-2.23-.47C308.1,245.66,307.8,245.33,307.8,245Z"/>
            <path className="cls-1" d="M319.74,242.64V240.4a3.48,3.48,0,0,1,1.12-2.59,3.7,3.7,0,0,1,2.7-1.09,3.74,3.74,0,0,1,2.63,1,3.43,3.43,0,0,1,1.11,2.6,2.68,2.68,0,0,1-.13.9.84.84,0,0,1-.42.48,2.37,2.37,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0H321v.74a2.32,2.32,0,0,0,.85,1.89,3.29,3.29,0,0,0,2.19.71,3.33,3.33,0,0,0,1.19-.2,3.47,3.47,0,0,0,.78-.4.84.84,0,0,1,.43-.2.51.51,0,0,1,.39.21.69.69,0,0,1,.17.41c0,.28-.29.57-.86.87a4.61,4.61,0,0,1-2.14.45,4.53,4.53,0,0,1-3.1-1.05A3.48,3.48,0,0,1,319.74,242.64ZM321,241h4a1.62,1.62,0,0,0,.8-.13c.13-.08.2-.27.2-.55a2.47,2.47,0,0,0-.71-1.74,2.34,2.34,0,0,0-1.79-.76,2.41,2.41,0,0,0-1.77.72,2.27,2.27,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M328.9,242.64v-2.18a3.65,3.65,0,0,1,1.06-2.64,3.4,3.4,0,0,1,2.54-1.1,3,3,0,0,1,1.62.44,3.17,3.17,0,0,1,1.06,1.1v-.93a.43.43,0,0,1,.17-.35.69.69,0,0,1,.45-.14.73.73,0,0,1,.45.14.44.44,0,0,1,.17.34v13.12a.46.46,0,0,1-.18.34.63.63,0,0,1-.44.16.71.71,0,0,1-.48-.16.43.43,0,0,1-.2-.34v-5.56a2.74,2.74,0,0,1-1.07,1.1,3,3,0,0,1-1.61.46,3.25,3.25,0,0,1-2.48-1.15A3.79,3.79,0,0,1,328.9,242.64Zm1.3,0a2.52,2.52,0,0,0,.74,1.79,2.27,2.27,0,0,0,1.74.8,2.38,2.38,0,0,0,1.7-.72,1.94,1.94,0,0,0,.74-1.25v-2.78a2.42,2.42,0,0,0-.73-1.83,2.38,2.38,0,0,0-3.45.08,2.43,2.43,0,0,0-.74,1.75Z"/>
            <path className="cls-1" d="M338.84,242.68v-5.34A.45.45,0,0,1,339,237a.67.67,0,0,1,.47-.16.68.68,0,0,1,.44.16.46.46,0,0,1,.2.34v5.33a2.48,2.48,0,0,0,.74,1.77,2.38,2.38,0,0,0,3.52,0,2.52,2.52,0,0,0,.74-1.77v-5.35a.39.39,0,0,1,.2-.34.78.78,0,0,1,.46-.14.77.77,0,0,1,.45.14.43.43,0,0,1,.19.34v5.36a3.61,3.61,0,0,1-1.13,2.63,3.69,3.69,0,0,1-2.67,1.13,3.64,3.64,0,0,1-2.68-1.13A3.6,3.6,0,0,1,338.84,242.68Z"/>
            <path className="cls-1" d="M348.86,233.79a.77.77,0,0,1-.26-.59.73.73,0,0,1,.26-.58.89.89,0,0,1,.64-.24.85.85,0,0,1,.61.24.79.79,0,0,1,.25.58.83.83,0,0,1-.25.59.82.82,0,0,1-.61.25A.86.86,0,0,1,348.86,233.79Zm0,12v-8.46A.42.42,0,0,1,349,237a.74.74,0,0,1,.46-.14.82.82,0,0,1,.47.14.43.43,0,0,1,.19.34v8.46a.48.48,0,0,1-.2.36.72.72,0,0,1-.46.16.64.64,0,0,1-.45-.16A.47.47,0,0,1,348.86,245.78Z"/>
            <path className="cls-1" d="M352.64,243.36V232.84a.39.39,0,0,1,.2-.34.77.77,0,0,1,.44-.14.83.83,0,0,1,.46.14.42.42,0,0,1,.2.34v4h2.58a.41.41,0,0,1,.33.16.65.65,0,0,1,0,.78.41.41,0,0,1-.33.16h-2.58v5.39a1.78,1.78,0,0,0,.41,1.36,2.08,2.08,0,0,0,1.45.39h.56a.58.58,0,0,1,.43.18.57.57,0,0,1,.17.42.61.61,0,0,1-.17.43.56.56,0,0,1-.43.19h-.56Q352.64,246.3,352.64,243.36Z"/>
            <path className="cls-1" d="M358,243.8a2.24,2.24,0,0,1,1.19-2.19,7.76,7.76,0,0,1,3.47-.61h1v-.47a3.16,3.16,0,0,0-.58-1.88,2.08,2.08,0,0,0-1.78-.79,3.54,3.54,0,0,0-1.45.28l-.69.29a.42.42,0,0,1-.38-.22.79.79,0,0,1-.14-.47c0-.25.28-.49.86-.7a5.27,5.27,0,0,1,1.84-.32,3.3,3.3,0,0,1,2.75,1.09,4.31,4.31,0,0,1,.87,2.77v5.2a.46.46,0,0,1-.18.37.71.71,0,0,1-.46.15.61.61,0,0,1-.43-.15.45.45,0,0,1-.17-.37v-.88a3.94,3.94,0,0,1-3,1.54,2.83,2.83,0,0,1-2-.67A2.48,2.48,0,0,1,358,243.8Zm1.3-.12a1.55,1.55,0,0,0,.48,1.25,1.89,1.89,0,0,0,1.26.41,2.78,2.78,0,0,0,1.79-.7,1.93,1.93,0,0,0,.85-1.4V242h-.84a12.58,12.58,0,0,0-1.35.06,5.85,5.85,0,0,0-1.09.22,1.56,1.56,0,0,0-.82.52A1.44,1.44,0,0,0,359.26,243.68Z"/>
            <path className="cls-1" d="M367.38,245.78V231.54a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.82.82,0,0,1,.47.14.43.43,0,0,1,.19.34v6.72a2.91,2.91,0,0,1,1.05-1.1,2.94,2.94,0,0,1,1.61-.44,3.39,3.39,0,0,1,2.53,1.1,3.68,3.68,0,0,1,1.05,2.64v2.18a3.8,3.8,0,0,1-1.07,2.65,3.29,3.29,0,0,1-2.47,1.15,3.1,3.1,0,0,1-1.66-.46,2.65,2.65,0,0,1-1.08-1.1v.89a.49.49,0,0,1-.18.37.63.63,0,0,1-.44.16.64.64,0,0,1-.45-.16A.47.47,0,0,1,367.38,245.78Zm1.3-2.53a1.92,1.92,0,0,0,.75,1.25,2.4,2.4,0,0,0,1.71.72,2.3,2.3,0,0,0,1.74-.8,2.56,2.56,0,0,0,.74-1.79v-2.16a2.47,2.47,0,0,0-.74-1.75,2.36,2.36,0,0,0-1.76-.78,2.46,2.46,0,0,0-2.44,2.53Z"/>
            <path className="cls-1" d="M377.22,245.78V231.52a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.82.82,0,0,1,.47.14.43.43,0,0,1,.19.34v14.26a.5.5,0,0,1-.19.36.71.71,0,0,1-.47.16.64.64,0,0,1-.45-.16A.47.47,0,0,1,377.22,245.78Z"/>
            <path className="cls-1" d="M380.84,242.64V240.4a3.51,3.51,0,0,1,1.11-2.59,3.88,3.88,0,0,1,5.34-.05,3.43,3.43,0,0,1,1.11,2.6,2.68,2.68,0,0,1-.13.9.84.84,0,0,1-.42.48,2.37,2.37,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0h-4.52v.74a2.32,2.32,0,0,0,.85,1.89,3.29,3.29,0,0,0,2.19.71,3.33,3.33,0,0,0,1.19-.2,3.71,3.71,0,0,0,.78-.4.84.84,0,0,1,.43-.2.51.51,0,0,1,.39.21.69.69,0,0,1,.17.41c0,.28-.29.57-.86.87a4.61,4.61,0,0,1-2.14.45,4.53,4.53,0,0,1-3.1-1.05A3.48,3.48,0,0,1,380.84,242.64Zm1.3-1.64h4a1.62,1.62,0,0,0,.8-.13c.13-.08.2-.27.2-.55a2.47,2.47,0,0,0-.71-1.74,2.34,2.34,0,0,0-1.79-.76,2.41,2.41,0,0,0-1.77.72,2.27,2.27,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M393.4,237.38a.48.48,0,0,1,.25-.41.93.93,0,0,1,.53-.17.43.43,0,0,1,.46.3l2.56,7.12,2.06-6.5a.39.39,0,0,1,.43-.29.4.4,0,0,1,.44.34l2,6.58,2.62-7.25c0-.2.19-.3.44-.3a.92.92,0,0,1,.53.16.48.48,0,0,1,.25.42.56.56,0,0,1-.08.3l-3.12,8.46a.48.48,0,0,1-.52.32.42.42,0,0,1-.41-.29l-2.08-6.52-2.05,6.4a.52.52,0,0,1-.55.41.51.51,0,0,1-.54-.4l-3.12-8.38A.61.61,0,0,1,393.4,237.38Z"/>
            <path className="cls-1" d="M407.34,242.64V240.4a3.51,3.51,0,0,1,1.11-2.59,3.88,3.88,0,0,1,5.34-.05,3.43,3.43,0,0,1,1.11,2.6,2.68,2.68,0,0,1-.13.9.84.84,0,0,1-.42.48,2.37,2.37,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0h-4.52v.74a2.32,2.32,0,0,0,.85,1.89,3.29,3.29,0,0,0,2.19.71,3.33,3.33,0,0,0,1.19-.2,3.71,3.71,0,0,0,.78-.4.84.84,0,0,1,.43-.2.51.51,0,0,1,.39.21.69.69,0,0,1,.17.41c0,.28-.29.57-.86.87a4.61,4.61,0,0,1-2.14.45,4.53,4.53,0,0,1-3.1-1.05A3.48,3.48,0,0,1,407.34,242.64Zm1.3-1.64h4a1.62,1.62,0,0,0,.8-.13c.13-.08.2-.27.2-.55a2.47,2.47,0,0,0-.71-1.74,2.34,2.34,0,0,0-1.79-.76,2.41,2.41,0,0,0-1.77.72,2.27,2.27,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M417,245.78V231.52a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.82.82,0,0,1,.47.14.43.43,0,0,1,.19.34v14.26a.5.5,0,0,1-.19.36.71.71,0,0,1-.47.16.64.64,0,0,1-.45-.16A.47.47,0,0,1,417,245.78Z"/>
            <path className="cls-1" d="M421.16,245.78V231.52a.44.44,0,0,1,.17-.34.76.76,0,0,1,.46-.14.83.83,0,0,1,.48.14.42.42,0,0,1,.18.34v14.26a.48.48,0,0,1-.18.36.71.71,0,0,1-.48.16.63.63,0,0,1-.44-.16A.47.47,0,0,1,421.16,245.78Z"/>
            <path className="cls-1" d="M425.13,245.78V231.54a.43.43,0,0,1,.19-.34.72.72,0,0,1,.46-.14.82.82,0,0,1,.47.14.42.42,0,0,1,.18.34v6.72a3,3,0,0,1,2.66-1.54,3.42,3.42,0,0,1,2.54,1.1,3.67,3.67,0,0,1,1,2.64v2.18a3.79,3.79,0,0,1-1.06,2.65,3.29,3.29,0,0,1-2.48,1.15,3.15,3.15,0,0,1-1.66-.46,2.77,2.77,0,0,1-1.08-1.1v.89a.49.49,0,0,1-.18.37.6.6,0,0,1-.43.16.64.64,0,0,1-.45-.16A.48.48,0,0,1,425.13,245.78Zm1.3-2.53a1.92,1.92,0,0,0,.75,1.25,2.4,2.4,0,0,0,1.71.72,2.27,2.27,0,0,0,1.74-.8,2.53,2.53,0,0,0,.75-1.79v-2.16a2.44,2.44,0,0,0-.75-1.75,2.33,2.33,0,0,0-1.75-.78,2.42,2.42,0,0,0-1.71.7,2.4,2.4,0,0,0-.74,1.83Z"/>
            <path className="cls-1" d="M434.45,242.64V240.4a3.52,3.52,0,0,1,1.12-2.59,3.87,3.87,0,0,1,5.33-.05,3.43,3.43,0,0,1,1.11,2.6,2.45,2.45,0,0,1-.13.9.78.78,0,0,1-.42.48,2.21,2.21,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0h-4.52v.74a2.35,2.35,0,0,0,.85,1.89,3.33,3.33,0,0,0,2.19.71,3.29,3.29,0,0,0,1.19-.2,3.47,3.47,0,0,0,.78-.4.87.87,0,0,1,.43-.2.49.49,0,0,1,.39.21.63.63,0,0,1,.17.41c0,.28-.28.57-.86.87a5.18,5.18,0,0,1-5.24-.6A3.48,3.48,0,0,1,434.45,242.64Zm1.3-1.64h4a1.59,1.59,0,0,0,.8-.13c.14-.08.2-.27.2-.55a2.43,2.43,0,0,0-.71-1.74,2.33,2.33,0,0,0-1.79-.76,2.44,2.44,0,0,0-1.77.72,2.31,2.31,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M444,233.79a.8.8,0,0,1-.26-.59.77.77,0,0,1,.26-.58.93.93,0,0,1,.65-.24.83.83,0,0,1,.6.24.76.76,0,0,1,.25.58.85.85,0,0,1-.85.84A.9.9,0,0,1,444,233.79Zm0,12v-8.46a.43.43,0,0,1,.19-.34.72.72,0,0,1,.46-.14.76.76,0,0,1,.46.14.4.4,0,0,1,.19.34v8.46a.47.47,0,0,1-.19.36.72.72,0,0,1-.46.16.64.64,0,0,1-.45-.16A.48.48,0,0,1,444,245.78Z"/>
            <path className="cls-1" d="M447.79,245.78v-8.46A.42.42,0,0,1,448,237a.72.72,0,0,1,.46-.13.71.71,0,0,1,.42.14.44.44,0,0,1,.18.35v.93a2.77,2.77,0,0,1,1-1.09,2.93,2.93,0,0,1,1.63-.45,3.36,3.36,0,0,1,2.5,1.1,3.65,3.65,0,0,1,1.07,2.64v5.32a.44.44,0,0,1-.2.38.83.83,0,0,1-.46.14.8.8,0,0,1-.45-.14.44.44,0,0,1-.19-.38v-5.31a2.49,2.49,0,0,0-.74-1.75,2.36,2.36,0,0,0-1.76-.78,2.28,2.28,0,0,0-1.66.7,2.4,2.4,0,0,0-.73,1.83v5.31a.47.47,0,0,1-.19.36.72.72,0,0,1-.46.16.63.63,0,0,1-.46-.16A.5.5,0,0,1,447.79,245.78Z"/>
            <path className="cls-1" d="M457,248.28a2.29,2.29,0,0,1,1.71-2.18,1.4,1.4,0,0,1-1.11-1.37,1.6,1.6,0,0,1,1-1.43,3.14,3.14,0,0,1-1.1-2.53V240a3.13,3.13,0,0,1,1-2.38,3.79,3.79,0,0,1,4.83-.08,2.89,2.89,0,0,1,.68-.91,1.11,1.11,0,0,1,.74-.34.56.56,0,0,1,.43.16.5.5,0,0,1,.16.36.4.4,0,0,1-.33.44,1.68,1.68,0,0,0-1.13.84,3,3,0,0,1,.57,1.89v.76a3.2,3.2,0,0,1-1,2.39,3.47,3.47,0,0,1-2.5.94,3.72,3.72,0,0,1-1.55-.33c-.41.22-.62.48-.62.77a.71.71,0,0,0,.46.64,4.06,4.06,0,0,0,1.15.31l1.5.19a9.24,9.24,0,0,1,1.5.31,2.28,2.28,0,0,1,1.15.74,2,2,0,0,1,.47,1.39,2.39,2.39,0,0,1-1.08,2.1,5,5,0,0,1-2.85.74,5.41,5.41,0,0,1-2.93-.72A2.21,2.21,0,0,1,457,248.28Zm1.26-.12a1.51,1.51,0,0,0,.75,1.32,3.66,3.66,0,0,0,2,.5,3.38,3.38,0,0,0,2-.5,1.56,1.56,0,0,0,.71-1.35,1.2,1.2,0,0,0-.2-.7A1.44,1.44,0,0,0,463,247a3.28,3.28,0,0,0-.71-.28,4.77,4.77,0,0,0-.78-.15l-.84-.09-.68-.07C458.81,246.75,458.21,247.35,458.21,248.16Zm.5-7.42a2.53,2.53,0,0,0,.59,1.72,2.08,2.08,0,0,0,1.62.66,2,2,0,0,0,1.57-.66,2.49,2.49,0,0,0,.59-1.7V240a2.39,2.39,0,0,0-.59-1.66,2.26,2.26,0,0,0-3.19,0,2.39,2.39,0,0,0-.59,1.66Z"/>
            <path className="cls-1" d="M12.92,270.38a.48.48,0,0,1,.25-.41,1,1,0,0,1,.53-.17.43.43,0,0,1,.46.3l2.56,7.12,2.06-6.5a.4.4,0,0,1,.44-.29.41.41,0,0,1,.44.34l2,6.58,2.62-7.25q.06-.3.45-.3a.91.91,0,0,1,.52.16.48.48,0,0,1,.25.42.56.56,0,0,1-.07.3l-3.12,8.46a.49.49,0,0,1-.53.32.4.4,0,0,1-.4-.29l-2.09-6.52-2.05,6.4a.52.52,0,0,1-.55.41.51.51,0,0,1-.54-.4L13,270.68A.75.75,0,0,1,12.92,270.38Z"/>
            <path className="cls-1" d="M27.22,266.79a.8.8,0,0,1-.26-.59.77.77,0,0,1,.26-.58.91.91,0,0,1,.64-.24.85.85,0,0,1,.61.24.76.76,0,0,1,.25.58.79.79,0,0,1-.25.59.82.82,0,0,1-.61.25A.87.87,0,0,1,27.22,266.79Zm0,12v-8.46a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.79.79,0,0,1,.47.14.43.43,0,0,1,.19.34v8.46a.48.48,0,0,1-.2.36.7.7,0,0,1-.46.16.67.67,0,0,1-.45-.16A.47.47,0,0,1,27.22,278.78Z"/>
            <path className="cls-1" d="M31,276.36V265.84a.42.42,0,0,1,.2-.34.77.77,0,0,1,.44-.14.81.81,0,0,1,.46.14.42.42,0,0,1,.2.34v4h2.58a.41.41,0,0,1,.33.16.65.65,0,0,1,0,.78.41.41,0,0,1-.33.16H32.3v5.39a1.83,1.83,0,0,0,.41,1.36,2.1,2.1,0,0,0,1.45.39h.56a.57.57,0,0,1,.43.18.57.57,0,0,1,.17.42.61.61,0,0,1-.17.43.54.54,0,0,1-.43.19h-.56Q31,279.3,31,276.36Z"/>
            <path className="cls-1" d="M37,278.78V264.54a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.79.79,0,0,1,.47.14.43.43,0,0,1,.19.34v6.72a2.82,2.82,0,0,1,1-1.09,2.89,2.89,0,0,1,1.61-.45,3.34,3.34,0,0,1,2.49,1.1,3.68,3.68,0,0,1,1.05,2.64v5.32a.44.44,0,0,1-.19.38.79.79,0,0,1-.47.14.77.77,0,0,1-.44-.14.44.44,0,0,1-.2-.38v-5.31a2.49,2.49,0,0,0-.74-1.75,2.34,2.34,0,0,0-3.41-.08,2.4,2.4,0,0,0-.73,1.83v5.31a.48.48,0,0,1-.2.36.7.7,0,0,1-.46.16.65.65,0,0,1-.45-.16A.47.47,0,0,1,37,278.78Z"/>
            <path className="cls-1" d="M46.9,266.79a.8.8,0,0,1-.26-.59.77.77,0,0,1,.26-.58.91.91,0,0,1,.64-.24.85.85,0,0,1,.61.24.76.76,0,0,1,.25.58.79.79,0,0,1-.25.59.82.82,0,0,1-.61.25A.87.87,0,0,1,46.9,266.79Zm0,12v-8.46a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14A.79.79,0,0,1,48,270a.43.43,0,0,1,.19.34v8.46a.48.48,0,0,1-.2.36.7.7,0,0,1-.46.16.65.65,0,0,1-.45-.16A.47.47,0,0,1,46.9,278.78Z"/>
            <path className="cls-1" d="M50.72,278.78v-8.46a.41.41,0,0,1,.18-.35.74.74,0,0,1,.46-.13.68.68,0,0,1,.42.14.44.44,0,0,1,.18.35v.93a2.85,2.85,0,0,1,1-1.09,3,3,0,0,1,1.64-.45,3.38,3.38,0,0,1,2.5,1.1,3.65,3.65,0,0,1,1.06,2.64v5.32a.44.44,0,0,1-.2.38.81.81,0,0,1-.46.14.77.77,0,0,1-.44-.14.44.44,0,0,1-.2-.38v-5.31a2.49,2.49,0,0,0-.74-1.75,2.34,2.34,0,0,0-1.76-.78,2.29,2.29,0,0,0-1.66.7,2.43,2.43,0,0,0-.72,1.83v5.31a.48.48,0,0,1-.2.36.7.7,0,0,1-.46.16.65.65,0,0,1-.46-.16A.48.48,0,0,1,50.72,278.78Z"/>
            <path className="cls-1" d="M64,276.8a2.25,2.25,0,0,1,1.19-2.19,7.76,7.76,0,0,1,3.47-.61h1v-.47a3.09,3.09,0,0,0-.58-1.88,2.08,2.08,0,0,0-1.78-.79,3.75,3.75,0,0,0-1.45.28,7.44,7.44,0,0,1-.69.29.42.42,0,0,1-.38-.22.86.86,0,0,1-.14-.47c0-.25.29-.49.86-.7a5.31,5.31,0,0,1,1.84-.32,3.3,3.3,0,0,1,2.75,1.09,4.31,4.31,0,0,1,.87,2.77v5.2a.46.46,0,0,1-.18.37.69.69,0,0,1-.46.15.63.63,0,0,1-.43-.15.48.48,0,0,1-.17-.37v-.88a3.93,3.93,0,0,1-3,1.54,2.84,2.84,0,0,1-2-.67A2.48,2.48,0,0,1,64,276.8Zm1.3-.12a1.55,1.55,0,0,0,.48,1.25,1.89,1.89,0,0,0,1.26.41,2.76,2.76,0,0,0,1.79-.7,1.9,1.9,0,0,0,.85-1.4V275h-.84a12.84,12.84,0,0,0-1.35.06,5.85,5.85,0,0,0-1.09.22,1.6,1.6,0,0,0-.82.52A1.44,1.44,0,0,0,65.28,276.68Z"/>
            <path className="cls-1" d="M77.4,278.78V264.54a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.79.79,0,0,1,.47.14.43.43,0,0,1,.19.34v6.72a2.82,2.82,0,0,1,1-1.09,2.89,2.89,0,0,1,1.61-.45,3.34,3.34,0,0,1,2.49,1.1,3.68,3.68,0,0,1,1,2.64v5.32a.44.44,0,0,1-.19.38.79.79,0,0,1-.47.14.77.77,0,0,1-.44-.14.44.44,0,0,1-.2-.38v-5.31a2.49,2.49,0,0,0-.74-1.75,2.34,2.34,0,0,0-3.41-.08,2.4,2.4,0,0,0-.73,1.83v5.31a.48.48,0,0,1-.2.36.7.7,0,0,1-.46.16.65.65,0,0,1-.45-.16A.47.47,0,0,1,77.4,278.78Z"/>
            <path className="cls-1" d="M87,275.64V273.4a3.48,3.48,0,0,1,1.12-2.59,3.87,3.87,0,0,1,5.33-.05,3.43,3.43,0,0,1,1.11,2.6,2.45,2.45,0,0,1-.13.9.84.84,0,0,1-.42.48,2.21,2.21,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0H88.26v.74a2.32,2.32,0,0,0,.85,1.89,3.7,3.7,0,0,0,3.38.51,3.47,3.47,0,0,0,.78-.4.84.84,0,0,1,.43-.2.5.5,0,0,1,.39.21.69.69,0,0,1,.17.41c0,.28-.29.57-.86.87a4.58,4.58,0,0,1-2.14.45,4.53,4.53,0,0,1-3.1-1.05A3.48,3.48,0,0,1,87,275.64Zm1.3-1.64h4a1.62,1.62,0,0,0,.8-.13c.13-.08.2-.27.2-.55a2.47,2.47,0,0,0-.71-1.74,2.33,2.33,0,0,0-1.79-.76,2.44,2.44,0,0,0-1.77.72,2.27,2.27,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M95.82,276.8A2.24,2.24,0,0,1,97,274.61a7.76,7.76,0,0,1,3.47-.61h1v-.47a3.09,3.09,0,0,0-.58-1.88,2.08,2.08,0,0,0-1.78-.79,3.75,3.75,0,0,0-1.45.28c-.44.19-.66.29-.69.29a.42.42,0,0,1-.38-.22.86.86,0,0,1-.14-.47c0-.25.29-.49.86-.7a5.31,5.31,0,0,1,1.84-.32,3.3,3.3,0,0,1,2.75,1.09,4.31,4.31,0,0,1,.87,2.77v5.2a.46.46,0,0,1-.18.37.69.69,0,0,1-.46.15.63.63,0,0,1-.43-.15.48.48,0,0,1-.17-.37v-.88a3.93,3.93,0,0,1-3,1.54,2.83,2.83,0,0,1-2-.67A2.48,2.48,0,0,1,95.82,276.8Zm1.3-.12a1.55,1.55,0,0,0,.48,1.25,1.89,1.89,0,0,0,1.26.41,2.76,2.76,0,0,0,1.79-.7,1.93,1.93,0,0,0,.85-1.4V275h-.84a12.84,12.84,0,0,0-1.35.06,5.85,5.85,0,0,0-1.09.22,1.56,1.56,0,0,0-.82.52A1.44,1.44,0,0,0,97.12,276.68Z"/>
            <path className="cls-1" d="M105.4,278.78V264.52a.42.42,0,0,1,.18-.34A.74.74,0,0,1,106,264a.82.82,0,0,1,.47.14.43.43,0,0,1,.19.34v14.26a.5.5,0,0,1-.19.36.71.71,0,0,1-.47.16.65.65,0,0,1-.45-.16A.47.47,0,0,1,105.4,278.78Z"/>
            <path className="cls-1" d="M109.34,276.36V265.84a.39.39,0,0,1,.2-.34.77.77,0,0,1,.44-.14.83.83,0,0,1,.46.14.42.42,0,0,1,.2.34v4h2.58a.41.41,0,0,1,.33.16.65.65,0,0,1,0,.78.41.41,0,0,1-.33.16h-2.58v5.39a1.78,1.78,0,0,0,.41,1.36,2.08,2.08,0,0,0,1.45.39h.56a.57.57,0,0,1,.43.18.57.57,0,0,1,.17.42.61.61,0,0,1-.17.43.54.54,0,0,1-.43.19h-.56Q109.34,279.3,109.34,276.36Z"/>
            <path className="cls-1" d="M115.32,278.78V264.54a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.79.79,0,0,1,.47.14.43.43,0,0,1,.19.34v6.72a2.82,2.82,0,0,1,1-1.09,2.89,2.89,0,0,1,1.61-.45,3.34,3.34,0,0,1,2.49,1.1,3.68,3.68,0,0,1,1,2.64v5.32a.44.44,0,0,1-.19.38.79.79,0,0,1-.47.14.77.77,0,0,1-.44-.14.44.44,0,0,1-.2-.38v-5.31a2.49,2.49,0,0,0-.74-1.75,2.34,2.34,0,0,0-3.41-.08,2.4,2.4,0,0,0-.73,1.83v5.31a.48.48,0,0,1-.2.36.7.7,0,0,1-.46.16.65.65,0,0,1-.45-.16A.47.47,0,0,1,115.32,278.78Z"/>
            <path className="cls-1" d="M124.08,270.34a.55.55,0,0,1,.26-.43.87.87,0,0,1,.54-.19c.25,0,.41.09.46.28l2.74,7.34,2.58-7.34a.44.44,0,0,1,.44-.28.91.91,0,0,1,.53.19.55.55,0,0,1,.27.45.22.22,0,0,1,0,.16l-3.1,8.4-.36,1-.39.94a6.69,6.69,0,0,1-.49,1l-.52.8a3,3,0,0,1-.65.72,4.35,4.35,0,0,1-.75.41,2.17,2.17,0,0,1-.9.19.53.53,0,0,1-.6-.6c0-.41.13-.62.4-.62a2.18,2.18,0,0,0,1.71-1.11,7.84,7.84,0,0,0,1.19-2.74l-3.26-8.35A.38.38,0,0,1,124.08,270.34Z"/>
            <path className="cls-1" d="M137.46,278.78V264.54a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.79.79,0,0,1,.47.14.4.4,0,0,1,.19.34v6.72a2.91,2.91,0,0,1,1.05-1.1,3,3,0,0,1,1.61-.44,3.38,3.38,0,0,1,2.53,1.1,3.68,3.68,0,0,1,1.05,2.64v2.18a3.8,3.8,0,0,1-1.07,2.65,3.27,3.27,0,0,1-2.47,1.15,3.12,3.12,0,0,1-1.66-.46,2.65,2.65,0,0,1-1.08-1.1v.89a.49.49,0,0,1-.18.37.63.63,0,0,1-.44.16.67.67,0,0,1-.45-.16A.47.47,0,0,1,137.46,278.78Zm1.3-2.53a1.92,1.92,0,0,0,.75,1.25,2.4,2.4,0,0,0,1.71.72,2.27,2.27,0,0,0,1.74-.8,2.56,2.56,0,0,0,.74-1.79v-2.16a2.47,2.47,0,0,0-.74-1.75,2.34,2.34,0,0,0-1.76-.78,2.46,2.46,0,0,0-2.44,2.53Z"/>
            <path className="cls-1" d="M147.14,266.79a.8.8,0,0,1-.26-.59.77.77,0,0,1,.26-.58.91.91,0,0,1,.64-.24.85.85,0,0,1,.61.24.76.76,0,0,1,.25.58.79.79,0,0,1-.25.59.82.82,0,0,1-.61.25A.87.87,0,0,1,147.14,266.79Zm0,12v-8.46a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.79.79,0,0,1,.47.14.4.4,0,0,1,.19.34v8.46a.48.48,0,0,1-.2.36.7.7,0,0,1-.46.16.65.65,0,0,1-.45-.16A.47.47,0,0,1,147.14,278.78Z"/>
            <path className="cls-1" d="M150.58,275.64v-2.18a3.57,3.57,0,0,1,1.12-2.63,3.65,3.65,0,0,1,2.66-1.11,3.74,3.74,0,0,1,2.68,1.1,3.53,3.53,0,0,1,1.14,2.64v2.18a3.93,3.93,0,0,1-3.82,3.8,3.6,3.6,0,0,1-2.64-1.15A3.66,3.66,0,0,1,150.58,275.64Zm1.3,0a2.54,2.54,0,0,0,.73,1.8,2.29,2.29,0,0,0,1.75.79,2.34,2.34,0,0,0,1.78-.79,2.55,2.55,0,0,0,.74-1.8v-2.16a2.44,2.44,0,0,0-.75-1.75,2.38,2.38,0,0,0-1.77-.78,2.32,2.32,0,0,0-1.75.78,2.46,2.46,0,0,0-.73,1.75Z"/>
            <path className="cls-1" d="M159.72,278a.72.72,0,0,1,.14-.4.4.4,0,0,1,.32-.2,1,1,0,0,1,.44.23,4.35,4.35,0,0,0,.86.47,3.38,3.38,0,0,0,1.34.24,2.47,2.47,0,0,0,1.56-.4,1.27,1.27,0,0,0,.5-1,1.43,1.43,0,0,0-.51-1.15,3.44,3.44,0,0,0-1.23-.65l-1.45-.44a2.72,2.72,0,0,1-1.24-.77,2.09,2.09,0,0,1-.51-1.47,2.64,2.64,0,0,1,.79-1.91,3.15,3.15,0,0,1,2.37-.81,5.25,5.25,0,0,1,1.83.31c.57.21.85.43.85.67a.79.79,0,0,1-.16.44.47.47,0,0,1-.38.24,5.24,5.24,0,0,1-.74-.28,3.58,3.58,0,0,0-1.38-.28,2.12,2.12,0,0,0-1.5.47,1.44,1.44,0,0,0-.5,1.06,1.11,1.11,0,0,0,.52,1,4.75,4.75,0,0,0,1.24.57l1.46.43a2.33,2.33,0,0,1,1.24.87,2.69,2.69,0,0,1,.5,1.68,2.32,2.32,0,0,1-.85,1.89,3.68,3.68,0,0,1-2.37.69,4.87,4.87,0,0,1-2.23-.47C160,278.66,159.72,278.33,159.72,278Z"/>
            <path className="cls-1" d="M168,283.44V270.32a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.72.72,0,0,1,.44.14.44.44,0,0,1,.18.35v.93a3,3,0,0,1,1.06-1.09,3,3,0,0,1,1.62-.45,3.42,3.42,0,0,1,2.54,1.1,3.69,3.69,0,0,1,1.06,2.64v2.18a3.8,3.8,0,0,1-1.07,2.65,3.27,3.27,0,0,1-2.47,1.15,3,3,0,0,1-1.62-.46,2.84,2.84,0,0,1-1.08-1.1v5.56a.48.48,0,0,1-.19.34.69.69,0,0,1-.47.16.65.65,0,0,1-.45-.16A.45.45,0,0,1,168,283.44Zm1.3-7.19a1.92,1.92,0,0,0,.75,1.25,2.4,2.4,0,0,0,1.71.72,2.29,2.29,0,0,0,1.73-.8,2.53,2.53,0,0,0,.75-1.79v-2.16a2.44,2.44,0,0,0-.75-1.75,2.42,2.42,0,0,0-4.19,1.75Z"/>
            <path className="cls-1" d="M177.7,278.78V264.54a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.79.79,0,0,1,.47.14.43.43,0,0,1,.19.34v6.72a2.82,2.82,0,0,1,1-1.09,2.89,2.89,0,0,1,1.61-.45,3.34,3.34,0,0,1,2.49,1.1,3.68,3.68,0,0,1,1.05,2.64v5.32a.44.44,0,0,1-.19.38.82.82,0,0,1-.47.14.77.77,0,0,1-.44-.14.44.44,0,0,1-.2-.38v-5.31a2.49,2.49,0,0,0-.74-1.75,2.34,2.34,0,0,0-3.41-.08,2.4,2.4,0,0,0-.73,1.83v5.31a.48.48,0,0,1-.2.36.7.7,0,0,1-.46.16.65.65,0,0,1-.45-.16A.47.47,0,0,1,177.7,278.78Z"/>
            <path className="cls-1" d="M187.26,275.64V273.4a3.48,3.48,0,0,1,1.12-2.59,3.87,3.87,0,0,1,5.33-.05,3.43,3.43,0,0,1,1.11,2.6,2.45,2.45,0,0,1-.13.9.84.84,0,0,1-.42.48,2.21,2.21,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0h-4.52v.74a2.32,2.32,0,0,0,.85,1.89,3.29,3.29,0,0,0,2.19.71,3.33,3.33,0,0,0,1.19-.2,3.47,3.47,0,0,0,.78-.4.84.84,0,0,1,.43-.2.5.5,0,0,1,.39.21.69.69,0,0,1,.17.41c0,.28-.29.57-.86.87a4.58,4.58,0,0,1-2.14.45,4.53,4.53,0,0,1-3.1-1.05A3.48,3.48,0,0,1,187.26,275.64Zm1.3-1.64h4a1.62,1.62,0,0,0,.8-.13c.13-.08.2-.27.2-.55a2.47,2.47,0,0,0-.71-1.74,2.33,2.33,0,0,0-1.79-.76,2.44,2.44,0,0,0-1.77.72,2.27,2.27,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M196.78,278.78v-8.46A.42.42,0,0,1,197,270a.74.74,0,0,1,.46-.14.72.72,0,0,1,.44.14.44.44,0,0,1,.18.35v1a3.08,3.08,0,0,1,1.06-1.13,2.88,2.88,0,0,1,1.62-.47h.7a.48.48,0,0,1,.36.18.66.66,0,0,1,0,.86.48.48,0,0,1-.36.18h-.7a2.51,2.51,0,0,0-1.84.81,2.83,2.83,0,0,0-.8,2.1v4.93a.5.5,0,0,1-.19.36.69.69,0,0,1-.47.16.65.65,0,0,1-.45-.16A.47.47,0,0,1,196.78,278.78Z"/>
            <path className="cls-1" d="M202.68,275.64V273.4a3.48,3.48,0,0,1,1.12-2.59,3.87,3.87,0,0,1,5.33-.05,3.43,3.43,0,0,1,1.11,2.6,2.45,2.45,0,0,1-.13.9.84.84,0,0,1-.42.48,2.21,2.21,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0H204v.74a2.32,2.32,0,0,0,.85,1.89,3.7,3.7,0,0,0,3.38.51,3.47,3.47,0,0,0,.78-.4.84.84,0,0,1,.43-.2.49.49,0,0,1,.39.21.69.69,0,0,1,.17.41c0,.28-.29.57-.86.87a4.58,4.58,0,0,1-2.14.45,4.53,4.53,0,0,1-3.1-1.05A3.48,3.48,0,0,1,202.68,275.64ZM204,274h4a1.59,1.59,0,0,0,.8-.13c.13-.08.2-.27.2-.55a2.47,2.47,0,0,0-.71-1.74,2.33,2.33,0,0,0-1.79-.76,2.44,2.44,0,0,0-1.77.72,2.27,2.27,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M211.74,278.43a1,1,0,0,1,.25-.72.82.82,0,0,1,.64-.29.8.8,0,0,1,.61.3,1.07,1.07,0,0,1,.26.71,1,1,0,0,1-.26.69.8.8,0,0,1-.61.3.82.82,0,0,1-.64-.29A1,1,0,0,1,211.74,278.43Z"/>
            <path className="cls-1" d="M218.66,265.29a.68.68,0,0,1,.13-.42.42.42,0,0,1,.37-.19h8a.43.43,0,0,1,.37.18.63.63,0,0,1,.13.41.72.72,0,0,1-.13.44.42.42,0,0,1-.37.19h-3.34v12.88a.44.44,0,0,1-.2.38.81.81,0,0,1-.46.14.79.79,0,0,1-.47-.14.44.44,0,0,1-.19-.38V265.9h-3.36a.43.43,0,0,1-.36-.18A.67.67,0,0,1,218.66,265.29Z"/>
            <path className="cls-1" d="M229.24,278.78V264.54a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.79.79,0,0,1,.47.14.43.43,0,0,1,.19.34v6.72a2.82,2.82,0,0,1,1-1.09,2.89,2.89,0,0,1,1.61-.45,3.34,3.34,0,0,1,2.49,1.1,3.68,3.68,0,0,1,1.05,2.64v5.32a.44.44,0,0,1-.19.38.79.79,0,0,1-.47.14.77.77,0,0,1-.44-.14.44.44,0,0,1-.2-.38v-5.31a2.49,2.49,0,0,0-.74-1.75,2.34,2.34,0,0,0-3.41-.08,2.4,2.4,0,0,0-.73,1.83v5.31a.48.48,0,0,1-.2.36.72.72,0,0,1-.46.16.64.64,0,0,1-.45-.16A.47.47,0,0,1,229.24,278.78Z"/>
            <path className="cls-1" d="M238.8,275.64V273.4a3.48,3.48,0,0,1,1.12-2.59,3.87,3.87,0,0,1,5.33-.05,3.43,3.43,0,0,1,1.11,2.6,2.45,2.45,0,0,1-.13.9.84.84,0,0,1-.42.48,2.21,2.21,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0H240.1v.74a2.32,2.32,0,0,0,.85,1.89,3.7,3.7,0,0,0,3.38.51,3.47,3.47,0,0,0,.78-.4.84.84,0,0,1,.43-.2.5.5,0,0,1,.39.21.69.69,0,0,1,.17.41c0,.28-.29.57-.86.87a4.58,4.58,0,0,1-2.14.45,4.53,4.53,0,0,1-3.1-1.05A3.48,3.48,0,0,1,238.8,275.64Zm1.3-1.64h4a1.62,1.62,0,0,0,.8-.13c.13-.08.2-.27.2-.55a2.47,2.47,0,0,0-.71-1.74,2.33,2.33,0,0,0-1.79-.76,2.44,2.44,0,0,0-1.77.72,2.27,2.27,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M252.22,275.68v-5.34a.45.45,0,0,1,.19-.34.69.69,0,0,1,.47-.16.66.66,0,0,1,.44.16.46.46,0,0,1,.2.34v5.33a2.52,2.52,0,0,0,.74,1.77,2.38,2.38,0,0,0,3.52,0,2.52,2.52,0,0,0,.74-1.77v-5.35a.39.39,0,0,1,.2-.34.78.78,0,0,1,.46-.14.77.77,0,0,1,.45.14.43.43,0,0,1,.19.34v5.36a3.61,3.61,0,0,1-1.13,2.63,3.73,3.73,0,0,1-5.35,0A3.6,3.6,0,0,1,252.22,275.68Z"/>
            <path className="cls-1" d="M262.4,278.78V264.52a.42.42,0,0,1,.18-.34A.74.74,0,0,1,263,264a.82.82,0,0,1,.47.14.43.43,0,0,1,.19.34v14.26a.5.5,0,0,1-.19.36.71.71,0,0,1-.47.16.65.65,0,0,1-.45-.16A.47.47,0,0,1,262.4,278.78Z"/>
            <path className="cls-1" d="M266.34,276.36V265.84a.42.42,0,0,1,.2-.34.77.77,0,0,1,.44-.14.81.81,0,0,1,.46.14.42.42,0,0,1,.2.34v4h2.58a.41.41,0,0,1,.33.16.65.65,0,0,1,0,.78.41.41,0,0,1-.33.16h-2.58v5.39a1.83,1.83,0,0,0,.41,1.36,2.1,2.1,0,0,0,1.45.39h.56a.57.57,0,0,1,.43.18.57.57,0,0,1,.17.42.61.61,0,0,1-.17.43.54.54,0,0,1-.43.19h-.56Q266.33,279.3,266.34,276.36Z"/>
            <path className="cls-1" d="M272.32,266.79a.8.8,0,0,1-.26-.59.77.77,0,0,1,.26-.58.89.89,0,0,1,.64-.24.85.85,0,0,1,.61.24.76.76,0,0,1,.25.58.79.79,0,0,1-.25.59.82.82,0,0,1-.61.25A.86.86,0,0,1,272.32,266.79Zm0,12v-8.46a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.79.79,0,0,1,.47.14.43.43,0,0,1,.19.34v8.46a.48.48,0,0,1-.2.36.7.7,0,0,1-.46.16.65.65,0,0,1-.45-.16A.47.47,0,0,1,272.32,278.78Z"/>
            <path className="cls-1" d="M276.14,278.78v-8.46a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.72.72,0,0,1,.44.14.44.44,0,0,1,.18.35v.93a2.91,2.91,0,0,1,1.05-1.1,3,3,0,0,1,1.61-.44,2.79,2.79,0,0,1,1.72.56,3.15,3.15,0,0,1,1.12,1.46,3.69,3.69,0,0,1,1.32-1.51,3.21,3.21,0,0,1,4.08.51,3.71,3.71,0,0,1,1,2.72v5.32a.47.47,0,0,1-.19.36.69.69,0,0,1-.47.16.67.67,0,0,1-.45-.16.47.47,0,0,1-.19-.36v-5.31a2.47,2.47,0,0,0-.69-1.85,2.21,2.21,0,0,0-1.59-.68,2.27,2.27,0,0,0-1.63.69,2.37,2.37,0,0,0-.71,1.76v5.41c0,.33-.22.5-.66.5s-.64-.17-.64-.5v-5.39a2.45,2.45,0,0,0-.67-1.76,2.15,2.15,0,0,0-1.61-.71,2.28,2.28,0,0,0-1.65.69,2.46,2.46,0,0,0-.71,1.84v5.31a.48.48,0,0,1-.2.36.7.7,0,0,1-.46.16.65.65,0,0,1-.45-.16A.47.47,0,0,1,276.14,278.78Z"/>
            <path className="cls-1" d="M291.08,276.8a2.24,2.24,0,0,1,1.19-2.19,7.76,7.76,0,0,1,3.47-.61h1v-.47a3.09,3.09,0,0,0-.58-1.88,2.08,2.08,0,0,0-1.78-.79,3.75,3.75,0,0,0-1.45.28c-.44.19-.66.29-.69.29a.42.42,0,0,1-.38-.22.86.86,0,0,1-.14-.47c0-.25.29-.49.86-.7a5.31,5.31,0,0,1,1.84-.32,3.3,3.3,0,0,1,2.75,1.09,4.31,4.31,0,0,1,.87,2.77v5.2a.46.46,0,0,1-.18.37.69.69,0,0,1-.46.15.63.63,0,0,1-.43-.15.48.48,0,0,1-.17-.37v-.88a3.94,3.94,0,0,1-3,1.54,2.83,2.83,0,0,1-2-.67A2.48,2.48,0,0,1,291.08,276.8Zm1.3-.12a1.55,1.55,0,0,0,.48,1.25,1.89,1.89,0,0,0,1.26.41,2.76,2.76,0,0,0,1.79-.7,1.93,1.93,0,0,0,.85-1.4V275h-.84a12.71,12.71,0,0,0-1.35.06,5.85,5.85,0,0,0-1.09.22,1.56,1.56,0,0,0-.82.52A1.44,1.44,0,0,0,292.38,276.68Z"/>
            <path className="cls-1" d="M300.46,276.36V265.84a.39.39,0,0,1,.2-.34.77.77,0,0,1,.44-.14.83.83,0,0,1,.46.14.42.42,0,0,1,.2.34v4h2.58a.41.41,0,0,1,.33.16.65.65,0,0,1,0,.78.41.41,0,0,1-.33.16h-2.58v5.39a1.78,1.78,0,0,0,.41,1.36,2.08,2.08,0,0,0,1.45.39h.56a.57.57,0,0,1,.43.18.57.57,0,0,1,.17.42.61.61,0,0,1-.17.43.54.54,0,0,1-.43.19h-.56Q300.46,279.3,300.46,276.36Z"/>
            <path className="cls-1" d="M305.9,275.64V273.4a3.48,3.48,0,0,1,1.12-2.59,3.7,3.7,0,0,1,2.7-1.09,3.74,3.74,0,0,1,2.63,1,3.43,3.43,0,0,1,1.11,2.6,2.68,2.68,0,0,1-.13.9.84.84,0,0,1-.42.48,2.37,2.37,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0H307.2v.74a2.32,2.32,0,0,0,.85,1.89,3.29,3.29,0,0,0,2.19.71,3.33,3.33,0,0,0,1.19-.2,3.47,3.47,0,0,0,.78-.4.84.84,0,0,1,.43-.2.51.51,0,0,1,.39.21.69.69,0,0,1,.17.41c0,.28-.29.57-.86.87a4.61,4.61,0,0,1-2.14.45,4.53,4.53,0,0,1-3.1-1.05A3.48,3.48,0,0,1,305.9,275.64Zm1.3-1.64h4a1.62,1.62,0,0,0,.8-.13c.13-.08.2-.27.2-.55a2.47,2.47,0,0,0-.71-1.74,2.34,2.34,0,0,0-1.79-.76,2.41,2.41,0,0,0-1.77.72,2.27,2.27,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M319.06,275.64V273.4a3.48,3.48,0,0,1,1.12-2.59,3.7,3.7,0,0,1,2.7-1.09,3.74,3.74,0,0,1,2.63,1,3.43,3.43,0,0,1,1.11,2.6,2.68,2.68,0,0,1-.13.9.84.84,0,0,1-.42.48,2.37,2.37,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0h-4.52v.74a2.32,2.32,0,0,0,.85,1.89,3.29,3.29,0,0,0,2.19.71,3.33,3.33,0,0,0,1.19-.2,3.47,3.47,0,0,0,.78-.4.84.84,0,0,1,.43-.2.51.51,0,0,1,.39.21.69.69,0,0,1,.17.41c0,.28-.29.57-.86.87a4.61,4.61,0,0,1-2.14.45,4.53,4.53,0,0,1-3.1-1.05A3.48,3.48,0,0,1,319.06,275.64Zm1.3-1.64h4a1.62,1.62,0,0,0,.8-.13c.13-.08.2-.27.2-.55a2.47,2.47,0,0,0-.71-1.74,2.34,2.34,0,0,0-1.79-.76,2.41,2.41,0,0,0-1.77.72,2.27,2.27,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M328,278a.65.65,0,0,1,.14-.4.4.4,0,0,1,.32-.2,1,1,0,0,1,.44.23,4.08,4.08,0,0,0,.86.47,3.38,3.38,0,0,0,1.34.24,2.49,2.49,0,0,0,1.56-.4,1.27,1.27,0,0,0,.5-1,1.43,1.43,0,0,0-.51-1.15,3.44,3.44,0,0,0-1.23-.65l-1.45-.44a2.72,2.72,0,0,1-1.24-.77,2.09,2.09,0,0,1-.51-1.47,2.64,2.64,0,0,1,.79-1.91,3.15,3.15,0,0,1,2.37-.81,5.25,5.25,0,0,1,1.83.31c.56.21.85.43.85.67a.88.88,0,0,1-.16.44.47.47,0,0,1-.38.24,5.24,5.24,0,0,1-.74-.28,3.58,3.58,0,0,0-1.38-.28,2.12,2.12,0,0,0-1.5.47,1.4,1.4,0,0,0-.5,1.06,1.11,1.11,0,0,0,.52,1,4.75,4.75,0,0,0,1.24.57l1.46.43a2.37,2.37,0,0,1,1.24.87,2.69,2.69,0,0,1,.5,1.68,2.32,2.32,0,0,1-.85,1.89,3.68,3.68,0,0,1-2.37.69,4.84,4.84,0,0,1-2.23-.47C328.28,278.66,328,278.33,328,278Z"/>
            <path className="cls-1" d="M335.68,278a.65.65,0,0,1,.14-.4.4.4,0,0,1,.32-.2,1,1,0,0,1,.44.23,4.08,4.08,0,0,0,.86.47,3.38,3.38,0,0,0,1.34.24,2.49,2.49,0,0,0,1.56-.4,1.27,1.27,0,0,0,.5-1,1.43,1.43,0,0,0-.51-1.15,3.44,3.44,0,0,0-1.23-.65l-1.45-.44a2.72,2.72,0,0,1-1.24-.77,2.09,2.09,0,0,1-.51-1.47,2.64,2.64,0,0,1,.79-1.91,3.15,3.15,0,0,1,2.37-.81,5.25,5.25,0,0,1,1.83.31c.56.21.85.43.85.67a.88.88,0,0,1-.16.44.47.47,0,0,1-.38.24,5.24,5.24,0,0,1-.74-.28,3.58,3.58,0,0,0-1.38-.28,2.12,2.12,0,0,0-1.5.47,1.4,1.4,0,0,0-.5,1.06,1.11,1.11,0,0,0,.52,1,4.75,4.75,0,0,0,1.24.57l1.46.43a2.37,2.37,0,0,1,1.24.87,2.69,2.69,0,0,1,.5,1.68,2.32,2.32,0,0,1-.85,1.89,3.68,3.68,0,0,1-2.37.69,4.84,4.84,0,0,1-2.23-.47C336,278.66,335.68,278.33,335.68,278Z"/>
            <path className="cls-1" d="M343.62,275.64V273.4a3.48,3.48,0,0,1,1.12-2.59,3.7,3.7,0,0,1,2.7-1.09,3.74,3.74,0,0,1,2.63,1,3.43,3.43,0,0,1,1.11,2.6,2.68,2.68,0,0,1-.13.9.84.84,0,0,1-.42.48,2.37,2.37,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0h-4.52v.74a2.32,2.32,0,0,0,.85,1.89,3.29,3.29,0,0,0,2.19.71,3.33,3.33,0,0,0,1.19-.2,3.47,3.47,0,0,0,.78-.4.84.84,0,0,1,.43-.2.51.51,0,0,1,.39.21.69.69,0,0,1,.17.41c0,.28-.29.57-.86.87a4.61,4.61,0,0,1-2.14.45,4.53,4.53,0,0,1-3.1-1.05A3.48,3.48,0,0,1,343.62,275.64Zm1.3-1.64h4a1.62,1.62,0,0,0,.8-.13c.13-.08.2-.27.2-.55a2.47,2.47,0,0,0-.71-1.74,2.34,2.34,0,0,0-1.79-.76,2.41,2.41,0,0,0-1.77.72,2.27,2.27,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M353.14,278.78v-8.46a.41.41,0,0,1,.18-.35.74.74,0,0,1,.46-.13.68.68,0,0,1,.42.14.44.44,0,0,1,.18.35v.93a2.77,2.77,0,0,1,1-1.09,2.94,2.94,0,0,1,1.64-.45,3.38,3.38,0,0,1,2.5,1.1,3.69,3.69,0,0,1,1.06,2.64v5.32a.44.44,0,0,1-.2.38.83.83,0,0,1-.46.14.77.77,0,0,1-.44-.14.42.42,0,0,1-.2-.38v-5.31a2.49,2.49,0,0,0-.74-1.75,2.36,2.36,0,0,0-1.76-.78,2.29,2.29,0,0,0-1.66.7,2.43,2.43,0,0,0-.72,1.83v5.31a.48.48,0,0,1-.2.36.72.72,0,0,1-.46.16.65.65,0,0,1-.46-.16A.48.48,0,0,1,353.14,278.78Z"/>
            <path className="cls-1" d="M362.72,275.64V273.5a3.81,3.81,0,0,1,1-2.73,3.74,3.74,0,0,1,2.88-1,4.21,4.21,0,0,1,2,.43q.81.44.81.87a.65.65,0,0,1-.18.43.53.53,0,0,1-.4.21.75.75,0,0,1-.37-.18,4.26,4.26,0,0,0-.71-.35,2.85,2.85,0,0,0-1.1-.19,2.61,2.61,0,0,0-1.95.68,2.55,2.55,0,0,0-.67,1.89v2.12a2.57,2.57,0,0,0,.67,1.91,2.64,2.64,0,0,0,2,.68,3.27,3.27,0,0,0,1.15-.18,3.92,3.92,0,0,0,.72-.37.69.69,0,0,1,.39-.19.57.57,0,0,1,.43.2.67.67,0,0,1,.17.44c0,.31-.28.6-.84.89a4.51,4.51,0,0,1-2.08.43,3.51,3.51,0,0,1-3.88-3.8Z"/>
            <path className="cls-1" d="M370.82,275.64V273.4a3.48,3.48,0,0,1,1.12-2.59,3.7,3.7,0,0,1,2.7-1.09,3.74,3.74,0,0,1,2.63,1,3.43,3.43,0,0,1,1.11,2.6,2.68,2.68,0,0,1-.13.9.84.84,0,0,1-.42.48,2.37,2.37,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0h-4.52v.74a2.32,2.32,0,0,0,.85,1.89,3.29,3.29,0,0,0,2.19.71,3.33,3.33,0,0,0,1.19-.2,3.47,3.47,0,0,0,.78-.4.84.84,0,0,1,.43-.2.51.51,0,0,1,.39.21.69.69,0,0,1,.17.41c0,.28-.29.57-.86.87a4.61,4.61,0,0,1-2.14.45,4.53,4.53,0,0,1-3.1-1.05A3.48,3.48,0,0,1,370.82,275.64Zm1.3-1.64h4a1.62,1.62,0,0,0,.8-.13c.13-.08.2-.27.2-.55a2.47,2.47,0,0,0-.71-1.74,2.34,2.34,0,0,0-1.79-.76,2.41,2.41,0,0,0-1.77.72,2.27,2.27,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M384,275.64v-2.18a3.57,3.57,0,0,1,1.12-2.63,3.63,3.63,0,0,1,2.66-1.11,3.76,3.76,0,0,1,2.68,1.1,3.53,3.53,0,0,1,1.14,2.64v2.18a3.93,3.93,0,0,1-3.82,3.8,3.6,3.6,0,0,1-2.64-1.15A3.66,3.66,0,0,1,384,275.64Zm1.3,0a2.54,2.54,0,0,0,.73,1.8,2.28,2.28,0,0,0,1.75.79,2.36,2.36,0,0,0,1.78-.79,2.55,2.55,0,0,0,.74-1.8v-2.16a2.44,2.44,0,0,0-.75-1.75,2.38,2.38,0,0,0-1.77-.78,2.3,2.3,0,0,0-1.75.78,2.46,2.46,0,0,0-.73,1.75Z"/>
            <path className="cls-1" d="M393.7,278.8V267.69a3.6,3.6,0,0,1,1.06-2.6,3.35,3.35,0,0,1,2.5-1.09h.5a.5.5,0,0,1,.4.19.67.67,0,0,1,.16.43.61.61,0,0,1-.16.42.52.52,0,0,1-.4.18h-.34a2.3,2.3,0,0,0-1.71.71,2.4,2.4,0,0,0-.71,1.69v2.22h2.86a.41.41,0,0,1,.33.16.62.62,0,0,1,.13.37.69.69,0,0,1-.13.41.41.41,0,0,1-.33.16H395v7.86a.42.42,0,0,1-.2.36.87.87,0,0,1-.48.14.7.7,0,0,1-.44-.14A.43.43,0,0,1,393.7,278.8Z"/>
            <path className="cls-1" d="M403.26,277.3a.8.8,0,0,1,.19-.49.5.5,0,0,1,.41-.25.68.68,0,0,1,.4.26l.56.58a2.58,2.58,0,0,0,.95.57,4.08,4.08,0,0,0,1.51.25,3.18,3.18,0,0,0,2.2-.71,2.63,2.63,0,0,0,.78-2.05,3,3,0,0,0-.37-1.53,2.76,2.76,0,0,0-.95-1,7.64,7.64,0,0,0-1.28-.64l-1.44-.56a6.52,6.52,0,0,1-1.29-.67A2.92,2.92,0,0,1,404,270a3.57,3.57,0,0,1-.37-1.69,3.86,3.86,0,0,1,.39-1.8,2.77,2.77,0,0,1,1.05-1.14,5.19,5.19,0,0,1,1.35-.55,6.19,6.19,0,0,1,1.45-.17,7.47,7.47,0,0,1,2.29.35c.71.23,1.07.52,1.07.87a.91.91,0,0,1-.17.49.49.49,0,0,1-.41.25,1.26,1.26,0,0,1-.46-.18,5.47,5.47,0,0,0-.95-.38,4.93,4.93,0,0,0-1.39-.18,3.69,3.69,0,0,0-2.08.54,2,2,0,0,0-.82,1.78,2.15,2.15,0,0,0,.49,1.44,2.66,2.66,0,0,0,1.23.84c.49.17,1,.38,1.6.62s1.1.5,1.6.77a3.23,3.23,0,0,1,1.23,1.3,4.55,4.55,0,0,1,.49,2.19,3.94,3.94,0,0,1-1.14,3.08,4.45,4.45,0,0,1-3.08,1,5.41,5.41,0,0,1-2.92-.74C403.65,278.2,403.26,277.74,403.26,277.3Z"/>
            <path className="cls-1" d="M413.32,275.64V273.4a3.48,3.48,0,0,1,1.12-2.59,3.7,3.7,0,0,1,2.7-1.09,3.74,3.74,0,0,1,2.63,1,3.43,3.43,0,0,1,1.11,2.6,2.68,2.68,0,0,1-.13.9.84.84,0,0,1-.42.48,2.37,2.37,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0h-4.52v.74a2.32,2.32,0,0,0,.85,1.89,3.29,3.29,0,0,0,2.19.71,3.33,3.33,0,0,0,1.19-.2,3.47,3.47,0,0,0,.78-.4.84.84,0,0,1,.43-.2.51.51,0,0,1,.39.21.69.69,0,0,1,.17.41c0,.28-.29.57-.86.87a4.61,4.61,0,0,1-2.14.45,4.53,4.53,0,0,1-3.1-1.05A3.48,3.48,0,0,1,413.32,275.64Zm1.3-1.64h4a1.62,1.62,0,0,0,.8-.13c.13-.08.2-.27.2-.55a2.47,2.47,0,0,0-.71-1.74,2.34,2.34,0,0,0-1.79-.76,2.41,2.41,0,0,0-1.77.72,2.27,2.27,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M422.48,275.64V273.4a3.48,3.48,0,0,1,1.12-2.59,3.7,3.7,0,0,1,2.7-1.09,3.74,3.74,0,0,1,2.63,1,3.43,3.43,0,0,1,1.11,2.6,2.68,2.68,0,0,1-.13.9.84.84,0,0,1-.42.48,2.37,2.37,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0h-4.52v.74a2.32,2.32,0,0,0,.85,1.89,3.29,3.29,0,0,0,2.19.71,3.33,3.33,0,0,0,1.19-.2,3.47,3.47,0,0,0,.78-.4.84.84,0,0,1,.43-.2.51.51,0,0,1,.39.21.69.69,0,0,1,.17.41c0,.28-.29.57-.86.87a4.61,4.61,0,0,1-2.14.45,4.53,4.53,0,0,1-3.1-1.05A3.48,3.48,0,0,1,422.48,275.64Zm1.3-1.64h4a1.62,1.62,0,0,0,.8-.13c.13-.08.2-.27.2-.55a2.47,2.47,0,0,0-.71-1.74,2.34,2.34,0,0,0-1.79-.76,2.41,2.41,0,0,0-1.77.72,2.27,2.27,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M432.06,278.6V265.36a.66.66,0,0,1,.2-.48.69.69,0,0,1,.5-.2h3.8q4.6,0,4.6,4.08a4.17,4.17,0,0,1-.76,2.66,3.67,3.67,0,0,1-2.06,1.28l2.8,5.28a.61.61,0,0,1,.08.3,1,1,0,0,1-.41.74,1.31,1.31,0,0,1-.87.34,1,1,0,0,1-.84-.62L436.18,273h-2v5.6a.6.6,0,0,1-.31.51,1.39,1.39,0,0,1-.75.19,1.35,1.35,0,0,1-.73-.19A.58.58,0,0,1,432.06,278.6Zm2.1-7.28h2.4c1.65,0,2.48-.79,2.48-2.38s-.83-2.38-2.48-2.38h-2.4Z"/>
            <path className="cls-1" d="M443.24,278.6V265.36a.62.62,0,0,1,.2-.48.69.69,0,0,1,.5-.2h3.8c3.06,0,4.6,1.36,4.6,4.08a4.23,4.23,0,0,1-.76,2.66,3.67,3.67,0,0,1-2.06,1.28l2.8,5.28a.61.61,0,0,1,.08.3,1,1,0,0,1-.41.74,1.35,1.35,0,0,1-.87.34,1,1,0,0,1-.84-.62L447.36,273h-2v5.6a.6.6,0,0,1-.31.51,1.39,1.39,0,0,1-.75.19,1.32,1.32,0,0,1-.73-.19A.58.58,0,0,1,443.24,278.6Zm2.1-7.28h2.4c1.65,0,2.48-.79,2.48-2.38s-.83-2.38-2.48-2.38h-2.4Z"/>
            <path className="cls-1" d="M454.42,278.6V265.36a.54.54,0,0,1,.31-.51,1.54,1.54,0,0,1,.75-.17,1.6,1.6,0,0,1,.75.17.55.55,0,0,1,.31.51V278.6a.6.6,0,0,1-.31.51,1.56,1.56,0,0,1-1.49,0A.57.57,0,0,1,454.42,278.6Z"/>
            <path className="cls-1" d="M463.12,266.79a.77.77,0,0,1-.26-.59.73.73,0,0,1,.26-.58.89.89,0,0,1,.64-.24.85.85,0,0,1,.61.24.79.79,0,0,1,.25.58.83.83,0,0,1-.25.59.82.82,0,0,1-.61.25A.86.86,0,0,1,463.12,266.79Zm0,12v-8.46a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.82.82,0,0,1,.47.14.43.43,0,0,1,.19.34v8.46a.48.48,0,0,1-.2.36.72.72,0,0,1-.46.16.64.64,0,0,1-.45-.16A.47.47,0,0,1,463.12,278.78Z"/>
            <path className="cls-1" d="M466.34,278a.65.65,0,0,1,.14-.4.4.4,0,0,1,.32-.2,1,1,0,0,1,.44.23,4.08,4.08,0,0,0,.86.47,3.38,3.38,0,0,0,1.34.24,2.49,2.49,0,0,0,1.56-.4,1.27,1.27,0,0,0,.5-1,1.43,1.43,0,0,0-.51-1.15,3.44,3.44,0,0,0-1.23-.65l-1.45-.44a2.72,2.72,0,0,1-1.24-.77,2.09,2.09,0,0,1-.51-1.47,2.64,2.64,0,0,1,.79-1.91,3.15,3.15,0,0,1,2.37-.81,5.25,5.25,0,0,1,1.83.31c.56.21.85.43.85.67a.88.88,0,0,1-.16.44.47.47,0,0,1-.38.24,5.24,5.24,0,0,1-.74-.28,3.58,3.58,0,0,0-1.38-.28,2.12,2.12,0,0,0-1.5.47,1.4,1.4,0,0,0-.5,1.06,1.11,1.11,0,0,0,.52,1,4.75,4.75,0,0,0,1.24.57l1.46.43a2.33,2.33,0,0,1,1.24.87,2.69,2.69,0,0,1,.5,1.68,2.32,2.32,0,0,1-.85,1.89,3.68,3.68,0,0,1-2.37.69,4.84,4.84,0,0,1-2.23-.47C466.64,278.66,466.34,278.33,466.34,278Z"/>
            <path className="cls-1" d="M31,311.6v-8.1a.58.58,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.14,1.14,0,0,1,.66.19.58.58,0,0,1,.28.5v.73a2.84,2.84,0,0,1,2.66-1.54,2.38,2.38,0,0,1,1.57.56,3.44,3.44,0,0,1,1,1.46,3.75,3.75,0,0,1,1.31-1.51,3.16,3.16,0,0,1,1.71-.51,3.3,3.3,0,0,1,2.45,1.07,3.94,3.94,0,0,1,1,2.87v5a.62.62,0,0,1-.32.48,1.24,1.24,0,0,1-.74.22,1.2,1.2,0,0,1-.72-.22.61.61,0,0,1-.32-.48v-5a2.07,2.07,0,0,0-.56-1.53,1.8,1.8,0,0,0-1.3-.56,1.84,1.84,0,0,0-1.31.56,2,2,0,0,0-.57,1.49v5a.54.54,0,0,1-.31.51,1.6,1.6,0,0,1-.75.17,1.53,1.53,0,0,1-.72-.17.55.55,0,0,1-.32-.51v-5a2,2,0,0,0-.55-1.47,1.73,1.73,0,0,0-1.31-.58,1.79,1.79,0,0,0-1.31.56,2,2,0,0,0-.55,1.51v5a.61.61,0,0,1-.32.48,1.24,1.24,0,0,1-.74.22,1.2,1.2,0,0,1-.74-.22A.62.62,0,0,1,31,311.6Z"/>
            <path className="cls-1" d="M46.8,308.52v-5a.6.6,0,0,1,.32-.49,1.31,1.31,0,0,1,.74-.21,1.28,1.28,0,0,1,.73.21.58.58,0,0,1,.31.49v5a2,2,0,0,0,.61,1.44,1.94,1.94,0,0,0,2.87,0,2,2,0,0,0,.6-1.45v-5a.56.56,0,0,1,.31-.48,1.4,1.4,0,0,1,.75-.2,1.28,1.28,0,0,1,.73.2.58.58,0,0,1,.29.48v5a3.74,3.74,0,0,1-1.22,2.76,4.17,4.17,0,0,1-5.81,0A3.72,3.72,0,0,1,46.8,308.52Z"/>
            <path className="cls-1" d="M57.16,309.18v-10a.57.57,0,0,1,.32-.48,1.34,1.34,0,0,1,.72-.2,1.4,1.4,0,0,1,.73.2.56.56,0,0,1,.33.48v3.66H61.7a.51.51,0,0,1,.42.23,1,1,0,0,1,0,1.1.51.51,0,0,1-.42.23H59.26v4.8a1.37,1.37,0,0,0,.31,1,1.56,1.56,0,0,0,1.09.3h.7a.87.87,0,0,1,.66.26A1,1,0,0,1,62,312a.87.87,0,0,1-.66.26h-.7Q57.17,312.3,57.16,309.18Z"/>
            <path className="cls-1" d="M63.68,308.52v-5A.6.6,0,0,1,64,303a1.31,1.31,0,0,1,.74-.21,1.28,1.28,0,0,1,.73.21.58.58,0,0,1,.31.49v5a2,2,0,0,0,.61,1.44,1.94,1.94,0,0,0,2.87,0,2,2,0,0,0,.6-1.45v-5a.56.56,0,0,1,.31-.48,1.4,1.4,0,0,1,.75-.2,1.28,1.28,0,0,1,.73.2.58.58,0,0,1,.29.48v5a3.74,3.74,0,0,1-1.22,2.76,4.17,4.17,0,0,1-5.81,0A3.72,3.72,0,0,1,63.68,308.52Z"/>
            <path className="cls-1" d="M73.46,309.82a2.3,2.3,0,0,1,1.2-2.23,7.66,7.66,0,0,1,3.48-.63H79v-.37a2.47,2.47,0,0,0-.49-1.55,1.75,1.75,0,0,0-1.49-.66,3.29,3.29,0,0,0-1.51.33,4.76,4.76,0,0,1-.71.33.63.63,0,0,1-.55-.33,1.32,1.32,0,0,1-.21-.73c0-.35.32-.65,1-.9a5.65,5.65,0,0,1,2-.38,3.89,3.89,0,0,1,3,1.11,4,4,0,0,1,1,2.77v5a.6.6,0,0,1-.29.49,1.21,1.21,0,0,1-.73.21,1.1,1.1,0,0,1-.68-.21.59.59,0,0,1-.28-.49v-.7a3.92,3.92,0,0,1-3,1.56,2.76,2.76,0,0,1-1.91-.68A2.51,2.51,0,0,1,73.46,309.82Zm2.12-.26a1.25,1.25,0,0,0,.36,1,1.33,1.33,0,0,0,.94.33,2.1,2.1,0,0,0,1.45-.64,1.86,1.86,0,0,0,.69-1.36v-.74h-.5a11.54,11.54,0,0,0-1.47.08,2.1,2.1,0,0,0-1,.42A1.12,1.12,0,0,0,75.58,309.56Z"/>
            <path className="cls-1" d="M83.44,311.6V297.86a.58.58,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.39,1.39,0,0,1,.75.19.56.56,0,0,1,.31.49V311.6a.62.62,0,0,1-.32.48,1.24,1.24,0,0,1-.74.22,1.2,1.2,0,0,1-.74-.22A.62.62,0,0,1,83.44,311.6Z"/>
            <path className="cls-1" d="M92.1,311.6V297.86a.58.58,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.39,1.39,0,0,1,.75.19.56.56,0,0,1,.31.49V311.6a.62.62,0,0,1-.32.48,1.24,1.24,0,0,1-.74.22,1.2,1.2,0,0,1-.74-.22A.62.62,0,0,1,92.1,311.6Z"/>
            <path className="cls-1" d="M96.22,308.48v-1.9a3.65,3.65,0,0,1,1.22-2.73,4.32,4.32,0,0,1,5.75-.11,3.36,3.36,0,0,1,1.17,2.63,3.06,3.06,0,0,1-.14,1,.89.89,0,0,1-.44.52,1.81,1.81,0,0,1-.56.2,4.2,4.2,0,0,1-.68,0H98.32v.46a2,2,0,0,0,.72,1.61,2.9,2.9,0,0,0,1.88.59,3.35,3.35,0,0,0,1.55-.38,3.25,3.25,0,0,1,.89-.39.7.7,0,0,1,.58.33,1,1,0,0,1,.24.62c0,.33-.32.66-1,1a5.12,5.12,0,0,1-2.36.5,5,5,0,0,1-3.34-1.1A3.57,3.57,0,0,1,96.22,308.48Zm2.1-1.7h3.32a1,1,0,0,0,.59-.12.57.57,0,0,0,.15-.47,1.77,1.77,0,0,0-.58-1.34,2,2,0,0,0-1.44-.55,2.11,2.11,0,0,0-1.44.52,1.69,1.69,0,0,0-.6,1.33Z"/>
            <path className="cls-1" d="M105.46,309.82a2.3,2.3,0,0,1,1.2-2.23,7.66,7.66,0,0,1,3.48-.63H111v-.37a2.47,2.47,0,0,0-.49-1.55,1.75,1.75,0,0,0-1.49-.66,3.29,3.29,0,0,0-1.51.33,4.76,4.76,0,0,1-.71.33.63.63,0,0,1-.55-.33,1.32,1.32,0,0,1-.21-.73c0-.35.32-.65,1-.9a5.65,5.65,0,0,1,2-.38,3.89,3.89,0,0,1,3,1.11,4,4,0,0,1,1,2.77v5a.6.6,0,0,1-.29.49,1.21,1.21,0,0,1-.73.21,1.1,1.1,0,0,1-.68-.21.59.59,0,0,1-.28-.49v-.7a3.92,3.92,0,0,1-3,1.56,2.76,2.76,0,0,1-1.91-.68A2.51,2.51,0,0,1,105.46,309.82Zm2.12-.26a1.25,1.25,0,0,0,.36,1,1.33,1.33,0,0,0,.94.33,2.1,2.1,0,0,0,1.45-.64,1.86,1.86,0,0,0,.69-1.36v-.74h-.5a11.54,11.54,0,0,0-1.47.08,2.1,2.1,0,0,0-1,.42A1.12,1.12,0,0,0,107.58,309.56Z"/>
            <path className="cls-1" d="M115.26,311.6v-8.1a.58.58,0,0,1,.29-.49,1.28,1.28,0,0,1,.73-.19,1.16,1.16,0,0,1,.68.19.58.58,0,0,1,.28.5v.89a3.19,3.19,0,0,1,1-1.2,2.53,2.53,0,0,1,1.51-.5h.64a.73.73,0,0,1,.56.27,1,1,0,0,1,.24.66,1,1,0,0,1-.24.67.71.71,0,0,1-.56.28h-.64a2.2,2.2,0,0,0-1.69.82,3,3,0,0,0-.73,2.11v4.09a.61.61,0,0,1-.32.48,1.28,1.28,0,0,1-.76.22,1.15,1.15,0,0,1-.73-.22A.63.63,0,0,1,115.26,311.6Z"/>
            <path className="cls-1" d="M122.46,311.6v-8.1a.58.58,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.11,1.11,0,0,1,.67.19.6.6,0,0,1,.27.5v.73a2.7,2.7,0,0,1,1-1.1,3.08,3.08,0,0,1,1.65-.44,3.18,3.18,0,0,1,2.45,1.16,4,4,0,0,1,1,2.78v5a.58.58,0,0,1-.31.51,1.39,1.39,0,0,1-.75.19,1.34,1.34,0,0,1-.72-.19.59.59,0,0,1-.32-.51v-5a2,2,0,0,0-.6-1.43,1.88,1.88,0,0,0-2.76-.07,2,2,0,0,0-.58,1.5v5a.61.61,0,0,1-.32.48,1.24,1.24,0,0,1-.74.22,1.2,1.2,0,0,1-.74-.22A.62.62,0,0,1,122.46,311.6Z"/>
            <path className="cls-1" d="M132.54,299.34a1,1,0,0,1,.36-.78,1.32,1.32,0,0,1,1.72,0,1,1,0,0,1,.36.78,1,1,0,0,1-.36.77,1.29,1.29,0,0,1-1.72,0A1,1,0,0,1,132.54,299.34Zm.18,12.26v-8.1A.58.58,0,0,1,133,303a1.3,1.3,0,0,1,.75-.19,1.36,1.36,0,0,1,.76.19.57.57,0,0,1,.3.49v8.1a.61.61,0,0,1-.31.48,1.25,1.25,0,0,1-.75.22,1.2,1.2,0,0,1-.74-.22A.62.62,0,0,1,132.72,311.6Z"/>
            <path className="cls-1" d="M137,311.6v-8.1a.58.58,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.11,1.11,0,0,1,.67.19.6.6,0,0,1,.27.5v.73a2.7,2.7,0,0,1,1-1.1,3.08,3.08,0,0,1,1.65-.44,3.18,3.18,0,0,1,2.45,1.16,4,4,0,0,1,1,2.78v5a.58.58,0,0,1-.31.51,1.39,1.39,0,0,1-.75.19,1.34,1.34,0,0,1-.72-.19.59.59,0,0,1-.32-.51v-5a2,2,0,0,0-.6-1.43,1.88,1.88,0,0,0-2.76-.07,2,2,0,0,0-.58,1.5v5a.61.61,0,0,1-.32.48,1.24,1.24,0,0,1-.74.22,1.2,1.2,0,0,1-.74-.22A.62.62,0,0,1,137,311.6Z"/>
            <path className="cls-1" d="M146.56,314.09a2.35,2.35,0,0,1,1.56-2.17,1.37,1.37,0,0,1-.88-1.33,1.84,1.84,0,0,1,.94-1.53,3,3,0,0,1-1.2-2.52V306a3,3,0,0,1,1-2.36,3.9,3.9,0,0,1,2.69-.92,3.82,3.82,0,0,1,2.32.68c.39-.89.82-1.34,1.3-1.34a.88.88,0,0,1,.62.23.68.68,0,0,1,.24.49.55.55,0,0,1-.34.5,2.29,2.29,0,0,0-1,.8,3,3,0,0,1,.64,1.92v.56a3,3,0,0,1-1.06,2.38,3.93,3.93,0,0,1-2.72.94,3.7,3.7,0,0,1-1.38-.24.71.71,0,0,0-.38.56c0,.27.21.47.63.59a8.83,8.83,0,0,0,1.52.28c.59.07,1.19.17,1.78.3a3,3,0,0,1,1.52.82,2.2,2.2,0,0,1,.63,1.65,2.47,2.47,0,0,1-1.19,2.23,5.55,5.55,0,0,1-3,.75,5.8,5.8,0,0,1-3-.74A2.21,2.21,0,0,1,146.56,314.09Zm2-.23a1.35,1.35,0,0,0,.63,1.17,2.88,2.88,0,0,0,1.63.43,2.71,2.71,0,0,0,1.58-.42,1.36,1.36,0,0,0,.6-1.16,1.12,1.12,0,0,0-.28-.78,1.74,1.74,0,0,0-.83-.46,6.7,6.7,0,0,0-.94-.2,10.33,10.33,0,0,0-1.05-.09C149,312.68,148.54,313.19,148.54,313.86Zm.48-7.34a2.16,2.16,0,0,0,.46,1.44,1.55,1.55,0,0,0,1.26.56A1.53,1.53,0,0,0,152,308a2.14,2.14,0,0,0,.46-1.43V306a2.08,2.08,0,0,0-.46-1.39,1.55,1.55,0,0,0-1.26-.55,1.53,1.53,0,0,0-1.24.55A2.08,2.08,0,0,0,149,306Z"/>
            <path className="cls-1" d="M156.1,311.39a1,1,0,0,1,.35-.8,1.2,1.2,0,0,1,.86-.33,1.25,1.25,0,0,1,.86.33,1,1,0,0,1,.37.8,14.26,14.26,0,0,1-1,2.51.5.5,0,0,1-.48.28,1,1,0,0,1-.49-.14.44.44,0,0,1-.23-.39.43.43,0,0,1,.06-.24l.44-1A1,1,0,0,1,156.1,311.39Z"/>
            <path className="cls-1" d="M163.64,309.82a2.3,2.3,0,0,1,1.2-2.23,7.66,7.66,0,0,1,3.48-.63h.88v-.37a2.47,2.47,0,0,0-.49-1.55,1.75,1.75,0,0,0-1.49-.66,3.29,3.29,0,0,0-1.51.33,4.76,4.76,0,0,1-.71.33.63.63,0,0,1-.55-.33,1.32,1.32,0,0,1-.21-.73c0-.35.32-.65,1-.9a5.65,5.65,0,0,1,2-.38,3.89,3.89,0,0,1,3.05,1.11,4,4,0,0,1,1,2.77v5a.6.6,0,0,1-.29.49,1.21,1.21,0,0,1-.73.21,1.1,1.1,0,0,1-.68-.21.59.59,0,0,1-.28-.49v-.7a3.94,3.94,0,0,1-3,1.56,2.76,2.76,0,0,1-1.91-.68A2.51,2.51,0,0,1,163.64,309.82Zm2.12-.26a1.25,1.25,0,0,0,.36,1,1.33,1.33,0,0,0,.94.33,2.1,2.1,0,0,0,1.45-.64,1.86,1.86,0,0,0,.69-1.36v-.74h-.5a11.54,11.54,0,0,0-1.47.08,2.1,2.1,0,0,0-1,.42A1.12,1.12,0,0,0,165.76,309.56Z"/>
            <path className="cls-1" d="M172.84,310.86a1.12,1.12,0,0,1,.21-.62.61.61,0,0,1,.51-.32,1,1,0,0,1,.51.23,4.23,4.23,0,0,0,.87.48,3.38,3.38,0,0,0,1.31.23c1.14,0,1.71-.36,1.71-1.09a1,1,0,0,0-.5-.91,4.21,4.21,0,0,0-1.21-.53c-.47-.12-.94-.27-1.41-.43a2.67,2.67,0,0,1-1.2-.82,2.27,2.27,0,0,1-.5-1.51,2.76,2.76,0,0,1,.84-2,3.47,3.47,0,0,1,2.58-.85,5.91,5.91,0,0,1,2.07.35c.62.23.93.51.93.83a1.31,1.31,0,0,1-.23.65.65.65,0,0,1-.57.37,4.08,4.08,0,0,1-.82-.3,3.4,3.4,0,0,0-1.34-.3,1.78,1.78,0,0,0-1.21.36,1.07,1.07,0,0,0-.41.81.86.86,0,0,0,.5.76,4.79,4.79,0,0,0,1.22.46,12.29,12.29,0,0,1,1.42.45,2.65,2.65,0,0,1,1.19.9,2.69,2.69,0,0,1,.49,1.65,2.46,2.46,0,0,1-.92,2,4.07,4.07,0,0,1-2.6.74,5.45,5.45,0,0,1-2.43-.5C173.18,311.63,172.84,311.26,172.84,310.86Z"/>
            <path className="cls-1" d="M185.5,309.18v-10a.57.57,0,0,1,.32-.48,1.34,1.34,0,0,1,.72-.2,1.4,1.4,0,0,1,.73.2.56.56,0,0,1,.33.48v3.66H190a.51.51,0,0,1,.42.23,1,1,0,0,1,0,1.1.51.51,0,0,1-.42.23H187.6v4.8a1.37,1.37,0,0,0,.31,1,1.56,1.56,0,0,0,1.09.3h.7a.87.87,0,0,1,.66.26,1,1,0,0,1,0,1.28.87.87,0,0,1-.66.26H189Q185.51,312.3,185.5,309.18Z"/>
            <path className="cls-1" d="M192.12,311.6V297.88a.58.58,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.39,1.39,0,0,1,.75.19.56.56,0,0,1,.31.49v6.36a2.73,2.73,0,0,1,1-1.1,2.83,2.83,0,0,1,1.57-.44,3.18,3.18,0,0,1,2.45,1.16,4,4,0,0,1,1,2.78v5a.59.59,0,0,1-.32.51,1.38,1.38,0,0,1-.74.19,1.34,1.34,0,0,1-.72-.19.59.59,0,0,1-.32-.51v-5a2,2,0,0,0-.6-1.43,1.88,1.88,0,0,0-2.76-.07,2,2,0,0,0-.58,1.5v5a.61.61,0,0,1-.32.48,1.24,1.24,0,0,1-.74.22,1.2,1.2,0,0,1-.74-.22A.62.62,0,0,1,192.12,311.6Z"/>
            <path className="cls-1" d="M202,308.48v-1.9a3.65,3.65,0,0,1,1.22-2.73,4.32,4.32,0,0,1,5.75-.11,3.36,3.36,0,0,1,1.17,2.63,3.06,3.06,0,0,1-.14,1,.89.89,0,0,1-.44.52,1.81,1.81,0,0,1-.56.2,4.2,4.2,0,0,1-.68,0h-4.22v.46a2,2,0,0,0,.72,1.61,2.9,2.9,0,0,0,1.88.59,3.35,3.35,0,0,0,1.55-.38,3.25,3.25,0,0,1,.89-.39.7.7,0,0,1,.58.33,1,1,0,0,1,.24.62c0,.33-.32.66-1,1a5.12,5.12,0,0,1-2.36.5,5,5,0,0,1-3.34-1.1A3.57,3.57,0,0,1,202,308.48Zm2.1-1.7h3.32a1,1,0,0,0,.59-.12.57.57,0,0,0,.15-.47,1.77,1.77,0,0,0-.58-1.34,2,2,0,0,0-1.44-.55,2.11,2.11,0,0,0-1.44.52,1.69,1.69,0,0,0-.6,1.33Z"/>
            <path className="cls-1" d="M215.5,308.48v-1.84a4,4,0,0,1,1.05-2.78,3.32,3.32,0,0,1,2.55-1.16,3,3,0,0,1,2.68,1.54v-.73a.58.58,0,0,1,.28-.5,1.2,1.2,0,0,1,.7-.19,1.29,1.29,0,0,1,.72.19.57.57,0,0,1,.3.49v12.6a.59.59,0,0,1-.3.49,1.27,1.27,0,0,1-.74.21,1.32,1.32,0,0,1-.75-.21.58.58,0,0,1-.31-.49v-5.22a3,3,0,0,1-1.07,1.11,2.92,2.92,0,0,1-1.59.47,3.19,3.19,0,0,1-2.46-1.21A4.07,4.07,0,0,1,215.5,308.48Zm2.1,0a2.06,2.06,0,0,0,.62,1.46,1.89,1.89,0,0,0,1.42.65,2,2,0,0,0,1.42-.61,1.65,1.65,0,0,0,.62-1v-2.28a2,2,0,0,0-3.46-1.43,2,2,0,0,0-.62,1.43Z"/>
            <path className="cls-1" d="M225.92,308.52v-5a.6.6,0,0,1,.32-.49,1.31,1.31,0,0,1,.74-.21,1.28,1.28,0,0,1,.73.21.58.58,0,0,1,.31.49v5a2,2,0,0,0,.61,1.44,1.94,1.94,0,0,0,2.87,0,2,2,0,0,0,.6-1.45v-5a.56.56,0,0,1,.31-.48,1.4,1.4,0,0,1,.75-.2,1.28,1.28,0,0,1,.73.2.58.58,0,0,1,.29.48v5a3.74,3.74,0,0,1-1.22,2.76,4.17,4.17,0,0,1-5.81,0A3.72,3.72,0,0,1,225.92,308.52Z"/>
            <path className="cls-1" d="M235.7,309.82a2.3,2.3,0,0,1,1.2-2.23,7.66,7.66,0,0,1,3.48-.63h.88v-.37a2.47,2.47,0,0,0-.49-1.55,1.74,1.74,0,0,0-1.49-.66,3.29,3.29,0,0,0-1.51.33,4.76,4.76,0,0,1-.71.33.63.63,0,0,1-.55-.33,1.32,1.32,0,0,1-.21-.73c0-.35.32-.65,1-.9a5.65,5.65,0,0,1,2-.38,3.89,3.89,0,0,1,3.05,1.11,4,4,0,0,1,1,2.77v5a.6.6,0,0,1-.29.49,1.21,1.21,0,0,1-.73.21,1.1,1.1,0,0,1-.68-.21.59.59,0,0,1-.28-.49v-.7a3.92,3.92,0,0,1-3,1.56,2.76,2.76,0,0,1-1.91-.68A2.51,2.51,0,0,1,235.7,309.82Zm2.12-.26a1.25,1.25,0,0,0,.36,1,1.33,1.33,0,0,0,.94.33,2.1,2.1,0,0,0,1.45-.64,1.86,1.86,0,0,0,.69-1.36v-.74h-.5a11.54,11.54,0,0,0-1.47.08,2.1,2.1,0,0,0-1,.42A1.12,1.12,0,0,0,237.82,309.56Z"/>
            <path className="cls-1" d="M245.68,311.6V297.86a.58.58,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.39,1.39,0,0,1,.75.19.56.56,0,0,1,.31.49V311.6a.62.62,0,0,1-.32.48,1.24,1.24,0,0,1-.74.22,1.2,1.2,0,0,1-.74-.22A.62.62,0,0,1,245.68,311.6Z"/>
            <path className="cls-1" d="M250,299.34a1,1,0,0,1,.36-.78,1.32,1.32,0,0,1,1.72,0,1,1,0,0,1,.36.78,1,1,0,0,1-.36.77,1.29,1.29,0,0,1-1.72,0A1,1,0,0,1,250,299.34Zm.18,12.26v-8.1a.58.58,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.36,1.36,0,0,1,.76.19.57.57,0,0,1,.3.49v8.1a.61.61,0,0,1-.31.48,1.25,1.25,0,0,1-.75.22,1.2,1.2,0,0,1-.74-.22A.62.62,0,0,1,250.16,311.6Z"/>
            <path className="cls-1" d="M254.44,309.18v-10a.57.57,0,0,1,.32-.48,1.34,1.34,0,0,1,.72-.2,1.4,1.4,0,0,1,.73.2.56.56,0,0,1,.33.48v3.66H259a.51.51,0,0,1,.42.23,1,1,0,0,1,0,1.1.51.51,0,0,1-.42.23h-2.44v4.8a1.37,1.37,0,0,0,.31,1,1.56,1.56,0,0,0,1.09.3h.7a.87.87,0,0,1,.66.26,1,1,0,0,1,0,1.28.87.87,0,0,1-.66.26h-.7Q254.45,312.3,254.44,309.18Z"/>
            <path className="cls-1" d="M260.14,315.9c0-.6.19-.9.58-.9a2.59,2.59,0,0,0,1.78-.86,3.93,3.93,0,0,0,1.08-2.42L260.22,304a.47.47,0,0,1-.08-.28.86.86,0,0,1,.46-.69,1.61,1.61,0,0,1,.92-.31.61.61,0,0,1,.64.38l2.52,6.5,2.16-6.5a.62.62,0,0,1,.6-.38,1.71,1.71,0,0,1,.91.31.87.87,0,0,1,.49.73.45.45,0,0,1,0,.24l-3,7.82-.34.9-.37.86a8.13,8.13,0,0,1-.43.88l-.48.74a2.65,2.65,0,0,1-.6.69l-.68.48a2.43,2.43,0,0,1-.84.36,4.39,4.39,0,0,1-1,.11C260.47,316.8,260.14,316.5,260.14,315.9Z"/>
            <path className="cls-1" d="M274.18,311.6v-8.1a.58.58,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.14,1.14,0,0,1,.66.19.58.58,0,0,1,.28.5v.73a2.84,2.84,0,0,1,2.66-1.54,2.38,2.38,0,0,1,1.57.56,3.44,3.44,0,0,1,1.05,1.46,3.75,3.75,0,0,1,1.31-1.51,3.16,3.16,0,0,1,1.71-.51,3.3,3.3,0,0,1,2.45,1.07,3.94,3.94,0,0,1,1,2.87v5a.62.62,0,0,1-.32.48,1.24,1.24,0,0,1-.74.22,1.2,1.2,0,0,1-.72-.22.61.61,0,0,1-.32-.48v-5a2.07,2.07,0,0,0-.56-1.53,1.8,1.8,0,0,0-1.3-.56,1.84,1.84,0,0,0-1.31.56,2,2,0,0,0-.57,1.49v5a.54.54,0,0,1-.31.51,1.6,1.6,0,0,1-.75.17,1.53,1.53,0,0,1-.72-.17.55.55,0,0,1-.32-.51v-5a2,2,0,0,0-.55-1.47,1.73,1.73,0,0,0-1.31-.58,1.79,1.79,0,0,0-1.31.56,2,2,0,0,0-.55,1.51v5a.61.61,0,0,1-.32.48,1.24,1.24,0,0,1-.74.22,1.2,1.2,0,0,1-.74-.22A.62.62,0,0,1,274.18,311.6Z"/>
            <path className="cls-1" d="M289.46,309.82a2.3,2.3,0,0,1,1.2-2.23,7.66,7.66,0,0,1,3.48-.63H295v-.37a2.47,2.47,0,0,0-.49-1.55,1.75,1.75,0,0,0-1.49-.66,3.29,3.29,0,0,0-1.51.33,4.76,4.76,0,0,1-.71.33.63.63,0,0,1-.55-.33,1.32,1.32,0,0,1-.21-.73c0-.35.32-.65,1-.9a5.65,5.65,0,0,1,2.05-.38,3.89,3.89,0,0,1,3.05,1.11,4,4,0,0,1,1,2.77v5a.6.6,0,0,1-.29.49,1.21,1.21,0,0,1-.73.21,1.1,1.1,0,0,1-.68-.21.59.59,0,0,1-.28-.49v-.7a3.92,3.92,0,0,1-3,1.56,2.76,2.76,0,0,1-1.91-.68A2.51,2.51,0,0,1,289.46,309.82Zm2.12-.26a1.25,1.25,0,0,0,.36,1,1.33,1.33,0,0,0,.94.33,2.1,2.1,0,0,0,1.45-.64,1.86,1.86,0,0,0,.69-1.36v-.74h-.5a11.54,11.54,0,0,0-1.47.08,2.1,2.1,0,0,0-1,.42A1.12,1.12,0,0,0,291.58,309.56Z"/>
            <path className="cls-1" d="M299.28,311.6V297.86a.57.57,0,0,1,.28-.49,1.27,1.27,0,0,1,.74-.19,1.42,1.42,0,0,1,.77.19.56.56,0,0,1,.31.49v8.9l4.18-3.84a.6.6,0,0,1,.44-.18,1,1,0,0,1,.68.32.92.92,0,0,1,.34.68.6.6,0,0,1-.18.44l-2.58,2.3L307.4,311a.65.65,0,0,1,.12.36,1,1,0,0,1-.4.71,1.23,1.23,0,0,1-.78.35.67.67,0,0,1-.56-.3l-2.94-4.34-1.46,1.32v2.5a.62.62,0,0,1-.32.48,1.28,1.28,0,0,1-.76.22,1.15,1.15,0,0,1-.73-.22A.63.63,0,0,1,299.28,311.6Z"/>
            <path className="cls-1" d="M308.68,299.34a1,1,0,0,1,.36-.78,1.32,1.32,0,0,1,1.72,0,1,1,0,0,1,.36.78,1,1,0,0,1-.36.77,1.29,1.29,0,0,1-1.72,0A1,1,0,0,1,308.68,299.34Zm.18,12.26v-8.1a.56.56,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.38,1.38,0,0,1,.76.19.57.57,0,0,1,.3.49v8.1a.61.61,0,0,1-.31.48,1.25,1.25,0,0,1-.75.22,1.2,1.2,0,0,1-.74-.22A.62.62,0,0,1,308.86,311.6Z"/>
            <path className="cls-1" d="M313.16,311.6v-8.1a.56.56,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.13,1.13,0,0,1,.67.19.6.6,0,0,1,.27.5v.73a2.7,2.7,0,0,1,1-1.1,3.08,3.08,0,0,1,1.65-.44,3.18,3.18,0,0,1,2.45,1.16,4.07,4.07,0,0,1,1,2.78v5a.58.58,0,0,1-.31.51,1.39,1.39,0,0,1-.75.19,1.34,1.34,0,0,1-.72-.19.57.57,0,0,1-.32-.51v-5a2,2,0,0,0-.6-1.43,1.88,1.88,0,0,0-2.76-.07,2,2,0,0,0-.58,1.5v5a.61.61,0,0,1-.32.48,1.24,1.24,0,0,1-.74.22,1.2,1.2,0,0,1-.74-.22A.62.62,0,0,1,313.16,311.6Z"/>
            <path className="cls-1" d="M322.7,314.09a2.35,2.35,0,0,1,1.56-2.17,1.37,1.37,0,0,1-.88-1.33,1.84,1.84,0,0,1,.94-1.53,3,3,0,0,1-1.2-2.52V306a3,3,0,0,1,1-2.36,3.88,3.88,0,0,1,2.69-.92,3.84,3.84,0,0,1,2.32.68c.38-.89.82-1.34,1.3-1.34a.88.88,0,0,1,.62.23.68.68,0,0,1,.24.49.57.57,0,0,1-.34.5,2.29,2.29,0,0,0-1,.8,3,3,0,0,1,.64,1.92v.56a3.07,3.07,0,0,1-1.06,2.38,3.93,3.93,0,0,1-2.72.94,3.7,3.7,0,0,1-1.38-.24.69.69,0,0,0-.38.56c0,.27.21.47.63.59a8.65,8.65,0,0,0,1.52.28c.59.07,1.19.17,1.78.3a3,3,0,0,1,1.52.82,2.2,2.2,0,0,1,.63,1.65,2.47,2.47,0,0,1-1.19,2.23,5.58,5.58,0,0,1-3,.75,5.8,5.8,0,0,1-3-.74A2.21,2.21,0,0,1,322.7,314.09Zm2-.23a1.35,1.35,0,0,0,.63,1.17,2.88,2.88,0,0,0,1.63.43,2.71,2.71,0,0,0,1.58-.42,1.36,1.36,0,0,0,.6-1.16,1.12,1.12,0,0,0-.28-.78,1.74,1.74,0,0,0-.83-.46,6.7,6.7,0,0,0-.94-.2,10.33,10.33,0,0,0-1.05-.09C325.13,312.68,324.68,313.19,324.68,313.86Zm.48-7.34a2.16,2.16,0,0,0,.46,1.44,1.69,1.69,0,0,0,2.5,0,2.19,2.19,0,0,0,.46-1.43V306a2.13,2.13,0,0,0-.46-1.39,1.55,1.55,0,0,0-1.26-.55,1.51,1.51,0,0,0-1.24.55,2.08,2.08,0,0,0-.46,1.39Z"/>
            <path className="cls-1" d="M336.66,309.18v-10a.55.55,0,0,1,.32-.48,1.34,1.34,0,0,1,.72-.2,1.43,1.43,0,0,1,.73.2.56.56,0,0,1,.33.48v3.66h2.44a.51.51,0,0,1,.42.23,1,1,0,0,1,0,1.1.51.51,0,0,1-.42.23h-2.44v4.8a1.37,1.37,0,0,0,.31,1,1.54,1.54,0,0,0,1.09.3h.7a.87.87,0,0,1,.66.26,1,1,0,0,1,0,1.28.87.87,0,0,1-.66.26h-.7C337.82,312.3,336.66,311.26,336.66,309.18Z"/>
            <path className="cls-1" d="M343.26,311.6v-8.1a.56.56,0,0,1,.29-.49,1.26,1.26,0,0,1,.73-.19,1.18,1.18,0,0,1,.68.19.61.61,0,0,1,.28.5v.89a3.1,3.1,0,0,1,1-1.2,2.53,2.53,0,0,1,1.51-.5h.64a.73.73,0,0,1,.56.27,1,1,0,0,1,.24.66,1,1,0,0,1-.24.67.71.71,0,0,1-.56.28h-.64a2.2,2.2,0,0,0-1.69.82,3,3,0,0,0-.73,2.11v4.09a.61.61,0,0,1-.32.48,1.28,1.28,0,0,1-.76.22,1.13,1.13,0,0,1-.73-.22A.6.6,0,0,1,343.26,311.6Z"/>
            <path className="cls-1" d="M349.86,309.82a2.3,2.3,0,0,1,1.2-2.23,7.66,7.66,0,0,1,3.48-.63h.88v-.37a2.54,2.54,0,0,0-.49-1.55,1.75,1.75,0,0,0-1.49-.66,3.26,3.26,0,0,0-1.51.33,5.36,5.36,0,0,1-.71.33.63.63,0,0,1-.55-.33,1.32,1.32,0,0,1-.21-.73c0-.35.32-.65,1-.9a5.61,5.61,0,0,1,2.05-.38,3.89,3.89,0,0,1,3,1.11,4,4,0,0,1,1,2.77v5a.6.6,0,0,1-.29.49,1.23,1.23,0,0,1-.73.21,1.08,1.08,0,0,1-.68-.21.59.59,0,0,1-.28-.49v-.7a3.94,3.94,0,0,1-3,1.56,2.74,2.74,0,0,1-1.91-.68A2.51,2.51,0,0,1,349.86,309.82Zm2.12-.26a1.25,1.25,0,0,0,.36,1,1.33,1.33,0,0,0,.94.33,2.11,2.11,0,0,0,1.45-.64,1.86,1.86,0,0,0,.69-1.36v-.74h-.5a11.54,11.54,0,0,0-1.47.08,2.1,2.1,0,0,0-1,.42A1.12,1.12,0,0,0,352,309.56Z"/>
            <path className="cls-1" d="M359.66,311.6v-8.1A.56.56,0,0,1,360,303a1.3,1.3,0,0,1,.75-.19,1.13,1.13,0,0,1,.67.19.6.6,0,0,1,.27.5v.73a2.64,2.64,0,0,1,1-1.1,3.08,3.08,0,0,1,1.65-.44,3.2,3.2,0,0,1,2.45,1.16,4.07,4.07,0,0,1,1,2.78v5a.6.6,0,0,1-.31.51,1.39,1.39,0,0,1-.75.19,1.34,1.34,0,0,1-.72-.19.57.57,0,0,1-.32-.51v-5a2,2,0,0,0-.6-1.43,1.88,1.88,0,0,0-1.42-.64,1.84,1.84,0,0,0-1.34.57,2,2,0,0,0-.58,1.5v5a.61.61,0,0,1-.32.48,1.26,1.26,0,0,1-.74.22,1.18,1.18,0,0,1-.74-.22A.62.62,0,0,1,359.66,311.6Z"/>
            <path className="cls-1" d="M369.32,310.86a1.12,1.12,0,0,1,.21-.62.61.61,0,0,1,.51-.32,1.06,1.06,0,0,1,.51.23,4.39,4.39,0,0,0,.86.48,3.45,3.45,0,0,0,1.31.23c1.15,0,1.72-.36,1.72-1.09a1,1,0,0,0-.5-.91,4.34,4.34,0,0,0-1.21-.53c-.48-.12-1-.27-1.41-.43a2.67,2.67,0,0,1-1.2-.82,2.22,2.22,0,0,1-.5-1.51,2.76,2.76,0,0,1,.84-2,3.47,3.47,0,0,1,2.58-.85,5.91,5.91,0,0,1,2.07.35c.62.23.93.51.93.83a1.31,1.31,0,0,1-.23.65.68.68,0,0,1-.57.37,3.86,3.86,0,0,1-.82-.3,3.44,3.44,0,0,0-1.34-.3,1.77,1.77,0,0,0-1.21.36,1,1,0,0,0-.41.81.84.84,0,0,0,.5.76,4.79,4.79,0,0,0,1.22.46,12.29,12.29,0,0,1,1.42.45,2.65,2.65,0,0,1,1.19.9,2.69,2.69,0,0,1,.49,1.65,2.46,2.46,0,0,1-.92,2,4.08,4.08,0,0,1-2.6.74,5.45,5.45,0,0,1-2.43-.5C369.65,311.63,369.32,311.26,369.32,310.86Z"/>
            <path className="cls-1" d="M378,311.62V301a3.78,3.78,0,0,1,1-2.71,3.29,3.29,0,0,1,2.5-1.11h.92a.74.74,0,0,1,.6.27,1,1,0,0,1,0,1.26.74.74,0,0,1-.6.27H382a1.78,1.78,0,0,0-1.84,1.82v2h2.6a.51.51,0,0,1,.42.23,1,1,0,0,1,0,1.1.51.51,0,0,1-.42.23h-2.6v7.24a.57.57,0,0,1-.32.49,1.45,1.45,0,0,1-.76.19,1.26,1.26,0,0,1-.73-.19A.56.56,0,0,1,378,311.62Z"/>
            <path className="cls-1" d="M383.92,308.48v-1.84a3.71,3.71,0,0,1,1.22-2.76,4,4,0,0,1,2.92-1.18,4.11,4.11,0,0,1,2.9,1.17,3.7,3.7,0,0,1,1.24,2.77v1.84a3.77,3.77,0,0,1-1.25,2.77,4.08,4.08,0,0,1-5.79,0A3.75,3.75,0,0,1,383.92,308.48Zm2.1,0a2.08,2.08,0,0,0,.6,1.46,1.87,1.87,0,0,0,1.44.65,1.89,1.89,0,0,0,1.43-.65,2.09,2.09,0,0,0,.61-1.46v-1.82a2,2,0,0,0-.61-1.43,1.92,1.92,0,0,0-1.43-.64,1.89,1.89,0,0,0-1.44.64,2,2,0,0,0-.6,1.43Z"/>
            <path className="cls-1" d="M394,311.6v-8.1a.56.56,0,0,1,.29-.49,1.26,1.26,0,0,1,.73-.19,1.18,1.18,0,0,1,.68.19.61.61,0,0,1,.28.5v.89a3.1,3.1,0,0,1,1-1.2,2.53,2.53,0,0,1,1.51-.5h.64a.73.73,0,0,1,.56.27,1,1,0,0,1,.24.66,1,1,0,0,1-.24.67.71.71,0,0,1-.56.28h-.64a2.19,2.19,0,0,0-1.69.82,3,3,0,0,0-.73,2.11v4.09a.61.61,0,0,1-.32.48,1.31,1.31,0,0,1-.76.22,1.13,1.13,0,0,1-.73-.22A.6.6,0,0,1,394,311.6Z"/>
            <path className="cls-1" d="M401.24,311.6v-8.1a.56.56,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.14,1.14,0,0,1,.66.19.61.61,0,0,1,.28.5v.73a2.84,2.84,0,0,1,2.66-1.54,2.4,2.4,0,0,1,1.57.56,3.44,3.44,0,0,1,1.05,1.46,3.75,3.75,0,0,1,1.31-1.51,3.16,3.16,0,0,1,1.71-.51,3.31,3.31,0,0,1,2.45,1.07,4,4,0,0,1,1,2.87v5a.62.62,0,0,1-.32.48,1.26,1.26,0,0,1-.74.22,1.2,1.2,0,0,1-.72-.22.59.59,0,0,1-.32-.48v-5a2.07,2.07,0,0,0-.56-1.53,1.8,1.8,0,0,0-2.61,0,2,2,0,0,0-.57,1.49v5a.55.55,0,0,1-.31.51,1.6,1.6,0,0,1-.75.17,1.53,1.53,0,0,1-.72-.17.53.53,0,0,1-.32-.51v-5a2.07,2.07,0,0,0-.55-1.47,1.75,1.75,0,0,0-1.31-.58,1.77,1.77,0,0,0-1.31.56,2,2,0,0,0-.55,1.51v5a.61.61,0,0,1-.32.48,1.26,1.26,0,0,1-.74.22,1.18,1.18,0,0,1-.74-.22A.62.62,0,0,1,401.24,311.6Z"/>
            <path className="cls-1" d="M416.52,309.82a2.3,2.3,0,0,1,1.2-2.23,7.66,7.66,0,0,1,3.48-.63h.88v-.37a2.54,2.54,0,0,0-.49-1.55,1.75,1.75,0,0,0-1.49-.66,3.26,3.26,0,0,0-1.51.33,5.36,5.36,0,0,1-.71.33.63.63,0,0,1-.55-.33,1.32,1.32,0,0,1-.21-.73c0-.35.32-.65,1-.9a5.61,5.61,0,0,1,2.05-.38,3.89,3.89,0,0,1,3.05,1.11,4,4,0,0,1,1,2.77v5a.6.6,0,0,1-.29.49,1.23,1.23,0,0,1-.73.21,1.08,1.08,0,0,1-.68-.21.59.59,0,0,1-.28-.49v-.7a3.94,3.94,0,0,1-3,1.56,2.74,2.74,0,0,1-1.91-.68A2.51,2.51,0,0,1,416.52,309.82Zm2.12-.26a1.25,1.25,0,0,0,.36,1,1.33,1.33,0,0,0,.94.33,2.11,2.11,0,0,0,1.45-.64,1.86,1.86,0,0,0,.69-1.36v-.74h-.5a11.54,11.54,0,0,0-1.47.08,2.1,2.1,0,0,0-1,.42A1.12,1.12,0,0,0,418.64,309.56Z"/>
            <path className="cls-1" d="M426.3,309.18v-10a.55.55,0,0,1,.32-.48,1.34,1.34,0,0,1,.72-.2,1.43,1.43,0,0,1,.73.2.56.56,0,0,1,.33.48v3.66h2.44a.51.51,0,0,1,.42.23,1,1,0,0,1,0,1.1.51.51,0,0,1-.42.23H428.4v4.8a1.37,1.37,0,0,0,.31,1,1.54,1.54,0,0,0,1.09.3h.7a.87.87,0,0,1,.66.26,1,1,0,0,1,0,1.28.87.87,0,0,1-.66.26h-.7C427.46,312.3,426.3,311.26,426.3,309.18Z"/>
            <path className="cls-1" d="M432.72,299.34a1,1,0,0,1,.36-.78,1.25,1.25,0,0,1,.86-.32,1.27,1.27,0,0,1,.86.32,1,1,0,0,1,.36.78,1,1,0,0,1-.36.77,1.23,1.23,0,0,1-.86.33,1.22,1.22,0,0,1-.86-.33A1,1,0,0,1,432.72,299.34Zm.18,12.26v-8.1a.56.56,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.38,1.38,0,0,1,.76.19.57.57,0,0,1,.3.49v8.1a.63.63,0,0,1-.31.48,1.25,1.25,0,0,1-.75.22,1.18,1.18,0,0,1-.74-.22A.62.62,0,0,1,432.9,311.6Z"/>
            <path className="cls-1" d="M436.82,308.48v-1.84a3.71,3.71,0,0,1,1.22-2.76A4,4,0,0,1,441,302.7a4.11,4.11,0,0,1,2.9,1.17,3.7,3.7,0,0,1,1.24,2.77v1.84a3.77,3.77,0,0,1-1.25,2.77,4.08,4.08,0,0,1-5.79,0A3.75,3.75,0,0,1,436.82,308.48Zm2.1,0a2.08,2.08,0,0,0,.6,1.46,1.87,1.87,0,0,0,1.44.65,1.89,1.89,0,0,0,1.43-.65,2.09,2.09,0,0,0,.61-1.46v-1.82a2,2,0,0,0-.61-1.43,1.92,1.92,0,0,0-1.43-.64,1.89,1.89,0,0,0-1.44.64,2,2,0,0,0-.6,1.43Z"/>
            <path className="cls-1" d="M446.94,311.6v-8.1a.56.56,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.13,1.13,0,0,1,.67.19.6.6,0,0,1,.27.5v.73a2.64,2.64,0,0,1,1-1.1,3.08,3.08,0,0,1,1.65-.44,3.2,3.2,0,0,1,2.45,1.16,4.07,4.07,0,0,1,1,2.78v5a.6.6,0,0,1-.31.51,1.39,1.39,0,0,1-.75.19,1.34,1.34,0,0,1-.72-.19.57.57,0,0,1-.32-.51v-5a2,2,0,0,0-.6-1.43,1.88,1.88,0,0,0-1.42-.64,1.84,1.84,0,0,0-1.34.57,2,2,0,0,0-.58,1.5v5a.61.61,0,0,1-.32.48,1.26,1.26,0,0,1-.74.22,1.18,1.18,0,0,1-.74-.22A.62.62,0,0,1,446.94,311.6Z"/>
            <path className="cls-1" d="M10.14,349.1V336.5a.58.58,0,0,1,.29-.49,1.23,1.23,0,0,1,.71-.19,1.2,1.2,0,0,1,.7.19.58.58,0,0,1,.28.5v.73a3,3,0,0,1,2.68-1.54,3.32,3.32,0,0,1,2.55,1.16,4,4,0,0,1,1,2.78v1.84a4.07,4.07,0,0,1-1.06,2.77,3.19,3.19,0,0,1-2.46,1.21,2.92,2.92,0,0,1-1.59-.47,2.77,2.77,0,0,1-1-1.11v5.22a.6.6,0,0,1-.32.49,1.35,1.35,0,0,1-.76.21,1.21,1.21,0,0,1-.73-.21A.6.6,0,0,1,10.14,349.1Zm2.1-7.17a1.63,1.63,0,0,0,.61,1,1.93,1.93,0,0,0,1.41.61,1.89,1.89,0,0,0,1.42-.65,2.06,2.06,0,0,0,.62-1.46v-1.82a2,2,0,0,0-.62-1.43,2,2,0,0,0-3.44,1.43Z"/>
            <path className="cls-1" d="M19.86,341.48v-1.84a3.74,3.74,0,0,1,1.22-2.76A4,4,0,0,1,24,335.7a4.09,4.09,0,0,1,2.9,1.17,3.67,3.67,0,0,1,1.24,2.77v1.84a3.77,3.77,0,0,1-1.25,2.77,4.08,4.08,0,0,1-5.79,0A3.75,3.75,0,0,1,19.86,341.48Zm2.1,0a2.08,2.08,0,0,0,.6,1.46,1.91,1.91,0,0,0,2.87,0,2,2,0,0,0,.61-1.46v-1.82a2,2,0,0,0-.61-1.43,1.93,1.93,0,0,0-2.87,0,2,2,0,0,0-.6,1.43Z"/>
            <path className="cls-1" d="M29.38,343.86a1.12,1.12,0,0,1,.21-.62.63.63,0,0,1,.52-.32,1,1,0,0,1,.5.23,4.51,4.51,0,0,0,.87.48,3.41,3.41,0,0,0,1.31.23c1.14,0,1.71-.36,1.71-1.09a1,1,0,0,0-.5-.91,4.21,4.21,0,0,0-1.21-.53c-.47-.12-.94-.27-1.41-.43a2.67,2.67,0,0,1-1.2-.82,2.27,2.27,0,0,1-.5-1.51,2.76,2.76,0,0,1,.84-2,3.47,3.47,0,0,1,2.58-.85,5.91,5.91,0,0,1,2.07.35c.62.23.93.51.93.83a1.31,1.31,0,0,1-.23.65.65.65,0,0,1-.57.37,4.08,4.08,0,0,1-.82-.3,3.4,3.4,0,0,0-1.34-.3,1.78,1.78,0,0,0-1.21.36,1.07,1.07,0,0,0-.41.81.86.86,0,0,0,.5.76,4.79,4.79,0,0,0,1.22.46,12.29,12.29,0,0,1,1.42.45,2.6,2.6,0,0,1,1.19.9,2.63,2.63,0,0,1,.49,1.65,2.46,2.46,0,0,1-.92,2,4.07,4.07,0,0,1-2.6.74,5.45,5.45,0,0,1-2.43-.5C29.72,344.63,29.38,344.26,29.38,343.86Z"/>
            <path className="cls-1" d="M37.46,343.86a1.12,1.12,0,0,1,.21-.62.63.63,0,0,1,.52-.32,1,1,0,0,1,.5.23,4.51,4.51,0,0,0,.87.48,3.41,3.41,0,0,0,1.31.23c1.14,0,1.71-.36,1.71-1.09a1,1,0,0,0-.5-.91,4.21,4.21,0,0,0-1.21-.53c-.47-.12-.94-.27-1.41-.43a2.67,2.67,0,0,1-1.2-.82,2.27,2.27,0,0,1-.5-1.51,2.76,2.76,0,0,1,.84-2,3.47,3.47,0,0,1,2.58-.85,5.91,5.91,0,0,1,2.07.35c.62.23.93.51.93.83a1.31,1.31,0,0,1-.23.65.65.65,0,0,1-.57.37,4.08,4.08,0,0,1-.82-.3,3.4,3.4,0,0,0-1.34-.3,1.78,1.78,0,0,0-1.21.36,1.07,1.07,0,0,0-.41.81.86.86,0,0,0,.5.76,4.79,4.79,0,0,0,1.22.46,12.29,12.29,0,0,1,1.42.45,2.58,2.58,0,0,1,1.68,2.55,2.46,2.46,0,0,1-.92,2,4.07,4.07,0,0,1-2.6.74,5.45,5.45,0,0,1-2.43-.5C37.8,344.63,37.46,344.26,37.46,343.86Z"/>
            <path className="cls-1" d="M46,332.34a1,1,0,0,1,.36-.78,1.32,1.32,0,0,1,1.72,0,1,1,0,0,1,.36.78,1,1,0,0,1-.36.77,1.29,1.29,0,0,1-1.72,0A1,1,0,0,1,46,332.34Zm.18,12.26v-8.1a.58.58,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.36,1.36,0,0,1,.76.19.57.57,0,0,1,.3.49v8.1a.61.61,0,0,1-.31.48,1.25,1.25,0,0,1-.75.22,1.2,1.2,0,0,1-.74-.22A.62.62,0,0,1,46.14,344.6Z"/>
            <path className="cls-1" d="M50.46,344.6V330.88a.57.57,0,0,1,.28-.49,1.27,1.27,0,0,1,.74-.19,1.42,1.42,0,0,1,.77.19.56.56,0,0,1,.31.49v6.36a3,3,0,0,1,1-1.1,2.82,2.82,0,0,1,1.56-.44,3.3,3.3,0,0,1,2.53,1.16,4,4,0,0,1,1,2.78v1.84a4.07,4.07,0,0,1-1.06,2.77,3.15,3.15,0,0,1-4.12.75,2.94,2.94,0,0,1-1.1-1.12v.71a.63.63,0,0,1-.29.49,1.06,1.06,0,0,1-.67.22,1.15,1.15,0,0,1-.73-.22A.63.63,0,0,1,50.46,344.6Zm2.1-2.67a1.63,1.63,0,0,0,.63,1,2,2,0,0,0,1.41.61,1.84,1.84,0,0,0,1.42-.65,2.08,2.08,0,0,0,.6-1.46v-1.82a2,2,0,0,0-.61-1.43,1.9,1.9,0,0,0-1.43-.64,2,2,0,0,0-1.4.58,2,2,0,0,0-.62,1.49Z"/>
            <path className="cls-1" d="M60.74,344.6V330.86a.58.58,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.39,1.39,0,0,1,.75.19.56.56,0,0,1,.31.49V344.6a.62.62,0,0,1-.32.48,1.24,1.24,0,0,1-.74.22,1.2,1.2,0,0,1-.74-.22A.62.62,0,0,1,60.74,344.6Z"/>
            <path className="cls-1" d="M64.86,341.48v-1.9a3.65,3.65,0,0,1,1.22-2.73,4.32,4.32,0,0,1,5.75-.11A3.36,3.36,0,0,1,73,339.37a3.06,3.06,0,0,1-.14,1,.89.89,0,0,1-.44.52,1.81,1.81,0,0,1-.56.2,4.2,4.2,0,0,1-.68,0H67v.46a2,2,0,0,0,.72,1.61,2.9,2.9,0,0,0,1.88.59,3.35,3.35,0,0,0,1.55-.38A3.25,3.25,0,0,1,72,343a.7.7,0,0,1,.58.33,1,1,0,0,1,.24.62c0,.33-.32.66-1,1a5.12,5.12,0,0,1-2.36.5,5,5,0,0,1-3.34-1.1A3.57,3.57,0,0,1,64.86,341.48Zm2.1-1.7h3.32a1,1,0,0,0,.59-.12.57.57,0,0,0,.15-.47,1.77,1.77,0,0,0-.58-1.34A2,2,0,0,0,69,337.3a2.11,2.11,0,0,0-1.44.52,1.69,1.69,0,0,0-.6,1.33Z"/>
            <path className="cls-1" d="M74.61,345.1a1,1,0,0,1-.35-.78,1,1,0,0,1,.35-.78,1.27,1.27,0,0,1,.88-.32,1.25,1.25,0,0,1,.85.32,1,1,0,0,1,.36.78,1,1,0,0,1-.36.77,1.21,1.21,0,0,1-.85.33A1.27,1.27,0,0,1,74.61,345.1Z"/>
            <path className="cls-1" d="M82.64,341.14v-6.2a4.18,4.18,0,0,1,1.13-3.23,5,5,0,0,1,6.06,0A4.18,4.18,0,0,1,91,334.94v6.2a4.21,4.21,0,0,1-1.13,3.25,5,5,0,0,1-6.06,0A4.21,4.21,0,0,1,82.64,341.14Zm1.32,0c0,2,1,3.06,2.84,3.06s2.84-1,2.84-3.06v-6.2c0-2-1-3-2.84-3s-2.84,1-2.84,3Z"/>
            <path className="cls-1" d="M93.46,344.78v-8.46a.41.41,0,0,1,.18-.35.74.74,0,0,1,.46-.13.68.68,0,0,1,.42.14.44.44,0,0,1,.18.35v.93a2.85,2.85,0,0,1,1-1.09,3,3,0,0,1,1.64-.45,3.38,3.38,0,0,1,2.5,1.1,3.65,3.65,0,0,1,1.06,2.64v5.32a.44.44,0,0,1-.2.38.81.81,0,0,1-.46.14.77.77,0,0,1-.44-.14.44.44,0,0,1-.2-.38v-5.31a2.49,2.49,0,0,0-.74-1.75,2.34,2.34,0,0,0-1.76-.78,2.29,2.29,0,0,0-1.66.7,2.43,2.43,0,0,0-.72,1.83v5.31a.48.48,0,0,1-.2.36.7.7,0,0,1-.46.16.65.65,0,0,1-.46-.16A.48.48,0,0,1,93.46,344.78Z"/>
            <path className="cls-1" d="M107.34,342.36V331.84a.42.42,0,0,1,.2-.34.77.77,0,0,1,.44-.14.81.81,0,0,1,.46.14.42.42,0,0,1,.2.34v4h2.58a.41.41,0,0,1,.33.16.65.65,0,0,1,0,.78.41.41,0,0,1-.33.16h-2.58v5.39a1.83,1.83,0,0,0,.41,1.36,2.1,2.1,0,0,0,1.45.39h.56a.57.57,0,0,1,.43.18.57.57,0,0,1,.17.42.61.61,0,0,1-.17.43.54.54,0,0,1-.43.19h-.56Q107.34,345.3,107.34,342.36Z"/>
            <path className="cls-1" d="M113.32,344.78V330.54a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.79.79,0,0,1,.47.14.4.4,0,0,1,.19.34v6.72a3,3,0,0,1,2.64-1.54,3.34,3.34,0,0,1,2.49,1.1,3.68,3.68,0,0,1,1,2.64v5.32a.44.44,0,0,1-.19.38.79.79,0,0,1-.47.14.77.77,0,0,1-.44-.14.44.44,0,0,1-.2-.38v-5.31a2.49,2.49,0,0,0-.74-1.75,2.34,2.34,0,0,0-3.41-.08,2.4,2.4,0,0,0-.73,1.83v5.31a.48.48,0,0,1-.2.36.7.7,0,0,1-.46.16.67.67,0,0,1-.45-.16A.47.47,0,0,1,113.32,344.78Z"/>
            <path className="cls-1" d="M122.88,341.64V339.4a3.48,3.48,0,0,1,1.12-2.59,3.87,3.87,0,0,1,5.33-.05,3.43,3.43,0,0,1,1.11,2.6,2.45,2.45,0,0,1-.13.9.84.84,0,0,1-.42.48,2.21,2.21,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0h-4.52v.74a2.32,2.32,0,0,0,.85,1.89,3.7,3.7,0,0,0,3.38.51,3.47,3.47,0,0,0,.78-.4.84.84,0,0,1,.43-.2.49.49,0,0,1,.39.21.63.63,0,0,1,.17.41c0,.28-.29.57-.86.87a5.18,5.18,0,0,1-5.24-.6A3.48,3.48,0,0,1,122.88,341.64Zm1.3-1.64h4a1.59,1.59,0,0,0,.8-.13c.13-.08.2-.27.2-.55a2.47,2.47,0,0,0-.71-1.74,2.33,2.33,0,0,0-1.79-.76,2.44,2.44,0,0,0-1.77.72,2.27,2.27,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M131.8,344a.72.72,0,0,1,.14-.4.41.41,0,0,1,.32-.2,1,1,0,0,1,.44.23,4.35,4.35,0,0,0,.86.47,3.38,3.38,0,0,0,1.34.24,2.45,2.45,0,0,0,1.56-.4,1.27,1.27,0,0,0,.5-1,1.43,1.43,0,0,0-.51-1.15,3.44,3.44,0,0,0-1.23-.65l-1.45-.44a2.77,2.77,0,0,1-1.24-.77,2.09,2.09,0,0,1-.51-1.47,2.68,2.68,0,0,1,.79-1.91,3.18,3.18,0,0,1,2.37-.81A5.25,5.25,0,0,1,137,336c.57.21.85.43.85.67a.79.79,0,0,1-.16.44.46.46,0,0,1-.38.24,5.82,5.82,0,0,1-.74-.28,3.51,3.51,0,0,0-1.38-.28,2.16,2.16,0,0,0-1.5.47,1.44,1.44,0,0,0-.5,1.06,1.11,1.11,0,0,0,.52,1,4.9,4.9,0,0,0,1.24.57l1.46.43a2.29,2.29,0,0,1,1.24.87,2.62,2.62,0,0,1,.5,1.68,2.29,2.29,0,0,1-.85,1.89,3.66,3.66,0,0,1-2.37.69,4.87,4.87,0,0,1-2.23-.47C132.1,344.66,131.8,344.33,131.8,344Z"/>
            <path className="cls-1" d="M139.74,341.64V339.4a3.52,3.52,0,0,1,1.12-2.59,3.87,3.87,0,0,1,5.33-.05,3.43,3.43,0,0,1,1.11,2.6,2.45,2.45,0,0,1-.13.9.81.81,0,0,1-.42.48,2.21,2.21,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0H141v.74a2.35,2.35,0,0,0,.85,1.89,3.33,3.33,0,0,0,2.19.71,3.29,3.29,0,0,0,1.19-.2,3.47,3.47,0,0,0,.78-.4.87.87,0,0,1,.43-.2.49.49,0,0,1,.39.21.63.63,0,0,1,.17.41c0,.28-.29.57-.86.87a5.18,5.18,0,0,1-5.24-.6A3.48,3.48,0,0,1,139.74,341.64ZM141,340h4a1.59,1.59,0,0,0,.8-.13c.13-.08.2-.27.2-.55a2.47,2.47,0,0,0-.71-1.74,2.33,2.33,0,0,0-1.79-.76,2.44,2.44,0,0,0-1.77.72,2.31,2.31,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M152.9,341.64V339.4a3.52,3.52,0,0,1,1.12-2.59,3.87,3.87,0,0,1,5.33-.05,3.43,3.43,0,0,1,1.11,2.6,2.45,2.45,0,0,1-.13.9.78.78,0,0,1-.42.48,2.21,2.21,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0H154.2v.74a2.35,2.35,0,0,0,.85,1.89,3.33,3.33,0,0,0,2.19.71,3.29,3.29,0,0,0,1.19-.2,3.47,3.47,0,0,0,.78-.4.87.87,0,0,1,.43-.2.49.49,0,0,1,.39.21.63.63,0,0,1,.17.41c0,.28-.28.57-.86.87a5.18,5.18,0,0,1-5.24-.6A3.48,3.48,0,0,1,152.9,341.64Zm1.3-1.64h4a1.59,1.59,0,0,0,.8-.13c.13-.08.2-.27.2-.55a2.47,2.47,0,0,0-.71-1.74,2.33,2.33,0,0,0-1.79-.76,2.44,2.44,0,0,0-1.77.72,2.31,2.31,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M161.82,344a.72.72,0,0,1,.14-.4.41.41,0,0,1,.32-.2.91.91,0,0,1,.44.23,4.35,4.35,0,0,0,.86.47,3.38,3.38,0,0,0,1.34.24,2.45,2.45,0,0,0,1.56-.4,1.24,1.24,0,0,0,.5-1,1.43,1.43,0,0,0-.51-1.15,3.44,3.44,0,0,0-1.23-.65l-1.45-.44a2.77,2.77,0,0,1-1.24-.77,2.09,2.09,0,0,1-.51-1.47,2.68,2.68,0,0,1,.79-1.91,3.18,3.18,0,0,1,2.37-.81A5.21,5.21,0,0,1,167,336c.57.21.85.43.85.67a.79.79,0,0,1-.16.44.44.44,0,0,1-.38.24,5.82,5.82,0,0,1-.74-.28,3.51,3.51,0,0,0-1.38-.28,2.16,2.16,0,0,0-1.5.47,1.44,1.44,0,0,0-.5,1.06,1.11,1.11,0,0,0,.52,1,4.9,4.9,0,0,0,1.24.57l1.46.43a2.29,2.29,0,0,1,1.24.87,2.62,2.62,0,0,1,.5,1.68,2.29,2.29,0,0,1-.85,1.89,3.64,3.64,0,0,1-2.37.69,4.87,4.87,0,0,1-2.23-.47C162.13,344.66,161.82,344.33,161.82,344Z"/>
            <path className="cls-1" d="M169.52,344a.72.72,0,0,1,.14-.4.41.41,0,0,1,.32-.2.91.91,0,0,1,.44.23,4.35,4.35,0,0,0,.86.47,3.41,3.41,0,0,0,1.34.24,2.45,2.45,0,0,0,1.56-.4,1.24,1.24,0,0,0,.5-1,1.43,1.43,0,0,0-.51-1.15,3.44,3.44,0,0,0-1.23-.65l-1.45-.44a2.77,2.77,0,0,1-1.24-.77,2.09,2.09,0,0,1-.51-1.47,2.68,2.68,0,0,1,.79-1.91,3.18,3.18,0,0,1,2.37-.81,5.17,5.17,0,0,1,1.83.31c.57.21.85.43.85.67a.79.79,0,0,1-.16.44.44.44,0,0,1-.38.24,5.82,5.82,0,0,1-.74-.28,3.51,3.51,0,0,0-1.38-.28,2.16,2.16,0,0,0-1.5.47,1.44,1.44,0,0,0-.5,1.06,1.11,1.11,0,0,0,.52,1,4.9,4.9,0,0,0,1.24.57l1.46.43a2.29,2.29,0,0,1,1.24.87,2.62,2.62,0,0,1,.5,1.68,2.29,2.29,0,0,1-.85,1.89,3.64,3.64,0,0,1-2.37.69,4.87,4.87,0,0,1-2.23-.47C169.83,344.66,169.52,344.33,169.52,344Z"/>
            <path className="cls-1" d="M177.46,341.64V339.4a3.52,3.52,0,0,1,1.12-2.59,3.87,3.87,0,0,1,5.33-.05,3.43,3.43,0,0,1,1.11,2.6,2.45,2.45,0,0,1-.13.9.78.78,0,0,1-.42.48,2.21,2.21,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0h-4.52v.74a2.35,2.35,0,0,0,.85,1.89,3.33,3.33,0,0,0,2.19.71,3.29,3.29,0,0,0,1.19-.2,3.47,3.47,0,0,0,.78-.4.87.87,0,0,1,.43-.2.49.49,0,0,1,.39.21.63.63,0,0,1,.17.41c0,.28-.28.57-.86.87a5.18,5.18,0,0,1-5.24-.6A3.48,3.48,0,0,1,177.46,341.64Zm1.3-1.64h4a1.59,1.59,0,0,0,.8-.13c.14-.08.2-.27.2-.55a2.43,2.43,0,0,0-.71-1.74,2.33,2.33,0,0,0-1.79-.76,2.44,2.44,0,0,0-1.77.72,2.31,2.31,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M187,344.78v-8.46a.41.41,0,0,1,.18-.35.76.76,0,0,1,.46-.13.68.68,0,0,1,.42.14.44.44,0,0,1,.18.35v.93a2.92,2.92,0,0,1,1-1.09,3,3,0,0,1,1.64-.45,3.36,3.36,0,0,1,2.5,1.1,3.65,3.65,0,0,1,1.06,2.64v5.32a.42.42,0,0,1-.2.38.79.79,0,0,1-.9,0,.44.44,0,0,1-.2-.38v-5.31a2.45,2.45,0,0,0-.74-1.75,2.34,2.34,0,0,0-1.76-.78,2.31,2.31,0,0,0-1.66.7,2.43,2.43,0,0,0-.72,1.83v5.31a.45.45,0,0,1-.2.36.68.68,0,0,1-.46.16.66.66,0,0,1-.46-.16A.48.48,0,0,1,187,344.78Z"/>
            <path className="cls-1" d="M196.86,342.36V331.84a.42.42,0,0,1,.2-.34.79.79,0,0,1,.9,0,.39.39,0,0,1,.2.34v4h2.58a.38.38,0,0,1,.33.16.65.65,0,0,1,0,.78.38.38,0,0,1-.33.16h-2.58v5.39a1.83,1.83,0,0,0,.41,1.36,2.12,2.12,0,0,0,1.45.39h.56a.55.55,0,0,1,.43.18.54.54,0,0,1,.17.42.57.57,0,0,1-.17.43.53.53,0,0,1-.43.19H200C197.92,345.3,196.86,344.32,196.86,342.36Z"/>
            <path className="cls-1" d="M202.84,332.79a.8.8,0,0,1-.26-.59.77.77,0,0,1,.26-.58.93.93,0,0,1,.64-.24.84.84,0,0,1,.61.24.76.76,0,0,1,.25.58.79.79,0,0,1-.25.59.81.81,0,0,1-.61.25A.89.89,0,0,1,202.84,332.79Zm0,12v-8.46A.42.42,0,0,1,203,336a.76.76,0,0,1,.46-.14.77.77,0,0,1,.47.14.4.4,0,0,1,.19.34v8.46a.45.45,0,0,1-.2.36.68.68,0,0,1-.46.16.67.67,0,0,1-.45-.16A.5.5,0,0,1,202.84,344.78Z"/>
            <path className="cls-1" d="M206,342.8a2.25,2.25,0,0,1,1.19-2.19,7.79,7.79,0,0,1,3.47-.61h1v-.47a3.09,3.09,0,0,0-.58-1.88,2.07,2.07,0,0,0-1.78-.79,3.78,3.78,0,0,0-1.45.28,7.44,7.44,0,0,1-.69.29.43.43,0,0,1-.38-.22.86.86,0,0,1-.14-.47c0-.25.29-.49.86-.7a5.35,5.35,0,0,1,1.84-.32,3.29,3.29,0,0,1,2.75,1.09,4.31,4.31,0,0,1,.87,2.77v5.2a.46.46,0,0,1-.18.37.69.69,0,0,1-.46.15.64.64,0,0,1-.43-.15.48.48,0,0,1-.17-.37v-.88a3.93,3.93,0,0,1-3,1.54,2.84,2.84,0,0,1-2-.67A2.51,2.51,0,0,1,206,342.8Zm1.3-.12a1.55,1.55,0,0,0,.48,1.25,1.89,1.89,0,0,0,1.26.41,2.74,2.74,0,0,0,1.79-.7,1.9,1.9,0,0,0,.85-1.4V341h-.84a12.84,12.84,0,0,0-1.35.06,6,6,0,0,0-1.09.22,1.6,1.6,0,0,0-.82.52A1.5,1.5,0,0,0,207.3,342.68Z"/>
            <path className="cls-1" d="M215.58,344.78V330.52a.42.42,0,0,1,.18-.34.76.76,0,0,1,.46-.14.77.77,0,0,1,.47.14.4.4,0,0,1,.19.34v14.26a.47.47,0,0,1-.19.36.74.74,0,0,1-.92,0A.5.5,0,0,1,215.58,344.78Z"/>
            <path className="cls-1" d="M223.56,349.44V336.32a.42.42,0,0,1,.18-.34.76.76,0,0,1,.46-.14.7.7,0,0,1,.44.14.44.44,0,0,1,.18.35v.93a3.06,3.06,0,0,1,1.06-1.09,3,3,0,0,1,1.62-.45,3.4,3.4,0,0,1,2.54,1.1,3.65,3.65,0,0,1,1.06,2.64v2.18a3.75,3.75,0,0,1-1.07,2.65,3.26,3.26,0,0,1-2.47,1.15,3,3,0,0,1-1.62-.46,2.91,2.91,0,0,1-1.08-1.1v5.56a.45.45,0,0,1-.19.34.74.74,0,0,1-.92,0A.48.48,0,0,1,223.56,349.44Zm1.3-7.19a1.92,1.92,0,0,0,.75,1.25,2.4,2.4,0,0,0,1.71.72,2.28,2.28,0,0,0,1.73-.8,2.53,2.53,0,0,0,.75-1.79v-2.16a2.44,2.44,0,0,0-.75-1.75,2.39,2.39,0,0,0-3.46-.08,2.42,2.42,0,0,0-.73,1.83Z"/>
            <path className="cls-1" d="M233.24,344.78v-8.46a.42.42,0,0,1,.18-.34.76.76,0,0,1,.46-.14.7.7,0,0,1,.44.14.44.44,0,0,1,.18.35v1a3.17,3.17,0,0,1,1.06-1.13,2.88,2.88,0,0,1,1.62-.47h.7a.45.45,0,0,1,.36.18.66.66,0,0,1,0,.86.45.45,0,0,1-.36.18h-.7a2.53,2.53,0,0,0-1.84.81,2.87,2.87,0,0,0-.8,2.1v4.93a.47.47,0,0,1-.19.36.74.74,0,0,1-.92,0A.5.5,0,0,1,233.24,344.78Z"/>
            <path className="cls-1" d="M239.86,332.79a.8.8,0,0,1-.26-.59.77.77,0,0,1,.26-.58.93.93,0,0,1,.64-.24.84.84,0,0,1,.61.24.76.76,0,0,1,.25.58.79.79,0,0,1-.25.59.81.81,0,0,1-.61.25A.89.89,0,0,1,239.86,332.79Zm0,12v-8.46A.42.42,0,0,1,240,336a.76.76,0,0,1,.46-.14.77.77,0,0,1,.47.14.4.4,0,0,1,.19.34v8.46a.45.45,0,0,1-.2.36.68.68,0,0,1-.46.16.67.67,0,0,1-.45-.16A.5.5,0,0,1,239.86,344.78Z"/>
            <path className="cls-1" d="M243.68,344.78v-8.46a.41.41,0,0,1,.18-.35.76.76,0,0,1,.46-.13.68.68,0,0,1,.42.14.44.44,0,0,1,.18.35v.93a2.92,2.92,0,0,1,1-1.09,3,3,0,0,1,1.64-.45,3.36,3.36,0,0,1,2.5,1.1,3.65,3.65,0,0,1,1.06,2.64v5.32a.42.42,0,0,1-.2.38.79.79,0,0,1-.9,0,.44.44,0,0,1-.2-.38v-5.31a2.45,2.45,0,0,0-.74-1.75,2.34,2.34,0,0,0-1.76-.78,2.31,2.31,0,0,0-1.66.7,2.43,2.43,0,0,0-.72,1.83v5.31a.45.45,0,0,1-.2.36.68.68,0,0,1-.46.16.66.66,0,0,1-.46-.16A.48.48,0,0,1,243.68,344.78Z"/>
            <path className="cls-1" d="M253.26,341.64V339.5a3.86,3.86,0,0,1,1-2.73,3.77,3.77,0,0,1,2.88-1,4.21,4.21,0,0,1,2,.43q.81.44.81.87a.65.65,0,0,1-.18.43.52.52,0,0,1-.4.21.79.79,0,0,1-.37-.18,3.59,3.59,0,0,0-.71-.35,2.78,2.78,0,0,0-1.1-.19,2.64,2.64,0,0,0-2,.68,2.59,2.59,0,0,0-.67,1.89v2.12a2.61,2.61,0,0,0,.67,1.91,2.68,2.68,0,0,0,2,.68,3.19,3.19,0,0,0,1.15-.18,3.35,3.35,0,0,0,.72-.37.75.75,0,0,1,.39-.19.54.54,0,0,1,.43.2.63.63,0,0,1,.17.44c0,.31-.28.6-.84.89a4.48,4.48,0,0,1-2.08.43,3.82,3.82,0,0,1-2.9-1A3.86,3.86,0,0,1,253.26,341.64Z"/>
            <path className="cls-1" d="M261.82,332.79a.8.8,0,0,1-.26-.59.77.77,0,0,1,.26-.58.93.93,0,0,1,.64-.24.84.84,0,0,1,.61.24.76.76,0,0,1,.25.58.79.79,0,0,1-.25.59.81.81,0,0,1-.61.25A.89.89,0,0,1,261.82,332.79Zm0,12v-8.46A.42.42,0,0,1,262,336a.76.76,0,0,1,.46-.14.77.77,0,0,1,.47.14.4.4,0,0,1,.19.34v8.46a.45.45,0,0,1-.2.36.68.68,0,0,1-.46.16.67.67,0,0,1-.45-.16A.5.5,0,0,1,261.82,344.78Z"/>
            <path className="cls-1" d="M265.64,349.44V336.32a.42.42,0,0,1,.18-.34.76.76,0,0,1,.46-.14.7.7,0,0,1,.44.14.44.44,0,0,1,.18.35v.93a3.06,3.06,0,0,1,1.06-1.09,3,3,0,0,1,1.62-.45,3.4,3.4,0,0,1,2.54,1.1,3.65,3.65,0,0,1,1.06,2.64v2.18a3.75,3.75,0,0,1-1.07,2.65,3.26,3.26,0,0,1-2.47,1.15A3,3,0,0,1,268,345a2.91,2.91,0,0,1-1.08-1.1v5.56a.45.45,0,0,1-.19.34.74.74,0,0,1-.92,0A.48.48,0,0,1,265.64,349.44Zm1.3-7.19a1.92,1.92,0,0,0,.75,1.25,2.4,2.4,0,0,0,1.71.72,2.28,2.28,0,0,0,1.73-.8,2.53,2.53,0,0,0,.75-1.79v-2.16a2.44,2.44,0,0,0-.75-1.75,2.39,2.39,0,0,0-3.46-.08,2.42,2.42,0,0,0-.73,1.83Z"/>
            <path className="cls-1" d="M275.48,344.78V330.52a.42.42,0,0,1,.18-.34.76.76,0,0,1,.46-.14.77.77,0,0,1,.47.14.4.4,0,0,1,.19.34v14.26a.47.47,0,0,1-.19.36.74.74,0,0,1-.92,0A.5.5,0,0,1,275.48,344.78Z"/>
            <path className="cls-1" d="M279.1,341.64V339.4a3.52,3.52,0,0,1,1.12-2.59,3.87,3.87,0,0,1,5.33-.05,3.43,3.43,0,0,1,1.11,2.6,2.45,2.45,0,0,1-.13.9.78.78,0,0,1-.42.48,2.21,2.21,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0H280.4v.74a2.35,2.35,0,0,0,.85,1.89,3.33,3.33,0,0,0,2.19.71,3.29,3.29,0,0,0,1.19-.2,3.47,3.47,0,0,0,.78-.4.87.87,0,0,1,.43-.2.49.49,0,0,1,.39.21.63.63,0,0,1,.17.41c0,.28-.28.57-.86.87a5.18,5.18,0,0,1-5.24-.6A3.48,3.48,0,0,1,279.1,341.64Zm1.3-1.64h4a1.59,1.59,0,0,0,.8-.13c.13-.08.2-.27.2-.55a2.43,2.43,0,0,0-.71-1.74,2.33,2.33,0,0,0-1.79-.76,2.44,2.44,0,0,0-1.77.72,2.31,2.31,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M288,344a.72.72,0,0,1,.14-.4.41.41,0,0,1,.32-.2.91.91,0,0,1,.44.23,4.35,4.35,0,0,0,.86.47,3.41,3.41,0,0,0,1.34.24,2.45,2.45,0,0,0,1.56-.4,1.24,1.24,0,0,0,.5-1,1.43,1.43,0,0,0-.51-1.15,3.44,3.44,0,0,0-1.23-.65l-1.45-.44a2.77,2.77,0,0,1-1.24-.77,2.09,2.09,0,0,1-.51-1.47,2.68,2.68,0,0,1,.79-1.91,3.18,3.18,0,0,1,2.37-.81,5.17,5.17,0,0,1,1.83.31c.57.21.85.43.85.67a.79.79,0,0,1-.16.44.44.44,0,0,1-.38.24,5.82,5.82,0,0,1-.74-.28,3.51,3.51,0,0,0-1.38-.28,2.16,2.16,0,0,0-1.5.47,1.44,1.44,0,0,0-.5,1.06,1.11,1.11,0,0,0,.52,1,4.9,4.9,0,0,0,1.24.57l1.46.43a2.29,2.29,0,0,1,1.24.87,2.62,2.62,0,0,1,.5,1.68,2.29,2.29,0,0,1-.85,1.89,3.64,3.64,0,0,1-2.37.69,4.87,4.87,0,0,1-2.23-.47C288.33,344.66,288,344.33,288,344Z"/>
            <path className="cls-1" d="M300.28,342.36V331.84a.42.42,0,0,1,.2-.34.79.79,0,0,1,.9,0,.39.39,0,0,1,.2.34v4h2.58a.38.38,0,0,1,.33.16.65.65,0,0,1,0,.78.38.38,0,0,1-.33.16h-2.58v5.39a1.83,1.83,0,0,0,.41,1.36,2.12,2.12,0,0,0,1.45.39H304a.55.55,0,0,1,.43.18.54.54,0,0,1,.17.42.57.57,0,0,1-.17.43.53.53,0,0,1-.43.19h-.56C301.34,345.3,300.28,344.32,300.28,342.36Z"/>
            <path className="cls-1" d="M306.26,344.78V330.54a.42.42,0,0,1,.18-.34.76.76,0,0,1,.46-.14.77.77,0,0,1,.47.14.4.4,0,0,1,.19.34v6.72a3,3,0,0,1,2.64-1.54,3.34,3.34,0,0,1,2.49,1.1,3.68,3.68,0,0,1,1.05,2.64v5.32a.44.44,0,0,1-.19.38.77.77,0,0,1-.47.14.79.79,0,0,1-.44-.14.44.44,0,0,1-.2-.38v-5.31a2.45,2.45,0,0,0-.74-1.75,2.31,2.31,0,0,0-1.74-.78,2.35,2.35,0,0,0-1.67.7,2.44,2.44,0,0,0-.73,1.83v5.31a.45.45,0,0,1-.2.36.68.68,0,0,1-.46.16.67.67,0,0,1-.45-.16A.5.5,0,0,1,306.26,344.78Z"/>
            <path className="cls-1" d="M315.82,341.64V339.4a3.52,3.52,0,0,1,1.12-2.59,3.87,3.87,0,0,1,5.33-.05,3.43,3.43,0,0,1,1.11,2.6,2.45,2.45,0,0,1-.13.9.78.78,0,0,1-.42.48,2.21,2.21,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0h-4.52v.74a2.35,2.35,0,0,0,.85,1.89,3.33,3.33,0,0,0,2.19.71,3.29,3.29,0,0,0,1.19-.2,3.47,3.47,0,0,0,.78-.4.87.87,0,0,1,.43-.2.49.49,0,0,1,.39.21.63.63,0,0,1,.17.41c0,.28-.28.57-.86.87a5.18,5.18,0,0,1-5.24-.6A3.48,3.48,0,0,1,315.82,341.64Zm1.3-1.64h4a1.59,1.59,0,0,0,.8-.13c.14-.08.2-.27.2-.55a2.43,2.43,0,0,0-.71-1.74,2.33,2.33,0,0,0-1.79-.76,2.44,2.44,0,0,0-1.77.72,2.31,2.31,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M328.8,343.3a.87.87,0,0,1,.19-.49.53.53,0,0,1,.41-.25c.11,0,.24.09.4.26l.56.58a2.67,2.67,0,0,0,.95.57,4.16,4.16,0,0,0,1.51.25,3.16,3.16,0,0,0,2.2-.71,2.63,2.63,0,0,0,.78-2.05,2.94,2.94,0,0,0-.37-1.53,2.6,2.6,0,0,0-.95-1,7.28,7.28,0,0,0-1.28-.64l-1.44-.56a6.52,6.52,0,0,1-1.29-.67,3,3,0,0,1-.94-1.1,3.7,3.7,0,0,1-.37-1.69,3.86,3.86,0,0,1,.39-1.8,2.77,2.77,0,0,1,1.05-1.14,5.19,5.19,0,0,1,1.35-.55,6.26,6.26,0,0,1,1.45-.17,7.37,7.37,0,0,1,2.29.35c.72.23,1.07.52,1.07.87a.83.83,0,0,1-.17.49.48.48,0,0,1-.41.25,1.33,1.33,0,0,1-.46-.18,4.83,4.83,0,0,0-2.34-.56,3.72,3.72,0,0,0-2.08.54,2,2,0,0,0-.82,1.78,2.2,2.2,0,0,0,.49,1.44,2.77,2.77,0,0,0,1.23.84c.5.17,1,.38,1.6.62a15.91,15.91,0,0,1,1.6.77,3.1,3.1,0,0,1,1.23,1.3,4.43,4.43,0,0,1,.49,2.19,3.94,3.94,0,0,1-1.14,3.08,4.44,4.44,0,0,1-3.08,1,5.43,5.43,0,0,1-2.92-.74C329.2,344.2,328.8,343.74,328.8,343.3Z"/>
            <path className="cls-1" d="M338.86,341.64V339.4a3.52,3.52,0,0,1,1.12-2.59,3.87,3.87,0,0,1,5.33-.05,3.43,3.43,0,0,1,1.11,2.6,2.45,2.45,0,0,1-.13.9.78.78,0,0,1-.42.48,2.21,2.21,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0h-4.52v.74a2.35,2.35,0,0,0,.85,1.89,3.33,3.33,0,0,0,2.19.71,3.29,3.29,0,0,0,1.19-.2,3.47,3.47,0,0,0,.78-.4.87.87,0,0,1,.43-.2.49.49,0,0,1,.39.21.63.63,0,0,1,.17.41c0,.28-.28.57-.86.87a5.18,5.18,0,0,1-5.24-.6A3.48,3.48,0,0,1,338.86,341.64Zm1.3-1.64h4a1.59,1.59,0,0,0,.8-.13c.14-.08.2-.27.2-.55a2.43,2.43,0,0,0-.71-1.74,2.33,2.33,0,0,0-1.79-.76,2.44,2.44,0,0,0-1.77.72,2.31,2.31,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M348,341.64V339.4a3.52,3.52,0,0,1,1.12-2.59,3.87,3.87,0,0,1,5.33-.05,3.43,3.43,0,0,1,1.11,2.6,2.45,2.45,0,0,1-.13.9.78.78,0,0,1-.42.48,2.21,2.21,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0h-4.52v.74a2.35,2.35,0,0,0,.85,1.89,3.33,3.33,0,0,0,2.19.71,3.29,3.29,0,0,0,1.19-.2,3.47,3.47,0,0,0,.78-.4.87.87,0,0,1,.43-.2.49.49,0,0,1,.39.21.63.63,0,0,1,.17.41c0,.28-.28.57-.86.87a5.18,5.18,0,0,1-5.24-.6A3.48,3.48,0,0,1,348,341.64Zm1.3-1.64h4a1.59,1.59,0,0,0,.8-.13c.14-.08.2-.27.2-.55a2.43,2.43,0,0,0-.71-1.74,2.33,2.33,0,0,0-1.79-.76,2.44,2.44,0,0,0-1.77.72,2.31,2.31,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M357.6,344.6V331.36a.66.66,0,0,1,.2-.48.7.7,0,0,1,.5-.2h3.8q4.61,0,4.6,4.08a4.17,4.17,0,0,1-.76,2.66,3.6,3.6,0,0,1-2.06,1.28l2.8,5.28a.52.52,0,0,1,.08.3,1,1,0,0,1-.41.74,1.31,1.31,0,0,1-.87.34,1,1,0,0,1-.84-.62L361.72,339h-2v5.6a.58.58,0,0,1-.31.51,1.54,1.54,0,0,1-1.48,0A.6.6,0,0,1,357.6,344.6Zm2.1-7.28h2.4c1.66,0,2.48-.79,2.48-2.38s-.82-2.38-2.48-2.38h-2.4Z"/>
            <path className="cls-1" d="M368.78,344.6V331.36a.66.66,0,0,1,.2-.48.7.7,0,0,1,.5-.2h3.8q4.61,0,4.6,4.08a4.17,4.17,0,0,1-.76,2.66,3.6,3.6,0,0,1-2.06,1.28l2.8,5.28a.52.52,0,0,1,.08.3,1,1,0,0,1-.41.74,1.31,1.31,0,0,1-.87.34,1,1,0,0,1-.84-.62L372.9,339h-2v5.6a.58.58,0,0,1-.31.51,1.54,1.54,0,0,1-1.48,0A.6.6,0,0,1,368.78,344.6Zm2.1-7.28h2.4c1.66,0,2.48-.79,2.48-2.38s-.82-2.38-2.48-2.38h-2.4Z"/>
            <path className="cls-1" d="M380,344.6V331.36a.55.55,0,0,1,.31-.51,1.6,1.6,0,0,1,.75-.17,1.54,1.54,0,0,1,.75.17.54.54,0,0,1,.31.51V344.6a.58.58,0,0,1-.31.51,1.35,1.35,0,0,1-.75.19,1.41,1.41,0,0,1-.74-.19A.59.59,0,0,1,380,344.6Z"/>
            <path className="cls-1" d="M388.58,341v-5.82a4.28,4.28,0,0,1,1.23-3.38,4.69,4.69,0,0,1,3.21-1.08,5.09,5.09,0,0,1,3.29,1,3.17,3.17,0,0,1,1.25,2.61.89.89,0,0,1-1.06,1.1c-.69,0-1.05-.23-1.06-.7a2.05,2.05,0,0,0-2.34-2.14q-2.4,0-2.4,2.58V341c0,1.72.82,2.58,2.44,2.58a3,3,0,0,0,1.2-.21,1.34,1.34,0,0,0,.69-.61,3.37,3.37,0,0,0,.29-.7,3.46,3.46,0,0,0,.12-.74c0-.47.39-.7,1-.7a1.21,1.21,0,0,1,.83.23,1.14,1.14,0,0,1,.25.87,3.33,3.33,0,0,1-1.26,2.7,5,5,0,0,1-3.3,1,4.66,4.66,0,0,1-3.2-1.08A4.31,4.31,0,0,1,388.58,341Z"/>
            <path className="cls-1" d="M398.94,341.48v-1.84a3.74,3.74,0,0,1,1.22-2.76,4.19,4.19,0,0,1,5.82,0,3.67,3.67,0,0,1,1.24,2.77v1.84a3.77,3.77,0,0,1-1.25,2.77,4.08,4.08,0,0,1-5.79,0A3.75,3.75,0,0,1,398.94,341.48Zm2.1,0a2.08,2.08,0,0,0,.6,1.46,1.91,1.91,0,0,0,2.87,0,2,2,0,0,0,.61-1.46v-1.82a2,2,0,0,0-.61-1.43,1.93,1.93,0,0,0-2.87,0,2,2,0,0,0-.6,1.43Z"/>
            <path className="cls-1" d="M409.06,344.6v-8.1a.58.58,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.11,1.11,0,0,1,.67.19.6.6,0,0,1,.27.5v.73a2.7,2.7,0,0,1,1-1.1,3.08,3.08,0,0,1,1.65-.44,3.17,3.17,0,0,1,2.45,1.16,4,4,0,0,1,1,2.78v5a.58.58,0,0,1-.31.51,1.39,1.39,0,0,1-.75.19,1.36,1.36,0,0,1-.72-.19.59.59,0,0,1-.32-.51v-5a2,2,0,0,0-.6-1.43,1.88,1.88,0,0,0-2.76-.07,2,2,0,0,0-.58,1.5v5a.61.61,0,0,1-.32.48,1.24,1.24,0,0,1-.74.22,1.2,1.2,0,0,1-.74-.22A.62.62,0,0,1,409.06,344.6Z"/>
            <path className="cls-1" d="M419,341.48v-1.8a4,4,0,0,1,1-2.88,4.08,4.08,0,0,1,3.1-1.1,4.76,4.76,0,0,1,2.18.45c.59.3.88.64.88,1a1.12,1.12,0,0,1-.25.68.7.7,0,0,1-.59.34,3.28,3.28,0,0,1-.71-.3,3,3,0,0,0-1.35-.3c-1.47,0-2.2.7-2.2,2.11v1.78c0,1.41.74,2.11,2.22,2.11a2.91,2.91,0,0,0,1.37-.32,3.09,3.09,0,0,1,.73-.32.78.78,0,0,1,.65.32,1.06,1.06,0,0,1,.25.68c0,.37-.31.72-.92,1a4.78,4.78,0,0,1-2.24.48,4.1,4.1,0,0,1-3.12-1.09A4,4,0,0,1,419,341.48Z"/>
            <path className="cls-1" d="M427.38,341.48v-1.9a3.65,3.65,0,0,1,1.22-2.73,4.32,4.32,0,0,1,5.75-.11,3.36,3.36,0,0,1,1.17,2.63,3.06,3.06,0,0,1-.14,1,.89.89,0,0,1-.44.52,1.81,1.81,0,0,1-.56.2,4.2,4.2,0,0,1-.68,0h-4.22v.46a2,2,0,0,0,.72,1.61,2.9,2.9,0,0,0,1.88.59,3.35,3.35,0,0,0,1.55-.38,3.39,3.39,0,0,1,.89-.39.7.7,0,0,1,.58.33,1,1,0,0,1,.24.62c0,.33-.32.66-1,1a5.12,5.12,0,0,1-2.36.5,5,5,0,0,1-3.34-1.1A3.57,3.57,0,0,1,427.38,341.48Zm2.1-1.7h3.32a1,1,0,0,0,.59-.12.57.57,0,0,0,.15-.47,1.77,1.77,0,0,0-.58-1.34,2,2,0,0,0-1.44-.55,2.11,2.11,0,0,0-1.44.52,1.69,1.69,0,0,0-.6,1.33Z"/>
            <path className="cls-1" d="M437.24,349.1V336.5a.58.58,0,0,1,.29-.49,1.23,1.23,0,0,1,.71-.19,1.2,1.2,0,0,1,.7.19.58.58,0,0,1,.28.5v.73a3,3,0,0,1,2.68-1.54,3.32,3.32,0,0,1,2.55,1.16,4,4,0,0,1,1.05,2.78v1.84a4.07,4.07,0,0,1-1.06,2.77,3.19,3.19,0,0,1-2.46,1.21,2.92,2.92,0,0,1-1.59-.47,2.77,2.77,0,0,1-1.05-1.11v5.22a.6.6,0,0,1-.32.49,1.35,1.35,0,0,1-.76.21,1.21,1.21,0,0,1-.73-.21A.6.6,0,0,1,437.24,349.1Zm2.1-7.17a1.63,1.63,0,0,0,.61,1,1.93,1.93,0,0,0,1.41.61,1.89,1.89,0,0,0,1.42-.65,2.06,2.06,0,0,0,.62-1.46v-1.82a2,2,0,0,0-.62-1.43,2,2,0,0,0-3.44,1.43Z"/>
            <path className="cls-1" d="M447.32,342.18v-10a.57.57,0,0,1,.32-.48,1.34,1.34,0,0,1,.72-.2,1.4,1.4,0,0,1,.73.2.56.56,0,0,1,.33.48v3.66h2.44a.51.51,0,0,1,.42.23,1,1,0,0,1,0,1.1.51.51,0,0,1-.42.23h-2.44v4.8a1.37,1.37,0,0,0,.31,1,1.56,1.56,0,0,0,1.09.3h.7a.87.87,0,0,1,.66.26,1,1,0,0,1,0,1.28.87.87,0,0,1-.66.26h-.7Q447.33,345.3,447.32,342.18Z"/>
            <path className="cls-1" d="M453.84,341.52v-5a.6.6,0,0,1,.32-.49,1.31,1.31,0,0,1,.74-.21,1.28,1.28,0,0,1,.73.21.58.58,0,0,1,.31.49v5a2,2,0,0,0,.61,1.44,1.94,1.94,0,0,0,2.87,0,2,2,0,0,0,.6-1.45v-5a.56.56,0,0,1,.31-.48,1.4,1.4,0,0,1,.75-.2,1.28,1.28,0,0,1,.73.2.58.58,0,0,1,.29.48v5a3.74,3.74,0,0,1-1.22,2.76,4.17,4.17,0,0,1-5.81,0A3.72,3.72,0,0,1,453.84,341.52Z"/>
            <path className="cls-1" d="M463.62,342.82a2.3,2.3,0,0,1,1.2-2.23,7.66,7.66,0,0,1,3.48-.63h.88v-.37a2.47,2.47,0,0,0-.49-1.55,1.74,1.74,0,0,0-1.49-.66,3.29,3.29,0,0,0-1.51.33,4.76,4.76,0,0,1-.71.33.63.63,0,0,1-.55-.33,1.32,1.32,0,0,1-.21-.73c0-.35.32-.65,1-.9a5.65,5.65,0,0,1,2.05-.38,3.89,3.89,0,0,1,3.05,1.11,4,4,0,0,1,1,2.77v5a.6.6,0,0,1-.29.49,1.21,1.21,0,0,1-.73.21,1.1,1.1,0,0,1-.68-.21.59.59,0,0,1-.28-.49v-.7a3.92,3.92,0,0,1-3,1.56,2.76,2.76,0,0,1-1.91-.68A2.51,2.51,0,0,1,463.62,342.82Zm2.12-.26a1.25,1.25,0,0,0,.36,1,1.33,1.33,0,0,0,.94.33,2.1,2.1,0,0,0,1.45-.64,1.86,1.86,0,0,0,.69-1.36v-.74h-.5a11.54,11.54,0,0,0-1.47.08,2.1,2.1,0,0,0-1,.42A1.12,1.12,0,0,0,465.74,342.56Z"/>
            <path className="cls-1" d="M473.6,344.6V330.86a.58.58,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.39,1.39,0,0,1,.75.19.56.56,0,0,1,.31.49V344.6a.62.62,0,0,1-.32.48,1.24,1.24,0,0,1-.74.22,1.2,1.2,0,0,1-.74-.22A.62.62,0,0,1,473.6,344.6Z"/>
            <path className="cls-1" d="M8.61,377.6V364.38a.59.59,0,0,1,.28-.51,1.14,1.14,0,0,1,.66-.19h7a.56.56,0,0,1,.51.27,1.35,1.35,0,0,1,0,1.33.56.56,0,0,1-.5.28H10.73v4.58h3.1a.58.58,0,0,1,.5.25,1.08,1.08,0,0,1,0,1.18.56.56,0,0,1-.51.25h-3.1v5.78a.56.56,0,0,1-.31.52,1.63,1.63,0,0,1-1.49,0A.57.57,0,0,1,8.61,377.6Z"/>
            <path className="cls-1" d="M16.57,377.6v-8.1a.58.58,0,0,1,.29-.49,1.28,1.28,0,0,1,.73-.19,1.16,1.16,0,0,1,.68.19.58.58,0,0,1,.28.5v.89a3.19,3.19,0,0,1,1-1.2,2.53,2.53,0,0,1,1.51-.5h.64a.73.73,0,0,1,.56.27,1,1,0,0,1,.24.66,1,1,0,0,1-.24.67.71.71,0,0,1-.56.28h-.64a2.2,2.2,0,0,0-1.69.82,3.07,3.07,0,0,0-.73,2.11v4.09a.61.61,0,0,1-.32.48,1.28,1.28,0,0,1-.76.22,1.15,1.15,0,0,1-.73-.22A.63.63,0,0,1,16.57,377.6Z"/>
            <path className="cls-1" d="M23.17,375.82a2.3,2.3,0,0,1,1.2-2.23,7.66,7.66,0,0,1,3.48-.63h.88v-.37a2.47,2.47,0,0,0-.49-1.55,1.72,1.72,0,0,0-1.49-.66,3.29,3.29,0,0,0-1.51.33,4.76,4.76,0,0,1-.71.33.63.63,0,0,1-.55-.33,1.32,1.32,0,0,1-.21-.73c0-.35.32-.65,1-.9a5.65,5.65,0,0,1,2.05-.38,3.87,3.87,0,0,1,3.05,1.11,4,4,0,0,1,1,2.77v5a.58.58,0,0,1-.29.49,1.19,1.19,0,0,1-.73.21,1.1,1.1,0,0,1-.68-.21.62.62,0,0,1-.28-.49v-.7a3.92,3.92,0,0,1-3,1.56,2.76,2.76,0,0,1-1.91-.68A2.51,2.51,0,0,1,23.17,375.82Zm2.12-.26a1.25,1.25,0,0,0,.36,1,1.33,1.33,0,0,0,.94.33,2.1,2.1,0,0,0,1.45-.64,1.86,1.86,0,0,0,.69-1.36v-.74h-.5a11.54,11.54,0,0,0-1.47.08,2.1,2.1,0,0,0-1,.42A1.12,1.12,0,0,0,25.29,375.56Z"/>
            <path className="cls-1" d="M33,377.6v-8.1a.58.58,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.09,1.09,0,0,1,.66.19.58.58,0,0,1,.28.5v.73a2.85,2.85,0,0,1,2.66-1.54,2.38,2.38,0,0,1,1.57.56,3.44,3.44,0,0,1,1,1.46,3.82,3.82,0,0,1,1.31-1.51,3.16,3.16,0,0,1,1.71-.51,3.28,3.28,0,0,1,2.45,1.07,3.94,3.94,0,0,1,1,2.87v5a.6.6,0,0,1-.32.48,1.32,1.32,0,0,1-1.46,0,.61.61,0,0,1-.32-.48v-5a2,2,0,0,0-.56-1.53,1.78,1.78,0,0,0-1.3-.56,1.84,1.84,0,0,0-1.31.56,2,2,0,0,0-.57,1.49v5a.54.54,0,0,1-.31.51,1.54,1.54,0,0,1-.75.17,1.59,1.59,0,0,1-.72-.17.55.55,0,0,1-.32-.51v-5a2,2,0,0,0-.55-1.47,1.72,1.72,0,0,0-1.31-.58,1.79,1.79,0,0,0-1.31.56,2,2,0,0,0-.55,1.51v5a.61.61,0,0,1-.32.48,1.24,1.24,0,0,1-.74.22,1.2,1.2,0,0,1-.74-.22A.62.62,0,0,1,33,377.6Z"/>
            <path className="cls-1" d="M48.49,374.48v-1.9a3.65,3.65,0,0,1,1.22-2.73,4.32,4.32,0,0,1,5.75-.11,3.36,3.36,0,0,1,1.17,2.63,3.06,3.06,0,0,1-.14,1,.89.89,0,0,1-.44.52,1.73,1.73,0,0,1-.56.2,4.2,4.2,0,0,1-.68,0H50.59v.46a2,2,0,0,0,.72,1.61,2.9,2.9,0,0,0,1.88.59,3.35,3.35,0,0,0,1.55-.38,3.39,3.39,0,0,1,.89-.39.7.7,0,0,1,.58.33,1,1,0,0,1,.24.62c0,.33-.32.66-1,1a5.09,5.09,0,0,1-2.36.5,5,5,0,0,1-3.34-1.1A3.57,3.57,0,0,1,48.49,374.48Zm2.1-1.7h3.32a1,1,0,0,0,.59-.12.57.57,0,0,0,.15-.47,1.77,1.77,0,0,0-.58-1.34,2,2,0,0,0-1.44-.55,2.11,2.11,0,0,0-1.44.52,1.69,1.69,0,0,0-.6,1.33Z"/>
            <path className="cls-1" d="M57.43,369.62a.74.74,0,0,1,.44-.62,1.82,1.82,0,0,1,.9-.26.6.6,0,0,1,.64.38l2.22,6.22,1.7-5.52a.68.68,0,0,1,.74-.46.67.67,0,0,1,.74.46l1.68,5.56,2.24-6.26a.57.57,0,0,1,.62-.38,1.78,1.78,0,0,1,.89.26.75.75,0,0,1,.45.64.63.63,0,0,1-.08.32l-3.1,8.12q-.15.42-.84.42c-.45,0-.74-.13-.86-.4l-1.74-5.16L62.35,378c-.12.31-.42.46-.9.46a.79.79,0,0,1-.84-.46L57.51,370A1.39,1.39,0,0,1,57.43,369.62Z"/>
            <path className="cls-1" d="M71.63,374.48v-1.84a3.74,3.74,0,0,1,1.22-2.76,4.19,4.19,0,0,1,5.82,0,3.67,3.67,0,0,1,1.24,2.77v1.84a3.74,3.74,0,0,1-1.25,2.77,4,4,0,0,1-2.89,1.21,4.05,4.05,0,0,1-2.9-1.2A3.75,3.75,0,0,1,71.63,374.48Zm2.1,0a2.08,2.08,0,0,0,.6,1.46,1.91,1.91,0,0,0,2.87,0,2,2,0,0,0,.61-1.46v-1.82a2,2,0,0,0-.61-1.43,1.93,1.93,0,0,0-2.87,0,2,2,0,0,0-.6,1.43Z"/>
            <path className="cls-1" d="M81.75,377.6v-8.1A.58.58,0,0,1,82,369a1.3,1.3,0,0,1,.73-.19,1.14,1.14,0,0,1,.68.19.58.58,0,0,1,.28.5v.89a3.19,3.19,0,0,1,1-1.2,2.56,2.56,0,0,1,1.51-.5h.64a.72.72,0,0,1,.56.27,1,1,0,0,1,.24.66,1,1,0,0,1-.24.67.7.7,0,0,1-.56.28h-.64a2.2,2.2,0,0,0-1.69.82,3.07,3.07,0,0,0-.73,2.11v4.09a.59.59,0,0,1-.32.48,1.26,1.26,0,0,1-.76.22,1.17,1.17,0,0,1-.73-.22A.63.63,0,0,1,81.75,377.6Z"/>
            <path className="cls-1" d="M89,377.6V363.86a.57.57,0,0,1,.28-.49,1.27,1.27,0,0,1,.74-.19,1.42,1.42,0,0,1,.77.19.56.56,0,0,1,.31.49v8.9l4.18-3.84a.6.6,0,0,1,.44-.18,1,1,0,0,1,.68.32.92.92,0,0,1,.34.68.6.6,0,0,1-.18.44L94,372.48,97.09,377a.65.65,0,0,1,.12.36,1,1,0,0,1-.4.71,1.21,1.21,0,0,1-.78.35.67.67,0,0,1-.56-.3l-2.94-4.34-1.46,1.32v2.5a.62.62,0,0,1-.32.48,1.26,1.26,0,0,1-.76.22,1.15,1.15,0,0,1-.73-.22A.63.63,0,0,1,89,377.6Z"/>
            <path className="cls-1" d="M102.33,374.64v-2.18a3.57,3.57,0,0,1,1.12-2.63,3.65,3.65,0,0,1,2.66-1.11,3.74,3.74,0,0,1,2.68,1.1,3.53,3.53,0,0,1,1.14,2.64v2.18a3.93,3.93,0,0,1-3.82,3.8,3.6,3.6,0,0,1-2.64-1.15A3.66,3.66,0,0,1,102.33,374.64Zm1.3,0a2.54,2.54,0,0,0,.73,1.8,2.29,2.29,0,0,0,1.75.79,2.34,2.34,0,0,0,1.78-.79,2.55,2.55,0,0,0,.74-1.8v-2.16a2.44,2.44,0,0,0-.75-1.75,2.38,2.38,0,0,0-1.77-.78,2.32,2.32,0,0,0-1.75.78,2.46,2.46,0,0,0-.73,1.75Z"/>
            <path className="cls-1" d="M112.07,382.44V369.32a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.72.72,0,0,1,.44.14.44.44,0,0,1,.18.35v.93a3,3,0,0,1,1.06-1.09,3,3,0,0,1,1.62-.45,3.42,3.42,0,0,1,2.54,1.1,3.65,3.65,0,0,1,1.06,2.64v2.18a3.8,3.8,0,0,1-1.07,2.65,3.27,3.27,0,0,1-2.47,1.15,3,3,0,0,1-1.62-.46,2.91,2.91,0,0,1-1.08-1.1v5.56a.45.45,0,0,1-.19.34.69.69,0,0,1-.47.16.67.67,0,0,1-.45-.16A.45.45,0,0,1,112.07,382.44Zm1.3-7.19a1.92,1.92,0,0,0,.75,1.25,2.4,2.4,0,0,0,1.71.72,2.29,2.29,0,0,0,1.73-.8,2.53,2.53,0,0,0,.75-1.79v-2.16a2.44,2.44,0,0,0-.75-1.75,2.42,2.42,0,0,0-4.19,1.75Z"/>
            <path className="cls-1" d="M121.39,374.64V372.4a3.48,3.48,0,0,1,1.12-2.59,3.87,3.87,0,0,1,5.33-.05,3.43,3.43,0,0,1,1.11,2.6,2.45,2.45,0,0,1-.13.9.84.84,0,0,1-.42.48,2.21,2.21,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0h-4.52v.74a2.32,2.32,0,0,0,.85,1.89,3.7,3.7,0,0,0,3.38.51,3.47,3.47,0,0,0,.78-.4.84.84,0,0,1,.43-.2.49.49,0,0,1,.39.21.69.69,0,0,1,.17.41c0,.28-.29.57-.86.87a4.58,4.58,0,0,1-2.14.45,4.53,4.53,0,0,1-3.1-1.05A3.48,3.48,0,0,1,121.39,374.64Zm1.3-1.64h4a1.59,1.59,0,0,0,.8-.13c.13-.08.2-.27.2-.55a2.47,2.47,0,0,0-.71-1.74,2.33,2.33,0,0,0-1.79-.76,2.44,2.44,0,0,0-1.77.72,2.27,2.27,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M130.91,377.78v-8.46a.41.41,0,0,1,.18-.35.74.74,0,0,1,.46-.13.68.68,0,0,1,.42.14.44.44,0,0,1,.18.35v.93a2.85,2.85,0,0,1,1-1.09,3,3,0,0,1,1.64-.45,3.38,3.38,0,0,1,2.5,1.1,3.65,3.65,0,0,1,1.06,2.64v5.32a.44.44,0,0,1-.2.38.81.81,0,0,1-.46.14.77.77,0,0,1-.44-.14.44.44,0,0,1-.2-.38v-5.31a2.49,2.49,0,0,0-.74-1.75,2.34,2.34,0,0,0-1.76-.78,2.29,2.29,0,0,0-1.66.7,2.43,2.43,0,0,0-.72,1.83v5.31a.48.48,0,0,1-.2.36.7.7,0,0,1-.46.16.65.65,0,0,1-.46-.16A.48.48,0,0,1,130.91,377.78Z"/>
            <path className="cls-1" d="M140.23,377a.72.72,0,0,1,.14-.4.4.4,0,0,1,.32-.2,1,1,0,0,1,.44.23,4.35,4.35,0,0,0,.86.47,3.38,3.38,0,0,0,1.34.24,2.45,2.45,0,0,0,1.56-.4,1.27,1.27,0,0,0,.5-1,1.43,1.43,0,0,0-.51-1.15,3.44,3.44,0,0,0-1.23-.65l-1.45-.44a2.72,2.72,0,0,1-1.24-.77,2.09,2.09,0,0,1-.51-1.47,2.64,2.64,0,0,1,.79-1.91,3.18,3.18,0,0,1,2.37-.81,5.25,5.25,0,0,1,1.83.31c.57.21.85.43.85.67a.79.79,0,0,1-.16.44.46.46,0,0,1-.38.24,5.24,5.24,0,0,1-.74-.28,3.51,3.51,0,0,0-1.38-.28,2.12,2.12,0,0,0-1.5.47,1.44,1.44,0,0,0-.5,1.06,1.11,1.11,0,0,0,.52,1,4.75,4.75,0,0,0,1.24.57l1.46.43a2.33,2.33,0,0,1,1.24.87,2.69,2.69,0,0,1,.5,1.68,2.29,2.29,0,0,1-.85,1.89,3.68,3.68,0,0,1-2.37.69,4.87,4.87,0,0,1-2.23-.47C140.53,377.66,140.23,377.33,140.23,377Z"/>
            <path className="cls-1" d="M152.49,375.36V364.84a.42.42,0,0,1,.2-.34.77.77,0,0,1,.44-.14.81.81,0,0,1,.46.14.42.42,0,0,1,.2.34v4h2.58a.41.41,0,0,1,.33.16.65.65,0,0,1,0,.78.41.41,0,0,1-.33.16h-2.58v5.39a1.83,1.83,0,0,0,.41,1.36,2.1,2.1,0,0,0,1.45.39h.56a.57.57,0,0,1,.43.18.57.57,0,0,1,.17.42.61.61,0,0,1-.17.43.54.54,0,0,1-.43.19h-.56Q152.48,378.3,152.49,375.36Z"/>
            <path className="cls-1" d="M158.47,377.78V363.54a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.79.79,0,0,1,.47.14.4.4,0,0,1,.19.34v6.72a3,3,0,0,1,2.64-1.54,3.34,3.34,0,0,1,2.49,1.1,3.68,3.68,0,0,1,1,2.64v5.32a.44.44,0,0,1-.19.38.79.79,0,0,1-.47.14.77.77,0,0,1-.44-.14.44.44,0,0,1-.2-.38v-5.31a2.49,2.49,0,0,0-.74-1.75,2.34,2.34,0,0,0-3.41-.08,2.4,2.4,0,0,0-.73,1.83v5.31a.48.48,0,0,1-.2.36.7.7,0,0,1-.46.16.67.67,0,0,1-.45-.16A.47.47,0,0,1,158.47,377.78Z"/>
            <path className="cls-1" d="M168,374.64V372.4a3.48,3.48,0,0,1,1.12-2.59,3.87,3.87,0,0,1,5.33-.05,3.43,3.43,0,0,1,1.11,2.6,2.45,2.45,0,0,1-.13.9.84.84,0,0,1-.42.48,2.21,2.21,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0h-4.52v.74a2.32,2.32,0,0,0,.85,1.89,3.7,3.7,0,0,0,3.38.51,3.47,3.47,0,0,0,.78-.4.84.84,0,0,1,.43-.2.49.49,0,0,1,.39.21.69.69,0,0,1,.17.41c0,.28-.29.57-.86.87a4.58,4.58,0,0,1-2.14.45,4.53,4.53,0,0,1-3.1-1.05A3.48,3.48,0,0,1,168,374.64Zm1.3-1.64h4a1.59,1.59,0,0,0,.8-.13c.13-.08.2-.27.2-.55a2.47,2.47,0,0,0-.71-1.74,2.33,2.33,0,0,0-1.79-.76,2.44,2.44,0,0,0-1.77.72,2.27,2.27,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M181,377a.72.72,0,0,1,.14-.4.4.4,0,0,1,.32-.2,1,1,0,0,1,.44.23,4.35,4.35,0,0,0,.86.47,3.38,3.38,0,0,0,1.34.24,2.45,2.45,0,0,0,1.56-.4,1.27,1.27,0,0,0,.5-1,1.43,1.43,0,0,0-.51-1.15,3.44,3.44,0,0,0-1.23-.65l-1.45-.44a2.72,2.72,0,0,1-1.24-.77,2.09,2.09,0,0,1-.51-1.47,2.64,2.64,0,0,1,.79-1.91,3.18,3.18,0,0,1,2.37-.81,5.25,5.25,0,0,1,1.83.31c.57.21.85.43.85.67a.79.79,0,0,1-.16.44.46.46,0,0,1-.38.24,5.24,5.24,0,0,1-.74-.28,3.51,3.51,0,0,0-1.38-.28,2.12,2.12,0,0,0-1.5.47,1.44,1.44,0,0,0-.5,1.06,1.11,1.11,0,0,0,.52,1,4.75,4.75,0,0,0,1.24.57l1.46.43a2.29,2.29,0,0,1,1.24.87,2.69,2.69,0,0,1,.5,1.68,2.29,2.29,0,0,1-.85,1.89,3.68,3.68,0,0,1-2.37.69,4.87,4.87,0,0,1-2.23-.47C181.25,377.66,181,377.33,181,377Z"/>
            <path className="cls-1" d="M189.25,382.44V369.32a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.72.72,0,0,1,.44.14.44.44,0,0,1,.18.35v.93a3,3,0,0,1,1.06-1.09,3,3,0,0,1,1.62-.45,3.42,3.42,0,0,1,2.54,1.1,3.65,3.65,0,0,1,1.06,2.64v2.18a3.8,3.8,0,0,1-1.07,2.65,3.27,3.27,0,0,1-2.47,1.15,3,3,0,0,1-1.62-.46,2.91,2.91,0,0,1-1.08-1.1v5.56a.45.45,0,0,1-.19.34.69.69,0,0,1-.47.16.67.67,0,0,1-.45-.16A.45.45,0,0,1,189.25,382.44Zm1.3-7.19a1.92,1.92,0,0,0,.75,1.25,2.4,2.4,0,0,0,1.71.72,2.29,2.29,0,0,0,1.73-.8,2.53,2.53,0,0,0,.75-1.79v-2.16a2.44,2.44,0,0,0-.75-1.75,2.42,2.42,0,0,0-4.19,1.75Z"/>
            <path className="cls-1" d="M198.27,375.8a2.25,2.25,0,0,1,1.19-2.19,7.76,7.76,0,0,1,3.47-.61h1v-.47a3.09,3.09,0,0,0-.58-1.88,2.08,2.08,0,0,0-1.78-.79,3.75,3.75,0,0,0-1.45.28,7.44,7.44,0,0,1-.69.29.42.42,0,0,1-.38-.22.86.86,0,0,1-.14-.47c0-.25.29-.49.86-.7a5.31,5.31,0,0,1,1.84-.32,3.3,3.3,0,0,1,2.75,1.09,4.31,4.31,0,0,1,.87,2.77v5.2a.46.46,0,0,1-.18.37.69.69,0,0,1-.46.15.63.63,0,0,1-.43-.15.48.48,0,0,1-.17-.37v-.88a3.93,3.93,0,0,1-3,1.54,2.84,2.84,0,0,1-2-.67A2.48,2.48,0,0,1,198.27,375.8Zm1.3-.12a1.55,1.55,0,0,0,.48,1.25,1.89,1.89,0,0,0,1.26.41,2.76,2.76,0,0,0,1.79-.7,1.9,1.9,0,0,0,.85-1.4V374h-.84a12.84,12.84,0,0,0-1.35.06,5.85,5.85,0,0,0-1.09.22,1.6,1.6,0,0,0-.82.52A1.44,1.44,0,0,0,199.57,375.68Z"/>
            <path className="cls-1" d="M207.35,374.64V372.5a3.86,3.86,0,0,1,1-2.73,3.74,3.74,0,0,1,2.88-1,4.21,4.21,0,0,1,2,.43q.81.44.81.87a.65.65,0,0,1-.18.43.52.52,0,0,1-.4.21.79.79,0,0,1-.37-.18,3.9,3.9,0,0,0-.71-.35,2.81,2.81,0,0,0-1.1-.19,2.64,2.64,0,0,0-2,.68,2.55,2.55,0,0,0-.67,1.89v2.12a2.57,2.57,0,0,0,.67,1.91,2.66,2.66,0,0,0,2,.68,3.23,3.23,0,0,0,1.15-.18,3.92,3.92,0,0,0,.72-.37.75.75,0,0,1,.39-.19.55.55,0,0,1,.43.2.67.67,0,0,1,.17.44c0,.31-.28.6-.84.89a4.48,4.48,0,0,1-2.08.43,3.81,3.81,0,0,1-2.9-1A3.86,3.86,0,0,1,207.35,374.64Z"/>
            <path className="cls-1" d="M215.45,374.64V372.4a3.48,3.48,0,0,1,1.12-2.59,3.87,3.87,0,0,1,5.33-.05,3.43,3.43,0,0,1,1.11,2.6,2.45,2.45,0,0,1-.13.9.84.84,0,0,1-.42.48,2.21,2.21,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0h-4.52v.74a2.32,2.32,0,0,0,.85,1.89,3.7,3.7,0,0,0,3.38.51,3.47,3.47,0,0,0,.78-.4.84.84,0,0,1,.43-.2.49.49,0,0,1,.39.21.69.69,0,0,1,.17.41c0,.28-.29.57-.86.87a4.58,4.58,0,0,1-2.14.45,4.53,4.53,0,0,1-3.1-1.05A3.48,3.48,0,0,1,215.45,374.64Zm1.3-1.64h4a1.59,1.59,0,0,0,.8-.13c.13-.08.2-.27.2-.55a2.47,2.47,0,0,0-.71-1.74,2.33,2.33,0,0,0-1.79-.76,2.44,2.44,0,0,0-1.77.72,2.27,2.27,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M229,377.8V366.69a3.6,3.6,0,0,1,1.06-2.6,3.37,3.37,0,0,1,2.5-1.09h.5a.5.5,0,0,1,.4.19.62.62,0,0,1,.16.43.57.57,0,0,1-.16.42.52.52,0,0,1-.4.18h-.34a2.3,2.3,0,0,0-1.71.71,2.4,2.4,0,0,0-.71,1.69v2.22h2.86a.41.41,0,0,1,.33.16.56.56,0,0,1,.13.37.63.63,0,0,1-.13.41.41.41,0,0,1-.33.16h-2.86v7.86a.42.42,0,0,1-.2.36.83.83,0,0,1-.48.14.72.72,0,0,1-.44-.14A.43.43,0,0,1,229,377.8Z"/>
            <path className="cls-1" d="M234.35,374.64v-2.18a3.57,3.57,0,0,1,1.12-2.63,3.65,3.65,0,0,1,2.66-1.11,3.74,3.74,0,0,1,2.68,1.1,3.53,3.53,0,0,1,1.14,2.64v2.18a3.93,3.93,0,0,1-3.82,3.8,3.6,3.6,0,0,1-2.64-1.15A3.66,3.66,0,0,1,234.35,374.64Zm1.3,0a2.54,2.54,0,0,0,.73,1.8,2.29,2.29,0,0,0,1.75.79,2.34,2.34,0,0,0,1.78-.79,2.55,2.55,0,0,0,.74-1.8v-2.16a2.44,2.44,0,0,0-.75-1.75,2.38,2.38,0,0,0-1.77-.78,2.32,2.32,0,0,0-1.75.78,2.46,2.46,0,0,0-.73,1.75Z"/>
            <path className="cls-1" d="M244.09,377.78v-8.46a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.72.72,0,0,1,.44.14.44.44,0,0,1,.18.35v1a3.08,3.08,0,0,1,1.06-1.13,2.88,2.88,0,0,1,1.62-.47h.7a.48.48,0,0,1,.36.18.66.66,0,0,1,0,.86.48.48,0,0,1-.36.18H248a2.51,2.51,0,0,0-1.84.81,2.87,2.87,0,0,0-.8,2.1v4.93a.47.47,0,0,1-.19.36.69.69,0,0,1-.47.16.67.67,0,0,1-.45-.16A.47.47,0,0,1,244.09,377.78Z"/>
            <path className="cls-1" d="M254.67,375.36V364.84a.42.42,0,0,1,.2-.34.77.77,0,0,1,.44-.14.81.81,0,0,1,.46.14.42.42,0,0,1,.2.34v4h2.58a.41.41,0,0,1,.33.16.65.65,0,0,1,0,.78.41.41,0,0,1-.33.16H256v5.39a1.83,1.83,0,0,0,.41,1.36,2.1,2.1,0,0,0,1.45.39h.56a.57.57,0,0,1,.43.18.57.57,0,0,1,.17.42.61.61,0,0,1-.17.43.54.54,0,0,1-.43.19h-.56Q254.67,378.3,254.67,375.36Z"/>
            <path className="cls-1" d="M260.65,377.78V363.54a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.79.79,0,0,1,.47.14.4.4,0,0,1,.19.34v6.72a3,3,0,0,1,2.64-1.54,3.34,3.34,0,0,1,2.49,1.1,3.68,3.68,0,0,1,1.05,2.64v5.32a.44.44,0,0,1-.19.38.79.79,0,0,1-.47.14.77.77,0,0,1-.44-.14.44.44,0,0,1-.2-.38v-5.31a2.49,2.49,0,0,0-.74-1.75,2.34,2.34,0,0,0-3.41-.08,2.4,2.4,0,0,0-.73,1.83v5.31a.48.48,0,0,1-.2.36.7.7,0,0,1-.46.16.67.67,0,0,1-.45-.16A.47.47,0,0,1,260.65,377.78Z"/>
            <path className="cls-1" d="M270.21,374.64V372.4a3.48,3.48,0,0,1,1.12-2.59,3.87,3.87,0,0,1,5.33-.05,3.43,3.43,0,0,1,1.11,2.6,2.45,2.45,0,0,1-.13.9.84.84,0,0,1-.42.48,2.21,2.21,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0h-4.52v.74a2.32,2.32,0,0,0,.85,1.89,3.7,3.7,0,0,0,3.38.51,3.47,3.47,0,0,0,.78-.4.84.84,0,0,1,.43-.2.49.49,0,0,1,.39.21.69.69,0,0,1,.17.41c0,.28-.29.57-.86.87a4.58,4.58,0,0,1-2.14.45,4.53,4.53,0,0,1-3.1-1.05A3.48,3.48,0,0,1,270.21,374.64Zm1.3-1.64h4a1.59,1.59,0,0,0,.8-.13c.13-.08.2-.27.2-.55a2.47,2.47,0,0,0-.71-1.74,2.33,2.33,0,0,0-1.79-.76,2.44,2.44,0,0,0-1.77.72,2.27,2.27,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M283.73,382.44V369.32a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.72.72,0,0,1,.44.14.44.44,0,0,1,.18.35v.93a3,3,0,0,1,1.06-1.09,3,3,0,0,1,1.62-.45,3.42,3.42,0,0,1,2.54,1.1,3.65,3.65,0,0,1,1.06,2.64v2.18a3.8,3.8,0,0,1-1.07,2.65,3.27,3.27,0,0,1-2.47,1.15,3,3,0,0,1-1.62-.46,2.91,2.91,0,0,1-1.08-1.1v5.56a.45.45,0,0,1-.19.34.69.69,0,0,1-.47.16.67.67,0,0,1-.45-.16A.45.45,0,0,1,283.73,382.44Zm1.3-7.19a1.92,1.92,0,0,0,.75,1.25,2.4,2.4,0,0,0,1.71.72,2.29,2.29,0,0,0,1.73-.8,2.53,2.53,0,0,0,.75-1.79v-2.16a2.44,2.44,0,0,0-.75-1.75,2.42,2.42,0,0,0-4.19,1.75Z"/>
            <path className="cls-1" d="M293.41,377.78v-8.46a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.72.72,0,0,1,.44.14.44.44,0,0,1,.18.35v1a3.08,3.08,0,0,1,1.06-1.13,2.88,2.88,0,0,1,1.62-.47h.7a.48.48,0,0,1,.36.18.66.66,0,0,1,0,.86.48.48,0,0,1-.36.18h-.7a2.51,2.51,0,0,0-1.84.81,2.87,2.87,0,0,0-.8,2.1v4.93a.47.47,0,0,1-.19.36.69.69,0,0,1-.47.16.67.67,0,0,1-.45-.16A.47.47,0,0,1,293.41,377.78Z"/>
            <path className="cls-1" d="M299.29,374.64v-2.18a3.57,3.57,0,0,1,1.12-2.63,3.65,3.65,0,0,1,2.66-1.11,3.74,3.74,0,0,1,2.68,1.1,3.53,3.53,0,0,1,1.14,2.64v2.18a3.93,3.93,0,0,1-3.82,3.8,3.6,3.6,0,0,1-2.64-1.15A3.66,3.66,0,0,1,299.29,374.64Zm1.3,0a2.54,2.54,0,0,0,.73,1.8,2.29,2.29,0,0,0,1.75.79,2.34,2.34,0,0,0,1.78-.79,2.55,2.55,0,0,0,.74-1.8v-2.16a2.44,2.44,0,0,0-.75-1.75,2.38,2.38,0,0,0-1.77-.78,2.32,2.32,0,0,0-1.75.78,2.46,2.46,0,0,0-.73,1.75Z"/>
            <path className="cls-1" d="M308.69,374.64V372.5a3.86,3.86,0,0,1,1-2.73,3.74,3.74,0,0,1,2.88-1,4.21,4.21,0,0,1,2,.43q.81.44.81.87a.65.65,0,0,1-.18.43.52.52,0,0,1-.4.21.79.79,0,0,1-.37-.18,3.9,3.9,0,0,0-.71-.35,2.81,2.81,0,0,0-1.1-.19,2.64,2.64,0,0,0-1.95.68,2.55,2.55,0,0,0-.67,1.89v2.12a2.57,2.57,0,0,0,.67,1.91,2.66,2.66,0,0,0,2,.68,3.23,3.23,0,0,0,1.15-.18,3.92,3.92,0,0,0,.72-.37.75.75,0,0,1,.39-.19.55.55,0,0,1,.43.2.67.67,0,0,1,.17.44c0,.31-.28.6-.84.89a4.48,4.48,0,0,1-2.08.43,3.81,3.81,0,0,1-2.9-1A3.86,3.86,0,0,1,308.69,374.64Z"/>
            <path className="cls-1" d="M316.79,374.64V372.4a3.48,3.48,0,0,1,1.12-2.59,3.87,3.87,0,0,1,5.33-.05,3.43,3.43,0,0,1,1.11,2.6,2.45,2.45,0,0,1-.13.9.84.84,0,0,1-.42.48,2.21,2.21,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0h-4.52v.74a2.32,2.32,0,0,0,.85,1.89,3.7,3.7,0,0,0,3.38.51,3.47,3.47,0,0,0,.78-.4.84.84,0,0,1,.43-.2.49.49,0,0,1,.39.21.69.69,0,0,1,.17.41c0,.28-.29.57-.86.87a4.58,4.58,0,0,1-2.14.45,4.53,4.53,0,0,1-3.1-1.05A3.48,3.48,0,0,1,316.79,374.64Zm1.3-1.64h4a1.59,1.59,0,0,0,.8-.13c.13-.08.2-.27.2-.55a2.47,2.47,0,0,0-.71-1.74,2.33,2.33,0,0,0-1.79-.76,2.44,2.44,0,0,0-1.77.72,2.27,2.27,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M325.71,377a.72.72,0,0,1,.14-.4.4.4,0,0,1,.32-.2,1,1,0,0,1,.44.23,4.35,4.35,0,0,0,.86.47,3.38,3.38,0,0,0,1.34.24,2.45,2.45,0,0,0,1.56-.4,1.27,1.27,0,0,0,.5-1,1.43,1.43,0,0,0-.51-1.15,3.44,3.44,0,0,0-1.23-.65l-1.45-.44a2.72,2.72,0,0,1-1.24-.77,2.09,2.09,0,0,1-.51-1.47,2.64,2.64,0,0,1,.79-1.91,3.18,3.18,0,0,1,2.37-.81,5.25,5.25,0,0,1,1.83.31c.57.21.85.43.85.67a.79.79,0,0,1-.16.44.46.46,0,0,1-.38.24,5.24,5.24,0,0,1-.74-.28,3.51,3.51,0,0,0-1.38-.28,2.12,2.12,0,0,0-1.5.47,1.44,1.44,0,0,0-.5,1.06,1.11,1.11,0,0,0,.52,1,4.75,4.75,0,0,0,1.24.57l1.46.43a2.33,2.33,0,0,1,1.24.87,2.69,2.69,0,0,1,.5,1.68,2.29,2.29,0,0,1-.85,1.89,3.68,3.68,0,0,1-2.37.69,4.87,4.87,0,0,1-2.23-.47C326,377.66,325.71,377.33,325.71,377Z"/>
            <path className="cls-1" d="M333.41,377a.72.72,0,0,1,.14-.4.4.4,0,0,1,.32-.2,1,1,0,0,1,.44.23,4.35,4.35,0,0,0,.86.47,3.38,3.38,0,0,0,1.34.24,2.45,2.45,0,0,0,1.56-.4,1.27,1.27,0,0,0,.5-1,1.43,1.43,0,0,0-.51-1.15,3.44,3.44,0,0,0-1.23-.65l-1.45-.44a2.72,2.72,0,0,1-1.24-.77,2.09,2.09,0,0,1-.51-1.47,2.64,2.64,0,0,1,.79-1.91,3.18,3.18,0,0,1,2.37-.81,5.25,5.25,0,0,1,1.83.31c.57.21.85.43.85.67a.79.79,0,0,1-.16.44.46.46,0,0,1-.38.24,5.24,5.24,0,0,1-.74-.28,3.51,3.51,0,0,0-1.38-.28,2.12,2.12,0,0,0-1.5.47,1.44,1.44,0,0,0-.5,1.06,1.11,1.11,0,0,0,.52,1,4.75,4.75,0,0,0,1.24.57l1.46.43a2.33,2.33,0,0,1,1.24.87,2.69,2.69,0,0,1,.5,1.68,2.29,2.29,0,0,1-.85,1.89,3.68,3.68,0,0,1-2.37.69,4.87,4.87,0,0,1-2.23-.47C333.71,377.66,333.41,377.33,333.41,377Z"/>
            <path className="cls-1" d="M341.35,374.64V372.4a3.48,3.48,0,0,1,1.12-2.59,3.87,3.87,0,0,1,5.33-.05,3.43,3.43,0,0,1,1.11,2.6,2.45,2.45,0,0,1-.13.9.84.84,0,0,1-.42.48,2.21,2.21,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0h-4.52v.74a2.32,2.32,0,0,0,.85,1.89,3.7,3.7,0,0,0,3.38.51,3.47,3.47,0,0,0,.78-.4.84.84,0,0,1,.43-.2.49.49,0,0,1,.39.21.69.69,0,0,1,.17.41c0,.28-.29.57-.86.87a4.58,4.58,0,0,1-2.14.45,4.53,4.53,0,0,1-3.1-1.05A3.48,3.48,0,0,1,341.35,374.64Zm1.3-1.64h4a1.59,1.59,0,0,0,.8-.13c.13-.08.2-.27.2-.55a2.47,2.47,0,0,0-.71-1.74,2.33,2.33,0,0,0-1.79-.76,2.44,2.44,0,0,0-1.77.72,2.27,2.27,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M350.27,377a.72.72,0,0,1,.14-.4.4.4,0,0,1,.32-.2,1,1,0,0,1,.44.23,4.35,4.35,0,0,0,.86.47,3.38,3.38,0,0,0,1.34.24,2.45,2.45,0,0,0,1.56-.4,1.27,1.27,0,0,0,.5-1,1.43,1.43,0,0,0-.51-1.15,3.44,3.44,0,0,0-1.23-.65l-1.45-.44a2.72,2.72,0,0,1-1.24-.77,2.09,2.09,0,0,1-.51-1.47,2.64,2.64,0,0,1,.79-1.91,3.18,3.18,0,0,1,2.37-.81,5.25,5.25,0,0,1,1.83.31c.57.21.85.43.85.67a.79.79,0,0,1-.16.44.46.46,0,0,1-.38.24,5.24,5.24,0,0,1-.74-.28,3.51,3.51,0,0,0-1.38-.28,2.12,2.12,0,0,0-1.5.47,1.44,1.44,0,0,0-.5,1.06,1.11,1.11,0,0,0,.52,1,4.75,4.75,0,0,0,1.24.57l1.46.43a2.33,2.33,0,0,1,1.24.87,2.69,2.69,0,0,1,.5,1.68,2.29,2.29,0,0,1-.85,1.89,3.68,3.68,0,0,1-2.37.69,4.87,4.87,0,0,1-2.23-.47C350.57,377.66,350.27,377.33,350.27,377Z"/>
            <path className="cls-1" d="M361.91,375.8a2.25,2.25,0,0,1,1.19-2.19,7.76,7.76,0,0,1,3.47-.61h1v-.47a3.09,3.09,0,0,0-.58-1.88,2.08,2.08,0,0,0-1.78-.79,3.75,3.75,0,0,0-1.45.28,7.44,7.44,0,0,1-.69.29.42.42,0,0,1-.38-.22.86.86,0,0,1-.14-.47c0-.25.29-.49.86-.7a5.31,5.31,0,0,1,1.84-.32,3.3,3.3,0,0,1,2.75,1.09,4.31,4.31,0,0,1,.87,2.77v5.2a.46.46,0,0,1-.18.37.69.69,0,0,1-.46.15.63.63,0,0,1-.43-.15.48.48,0,0,1-.17-.37v-.88a3.93,3.93,0,0,1-3,1.54,2.84,2.84,0,0,1-2-.67A2.48,2.48,0,0,1,361.91,375.8Zm1.3-.12a1.55,1.55,0,0,0,.48,1.25,1.89,1.89,0,0,0,1.26.41,2.76,2.76,0,0,0,1.79-.7,1.9,1.9,0,0,0,.85-1.4V374h-.84a12.84,12.84,0,0,0-1.35.06,5.85,5.85,0,0,0-1.09.22,1.6,1.6,0,0,0-.82.52A1.44,1.44,0,0,0,363.21,375.68Z"/>
            <path className="cls-1" d="M371.33,377.78v-8.46a.41.41,0,0,1,.18-.35.74.74,0,0,1,.46-.13.68.68,0,0,1,.42.14.44.44,0,0,1,.18.35v.93a2.85,2.85,0,0,1,1-1.09,3,3,0,0,1,1.64-.45,3.38,3.38,0,0,1,2.5,1.1,3.65,3.65,0,0,1,1.06,2.64v5.32a.44.44,0,0,1-.2.38.81.81,0,0,1-.46.14.77.77,0,0,1-.44-.14.44.44,0,0,1-.2-.38v-5.31a2.49,2.49,0,0,0-.74-1.75,2.34,2.34,0,0,0-1.76-.78,2.29,2.29,0,0,0-1.66.7,2.43,2.43,0,0,0-.72,1.83v5.31a.48.48,0,0,1-.2.36.7.7,0,0,1-.46.16.65.65,0,0,1-.46-.16A.48.48,0,0,1,371.33,377.78Z"/>
            <path className="cls-1" d="M380.89,374.64v-2.18a3.68,3.68,0,0,1,1.05-2.64,3.38,3.38,0,0,1,2.53-1.1,3,3,0,0,1,2.64,1.54v-6.72a.42.42,0,0,1,.2-.34.83.83,0,0,1,.48-.14.69.69,0,0,1,.45.14.44.44,0,0,1,.17.34v14.24a.48.48,0,0,1-.18.36.63.63,0,0,1-.44.16.64.64,0,0,1-.43-.16.47.47,0,0,1-.19-.37v-.89a2.87,2.87,0,0,1-1.09,1.1,3.07,3.07,0,0,1-1.65.46,3.26,3.26,0,0,1-2.48-1.15A3.79,3.79,0,0,1,380.89,374.64Zm1.3,0a2.56,2.56,0,0,0,.74,1.79,2.27,2.27,0,0,0,1.74.8,2.36,2.36,0,0,0,1.7-.72,1.94,1.94,0,0,0,.74-1.25v-2.78a2.39,2.39,0,0,0-.73-1.83,2.38,2.38,0,0,0-3.45.08,2.47,2.47,0,0,0-.74,1.75Z"/>
            <path className="cls-1" d="M394.27,375.8a2.25,2.25,0,0,1,1.19-2.19,7.76,7.76,0,0,1,3.47-.61h1v-.47a3.09,3.09,0,0,0-.58-1.88,2.08,2.08,0,0,0-1.78-.79,3.75,3.75,0,0,0-1.45.28,7.44,7.44,0,0,1-.69.29.42.42,0,0,1-.38-.22.86.86,0,0,1-.14-.47c0-.25.29-.49.86-.7a5.31,5.31,0,0,1,1.84-.32,3.3,3.3,0,0,1,2.75,1.09,4.31,4.31,0,0,1,.87,2.77v5.2a.46.46,0,0,1-.18.37.69.69,0,0,1-.46.15.63.63,0,0,1-.43-.15.48.48,0,0,1-.17-.37v-.88a3.93,3.93,0,0,1-3,1.54,2.84,2.84,0,0,1-2-.67A2.48,2.48,0,0,1,394.27,375.8Zm1.3-.12a1.55,1.55,0,0,0,.48,1.25,1.89,1.89,0,0,0,1.26.41,2.76,2.76,0,0,0,1.79-.7,1.9,1.9,0,0,0,.85-1.4V374h-.84a12.84,12.84,0,0,0-1.35.06,5.85,5.85,0,0,0-1.09.22,1.6,1.6,0,0,0-.82.52A1.44,1.44,0,0,0,395.57,375.68Z"/>
            <path className="cls-1" d="M403.35,374.64V372.5a3.86,3.86,0,0,1,1-2.73,3.74,3.74,0,0,1,2.88-1,4.21,4.21,0,0,1,2,.43q.81.44.81.87a.65.65,0,0,1-.18.43.52.52,0,0,1-.4.21.79.79,0,0,1-.37-.18,3.9,3.9,0,0,0-.71-.35,2.81,2.81,0,0,0-1.1-.19,2.64,2.64,0,0,0-1.95.68,2.55,2.55,0,0,0-.67,1.89v2.12a2.57,2.57,0,0,0,.67,1.91,2.66,2.66,0,0,0,2,.68,3.23,3.23,0,0,0,1.15-.18,3.92,3.92,0,0,0,.72-.37.75.75,0,0,1,.39-.19.55.55,0,0,1,.43.2.67.67,0,0,1,.17.44c0,.31-.28.6-.84.89a4.48,4.48,0,0,1-2.08.43,3.81,3.81,0,0,1-2.9-1A3.86,3.86,0,0,1,403.35,374.64Z"/>
            <path className="cls-1" d="M411.87,375.36V364.84a.42.42,0,0,1,.2-.34.77.77,0,0,1,.44-.14.81.81,0,0,1,.46.14.42.42,0,0,1,.2.34v4h2.58a.41.41,0,0,1,.33.16.65.65,0,0,1,0,.78.41.41,0,0,1-.33.16h-2.58v5.39a1.83,1.83,0,0,0,.41,1.36,2.1,2.1,0,0,0,1.45.39h.56a.57.57,0,0,1,.43.18.57.57,0,0,1,.17.42.61.61,0,0,1-.17.43.54.54,0,0,1-.43.19H415Q411.87,378.3,411.87,375.36Z"/>
            <path className="cls-1" d="M417.85,365.79a.8.8,0,0,1-.26-.59.77.77,0,0,1,.26-.58.91.91,0,0,1,.64-.24.85.85,0,0,1,.61.24.76.76,0,0,1,.25.58.79.79,0,0,1-.25.59.82.82,0,0,1-.61.25A.87.87,0,0,1,417.85,365.79Zm0,12v-8.46A.42.42,0,0,1,418,369a.74.74,0,0,1,.46-.14.79.79,0,0,1,.47.14.43.43,0,0,1,.19.34v8.46a.48.48,0,0,1-.2.36.7.7,0,0,1-.46.16.67.67,0,0,1-.45-.16A.47.47,0,0,1,417.85,377.78Z"/>
            <path className="cls-1" d="M420.71,369.4A.51.51,0,0,1,421,369a.89.89,0,0,1,.53-.18.47.47,0,0,1,.48.3l2.64,7.2,2.64-7.12c.11-.25.26-.38.46-.38a1,1,0,0,1,.52.18.49.49,0,0,1,.28.4.76.76,0,0,1-.08.32l-3.22,8.44c-.08.21-.28.32-.6.32s-.52-.11-.6-.32l-3.22-8.44A.56.56,0,0,1,420.71,369.4Z"/>
            <path className="cls-1" d="M430.07,365.79a.8.8,0,0,1-.26-.59.77.77,0,0,1,.26-.58.91.91,0,0,1,.64-.24.85.85,0,0,1,.61.24.76.76,0,0,1,.25.58.79.79,0,0,1-.25.59.82.82,0,0,1-.61.25A.87.87,0,0,1,430.07,365.79Zm0,12v-8.46a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.79.79,0,0,1,.47.14.4.4,0,0,1,.19.34v8.46a.48.48,0,0,1-.2.36.7.7,0,0,1-.46.16.67.67,0,0,1-.45-.16A.47.47,0,0,1,430.07,377.78Z"/>
            <path className="cls-1" d="M433.85,375.36V364.84a.42.42,0,0,1,.2-.34.77.77,0,0,1,.44-.14.81.81,0,0,1,.46.14.42.42,0,0,1,.2.34v4h2.58a.41.41,0,0,1,.33.16.65.65,0,0,1,0,.78.41.41,0,0,1-.33.16h-2.58v5.39a1.83,1.83,0,0,0,.41,1.36,2.1,2.1,0,0,0,1.45.39h.56a.57.57,0,0,1,.43.18.57.57,0,0,1,.17.42.61.61,0,0,1-.17.43.54.54,0,0,1-.43.19H437Q433.85,378.3,433.85,375.36Z"/>
            <path className="cls-1" d="M439.83,365.79a.8.8,0,0,1-.26-.59.77.77,0,0,1,.26-.58.91.91,0,0,1,.64-.24.85.85,0,0,1,.61.24.76.76,0,0,1,.25.58.79.79,0,0,1-.25.59.82.82,0,0,1-.61.25A.87.87,0,0,1,439.83,365.79Zm0,12v-8.46A.42.42,0,0,1,440,369a.74.74,0,0,1,.46-.14.79.79,0,0,1,.47.14.4.4,0,0,1,.19.34v8.46a.48.48,0,0,1-.2.36.7.7,0,0,1-.46.16.67.67,0,0,1-.45-.16A.47.47,0,0,1,439.83,377.78Z"/>
            <path className="cls-1" d="M443.29,374.64V372.4a3.48,3.48,0,0,1,1.12-2.59,3.87,3.87,0,0,1,5.33-.05,3.43,3.43,0,0,1,1.11,2.6,2.45,2.45,0,0,1-.13.9.84.84,0,0,1-.42.48,2.21,2.21,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0h-4.52v.74a2.32,2.32,0,0,0,.85,1.89,3.7,3.7,0,0,0,3.38.51,3.47,3.47,0,0,0,.78-.4.84.84,0,0,1,.43-.2.49.49,0,0,1,.39.21.69.69,0,0,1,.17.41c0,.28-.29.57-.86.87a4.58,4.58,0,0,1-2.14.45,4.53,4.53,0,0,1-3.1-1.05A3.48,3.48,0,0,1,443.29,374.64Zm1.3-1.64h4a1.59,1.59,0,0,0,.8-.13c.13-.08.2-.27.2-.55a2.47,2.47,0,0,0-.71-1.74,2.33,2.33,0,0,0-1.79-.76,2.44,2.44,0,0,0-1.77.72,2.27,2.27,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M452.21,377a.72.72,0,0,1,.14-.4.4.4,0,0,1,.32-.2,1,1,0,0,1,.44.23,4.35,4.35,0,0,0,.86.47,3.38,3.38,0,0,0,1.34.24,2.45,2.45,0,0,0,1.56-.4,1.27,1.27,0,0,0,.5-1,1.43,1.43,0,0,0-.51-1.15,3.44,3.44,0,0,0-1.23-.65l-1.45-.44a2.72,2.72,0,0,1-1.24-.77,2.09,2.09,0,0,1-.51-1.47,2.64,2.64,0,0,1,.79-1.91,3.18,3.18,0,0,1,2.37-.81,5.25,5.25,0,0,1,1.83.31c.57.21.85.43.85.67a.79.79,0,0,1-.16.44.46.46,0,0,1-.38.24,5.24,5.24,0,0,1-.74-.28,3.51,3.51,0,0,0-1.38-.28,2.12,2.12,0,0,0-1.5.47,1.44,1.44,0,0,0-.5,1.06,1.11,1.11,0,0,0,.52,1,4.75,4.75,0,0,0,1.24.57l1.46.43a2.33,2.33,0,0,1,1.24.87,2.69,2.69,0,0,1,.5,1.68,2.29,2.29,0,0,1-.85,1.89,3.68,3.68,0,0,1-2.37.69,4.87,4.87,0,0,1-2.23-.47C452.51,377.66,452.21,377.33,452.21,377Z"/>
            <path className="cls-1" d="M464.13,374.64v-2.18a3.57,3.57,0,0,1,1.12-2.63,3.65,3.65,0,0,1,2.66-1.11,3.74,3.74,0,0,1,2.68,1.1,3.53,3.53,0,0,1,1.14,2.64v2.18a3.93,3.93,0,0,1-3.82,3.8,3.6,3.6,0,0,1-2.64-1.15A3.66,3.66,0,0,1,464.13,374.64Zm1.3,0a2.54,2.54,0,0,0,.73,1.8,2.29,2.29,0,0,0,1.75.79,2.34,2.34,0,0,0,1.78-.79,2.55,2.55,0,0,0,.74-1.8v-2.16a2.44,2.44,0,0,0-.75-1.75,2.38,2.38,0,0,0-1.77-.78,2.32,2.32,0,0,0-1.75.78,2.46,2.46,0,0,0-.73,1.75Z"/>
            <path className="cls-1" d="M473.87,377.8V366.69a3.6,3.6,0,0,1,1.06-2.6,3.37,3.37,0,0,1,2.5-1.09h.5a.5.5,0,0,1,.4.19.62.62,0,0,1,.16.43.57.57,0,0,1-.16.42.52.52,0,0,1-.4.18h-.34a2.3,2.3,0,0,0-1.71.71,2.4,2.4,0,0,0-.71,1.69v2.22H478a.41.41,0,0,1,.33.16.56.56,0,0,1,.13.37.63.63,0,0,1-.13.41.41.41,0,0,1-.33.16h-2.86v7.86a.42.42,0,0,1-.2.36.83.83,0,0,1-.48.14.72.72,0,0,1-.44-.14A.43.43,0,0,1,473.87,377.8Z"/>
            <path className="cls-1" d="M5.89,408.36V397.84a.39.39,0,0,1,.2-.34.77.77,0,0,1,.44-.14.81.81,0,0,1,.46.14.42.42,0,0,1,.2.34v4H9.77a.41.41,0,0,1,.33.16.65.65,0,0,1,0,.78.41.41,0,0,1-.33.16H7.19v5.39a1.83,1.83,0,0,0,.41,1.36,2.1,2.1,0,0,0,1.45.39h.56a.57.57,0,0,1,.43.18.57.57,0,0,1,.17.42.61.61,0,0,1-.17.43.54.54,0,0,1-.43.19H9.05Q5.89,411.3,5.89,408.36Z"/>
            <path className="cls-1" d="M11.87,410.78V396.54a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.79.79,0,0,1,.47.14.4.4,0,0,1,.19.34v6.72a3,3,0,0,1,2.64-1.54,3.34,3.34,0,0,1,2.49,1.1,3.68,3.68,0,0,1,1.05,2.64v5.32a.44.44,0,0,1-.19.38.79.79,0,0,1-.47.14.77.77,0,0,1-.44-.14.44.44,0,0,1-.2-.38v-5.31a2.49,2.49,0,0,0-.74-1.75,2.34,2.34,0,0,0-3.41-.08,2.4,2.4,0,0,0-.73,1.83v5.31a.48.48,0,0,1-.2.36.7.7,0,0,1-.46.16.67.67,0,0,1-.45-.16A.47.47,0,0,1,11.87,410.78Z"/>
            <path className="cls-1" d="M21.43,407.64V405.4a3.48,3.48,0,0,1,1.12-2.59,3.87,3.87,0,0,1,5.33-.05,3.43,3.43,0,0,1,1.11,2.6,2.45,2.45,0,0,1-.13.9.84.84,0,0,1-.42.48,2.21,2.21,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0H22.73v.74a2.32,2.32,0,0,0,.85,1.89,3.7,3.7,0,0,0,3.38.51,3.47,3.47,0,0,0,.78-.4.84.84,0,0,1,.43-.2.49.49,0,0,1,.39.21.69.69,0,0,1,.17.41c0,.28-.29.57-.86.87a4.58,4.58,0,0,1-2.14.45,4.53,4.53,0,0,1-3.1-1.05A3.48,3.48,0,0,1,21.43,407.64Zm1.3-1.64h4a1.59,1.59,0,0,0,.8-.13c.13-.08.2-.27.2-.55a2.47,2.47,0,0,0-.71-1.74,2.33,2.33,0,0,0-1.79-.76,2.44,2.44,0,0,0-1.77.72,2.27,2.27,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M34.41,409.3a.8.8,0,0,1,.19-.49.52.52,0,0,1,.41-.25c.11,0,.24.09.4.26l.56.58a2.67,2.67,0,0,0,1,.57,4.12,4.12,0,0,0,1.51.25,3.16,3.16,0,0,0,2.2-.71,2.63,2.63,0,0,0,.78-2.05,2.94,2.94,0,0,0-.37-1.53,2.68,2.68,0,0,0-.95-1,7.28,7.28,0,0,0-1.28-.64l-1.44-.56a6.52,6.52,0,0,1-1.29-.67,3,3,0,0,1-.94-1.1,3.57,3.57,0,0,1-.37-1.69,3.86,3.86,0,0,1,.39-1.8,2.77,2.77,0,0,1,1.05-1.14,5.19,5.19,0,0,1,1.35-.55,6.19,6.19,0,0,1,1.45-.17,7.42,7.42,0,0,1,2.29.35c.71.23,1.07.52,1.07.87a.91.91,0,0,1-.17.49.48.48,0,0,1-.41.25,1.33,1.33,0,0,1-.46-.18,5.15,5.15,0,0,0-.95-.38,4.93,4.93,0,0,0-1.39-.18,3.72,3.72,0,0,0-2.08.54,2,2,0,0,0-.82,1.78,2.15,2.15,0,0,0,.49,1.44,2.77,2.77,0,0,0,1.23.84c.49.17,1,.38,1.6.62a17,17,0,0,1,1.6.77,3.23,3.23,0,0,1,1.23,1.3,4.43,4.43,0,0,1,.49,2.19,3.94,3.94,0,0,1-1.14,3.08,4.45,4.45,0,0,1-3.08,1,5.43,5.43,0,0,1-2.92-.74C34.8,410.2,34.41,409.74,34.41,409.3Z"/>
            <path className="cls-1" d="M44.47,407.64V405.4a3.48,3.48,0,0,1,1.12-2.59,3.87,3.87,0,0,1,5.33-.05,3.43,3.43,0,0,1,1.11,2.6,2.45,2.45,0,0,1-.13.9.84.84,0,0,1-.42.48,2.21,2.21,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0H45.77v.74a2.32,2.32,0,0,0,.85,1.89,3.7,3.7,0,0,0,3.38.51,3.47,3.47,0,0,0,.78-.4.84.84,0,0,1,.43-.2.49.49,0,0,1,.39.21.69.69,0,0,1,.17.41c0,.28-.29.57-.86.87a4.58,4.58,0,0,1-2.14.45,4.53,4.53,0,0,1-3.1-1.05A3.48,3.48,0,0,1,44.47,407.64Zm1.3-1.64h4a1.59,1.59,0,0,0,.8-.13c.13-.08.2-.27.2-.55a2.47,2.47,0,0,0-.71-1.74,2.33,2.33,0,0,0-1.79-.76,2.44,2.44,0,0,0-1.77.72,2.27,2.27,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M53.63,407.64V405.4a3.48,3.48,0,0,1,1.12-2.59,3.87,3.87,0,0,1,5.33-.05,3.43,3.43,0,0,1,1.11,2.6,2.45,2.45,0,0,1-.13.9.84.84,0,0,1-.42.48,2.21,2.21,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0H54.93v.74a2.32,2.32,0,0,0,.85,1.89,3.7,3.7,0,0,0,3.38.51,3.47,3.47,0,0,0,.78-.4.84.84,0,0,1,.43-.2.49.49,0,0,1,.39.21.69.69,0,0,1,.17.41c0,.28-.29.57-.86.87a4.58,4.58,0,0,1-2.14.45,4.53,4.53,0,0,1-3.1-1.05A3.48,3.48,0,0,1,53.63,407.64Zm1.3-1.64h4a1.59,1.59,0,0,0,.8-.13c.13-.08.2-.27.2-.55a2.47,2.47,0,0,0-.71-1.74,2.33,2.33,0,0,0-1.79-.76,2.44,2.44,0,0,0-1.77.72,2.27,2.27,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M63.21,410.6V397.36a.66.66,0,0,1,.2-.48.69.69,0,0,1,.5-.2h3.8q4.61,0,4.6,4.08a4.17,4.17,0,0,1-.76,2.66,3.63,3.63,0,0,1-2.06,1.28l2.8,5.28a.61.61,0,0,1,.08.3A1,1,0,0,1,72,411a1.31,1.31,0,0,1-.87.34,1,1,0,0,1-.84-.62L67.33,405h-2v5.6a.58.58,0,0,1-.31.51,1.39,1.39,0,0,1-.75.19,1.35,1.35,0,0,1-.73-.19A.58.58,0,0,1,63.21,410.6Zm2.1-7.28h2.4c1.65,0,2.48-.79,2.48-2.38s-.83-2.38-2.48-2.38h-2.4Z"/>
            <path className="cls-1" d="M74.39,410.6V397.36a.66.66,0,0,1,.2-.48.69.69,0,0,1,.5-.2h3.8q4.6,0,4.6,4.08a4.17,4.17,0,0,1-.76,2.66,3.63,3.63,0,0,1-2.06,1.28l2.8,5.28a.61.61,0,0,1,.08.3,1,1,0,0,1-.41.74,1.31,1.31,0,0,1-.87.34,1,1,0,0,1-.84-.62L78.51,405h-2v5.6a.58.58,0,0,1-.31.51,1.39,1.39,0,0,1-.75.19,1.35,1.35,0,0,1-.73-.19A.58.58,0,0,1,74.39,410.6Zm2.1-7.28h2.4c1.65,0,2.48-.79,2.48-2.38s-.83-2.38-2.48-2.38h-2.4Z"/>
            <path className="cls-1" d="M85.57,410.6V397.36a.54.54,0,0,1,.31-.51,1.74,1.74,0,0,1,1.5,0,.54.54,0,0,1,.31.51V410.6a.58.58,0,0,1-.31.51,1.56,1.56,0,0,1-1.49,0A.59.59,0,0,1,85.57,410.6Z"/>
            <path className="cls-1" d="M94.33,410.6V397.36a.54.54,0,0,1,.31-.51,1.74,1.74,0,0,1,1.5,0,.54.54,0,0,1,.31.51V410.6a.58.58,0,0,1-.31.51,1.56,1.56,0,0,1-1.49,0A.59.59,0,0,1,94.33,410.6Z"/>
            <path className="cls-1" d="M98.87,410.6v-8.1a.56.56,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.14,1.14,0,0,1,.66.19.61.61,0,0,1,.28.5v.73a2.84,2.84,0,0,1,2.66-1.54,2.4,2.4,0,0,1,1.57.56,3.44,3.44,0,0,1,1,1.46,3.75,3.75,0,0,1,1.31-1.51,3.16,3.16,0,0,1,1.71-.51,3.31,3.31,0,0,1,2.45,1.07,3.94,3.94,0,0,1,1,2.87v5a.62.62,0,0,1-.32.48,1.26,1.26,0,0,1-.74.22,1.2,1.2,0,0,1-.72-.22.59.59,0,0,1-.32-.48v-5a2.07,2.07,0,0,0-.56-1.53,1.8,1.8,0,0,0-2.61,0,2,2,0,0,0-.57,1.49v5a.54.54,0,0,1-.31.51,1.6,1.6,0,0,1-.75.17,1.53,1.53,0,0,1-.72-.17.53.53,0,0,1-.32-.51v-5a2.07,2.07,0,0,0-.55-1.47,1.75,1.75,0,0,0-1.31-.58,1.77,1.77,0,0,0-1.31.56,2,2,0,0,0-.55,1.51v5a.61.61,0,0,1-.32.48,1.24,1.24,0,0,1-.74.22,1.2,1.2,0,0,1-.74-.22A.62.62,0,0,1,98.87,410.6Z"/>
            <path className="cls-1" d="M114.77,415.1V402.5a.58.58,0,0,1,.29-.49,1.23,1.23,0,0,1,.71-.19,1.2,1.2,0,0,1,.7.19.58.58,0,0,1,.28.5v.73a3,3,0,0,1,1.07-1.1,3,3,0,0,1,1.61-.44,3.32,3.32,0,0,1,2.55,1.16,4,4,0,0,1,1,2.78v1.84a4.12,4.12,0,0,1-1.06,2.77,3.19,3.19,0,0,1-2.46,1.21,2.9,2.9,0,0,1-1.59-.47,2.77,2.77,0,0,1-1-1.11v5.22a.6.6,0,0,1-.32.49,1.38,1.38,0,0,1-.76.21,1.21,1.21,0,0,1-.73-.21A.6.6,0,0,1,114.77,415.1Zm2.1-7.17a1.63,1.63,0,0,0,.61,1,1.9,1.9,0,0,0,2.83,0,2.06,2.06,0,0,0,.62-1.46v-1.82a2,2,0,0,0-.62-1.43,2,2,0,0,0-1.46-.64,2,2,0,0,0-2,2.07Z"/>
            <path className="cls-1" d="M125.05,410.6V396.86a.58.58,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.39,1.39,0,0,1,.75.19.58.58,0,0,1,.31.49V410.6a.62.62,0,0,1-.32.48,1.24,1.24,0,0,1-.74.22,1.18,1.18,0,0,1-.74-.22A.62.62,0,0,1,125.05,410.6Z"/>
            <path className="cls-1" d="M129.17,407.48v-1.9a3.65,3.65,0,0,1,1.22-2.73,4.32,4.32,0,0,1,5.75-.11,3.36,3.36,0,0,1,1.17,2.63,3.06,3.06,0,0,1-.14,1,.89.89,0,0,1-.44.52,1.81,1.81,0,0,1-.56.2,4.2,4.2,0,0,1-.68,0h-4.22v.46a2,2,0,0,0,.72,1.61,2.9,2.9,0,0,0,1.88.59,3.35,3.35,0,0,0,1.55-.38,3.25,3.25,0,0,1,.89-.39.7.7,0,0,1,.58.33,1,1,0,0,1,.24.62c0,.33-.32.66-1,1a5.12,5.12,0,0,1-2.36.5,5,5,0,0,1-3.34-1.1A3.57,3.57,0,0,1,129.17,407.48Zm2.1-1.7h3.32a1,1,0,0,0,.59-.12.57.57,0,0,0,.15-.47,1.77,1.77,0,0,0-.58-1.34,2,2,0,0,0-1.44-.55,2.11,2.11,0,0,0-1.44.52,1.69,1.69,0,0,0-.6,1.33Z"/>
            <path className="cls-1" d="M139,410.6v-8.1a.56.56,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.14,1.14,0,0,1,.66.19.61.61,0,0,1,.28.5v.73a2.84,2.84,0,0,1,2.66-1.54,2.4,2.4,0,0,1,1.57.56,3.44,3.44,0,0,1,1.05,1.46,3.75,3.75,0,0,1,1.31-1.51,3.16,3.16,0,0,1,1.71-.51,3.31,3.31,0,0,1,2.45,1.07,4,4,0,0,1,1,2.87v5a.62.62,0,0,1-.32.48,1.26,1.26,0,0,1-.74.22,1.2,1.2,0,0,1-.72-.22.59.59,0,0,1-.32-.48v-5a2.07,2.07,0,0,0-.56-1.53,1.8,1.8,0,0,0-2.61,0,2,2,0,0,0-.57,1.49v5a.54.54,0,0,1-.31.51,1.6,1.6,0,0,1-.75.17,1.53,1.53,0,0,1-.72-.17.53.53,0,0,1-.32-.51v-5a2.07,2.07,0,0,0-.55-1.47,1.75,1.75,0,0,0-1.31-.58,1.77,1.77,0,0,0-1.31.56,2,2,0,0,0-.55,1.51v5a.61.61,0,0,1-.32.48,1.24,1.24,0,0,1-.74.22,1.18,1.18,0,0,1-.74-.22A.62.62,0,0,1,139,410.6Z"/>
            <path className="cls-1" d="M154.53,407.48v-1.9a3.61,3.61,0,0,1,1.22-2.73,4.32,4.32,0,0,1,5.75-.11,3.36,3.36,0,0,1,1.17,2.63,3.06,3.06,0,0,1-.14,1,.92.92,0,0,1-.44.52,1.81,1.81,0,0,1-.56.2,4.27,4.27,0,0,1-.68,0h-4.22v.46a2,2,0,0,0,.72,1.61,2.88,2.88,0,0,0,1.88.59,3.38,3.38,0,0,0,1.55-.38,3.12,3.12,0,0,1,.89-.39.71.71,0,0,1,.58.33,1,1,0,0,1,.24.62c0,.33-.32.66-1,1a5.12,5.12,0,0,1-2.36.5,5,5,0,0,1-3.34-1.1A3.57,3.57,0,0,1,154.53,407.48Zm2.1-1.7H160a1,1,0,0,0,.59-.12.57.57,0,0,0,.15-.47,1.77,1.77,0,0,0-.58-1.34,2,2,0,0,0-1.44-.55,2.11,2.11,0,0,0-1.44.52,1.69,1.69,0,0,0-.6,1.33Z"/>
            <path className="cls-1" d="M164.37,410.6v-8.1a.56.56,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.13,1.13,0,0,1,.67.19.6.6,0,0,1,.27.5v.73a2.64,2.64,0,0,1,1-1.1,3.08,3.08,0,0,1,1.65-.44,3.2,3.2,0,0,1,2.45,1.16,4.07,4.07,0,0,1,1,2.78v5a.6.6,0,0,1-.31.51,1.39,1.39,0,0,1-.75.19,1.34,1.34,0,0,1-.72-.19.57.57,0,0,1-.32-.51v-5a2,2,0,0,0-.6-1.43,1.88,1.88,0,0,0-1.42-.64,1.84,1.84,0,0,0-1.34.57,2,2,0,0,0-.58,1.5v5a.61.61,0,0,1-.32.48,1.26,1.26,0,0,1-.74.22,1.18,1.18,0,0,1-.74-.22A.62.62,0,0,1,164.37,410.6Z"/>
            <path className="cls-1" d="M174.61,408.18v-10a.55.55,0,0,1,.32-.48,1.34,1.34,0,0,1,.72-.2,1.43,1.43,0,0,1,.73.2.56.56,0,0,1,.33.48v3.66h2.44a.51.51,0,0,1,.42.23,1,1,0,0,1,0,1.1.51.51,0,0,1-.42.23h-2.44v4.8a1.37,1.37,0,0,0,.31,1,1.54,1.54,0,0,0,1.09.3h.7a.87.87,0,0,1,.66.26,1,1,0,0,1,0,1.28.87.87,0,0,1-.66.26h-.7C175.77,411.3,174.61,410.26,174.61,408.18Z"/>
            <path className="cls-1" d="M180.61,408.82a2.3,2.3,0,0,1,1.2-2.23,7.66,7.66,0,0,1,3.48-.63h.88v-.37a2.54,2.54,0,0,0-.49-1.55,1.75,1.75,0,0,0-1.49-.66,3.26,3.26,0,0,0-1.51.33,5.36,5.36,0,0,1-.71.33.63.63,0,0,1-.55-.33,1.32,1.32,0,0,1-.21-.73c0-.35.32-.65,1-.9a5.61,5.61,0,0,1,2-.38,3.89,3.89,0,0,1,3.05,1.11,4,4,0,0,1,1,2.77v5a.6.6,0,0,1-.29.49,1.23,1.23,0,0,1-.73.21,1.08,1.08,0,0,1-.68-.21.59.59,0,0,1-.28-.49v-.7a3.94,3.94,0,0,1-3,1.56,2.74,2.74,0,0,1-1.91-.68A2.51,2.51,0,0,1,180.61,408.82Zm2.12-.26a1.25,1.25,0,0,0,.36,1,1.33,1.33,0,0,0,.94.33,2.11,2.11,0,0,0,1.45-.64,1.86,1.86,0,0,0,.69-1.36v-.74h-.5a11.54,11.54,0,0,0-1.47.08,2.1,2.1,0,0,0-1,.42A1.12,1.12,0,0,0,182.73,408.56Z"/>
            <path className="cls-1" d="M190.39,408.18v-10a.55.55,0,0,1,.32-.48,1.34,1.34,0,0,1,.72-.2,1.43,1.43,0,0,1,.73.2.56.56,0,0,1,.33.48v3.66h2.44a.51.51,0,0,1,.42.23,1,1,0,0,1,0,1.1.51.51,0,0,1-.42.23h-2.44v4.8a1.37,1.37,0,0,0,.31,1,1.54,1.54,0,0,0,1.09.3h.7a.87.87,0,0,1,.66.26,1,1,0,0,1,0,1.28.87.87,0,0,1-.66.26h-.7C191.55,411.3,190.39,410.26,190.39,408.18Z"/>
            <path className="cls-1" d="M196.81,398.34a1,1,0,0,1,.36-.78,1.25,1.25,0,0,1,.86-.32,1.27,1.27,0,0,1,.86.32,1,1,0,0,1,.36.78,1,1,0,0,1-.36.77,1.23,1.23,0,0,1-.86.33,1.22,1.22,0,0,1-.86-.33A1,1,0,0,1,196.81,398.34ZM197,410.6v-8.1a.56.56,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.38,1.38,0,0,1,.76.19.57.57,0,0,1,.3.49v8.1a.63.63,0,0,1-.31.48,1.25,1.25,0,0,1-.75.22,1.18,1.18,0,0,1-.74-.22A.62.62,0,0,1,197,410.6Z"/>
            <path className="cls-1" d="M200.91,407.48v-1.84a3.71,3.71,0,0,1,1.22-2.76,4,4,0,0,1,2.92-1.18,4.11,4.11,0,0,1,2.9,1.17,3.7,3.7,0,0,1,1.24,2.77v1.84a3.77,3.77,0,0,1-1.25,2.77,4.08,4.08,0,0,1-5.79,0A3.75,3.75,0,0,1,200.91,407.48Zm2.1,0a2.08,2.08,0,0,0,.6,1.46,1.87,1.87,0,0,0,1.44.65,1.89,1.89,0,0,0,1.43-.65,2.09,2.09,0,0,0,.61-1.46v-1.82a2,2,0,0,0-.61-1.43,1.92,1.92,0,0,0-1.43-.64,1.89,1.89,0,0,0-1.44.64,2,2,0,0,0-.6,1.43Z"/>
            <path className="cls-1" d="M211,410.6v-8.1a.56.56,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.13,1.13,0,0,1,.67.19.6.6,0,0,1,.27.5v.73a2.64,2.64,0,0,1,1-1.1,3.08,3.08,0,0,1,1.65-.44,3.2,3.2,0,0,1,2.45,1.16,4.07,4.07,0,0,1,1,2.78v5a.6.6,0,0,1-.31.51,1.39,1.39,0,0,1-.75.19,1.34,1.34,0,0,1-.72-.19.57.57,0,0,1-.32-.51v-5a2,2,0,0,0-.6-1.43,1.88,1.88,0,0,0-1.42-.64,1.84,1.84,0,0,0-1.34.57,2,2,0,0,0-.58,1.5v5a.61.61,0,0,1-.32.48,1.26,1.26,0,0,1-.74.22,1.18,1.18,0,0,1-.74-.22A.62.62,0,0,1,211,410.6Z"/>
            <path className="cls-1" d="M225.51,410.6V397.36a.59.59,0,0,1,.23-.48.88.88,0,0,1,.59-.2h3.9a4.62,4.62,0,0,1,3.19,1.06,4.27,4.27,0,0,1,1.19,3.34v.16a4.21,4.21,0,0,1-1.23,3.35,4.82,4.82,0,0,1-3.27,1.07h-2.48v4.94a.6.6,0,0,1-.31.51,1.56,1.56,0,0,1-1.49,0A.57.57,0,0,1,225.51,410.6Zm2.12-6.62h2.48a2.34,2.34,0,0,0,1.76-.65,2.73,2.73,0,0,0,.62-1.95v-.24a2.71,2.71,0,0,0-.62-1.94,2.33,2.33,0,0,0-1.76-.64h-2.48Z"/>
            <path className="cls-1" d="M235.33,408.82a2.3,2.3,0,0,1,1.2-2.23A7.66,7.66,0,0,1,240,406h.88v-.37a2.54,2.54,0,0,0-.49-1.55,1.75,1.75,0,0,0-1.49-.66,3.26,3.26,0,0,0-1.51.33,5.36,5.36,0,0,1-.71.33.63.63,0,0,1-.55-.33,1.32,1.32,0,0,1-.21-.73c0-.35.32-.65,1-.9a5.61,5.61,0,0,1,2-.38,3.89,3.89,0,0,1,3.05,1.11,4,4,0,0,1,1,2.77v5a.6.6,0,0,1-.29.49,1.23,1.23,0,0,1-.73.21,1.08,1.08,0,0,1-.68-.21.59.59,0,0,1-.28-.49v-.7a3.94,3.94,0,0,1-3,1.56,2.74,2.74,0,0,1-1.91-.68A2.51,2.51,0,0,1,235.33,408.82Zm2.12-.26a1.25,1.25,0,0,0,.36,1,1.33,1.33,0,0,0,.94.33,2.11,2.11,0,0,0,1.45-.64,1.86,1.86,0,0,0,.69-1.36v-.74h-.5a11.54,11.54,0,0,0-1.47.08,2.1,2.1,0,0,0-1,.42A1.12,1.12,0,0,0,237.45,408.56Z"/>
            <path className="cls-1" d="M245.11,408.18v-10a.55.55,0,0,1,.32-.48,1.34,1.34,0,0,1,.72-.2,1.4,1.4,0,0,1,.73.2.56.56,0,0,1,.33.48v3.66h2.44a.51.51,0,0,1,.42.23,1,1,0,0,1,0,1.1.51.51,0,0,1-.42.23h-2.44v4.8a1.37,1.37,0,0,0,.31,1,1.54,1.54,0,0,0,1.09.3h.7a.87.87,0,0,1,.66.26,1,1,0,0,1,0,1.28.87.87,0,0,1-.66.26h-.7C246.27,411.3,245.11,410.26,245.11,408.18Z"/>
            <path className="cls-1" d="M251.73,410.6V396.88a.58.58,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.39,1.39,0,0,1,.75.19.58.58,0,0,1,.31.49v6.36a2.73,2.73,0,0,1,1-1.1,2.81,2.81,0,0,1,1.57-.44,3.2,3.2,0,0,1,2.45,1.16,4,4,0,0,1,1,2.78v5a.59.59,0,0,1-.32.51,1.41,1.41,0,0,1-.74.19,1.34,1.34,0,0,1-.72-.19.59.59,0,0,1-.32-.51v-5a2,2,0,0,0-.6-1.43,1.88,1.88,0,0,0-1.42-.64,1.84,1.84,0,0,0-1.34.57,2,2,0,0,0-.58,1.5v5a.61.61,0,0,1-.32.48,1.26,1.26,0,0,1-.74.22,1.18,1.18,0,0,1-.74-.22A.62.62,0,0,1,251.73,410.6Z"/>
            <path className="cls-1" d="M261,402.62a.74.74,0,0,1,.44-.62,1.76,1.76,0,0,1,.9-.26.61.61,0,0,1,.64.38l2.22,6.22,1.7-5.52a.83.83,0,0,1,1.48,0l1.68,5.56,2.24-6.26a.57.57,0,0,1,.62-.38,1.81,1.81,0,0,1,.89.26.75.75,0,0,1,.45.64.76.76,0,0,1-.08.32l-3.1,8.12q-.16.42-.84.42c-.45,0-.74-.13-.86-.4l-1.74-5.16-1.72,5.1c-.12.31-.42.46-.9.46a.79.79,0,0,1-.84-.46L261,403A1.39,1.39,0,0,1,261,402.62Z"/>
            <path className="cls-1" d="M274.93,408.82a2.3,2.3,0,0,1,1.2-2.23,7.66,7.66,0,0,1,3.48-.63h.88v-.37A2.47,2.47,0,0,0,280,404a1.75,1.75,0,0,0-1.49-.66,3.29,3.29,0,0,0-1.51.33,4.76,4.76,0,0,1-.71.33.63.63,0,0,1-.55-.33,1.32,1.32,0,0,1-.21-.73c0-.35.32-.65,1-.9a5.65,5.65,0,0,1,2.05-.38,3.89,3.89,0,0,1,3.05,1.11,4,4,0,0,1,1,2.77v5a.6.6,0,0,1-.29.49,1.21,1.21,0,0,1-.73.21,1.1,1.1,0,0,1-.68-.21.59.59,0,0,1-.28-.49v-.7a3.94,3.94,0,0,1-3,1.56,2.76,2.76,0,0,1-1.91-.68A2.51,2.51,0,0,1,274.93,408.82Zm2.12-.26a1.25,1.25,0,0,0,.36,1,1.33,1.33,0,0,0,.94.33,2.1,2.1,0,0,0,1.45-.64,1.86,1.86,0,0,0,.69-1.36v-.74H280a11.54,11.54,0,0,0-1.47.08,2.1,2.1,0,0,0-1,.42A1.12,1.12,0,0,0,277.05,408.56Z"/>
            <path className="cls-1" d="M283.67,414.9c0-.6.19-.9.58-.9a2.59,2.59,0,0,0,1.78-.86,3.93,3.93,0,0,0,1.08-2.42L283.75,403a.47.47,0,0,1-.08-.28.86.86,0,0,1,.46-.69,1.61,1.61,0,0,1,.92-.31.61.61,0,0,1,.64.38l2.52,6.5,2.16-6.5a.62.62,0,0,1,.6-.38,1.71,1.71,0,0,1,.91.31.89.89,0,0,1,.49.73.45.45,0,0,1,0,.24l-3,7.82-.34.9-.37.86a8.13,8.13,0,0,1-.43.88l-.48.74a2.65,2.65,0,0,1-.6.69l-.68.48a2.52,2.52,0,0,1-.84.36,4.39,4.39,0,0,1-1,.11C284,415.8,283.67,415.5,283.67,414.9Z"/>
            <path className="cls-1" d="M293.12,411.1a1,1,0,0,1-.35-.78,1,1,0,0,1,.35-.78,1.33,1.33,0,0,1,1.73,0,1,1,0,0,1,.36.78,1,1,0,0,1-.36.77,1.21,1.21,0,0,1-.85.33A1.25,1.25,0,0,1,293.12,411.1Z"/>
            <path className="cls-1" d="M301.31,410.78v-13.6c0-.33.22-.5.66-.5s.72.31,1,.92l5.4,10.64V397.16q0-.48.66-.48t.66.48v13.62a.44.44,0,0,1-.2.38.83.83,0,0,1-.46.14c-.34,0-.63-.25-.88-.76l-5.56-10.82v11.06a.44.44,0,0,1-.2.38.83.83,0,0,1-.46.14.79.79,0,0,1-.47-.14A.44.44,0,0,1,301.31,410.78Z"/>
            <path className="cls-1" d="M312.05,407.64V405.4a3.48,3.48,0,0,1,1.12-2.59,3.87,3.87,0,0,1,5.33-.05,3.43,3.43,0,0,1,1.11,2.6,2.45,2.45,0,0,1-.13.9.84.84,0,0,1-.42.48,2.21,2.21,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0h-4.52v.74a2.32,2.32,0,0,0,.85,1.89,3.7,3.7,0,0,0,3.38.51,3.47,3.47,0,0,0,.78-.4.84.84,0,0,1,.43-.2.5.5,0,0,1,.39.21.69.69,0,0,1,.17.41c0,.28-.29.57-.86.87a4.58,4.58,0,0,1-2.14.45,4.53,4.53,0,0,1-3.1-1.05A3.48,3.48,0,0,1,312.05,407.64Zm1.3-1.64h4a1.62,1.62,0,0,0,.8-.13c.13-.08.2-.27.2-.55a2.47,2.47,0,0,0-.71-1.74,2.33,2.33,0,0,0-1.79-.76,2.44,2.44,0,0,0-1.77.72,2.27,2.27,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M320.61,402.38a.48.48,0,0,1,.25-.41,1,1,0,0,1,.53-.17.43.43,0,0,1,.46.3l2.56,7.12,2.06-6.5a.46.46,0,0,1,.87,0l2,6.58,2.62-7.25c0-.2.19-.3.44-.3a.92.92,0,0,1,.53.16.52.52,0,0,1,.17.72L330,411.14a.47.47,0,0,1-.52.32.4.4,0,0,1-.4-.29L327,404.65l-2.05,6.4a.52.52,0,0,1-.55.41.51.51,0,0,1-.54-.4l-3.12-8.38A.61.61,0,0,1,320.61,402.38Z"/>
            <path className="cls-1" d="M338.91,410.78v-8.46a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.72.72,0,0,1,.44.14.44.44,0,0,1,.18.35v1a3.08,3.08,0,0,1,1.06-1.13,2.88,2.88,0,0,1,1.62-.47h.7a.48.48,0,0,1,.36.18.66.66,0,0,1,0,.86.48.48,0,0,1-.36.18h-.7a2.51,2.51,0,0,0-1.84.81,2.83,2.83,0,0,0-.8,2.1v4.93a.5.5,0,0,1-.19.36.69.69,0,0,1-.47.16.65.65,0,0,1-.45-.16A.47.47,0,0,1,338.91,410.78Z"/>
            <path className="cls-1" d="M344.81,407.64V405.4a3.48,3.48,0,0,1,1.12-2.59,3.87,3.87,0,0,1,5.33-.05,3.43,3.43,0,0,1,1.11,2.6,2.45,2.45,0,0,1-.13.9.84.84,0,0,1-.42.48,2.21,2.21,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0h-4.52v.74a2.32,2.32,0,0,0,.85,1.89,3.29,3.29,0,0,0,2.19.71,3.33,3.33,0,0,0,1.19-.2,3.47,3.47,0,0,0,.78-.4.84.84,0,0,1,.43-.2.5.5,0,0,1,.39.21.69.69,0,0,1,.17.41c0,.28-.29.57-.86.87a4.61,4.61,0,0,1-2.14.45,4.53,4.53,0,0,1-3.1-1.05A3.48,3.48,0,0,1,344.81,407.64Zm1.3-1.64h4a1.62,1.62,0,0,0,.8-.13c.13-.08.2-.27.2-.55a2.47,2.47,0,0,0-.71-1.74,2.34,2.34,0,0,0-1.79-.76,2.44,2.44,0,0,0-1.77.72,2.27,2.27,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M353.57,413.28a2.28,2.28,0,0,1,1.7-2.18,1.4,1.4,0,0,1-1.1-1.37,1.59,1.59,0,0,1,1-1.43,3.11,3.11,0,0,1-1.1-2.53V405a3.13,3.13,0,0,1,.95-2.38,3.79,3.79,0,0,1,4.83-.08,3,3,0,0,1,.68-.91,1.11,1.11,0,0,1,.74-.34.6.6,0,0,1,.44.16.54.54,0,0,1,.16.36.41.41,0,0,1-.34.44,1.64,1.64,0,0,0-1.12.84A3.09,3.09,0,0,1,361,405v.76a3.2,3.2,0,0,1-1,2.39,3.47,3.47,0,0,1-2.5.94,3.64,3.64,0,0,1-1.54-.33c-.41.22-.62.48-.62.77a.69.69,0,0,0,.46.64,4.08,4.08,0,0,0,1.14.31l1.5.19a9.14,9.14,0,0,1,1.51.31,2.32,2.32,0,0,1,1.15.74,2.08,2.08,0,0,1,.46,1.39,2.39,2.39,0,0,1-1.08,2.1,5,5,0,0,1-2.84.74,5.37,5.37,0,0,1-2.93-.72A2.21,2.21,0,0,1,353.57,413.28Zm1.26-.12a1.51,1.51,0,0,0,.75,1.32,3.63,3.63,0,0,0,2,.5,3.42,3.42,0,0,0,2-.5,1.58,1.58,0,0,0,.71-1.35,1.27,1.27,0,0,0-.2-.7,1.58,1.58,0,0,0-.47-.46,3.28,3.28,0,0,0-.71-.28,4.41,4.41,0,0,0-.78-.15l-.84-.09-.68-.07C355.42,411.75,354.83,412.35,354.83,413.16Zm.5-7.42a2.53,2.53,0,0,0,.59,1.72,2,2,0,0,0,1.61.66,2,2,0,0,0,1.57-.66,2.49,2.49,0,0,0,.59-1.7V405a2.39,2.39,0,0,0-.59-1.66,2,2,0,0,0-1.59-.66,2,2,0,0,0-1.59.66,2.39,2.39,0,0,0-.59,1.66Z"/>
            <path className="cls-1" d="M363.37,398.79a.8.8,0,0,1-.26-.59.77.77,0,0,1,.26-.58.89.89,0,0,1,.64-.24.85.85,0,0,1,.61.24.76.76,0,0,1,.25.58.79.79,0,0,1-.25.59.82.82,0,0,1-.61.25A.86.86,0,0,1,363.37,398.79Zm0,12v-8.46a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.79.79,0,0,1,.47.14.43.43,0,0,1,.19.34v8.46a.48.48,0,0,1-.2.36.72.72,0,0,1-.46.16.64.64,0,0,1-.45-.16A.47.47,0,0,1,363.37,410.78Z"/>
            <path className="cls-1" d="M366.81,407.64v-2.18a3.57,3.57,0,0,1,1.12-2.63,3.63,3.63,0,0,1,2.66-1.11,3.74,3.74,0,0,1,2.68,1.1,3.53,3.53,0,0,1,1.14,2.64v2.18a3.93,3.93,0,0,1-3.82,3.8,3.6,3.6,0,0,1-2.64-1.15A3.66,3.66,0,0,1,366.81,407.64Zm1.3,0a2.54,2.54,0,0,0,.73,1.8,2.29,2.29,0,0,0,1.75.79,2.34,2.34,0,0,0,1.78-.79,2.55,2.55,0,0,0,.74-1.8v-2.16a2.44,2.44,0,0,0-.75-1.75,2.38,2.38,0,0,0-1.77-.78,2.32,2.32,0,0,0-1.75.78,2.46,2.46,0,0,0-.73,1.75Z"/>
            <path className="cls-1" d="M376.55,410.78v-8.46a.41.41,0,0,1,.18-.35.74.74,0,0,1,.46-.13.68.68,0,0,1,.42.14.44.44,0,0,1,.18.35v.93a2.77,2.77,0,0,1,1-1.09,2.94,2.94,0,0,1,1.64-.45,3.38,3.38,0,0,1,2.5,1.1,3.69,3.69,0,0,1,1.06,2.64v5.32a.44.44,0,0,1-.2.38.83.83,0,0,1-.46.14.77.77,0,0,1-.44-.14.42.42,0,0,1-.2-.38v-5.31a2.49,2.49,0,0,0-.74-1.75,2.34,2.34,0,0,0-1.76-.78,2.29,2.29,0,0,0-1.66.7,2.43,2.43,0,0,0-.72,1.83v5.31a.48.48,0,0,1-.2.36.72.72,0,0,1-.46.16.65.65,0,0,1-.46-.16A.48.48,0,0,1,376.55,410.78Z"/>
            <path className="cls-1" d="M385.81,408.8a2.24,2.24,0,0,1,1.19-2.19,7.76,7.76,0,0,1,3.47-.61h1v-.47a3.16,3.16,0,0,0-.58-1.88,2.08,2.08,0,0,0-1.78-.79,3.71,3.71,0,0,0-1.45.28l-.69.29a.42.42,0,0,1-.38-.22.79.79,0,0,1-.14-.47c0-.25.28-.49.86-.7a5.27,5.27,0,0,1,1.84-.32,3.3,3.3,0,0,1,2.75,1.09,4.31,4.31,0,0,1,.87,2.77v5.2a.46.46,0,0,1-.18.37.71.71,0,0,1-.46.15.61.61,0,0,1-.43-.15.45.45,0,0,1-.17-.37v-.88a3.94,3.94,0,0,1-3,1.54,2.83,2.83,0,0,1-2-.67A2.48,2.48,0,0,1,385.81,408.8Zm1.3-.12a1.55,1.55,0,0,0,.48,1.25,1.89,1.89,0,0,0,1.26.41,2.78,2.78,0,0,0,1.79-.7,1.93,1.93,0,0,0,.85-1.4V407h-.84a12.71,12.71,0,0,0-1.35.06,5.85,5.85,0,0,0-1.09.22,1.56,1.56,0,0,0-.82.52A1.44,1.44,0,0,0,387.11,408.68Z"/>
            <path className="cls-1" d="M395.39,410.78V396.52a.42.42,0,0,1,.18-.34A.74.74,0,0,1,396,396a.82.82,0,0,1,.47.14.43.43,0,0,1,.19.34v14.26a.5.5,0,0,1-.19.36.71.71,0,0,1-.47.16.64.64,0,0,1-.45-.16A.47.47,0,0,1,395.39,410.78Z"/>
            <path className="cls-1" d="M403.37,398.79a.77.77,0,0,1-.26-.59.73.73,0,0,1,.26-.58.89.89,0,0,1,.64-.24.85.85,0,0,1,.61.24.79.79,0,0,1,.25.58.83.83,0,0,1-.25.59.82.82,0,0,1-.61.25A.86.86,0,0,1,403.37,398.79Zm0,12v-8.46a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.82.82,0,0,1,.47.14.43.43,0,0,1,.19.34v8.46a.48.48,0,0,1-.2.36.72.72,0,0,1-.46.16.64.64,0,0,1-.45-.16A.47.47,0,0,1,403.37,410.78Z"/>
            <path className="cls-1" d="M407.19,410.78v-8.46a.41.41,0,0,1,.18-.35.74.74,0,0,1,.46-.13.68.68,0,0,1,.42.14.44.44,0,0,1,.18.35v.93a2.77,2.77,0,0,1,1-1.09,2.94,2.94,0,0,1,1.64-.45,3.38,3.38,0,0,1,2.5,1.1,3.69,3.69,0,0,1,1.06,2.64v5.32a.44.44,0,0,1-.2.38.83.83,0,0,1-.46.14.77.77,0,0,1-.44-.14.42.42,0,0,1-.2-.38v-5.31a2.49,2.49,0,0,0-.74-1.75,2.36,2.36,0,0,0-1.76-.78,2.29,2.29,0,0,0-1.66.7,2.43,2.43,0,0,0-.72,1.83v5.31a.48.48,0,0,1-.2.36.72.72,0,0,1-.46.16.65.65,0,0,1-.46-.16A.48.48,0,0,1,407.19,410.78Z"/>
            <path className="cls-1" d="M417.11,410.78v-8.46a.41.41,0,0,1,.18-.35.74.74,0,0,1,.46-.13.68.68,0,0,1,.42.14.44.44,0,0,1,.18.35v.93a2.77,2.77,0,0,1,1-1.09,2.94,2.94,0,0,1,1.64-.45,3.38,3.38,0,0,1,2.5,1.1,3.69,3.69,0,0,1,1.06,2.64v5.32a.44.44,0,0,1-.2.38.83.83,0,0,1-.46.14.77.77,0,0,1-.44-.14.42.42,0,0,1-.2-.38v-5.31a2.49,2.49,0,0,0-.74-1.75,2.36,2.36,0,0,0-1.76-.78,2.29,2.29,0,0,0-1.66.7,2.43,2.43,0,0,0-.72,1.83v5.31a.48.48,0,0,1-.2.36.72.72,0,0,1-.46.16.65.65,0,0,1-.46-.16A.48.48,0,0,1,417.11,410.78Z"/>
            <path className="cls-1" d="M426.65,407.64v-2.18a3.57,3.57,0,0,1,1.12-2.63,3.63,3.63,0,0,1,2.66-1.11,3.76,3.76,0,0,1,2.68,1.1,3.53,3.53,0,0,1,1.14,2.64v2.18a3.93,3.93,0,0,1-3.82,3.8,3.6,3.6,0,0,1-2.64-1.15A3.66,3.66,0,0,1,426.65,407.64Zm1.3,0a2.54,2.54,0,0,0,.73,1.8,2.28,2.28,0,0,0,1.75.79,2.36,2.36,0,0,0,1.78-.79,2.55,2.55,0,0,0,.74-1.8v-2.16a2.44,2.44,0,0,0-.75-1.75,2.38,2.38,0,0,0-1.77-.78,2.3,2.3,0,0,0-1.75.78,2.46,2.46,0,0,0-.73,1.75Z"/>
            <path className="cls-1" d="M435.43,402.4a.51.51,0,0,1,.25-.42.87.87,0,0,1,.53-.18.47.47,0,0,1,.48.3l2.64,7.2,2.64-7.12a.51.51,0,0,1,.46-.38,1,1,0,0,1,.52.18.51.51,0,0,1,.28.4.76.76,0,0,1-.08.32l-3.22,8.44c-.08.21-.28.32-.6.32s-.52-.11-.6-.32l-3.22-8.44A.48.48,0,0,1,435.43,402.4Z"/>
            <path className="cls-1" d="M444.13,408.8a2.24,2.24,0,0,1,1.19-2.19,7.76,7.76,0,0,1,3.47-.61h1v-.47a3.16,3.16,0,0,0-.58-1.88,2.08,2.08,0,0,0-1.78-.79,3.71,3.71,0,0,0-1.45.28l-.69.29a.42.42,0,0,1-.38-.22.79.79,0,0,1-.14-.47c0-.25.28-.49.86-.7a5.27,5.27,0,0,1,1.84-.32,3.3,3.3,0,0,1,2.75,1.09,4.31,4.31,0,0,1,.87,2.77v5.2a.46.46,0,0,1-.18.37.71.71,0,0,1-.46.15.61.61,0,0,1-.43-.15.45.45,0,0,1-.17-.37v-.88a3.94,3.94,0,0,1-3,1.54,2.83,2.83,0,0,1-2-.67A2.48,2.48,0,0,1,444.13,408.8Zm1.3-.12a1.55,1.55,0,0,0,.48,1.25,1.89,1.89,0,0,0,1.26.41,2.78,2.78,0,0,0,1.79-.7,1.93,1.93,0,0,0,.85-1.4V407H449a12.58,12.58,0,0,0-1.35.06,5.85,5.85,0,0,0-1.09.22,1.56,1.56,0,0,0-.82.52A1.44,1.44,0,0,0,445.43,408.68Z"/>
            <path className="cls-1" d="M453.51,408.36V397.84a.39.39,0,0,1,.2-.34.77.77,0,0,1,.44-.14.83.83,0,0,1,.46.14.42.42,0,0,1,.2.34v4h2.58a.41.41,0,0,1,.33.16.65.65,0,0,1,0,.78.41.41,0,0,1-.33.16h-2.58v5.39a1.78,1.78,0,0,0,.41,1.36,2.08,2.08,0,0,0,1.45.39h.56a.58.58,0,0,1,.43.18.57.57,0,0,1,.17.42.61.61,0,0,1-.17.43.56.56,0,0,1-.43.19h-.56Q453.51,411.3,453.51,408.36Z"/>
            <path className="cls-1" d="M459.49,398.79a.77.77,0,0,1-.26-.59.73.73,0,0,1,.26-.58.89.89,0,0,1,.64-.24.85.85,0,0,1,.61.24.79.79,0,0,1,.25.58.83.83,0,0,1-.25.59.82.82,0,0,1-.61.25A.86.86,0,0,1,459.49,398.79Zm0,12v-8.46a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.82.82,0,0,1,.47.14.43.43,0,0,1,.19.34v8.46a.48.48,0,0,1-.2.36.72.72,0,0,1-.46.16.64.64,0,0,1-.45-.16A.47.47,0,0,1,459.49,410.78Z"/>
            <path className="cls-1" d="M462.93,407.64v-2.18a3.57,3.57,0,0,1,1.12-2.63,3.63,3.63,0,0,1,2.66-1.11,3.76,3.76,0,0,1,2.68,1.1,3.53,3.53,0,0,1,1.14,2.64v2.18a3.93,3.93,0,0,1-3.82,3.8,3.6,3.6,0,0,1-2.64-1.15A3.66,3.66,0,0,1,462.93,407.64Zm1.3,0a2.54,2.54,0,0,0,.73,1.8,2.28,2.28,0,0,0,1.75.79,2.36,2.36,0,0,0,1.78-.79,2.55,2.55,0,0,0,.74-1.8v-2.16a2.44,2.44,0,0,0-.75-1.75,2.38,2.38,0,0,0-1.77-.78,2.3,2.3,0,0,0-1.75.78,2.46,2.46,0,0,0-.73,1.75Z"/>
            <path className="cls-1" d="M472.67,410.78v-8.46a.41.41,0,0,1,.18-.35.74.74,0,0,1,.46-.13.68.68,0,0,1,.42.14.44.44,0,0,1,.18.35v.93a2.77,2.77,0,0,1,1-1.09,2.94,2.94,0,0,1,1.64-.45,3.38,3.38,0,0,1,2.5,1.1,3.69,3.69,0,0,1,1.06,2.64v5.32a.44.44,0,0,1-.2.38.83.83,0,0,1-.46.14.77.77,0,0,1-.44-.14.42.42,0,0,1-.2-.38v-5.31a2.49,2.49,0,0,0-.74-1.75,2.36,2.36,0,0,0-1.76-.78,2.29,2.29,0,0,0-1.66.7,2.43,2.43,0,0,0-.72,1.83v5.31a.48.48,0,0,1-.2.36.72.72,0,0,1-.46.16.65.65,0,0,1-.46-.16A.48.48,0,0,1,472.67,410.78Z"/>
            <path className="cls-1" d="M.27,448.44V435.32A.42.42,0,0,1,.45,435a.74.74,0,0,1,.46-.14.72.72,0,0,1,.44.14.44.44,0,0,1,.18.35v.93a3,3,0,0,1,1.06-1.09,3,3,0,0,1,1.62-.45,3.42,3.42,0,0,1,2.54,1.1,3.65,3.65,0,0,1,1.06,2.64v2.18a3.8,3.8,0,0,1-1.07,2.65,3.27,3.27,0,0,1-2.47,1.15A3,3,0,0,1,2.65,444a2.91,2.91,0,0,1-1.08-1.1v5.56a.45.45,0,0,1-.19.34.69.69,0,0,1-.47.16.67.67,0,0,1-.45-.16A.45.45,0,0,1,.27,448.44Zm1.3-7.19a1.92,1.92,0,0,0,.75,1.25,2.4,2.4,0,0,0,1.71.72,2.29,2.29,0,0,0,1.73-.8,2.53,2.53,0,0,0,.75-1.79v-2.16a2.44,2.44,0,0,0-.75-1.75,2.39,2.39,0,0,0-3.46-.08,2.39,2.39,0,0,0-.73,1.83Z"/>
            <path className="cls-1" d="M9.57,440.64v-2.18a3.57,3.57,0,0,1,1.12-2.63,3.65,3.65,0,0,1,2.66-1.11,3.74,3.74,0,0,1,2.68,1.1,3.53,3.53,0,0,1,1.14,2.64v2.18A3.63,3.63,0,0,1,16,443.29a3.66,3.66,0,0,1-2.67,1.15,3.6,3.6,0,0,1-2.64-1.15A3.66,3.66,0,0,1,9.57,440.64Zm1.3,0a2.54,2.54,0,0,0,.73,1.8,2.29,2.29,0,0,0,1.75.79,2.34,2.34,0,0,0,1.78-.79,2.55,2.55,0,0,0,.74-1.8v-2.16a2.44,2.44,0,0,0-.75-1.75,2.38,2.38,0,0,0-1.77-.78,2.32,2.32,0,0,0-1.75.78,2.46,2.46,0,0,0-.73,1.75Z"/>
            <path className="cls-1" d="M19.47,443.78V429.52a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.82.82,0,0,1,.47.14.43.43,0,0,1,.19.34v14.26a.5.5,0,0,1-.19.36.71.71,0,0,1-.47.16.67.67,0,0,1-.45-.16A.47.47,0,0,1,19.47,443.78Z"/>
            <path className="cls-1" d="M23.45,431.79a.8.8,0,0,1-.26-.59.77.77,0,0,1,.26-.58.89.89,0,0,1,.64-.24.85.85,0,0,1,.61.24.76.76,0,0,1,.25.58.79.79,0,0,1-.25.59.82.82,0,0,1-.61.25A.86.86,0,0,1,23.45,431.79Zm0,12v-8.46a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.79.79,0,0,1,.47.14.43.43,0,0,1,.19.34v8.46a.48.48,0,0,1-.2.36.7.7,0,0,1-.46.16.65.65,0,0,1-.45-.16A.47.47,0,0,1,23.45,443.78Z"/>
            <path className="cls-1" d="M26.93,440.64V438.5a3.81,3.81,0,0,1,1-2.73,3.74,3.74,0,0,1,2.88-1,4.21,4.21,0,0,1,2,.43q.81.44.81.87a.65.65,0,0,1-.18.43.52.52,0,0,1-.4.21.79.79,0,0,1-.37-.18,4.26,4.26,0,0,0-.71-.35,2.81,2.81,0,0,0-1.1-.19,2.64,2.64,0,0,0-2,.68,2.55,2.55,0,0,0-.67,1.89v2.12a2.57,2.57,0,0,0,.67,1.91,2.66,2.66,0,0,0,2,.68A3.27,3.27,0,0,0,32,443a3.92,3.92,0,0,0,.72-.37.75.75,0,0,1,.39-.19.55.55,0,0,1,.43.2.67.67,0,0,1,.17.44c0,.31-.28.6-.84.89a4.48,4.48,0,0,1-2.08.43,3.51,3.51,0,0,1-3.88-3.8Z"/>
            <path className="cls-1" d="M35.49,431.79a.8.8,0,0,1-.26-.59.77.77,0,0,1,.26-.58.91.91,0,0,1,.64-.24.85.85,0,0,1,.61.24.76.76,0,0,1,.25.58.79.79,0,0,1-.25.59.82.82,0,0,1-.61.25A.87.87,0,0,1,35.49,431.79Zm0,12v-8.46a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.79.79,0,0,1,.47.14.43.43,0,0,1,.19.34v8.46a.48.48,0,0,1-.2.36.72.72,0,0,1-.46.16.64.64,0,0,1-.45-.16A.47.47,0,0,1,35.49,443.78Z"/>
            <path className="cls-1" d="M39,440.64V438.4a3.48,3.48,0,0,1,1.12-2.59,3.87,3.87,0,0,1,5.33-.05,3.43,3.43,0,0,1,1.11,2.6,2.45,2.45,0,0,1-.13.9.84.84,0,0,1-.42.48,2.21,2.21,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0H40.25v.74a2.32,2.32,0,0,0,.85,1.89,3.7,3.7,0,0,0,3.38.51,3.47,3.47,0,0,0,.78-.4.84.84,0,0,1,.43-.2.5.5,0,0,1,.39.21.69.69,0,0,1,.17.41c0,.28-.29.57-.86.87a4.58,4.58,0,0,1-2.14.45,4.53,4.53,0,0,1-3.1-1.05A3.48,3.48,0,0,1,39,440.64Zm1.3-1.64h4a1.59,1.59,0,0,0,.8-.13c.13-.08.2-.27.2-.55a2.47,2.47,0,0,0-.71-1.74,2.33,2.33,0,0,0-1.79-.76,2.44,2.44,0,0,0-1.77.72,2.27,2.27,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M47.87,443a.72.72,0,0,1,.14-.4.4.4,0,0,1,.32-.2,1,1,0,0,1,.44.23,4.35,4.35,0,0,0,.86.47,3.38,3.38,0,0,0,1.34.24,2.45,2.45,0,0,0,1.56-.4,1.27,1.27,0,0,0,.5-1,1.43,1.43,0,0,0-.51-1.15,3.44,3.44,0,0,0-1.23-.65l-1.45-.44a2.72,2.72,0,0,1-1.24-.77,2.09,2.09,0,0,1-.51-1.47,2.64,2.64,0,0,1,.79-1.91,3.18,3.18,0,0,1,2.37-.81,5.25,5.25,0,0,1,1.83.31c.57.21.85.43.85.67a.79.79,0,0,1-.16.44.46.46,0,0,1-.38.24,5.24,5.24,0,0,1-.74-.28,3.51,3.51,0,0,0-1.38-.28,2.12,2.12,0,0,0-1.5.47,1.44,1.44,0,0,0-.5,1.06,1.11,1.11,0,0,0,.52,1,4.75,4.75,0,0,0,1.24.57l1.46.43a2.33,2.33,0,0,1,1.24.87,2.69,2.69,0,0,1,.5,1.68,2.29,2.29,0,0,1-.85,1.89,3.68,3.68,0,0,1-2.37.69,4.87,4.87,0,0,1-2.23-.47C48.17,443.66,47.87,443.33,47.87,443Z"/>
            <path className="cls-1" d="M59.51,441.8a2.25,2.25,0,0,1,1.19-2.19,7.76,7.76,0,0,1,3.47-.61h1v-.47a3.09,3.09,0,0,0-.58-1.88,2.08,2.08,0,0,0-1.78-.79,3.75,3.75,0,0,0-1.45.28,7.44,7.44,0,0,1-.69.29.42.42,0,0,1-.38-.22.86.86,0,0,1-.14-.47c0-.25.29-.49.86-.7a5.31,5.31,0,0,1,1.84-.32,3.3,3.3,0,0,1,2.75,1.09,4.31,4.31,0,0,1,.87,2.77v5.2a.46.46,0,0,1-.18.37.69.69,0,0,1-.46.15.63.63,0,0,1-.43-.15.48.48,0,0,1-.17-.37v-.88a3.93,3.93,0,0,1-3,1.54,2.84,2.84,0,0,1-2-.67A2.48,2.48,0,0,1,59.51,441.8Zm1.3-.12a1.55,1.55,0,0,0,.48,1.25,1.89,1.89,0,0,0,1.26.41,2.76,2.76,0,0,0,1.79-.7,1.9,1.9,0,0,0,.85-1.4V440h-.84A12.84,12.84,0,0,0,63,440a5.85,5.85,0,0,0-1.09.22,1.6,1.6,0,0,0-.82.52A1.44,1.44,0,0,0,60.81,441.68Z"/>
            <path className="cls-1" d="M68.93,443.78v-8.46a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14A.72.72,0,0,1,70,435a.44.44,0,0,1,.18.35v1a3.08,3.08,0,0,1,1.06-1.13,2.88,2.88,0,0,1,1.62-.47h.7a.48.48,0,0,1,.36.18.66.66,0,0,1,0,.86.48.48,0,0,1-.36.18h-.7a2.51,2.51,0,0,0-1.84.81,2.87,2.87,0,0,0-.8,2.1v4.93a.47.47,0,0,1-.19.36.69.69,0,0,1-.47.16.67.67,0,0,1-.45-.16A.47.47,0,0,1,68.93,443.78Z"/>
            <path className="cls-1" d="M74.83,440.64V438.4A3.48,3.48,0,0,1,76,435.81a3.87,3.87,0,0,1,5.33-.05,3.43,3.43,0,0,1,1.11,2.6,2.45,2.45,0,0,1-.13.9.84.84,0,0,1-.42.48,2.21,2.21,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0H76.13v.74a2.32,2.32,0,0,0,.85,1.89,3.7,3.7,0,0,0,3.38.51,3.47,3.47,0,0,0,.78-.4.84.84,0,0,1,.43-.2.49.49,0,0,1,.39.21.69.69,0,0,1,.17.41c0,.28-.29.57-.86.87a4.58,4.58,0,0,1-2.14.45,4.53,4.53,0,0,1-3.1-1.05A3.48,3.48,0,0,1,74.83,440.64Zm1.3-1.64h4a1.59,1.59,0,0,0,.8-.13c.13-.08.2-.27.2-.55a2.47,2.47,0,0,0-.71-1.74,2.33,2.33,0,0,0-1.79-.76,2.44,2.44,0,0,0-1.77.72,2.27,2.27,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M88,440.64V438.4a3.48,3.48,0,0,1,1.12-2.59,3.87,3.87,0,0,1,5.33-.05,3.43,3.43,0,0,1,1.11,2.6,2.45,2.45,0,0,1-.13.9.84.84,0,0,1-.42.48,2.21,2.21,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0H89.29v.74a2.32,2.32,0,0,0,.85,1.89,3.7,3.7,0,0,0,3.38.51,3.47,3.47,0,0,0,.78-.4.84.84,0,0,1,.43-.2.49.49,0,0,1,.39.21.69.69,0,0,1,.17.41c0,.28-.29.57-.86.87a4.58,4.58,0,0,1-2.14.45,4.53,4.53,0,0,1-3.1-1.05A3.48,3.48,0,0,1,88,440.64Zm1.3-1.64h4a1.59,1.59,0,0,0,.8-.13c.13-.08.2-.27.2-.55a2.47,2.47,0,0,0-.71-1.74,2.33,2.33,0,0,0-1.79-.76,2.44,2.44,0,0,0-1.77.72,2.27,2.27,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M97.51,443.78v-8.46a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.72.72,0,0,1,.44.14.44.44,0,0,1,.18.35v.93a2.91,2.91,0,0,1,1-1.1,3,3,0,0,1,1.61-.44,2.79,2.79,0,0,1,1.72.56,3.15,3.15,0,0,1,1.12,1.46,3.69,3.69,0,0,1,1.32-1.51,3.21,3.21,0,0,1,4.08.51,3.71,3.71,0,0,1,1,2.72v5.32a.47.47,0,0,1-.19.36.69.69,0,0,1-.47.16.67.67,0,0,1-.45-.16.47.47,0,0,1-.19-.36v-5.31a2.47,2.47,0,0,0-.69-1.85,2.21,2.21,0,0,0-1.59-.68,2.27,2.27,0,0,0-1.63.69,2.37,2.37,0,0,0-.71,1.76v5.41c0,.33-.22.5-.66.5s-.64-.17-.64-.5v-5.39a2.45,2.45,0,0,0-.67-1.76,2.15,2.15,0,0,0-1.61-.71,2.28,2.28,0,0,0-1.65.69,2.46,2.46,0,0,0-.71,1.84v5.31a.48.48,0,0,1-.2.36.7.7,0,0,1-.46.16.67.67,0,0,1-.45-.16A.47.47,0,0,1,97.51,443.78Z"/>
            <path className="cls-1" d="M112.75,440.64V438.4a3.48,3.48,0,0,1,1.12-2.59,3.87,3.87,0,0,1,5.33-.05,3.43,3.43,0,0,1,1.11,2.6,2.45,2.45,0,0,1-.13.9.84.84,0,0,1-.42.48,2.21,2.21,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0h-4.52v.74a2.32,2.32,0,0,0,.85,1.89,3.7,3.7,0,0,0,3.38.51,3.47,3.47,0,0,0,.78-.4.84.84,0,0,1,.43-.2.49.49,0,0,1,.39.21.69.69,0,0,1,.17.41c0,.28-.29.57-.86.87a4.58,4.58,0,0,1-2.14.45,4.53,4.53,0,0,1-3.1-1.05A3.48,3.48,0,0,1,112.75,440.64Zm1.3-1.64h4a1.59,1.59,0,0,0,.8-.13c.13-.08.2-.27.2-.55a2.47,2.47,0,0,0-.71-1.74,2.33,2.33,0,0,0-1.79-.76,2.44,2.44,0,0,0-1.77.72,2.27,2.27,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M122.27,443.78v-8.46a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.72.72,0,0,1,.44.14.44.44,0,0,1,.18.35v1a3.08,3.08,0,0,1,1.06-1.13,2.88,2.88,0,0,1,1.62-.47h.7a.48.48,0,0,1,.36.18.66.66,0,0,1,0,.86.48.48,0,0,1-.36.18h-.7a2.51,2.51,0,0,0-1.84.81,2.87,2.87,0,0,0-.8,2.1v4.93a.47.47,0,0,1-.19.36.69.69,0,0,1-.47.16.67.67,0,0,1-.45-.16A.47.47,0,0,1,122.27,443.78Z"/>
            <path className="cls-1" d="M127.91,446.28a2.29,2.29,0,0,1,1.7-2.18,1.4,1.4,0,0,1-1.1-1.37,1.59,1.59,0,0,1,1-1.43,3.14,3.14,0,0,1-1.1-2.53V438a3.16,3.16,0,0,1,1-2.38,3.79,3.79,0,0,1,4.83-.08,3,3,0,0,1,.68-.91,1.11,1.11,0,0,1,.74-.34.58.58,0,0,1,.44.16.5.5,0,0,1,.16.36.4.4,0,0,1-.34.44,1.64,1.64,0,0,0-1.12.84,3.09,3.09,0,0,1,.56,1.89v.76a3.2,3.2,0,0,1-1,2.39,3.45,3.45,0,0,1-2.5.94,3.64,3.64,0,0,1-1.54-.33c-.41.22-.62.48-.62.77a.69.69,0,0,0,.46.64,4.06,4.06,0,0,0,1.15.31l1.49.19a9.14,9.14,0,0,1,1.51.31,2.32,2.32,0,0,1,1.15.74,2,2,0,0,1,.46,1.39,2.39,2.39,0,0,1-1.08,2.1,5,5,0,0,1-2.84.74,5.37,5.37,0,0,1-2.93-.72A2.21,2.21,0,0,1,127.91,446.28Zm1.26-.12a1.51,1.51,0,0,0,.75,1.32,3.65,3.65,0,0,0,2,.5,3.39,3.39,0,0,0,2-.5,1.58,1.58,0,0,0,.71-1.35,1.27,1.27,0,0,0-.2-.7A1.47,1.47,0,0,0,134,445a3.28,3.28,0,0,0-.71-.28,4.41,4.41,0,0,0-.78-.15l-.84-.09-.68-.07C129.76,444.75,129.17,445.35,129.17,446.16Zm.5-7.42a2.53,2.53,0,0,0,.59,1.72,2,2,0,0,0,1.61.66,2,2,0,0,0,1.57-.66,2.49,2.49,0,0,0,.59-1.7V438a2.39,2.39,0,0,0-.59-1.66,2.25,2.25,0,0,0-3.18,0,2.39,2.39,0,0,0-.59,1.66Z"/>
            <path className="cls-1" d="M137.71,431.79a.8.8,0,0,1-.26-.59.77.77,0,0,1,.26-.58.91.91,0,0,1,.64-.24.85.85,0,0,1,.61.24.76.76,0,0,1,.25.58.79.79,0,0,1-.25.59.82.82,0,0,1-.61.25A.87.87,0,0,1,137.71,431.79Zm0,12v-8.46a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.79.79,0,0,1,.47.14.4.4,0,0,1,.19.34v8.46a.48.48,0,0,1-.2.36.7.7,0,0,1-.46.16.67.67,0,0,1-.45-.16A.47.47,0,0,1,137.71,443.78Z"/>
            <path className="cls-1" d="M141.53,443.78v-8.46a.41.41,0,0,1,.18-.35.74.74,0,0,1,.46-.13.68.68,0,0,1,.42.14.44.44,0,0,1,.18.35v.93a2.85,2.85,0,0,1,1-1.09,3,3,0,0,1,1.64-.45,3.38,3.38,0,0,1,2.5,1.1,3.65,3.65,0,0,1,1.06,2.64v5.32a.44.44,0,0,1-.2.38.81.81,0,0,1-.46.14.77.77,0,0,1-.44-.14.44.44,0,0,1-.2-.38v-5.31a2.49,2.49,0,0,0-.74-1.75,2.34,2.34,0,0,0-1.76-.78,2.29,2.29,0,0,0-1.66.7,2.43,2.43,0,0,0-.72,1.83v5.31a.48.48,0,0,1-.2.36.7.7,0,0,1-.46.16.65.65,0,0,1-.46-.16A.48.48,0,0,1,141.53,443.78Z"/>
            <path className="cls-1" d="M150.69,446.28a2.29,2.29,0,0,1,1.7-2.18,1.4,1.4,0,0,1-1.1-1.37,1.59,1.59,0,0,1,1-1.43,3.14,3.14,0,0,1-1.1-2.53V438a3.16,3.16,0,0,1,.95-2.38,3.79,3.79,0,0,1,4.83-.08,3,3,0,0,1,.68-.91,1.11,1.11,0,0,1,.74-.34.58.58,0,0,1,.44.16.5.5,0,0,1,.16.36.4.4,0,0,1-.34.44,1.64,1.64,0,0,0-1.12.84,3.09,3.09,0,0,1,.56,1.89v.76a3.2,3.2,0,0,1-1,2.39,3.45,3.45,0,0,1-2.5.94,3.64,3.64,0,0,1-1.54-.33c-.41.22-.62.48-.62.77a.69.69,0,0,0,.46.64,4.06,4.06,0,0,0,1.15.31l1.49.19a9.14,9.14,0,0,1,1.51.31,2.32,2.32,0,0,1,1.15.74,2,2,0,0,1,.46,1.39,2.39,2.39,0,0,1-1.08,2.1,5,5,0,0,1-2.84.74,5.37,5.37,0,0,1-2.93-.72A2.21,2.21,0,0,1,150.69,446.28Zm1.26-.12a1.51,1.51,0,0,0,.75,1.32,3.65,3.65,0,0,0,2,.5,3.39,3.39,0,0,0,2-.5,1.58,1.58,0,0,0,.71-1.35,1.27,1.27,0,0,0-.2-.7,1.47,1.47,0,0,0-.47-.46,3.28,3.28,0,0,0-.71-.28,4.41,4.41,0,0,0-.78-.15l-.84-.09-.68-.07C152.54,444.75,152,445.35,152,446.16Zm.5-7.42a2.53,2.53,0,0,0,.59,1.72,2,2,0,0,0,1.61.66,2,2,0,0,0,1.57-.66,2.49,2.49,0,0,0,.59-1.7V438a2.39,2.39,0,0,0-.59-1.66,2.25,2.25,0,0,0-3.18,0,2.39,2.39,0,0,0-.59,1.66Z"/>
            <path className="cls-1" d="M160,443.5a.93.93,0,0,1,.25-.67.83.83,0,0,1,.62-.27.91.91,0,0,1,.89.94,11.57,11.57,0,0,1-.76,2.24.41.41,0,0,1-.4.24.57.57,0,0,1-.33-.11.32.32,0,0,1-.15-.27,1,1,0,0,1,0-.2l.4-1A.87.87,0,0,1,160,443.5Z"/>
            <path className="cls-1" d="M167.25,441.8a2.25,2.25,0,0,1,1.19-2.19,7.76,7.76,0,0,1,3.47-.61h1v-.47a3.09,3.09,0,0,0-.58-1.88,2.08,2.08,0,0,0-1.78-.79,3.75,3.75,0,0,0-1.45.28,7.44,7.44,0,0,1-.69.29.42.42,0,0,1-.38-.22.86.86,0,0,1-.14-.47c0-.25.29-.49.86-.7a5.31,5.31,0,0,1,1.84-.32,3.3,3.3,0,0,1,2.75,1.09,4.31,4.31,0,0,1,.87,2.77v5.2a.46.46,0,0,1-.18.37.69.69,0,0,1-.46.15.63.63,0,0,1-.43-.15.48.48,0,0,1-.17-.37v-.88a3.93,3.93,0,0,1-3,1.54,2.84,2.84,0,0,1-2-.67A2.48,2.48,0,0,1,167.25,441.8Zm1.3-.12a1.55,1.55,0,0,0,.48,1.25,1.89,1.89,0,0,0,1.26.41,2.76,2.76,0,0,0,1.79-.7,1.9,1.9,0,0,0,.85-1.4V440h-.84a12.84,12.84,0,0,0-1.35.06,5.85,5.85,0,0,0-1.09.22,1.6,1.6,0,0,0-.82.52A1.44,1.44,0,0,0,168.55,441.68Z"/>
            <path className="cls-1" d="M176.83,443.78V429.52a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.79.79,0,0,1,.47.14.4.4,0,0,1,.19.34v14.26a.47.47,0,0,1-.19.36.69.69,0,0,1-.47.16.67.67,0,0,1-.45-.16A.47.47,0,0,1,176.83,443.78Z"/>
            <path className="cls-1" d="M180.43,440.64v-2.18a3.57,3.57,0,0,1,1.12-2.63,3.65,3.65,0,0,1,2.66-1.11,3.74,3.74,0,0,1,2.68,1.1,3.53,3.53,0,0,1,1.14,2.64v2.18a3.93,3.93,0,0,1-3.82,3.8,3.6,3.6,0,0,1-2.64-1.15A3.66,3.66,0,0,1,180.43,440.64Zm1.3,0a2.54,2.54,0,0,0,.73,1.8,2.29,2.29,0,0,0,1.75.79,2.34,2.34,0,0,0,1.78-.79,2.55,2.55,0,0,0,.74-1.8v-2.16a2.44,2.44,0,0,0-.75-1.75,2.38,2.38,0,0,0-1.77-.78,2.32,2.32,0,0,0-1.75.78,2.46,2.46,0,0,0-.73,1.75Z"/>
            <path className="cls-1" d="M190.17,443.78v-8.46a.41.41,0,0,1,.18-.35.74.74,0,0,1,.46-.13.68.68,0,0,1,.42.14.44.44,0,0,1,.18.35v.93a2.85,2.85,0,0,1,1-1.09,3,3,0,0,1,1.64-.45,3.38,3.38,0,0,1,2.5,1.1,3.65,3.65,0,0,1,1.06,2.64v5.32a.44.44,0,0,1-.2.38.81.81,0,0,1-.46.14.77.77,0,0,1-.44-.14.44.44,0,0,1-.2-.38v-5.31a2.49,2.49,0,0,0-.74-1.75,2.34,2.34,0,0,0-1.76-.78,2.29,2.29,0,0,0-1.66.7,2.43,2.43,0,0,0-.72,1.83v5.31a.48.48,0,0,1-.2.36.7.7,0,0,1-.46.16.65.65,0,0,1-.46-.16A.48.48,0,0,1,190.17,443.78Z"/>
            <path className="cls-1" d="M199.33,446.28a2.29,2.29,0,0,1,1.7-2.18,1.4,1.4,0,0,1-1.1-1.37,1.59,1.59,0,0,1,1-1.43,3.14,3.14,0,0,1-1.1-2.53V438a3.16,3.16,0,0,1,.95-2.38,3.79,3.79,0,0,1,4.83-.08,3,3,0,0,1,.68-.91,1.11,1.11,0,0,1,.74-.34.58.58,0,0,1,.44.16.5.5,0,0,1,.16.36.4.4,0,0,1-.34.44,1.64,1.64,0,0,0-1.12.84,3.09,3.09,0,0,1,.56,1.89v.76a3.2,3.2,0,0,1-1,2.39,3.45,3.45,0,0,1-2.5.94,3.64,3.64,0,0,1-1.54-.33c-.41.22-.62.48-.62.77a.69.69,0,0,0,.46.64,4.08,4.08,0,0,0,1.14.31l1.5.19a9.14,9.14,0,0,1,1.51.31,2.32,2.32,0,0,1,1.15.74,2,2,0,0,1,.46,1.39,2.39,2.39,0,0,1-1.08,2.1,5,5,0,0,1-2.84.74,5.37,5.37,0,0,1-2.93-.72A2.21,2.21,0,0,1,199.33,446.28Zm1.26-.12a1.51,1.51,0,0,0,.75,1.32,3.65,3.65,0,0,0,2,.5,3.39,3.39,0,0,0,2-.5,1.58,1.58,0,0,0,.71-1.35,1.27,1.27,0,0,0-.2-.7,1.47,1.47,0,0,0-.47-.46,3.28,3.28,0,0,0-.71-.28,4.41,4.41,0,0,0-.78-.15l-.84-.09-.68-.07C201.18,444.75,200.59,445.35,200.59,446.16Zm.5-7.42a2.53,2.53,0,0,0,.59,1.72,2,2,0,0,0,1.61.66,2,2,0,0,0,1.57-.66,2.49,2.49,0,0,0,.59-1.7V438a2.39,2.39,0,0,0-.59-1.66,2.25,2.25,0,0,0-3.18,0,2.39,2.39,0,0,0-.59,1.66Z"/>
            <path className="cls-1" d="M213.09,441.36V430.84a.42.42,0,0,1,.2-.34.77.77,0,0,1,.44-.14.81.81,0,0,1,.46.14.42.42,0,0,1,.2.34v4H217a.41.41,0,0,1,.33.16.65.65,0,0,1,0,.78.41.41,0,0,1-.33.16h-2.58v5.39a1.83,1.83,0,0,0,.41,1.36,2.1,2.1,0,0,0,1.45.39h.56a.57.57,0,0,1,.43.18.57.57,0,0,1,.17.42.61.61,0,0,1-.17.43.54.54,0,0,1-.43.19h-.56Q213.08,444.3,213.09,441.36Z"/>
            <path className="cls-1" d="M219.07,443.78V429.54a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.79.79,0,0,1,.47.14.4.4,0,0,1,.19.34v6.72a3,3,0,0,1,2.64-1.54,3.34,3.34,0,0,1,2.49,1.1,3.68,3.68,0,0,1,1.05,2.64v5.32a.44.44,0,0,1-.19.38.79.79,0,0,1-.47.14.77.77,0,0,1-.44-.14.44.44,0,0,1-.2-.38v-5.31a2.49,2.49,0,0,0-.74-1.75,2.34,2.34,0,0,0-3.41-.08,2.4,2.4,0,0,0-.73,1.83v5.31a.48.48,0,0,1-.2.36.7.7,0,0,1-.46.16.67.67,0,0,1-.45-.16A.47.47,0,0,1,219.07,443.78Z"/>
            <path className="cls-1" d="M228.63,440.64V438.4a3.48,3.48,0,0,1,1.12-2.59,3.87,3.87,0,0,1,5.33-.05,3.43,3.43,0,0,1,1.11,2.6,2.45,2.45,0,0,1-.13.9.84.84,0,0,1-.42.48,2.21,2.21,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0h-4.52v.74a2.32,2.32,0,0,0,.85,1.89,3.7,3.7,0,0,0,3.38.51,3.47,3.47,0,0,0,.78-.4.84.84,0,0,1,.43-.2.49.49,0,0,1,.39.21.69.69,0,0,1,.17.41c0,.28-.29.57-.86.87a4.58,4.58,0,0,1-2.14.45,4.53,4.53,0,0,1-3.1-1.05A3.48,3.48,0,0,1,228.63,440.64Zm1.3-1.64h4a1.59,1.59,0,0,0,.8-.13c.13-.08.2-.27.2-.55a2.47,2.47,0,0,0-.71-1.74,2.33,2.33,0,0,0-1.79-.76,2.44,2.44,0,0,0-1.77.72,2.27,2.27,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M241.61,442.3a.8.8,0,0,1,.19-.49.52.52,0,0,1,.41-.25c.11,0,.24.09.4.26l.56.58a2.67,2.67,0,0,0,1,.57,4.12,4.12,0,0,0,1.51.25,3.16,3.16,0,0,0,2.2-.71,2.63,2.63,0,0,0,.78-2.05,2.94,2.94,0,0,0-.37-1.53,2.68,2.68,0,0,0-1-1,7.28,7.28,0,0,0-1.28-.64l-1.44-.56a6.52,6.52,0,0,1-1.29-.67,3,3,0,0,1-.94-1.1,3.57,3.57,0,0,1-.37-1.69,3.86,3.86,0,0,1,.39-1.8,2.77,2.77,0,0,1,1-1.14,5.19,5.19,0,0,1,1.35-.55,6.19,6.19,0,0,1,1.45-.17,7.42,7.42,0,0,1,2.29.35c.71.23,1.07.52,1.07.87a.91.91,0,0,1-.17.49.48.48,0,0,1-.41.25,1.33,1.33,0,0,1-.46-.18,5.15,5.15,0,0,0-.95-.38,4.93,4.93,0,0,0-1.39-.18,3.72,3.72,0,0,0-2.08.54,2,2,0,0,0-.82,1.78,2.15,2.15,0,0,0,.49,1.44,2.77,2.77,0,0,0,1.23.84c.49.17,1,.38,1.6.62a17,17,0,0,1,1.6.77,3.23,3.23,0,0,1,1.23,1.3,4.43,4.43,0,0,1,.49,2.19,3.94,3.94,0,0,1-1.14,3.08,4.45,4.45,0,0,1-3.08,1,5.43,5.43,0,0,1-2.92-.74C242,443.2,241.61,442.74,241.61,442.3Z"/>
            <path className="cls-1" d="M250.93,440.3A.68.68,0,0,1,251,440l5-9.88a.65.65,0,0,1,.62-.4.68.68,0,0,1,.43.16.47.47,0,0,1,.21.38.65.65,0,0,1-.06.26l-4.62,9.12h3.92v-3.89q0-.51.66-.51a.8.8,0,0,1,.46.13.43.43,0,0,1,.2.38v3.89H259a.43.43,0,0,1,.37.18.7.7,0,0,1,.13.42.74.74,0,0,1-.14.44.43.43,0,0,1-.36.18h-1.1v2.95a.46.46,0,0,1-.2.39.81.81,0,0,1-.46.14.79.79,0,0,1-.47-.14.45.45,0,0,1-.19-.39v-2.95h-5.08A.46.46,0,0,1,250.93,440.3Z"/>
            <path className="cls-1" d="M264.91,448.44V435.32a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.72.72,0,0,1,.44.14.44.44,0,0,1,.18.35v.93a3,3,0,0,1,1.06-1.09,3,3,0,0,1,1.62-.45,3.42,3.42,0,0,1,2.54,1.1,3.65,3.65,0,0,1,1.06,2.64v2.18a3.8,3.8,0,0,1-1.07,2.65,3.27,3.27,0,0,1-2.47,1.15,3,3,0,0,1-1.62-.46,2.91,2.91,0,0,1-1.08-1.1v5.56a.45.45,0,0,1-.19.34.69.69,0,0,1-.47.16.67.67,0,0,1-.45-.16A.45.45,0,0,1,264.91,448.44Zm1.3-7.19a1.92,1.92,0,0,0,.75,1.25,2.4,2.4,0,0,0,1.71.72,2.29,2.29,0,0,0,1.73-.8,2.53,2.53,0,0,0,.75-1.79v-2.16a2.44,2.44,0,0,0-.75-1.75,2.42,2.42,0,0,0-4.19,1.75Z"/>
            <path className="cls-1" d="M273.93,441.8a2.25,2.25,0,0,1,1.19-2.19,7.76,7.76,0,0,1,3.47-.61h1v-.47a3.09,3.09,0,0,0-.58-1.88,2.08,2.08,0,0,0-1.78-.79,3.75,3.75,0,0,0-1.45.28,7.44,7.44,0,0,1-.69.29.42.42,0,0,1-.38-.22.86.86,0,0,1-.14-.47c0-.25.29-.49.86-.7a5.31,5.31,0,0,1,1.84-.32,3.3,3.3,0,0,1,2.75,1.09,4.31,4.31,0,0,1,.87,2.77v5.2a.46.46,0,0,1-.18.37.69.69,0,0,1-.46.15.63.63,0,0,1-.43-.15.48.48,0,0,1-.17-.37v-.88a3.93,3.93,0,0,1-3,1.54,2.84,2.84,0,0,1-2-.67A2.48,2.48,0,0,1,273.93,441.8Zm1.3-.12a1.55,1.55,0,0,0,.48,1.25,1.89,1.89,0,0,0,1.26.41,2.76,2.76,0,0,0,1.79-.7,1.9,1.9,0,0,0,.85-1.4V440h-.84a12.84,12.84,0,0,0-1.35.06,5.85,5.85,0,0,0-1.09.22,1.6,1.6,0,0,0-.82.52A1.44,1.44,0,0,0,275.23,441.68Z"/>
            <path className="cls-1" d="M283.35,443.78v-8.46a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.72.72,0,0,1,.44.14.44.44,0,0,1,.18.35v1a3.08,3.08,0,0,1,1.06-1.13,2.88,2.88,0,0,1,1.62-.47h.7a.48.48,0,0,1,.36.18.66.66,0,0,1,0,.86.48.48,0,0,1-.36.18h-.7a2.51,2.51,0,0,0-1.84.81,2.87,2.87,0,0,0-.8,2.1v4.93a.5.5,0,0,1-.19.36.69.69,0,0,1-.47.16.67.67,0,0,1-.45-.16A.47.47,0,0,1,283.35,443.78Z"/>
            <path className="cls-1" d="M289.31,441.8a2.24,2.24,0,0,1,1.19-2.19A7.76,7.76,0,0,1,294,439h1v-.47a3.09,3.09,0,0,0-.58-1.88,2.08,2.08,0,0,0-1.78-.79,3.75,3.75,0,0,0-1.45.28,7.44,7.44,0,0,1-.69.29.42.42,0,0,1-.38-.22.86.86,0,0,1-.14-.47c0-.25.29-.49.86-.7a5.31,5.31,0,0,1,1.84-.32,3.3,3.3,0,0,1,2.75,1.09,4.31,4.31,0,0,1,.87,2.77v5.2a.46.46,0,0,1-.18.37.69.69,0,0,1-.46.15.63.63,0,0,1-.43-.15.48.48,0,0,1-.17-.37v-.88a3.93,3.93,0,0,1-3,1.54,2.84,2.84,0,0,1-2-.67A2.48,2.48,0,0,1,289.31,441.8Zm1.3-.12a1.55,1.55,0,0,0,.48,1.25,1.89,1.89,0,0,0,1.26.41,2.76,2.76,0,0,0,1.79-.7,1.93,1.93,0,0,0,.85-1.4V440h-.84a12.84,12.84,0,0,0-1.35.06,5.85,5.85,0,0,0-1.09.22,1.6,1.6,0,0,0-.82.52A1.44,1.44,0,0,0,290.61,441.68Z"/>
            <path className="cls-1" d="M298.37,440.64v-2.18a3.68,3.68,0,0,1,1.05-2.64,3.38,3.38,0,0,1,2.53-1.1,3,3,0,0,1,2.64,1.54v-6.72a.42.42,0,0,1,.2-.34.83.83,0,0,1,.48-.14.71.71,0,0,1,.45.14.44.44,0,0,1,.17.34v14.24a.48.48,0,0,1-.18.36.63.63,0,0,1-.44.16.62.62,0,0,1-.43-.16.47.47,0,0,1-.19-.37v-.89a2.87,2.87,0,0,1-1.09,1.1,3.07,3.07,0,0,1-1.65.46,3.26,3.26,0,0,1-2.48-1.15A3.79,3.79,0,0,1,298.37,440.64Zm1.3,0a2.56,2.56,0,0,0,.74,1.79,2.27,2.27,0,0,0,1.74.8,2.36,2.36,0,0,0,1.7-.72,1.94,1.94,0,0,0,.74-1.25v-2.78a2.39,2.39,0,0,0-.73-1.83,2.38,2.38,0,0,0-3.45.08,2.47,2.47,0,0,0-.74,1.75Z"/>
            <path className="cls-1" d="M308.41,431.79a.8.8,0,0,1-.26-.59.77.77,0,0,1,.26-.58.89.89,0,0,1,.64-.24.85.85,0,0,1,.61.24.76.76,0,0,1,.25.58.79.79,0,0,1-.25.59.82.82,0,0,1-.61.25A.86.86,0,0,1,308.41,431.79Zm0,12v-8.46a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.79.79,0,0,1,.47.14.43.43,0,0,1,.19.34v8.46a.48.48,0,0,1-.2.36.7.7,0,0,1-.46.16.65.65,0,0,1-.45-.16A.47.47,0,0,1,308.41,443.78Z"/>
            <path className="cls-1" d="M311.47,446.28a2.29,2.29,0,0,1,1.7-2.18,1.4,1.4,0,0,1-1.1-1.37,1.59,1.59,0,0,1,1-1.43,3.11,3.11,0,0,1-1.1-2.53V438a3.16,3.16,0,0,1,.95-2.38,3.79,3.79,0,0,1,4.83-.08,3,3,0,0,1,.68-.91,1.11,1.11,0,0,1,.74-.34.58.58,0,0,1,.44.16.54.54,0,0,1,.16.36.4.4,0,0,1-.34.44,1.64,1.64,0,0,0-1.12.84,3.09,3.09,0,0,1,.56,1.89v.76a3.2,3.2,0,0,1-1,2.39,3.45,3.45,0,0,1-2.5.94,3.64,3.64,0,0,1-1.54-.33c-.41.22-.62.48-.62.77a.69.69,0,0,0,.46.64,4.08,4.08,0,0,0,1.14.31l1.5.19a9.14,9.14,0,0,1,1.51.31,2.32,2.32,0,0,1,1.15.74,2.08,2.08,0,0,1,.46,1.39,2.39,2.39,0,0,1-1.08,2.1,5,5,0,0,1-2.84.74,5.37,5.37,0,0,1-2.93-.72A2.21,2.21,0,0,1,311.47,446.28Zm1.26-.12a1.51,1.51,0,0,0,.75,1.32,3.65,3.65,0,0,0,2,.5,3.42,3.42,0,0,0,2-.5,1.58,1.58,0,0,0,.71-1.35,1.27,1.27,0,0,0-.2-.7,1.47,1.47,0,0,0-.47-.46,3.28,3.28,0,0,0-.71-.28,4.41,4.41,0,0,0-.78-.15l-.84-.09-.68-.07C313.32,444.75,312.73,445.35,312.73,446.16Zm.5-7.42a2.53,2.53,0,0,0,.59,1.72,2,2,0,0,0,1.61.66,2,2,0,0,0,1.57-.66,2.49,2.49,0,0,0,.59-1.7V438a2.39,2.39,0,0,0-.59-1.66,2.25,2.25,0,0,0-3.18,0,2.39,2.39,0,0,0-.59,1.66Z"/>
            <path className="cls-1" d="M321.27,443.78v-8.46a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.72.72,0,0,1,.44.14.44.44,0,0,1,.18.35v.93a2.91,2.91,0,0,1,1.05-1.1,3,3,0,0,1,1.61-.44,2.79,2.79,0,0,1,1.72.56,3.15,3.15,0,0,1,1.12,1.46,3.69,3.69,0,0,1,1.32-1.51,3.21,3.21,0,0,1,4.08.51,3.71,3.71,0,0,1,1,2.72v5.32a.47.47,0,0,1-.19.36.71.71,0,0,1-.47.16.67.67,0,0,1-.45-.16.47.47,0,0,1-.19-.36v-5.31a2.47,2.47,0,0,0-.69-1.85,2.21,2.21,0,0,0-1.59-.68,2.27,2.27,0,0,0-1.63.69,2.37,2.37,0,0,0-.71,1.76v5.41c0,.33-.22.5-.66.5s-.64-.17-.64-.5v-5.39a2.45,2.45,0,0,0-.67-1.76,2.15,2.15,0,0,0-1.61-.71,2.28,2.28,0,0,0-1.65.69,2.46,2.46,0,0,0-.71,1.84v5.31a.48.48,0,0,1-.2.36.7.7,0,0,1-.46.16.65.65,0,0,1-.45-.16A.47.47,0,0,1,321.27,443.78Z"/>
            <path className="cls-1" d="M340.87,448.44V435.32a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.72.72,0,0,1,.44.14.44.44,0,0,1,.18.35v.93a3,3,0,0,1,1.06-1.09,3,3,0,0,1,1.62-.45,3.43,3.43,0,0,1,2.54,1.1,3.69,3.69,0,0,1,1.06,2.64v2.18a3.8,3.8,0,0,1-1.07,2.65,3.27,3.27,0,0,1-2.47,1.15,3,3,0,0,1-1.62-.46,2.77,2.77,0,0,1-1.08-1.1v5.56a.48.48,0,0,1-.19.34.69.69,0,0,1-.47.16.64.64,0,0,1-.45-.16A.45.45,0,0,1,340.87,448.44Zm1.3-7.19a1.92,1.92,0,0,0,.75,1.25,2.4,2.4,0,0,0,1.71.72,2.29,2.29,0,0,0,1.73-.8,2.53,2.53,0,0,0,.75-1.79v-2.16a2.44,2.44,0,0,0-.75-1.75,2.38,2.38,0,0,0-1.77-.78,2.34,2.34,0,0,0-1.69.7,2.39,2.39,0,0,0-.73,1.83Z"/>
            <path className="cls-1" d="M350.55,443.78v-8.46a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.72.72,0,0,1,.44.14.44.44,0,0,1,.18.35v1a3.08,3.08,0,0,1,1.06-1.13,2.86,2.86,0,0,1,1.62-.47h.7a.48.48,0,0,1,.36.18.66.66,0,0,1,0,.86.48.48,0,0,1-.36.18h-.7a2.5,2.5,0,0,0-1.84.81,2.83,2.83,0,0,0-.8,2.1v4.93a.5.5,0,0,1-.19.36.69.69,0,0,1-.47.16.64.64,0,0,1-.45-.16A.47.47,0,0,1,350.55,443.78Z"/>
            <path className="cls-1" d="M356.43,440.64v-2.18a3.57,3.57,0,0,1,1.12-2.63,3.63,3.63,0,0,1,2.66-1.11,3.76,3.76,0,0,1,2.68,1.1,3.53,3.53,0,0,1,1.14,2.64v2.18a3.93,3.93,0,0,1-3.82,3.8,3.6,3.6,0,0,1-2.64-1.15A3.66,3.66,0,0,1,356.43,440.64Zm1.3,0a2.54,2.54,0,0,0,.73,1.8,2.28,2.28,0,0,0,1.75.79,2.36,2.36,0,0,0,1.78-.79,2.55,2.55,0,0,0,.74-1.8v-2.16a2.44,2.44,0,0,0-.75-1.75,2.38,2.38,0,0,0-1.77-.78,2.3,2.3,0,0,0-1.75.78,2.46,2.46,0,0,0-.73,1.75Z"/>
            <path className="cls-1" d="M366.17,448.44V435.32a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.72.72,0,0,1,.44.14.44.44,0,0,1,.18.35v.93a3,3,0,0,1,1.06-1.09,2.9,2.9,0,0,1,1.62-.45,3.43,3.43,0,0,1,2.54,1.1,3.69,3.69,0,0,1,1.06,2.64v2.18a3.8,3.8,0,0,1-1.07,2.65,3.29,3.29,0,0,1-2.47,1.15,3,3,0,0,1-1.62-.46,2.77,2.77,0,0,1-1.08-1.1v5.56a.48.48,0,0,1-.19.34.71.71,0,0,1-.47.16.64.64,0,0,1-.45-.16A.45.45,0,0,1,366.17,448.44Zm1.3-7.19a1.92,1.92,0,0,0,.75,1.25,2.4,2.4,0,0,0,1.71.72,2.29,2.29,0,0,0,1.73-.8,2.53,2.53,0,0,0,.75-1.79v-2.16a2.44,2.44,0,0,0-.75-1.75,2.38,2.38,0,0,0-1.77-.78,2.34,2.34,0,0,0-1.69.7,2.39,2.39,0,0,0-.73,1.83Z"/>
            <path className="cls-1" d="M375.47,440.64v-2.18a3.57,3.57,0,0,1,1.12-2.63,3.63,3.63,0,0,1,2.66-1.11,3.76,3.76,0,0,1,2.68,1.1,3.53,3.53,0,0,1,1.14,2.64v2.18a3.93,3.93,0,0,1-3.82,3.8,3.6,3.6,0,0,1-2.64-1.15A3.66,3.66,0,0,1,375.47,440.64Zm1.3,0a2.54,2.54,0,0,0,.73,1.8,2.28,2.28,0,0,0,1.75.79,2.36,2.36,0,0,0,1.78-.79,2.55,2.55,0,0,0,.74-1.8v-2.16a2.44,2.44,0,0,0-.75-1.75,2.38,2.38,0,0,0-1.77-.78,2.3,2.3,0,0,0-1.75.78,2.46,2.46,0,0,0-.73,1.75Z"/>
            <path className="cls-1" d="M384.61,443a.65.65,0,0,1,.14-.4.4.4,0,0,1,.32-.2,1,1,0,0,1,.44.23,4.08,4.08,0,0,0,.86.47,3.38,3.38,0,0,0,1.34.24,2.49,2.49,0,0,0,1.56-.4,1.27,1.27,0,0,0,.5-1,1.43,1.43,0,0,0-.51-1.15,3.44,3.44,0,0,0-1.23-.65l-1.45-.44a2.72,2.72,0,0,1-1.24-.77,2.09,2.09,0,0,1-.51-1.47,2.64,2.64,0,0,1,.79-1.91,3.15,3.15,0,0,1,2.37-.81,5.25,5.25,0,0,1,1.83.31c.56.21.85.43.85.67a.88.88,0,0,1-.16.44.47.47,0,0,1-.38.24,5.24,5.24,0,0,1-.74-.28,3.58,3.58,0,0,0-1.38-.28,2.12,2.12,0,0,0-1.5.47,1.4,1.4,0,0,0-.5,1.06,1.12,1.12,0,0,0,.51,1,4.9,4.9,0,0,0,1.24.57l1.47.43a2.37,2.37,0,0,1,1.24.87,2.69,2.69,0,0,1,.5,1.68,2.32,2.32,0,0,1-.85,1.89,3.68,3.68,0,0,1-2.37.69,4.84,4.84,0,0,1-2.23-.47C384.91,443.66,384.61,443.33,384.61,443Z"/>
            <path className="cls-1" d="M392.55,440.64V438.4a3.48,3.48,0,0,1,1.12-2.59,3.7,3.7,0,0,1,2.7-1.09,3.74,3.74,0,0,1,2.63,1,3.43,3.43,0,0,1,1.11,2.6,2.68,2.68,0,0,1-.13.9.84.84,0,0,1-.42.48,2.37,2.37,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0h-4.52v.74a2.32,2.32,0,0,0,.85,1.89,3.29,3.29,0,0,0,2.19.71,3.33,3.33,0,0,0,1.19-.2,3.47,3.47,0,0,0,.78-.4.84.84,0,0,1,.43-.2.51.51,0,0,1,.39.21.69.69,0,0,1,.17.41c0,.28-.29.57-.86.87a4.61,4.61,0,0,1-2.14.45,4.53,4.53,0,0,1-3.1-1.05A3.48,3.48,0,0,1,392.55,440.64Zm1.3-1.64h4a1.62,1.62,0,0,0,.8-.13c.13-.08.2-.27.2-.55a2.47,2.47,0,0,0-.71-1.74,2.34,2.34,0,0,0-1.79-.76,2.41,2.41,0,0,0-1.77.72,2.27,2.27,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M401.71,440.64v-2.18a3.68,3.68,0,0,1,1.05-2.64,3.38,3.38,0,0,1,2.53-1.1,3,3,0,0,1,2.64,1.54v-6.72a.39.39,0,0,1,.2-.34.83.83,0,0,1,.48-.14.73.73,0,0,1,.45.14.44.44,0,0,1,.17.34v14.24a.48.48,0,0,1-.18.36.63.63,0,0,1-.44.16.62.62,0,0,1-.43-.16.47.47,0,0,1-.19-.37v-.89a2.87,2.87,0,0,1-1.09,1.1,3.07,3.07,0,0,1-1.65.46,3.25,3.25,0,0,1-2.48-1.15A3.79,3.79,0,0,1,401.71,440.64Zm1.3,0a2.52,2.52,0,0,0,.74,1.79,2.27,2.27,0,0,0,1.74.8,2.38,2.38,0,0,0,1.7-.72,1.94,1.94,0,0,0,.74-1.25v-2.78a2.42,2.42,0,0,0-.73-1.83,2.38,2.38,0,0,0-3.45.08,2.43,2.43,0,0,0-.74,1.75Z"/>
            <path className="cls-1" d="M415.75,443.78V429.54a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.82.82,0,0,1,.47.14.43.43,0,0,1,.19.34v6.72a2.91,2.91,0,0,1,1.05-1.1,2.94,2.94,0,0,1,1.61-.44,3.39,3.39,0,0,1,2.53,1.1,3.68,3.68,0,0,1,1.05,2.64v2.18a3.8,3.8,0,0,1-1.07,2.65,3.29,3.29,0,0,1-2.47,1.15,3.1,3.1,0,0,1-1.66-.46,2.65,2.65,0,0,1-1.08-1.1v.89a.49.49,0,0,1-.18.37.63.63,0,0,1-.44.16.64.64,0,0,1-.45-.16A.47.47,0,0,1,415.75,443.78Zm1.3-2.53a1.92,1.92,0,0,0,.75,1.25,2.4,2.4,0,0,0,1.71.72,2.3,2.3,0,0,0,1.74-.8,2.56,2.56,0,0,0,.74-1.79v-2.16a2.47,2.47,0,0,0-.74-1.75,2.36,2.36,0,0,0-1.76-.78,2.46,2.46,0,0,0-2.44,2.53Z"/>
            <path className="cls-1" d="M424.37,435.34a.52.52,0,0,1,.26-.43.87.87,0,0,1,.54-.19.42.42,0,0,1,.46.28l2.74,7.34L431,435a.43.43,0,0,1,.44-.28.91.91,0,0,1,.53.19.55.55,0,0,1,.27.45.28.28,0,0,1,0,.16l-3.1,8.4-.36,1-.39.94a7.9,7.9,0,0,1-.49,1l-.52.8a3.16,3.16,0,0,1-.65.72,4.35,4.35,0,0,1-.75.41,2.24,2.24,0,0,1-.9.19.53.53,0,0,1-.6-.6c0-.41.13-.62.4-.62a2.18,2.18,0,0,0,1.71-1.11,8,8,0,0,0,1.19-2.74l-3.26-8.35A.38.38,0,0,1,424.37,435.34Z"/>
            <path className="cls-1" d="M437.71,441.36V430.84a.39.39,0,0,1,.2-.34.77.77,0,0,1,.44-.14.83.83,0,0,1,.46.14.42.42,0,0,1,.2.34v4h2.58a.41.41,0,0,1,.33.16.65.65,0,0,1,0,.78.41.41,0,0,1-.33.16H439v5.39a1.78,1.78,0,0,0,.41,1.36,2.08,2.08,0,0,0,1.45.39h.56a.58.58,0,0,1,.43.18.57.57,0,0,1,.17.42.61.61,0,0,1-.17.43.56.56,0,0,1-.43.19h-.56Q437.71,444.3,437.71,441.36Z"/>
            <path className="cls-1" d="M443.69,443.78V429.54a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.82.82,0,0,1,.47.14.43.43,0,0,1,.19.34v6.72a2.82,2.82,0,0,1,1-1.09,2.87,2.87,0,0,1,1.61-.45,3.34,3.34,0,0,1,2.49,1.1,3.68,3.68,0,0,1,1.05,2.64v5.32a.46.46,0,0,1-.19.38.82.82,0,0,1-.47.14.77.77,0,0,1-.44-.14.42.42,0,0,1-.2-.38v-5.31a2.49,2.49,0,0,0-.74-1.75,2.34,2.34,0,0,0-1.74-.78,2.32,2.32,0,0,0-1.67.7,2.4,2.4,0,0,0-.73,1.83v5.31a.48.48,0,0,1-.2.36.72.72,0,0,1-.46.16.64.64,0,0,1-.45-.16A.47.47,0,0,1,443.69,443.78Z"/>
            <path className="cls-1" d="M453.25,440.64V438.4a3.48,3.48,0,0,1,1.12-2.59,3.7,3.7,0,0,1,2.7-1.09,3.74,3.74,0,0,1,2.63,1,3.43,3.43,0,0,1,1.11,2.6,2.68,2.68,0,0,1-.13.9.84.84,0,0,1-.42.48,2.37,2.37,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0h-4.52v.74a2.32,2.32,0,0,0,.85,1.89,3.29,3.29,0,0,0,2.19.71,3.33,3.33,0,0,0,1.19-.2,3.47,3.47,0,0,0,.78-.4.84.84,0,0,1,.43-.2.51.51,0,0,1,.39.21.69.69,0,0,1,.17.41c0,.28-.29.57-.86.87a4.61,4.61,0,0,1-2.14.45,4.53,4.53,0,0,1-3.1-1.05A3.48,3.48,0,0,1,453.25,440.64Zm1.3-1.64h4a1.62,1.62,0,0,0,.8-.13c.13-.08.2-.27.2-.55a2.47,2.47,0,0,0-.71-1.74,2.34,2.34,0,0,0-1.79-.76,2.41,2.41,0,0,0-1.77.72,2.27,2.27,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M467,443.78v-13.6a.43.43,0,0,1,.18-.37.68.68,0,0,1,.42-.13h7.12c.33,0,.5.19.5.58a.85.85,0,0,1-.13.45.42.42,0,0,1-.37.19h-6.4v5.5h3.14a.45.45,0,0,1,.37.17.73.73,0,0,1,.13.43c0,.36-.17.54-.5.54h-3.14v5.54h6.4a.45.45,0,0,1,.36.18.72.72,0,0,1,.14.44c0,.4-.17.6-.5.6h-7.12a.68.68,0,0,1-.42-.14A.45.45,0,0,1,467,443.78Z"/>
            <path className="cls-1" d="M477.13,440.14v-10q0-.48.66-.48t.66.48v10a3.16,3.16,0,0,0,.75,2.29,3.33,3.33,0,0,0,4.26,0,3.16,3.16,0,0,0,.75-2.29v-10q0-.48.66-.48t.66.48v10a4.18,4.18,0,0,1-1.15,3.25,5,5,0,0,1-6.1,0A4.15,4.15,0,0,1,477.13,440.14Z"/>
            <path className="cls-1" d="M0,473.9c0-.45.21-.68.64-.68s.68.17.68.52a2.26,2.26,0,0,0,.77,1.8,3.06,3.06,0,0,0,2.07.66,2.77,2.77,0,0,0,2.1-.76,3.19,3.19,0,0,0,.74-2.3V463.9H4.18a.42.42,0,0,1-.37-.19.77.77,0,0,1-.13-.45.63.63,0,0,1,.13-.41.45.45,0,0,1,.37-.17H7.7c.41,0,.62.17.62.5v10a4.21,4.21,0,0,1-1.13,3.25,4.25,4.25,0,0,1-3,1,4.51,4.51,0,0,1-3.05-1A3.17,3.17,0,0,1,0,473.9Z"/>
            <path className="cls-1" d="M11.3,476.78V463.16c0-.29.17-.45.5-.48h3.54a5,5,0,0,1,3.13.92,3.59,3.59,0,0,1,1.19,3q0,3.31-3.12,3.86l3.08,5.92a.42.42,0,0,1,.08.26.62.62,0,0,1-.24.47.77.77,0,0,1-.52.21c-.24,0-.47-.23-.7-.7l-3.08-6H12.62v6.14a.44.44,0,0,1-.2.38.81.81,0,0,1-.46.14.79.79,0,0,1-.47-.14A.44.44,0,0,1,11.3,476.78Zm1.32-7.28h2.72q3,0,3-2.82c0-1.85-1-2.78-3-2.78H12.62Z"/>
            <path className="cls-1" d="M21.72,473.14v-6.2a4.09,4.09,0,0,1,1.17-3.22,4.41,4.41,0,0,1,3-1,4.39,4.39,0,0,1,3,1A3,3,0,0,1,30,466a1,1,0,0,1-.15.64.66.66,0,0,1-.51.16c-.41,0-.63-.17-.66-.5a3.68,3.68,0,0,0-.08-.58,2.5,2.5,0,0,0-.24-.63,2.16,2.16,0,0,0-.47-.61,2.2,2.2,0,0,0-.81-.43,4,4,0,0,0-1.2-.17c-1.92,0-2.88,1-2.88,3v6.2q0,3.06,3,3.06a3.76,3.76,0,0,0,1.4-.23,1.63,1.63,0,0,0,.82-.67,3.44,3.44,0,0,0,.35-.75,5.4,5.4,0,0,0,.15-.81c0-.35.26-.52.66-.52a.66.66,0,0,1,.51.17A1,1,0,0,1,30,474a3.06,3.06,0,0,1-1.13,2.4,4.51,4.51,0,0,1-3.05,1,4.3,4.3,0,0,1-3-1A4.14,4.14,0,0,1,21.72,473.14Z"/>
            <path className="cls-1" d="M31.94,469.71a1,1,0,0,1,.25-.72.82.82,0,0,1,.64-.29.8.8,0,0,1,.61.3,1.07,1.07,0,0,1,.26.71,1,1,0,0,1-.26.69.8.8,0,0,1-.61.3.82.82,0,0,1-.64-.29A1,1,0,0,1,31.94,469.71Zm0,6.72a1,1,0,0,1,.25-.72.82.82,0,0,1,.64-.29.8.8,0,0,1,.61.3,1.07,1.07,0,0,1,.26.71,1,1,0,0,1-.26.69.8.8,0,0,1-.61.3.82.82,0,0,1-.64-.29A1,1,0,0,1,31.94,476.43Z"/>
            <path className="cls-1" d="M39.46,475.1a1.26,1.26,0,0,1,.28-.76.81.81,0,0,1,.64-.38.86.86,0,0,1,.53.26,7.76,7.76,0,0,1,.61.55,2.79,2.79,0,0,0,.91.55,3.52,3.52,0,0,0,1.37.26,2.89,2.89,0,0,0,1.83-.54,1.9,1.9,0,0,0,.69-1.59,2.12,2.12,0,0,0-.67-1.61,5.16,5.16,0,0,0-1.62-1c-.63-.25-1.27-.52-1.91-.82a4.25,4.25,0,0,1-1.63-1.33,3.52,3.52,0,0,1-.67-2.21,3.89,3.89,0,0,1,.41-1.83,2.93,2.93,0,0,1,1.11-1.2,5.52,5.52,0,0,1,1.47-.61,6.62,6.62,0,0,1,1.63-.19,8.9,8.9,0,0,1,1.13.07,8.06,8.06,0,0,1,1.17.23,2.52,2.52,0,0,1,1,.45.86.86,0,0,1,.38.67,1.41,1.41,0,0,1-.24.76.71.71,0,0,1-.64.38,1.2,1.2,0,0,1-.54-.19,6.61,6.61,0,0,0-.91-.38,4.06,4.06,0,0,0-1.31-.19,3.25,3.25,0,0,0-1.82.45,1.55,1.55,0,0,0-.68,1.38,1.59,1.59,0,0,0,.36,1,2.94,2.94,0,0,0,.93.71c.38.19.8.37,1.27.55a11.53,11.53,0,0,1,1.39.64,7.42,7.42,0,0,1,1.26.83,3.48,3.48,0,0,1,.93,1.3,4.72,4.72,0,0,1,.36,1.91,4,4,0,0,1-1.25,3.15,4.89,4.89,0,0,1-3.31,1.08,6,6,0,0,1-3.13-.77Q39.46,475.93,39.46,475.1Z"/>
            <path className="cls-1" d="M50.22,476.6v-8.1a.58.58,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.14,1.14,0,0,1,.66.19.58.58,0,0,1,.28.5v.73a2.84,2.84,0,0,1,2.66-1.54,2.38,2.38,0,0,1,1.57.56,3.44,3.44,0,0,1,1,1.46,3.75,3.75,0,0,1,1.31-1.51,3.16,3.16,0,0,1,1.71-.51A3.3,3.3,0,0,1,63,468.77a3.94,3.94,0,0,1,1,2.87v5a.62.62,0,0,1-.32.48,1.24,1.24,0,0,1-.74.22,1.2,1.2,0,0,1-.72-.22.61.61,0,0,1-.32-.48v-5a2.07,2.07,0,0,0-.56-1.53,1.8,1.8,0,0,0-1.3-.56,1.84,1.84,0,0,0-1.31.56,2,2,0,0,0-.57,1.49v5a.54.54,0,0,1-.31.51,1.6,1.6,0,0,1-.75.17,1.53,1.53,0,0,1-.72-.17.55.55,0,0,1-.32-.51v-5a2,2,0,0,0-.55-1.47,1.73,1.73,0,0,0-1.31-.58,1.79,1.79,0,0,0-1.31.56,2,2,0,0,0-.55,1.51v5a.61.61,0,0,1-.32.48,1.24,1.24,0,0,1-.74.22,1.2,1.2,0,0,1-.74-.22A.62.62,0,0,1,50.22,476.6Z"/>
            <path className="cls-1" d="M65.5,474.82a2.3,2.3,0,0,1,1.2-2.23,7.66,7.66,0,0,1,3.48-.63h.88v-.37a2.47,2.47,0,0,0-.49-1.55,1.74,1.74,0,0,0-1.49-.66,3.29,3.29,0,0,0-1.51.33,4.76,4.76,0,0,1-.71.33.63.63,0,0,1-.55-.33,1.32,1.32,0,0,1-.21-.73c0-.35.32-.65,1-.9a5.65,5.65,0,0,1,2.05-.38,3.89,3.89,0,0,1,3,1.11,4,4,0,0,1,1,2.77v5a.6.6,0,0,1-.29.49,1.21,1.21,0,0,1-.73.21,1.1,1.1,0,0,1-.68-.21.59.59,0,0,1-.28-.49v-.7a3.92,3.92,0,0,1-3,1.56,2.76,2.76,0,0,1-1.91-.68A2.51,2.51,0,0,1,65.5,474.82Zm2.12-.26a1.25,1.25,0,0,0,.36,1,1.33,1.33,0,0,0,.94.33,2.1,2.1,0,0,0,1.45-.64,1.86,1.86,0,0,0,.69-1.36v-.74h-.5a11.54,11.54,0,0,0-1.47.08,2.1,2.1,0,0,0-1,.42A1.12,1.12,0,0,0,67.62,474.56Z"/>
            <path className="cls-1" d="M75.3,476.6v-8.1a.58.58,0,0,1,.29-.49,1.28,1.28,0,0,1,.73-.19A1.16,1.16,0,0,1,77,468a.58.58,0,0,1,.28.5v.89a3.19,3.19,0,0,1,1-1.2,2.53,2.53,0,0,1,1.51-.5h.64A.73.73,0,0,1,81,468a1,1,0,0,1,.24.66,1,1,0,0,1-.24.67.71.71,0,0,1-.56.28h-.64a2.2,2.2,0,0,0-1.69.82,3,3,0,0,0-.73,2.11v4.09a.61.61,0,0,1-.32.48,1.28,1.28,0,0,1-.76.22,1.15,1.15,0,0,1-.73-.22A.63.63,0,0,1,75.3,476.6Z"/>
            <path className="cls-1" d="M82.48,474.18v-10a.57.57,0,0,1,.32-.48,1.34,1.34,0,0,1,.72-.2,1.4,1.4,0,0,1,.73.2.56.56,0,0,1,.33.48v3.66H87a.51.51,0,0,1,.42.23,1,1,0,0,1,0,1.1.51.51,0,0,1-.42.23H84.58v4.8a1.37,1.37,0,0,0,.31,1,1.56,1.56,0,0,0,1.09.3h.7a.87.87,0,0,1,.66.26,1,1,0,0,1,0,1.28.87.87,0,0,1-.66.26H86Q82.49,477.3,82.48,474.18Z"/>
            <path className="cls-1" d="M92.56,475.1a1.26,1.26,0,0,1,.28-.76.81.81,0,0,1,.64-.38.86.86,0,0,1,.53.26,7.76,7.76,0,0,1,.61.55,2.79,2.79,0,0,0,.91.55,3.52,3.52,0,0,0,1.37.26,2.89,2.89,0,0,0,1.83-.54,1.9,1.9,0,0,0,.69-1.59,2.12,2.12,0,0,0-.67-1.61,5.16,5.16,0,0,0-1.62-1c-.63-.25-1.27-.52-1.91-.82a4.25,4.25,0,0,1-1.63-1.33,3.52,3.52,0,0,1-.67-2.21,3.89,3.89,0,0,1,.41-1.83,2.93,2.93,0,0,1,1.11-1.2,5.52,5.52,0,0,1,1.47-.61,6.62,6.62,0,0,1,1.63-.19,8.9,8.9,0,0,1,1.13.07,8.06,8.06,0,0,1,1.17.23,2.52,2.52,0,0,1,1,.45.86.86,0,0,1,.38.67,1.41,1.41,0,0,1-.24.76.71.71,0,0,1-.64.38,1.2,1.2,0,0,1-.54-.19,6.61,6.61,0,0,0-.91-.38,4.06,4.06,0,0,0-1.31-.19,3.25,3.25,0,0,0-1.82.45,1.55,1.55,0,0,0-.68,1.38,1.59,1.59,0,0,0,.36,1,2.94,2.94,0,0,0,.93.71c.38.19.8.37,1.27.55a11.53,11.53,0,0,1,1.39.64,7.42,7.42,0,0,1,1.26.83,3.48,3.48,0,0,1,.93,1.3,4.72,4.72,0,0,1,.36,1.91,4,4,0,0,1-1.25,3.15A4.89,4.89,0,0,1,97,477.46a6,6,0,0,1-3.13-.77Q92.56,475.93,92.56,475.1Z"/>
            <path className="cls-1" d="M103.34,481.1V468.5a.58.58,0,0,1,.29-.49,1.23,1.23,0,0,1,.71-.19,1.2,1.2,0,0,1,.7.19.58.58,0,0,1,.28.5v.73A3,3,0,0,1,108,467.7a3.32,3.32,0,0,1,2.55,1.16,4,4,0,0,1,1,2.78v1.84a4.07,4.07,0,0,1-1.06,2.77,3.19,3.19,0,0,1-2.46,1.21,2.92,2.92,0,0,1-1.59-.47,2.77,2.77,0,0,1-1-1.11v5.22a.6.6,0,0,1-.32.49,1.35,1.35,0,0,1-.76.21,1.21,1.21,0,0,1-.73-.21A.6.6,0,0,1,103.34,481.1Zm2.1-7.17a1.63,1.63,0,0,0,.61,1,1.93,1.93,0,0,0,1.41.61,1.89,1.89,0,0,0,1.42-.65,2.06,2.06,0,0,0,.62-1.46v-1.82a2,2,0,0,0-.62-1.43,2,2,0,0,0-3.44,1.43Z"/>
            <path className="cls-1" d="M113.08,473.48v-1.9a3.65,3.65,0,0,1,1.22-2.73,4.32,4.32,0,0,1,5.75-.11,3.36,3.36,0,0,1,1.17,2.63,3.06,3.06,0,0,1-.14,1,.89.89,0,0,1-.44.52,1.81,1.81,0,0,1-.56.2,4.2,4.2,0,0,1-.68,0h-4.22v.46a2,2,0,0,0,.72,1.61,2.9,2.9,0,0,0,1.88.59,3.35,3.35,0,0,0,1.55-.38,3.25,3.25,0,0,1,.89-.39.7.7,0,0,1,.58.33,1,1,0,0,1,.24.62c0,.33-.32.66-1,1a5.12,5.12,0,0,1-2.36.5,5,5,0,0,1-3.34-1.1A3.57,3.57,0,0,1,113.08,473.48Zm2.1-1.7h3.32a1,1,0,0,0,.59-.12.57.57,0,0,0,.15-.47,1.77,1.77,0,0,0-.58-1.34,2,2,0,0,0-1.44-.55,2.11,2.11,0,0,0-1.44.52,1.69,1.69,0,0,0-.6,1.33Z"/>
            <path className="cls-1" d="M122.6,473.48v-1.8a4,4,0,0,1,1-2.88,4.08,4.08,0,0,1,3.1-1.1,4.76,4.76,0,0,1,2.18.45c.59.3.88.64.88,1a1.12,1.12,0,0,1-.25.68.71.71,0,0,1-.59.34,3.28,3.28,0,0,1-.71-.3,3,3,0,0,0-1.35-.3c-1.47,0-2.2.7-2.2,2.11v1.78c0,1.41.74,2.11,2.22,2.11a2.94,2.94,0,0,0,1.37-.32,3.09,3.09,0,0,1,.73-.32.8.8,0,0,1,.65.32,1.06,1.06,0,0,1,.25.68c0,.37-.31.72-.92,1a4.78,4.78,0,0,1-2.24.48,4.1,4.1,0,0,1-3.12-1.09A4,4,0,0,1,122.6,473.48Z"/>
            <path className="cls-1" d="M131.26,464.34a1,1,0,0,1,.36-.78,1.32,1.32,0,0,1,1.72,0,1,1,0,0,1,.36.78,1,1,0,0,1-.36.77,1.29,1.29,0,0,1-1.72,0A1,1,0,0,1,131.26,464.34Zm.18,12.26v-8.1a.58.58,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.36,1.36,0,0,1,.76.19.57.57,0,0,1,.3.49v8.1a.61.61,0,0,1-.31.48,1.25,1.25,0,0,1-.75.22,1.2,1.2,0,0,1-.74-.22A.62.62,0,0,1,131.44,476.6Z"/>
            <path className="cls-1" d="M135.14,474.82a2.3,2.3,0,0,1,1.2-2.23,7.66,7.66,0,0,1,3.48-.63h.88v-.37a2.47,2.47,0,0,0-.49-1.55,1.74,1.74,0,0,0-1.49-.66,3.29,3.29,0,0,0-1.51.33,4.76,4.76,0,0,1-.71.33.63.63,0,0,1-.55-.33,1.32,1.32,0,0,1-.21-.73c0-.35.32-.65,1-.9a5.65,5.65,0,0,1,2-.38,3.89,3.89,0,0,1,3.05,1.11,4,4,0,0,1,1,2.77v5a.6.6,0,0,1-.29.49,1.21,1.21,0,0,1-.73.21,1.1,1.1,0,0,1-.68-.21.59.59,0,0,1-.28-.49v-.7a3.92,3.92,0,0,1-3,1.56,2.76,2.76,0,0,1-1.91-.68A2.51,2.51,0,0,1,135.14,474.82Zm2.12-.26a1.25,1.25,0,0,0,.36,1,1.33,1.33,0,0,0,.94.33,2.1,2.1,0,0,0,1.45-.64,1.86,1.86,0,0,0,.69-1.36v-.74h-.5a11.54,11.54,0,0,0-1.47.08,2.1,2.1,0,0,0-1,.42A1.12,1.12,0,0,0,137.26,474.56Z"/>
            <path className="cls-1" d="M145.12,476.6V462.86a.58.58,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.39,1.39,0,0,1,.75.19.56.56,0,0,1,.31.49V476.6a.62.62,0,0,1-.32.48,1.24,1.24,0,0,1-.74.22,1.2,1.2,0,0,1-.74-.22A.62.62,0,0,1,145.12,476.6Z"/>
            <path className="cls-1" d="M149.42,464.34a1,1,0,0,1,.36-.78,1.32,1.32,0,0,1,1.72,0,1,1,0,0,1,.36.78,1,1,0,0,1-.36.77,1.29,1.29,0,0,1-1.72,0A1,1,0,0,1,149.42,464.34Zm.18,12.26v-8.1a.58.58,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.36,1.36,0,0,1,.76.19.57.57,0,0,1,.3.49v8.1a.61.61,0,0,1-.31.48,1.25,1.25,0,0,1-.75.22,1.2,1.2,0,0,1-.74-.22A.62.62,0,0,1,149.6,476.6Z"/>
            <path className="cls-1" d="M153.3,475.86a1.12,1.12,0,0,1,.21-.62.62.62,0,0,1,.52-.32,1,1,0,0,1,.5.23,4.23,4.23,0,0,0,.87.48,3.41,3.41,0,0,0,1.31.23c1.14,0,1.71-.36,1.71-1.09a1,1,0,0,0-.5-.91,4.21,4.21,0,0,0-1.21-.53c-.47-.12-.94-.27-1.41-.43a2.67,2.67,0,0,1-1.2-.82,2.27,2.27,0,0,1-.5-1.51,2.76,2.76,0,0,1,.84-2,3.47,3.47,0,0,1,2.58-.85,5.91,5.91,0,0,1,2.07.35c.62.23.93.51.93.83a1.31,1.31,0,0,1-.23.65.65.65,0,0,1-.57.37,4.08,4.08,0,0,1-.82-.3,3.4,3.4,0,0,0-1.34-.3,1.78,1.78,0,0,0-1.21.36,1.07,1.07,0,0,0-.41.81.86.86,0,0,0,.5.76,4.79,4.79,0,0,0,1.22.46,12.29,12.29,0,0,1,1.42.45,2.58,2.58,0,0,1,1.68,2.55,2.46,2.46,0,0,1-.92,2,4.07,4.07,0,0,1-2.6.74,5.45,5.45,0,0,1-2.43-.5C153.64,476.63,153.3,476.26,153.3,475.86Z"/>
            <path className="cls-1" d="M161.38,474.82a2.3,2.3,0,0,1,1.2-2.23,7.66,7.66,0,0,1,3.48-.63h.88v-.37a2.47,2.47,0,0,0-.49-1.55,1.74,1.74,0,0,0-1.49-.66,3.29,3.29,0,0,0-1.51.33,4.76,4.76,0,0,1-.71.33.63.63,0,0,1-.55-.33A1.32,1.32,0,0,1,162,469c0-.35.32-.65,1-.9a5.65,5.65,0,0,1,2.05-.38,3.89,3.89,0,0,1,3.05,1.11,4,4,0,0,1,1,2.77v5a.6.6,0,0,1-.29.49,1.21,1.21,0,0,1-.73.21,1.1,1.1,0,0,1-.68-.21.59.59,0,0,1-.28-.49v-.7a3.92,3.92,0,0,1-3,1.56,2.76,2.76,0,0,1-1.91-.68A2.51,2.51,0,0,1,161.38,474.82Zm2.12-.26a1.25,1.25,0,0,0,.36,1,1.33,1.33,0,0,0,.94.33,2.1,2.1,0,0,0,1.45-.64,1.86,1.86,0,0,0,.69-1.36v-.74h-.5a11.54,11.54,0,0,0-1.47.08,2.1,2.1,0,0,0-1,.42A1.12,1.12,0,0,0,163.5,474.56Z"/>
            <path className="cls-1" d="M171.16,474.18v-10a.57.57,0,0,1,.32-.48,1.34,1.34,0,0,1,.72-.2,1.4,1.4,0,0,1,.73.2.56.56,0,0,1,.33.48v3.66h2.44a.51.51,0,0,1,.42.23,1,1,0,0,1,0,1.1.51.51,0,0,1-.42.23h-2.44v4.8a1.37,1.37,0,0,0,.31,1,1.56,1.56,0,0,0,1.09.3h.7a.87.87,0,0,1,.66.26,1,1,0,0,1,0,1.28.87.87,0,0,1-.66.26h-.7Q171.17,477.3,171.16,474.18Z"/>
            <path className="cls-1" d="M177.58,464.34a1,1,0,0,1,.36-.78,1.32,1.32,0,0,1,1.72,0,1,1,0,0,1,.36.78,1,1,0,0,1-.36.77,1.29,1.29,0,0,1-1.72,0A1,1,0,0,1,177.58,464.34Zm.18,12.26v-8.1a.58.58,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.36,1.36,0,0,1,.76.19.57.57,0,0,1,.3.49v8.1a.61.61,0,0,1-.31.48,1.25,1.25,0,0,1-.75.22,1.2,1.2,0,0,1-.74-.22A.62.62,0,0,1,177.76,476.6Z"/>
            <path className="cls-1" d="M181.68,473.48v-1.84a3.74,3.74,0,0,1,1.22-2.76,4,4,0,0,1,2.92-1.18,4.09,4.09,0,0,1,2.9,1.17,3.67,3.67,0,0,1,1.24,2.77v1.84a3.77,3.77,0,0,1-1.25,2.77,4.08,4.08,0,0,1-5.79,0A3.75,3.75,0,0,1,181.68,473.48Zm2.1,0a2.08,2.08,0,0,0,.6,1.46,1.91,1.91,0,0,0,2.87,0,2,2,0,0,0,.61-1.46v-1.82a2,2,0,0,0-.61-1.43,1.93,1.93,0,0,0-2.87,0,2,2,0,0,0-.6,1.43Z"/>
            <path className="cls-1" d="M191.8,476.6v-8.1a.58.58,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.11,1.11,0,0,1,.67.19.6.6,0,0,1,.27.5v.73a2.7,2.7,0,0,1,1-1.1,3.08,3.08,0,0,1,1.65-.44,3.18,3.18,0,0,1,2.45,1.16,4,4,0,0,1,1,2.78v5a.58.58,0,0,1-.31.51,1.39,1.39,0,0,1-.75.19,1.34,1.34,0,0,1-.72-.19.59.59,0,0,1-.32-.51v-5a2,2,0,0,0-.6-1.43,1.88,1.88,0,0,0-2.76-.07,2,2,0,0,0-.58,1.5v5a.61.61,0,0,1-.32.48,1.24,1.24,0,0,1-.74.22,1.2,1.2,0,0,1-.74-.22A.62.62,0,0,1,191.8,476.6Z"/>
            <path className="cls-1" d="M205.54,475.1a1.26,1.26,0,0,1,.28-.76.81.81,0,0,1,.64-.38.86.86,0,0,1,.53.26,7.76,7.76,0,0,1,.61.55,2.79,2.79,0,0,0,.91.55,3.52,3.52,0,0,0,1.37.26,2.89,2.89,0,0,0,1.83-.54,1.9,1.9,0,0,0,.69-1.59,2.12,2.12,0,0,0-.67-1.61,5.16,5.16,0,0,0-1.62-1c-.63-.25-1.27-.52-1.91-.82a4.25,4.25,0,0,1-1.63-1.33,3.52,3.52,0,0,1-.67-2.21,3.89,3.89,0,0,1,.41-1.83,2.93,2.93,0,0,1,1.11-1.2,5.52,5.52,0,0,1,1.47-.61,6.62,6.62,0,0,1,1.63-.19,8.9,8.9,0,0,1,1.13.07,8.06,8.06,0,0,1,1.17.23,2.52,2.52,0,0,1,1,.45.86.86,0,0,1,.38.67,1.41,1.41,0,0,1-.24.76.71.71,0,0,1-.64.38,1.2,1.2,0,0,1-.54-.19,6.61,6.61,0,0,0-.91-.38,4.06,4.06,0,0,0-1.31-.19,3.25,3.25,0,0,0-1.82.45,1.55,1.55,0,0,0-.68,1.38,1.59,1.59,0,0,0,.36,1,2.94,2.94,0,0,0,.93.71c.38.19.8.37,1.27.55a11.53,11.53,0,0,1,1.39.64,7.42,7.42,0,0,1,1.26.83,3.48,3.48,0,0,1,.93,1.3,4.72,4.72,0,0,1,.36,1.91,4,4,0,0,1-1.25,3.15,4.89,4.89,0,0,1-3.31,1.08,6,6,0,0,1-3.13-.77Q205.54,475.93,205.54,475.1Z"/>
            <path className="cls-1" d="M216.28,474.18v-10a.57.57,0,0,1,.32-.48,1.34,1.34,0,0,1,.72-.2,1.4,1.4,0,0,1,.73.2.56.56,0,0,1,.33.48v3.66h2.44a.51.51,0,0,1,.42.23,1,1,0,0,1,0,1.1.51.51,0,0,1-.42.23h-2.44v4.8a1.37,1.37,0,0,0,.31,1,1.56,1.56,0,0,0,1.09.3h.7a.87.87,0,0,1,.66.26,1,1,0,0,1,0,1.28.87.87,0,0,1-.66.26h-.7Q216.28,477.3,216.28,474.18Z"/>
            <path className="cls-1" d="M222.88,476.6v-8.1a.58.58,0,0,1,.29-.49,1.28,1.28,0,0,1,.73-.19,1.16,1.16,0,0,1,.68.19.58.58,0,0,1,.28.5v.89a3.19,3.19,0,0,1,1-1.2,2.53,2.53,0,0,1,1.51-.5H228a.73.73,0,0,1,.56.27,1,1,0,0,1,.24.66,1,1,0,0,1-.24.67.71.71,0,0,1-.56.28h-.64a2.2,2.2,0,0,0-1.69.82,3,3,0,0,0-.73,2.11v4.09a.61.61,0,0,1-.32.48,1.28,1.28,0,0,1-.76.22,1.15,1.15,0,0,1-.73-.22A.63.63,0,0,1,222.88,476.6Z"/>
            <path className="cls-1" d="M229.48,474.82a2.3,2.3,0,0,1,1.2-2.23,7.66,7.66,0,0,1,3.48-.63H235v-.37a2.47,2.47,0,0,0-.49-1.55,1.74,1.74,0,0,0-1.49-.66,3.29,3.29,0,0,0-1.51.33,4.76,4.76,0,0,1-.71.33.63.63,0,0,1-.55-.33,1.32,1.32,0,0,1-.21-.73c0-.35.32-.65,1-.9a5.65,5.65,0,0,1,2-.38,3.89,3.89,0,0,1,3.05,1.11,4,4,0,0,1,1,2.77v5a.6.6,0,0,1-.29.49,1.21,1.21,0,0,1-.73.21,1.1,1.1,0,0,1-.68-.21.59.59,0,0,1-.28-.49v-.7a3.92,3.92,0,0,1-3,1.56,2.76,2.76,0,0,1-1.91-.68A2.51,2.51,0,0,1,229.48,474.82Zm2.12-.26a1.25,1.25,0,0,0,.36,1,1.33,1.33,0,0,0,.94.33,2.1,2.1,0,0,0,1.45-.64,1.86,1.86,0,0,0,.69-1.36v-.74h-.5a11.54,11.54,0,0,0-1.47.08,2.1,2.1,0,0,0-1,.42A1.12,1.12,0,0,0,231.6,474.56Z"/>
            <path className="cls-1" d="M239.26,474.18v-10a.57.57,0,0,1,.32-.48,1.34,1.34,0,0,1,.72-.2,1.4,1.4,0,0,1,.73.2.56.56,0,0,1,.33.48v3.66h2.44a.51.51,0,0,1,.42.23,1,1,0,0,1,0,1.1.51.51,0,0,1-.42.23h-2.44v4.8a1.37,1.37,0,0,0,.31,1,1.56,1.56,0,0,0,1.09.3h.7a.87.87,0,0,1,.66.26,1,1,0,0,1,0,1.28.87.87,0,0,1-.66.26h-.7Q239.27,477.3,239.26,474.18Z"/>
            <path className="cls-1" d="M245.36,473.48v-1.9a3.65,3.65,0,0,1,1.22-2.73,4.32,4.32,0,0,1,5.75-.11,3.36,3.36,0,0,1,1.17,2.63,3.06,3.06,0,0,1-.14,1,.89.89,0,0,1-.44.52,1.81,1.81,0,0,1-.56.2,4.2,4.2,0,0,1-.68,0h-4.22v.46a2,2,0,0,0,.72,1.61,2.9,2.9,0,0,0,1.88.59,3.35,3.35,0,0,0,1.55-.38,3.25,3.25,0,0,1,.89-.39.7.7,0,0,1,.58.33,1,1,0,0,1,.24.62c0,.33-.32.66-1,1a5.12,5.12,0,0,1-2.36.5,5,5,0,0,1-3.34-1.1A3.57,3.57,0,0,1,245.36,473.48Zm2.1-1.7h3.32a1,1,0,0,0,.59-.12.57.57,0,0,0,.15-.47,1.77,1.77,0,0,0-.58-1.34,2,2,0,0,0-1.44-.55,2.11,2.11,0,0,0-1.44.52,1.69,1.69,0,0,0-.6,1.33Z"/>
            <path className="cls-1" d="M254.48,479.09a2.35,2.35,0,0,1,1.57-2.17,1.37,1.37,0,0,1-.89-1.33,1.84,1.84,0,0,1,.94-1.53,3,3,0,0,1-1.2-2.52V471a3,3,0,0,1,1-2.36,3.9,3.9,0,0,1,2.69-.92,3.82,3.82,0,0,1,2.32.68c.39-.89.82-1.34,1.3-1.34a.88.88,0,0,1,.62.23.68.68,0,0,1,.24.49.55.55,0,0,1-.34.5,2.29,2.29,0,0,0-1,.8,3,3,0,0,1,.64,1.92v.56a3,3,0,0,1-1.06,2.38,3.93,3.93,0,0,1-2.72.94,3.7,3.7,0,0,1-1.38-.24.71.71,0,0,0-.38.56c0,.27.21.47.63.59a8.83,8.83,0,0,0,1.52.28c.59.07,1.19.17,1.78.3a3,3,0,0,1,1.52.82,2.2,2.2,0,0,1,.63,1.65,2.47,2.47,0,0,1-1.19,2.23,5.55,5.55,0,0,1-3,.75,5.8,5.8,0,0,1-3-.74A2.23,2.23,0,0,1,254.48,479.09Zm2-.23a1.35,1.35,0,0,0,.63,1.17,2.88,2.88,0,0,0,1.63.43,2.71,2.71,0,0,0,1.58-.42,1.36,1.36,0,0,0,.6-1.16,1.12,1.12,0,0,0-.28-.78,1.74,1.74,0,0,0-.83-.46,6.7,6.7,0,0,0-.94-.2,10.33,10.33,0,0,0-1.05-.09C256.91,477.68,256.46,478.19,256.46,478.86Zm.48-7.34a2.16,2.16,0,0,0,.46,1.44,1.56,1.56,0,0,0,1.26.56,1.53,1.53,0,0,0,1.24-.55,2.14,2.14,0,0,0,.46-1.43V471a2.08,2.08,0,0,0-.46-1.39,1.55,1.55,0,0,0-1.26-.55,1.53,1.53,0,0,0-1.24.55,2.08,2.08,0,0,0-.46,1.39Z"/>
            <path className="cls-1" d="M264.28,464.34a1,1,0,0,1,.36-.78,1.32,1.32,0,0,1,1.72,0,1,1,0,0,1,.36.78,1,1,0,0,1-.36.77,1.29,1.29,0,0,1-1.72,0A1,1,0,0,1,264.28,464.34Zm.18,12.26v-8.1a.58.58,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.36,1.36,0,0,1,.76.19.57.57,0,0,1,.3.49v8.1a.61.61,0,0,1-.31.48,1.25,1.25,0,0,1-.75.22,1.2,1.2,0,0,1-.74-.22A.62.62,0,0,1,264.46,476.6Z"/>
            <path className="cls-1" d="M268.4,473.48v-1.9a3.65,3.65,0,0,1,1.22-2.73,4.32,4.32,0,0,1,5.75-.11,3.36,3.36,0,0,1,1.17,2.63,3.06,3.06,0,0,1-.14,1,.89.89,0,0,1-.44.52,1.81,1.81,0,0,1-.56.2,4.2,4.2,0,0,1-.68,0H270.5v.46a2,2,0,0,0,.72,1.61,2.9,2.9,0,0,0,1.88.59,3.35,3.35,0,0,0,1.55-.38,3.25,3.25,0,0,1,.89-.39.7.7,0,0,1,.58.33,1,1,0,0,1,.24.62c0,.33-.32.66-1,1a5.12,5.12,0,0,1-2.36.5,5,5,0,0,1-3.34-1.1A3.57,3.57,0,0,1,268.4,473.48Zm2.1-1.7h3.32a1,1,0,0,0,.59-.12.57.57,0,0,0,.15-.47,1.77,1.77,0,0,0-.58-1.34,2,2,0,0,0-1.44-.55,2.11,2.11,0,0,0-1.44.52,1.69,1.69,0,0,0-.6,1.33Z"/>
            <path className="cls-1" d="M277.64,475.86a1.12,1.12,0,0,1,.21-.62.62.62,0,0,1,.52-.32,1,1,0,0,1,.5.23,4.23,4.23,0,0,0,.87.48,3.41,3.41,0,0,0,1.31.23c1.14,0,1.71-.36,1.71-1.09a1,1,0,0,0-.5-.91,4.21,4.21,0,0,0-1.21-.53c-.47-.12-.94-.27-1.41-.43a2.67,2.67,0,0,1-1.2-.82,2.27,2.27,0,0,1-.5-1.51,2.76,2.76,0,0,1,.84-2,3.47,3.47,0,0,1,2.58-.85,5.91,5.91,0,0,1,2.07.35c.62.23.93.51.93.83a1.31,1.31,0,0,1-.23.65.65.65,0,0,1-.57.37,4.08,4.08,0,0,1-.82-.3,3.4,3.4,0,0,0-1.34-.3,1.78,1.78,0,0,0-1.21.36,1.07,1.07,0,0,0-.41.81.86.86,0,0,0,.5.76,4.79,4.79,0,0,0,1.22.46,12.29,12.29,0,0,1,1.42.45,2.58,2.58,0,0,1,1.68,2.55,2.46,2.46,0,0,1-.92,2,4.07,4.07,0,0,1-2.6.74,5.45,5.45,0,0,1-2.43-.5C278,476.63,277.64,476.26,277.64,475.86Z"/>
            <path className="cls-1" d="M290.34,476.62V466a3.83,3.83,0,0,1,1-2.71,3.3,3.3,0,0,1,2.5-1.11h.92a.74.74,0,0,1,.6.27,1,1,0,0,1,0,1.26.74.74,0,0,1-.6.27h-.52a1.79,1.79,0,0,0-1.32.52,1.76,1.76,0,0,0-.52,1.3v2H295a.51.51,0,0,1,.42.23,1,1,0,0,1,0,1.1.51.51,0,0,1-.42.23h-2.6v7.24a.57.57,0,0,1-.32.49,1.43,1.43,0,0,1-.76.19,1.28,1.28,0,0,1-.73-.19A.58.58,0,0,1,290.34,476.62Z"/>
            <path className="cls-1" d="M296.24,473.48v-1.84a3.74,3.74,0,0,1,1.22-2.76,4,4,0,0,1,2.92-1.18,4.09,4.09,0,0,1,2.9,1.17,3.67,3.67,0,0,1,1.24,2.77v1.84a3.77,3.77,0,0,1-1.25,2.77,4.08,4.08,0,0,1-5.79,0A3.75,3.75,0,0,1,296.24,473.48Zm2.1,0a2.08,2.08,0,0,0,.6,1.46,1.91,1.91,0,0,0,2.87,0,2,2,0,0,0,.61-1.46v-1.82a2,2,0,0,0-.61-1.43,1.93,1.93,0,0,0-2.87,0,2,2,0,0,0-.6,1.43Z"/>
            <path className="cls-1" d="M306.36,476.6v-8.1a.58.58,0,0,1,.29-.49,1.28,1.28,0,0,1,.73-.19,1.16,1.16,0,0,1,.68.19.58.58,0,0,1,.28.5v.89a3.19,3.19,0,0,1,1-1.2,2.53,2.53,0,0,1,1.51-.5h.64a.73.73,0,0,1,.56.27,1,1,0,0,1,.24.66,1,1,0,0,1-.24.67.71.71,0,0,1-.56.28h-.64a2.2,2.2,0,0,0-1.69.82,3,3,0,0,0-.73,2.11v4.09a.61.61,0,0,1-.32.48,1.28,1.28,0,0,1-.76.22,1.15,1.15,0,0,1-.73-.22A.63.63,0,0,1,306.36,476.6Z"/>
            <path className="cls-1" d="M317,475.1a1.26,1.26,0,0,1,.28-.76A.81.81,0,0,1,318,474a.86.86,0,0,1,.53.26,7.76,7.76,0,0,1,.61.55,2.79,2.79,0,0,0,.91.55,3.52,3.52,0,0,0,1.37.26,2.89,2.89,0,0,0,1.83-.54,1.9,1.9,0,0,0,.69-1.59,2.12,2.12,0,0,0-.67-1.61,5.16,5.16,0,0,0-1.62-1c-.63-.25-1.27-.52-1.91-.82a4.25,4.25,0,0,1-1.63-1.33,3.52,3.52,0,0,1-.67-2.21,3.89,3.89,0,0,1,.41-1.83,2.93,2.93,0,0,1,1.11-1.2,5.52,5.52,0,0,1,1.47-.61,6.62,6.62,0,0,1,1.63-.19,8.9,8.9,0,0,1,1.13.07,8.06,8.06,0,0,1,1.17.23,2.52,2.52,0,0,1,1,.45.86.86,0,0,1,.38.67,1.41,1.41,0,0,1-.24.76.71.71,0,0,1-.64.38,1.2,1.2,0,0,1-.54-.19,6.61,6.61,0,0,0-.91-.38,4.06,4.06,0,0,0-1.31-.19,3.25,3.25,0,0,0-1.82.45,1.55,1.55,0,0,0-.68,1.38,1.59,1.59,0,0,0,.36,1,2.94,2.94,0,0,0,.93.71c.38.19.8.37,1.27.55a11.53,11.53,0,0,1,1.39.64,7.42,7.42,0,0,1,1.26.83,3.48,3.48,0,0,1,.93,1.3,4.72,4.72,0,0,1,.36,1.91,4,4,0,0,1-1.25,3.15,4.89,4.89,0,0,1-3.31,1.08,6,6,0,0,1-3.13-.77Q317,475.93,317,475.1Z"/>
            <path className="cls-1" d="M327.72,473.52v-5A.6.6,0,0,1,328,468a1.31,1.31,0,0,1,.74-.21,1.28,1.28,0,0,1,.73.21.58.58,0,0,1,.31.49v5a2,2,0,0,0,.61,1.44,1.94,1.94,0,0,0,2.87,0,2,2,0,0,0,.6-1.45v-5a.56.56,0,0,1,.31-.48,1.4,1.4,0,0,1,.75-.2,1.28,1.28,0,0,1,.73.2.58.58,0,0,1,.29.48v5a3.74,3.74,0,0,1-1.22,2.76,4.17,4.17,0,0,1-5.81,0A3.72,3.72,0,0,1,327.72,473.52Z"/>
            <path className="cls-1" d="M337.5,475.86a1.12,1.12,0,0,1,.21-.62.62.62,0,0,1,.52-.32,1,1,0,0,1,.5.23,4.23,4.23,0,0,0,.87.48,3.41,3.41,0,0,0,1.31.23c1.14,0,1.71-.36,1.71-1.09a1,1,0,0,0-.5-.91,4.21,4.21,0,0,0-1.21-.53c-.47-.12-.94-.27-1.41-.43a2.67,2.67,0,0,1-1.2-.82,2.27,2.27,0,0,1-.5-1.51,2.76,2.76,0,0,1,.84-2,3.47,3.47,0,0,1,2.58-.85,5.91,5.91,0,0,1,2.07.35c.62.23.93.51.93.83a1.31,1.31,0,0,1-.23.65.65.65,0,0,1-.57.37,4.08,4.08,0,0,1-.82-.3,3.4,3.4,0,0,0-1.34-.3,1.78,1.78,0,0,0-1.21.36,1.07,1.07,0,0,0-.41.81.86.86,0,0,0,.5.76,4.79,4.79,0,0,0,1.22.46,12.29,12.29,0,0,1,1.42.45,2.58,2.58,0,0,1,1.68,2.55,2.46,2.46,0,0,1-.92,2,4.07,4.07,0,0,1-2.6.74,5.45,5.45,0,0,1-2.43-.5C337.84,476.63,337.5,476.26,337.5,475.86Z"/>
            <path className="cls-1" d="M346.16,474.18v-10a.57.57,0,0,1,.32-.48,1.34,1.34,0,0,1,.72-.2,1.4,1.4,0,0,1,.73.2.56.56,0,0,1,.33.48v3.66h2.44a.51.51,0,0,1,.42.23,1,1,0,0,1,0,1.1.51.51,0,0,1-.42.23h-2.44v4.8a1.37,1.37,0,0,0,.31,1,1.56,1.56,0,0,0,1.09.3h.7a.87.87,0,0,1,.66.26,1,1,0,0,1,0,1.28.87.87,0,0,1-.66.26h-.7Q346.17,477.3,346.16,474.18Z"/>
            <path className="cls-1" d="M352.16,474.82a2.3,2.3,0,0,1,1.2-2.23,7.66,7.66,0,0,1,3.48-.63h.88v-.37a2.47,2.47,0,0,0-.49-1.55,1.74,1.74,0,0,0-1.49-.66,3.29,3.29,0,0,0-1.51.33,4.76,4.76,0,0,1-.71.33.63.63,0,0,1-.55-.33,1.32,1.32,0,0,1-.21-.73c0-.35.32-.65,1-.9a5.65,5.65,0,0,1,2-.38,3.89,3.89,0,0,1,3.05,1.11,4,4,0,0,1,1,2.77v5a.6.6,0,0,1-.29.49,1.21,1.21,0,0,1-.73.21,1.1,1.1,0,0,1-.68-.21.59.59,0,0,1-.28-.49v-.7a3.92,3.92,0,0,1-3,1.56,2.76,2.76,0,0,1-1.91-.68A2.51,2.51,0,0,1,352.16,474.82Zm2.12-.26a1.25,1.25,0,0,0,.36,1,1.33,1.33,0,0,0,.94.33,2.1,2.1,0,0,0,1.45-.64,1.86,1.86,0,0,0,.69-1.36v-.74h-.5a11.54,11.54,0,0,0-1.47.08,2.1,2.1,0,0,0-1,.42A1.12,1.12,0,0,0,354.28,474.56Z"/>
            <path className="cls-1" d="M361.78,464.34a1,1,0,0,1,.36-.78,1.32,1.32,0,0,1,1.72,0,1,1,0,0,1,.36.78,1,1,0,0,1-.36.77,1.29,1.29,0,0,1-1.72,0A1,1,0,0,1,361.78,464.34ZM362,476.6v-8.1a.58.58,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.36,1.36,0,0,1,.76.19.57.57,0,0,1,.3.49v8.1a.61.61,0,0,1-.31.48,1.25,1.25,0,0,1-.75.22,1.2,1.2,0,0,1-.74-.22A.62.62,0,0,1,362,476.6Z"/>
            <path className="cls-1" d="M366.26,476.6v-8.1a.58.58,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.11,1.11,0,0,1,.67.19.6.6,0,0,1,.27.5v.73a2.7,2.7,0,0,1,1-1.1,3.08,3.08,0,0,1,1.65-.44,3.18,3.18,0,0,1,2.45,1.16,4,4,0,0,1,1,2.78v5a.58.58,0,0,1-.31.51,1.39,1.39,0,0,1-.75.19,1.34,1.34,0,0,1-.72-.19.59.59,0,0,1-.32-.51v-5a2,2,0,0,0-.6-1.43,1.88,1.88,0,0,0-2.76-.07,2,2,0,0,0-.58,1.5v5a.61.61,0,0,1-.32.48,1.24,1.24,0,0,1-.74.22,1.2,1.2,0,0,1-.74-.22A.62.62,0,0,1,366.26,476.6Z"/>
            <path className="cls-1" d="M375.92,474.82a2.3,2.3,0,0,1,1.2-2.23,7.66,7.66,0,0,1,3.48-.63h.88v-.37A2.47,2.47,0,0,0,381,470a1.74,1.74,0,0,0-1.49-.66,3.29,3.29,0,0,0-1.51.33,4.76,4.76,0,0,1-.71.33.63.63,0,0,1-.55-.33,1.32,1.32,0,0,1-.21-.73c0-.35.32-.65,1-.9a5.65,5.65,0,0,1,2.05-.38,3.89,3.89,0,0,1,3,1.11,4,4,0,0,1,1,2.77v5a.6.6,0,0,1-.29.49,1.21,1.21,0,0,1-.73.21,1.1,1.1,0,0,1-.68-.21.59.59,0,0,1-.28-.49v-.7a3.92,3.92,0,0,1-3,1.56,2.76,2.76,0,0,1-1.91-.68A2.51,2.51,0,0,1,375.92,474.82Zm2.12-.26a1.25,1.25,0,0,0,.36,1,1.33,1.33,0,0,0,.94.33,2.1,2.1,0,0,0,1.45-.64,1.86,1.86,0,0,0,.69-1.36v-.74H381a11.54,11.54,0,0,0-1.47.08,2.1,2.1,0,0,0-1,.42A1.12,1.12,0,0,0,378,474.56Z"/>
            <path className="cls-1" d="M385.74,476.6V462.88a.57.57,0,0,1,.28-.49,1.27,1.27,0,0,1,.74-.19,1.42,1.42,0,0,1,.77.19.56.56,0,0,1,.31.49v6.36a3,3,0,0,1,1-1.1,2.82,2.82,0,0,1,1.56-.44,3.3,3.3,0,0,1,2.53,1.16,4,4,0,0,1,1.05,2.78v1.84a4.07,4.07,0,0,1-1.06,2.77,3.15,3.15,0,0,1-4.12.75,2.94,2.94,0,0,1-1.1-1.12v.71a.63.63,0,0,1-.29.49,1.06,1.06,0,0,1-.67.22,1.15,1.15,0,0,1-.73-.22A.63.63,0,0,1,385.74,476.6Zm2.1-2.67a1.63,1.63,0,0,0,.63,1,2,2,0,0,0,1.41.61,1.84,1.84,0,0,0,1.42-.65,2.08,2.08,0,0,0,.6-1.46v-1.82a2,2,0,0,0-.61-1.43,1.9,1.9,0,0,0-1.43-.64,2,2,0,0,0-1.4.58,2,2,0,0,0-.62,1.49Z"/>
            <path className="cls-1" d="M395.66,464.34a1,1,0,0,1,.36-.78,1.32,1.32,0,0,1,1.72,0,1,1,0,0,1,.36.78,1,1,0,0,1-.36.77,1.29,1.29,0,0,1-1.72,0A1,1,0,0,1,395.66,464.34Zm.18,12.26v-8.1a.58.58,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.36,1.36,0,0,1,.76.19.57.57,0,0,1,.3.49v8.1a.61.61,0,0,1-.31.48,1.25,1.25,0,0,1-.75.22,1.2,1.2,0,0,1-.74-.22A.62.62,0,0,1,395.84,476.6Z"/>
            <path className="cls-1" d="M400.32,476.6V462.86a.58.58,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.39,1.39,0,0,1,.75.19.56.56,0,0,1,.31.49V476.6a.62.62,0,0,1-.32.48,1.24,1.24,0,0,1-.74.22,1.2,1.2,0,0,1-.74-.22A.62.62,0,0,1,400.32,476.6Z"/>
            <path className="cls-1" d="M404.62,464.34a1,1,0,0,1,.36-.78,1.32,1.32,0,0,1,1.72,0,1,1,0,0,1,.36.78,1,1,0,0,1-.36.77,1.29,1.29,0,0,1-1.72,0A1,1,0,0,1,404.62,464.34Zm.18,12.26v-8.1a.58.58,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.36,1.36,0,0,1,.76.19.57.57,0,0,1,.3.49v8.1a.61.61,0,0,1-.31.48,1.25,1.25,0,0,1-.75.22,1.2,1.2,0,0,1-.74-.22A.62.62,0,0,1,404.8,476.6Z"/>
            <path className="cls-1" d="M409.08,474.18v-10a.57.57,0,0,1,.32-.48,1.34,1.34,0,0,1,.72-.2,1.4,1.4,0,0,1,.73.2.56.56,0,0,1,.33.48v3.66h2.44a.51.51,0,0,1,.42.23,1,1,0,0,1,0,1.1.51.51,0,0,1-.42.23h-2.44v4.8a1.37,1.37,0,0,0,.31,1,1.56,1.56,0,0,0,1.09.3h.7a.87.87,0,0,1,.66.26,1,1,0,0,1,0,1.28.87.87,0,0,1-.66.26h-.7Q409.09,477.3,409.08,474.18Z"/>
            <path className="cls-1" d="M414.78,480.9c0-.6.19-.9.58-.9a2.59,2.59,0,0,0,1.78-.86,3.93,3.93,0,0,0,1.08-2.42L414.86,469a.47.47,0,0,1-.08-.28.86.86,0,0,1,.46-.69,1.61,1.61,0,0,1,.92-.31.61.61,0,0,1,.64.38l2.52,6.5,2.16-6.5a.62.62,0,0,1,.6-.38,1.71,1.71,0,0,1,.91.31.87.87,0,0,1,.49.73.45.45,0,0,1,0,.24l-3,7.82-.34.9-.37.86a8.13,8.13,0,0,1-.43.88l-.48.74a2.65,2.65,0,0,1-.6.69l-.68.48a2.43,2.43,0,0,1-.84.36,4.39,4.39,0,0,1-1,.11C415.11,481.8,414.78,481.5,414.78,480.9Z"/>
            <path className="cls-1" d="M424.52,476.43a1,1,0,0,1,.25-.72.82.82,0,0,1,.64-.29.8.8,0,0,1,.61.3,1.07,1.07,0,0,1,.26.71,1,1,0,0,1-.26.69.8.8,0,0,1-.61.3.82.82,0,0,1-.64-.29A1,1,0,0,1,424.52,476.43Z"/>
            <path className="cls-1" d="M431.86,475.3a.8.8,0,0,1,.19-.49.52.52,0,0,1,.41-.25c.11,0,.24.09.4.26l.56.58a2.67,2.67,0,0,0,.95.57,4.12,4.12,0,0,0,1.51.25,3.16,3.16,0,0,0,2.2-.71,2.63,2.63,0,0,0,.78-2.05,2.94,2.94,0,0,0-.37-1.53,2.68,2.68,0,0,0-.95-1,7.28,7.28,0,0,0-1.28-.64l-1.44-.56a6.52,6.52,0,0,1-1.29-.67,3,3,0,0,1-.94-1.1,3.57,3.57,0,0,1-.37-1.69,3.86,3.86,0,0,1,.39-1.8,2.77,2.77,0,0,1,1.05-1.14,5.19,5.19,0,0,1,1.35-.55,6.19,6.19,0,0,1,1.45-.17,7.42,7.42,0,0,1,2.29.35c.71.23,1.07.52,1.07.87a.91.91,0,0,1-.17.49.48.48,0,0,1-.41.25,1.33,1.33,0,0,1-.46-.18,5.15,5.15,0,0,0-.95-.38,4.93,4.93,0,0,0-1.39-.18,3.72,3.72,0,0,0-2.08.54,2,2,0,0,0-.82,1.78,2.15,2.15,0,0,0,.49,1.44,2.77,2.77,0,0,0,1.23.84c.49.17,1,.38,1.6.62a17,17,0,0,1,1.6.77,3.23,3.23,0,0,1,1.23,1.3,4.43,4.43,0,0,1,.49,2.19,3.94,3.94,0,0,1-1.14,3.08,4.45,4.45,0,0,1-3.08,1,5.43,5.43,0,0,1-2.92-.74C432.25,476.2,431.86,475.74,431.86,475.3Z"/>
            <path className="cls-1" d="M441.92,473.64V471.4a3.48,3.48,0,0,1,1.12-2.59,3.87,3.87,0,0,1,5.33-.05,3.43,3.43,0,0,1,1.11,2.6,2.45,2.45,0,0,1-.13.9.84.84,0,0,1-.42.48,2.21,2.21,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0h-4.52v.74a2.32,2.32,0,0,0,.85,1.89,3.7,3.7,0,0,0,3.38.51,3.47,3.47,0,0,0,.78-.4.84.84,0,0,1,.43-.2.49.49,0,0,1,.39.21.69.69,0,0,1,.17.41c0,.28-.29.57-.86.87a4.58,4.58,0,0,1-2.14.45,4.53,4.53,0,0,1-3.1-1.05A3.48,3.48,0,0,1,441.92,473.64Zm1.3-1.64h4a1.59,1.59,0,0,0,.8-.13c.13-.08.2-.27.2-.55a2.47,2.47,0,0,0-.71-1.74,2.33,2.33,0,0,0-1.79-.76,2.44,2.44,0,0,0-1.77.72,2.27,2.27,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M451.08,473.64V471.4a3.48,3.48,0,0,1,1.12-2.59,3.87,3.87,0,0,1,5.33-.05,3.43,3.43,0,0,1,1.11,2.6,2.45,2.45,0,0,1-.13.9.84.84,0,0,1-.42.48,2.21,2.21,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0h-4.52v.74a2.32,2.32,0,0,0,.85,1.89,3.7,3.7,0,0,0,3.38.51,3.47,3.47,0,0,0,.78-.4.84.84,0,0,1,.43-.2.49.49,0,0,1,.39.21.69.69,0,0,1,.17.41c0,.28-.29.57-.86.87a4.58,4.58,0,0,1-2.14.45,4.53,4.53,0,0,1-3.1-1.05A3.48,3.48,0,0,1,451.08,473.64Zm1.3-1.64h4a1.59,1.59,0,0,0,.8-.13c.13-.08.2-.27.2-.55a2.47,2.47,0,0,0-.71-1.74,2.33,2.33,0,0,0-1.79-.76,2.44,2.44,0,0,0-1.77.72,2.27,2.27,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M460.66,476.6V463.36a.66.66,0,0,1,.2-.48.69.69,0,0,1,.5-.2h3.8q4.6,0,4.6,4.08a4.17,4.17,0,0,1-.76,2.66,3.63,3.63,0,0,1-2.06,1.28l2.8,5.28a.61.61,0,0,1,.08.3,1,1,0,0,1-.41.74,1.31,1.31,0,0,1-.87.34,1,1,0,0,1-.84-.62L464.78,471h-2v5.6a.58.58,0,0,1-.31.51,1.39,1.39,0,0,1-.75.19,1.35,1.35,0,0,1-.73-.19A.58.58,0,0,1,460.66,476.6Zm2.1-7.28h2.4c1.65,0,2.48-.79,2.48-2.38s-.83-2.38-2.48-2.38h-2.4Z"/>
            <path className="cls-1" d="M471.84,476.6V463.36a.66.66,0,0,1,.2-.48.69.69,0,0,1,.5-.2h3.8q4.61,0,4.6,4.08a4.17,4.17,0,0,1-.76,2.66,3.63,3.63,0,0,1-2.06,1.28l2.8,5.28a.61.61,0,0,1,.08.3,1,1,0,0,1-.41.74,1.31,1.31,0,0,1-.87.34,1,1,0,0,1-.84-.62L476,471h-2v5.6a.58.58,0,0,1-.31.51,1.39,1.39,0,0,1-.75.19,1.35,1.35,0,0,1-.73-.19A.58.58,0,0,1,471.84,476.6Zm2.1-7.28h2.4c1.65,0,2.48-.79,2.48-2.38s-.83-2.38-2.48-2.38h-2.4Z"/>
            <path className="cls-1" d="M483,476.6V463.36a.54.54,0,0,1,.31-.51,1.74,1.74,0,0,1,1.5,0,.54.54,0,0,1,.31.51V476.6a.58.58,0,0,1-.31.51,1.56,1.56,0,0,1-1.49,0A.59.59,0,0,1,483,476.6Z"/>
            <path className="cls-1" d="M38.57,497.34a1,1,0,0,1,.36-.78,1.25,1.25,0,0,1,.86-.32,1.27,1.27,0,0,1,.86.32,1,1,0,0,1,.36.78,1,1,0,0,1-.36.77,1.23,1.23,0,0,1-.86.33,1.22,1.22,0,0,1-.86-.33A1,1,0,0,1,38.57,497.34Zm.18,12.26v-8.1A.56.56,0,0,1,39,501a1.3,1.3,0,0,1,.75-.19,1.38,1.38,0,0,1,.76.19.57.57,0,0,1,.3.49v8.1a.61.61,0,0,1-.31.48,1.25,1.25,0,0,1-.75.22,1.2,1.2,0,0,1-.74-.22A.62.62,0,0,1,38.75,509.6Z"/>
            <path className="cls-1" d="M42.45,508.86a1.12,1.12,0,0,1,.21-.62.61.61,0,0,1,.51-.32,1.06,1.06,0,0,1,.51.23,4.39,4.39,0,0,0,.86.48,3.45,3.45,0,0,0,1.31.23c1.15,0,1.72-.36,1.72-1.09a1,1,0,0,0-.5-.91,4.34,4.34,0,0,0-1.21-.53c-.48-.12-.94-.27-1.41-.43a2.67,2.67,0,0,1-1.2-.82,2.27,2.27,0,0,1-.5-1.51,2.76,2.76,0,0,1,.84-2,3.47,3.47,0,0,1,2.58-.85,5.91,5.91,0,0,1,2.07.35c.62.23.93.51.93.83a1.31,1.31,0,0,1-.23.65.66.66,0,0,1-.57.37,3.86,3.86,0,0,1-.82-.3,3.44,3.44,0,0,0-1.34-.3,1.77,1.77,0,0,0-1.21.36,1,1,0,0,0-.41.81.84.84,0,0,0,.5.76,4.79,4.79,0,0,0,1.22.46,12.29,12.29,0,0,1,1.42.45,2.65,2.65,0,0,1,1.19.9,2.69,2.69,0,0,1,.49,1.65,2.46,2.46,0,0,1-.92,2,4.07,4.07,0,0,1-2.6.74,5.45,5.45,0,0,1-2.43-.5C42.79,509.63,42.45,509.26,42.45,508.86Z"/>
            <path className="cls-1" d="M55.11,507.18v-10a.55.55,0,0,1,.32-.48,1.34,1.34,0,0,1,.72-.2,1.4,1.4,0,0,1,.73.2.56.56,0,0,1,.33.48v3.66h2.44a.51.51,0,0,1,.42.23,1,1,0,0,1,0,1.1.51.51,0,0,1-.42.23H57.21v4.8a1.37,1.37,0,0,0,.31,1,1.54,1.54,0,0,0,1.09.3h.7a.87.87,0,0,1,.66.26A1,1,0,0,1,60,510a.87.87,0,0,1-.66.26h-.7C56.27,510.3,55.11,509.26,55.11,507.18Z"/>
            <path className="cls-1" d="M61.73,509.6V495.88a.58.58,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.39,1.39,0,0,1,.75.19.58.58,0,0,1,.31.49v6.36a2.73,2.73,0,0,1,1-1.1,2.81,2.81,0,0,1,1.57-.44,3.2,3.2,0,0,1,2.45,1.16,4,4,0,0,1,1,2.78v5a.59.59,0,0,1-.32.51,1.41,1.41,0,0,1-.74.19,1.34,1.34,0,0,1-.72-.19.59.59,0,0,1-.32-.51v-5a2,2,0,0,0-.6-1.43,1.88,1.88,0,0,0-1.42-.64,1.84,1.84,0,0,0-1.34.57,2,2,0,0,0-.58,1.5v5a.61.61,0,0,1-.32.48,1.26,1.26,0,0,1-.74.22,1.18,1.18,0,0,1-.74-.22A.62.62,0,0,1,61.73,509.6Z"/>
            <path className="cls-1" d="M71.63,506.48v-1.9a3.65,3.65,0,0,1,1.22-2.73,4.32,4.32,0,0,1,5.75-.11,3.36,3.36,0,0,1,1.17,2.63,3.06,3.06,0,0,1-.14,1,.89.89,0,0,1-.44.52,1.81,1.81,0,0,1-.56.2,4.27,4.27,0,0,1-.68,0H73.73v.46a2,2,0,0,0,.72,1.61,2.9,2.9,0,0,0,1.88.59,3.35,3.35,0,0,0,1.55-.38,3.25,3.25,0,0,1,.89-.39.7.7,0,0,1,.58.33,1,1,0,0,1,.24.62c0,.33-.32.66-1,1a5.12,5.12,0,0,1-2.36.5,5,5,0,0,1-3.34-1.1A3.57,3.57,0,0,1,71.63,506.48Zm2.1-1.7h3.32a1,1,0,0,0,.59-.12.57.57,0,0,0,.15-.47,1.77,1.77,0,0,0-.58-1.34,2,2,0,0,0-1.44-.55,2.11,2.11,0,0,0-1.44.52,1.69,1.69,0,0,0-.6,1.33Z"/>
            <path className="cls-1" d="M85.49,509.6V495.88a.57.57,0,0,1,.28-.49,1.27,1.27,0,0,1,.74-.19,1.42,1.42,0,0,1,.77.19.56.56,0,0,1,.31.49v6.36a3,3,0,0,1,1-1.1,2.82,2.82,0,0,1,1.56-.44,3.3,3.3,0,0,1,2.53,1.16,4,4,0,0,1,1,2.78v1.84a4.07,4.07,0,0,1-1.06,2.77,3.15,3.15,0,0,1-4.12.75,2.94,2.94,0,0,1-1.1-1.12v.71a.63.63,0,0,1-.29.49,1.06,1.06,0,0,1-.67.22,1.15,1.15,0,0,1-.73-.22A.63.63,0,0,1,85.49,509.6Zm2.1-2.67a1.63,1.63,0,0,0,.63,1,2,2,0,0,0,1.41.61,1.84,1.84,0,0,0,1.42-.65,2.08,2.08,0,0,0,.6-1.46v-1.82a2,2,0,0,0-.61-1.43,1.9,1.9,0,0,0-1.43-.64,2,2,0,0,0-1.4.58,2,2,0,0,0-.62,1.49Z"/>
            <path className="cls-1" d="M95.23,506.48v-1.9a3.65,3.65,0,0,1,1.22-2.73,4.32,4.32,0,0,1,5.75-.11,3.36,3.36,0,0,1,1.17,2.63,3.06,3.06,0,0,1-.14,1,.89.89,0,0,1-.44.52,1.81,1.81,0,0,1-.56.2,4.2,4.2,0,0,1-.68,0H97.33v.46a2,2,0,0,0,.72,1.61,2.9,2.9,0,0,0,1.88.59,3.35,3.35,0,0,0,1.55-.38,3.25,3.25,0,0,1,.89-.39.7.7,0,0,1,.58.33,1,1,0,0,1,.24.62c0,.33-.32.66-1,1a5.12,5.12,0,0,1-2.36.5,5,5,0,0,1-3.34-1.1A3.57,3.57,0,0,1,95.23,506.48Zm2.1-1.7h3.32a1,1,0,0,0,.59-.12.57.57,0,0,0,.15-.47,1.77,1.77,0,0,0-.58-1.34,2,2,0,0,0-1.44-.55,2.11,2.11,0,0,0-1.44.52,1.69,1.69,0,0,0-.6,1.33Z"/>
            <path className="cls-1" d="M104.47,508.86a1.12,1.12,0,0,1,.21-.62.61.61,0,0,1,.51-.32,1,1,0,0,1,.51.23,4.23,4.23,0,0,0,.87.48,3.38,3.38,0,0,0,1.31.23c1.14,0,1.71-.36,1.71-1.09a1,1,0,0,0-.5-.91,4.34,4.34,0,0,0-1.21-.53c-.47-.12-.94-.27-1.41-.43a2.67,2.67,0,0,1-1.2-.82,2.27,2.27,0,0,1-.5-1.51,2.76,2.76,0,0,1,.84-2,3.47,3.47,0,0,1,2.58-.85,5.91,5.91,0,0,1,2.07.35c.62.23.93.51.93.83a1.31,1.31,0,0,1-.23.65.65.65,0,0,1-.57.37,4.08,4.08,0,0,1-.82-.3,3.4,3.4,0,0,0-1.34-.3,1.78,1.78,0,0,0-1.21.36,1.07,1.07,0,0,0-.41.81.86.86,0,0,0,.5.76,4.79,4.79,0,0,0,1.22.46,12.29,12.29,0,0,1,1.42.45,2.65,2.65,0,0,1,1.19.9,2.69,2.69,0,0,1,.49,1.65,2.46,2.46,0,0,1-.92,2,4.07,4.07,0,0,1-2.6.74,5.45,5.45,0,0,1-2.43-.5C104.81,509.63,104.47,509.26,104.47,508.86Z"/>
            <path className="cls-1" d="M113.13,507.18v-10a.57.57,0,0,1,.32-.48,1.34,1.34,0,0,1,.72-.2,1.4,1.4,0,0,1,.73.2.56.56,0,0,1,.33.48v3.66h2.44a.51.51,0,0,1,.42.23,1,1,0,0,1,0,1.1.51.51,0,0,1-.42.23h-2.44v4.8a1.37,1.37,0,0,0,.31,1,1.56,1.56,0,0,0,1.09.3h.7a.87.87,0,0,1,.66.26,1,1,0,0,1,0,1.28.87.87,0,0,1-.66.26h-.7C114.29,510.3,113.13,509.26,113.13,507.18Z"/>
            <path className="cls-1" d="M122.83,501.62a.74.74,0,0,1,.44-.62,1.76,1.76,0,0,1,.9-.26.61.61,0,0,1,.64.38l2.22,6.22,1.7-5.52a.83.83,0,0,1,1.48,0l1.68,5.56,2.24-6.26a.57.57,0,0,1,.62-.38,1.81,1.81,0,0,1,.89.26.75.75,0,0,1,.45.64.76.76,0,0,1-.08.32l-3.1,8.12q-.16.42-.84.42c-.45,0-.74-.13-.86-.4l-1.74-5.16-1.72,5.1c-.12.31-.42.46-.9.46A.79.79,0,0,1,126,510l-3.1-8.08A1.39,1.39,0,0,1,122.83,501.62Z"/>
            <path className="cls-1" d="M136.81,507.82a2.3,2.3,0,0,1,1.2-2.23,7.66,7.66,0,0,1,3.48-.63h.88v-.37a2.54,2.54,0,0,0-.49-1.55,1.75,1.75,0,0,0-1.49-.66,3.29,3.29,0,0,0-1.51.33,4.76,4.76,0,0,1-.71.33.63.63,0,0,1-.55-.33,1.32,1.32,0,0,1-.21-.73c0-.35.32-.65,1-.9a5.65,5.65,0,0,1,2.05-.38,3.89,3.89,0,0,1,3,1.11,4,4,0,0,1,1,2.77v5a.6.6,0,0,1-.29.49,1.21,1.21,0,0,1-.73.21,1.08,1.08,0,0,1-.68-.21.59.59,0,0,1-.28-.49v-.7a3.94,3.94,0,0,1-3,1.56,2.76,2.76,0,0,1-1.91-.68A2.51,2.51,0,0,1,136.81,507.82Zm2.12-.26a1.25,1.25,0,0,0,.36,1,1.33,1.33,0,0,0,.94.33,2.1,2.1,0,0,0,1.45-.64,1.86,1.86,0,0,0,.69-1.36v-.74h-.5a11.54,11.54,0,0,0-1.47.08,2.1,2.1,0,0,0-1,.42A1.12,1.12,0,0,0,138.93,507.56Z"/>
            <path className="cls-1" d="M145.55,513.9c0-.6.19-.9.58-.9a2.59,2.59,0,0,0,1.78-.86,3.93,3.93,0,0,0,1.08-2.42L145.63,502a.47.47,0,0,1-.08-.28A.86.86,0,0,1,146,501a1.61,1.61,0,0,1,.92-.31.61.61,0,0,1,.64.38l2.52,6.5,2.16-6.5a.62.62,0,0,1,.6-.38,1.71,1.71,0,0,1,.91.31.89.89,0,0,1,.49.73.45.45,0,0,1,0,.24l-3,7.82-.34.9c-.08.19-.21.47-.37.86a8.13,8.13,0,0,1-.43.88l-.48.74a2.65,2.65,0,0,1-.6.69l-.68.48a2.52,2.52,0,0,1-.84.36,4.39,4.39,0,0,1-1,.11C145.88,514.8,145.55,514.5,145.55,513.9Z"/>
            <path className="cls-1" d="M159.57,507.18v-10a.55.55,0,0,1,.32-.48,1.34,1.34,0,0,1,.72-.2,1.4,1.4,0,0,1,.73.2.56.56,0,0,1,.33.48v3.66h2.44a.51.51,0,0,1,.42.23,1,1,0,0,1,0,1.1.51.51,0,0,1-.42.23h-2.44v4.8a1.37,1.37,0,0,0,.31,1,1.54,1.54,0,0,0,1.09.3h.7a.87.87,0,0,1,.66.26,1,1,0,0,1,0,1.28.87.87,0,0,1-.66.26h-.7C160.73,510.3,159.57,509.26,159.57,507.18Z"/>
            <path className="cls-1" d="M165.65,506.48v-1.84a3.74,3.74,0,0,1,1.22-2.76,4,4,0,0,1,2.92-1.18,4.09,4.09,0,0,1,2.9,1.17,3.67,3.67,0,0,1,1.24,2.77v1.84a3.77,3.77,0,0,1-1.25,2.77,4.08,4.08,0,0,1-5.79,0A3.75,3.75,0,0,1,165.65,506.48Zm2.1,0a2.08,2.08,0,0,0,.6,1.46,1.87,1.87,0,0,0,1.44.65,1.89,1.89,0,0,0,1.43-.65,2,2,0,0,0,.61-1.46v-1.82a2,2,0,0,0-.61-1.43,1.92,1.92,0,0,0-1.43-.64,1.89,1.89,0,0,0-1.44.64,2,2,0,0,0-.6,1.43Z"/>
            <path className="cls-1" d="M180,509.6V495.86a.58.58,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.39,1.39,0,0,1,.75.19.56.56,0,0,1,.31.49V509.6a.62.62,0,0,1-.32.48,1.24,1.24,0,0,1-.74.22,1.2,1.2,0,0,1-.74-.22A.62.62,0,0,1,180,509.6Z"/>
            <path className="cls-1" d="M184.25,497.34a1,1,0,0,1,.36-.78,1.32,1.32,0,0,1,1.72,0,1,1,0,0,1,.36.78,1,1,0,0,1-.36.77,1.29,1.29,0,0,1-1.72,0A1,1,0,0,1,184.25,497.34Zm.18,12.26v-8.1a.58.58,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.36,1.36,0,0,1,.76.19.57.57,0,0,1,.3.49v8.1a.61.61,0,0,1-.31.48,1.25,1.25,0,0,1-.75.22,1.2,1.2,0,0,1-.74-.22A.62.62,0,0,1,184.43,509.6Z"/>
            <path className="cls-1" d="M187.81,501.64a.75.75,0,0,1,.46-.63,1.8,1.8,0,0,1,.92-.27.61.61,0,0,1,.64.38l2.38,6.32,2.38-6.28a.62.62,0,0,1,.64-.42,1.82,1.82,0,0,1,.88.28c.33.19.5.39.5.62a1,1,0,0,1-.08.34l-3.36,8.1q-.18.42-1,.42t-1-.42l-3.34-8.1A.75.75,0,0,1,187.81,501.64Z"/>
            <path className="cls-1" d="M197.47,506.48v-1.9a3.65,3.65,0,0,1,1.22-2.73,4.32,4.32,0,0,1,5.75-.11,3.36,3.36,0,0,1,1.17,2.63,3.06,3.06,0,0,1-.14,1,.89.89,0,0,1-.44.52,1.81,1.81,0,0,1-.56.2,4.27,4.27,0,0,1-.68,0h-4.22v.46a2,2,0,0,0,.72,1.61,2.9,2.9,0,0,0,1.88.59,3.38,3.38,0,0,0,1.55-.38,3.12,3.12,0,0,1,.89-.39.71.71,0,0,1,.58.33,1,1,0,0,1,.24.62c0,.33-.32.66-1,1a5.12,5.12,0,0,1-2.36.5,5,5,0,0,1-3.34-1.1A3.57,3.57,0,0,1,197.47,506.48Zm2.1-1.7h3.32a1,1,0,0,0,.59-.12.57.57,0,0,0,.15-.47,1.77,1.77,0,0,0-.58-1.34,2,2,0,0,0-1.44-.55,2.11,2.11,0,0,0-1.44.52,1.69,1.69,0,0,0-.6,1.33Z"/>
            <path className="cls-1" d="M211.29,507.18v-10a.55.55,0,0,1,.32-.48,1.34,1.34,0,0,1,.72-.2,1.43,1.43,0,0,1,.73.2.56.56,0,0,1,.33.48v3.66h2.44a.51.51,0,0,1,.42.23,1,1,0,0,1,0,1.1.51.51,0,0,1-.42.23h-2.44v4.8a1.37,1.37,0,0,0,.31,1,1.54,1.54,0,0,0,1.09.3h.7a.87.87,0,0,1,.66.26,1,1,0,0,1,0,1.28.87.87,0,0,1-.66.26h-.7C212.45,510.3,211.29,509.26,211.29,507.18Z"/>
            <path className="cls-1" d="M217.91,509.6V495.88a.56.56,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.39,1.39,0,0,1,.75.19.58.58,0,0,1,.31.49v6.36a2.73,2.73,0,0,1,1-1.1,2.81,2.81,0,0,1,1.57-.44,3.2,3.2,0,0,1,2.45,1.16,4.07,4.07,0,0,1,1,2.78v5a.59.59,0,0,1-.32.51,1.41,1.41,0,0,1-.74.19,1.34,1.34,0,0,1-.72-.19.57.57,0,0,1-.32-.51v-5a2,2,0,0,0-.6-1.43,1.88,1.88,0,0,0-1.42-.64,1.84,1.84,0,0,0-1.34.57,2,2,0,0,0-.58,1.5v5a.61.61,0,0,1-.32.48,1.26,1.26,0,0,1-.74.22,1.18,1.18,0,0,1-.74-.22A.62.62,0,0,1,217.91,509.6Z"/>
            <path className="cls-1" d="M228,497.34a1,1,0,0,1,.36-.78,1.32,1.32,0,0,1,1.72,0,1,1,0,0,1,.36.78,1,1,0,0,1-.36.77,1.29,1.29,0,0,1-1.72,0A1,1,0,0,1,228,497.34Zm.18,12.26v-8.1a.56.56,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.38,1.38,0,0,1,.76.19.57.57,0,0,1,.3.49v8.1a.61.61,0,0,1-.31.48,1.25,1.25,0,0,1-.75.22,1.2,1.2,0,0,1-.74-.22A.62.62,0,0,1,228.17,509.6Z"/>
            <path className="cls-1" d="M231.87,508.86a1.12,1.12,0,0,1,.21-.62.61.61,0,0,1,.51-.32,1.06,1.06,0,0,1,.51.23,4.39,4.39,0,0,0,.86.48,3.45,3.45,0,0,0,1.31.23c1.15,0,1.72-.36,1.72-1.09a1,1,0,0,0-.5-.91,4.34,4.34,0,0,0-1.21-.53c-.48-.12-.95-.27-1.41-.43a2.67,2.67,0,0,1-1.2-.82,2.22,2.22,0,0,1-.5-1.51,2.76,2.76,0,0,1,.84-2,3.47,3.47,0,0,1,2.58-.85,5.91,5.91,0,0,1,2.07.35c.62.23.93.51.93.83a1.31,1.31,0,0,1-.23.65.68.68,0,0,1-.57.37,3.86,3.86,0,0,1-.82-.3,3.44,3.44,0,0,0-1.34-.3,1.77,1.77,0,0,0-1.21.36,1,1,0,0,0-.41.81.84.84,0,0,0,.5.76,4.79,4.79,0,0,0,1.22.46,12.29,12.29,0,0,1,1.42.45,2.65,2.65,0,0,1,1.19.9,2.69,2.69,0,0,1,.49,1.65,2.46,2.46,0,0,1-.92,2,4.08,4.08,0,0,1-2.6.74,5.45,5.45,0,0,1-2.43-.5C232.2,509.63,231.87,509.26,231.87,508.86Z"/>
            <path className="cls-1" d="M244.23,506.48v-1.8a4,4,0,0,1,1-2.88,4.08,4.08,0,0,1,3.1-1.1,4.78,4.78,0,0,1,2.18.45c.58.3.88.64.88,1a1.19,1.19,0,0,1-.25.68.73.73,0,0,1-.59.34,3.28,3.28,0,0,1-.71-.3,3,3,0,0,0-1.35-.3c-1.47,0-2.2.7-2.2,2.11v1.78c0,1.41.74,2.11,2.22,2.11a3,3,0,0,0,1.37-.32,3.09,3.09,0,0,1,.73-.32.8.8,0,0,1,.65.32,1.12,1.12,0,0,1,.25.68c0,.37-.31.72-.92,1a4.81,4.81,0,0,1-2.24.48,4.1,4.1,0,0,1-3.12-1.09A4,4,0,0,1,244.23,506.48Z"/>
            <path className="cls-1" d="M253.09,509.6V495.88a.56.56,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.39,1.39,0,0,1,.75.19.58.58,0,0,1,.31.49v6.36a2.73,2.73,0,0,1,1-1.1,2.81,2.81,0,0,1,1.57-.44,3.2,3.2,0,0,1,2.45,1.16,4.07,4.07,0,0,1,1,2.78v5a.59.59,0,0,1-.32.51,1.41,1.41,0,0,1-.74.19,1.34,1.34,0,0,1-.72-.19.57.57,0,0,1-.32-.51v-5a2,2,0,0,0-.6-1.43,1.88,1.88,0,0,0-1.42-.64,1.84,1.84,0,0,0-1.34.57,2,2,0,0,0-.58,1.5v5a.61.61,0,0,1-.32.48,1.26,1.26,0,0,1-.74.22,1.18,1.18,0,0,1-.74-.22A.62.62,0,0,1,253.09,509.6Z"/>
            <path className="cls-1" d="M262.75,507.82a2.3,2.3,0,0,1,1.2-2.23,7.66,7.66,0,0,1,3.48-.63h.88v-.37a2.54,2.54,0,0,0-.49-1.55,1.75,1.75,0,0,0-1.49-.66,3.26,3.26,0,0,0-1.51.33,5.36,5.36,0,0,1-.71.33.63.63,0,0,1-.55-.33,1.32,1.32,0,0,1-.21-.73c0-.35.32-.65,1-.9a5.61,5.61,0,0,1,2.05-.38,3.89,3.89,0,0,1,3.05,1.11,4,4,0,0,1,1,2.77v5a.6.6,0,0,1-.29.49,1.23,1.23,0,0,1-.73.21,1.08,1.08,0,0,1-.68-.21.59.59,0,0,1-.28-.49v-.7a3.94,3.94,0,0,1-3,1.56,2.74,2.74,0,0,1-1.91-.68A2.51,2.51,0,0,1,262.75,507.82Zm2.12-.26a1.25,1.25,0,0,0,.36,1,1.33,1.33,0,0,0,.94.33,2.11,2.11,0,0,0,1.45-.64,1.86,1.86,0,0,0,.69-1.36v-.74h-.5a11.54,11.54,0,0,0-1.47.08,2.1,2.1,0,0,0-1,.42A1.12,1.12,0,0,0,264.87,507.56Z"/>
            <path className="cls-1" d="M272.73,509.6V495.86a.58.58,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.39,1.39,0,0,1,.75.19.58.58,0,0,1,.31.49V509.6a.62.62,0,0,1-.32.48,1.26,1.26,0,0,1-.74.22,1.18,1.18,0,0,1-.74-.22A.62.62,0,0,1,272.73,509.6Z"/>
            <path className="cls-1" d="M277.39,509.6V495.86a.58.58,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.39,1.39,0,0,1,.75.19.58.58,0,0,1,.31.49V509.6a.62.62,0,0,1-.32.48,1.26,1.26,0,0,1-.74.22,1.18,1.18,0,0,1-.74-.22A.62.62,0,0,1,277.39,509.6Z"/>
            <path className="cls-1" d="M281.51,506.48v-1.9a3.65,3.65,0,0,1,1.22-2.73,4.32,4.32,0,0,1,5.75-.11,3.36,3.36,0,0,1,1.17,2.63,3.06,3.06,0,0,1-.14,1,.89.89,0,0,1-.44.52,1.81,1.81,0,0,1-.56.2,4.27,4.27,0,0,1-.68,0h-4.22v.46a2,2,0,0,0,.72,1.61,2.9,2.9,0,0,0,1.88.59,3.38,3.38,0,0,0,1.55-.38,3.25,3.25,0,0,1,.89-.39.7.7,0,0,1,.58.33,1,1,0,0,1,.24.62c0,.33-.32.66-1,1a5.12,5.12,0,0,1-2.36.5,5,5,0,0,1-3.34-1.1A3.57,3.57,0,0,1,281.51,506.48Zm2.1-1.7h3.32a1,1,0,0,0,.59-.12.57.57,0,0,0,.15-.47,1.77,1.77,0,0,0-.58-1.34,2,2,0,0,0-1.44-.55,2.11,2.11,0,0,0-1.44.52,1.69,1.69,0,0,0-.6,1.33Z"/>
            <path className="cls-1" d="M291.35,509.6v-8.1a.58.58,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.11,1.11,0,0,1,.67.19.6.6,0,0,1,.27.5v.73a2.7,2.7,0,0,1,1-1.1,3.08,3.08,0,0,1,1.65-.44,3.18,3.18,0,0,1,2.45,1.16,4,4,0,0,1,1,2.78v5a.58.58,0,0,1-.31.51,1.39,1.39,0,0,1-.75.19,1.34,1.34,0,0,1-.72-.19.59.59,0,0,1-.32-.51v-5a2,2,0,0,0-.6-1.43,1.88,1.88,0,0,0-2.76-.07,2,2,0,0,0-.58,1.5v5a.61.61,0,0,1-.32.48,1.24,1.24,0,0,1-.74.22,1.2,1.2,0,0,1-.74-.22A.62.62,0,0,1,291.35,509.6Z"/>
            <path className="cls-1" d="M300.89,512.09a2.35,2.35,0,0,1,1.56-2.17,1.37,1.37,0,0,1-.88-1.33,1.84,1.84,0,0,1,.94-1.53,3,3,0,0,1-1.2-2.52V504a3,3,0,0,1,1-2.36,3.9,3.9,0,0,1,2.69-.92,3.82,3.82,0,0,1,2.32.68c.39-.89.82-1.34,1.3-1.34a.88.88,0,0,1,.62.23.68.68,0,0,1,.24.49.55.55,0,0,1-.34.5,2.29,2.29,0,0,0-1,.8,3,3,0,0,1,.64,1.92v.56a3.07,3.07,0,0,1-1.06,2.38,3.93,3.93,0,0,1-2.72.94,3.7,3.7,0,0,1-1.38-.24.71.71,0,0,0-.38.56c0,.27.21.47.63.59a8.83,8.83,0,0,0,1.52.28c.59.07,1.19.17,1.78.3a3,3,0,0,1,1.52.82,2.2,2.2,0,0,1,.63,1.65,2.47,2.47,0,0,1-1.19,2.23,5.55,5.55,0,0,1-3,.75,5.8,5.8,0,0,1-3-.74A2.21,2.21,0,0,1,300.89,512.09Zm2-.23a1.35,1.35,0,0,0,.63,1.17,2.88,2.88,0,0,0,1.63.43,2.71,2.71,0,0,0,1.58-.42,1.36,1.36,0,0,0,.6-1.16,1.12,1.12,0,0,0-.28-.78,1.74,1.74,0,0,0-.83-.46,6.7,6.7,0,0,0-.94-.2,10.33,10.33,0,0,0-1.05-.09C303.32,510.68,302.87,511.19,302.87,511.86Zm.48-7.34a2.16,2.16,0,0,0,.46,1.44,1.56,1.56,0,0,0,1.26.56,1.53,1.53,0,0,0,1.24-.55,2.14,2.14,0,0,0,.46-1.43V504a2.08,2.08,0,0,0-.46-1.39,1.55,1.55,0,0,0-1.26-.55,1.53,1.53,0,0,0-1.24.55,2.08,2.08,0,0,0-.46,1.39Z"/>
            <path className="cls-1" d="M310.51,506.48v-1.9a3.65,3.65,0,0,1,1.22-2.73,4.32,4.32,0,0,1,5.75-.11,3.36,3.36,0,0,1,1.17,2.63,3.06,3.06,0,0,1-.14,1,.89.89,0,0,1-.44.52,1.81,1.81,0,0,1-.56.2,4.27,4.27,0,0,1-.68,0h-4.22v.46a2,2,0,0,0,.72,1.61,2.9,2.9,0,0,0,1.88.59,3.38,3.38,0,0,0,1.55-.38,3.25,3.25,0,0,1,.89-.39.7.7,0,0,1,.58.33,1,1,0,0,1,.24.62c0,.33-.32.66-1,1a5.12,5.12,0,0,1-2.36.5,5,5,0,0,1-3.34-1.1A3.57,3.57,0,0,1,310.51,506.48Zm2.1-1.7h3.32a1,1,0,0,0,.59-.12.57.57,0,0,0,.15-.47,1.77,1.77,0,0,0-.58-1.34,2,2,0,0,0-1.44-.55,2.11,2.11,0,0,0-1.44.52,1.69,1.69,0,0,0-.6,1.33Z"/>
            <path className="cls-1" d="M323.75,507.82a2.3,2.3,0,0,1,1.2-2.23,7.66,7.66,0,0,1,3.48-.63h.88v-.37a2.47,2.47,0,0,0-.49-1.55,1.74,1.74,0,0,0-1.49-.66,3.29,3.29,0,0,0-1.51.33,4.76,4.76,0,0,1-.71.33.63.63,0,0,1-.55-.33,1.32,1.32,0,0,1-.21-.73c0-.35.32-.65,1-.9a5.65,5.65,0,0,1,2.05-.38,3.89,3.89,0,0,1,3.05,1.11,4,4,0,0,1,1,2.77v5a.6.6,0,0,1-.29.49,1.21,1.21,0,0,1-.73.21,1.1,1.1,0,0,1-.68-.21.59.59,0,0,1-.28-.49v-.7a3.92,3.92,0,0,1-3,1.56,2.76,2.76,0,0,1-1.91-.68A2.51,2.51,0,0,1,323.75,507.82Zm2.12-.26a1.25,1.25,0,0,0,.36,1,1.33,1.33,0,0,0,.94.33,2.1,2.1,0,0,0,1.45-.64,1.86,1.86,0,0,0,.69-1.36v-.74h-.5a11.54,11.54,0,0,0-1.47.08,2.1,2.1,0,0,0-1,.42A1.12,1.12,0,0,0,325.87,507.56Z"/>
            <path className="cls-1" d="M333.55,509.6v-8.1a.58.58,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.11,1.11,0,0,1,.67.19.6.6,0,0,1,.27.5v.73a2.7,2.7,0,0,1,1-1.1,3.08,3.08,0,0,1,1.65-.44,3.18,3.18,0,0,1,2.45,1.16,4,4,0,0,1,1,2.78v5a.58.58,0,0,1-.31.51,1.39,1.39,0,0,1-.75.19,1.34,1.34,0,0,1-.72-.19.59.59,0,0,1-.32-.51v-5a2,2,0,0,0-.6-1.43,1.88,1.88,0,0,0-2.76-.07,2,2,0,0,0-.58,1.5v5a.61.61,0,0,1-.32.48,1.24,1.24,0,0,1-.74.22,1.2,1.2,0,0,1-.74-.22A.62.62,0,0,1,333.55,509.6Z"/>
            <path className="cls-1" d="M343.45,506.48v-1.84a4,4,0,0,1,1.05-2.78A3.3,3.3,0,0,1,347,500.7a2.82,2.82,0,0,1,1.56.44,3.12,3.12,0,0,1,1,1.1v-6.36a.57.57,0,0,1,.3-.49,1.36,1.36,0,0,1,.76-.19,1.3,1.3,0,0,1,.75.19.58.58,0,0,1,.29.49V509.6a.62.62,0,0,1-.3.48,1.2,1.2,0,0,1-.74.22,1.06,1.06,0,0,1-.67-.22.63.63,0,0,1-.29-.49v-.71a2.77,2.77,0,0,1-1.09,1.12,3.25,3.25,0,0,1-1.67.46,3.19,3.19,0,0,1-2.46-1.21A4.07,4.07,0,0,1,343.45,506.48Zm2.1,0a2.08,2.08,0,0,0,.6,1.46,1.84,1.84,0,0,0,1.42.65A2,2,0,0,0,349,508a1.61,1.61,0,0,0,.64-1v-2.28a2,2,0,0,0-.62-1.49,2,2,0,0,0-1.42-.58,1.9,1.9,0,0,0-1.43.64,2,2,0,0,0-.61,1.43Z"/>
            <path className="cls-1" d="M357.63,506.48v-1.8a4,4,0,0,1,1-2.88,4.08,4.08,0,0,1,3.1-1.1,4.78,4.78,0,0,1,2.18.45c.59.3.88.64.88,1a1.12,1.12,0,0,1-.25.68.73.73,0,0,1-.59.34,3.28,3.28,0,0,1-.71-.3,3,3,0,0,0-1.35-.3c-1.47,0-2.2.7-2.2,2.11v1.78c0,1.41.74,2.11,2.22,2.11a3,3,0,0,0,1.37-.32,3.09,3.09,0,0,1,.73-.32.8.8,0,0,1,.65.32,1.06,1.06,0,0,1,.25.68c0,.37-.31.72-.92,1a4.78,4.78,0,0,1-2.24.48,4.1,4.1,0,0,1-3.12-1.09A4,4,0,0,1,357.63,506.48Z"/>
            <path className="cls-1" d="M365.87,507.82a2.3,2.3,0,0,1,1.2-2.23,7.66,7.66,0,0,1,3.48-.63h.88v-.37a2.54,2.54,0,0,0-.49-1.55,1.75,1.75,0,0,0-1.49-.66,3.29,3.29,0,0,0-1.51.33,5.36,5.36,0,0,1-.71.33.63.63,0,0,1-.55-.33,1.32,1.32,0,0,1-.21-.73c0-.35.32-.65,1-.9a5.61,5.61,0,0,1,2.05-.38,3.89,3.89,0,0,1,3.05,1.11,4,4,0,0,1,1,2.77v5a.6.6,0,0,1-.29.49,1.23,1.23,0,0,1-.73.21,1.08,1.08,0,0,1-.68-.21.59.59,0,0,1-.28-.49v-.7a3.94,3.94,0,0,1-3,1.56,2.74,2.74,0,0,1-1.91-.68A2.51,2.51,0,0,1,365.87,507.82Zm2.12-.26a1.25,1.25,0,0,0,.36,1,1.33,1.33,0,0,0,.94.33,2.11,2.11,0,0,0,1.45-.64,1.86,1.86,0,0,0,.69-1.36v-.74h-.5a11.54,11.54,0,0,0-1.47.08,2.1,2.1,0,0,0-1,.42A1.12,1.12,0,0,0,368,507.56Z"/>
            <path className="cls-1" d="M375.69,514.1V501.5A.58.58,0,0,1,376,501a1.23,1.23,0,0,1,.71-.19,1.2,1.2,0,0,1,.7.19.58.58,0,0,1,.28.5v.73a3,3,0,0,1,1.07-1.1,3,3,0,0,1,1.61-.44,3.32,3.32,0,0,1,2.55,1.16,4,4,0,0,1,1.05,2.78v1.84a4.12,4.12,0,0,1-1.06,2.77,3.19,3.19,0,0,1-2.46,1.21,2.9,2.9,0,0,1-1.59-.47,2.77,2.77,0,0,1-1-1.11v5.22a.6.6,0,0,1-.32.49,1.38,1.38,0,0,1-.76.21,1.21,1.21,0,0,1-.73-.21A.6.6,0,0,1,375.69,514.1Zm2.1-7.17a1.63,1.63,0,0,0,.61,1,1.9,1.9,0,0,0,2.83,0,2.06,2.06,0,0,0,.62-1.46v-1.82a2,2,0,0,0-.62-1.43,2,2,0,0,0-1.46-.64,2,2,0,0,0-2,2.07Z"/>
            <path className="cls-1" d="M385.77,507.18v-10a.57.57,0,0,1,.32-.48,1.34,1.34,0,0,1,.72-.2,1.4,1.4,0,0,1,.73.2.56.56,0,0,1,.33.48v3.66h2.44a.51.51,0,0,1,.42.23,1,1,0,0,1,0,1.1.51.51,0,0,1-.42.23h-2.44v4.8a1.37,1.37,0,0,0,.31,1,1.54,1.54,0,0,0,1.09.3h.7a.87.87,0,0,1,.66.26,1,1,0,0,1,0,1.28.87.87,0,0,1-.66.26h-.7C386.93,510.3,385.77,509.26,385.77,507.18Z"/>
            <path className="cls-1" d="M392.29,506.52v-5a.6.6,0,0,1,.32-.49,1.31,1.31,0,0,1,.74-.21,1.28,1.28,0,0,1,.73.21.58.58,0,0,1,.31.49v5a2,2,0,0,0,.61,1.44,1.94,1.94,0,0,0,2.87,0,2,2,0,0,0,.6-1.45v-5a.56.56,0,0,1,.31-.48,1.4,1.4,0,0,1,.75-.2,1.28,1.28,0,0,1,.73.2.58.58,0,0,1,.29.48v5a3.74,3.74,0,0,1-1.22,2.76,4.17,4.17,0,0,1-5.81,0A3.72,3.72,0,0,1,392.29,506.52Z"/>
            <path className="cls-1" d="M402.67,509.6v-8.1A.56.56,0,0,1,403,501a1.26,1.26,0,0,1,.73-.19,1.18,1.18,0,0,1,.68.19.61.61,0,0,1,.28.5v.89a3.19,3.19,0,0,1,1-1.2,2.53,2.53,0,0,1,1.51-.5h.64a.73.73,0,0,1,.56.27,1,1,0,0,1,.24.66,1,1,0,0,1-.24.67.71.71,0,0,1-.56.28h-.64a2.2,2.2,0,0,0-1.69.82,3,3,0,0,0-.73,2.11v4.09a.61.61,0,0,1-.32.48,1.28,1.28,0,0,1-.76.22,1.13,1.13,0,0,1-.73-.22A.6.6,0,0,1,402.67,509.6Z"/>
            <path className="cls-1" d="M409.27,506.48v-1.9a3.65,3.65,0,0,1,1.22-2.73,4.32,4.32,0,0,1,5.75-.11,3.36,3.36,0,0,1,1.17,2.63,3.06,3.06,0,0,1-.14,1,.89.89,0,0,1-.44.52,1.81,1.81,0,0,1-.56.2,4.2,4.2,0,0,1-.68,0h-4.22v.46a2,2,0,0,0,.72,1.61,2.9,2.9,0,0,0,1.88.59,3.35,3.35,0,0,0,1.55-.38,3.25,3.25,0,0,1,.89-.39.7.7,0,0,1,.58.33,1,1,0,0,1,.24.62c0,.33-.32.66-1,1a5.12,5.12,0,0,1-2.36.5,5,5,0,0,1-3.34-1.1A3.57,3.57,0,0,1,409.27,506.48Zm2.1-1.7h3.32a1,1,0,0,0,.59-.12.57.57,0,0,0,.15-.47,1.77,1.77,0,0,0-.58-1.34,2,2,0,0,0-1.44-.55,2.11,2.11,0,0,0-1.44.52,1.69,1.69,0,0,0-.6,1.33Z"/>
            <path className="cls-1" d="M423.09,507.18v-10a.57.57,0,0,1,.32-.48,1.34,1.34,0,0,1,.72-.2,1.4,1.4,0,0,1,.73.2.56.56,0,0,1,.33.48v3.66h2.44a.51.51,0,0,1,.42.23,1,1,0,0,1,0,1.1.51.51,0,0,1-.42.23h-2.44v4.8a1.37,1.37,0,0,0,.31,1,1.56,1.56,0,0,0,1.09.3h.7a.87.87,0,0,1,.66.26,1,1,0,0,1,0,1.28.87.87,0,0,1-.66.26h-.7Q423.1,510.3,423.09,507.18Z"/>
            <path className="cls-1" d="M429.71,509.6V495.88a.58.58,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.39,1.39,0,0,1,.75.19.56.56,0,0,1,.31.49v6.36a2.73,2.73,0,0,1,1-1.1,2.83,2.83,0,0,1,1.57-.44,3.18,3.18,0,0,1,2.45,1.16,4,4,0,0,1,1,2.78v5a.59.59,0,0,1-.32.51,1.38,1.38,0,0,1-.74.19,1.34,1.34,0,0,1-.72-.19.59.59,0,0,1-.32-.51v-5a2,2,0,0,0-.6-1.43,1.88,1.88,0,0,0-2.76-.07,2,2,0,0,0-.58,1.5v5a.61.61,0,0,1-.32.48,1.24,1.24,0,0,1-.74.22,1.2,1.2,0,0,1-.74-.22A.62.62,0,0,1,429.71,509.6Z"/>
            <path className="cls-1" d="M439.61,506.48v-1.9a3.65,3.65,0,0,1,1.22-2.73,4.32,4.32,0,0,1,5.75-.11,3.36,3.36,0,0,1,1.17,2.63,3.06,3.06,0,0,1-.14,1,.89.89,0,0,1-.44.52,1.81,1.81,0,0,1-.56.2,4.2,4.2,0,0,1-.68,0h-4.22v.46a2,2,0,0,0,.72,1.61,2.9,2.9,0,0,0,1.88.59,3.35,3.35,0,0,0,1.55-.38,3.25,3.25,0,0,1,.89-.39.7.7,0,0,1,.58.33,1,1,0,0,1,.24.62c0,.33-.32.66-1,1a5.12,5.12,0,0,1-2.36.5,5,5,0,0,1-3.34-1.1A3.57,3.57,0,0,1,439.61,506.48Zm2.1-1.7H445a1,1,0,0,0,.59-.12.57.57,0,0,0,.15-.47,1.77,1.77,0,0,0-.58-1.34,2,2,0,0,0-1.44-.55,2.11,2.11,0,0,0-1.44.52,1.69,1.69,0,0,0-.6,1.33Z"/>
            <path className="cls-1" d="M20.32,539.48v-1.84a3.74,3.74,0,0,1,1.22-2.76,4,4,0,0,1,2.92-1.18,4.09,4.09,0,0,1,2.9,1.17,3.67,3.67,0,0,1,1.24,2.77v1.84a3.77,3.77,0,0,1-1.25,2.77,4.08,4.08,0,0,1-5.79,0A3.75,3.75,0,0,1,20.32,539.48Zm2.1,0a2.08,2.08,0,0,0,.6,1.46,1.87,1.87,0,0,0,1.44.65,1.89,1.89,0,0,0,1.43-.65,2.09,2.09,0,0,0,.61-1.46v-1.82a2,2,0,0,0-.61-1.43,1.92,1.92,0,0,0-1.43-.64,1.89,1.89,0,0,0-1.44.64,2,2,0,0,0-.6,1.43Z"/>
            <path className="cls-1" d="M30.46,547.1V534.5a.58.58,0,0,1,.29-.49,1.23,1.23,0,0,1,.71-.19,1.2,1.2,0,0,1,.7.19.58.58,0,0,1,.28.5v.73a3,3,0,0,1,2.68-1.54,3.32,3.32,0,0,1,2.55,1.16,4,4,0,0,1,1,2.78v1.84a4.07,4.07,0,0,1-1.06,2.77,3.19,3.19,0,0,1-2.46,1.21,2.92,2.92,0,0,1-1.59-.47,2.77,2.77,0,0,1-1-1.11v5.22a.6.6,0,0,1-.32.49,1.35,1.35,0,0,1-.76.21,1.21,1.21,0,0,1-.73-.21A.6.6,0,0,1,30.46,547.1Zm2.1-7.17a1.63,1.63,0,0,0,.61,1,1.93,1.93,0,0,0,1.41.61,1.9,1.9,0,0,0,1.42-.65,2.06,2.06,0,0,0,.62-1.46v-1.82a2,2,0,0,0-.62-1.43,2,2,0,0,0-1.46-.64,2,2,0,0,0-2,2.07Z"/>
            <path className="cls-1" d="M40.58,547.1V534.5a.58.58,0,0,1,.29-.49,1.23,1.23,0,0,1,.71-.19,1.2,1.2,0,0,1,.7.19.58.58,0,0,1,.28.5v.73a3,3,0,0,1,2.68-1.54,3.32,3.32,0,0,1,2.55,1.16,4,4,0,0,1,1.05,2.78v1.84a4.07,4.07,0,0,1-1.06,2.77,3.19,3.19,0,0,1-2.46,1.21,2.92,2.92,0,0,1-1.59-.47,2.77,2.77,0,0,1-1-1.11v5.22a.6.6,0,0,1-.32.49,1.35,1.35,0,0,1-.76.21,1.21,1.21,0,0,1-.73-.21A.6.6,0,0,1,40.58,547.1Zm2.1-7.17a1.63,1.63,0,0,0,.61,1,1.93,1.93,0,0,0,1.41.61,1.9,1.9,0,0,0,1.42-.65,2.06,2.06,0,0,0,.62-1.46v-1.82a2,2,0,0,0-.62-1.43,2,2,0,0,0-1.46-.64,2,2,0,0,0-2,2.07Z"/>
            <path className="cls-1" d="M50.3,539.48v-1.84a3.74,3.74,0,0,1,1.22-2.76,4,4,0,0,1,2.92-1.18,4.09,4.09,0,0,1,2.9,1.17,3.67,3.67,0,0,1,1.24,2.77v1.84a3.77,3.77,0,0,1-1.25,2.77,4.08,4.08,0,0,1-5.79,0A3.75,3.75,0,0,1,50.3,539.48Zm2.1,0a2.08,2.08,0,0,0,.6,1.46,1.91,1.91,0,0,0,2.87,0,2,2,0,0,0,.61-1.46v-1.82a2,2,0,0,0-.61-1.43,1.93,1.93,0,0,0-2.87,0,2,2,0,0,0-.6,1.43Z"/>
            <path className="cls-1" d="M60.42,542.6v-8.1a.58.58,0,0,1,.29-.49,1.28,1.28,0,0,1,.73-.19,1.16,1.16,0,0,1,.68.19.58.58,0,0,1,.28.5v.89a3.19,3.19,0,0,1,1-1.2,2.53,2.53,0,0,1,1.51-.5h.64a.73.73,0,0,1,.56.27,1,1,0,0,1,.24.66,1,1,0,0,1-.24.67.71.71,0,0,1-.56.28h-.64a2.2,2.2,0,0,0-1.69.82,3,3,0,0,0-.73,2.11v4.09a.61.61,0,0,1-.32.48,1.28,1.28,0,0,1-.76.22,1.15,1.15,0,0,1-.73-.22A.63.63,0,0,1,60.42,542.6Z"/>
            <path className="cls-1" d="M67.6,540.18v-10a.57.57,0,0,1,.32-.48,1.34,1.34,0,0,1,.72-.2,1.4,1.4,0,0,1,.73.2.56.56,0,0,1,.33.48v3.66h2.44a.51.51,0,0,1,.42.23,1,1,0,0,1,0,1.1.51.51,0,0,1-.42.23H69.7v4.8a1.37,1.37,0,0,0,.31,1,1.56,1.56,0,0,0,1.09.3h.7a.87.87,0,0,1,.66.26,1,1,0,0,1,0,1.28.87.87,0,0,1-.66.26h-.7Q67.6,543.3,67.6,540.18Z"/>
            <path className="cls-1" d="M74.12,539.52v-5a.6.6,0,0,1,.32-.49,1.31,1.31,0,0,1,.74-.21,1.28,1.28,0,0,1,.73.21.58.58,0,0,1,.31.49v5a2,2,0,0,0,.61,1.44,1.94,1.94,0,0,0,2.87,0,2,2,0,0,0,.6-1.45v-5a.56.56,0,0,1,.31-.48,1.4,1.4,0,0,1,.75-.2,1.28,1.28,0,0,1,.73.2.58.58,0,0,1,.29.48v5a3.74,3.74,0,0,1-1.22,2.76,4.17,4.17,0,0,1-5.81,0A3.72,3.72,0,0,1,74.12,539.52Z"/>
            <path className="cls-1" d="M84.5,542.6v-8.1a.58.58,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.11,1.11,0,0,1,.67.19.6.6,0,0,1,.27.5v.73a2.7,2.7,0,0,1,1-1.1,3.08,3.08,0,0,1,1.65-.44,3.18,3.18,0,0,1,2.45,1.16,4,4,0,0,1,1,2.78v5a.58.58,0,0,1-.31.51,1.39,1.39,0,0,1-.75.19,1.34,1.34,0,0,1-.72-.19.59.59,0,0,1-.32-.51v-5a2,2,0,0,0-.6-1.43,1.88,1.88,0,0,0-2.76-.07,2,2,0,0,0-.58,1.5v5a.61.61,0,0,1-.32.48,1.24,1.24,0,0,1-.74.22,1.2,1.2,0,0,1-.74-.22A.62.62,0,0,1,84.5,542.6Z"/>
            <path className="cls-1" d="M94.58,530.34a1,1,0,0,1,.36-.78,1.32,1.32,0,0,1,1.72,0,1,1,0,0,1,.36.78,1,1,0,0,1-.36.77,1.29,1.29,0,0,1-1.72,0A1,1,0,0,1,94.58,530.34Zm.18,12.26v-8.1a.58.58,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.36,1.36,0,0,1,.76.19.57.57,0,0,1,.3.49v8.1a.61.61,0,0,1-.31.48,1.25,1.25,0,0,1-.75.22,1.2,1.2,0,0,1-.74-.22A.62.62,0,0,1,94.76,542.6Z"/>
            <path className="cls-1" d="M99,540.18v-10a.57.57,0,0,1,.32-.48,1.34,1.34,0,0,1,.72-.2,1.4,1.4,0,0,1,.73.2.56.56,0,0,1,.33.48v3.66h2.44a.51.51,0,0,1,.42.23,1,1,0,0,1,0,1.1.51.51,0,0,1-.42.23h-2.44v4.8a1.37,1.37,0,0,0,.31,1,1.56,1.56,0,0,0,1.09.3h.7a.87.87,0,0,1,.66.26,1,1,0,0,1,0,1.28.87.87,0,0,1-.66.26h-.7C100.2,543.3,99,542.26,99,540.18Z"/>
            <path className="cls-1" d="M105.46,530.34a1,1,0,0,1,.36-.78,1.32,1.32,0,0,1,1.72,0,1,1,0,0,1,.36.78,1,1,0,0,1-.36.77,1.29,1.29,0,0,1-1.72,0A1,1,0,0,1,105.46,530.34Zm.18,12.26v-8.1a.58.58,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.36,1.36,0,0,1,.76.19.57.57,0,0,1,.3.49v8.1a.61.61,0,0,1-.31.48,1.25,1.25,0,0,1-.75.22,1.2,1.2,0,0,1-.74-.22A.62.62,0,0,1,105.64,542.6Z"/>
            <path className="cls-1" d="M109.58,539.48v-1.9a3.65,3.65,0,0,1,1.22-2.73,4.32,4.32,0,0,1,5.75-.11,3.36,3.36,0,0,1,1.17,2.63,3.06,3.06,0,0,1-.14,1,.89.89,0,0,1-.44.52,1.81,1.81,0,0,1-.56.2,4.2,4.2,0,0,1-.68,0h-4.22v.46a2,2,0,0,0,.72,1.61,2.9,2.9,0,0,0,1.88.59,3.35,3.35,0,0,0,1.55-.38,3.25,3.25,0,0,1,.89-.39.7.7,0,0,1,.58.33,1,1,0,0,1,.24.62c0,.33-.32.66-1,1a5.12,5.12,0,0,1-2.36.5,5,5,0,0,1-3.34-1.1A3.57,3.57,0,0,1,109.58,539.48Zm2.1-1.7H115a1,1,0,0,0,.59-.12.57.57,0,0,0,.15-.47,1.77,1.77,0,0,0-.58-1.34,2,2,0,0,0-1.44-.55,2.11,2.11,0,0,0-1.44.52,1.69,1.69,0,0,0-.6,1.33Z"/>
            <path className="cls-1" d="M118.82,541.86a1.12,1.12,0,0,1,.21-.62.61.61,0,0,1,.51-.32,1,1,0,0,1,.51.23,4.23,4.23,0,0,0,.87.48,3.36,3.36,0,0,0,1.3.23c1.15,0,1.72-.36,1.72-1.09a1,1,0,0,0-.5-.91,4.34,4.34,0,0,0-1.21-.53c-.48-.12-.94-.27-1.41-.43a2.67,2.67,0,0,1-1.2-.82,2.27,2.27,0,0,1-.5-1.51,2.76,2.76,0,0,1,.84-2,3.47,3.47,0,0,1,2.58-.85,5.91,5.91,0,0,1,2.07.35c.62.23.93.51.93.83a1.31,1.31,0,0,1-.23.65.66.66,0,0,1-.57.37,3.86,3.86,0,0,1-.82-.3,3.44,3.44,0,0,0-1.34-.3,1.77,1.77,0,0,0-1.21.36,1,1,0,0,0-.41.81.86.86,0,0,0,.5.76,4.79,4.79,0,0,0,1.22.46,12.29,12.29,0,0,1,1.42.45,2.65,2.65,0,0,1,1.19.9,2.69,2.69,0,0,1,.49,1.65,2.46,2.46,0,0,1-.92,2,4.07,4.07,0,0,1-2.6.74,5.45,5.45,0,0,1-2.43-.5C119.16,542.63,118.82,542.26,118.82,541.86Z"/>
            <path className="cls-1" d="M131.12,539.48v-1.84a3.74,3.74,0,0,1,1.22-2.76,4,4,0,0,1,2.92-1.18,4.09,4.09,0,0,1,2.9,1.17,3.67,3.67,0,0,1,1.24,2.77v1.84a3.77,3.77,0,0,1-1.25,2.77,4.08,4.08,0,0,1-5.79,0A3.75,3.75,0,0,1,131.12,539.48Zm2.1,0a2.08,2.08,0,0,0,.6,1.46,1.87,1.87,0,0,0,1.44.65,1.89,1.89,0,0,0,1.43-.65,2.09,2.09,0,0,0,.61-1.46v-1.82a2,2,0,0,0-.61-1.43,1.92,1.92,0,0,0-1.43-.64,1.89,1.89,0,0,0-1.44.64,2,2,0,0,0-.6,1.43Z"/>
            <path className="cls-1" d="M141.26,542.62V532a3.83,3.83,0,0,1,1-2.71,3.3,3.3,0,0,1,2.5-1.11h.92a.74.74,0,0,1,.6.27,1,1,0,0,1,0,1.26.74.74,0,0,1-.6.27h-.52a1.79,1.79,0,0,0-1.32.52,1.76,1.76,0,0,0-.52,1.3v2H146a.51.51,0,0,1,.42.23,1,1,0,0,1,0,1.1.51.51,0,0,1-.42.23h-2.6v7.24a.57.57,0,0,1-.32.49,1.43,1.43,0,0,1-.76.19,1.28,1.28,0,0,1-.73-.19A.58.58,0,0,1,141.26,542.62Z"/>
            <path className="cls-1" d="M151.2,540.82a2.3,2.3,0,0,1,1.2-2.23,7.66,7.66,0,0,1,3.48-.63h.88v-.37a2.54,2.54,0,0,0-.49-1.55,1.75,1.75,0,0,0-1.49-.66,3.26,3.26,0,0,0-1.51.33,5.36,5.36,0,0,1-.71.33.63.63,0,0,1-.55-.33,1.32,1.32,0,0,1-.21-.73c0-.35.32-.65,1-.9a5.65,5.65,0,0,1,2-.38,3.89,3.89,0,0,1,3.05,1.11,4,4,0,0,1,1,2.77v5a.6.6,0,0,1-.29.49,1.21,1.21,0,0,1-.73.21,1.08,1.08,0,0,1-.68-.21.59.59,0,0,1-.28-.49v-.7a3.94,3.94,0,0,1-3,1.56,2.74,2.74,0,0,1-1.91-.68A2.51,2.51,0,0,1,151.2,540.82Zm2.12-.26a1.25,1.25,0,0,0,.36,1,1.33,1.33,0,0,0,.94.33,2.1,2.1,0,0,0,1.45-.64,1.86,1.86,0,0,0,.69-1.36v-.74h-.5a11.54,11.54,0,0,0-1.47.08,2.1,2.1,0,0,0-1,.42A1.12,1.12,0,0,0,153.32,540.56Z"/>
            <path className="cls-1" d="M164.4,541.86a1.12,1.12,0,0,1,.21-.62.61.61,0,0,1,.51-.32,1,1,0,0,1,.51.23,4.23,4.23,0,0,0,.87.48,3.36,3.36,0,0,0,1.3.23c1.15,0,1.72-.36,1.72-1.09a1,1,0,0,0-.5-.91,4.34,4.34,0,0,0-1.21-.53c-.48-.12-.94-.27-1.41-.43a2.67,2.67,0,0,1-1.2-.82,2.27,2.27,0,0,1-.5-1.51,2.76,2.76,0,0,1,.84-2,3.47,3.47,0,0,1,2.58-.85,5.91,5.91,0,0,1,2.07.35c.62.23.93.51.93.83a1.31,1.31,0,0,1-.23.65.66.66,0,0,1-.57.37,3.86,3.86,0,0,1-.82-.3,3.44,3.44,0,0,0-1.34-.3,1.78,1.78,0,0,0-1.21.36,1,1,0,0,0-.41.81.84.84,0,0,0,.5.76,4.79,4.79,0,0,0,1.22.46,12.29,12.29,0,0,1,1.42.45,2.65,2.65,0,0,1,1.19.9,2.69,2.69,0,0,1,.49,1.65,2.46,2.46,0,0,1-.92,2,4.07,4.07,0,0,1-2.6.74,5.45,5.45,0,0,1-2.43-.5C164.74,542.63,164.4,542.26,164.4,541.86Z"/>
            <path className="cls-1" d="M173,539.52v-5a.6.6,0,0,1,.32-.49,1.31,1.31,0,0,1,.74-.21,1.28,1.28,0,0,1,.73.21.58.58,0,0,1,.31.49v5a2,2,0,0,0,.61,1.44,1.94,1.94,0,0,0,2.87,0,2,2,0,0,0,.6-1.45v-5a.56.56,0,0,1,.31-.48,1.4,1.4,0,0,1,.75-.2,1.28,1.28,0,0,1,.73.2.58.58,0,0,1,.29.48v5a3.74,3.74,0,0,1-1.22,2.76,4.17,4.17,0,0,1-5.81,0A3.72,3.72,0,0,1,173,539.52Z"/>
            <path className="cls-1" d="M182.78,541.86a1.12,1.12,0,0,1,.21-.62.61.61,0,0,1,.51-.32,1,1,0,0,1,.51.23,4.23,4.23,0,0,0,.87.48,3.36,3.36,0,0,0,1.3.23c1.15,0,1.72-.36,1.72-1.09a1,1,0,0,0-.5-.91,4.34,4.34,0,0,0-1.21-.53c-.48-.12-.94-.27-1.41-.43a2.67,2.67,0,0,1-1.2-.82,2.27,2.27,0,0,1-.5-1.51,2.76,2.76,0,0,1,.84-2,3.47,3.47,0,0,1,2.58-.85,5.91,5.91,0,0,1,2.07.35c.62.23.93.51.93.83a1.31,1.31,0,0,1-.23.65.66.66,0,0,1-.57.37,3.86,3.86,0,0,1-.82-.3,3.44,3.44,0,0,0-1.34-.3,1.77,1.77,0,0,0-1.21.36,1,1,0,0,0-.41.81.84.84,0,0,0,.5.76,4.79,4.79,0,0,0,1.22.46,12.29,12.29,0,0,1,1.42.45,2.65,2.65,0,0,1,1.19.9,2.69,2.69,0,0,1,.49,1.65,2.46,2.46,0,0,1-.92,2,4.08,4.08,0,0,1-2.6.74,5.45,5.45,0,0,1-2.43-.5C183.12,542.63,182.78,542.26,182.78,541.86Z"/>
            <path className="cls-1" d="M191.44,540.18v-10a.55.55,0,0,1,.32-.48,1.34,1.34,0,0,1,.72-.2,1.4,1.4,0,0,1,.73.2.56.56,0,0,1,.33.48v3.66H196a.51.51,0,0,1,.42.23,1,1,0,0,1,0,1.1.51.51,0,0,1-.42.23h-2.44v4.8a1.37,1.37,0,0,0,.31,1,1.54,1.54,0,0,0,1.09.3h.7a.87.87,0,0,1,.66.26,1,1,0,0,1,0,1.28.87.87,0,0,1-.66.26h-.7C192.6,543.3,191.44,542.26,191.44,540.18Z"/>
            <path className="cls-1" d="M197.44,540.82a2.3,2.3,0,0,1,1.2-2.23,7.66,7.66,0,0,1,3.48-.63H203v-.37a2.54,2.54,0,0,0-.49-1.55,1.75,1.75,0,0,0-1.49-.66,3.29,3.29,0,0,0-1.51.33,4.76,4.76,0,0,1-.71.33.63.63,0,0,1-.55-.33A1.32,1.32,0,0,1,198,535c0-.35.32-.65,1-.9a5.65,5.65,0,0,1,2.05-.38,3.89,3.89,0,0,1,3.05,1.11,4,4,0,0,1,1,2.77v5a.6.6,0,0,1-.29.49,1.21,1.21,0,0,1-.73.21,1.08,1.08,0,0,1-.68-.21.59.59,0,0,1-.28-.49v-.7a3.94,3.94,0,0,1-3,1.56,2.76,2.76,0,0,1-1.91-.68A2.51,2.51,0,0,1,197.44,540.82Zm2.12-.26a1.25,1.25,0,0,0,.36,1,1.33,1.33,0,0,0,.94.33,2.1,2.1,0,0,0,1.45-.64,1.86,1.86,0,0,0,.69-1.36v-.74h-.5a11.54,11.54,0,0,0-1.47.08,2.1,2.1,0,0,0-1,.42A1.12,1.12,0,0,0,199.56,540.56Z"/>
            <path className="cls-1" d="M207.06,530.34a1,1,0,0,1,.36-.78,1.32,1.32,0,0,1,1.72,0,1,1,0,0,1,.36.78,1,1,0,0,1-.36.77,1.29,1.29,0,0,1-1.72,0A1,1,0,0,1,207.06,530.34Zm.18,12.26v-8.1a.56.56,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.38,1.38,0,0,1,.76.19.57.57,0,0,1,.3.49v8.1a.61.61,0,0,1-.31.48,1.25,1.25,0,0,1-.75.22,1.2,1.2,0,0,1-.74-.22A.62.62,0,0,1,207.24,542.6Z"/>
            <path className="cls-1" d="M211.54,542.6v-8.1a.56.56,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.13,1.13,0,0,1,.67.19.6.6,0,0,1,.27.5v.73a2.7,2.7,0,0,1,1-1.1,3.08,3.08,0,0,1,1.65-.44,3.18,3.18,0,0,1,2.45,1.16,4.07,4.07,0,0,1,1,2.78v5a.58.58,0,0,1-.31.51,1.39,1.39,0,0,1-.75.19,1.34,1.34,0,0,1-.72-.19.57.57,0,0,1-.32-.51v-5a2,2,0,0,0-.6-1.43,1.88,1.88,0,0,0-2.76-.07,2,2,0,0,0-.58,1.5v5a.61.61,0,0,1-.32.48,1.24,1.24,0,0,1-.74.22,1.2,1.2,0,0,1-.74-.22A.62.62,0,0,1,211.54,542.6Z"/>
            <path className="cls-1" d="M221.2,540.82a2.3,2.3,0,0,1,1.2-2.23,7.66,7.66,0,0,1,3.48-.63h.88v-.37a2.54,2.54,0,0,0-.49-1.55,1.75,1.75,0,0,0-1.49-.66,3.26,3.26,0,0,0-1.51.33,5.36,5.36,0,0,1-.71.33.63.63,0,0,1-.55-.33,1.32,1.32,0,0,1-.21-.73c0-.35.32-.65,1-.9a5.65,5.65,0,0,1,2-.38,3.89,3.89,0,0,1,3.05,1.11,4,4,0,0,1,1,2.77v5a.6.6,0,0,1-.29.49,1.21,1.21,0,0,1-.73.21,1.08,1.08,0,0,1-.68-.21.59.59,0,0,1-.28-.49v-.7a3.94,3.94,0,0,1-3,1.56,2.74,2.74,0,0,1-1.91-.68A2.51,2.51,0,0,1,221.2,540.82Zm2.12-.26a1.25,1.25,0,0,0,.36,1,1.33,1.33,0,0,0,.94.33,2.1,2.1,0,0,0,1.45-.64,1.86,1.86,0,0,0,.69-1.36v-.74h-.5a11.54,11.54,0,0,0-1.47.08,2.1,2.1,0,0,0-1,.42A1.12,1.12,0,0,0,223.32,540.56Z"/>
            <path className="cls-1" d="M231,542.6V528.88a.57.57,0,0,1,.28-.49,1.27,1.27,0,0,1,.74-.19,1.44,1.44,0,0,1,.77.19.58.58,0,0,1,.31.49v6.36a2.88,2.88,0,0,1,2.58-1.54,3.31,3.31,0,0,1,2.53,1.16,4,4,0,0,1,1.05,2.78v1.84a4.12,4.12,0,0,1-1.06,2.77,3.15,3.15,0,0,1-4.12.75,2.87,2.87,0,0,1-1.1-1.12v.71a.63.63,0,0,1-.29.49,1.08,1.08,0,0,1-.67.22,1.15,1.15,0,0,1-.73-.22A.63.63,0,0,1,231,542.6Zm2.1-2.67a1.63,1.63,0,0,0,.63,1,2,2,0,0,0,1.41.61,1.86,1.86,0,0,0,1.42-.65,2.08,2.08,0,0,0,.6-1.46v-1.82a2,2,0,0,0-.61-1.43,1.92,1.92,0,0,0-1.43-.64,2,2,0,0,0-1.4.58,2,2,0,0,0-.62,1.49Z"/>
            <path className="cls-1" d="M241.3,542.6V528.86a.58.58,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.39,1.39,0,0,1,.75.19.58.58,0,0,1,.31.49V542.6a.62.62,0,0,1-.32.48,1.24,1.24,0,0,1-.74.22,1.18,1.18,0,0,1-.74-.22A.62.62,0,0,1,241.3,542.6Z"/>
            <path className="cls-1" d="M245.42,539.48v-1.9a3.65,3.65,0,0,1,1.22-2.73,4.32,4.32,0,0,1,5.75-.11,3.36,3.36,0,0,1,1.17,2.63,3.06,3.06,0,0,1-.14,1,.89.89,0,0,1-.44.52,1.81,1.81,0,0,1-.56.2,4.2,4.2,0,0,1-.68,0h-4.22v.46a2,2,0,0,0,.72,1.61,2.9,2.9,0,0,0,1.88.59,3.35,3.35,0,0,0,1.55-.38,3.25,3.25,0,0,1,.89-.39.7.7,0,0,1,.58.33,1,1,0,0,1,.24.62c0,.33-.32.66-1,1a5.12,5.12,0,0,1-2.36.5,5,5,0,0,1-3.34-1.1A3.57,3.57,0,0,1,245.42,539.48Zm2.1-1.7h3.32a1,1,0,0,0,.59-.12.57.57,0,0,0,.15-.47,1.77,1.77,0,0,0-.58-1.34,2,2,0,0,0-1.44-.55,2.11,2.11,0,0,0-1.44.52,1.69,1.69,0,0,0-.6,1.33Z"/>
            <path className="cls-1" d="M259.28,542.62V532a3.83,3.83,0,0,1,1-2.71,3.29,3.29,0,0,1,2.5-1.11h.92a.74.74,0,0,1,.6.27,1,1,0,0,1,0,1.26.74.74,0,0,1-.6.27h-.52a1.78,1.78,0,0,0-1.84,1.82v2H264a.51.51,0,0,1,.42.23,1,1,0,0,1,0,1.1.51.51,0,0,1-.42.23h-2.6v7.24a.57.57,0,0,1-.32.49,1.45,1.45,0,0,1-.76.19,1.26,1.26,0,0,1-.73-.19A.58.58,0,0,1,259.28,542.62Z"/>
            <path className="cls-1" d="M265.74,539.52v-5a.57.57,0,0,1,.32-.49,1.31,1.31,0,0,1,.74-.21,1.28,1.28,0,0,1,.73.21.61.61,0,0,1,.31.49v5a2,2,0,0,0,.61,1.44,1.89,1.89,0,0,0,1.43.64,1.92,1.92,0,0,0,1.44-.63,2,2,0,0,0,.6-1.45v-5a.56.56,0,0,1,.31-.48,1.35,1.35,0,0,1,.75-.2,1.3,1.3,0,0,1,.73.2.58.58,0,0,1,.29.48v5a3.74,3.74,0,0,1-1.22,2.76,4.17,4.17,0,0,1-5.81,0A3.72,3.72,0,0,1,265.74,539.52Z"/>
            <path className="cls-1" d="M276.1,540.18v-10a.55.55,0,0,1,.32-.48,1.34,1.34,0,0,1,.72-.2,1.43,1.43,0,0,1,.73.2.56.56,0,0,1,.33.48v3.66h2.44a.51.51,0,0,1,.42.23,1,1,0,0,1,0,1.1.51.51,0,0,1-.42.23H278.2v4.8a1.37,1.37,0,0,0,.31,1,1.54,1.54,0,0,0,1.09.3h.7a.87.87,0,0,1,.66.26,1,1,0,0,1,0,1.28.87.87,0,0,1-.66.26h-.7C277.26,543.3,276.1,542.26,276.1,540.18Z"/>
            <path className="cls-1" d="M282.62,539.52v-5a.57.57,0,0,1,.32-.49,1.31,1.31,0,0,1,.74-.21,1.28,1.28,0,0,1,.73.21.61.61,0,0,1,.31.49v5a2,2,0,0,0,.61,1.44,1.89,1.89,0,0,0,1.43.64,1.92,1.92,0,0,0,1.44-.63,2,2,0,0,0,.6-1.45v-5a.56.56,0,0,1,.31-.48,1.35,1.35,0,0,1,.75-.2,1.3,1.3,0,0,1,.73.2.58.58,0,0,1,.29.48v5a3.74,3.74,0,0,1-1.22,2.76,4.17,4.17,0,0,1-5.81,0A3.72,3.72,0,0,1,282.62,539.52Z"/>
            <path className="cls-1" d="M293,542.6v-8.1a.56.56,0,0,1,.29-.49,1.26,1.26,0,0,1,.73-.19,1.18,1.18,0,0,1,.68.19.61.61,0,0,1,.28.5v.89a3.1,3.1,0,0,1,1-1.2,2.53,2.53,0,0,1,1.51-.5h.64a.73.73,0,0,1,.56.27,1,1,0,0,1,.24.66,1,1,0,0,1-.24.67.71.71,0,0,1-.56.28h-.64a2.19,2.19,0,0,0-1.69.82,3,3,0,0,0-.73,2.11v4.09a.61.61,0,0,1-.32.48,1.31,1.31,0,0,1-.76.22,1.13,1.13,0,0,1-.73-.22A.6.6,0,0,1,293,542.6Z"/>
            <path className="cls-1" d="M299.6,539.48v-1.9a3.61,3.61,0,0,1,1.22-2.73,4.32,4.32,0,0,1,5.75-.11,3.36,3.36,0,0,1,1.17,2.63,3.06,3.06,0,0,1-.14,1,.92.92,0,0,1-.44.52,1.81,1.81,0,0,1-.56.2,4.27,4.27,0,0,1-.68,0H301.7v.46a2,2,0,0,0,.72,1.61,2.88,2.88,0,0,0,1.88.59,3.38,3.38,0,0,0,1.55-.38,3.12,3.12,0,0,1,.89-.39.71.71,0,0,1,.58.33,1,1,0,0,1,.24.62c0,.33-.32.66-1,1a5.12,5.12,0,0,1-2.36.5,5,5,0,0,1-3.34-1.1A3.57,3.57,0,0,1,299.6,539.48Zm2.1-1.7H305a1,1,0,0,0,.59-.12.57.57,0,0,0,.15-.47,1.8,1.8,0,0,0-.58-1.34,2,2,0,0,0-1.44-.55,2.11,2.11,0,0,0-1.44.52,1.69,1.69,0,0,0-.6,1.33Z"/>
            <path className="cls-1" d="M309,542.39a1,1,0,0,1,.35-.8,1.2,1.2,0,0,1,.86-.33,1.25,1.25,0,0,1,.86.33,1.06,1.06,0,0,1,.37.8,14.26,14.26,0,0,1-1,2.51.5.5,0,0,1-.48.28.93.93,0,0,1-.49-.14.44.44,0,0,1-.23-.39.43.43,0,0,1,.06-.24l.44-1A1,1,0,0,1,309,542.39Z"/>
            <path className="cls-1" d="M317.16,542.62V532a3.78,3.78,0,0,1,1-2.71,3.29,3.29,0,0,1,2.5-1.11h.92a.74.74,0,0,1,.6.27,1,1,0,0,1,0,1.26.74.74,0,0,1-.6.27h-.52a1.78,1.78,0,0,0-1.84,1.82v2h2.6a.51.51,0,0,1,.42.23,1,1,0,0,1,0,1.1.51.51,0,0,1-.42.23h-2.6v7.24a.57.57,0,0,1-.32.49,1.45,1.45,0,0,1-.76.19,1.26,1.26,0,0,1-.73-.19A.56.56,0,0,1,317.16,542.62Z"/>
            <path className="cls-1" d="M323.62,539.52v-5a.57.57,0,0,1,.32-.49,1.31,1.31,0,0,1,.74-.21,1.28,1.28,0,0,1,.73.21.61.61,0,0,1,.31.49v5a2,2,0,0,0,.61,1.44,1.89,1.89,0,0,0,1.43.64,1.92,1.92,0,0,0,1.44-.63,2,2,0,0,0,.6-1.45v-5a.56.56,0,0,1,.31-.48,1.35,1.35,0,0,1,.75-.2,1.3,1.3,0,0,1,.73.2.58.58,0,0,1,.29.48v5a3.74,3.74,0,0,1-1.22,2.76,4.17,4.17,0,0,1-5.81,0A3.72,3.72,0,0,1,323.62,539.52Z"/>
            <path className="cls-1" d="M334.18,542.6V528.86a.56.56,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.39,1.39,0,0,1,.75.19.58.58,0,0,1,.31.49V542.6a.62.62,0,0,1-.32.48,1.26,1.26,0,0,1-.74.22,1.18,1.18,0,0,1-.74-.22A.62.62,0,0,1,334.18,542.6Z"/>
            <path className="cls-1" d="M338.84,542.6V528.86a.56.56,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.39,1.39,0,0,1,.75.19.58.58,0,0,1,.31.49V542.6a.62.62,0,0,1-.32.48,1.26,1.26,0,0,1-.74.22,1.18,1.18,0,0,1-.74-.22A.62.62,0,0,1,338.84,542.6Z"/>
            <path className="cls-1" d="M346.94,539.48v-1.84a3.71,3.71,0,0,1,1.22-2.76,4,4,0,0,1,2.92-1.18,4.11,4.11,0,0,1,2.9,1.17,3.7,3.7,0,0,1,1.24,2.77v1.84a3.77,3.77,0,0,1-1.25,2.77,4.08,4.08,0,0,1-5.79,0A3.75,3.75,0,0,1,346.94,539.48Zm2.1,0a2.08,2.08,0,0,0,.6,1.46,1.87,1.87,0,0,0,1.44.65,1.89,1.89,0,0,0,1.43-.65,2.09,2.09,0,0,0,.61-1.46v-1.82a2,2,0,0,0-.61-1.43,1.92,1.92,0,0,0-1.43-.64,1.89,1.89,0,0,0-1.44.64,2,2,0,0,0-.6,1.43Z"/>
            <path className="cls-1" d="M357.08,542.62V532a3.78,3.78,0,0,1,1-2.71,3.29,3.29,0,0,1,2.5-1.11h.92a.74.74,0,0,1,.6.27,1,1,0,0,1,0,1.26.74.74,0,0,1-.6.27H361a1.78,1.78,0,0,0-1.84,1.82v2h2.6a.51.51,0,0,1,.42.23,1,1,0,0,1,0,1.1.51.51,0,0,1-.42.23h-2.6v7.24a.57.57,0,0,1-.32.49,1.45,1.45,0,0,1-.76.19,1.26,1.26,0,0,1-.73-.19A.56.56,0,0,1,357.08,542.62Z"/>
            <path className="cls-1" d="M367.64,547.1V534.5a.56.56,0,0,1,.29-.49,1.23,1.23,0,0,1,.71-.19,1.2,1.2,0,0,1,.7.19.61.61,0,0,1,.28.5v.73a3,3,0,0,1,1.07-1.1,3,3,0,0,1,1.61-.44,3.32,3.32,0,0,1,2.55,1.16,4,4,0,0,1,1,2.78v1.84a4.12,4.12,0,0,1-1.06,2.77,3.19,3.19,0,0,1-2.46,1.21,2.88,2.88,0,0,1-1.59-.47,2.77,2.77,0,0,1-1.05-1.11v5.22a.6.6,0,0,1-.32.49,1.38,1.38,0,0,1-.76.21,1.19,1.19,0,0,1-.73-.21A.58.58,0,0,1,367.64,547.1Zm2.1-7.17a1.63,1.63,0,0,0,.61,1,1.9,1.9,0,0,0,2.83,0,2.06,2.06,0,0,0,.62-1.46v-1.82a2,2,0,0,0-.62-1.43,2,2,0,0,0-1.46-.64,2,2,0,0,0-2,2.07Z"/>
            <path className="cls-1" d="M377.74,542.6v-8.1A.56.56,0,0,1,378,534a1.26,1.26,0,0,1,.73-.19,1.18,1.18,0,0,1,.68.19.61.61,0,0,1,.28.5v.89a3.1,3.1,0,0,1,1-1.2,2.53,2.53,0,0,1,1.51-.5h.64a.73.73,0,0,1,.56.27,1,1,0,0,1,.24.66,1,1,0,0,1-.24.67.71.71,0,0,1-.56.28h-.64a2.19,2.19,0,0,0-1.69.82,3,3,0,0,0-.73,2.11v4.09a.61.61,0,0,1-.32.48,1.31,1.31,0,0,1-.76.22,1.13,1.13,0,0,1-.73-.22A.6.6,0,0,1,377.74,542.6Z"/>
            <path className="cls-1" d="M384.32,539.48v-1.84a3.74,3.74,0,0,1,1.22-2.76,4,4,0,0,1,2.92-1.18,4.09,4.09,0,0,1,2.9,1.17,3.67,3.67,0,0,1,1.24,2.77v1.84a3.77,3.77,0,0,1-1.25,2.77,4.08,4.08,0,0,1-5.79,0A3.75,3.75,0,0,1,384.32,539.48Zm2.1,0a2.08,2.08,0,0,0,.6,1.46,1.87,1.87,0,0,0,1.44.65,1.89,1.89,0,0,0,1.43-.65,2.09,2.09,0,0,0,.61-1.46v-1.82a2,2,0,0,0-.61-1.43,1.92,1.92,0,0,0-1.43-.64,1.89,1.89,0,0,0-1.44.64,2,2,0,0,0-.6,1.43Z"/>
            <path className="cls-1" d="M394.44,542.6v-8.1a.58.58,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.14,1.14,0,0,1,.66.19.58.58,0,0,1,.28.5v.73a2.84,2.84,0,0,1,2.66-1.54,2.38,2.38,0,0,1,1.57.56,3.44,3.44,0,0,1,1.05,1.46,3.75,3.75,0,0,1,1.31-1.51,3.16,3.16,0,0,1,1.71-.51,3.3,3.3,0,0,1,2.45,1.07,3.94,3.94,0,0,1,1,2.87v5a.62.62,0,0,1-.32.48,1.24,1.24,0,0,1-.74.22,1.2,1.2,0,0,1-.72-.22.59.59,0,0,1-.32-.48v-5a2.07,2.07,0,0,0-.56-1.53,1.8,1.8,0,0,0-1.3-.56,1.84,1.84,0,0,0-1.31.56,2,2,0,0,0-.57,1.49v5a.54.54,0,0,1-.31.51,1.6,1.6,0,0,1-.75.17,1.53,1.53,0,0,1-.72-.17.55.55,0,0,1-.32-.51v-5a2,2,0,0,0-.55-1.47,1.73,1.73,0,0,0-1.31-.58,1.77,1.77,0,0,0-1.31.56,2,2,0,0,0-.55,1.51v5a.61.61,0,0,1-.32.48,1.24,1.24,0,0,1-.74.22,1.2,1.2,0,0,1-.74-.22A.62.62,0,0,1,394.44,542.6Z"/>
            <path className="cls-1" d="M410.14,530.34a1,1,0,0,1,.36-.78,1.32,1.32,0,0,1,1.72,0,1,1,0,0,1,.36.78,1,1,0,0,1-.36.77,1.29,1.29,0,0,1-1.72,0A1,1,0,0,1,410.14,530.34Zm.18,12.26v-8.1a.58.58,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.36,1.36,0,0,1,.76.19.57.57,0,0,1,.3.49v8.1a.61.61,0,0,1-.31.48,1.25,1.25,0,0,1-.75.22,1.2,1.2,0,0,1-.74-.22A.62.62,0,0,1,410.32,542.6Z"/>
            <path className="cls-1" d="M414,541.86a1.12,1.12,0,0,1,.21-.62.61.61,0,0,1,.51-.32,1,1,0,0,1,.51.23,4.23,4.23,0,0,0,.87.48,3.36,3.36,0,0,0,1.3.23c1.15,0,1.72-.36,1.72-1.09a1,1,0,0,0-.5-.91,4.34,4.34,0,0,0-1.21-.53c-.48-.12-.94-.27-1.41-.43a2.67,2.67,0,0,1-1.2-.82,2.27,2.27,0,0,1-.5-1.51,2.76,2.76,0,0,1,.84-2,3.47,3.47,0,0,1,2.58-.85,5.91,5.91,0,0,1,2.07.35c.62.23.93.51.93.83a1.31,1.31,0,0,1-.23.65.65.65,0,0,1-.57.37,3.86,3.86,0,0,1-.82-.3,3.44,3.44,0,0,0-1.34-.3,1.78,1.78,0,0,0-1.21.36,1,1,0,0,0-.41.81.86.86,0,0,0,.5.76,4.79,4.79,0,0,0,1.22.46,12.29,12.29,0,0,1,1.42.45,2.65,2.65,0,0,1,1.19.9,2.69,2.69,0,0,1,.49,1.65,2.46,2.46,0,0,1-.92,2,4.07,4.07,0,0,1-2.6.74A5.45,5.45,0,0,1,415,543C414.36,542.63,414,542.26,414,541.86Z"/>
            <path className="cls-1" d="M422.34,539.48v-1.9a3.65,3.65,0,0,1,1.22-2.73,4.32,4.32,0,0,1,5.75-.11,3.36,3.36,0,0,1,1.17,2.63,3.06,3.06,0,0,1-.14,1,.89.89,0,0,1-.44.52,1.81,1.81,0,0,1-.56.2,4.2,4.2,0,0,1-.68,0h-4.22v.46a2,2,0,0,0,.72,1.61,2.9,2.9,0,0,0,1.88.59,3.38,3.38,0,0,0,1.55-.38,3.25,3.25,0,0,1,.89-.39.71.71,0,0,1,.58.33,1,1,0,0,1,.24.62c0,.33-.32.66-1,1a5.12,5.12,0,0,1-2.36.5,5,5,0,0,1-3.34-1.1A3.57,3.57,0,0,1,422.34,539.48Zm2.1-1.7h3.32a1,1,0,0,0,.59-.12.57.57,0,0,0,.15-.47,1.77,1.77,0,0,0-.58-1.34,2,2,0,0,0-1.44-.55,2.11,2.11,0,0,0-1.44.52,1.69,1.69,0,0,0-.6,1.33Z"/>
            <path className="cls-1" d="M431.58,541.86a1.12,1.12,0,0,1,.21-.62.61.61,0,0,1,.51-.32,1,1,0,0,1,.51.23,4.23,4.23,0,0,0,.87.48,3.38,3.38,0,0,0,1.31.23c1.14,0,1.71-.36,1.71-1.09a1,1,0,0,0-.5-.91,4.21,4.21,0,0,0-1.21-.53c-.47-.12-.94-.27-1.41-.43a2.67,2.67,0,0,1-1.2-.82,2.27,2.27,0,0,1-.5-1.51,2.76,2.76,0,0,1,.84-2,3.47,3.47,0,0,1,2.58-.85,5.91,5.91,0,0,1,2.07.35c.62.23.93.51.93.83a1.31,1.31,0,0,1-.23.65.65.65,0,0,1-.57.37,4.08,4.08,0,0,1-.82-.3,3.4,3.4,0,0,0-1.34-.3,1.78,1.78,0,0,0-1.21.36,1.07,1.07,0,0,0-.41.81.86.86,0,0,0,.5.76,4.79,4.79,0,0,0,1.22.46,12.29,12.29,0,0,1,1.42.45,2.58,2.58,0,0,1,1.68,2.55,2.46,2.46,0,0,1-.92,2,4.07,4.07,0,0,1-2.6.74,5.45,5.45,0,0,1-2.43-.5C431.92,542.63,431.58,542.26,431.58,541.86Z"/>
            <path className="cls-1" d="M444.28,542.62V532a3.83,3.83,0,0,1,1-2.71,3.3,3.3,0,0,1,2.5-1.11h.92a.74.74,0,0,1,.6.27,1,1,0,0,1,0,1.26.74.74,0,0,1-.6.27h-.52a1.79,1.79,0,0,0-1.32.52,1.76,1.76,0,0,0-.52,1.3v2H449a.51.51,0,0,1,.42.23,1,1,0,0,1,0,1.1.51.51,0,0,1-.42.23h-2.6v7.24a.57.57,0,0,1-.32.49,1.43,1.43,0,0,1-.76.19,1.28,1.28,0,0,1-.73-.19A.58.58,0,0,1,444.28,542.62Z"/>
            <path className="cls-1" d="M450.18,539.48v-1.84a3.74,3.74,0,0,1,1.22-2.76,4,4,0,0,1,2.92-1.18,4.09,4.09,0,0,1,2.9,1.17,3.67,3.67,0,0,1,1.24,2.77v1.84a3.77,3.77,0,0,1-1.25,2.77,4.08,4.08,0,0,1-5.79,0A3.75,3.75,0,0,1,450.18,539.48Zm2.1,0a2.08,2.08,0,0,0,.6,1.46,1.91,1.91,0,0,0,2.87,0,2,2,0,0,0,.61-1.46v-1.82a2,2,0,0,0-.61-1.43,1.93,1.93,0,0,0-2.87,0,2,2,0,0,0-.6,1.43Z"/>
            <path className="cls-1" d="M460.3,542.6v-8.1a.58.58,0,0,1,.29-.49,1.28,1.28,0,0,1,.73-.19,1.16,1.16,0,0,1,.68.19.58.58,0,0,1,.28.5v.89a3.19,3.19,0,0,1,1-1.2,2.53,2.53,0,0,1,1.51-.5h.64a.73.73,0,0,1,.56.27,1,1,0,0,1,.24.66,1,1,0,0,1-.24.67.71.71,0,0,1-.56.28h-.64a2.2,2.2,0,0,0-1.69.82,3,3,0,0,0-.73,2.11v4.09a.61.61,0,0,1-.32.48,1.28,1.28,0,0,1-.76.22,1.15,1.15,0,0,1-.73-.22A.63.63,0,0,1,460.3,542.6Z"/>
            <path className="cls-1" d="M139.72,567.62a.72.72,0,0,1,.44-.62,1.76,1.76,0,0,1,.9-.26.62.62,0,0,1,.64.38l2.22,6.22,1.7-5.52a.67.67,0,0,1,.74-.46.68.68,0,0,1,.74.46l1.68,5.56,2.24-6.26a.57.57,0,0,1,.62-.38,1.81,1.81,0,0,1,.89.26.75.75,0,0,1,.45.64.76.76,0,0,1-.08.32l-3.1,8.12q-.17.42-.84.42c-.46,0-.74-.13-.86-.4l-1.74-5.16-1.72,5.1c-.12.31-.42.46-.9.46a.79.79,0,0,1-.84-.46L139.8,568A1,1,0,0,1,139.72,567.62Z"/>
            <path className="cls-1" d="M153.94,572.48v-1.9a3.61,3.61,0,0,1,1.22-2.73,4.32,4.32,0,0,1,5.75-.11,3.36,3.36,0,0,1,1.17,2.63,3.06,3.06,0,0,1-.14,1,.92.92,0,0,1-.44.52,1.81,1.81,0,0,1-.56.2,4.27,4.27,0,0,1-.68,0H156v.46a2,2,0,0,0,.72,1.61,2.88,2.88,0,0,0,1.88.59,3.38,3.38,0,0,0,1.55-.38,3.12,3.12,0,0,1,.89-.39.71.71,0,0,1,.58.33,1,1,0,0,1,.24.62c0,.33-.32.66-1,1a5.12,5.12,0,0,1-2.36.5,5,5,0,0,1-3.34-1.1A3.57,3.57,0,0,1,153.94,572.48Zm2.1-1.7h3.32a1,1,0,0,0,.59-.12.57.57,0,0,0,.15-.47,1.8,1.8,0,0,0-.58-1.34,2,2,0,0,0-1.44-.55,2.11,2.11,0,0,0-1.44.52,1.69,1.69,0,0,0-.6,1.33Z"/>
            <path className="cls-1" d="M164,575.6V561.86a.56.56,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.39,1.39,0,0,1,.75.19.58.58,0,0,1,.31.49V575.6a.62.62,0,0,1-.32.48,1.26,1.26,0,0,1-.74.22,1.18,1.18,0,0,1-.74-.22A.62.62,0,0,1,164,575.6Z"/>
            <path className="cls-1" d="M168.62,575.6V561.86a.56.56,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.39,1.39,0,0,1,.75.19.58.58,0,0,1,.31.49V575.6a.62.62,0,0,1-.32.48,1.26,1.26,0,0,1-.74.22,1.18,1.18,0,0,1-.74-.22A.62.62,0,0,1,168.62,575.6Z"/>
            <path className="cls-1" d="M173.12,575.6V561.88a.57.57,0,0,1,.28-.49,1.25,1.25,0,0,1,.74-.19,1.44,1.44,0,0,1,.77.19.58.58,0,0,1,.31.49v6.36a2.88,2.88,0,0,1,2.58-1.54,3.31,3.31,0,0,1,2.53,1.16,4,4,0,0,1,1,2.78v1.84a4.12,4.12,0,0,1-1.06,2.77,3.15,3.15,0,0,1-4.12.75,2.87,2.87,0,0,1-1.1-1.12v.71a.63.63,0,0,1-.29.49,1.08,1.08,0,0,1-.67.22,1.13,1.13,0,0,1-.73-.22A.6.6,0,0,1,173.12,575.6Zm2.1-2.67a1.63,1.63,0,0,0,.63,1,2,2,0,0,0,1.41.61,1.86,1.86,0,0,0,1.42-.65,2.08,2.08,0,0,0,.6-1.46v-1.82a2,2,0,0,0-.61-1.43,1.92,1.92,0,0,0-1.43-.64,2,2,0,0,0-1.4.58,1.91,1.91,0,0,0-.62,1.49Z"/>
            <path className="cls-1" d="M182.86,572.48v-1.9a3.61,3.61,0,0,1,1.22-2.73,4.32,4.32,0,0,1,5.75-.11,3.36,3.36,0,0,1,1.17,2.63,3.06,3.06,0,0,1-.14,1,.92.92,0,0,1-.44.52,1.81,1.81,0,0,1-.56.2,4.27,4.27,0,0,1-.68,0H185v.46a2,2,0,0,0,.72,1.61,2.9,2.9,0,0,0,1.88.59,3.38,3.38,0,0,0,1.55-.38A3.12,3.12,0,0,1,190,574a.71.71,0,0,1,.58.33,1,1,0,0,1,.24.62c0,.33-.32.66-1,1a5.12,5.12,0,0,1-2.36.5,5,5,0,0,1-3.34-1.1A3.57,3.57,0,0,1,182.86,572.48Zm2.1-1.7h3.32a1,1,0,0,0,.59-.12.57.57,0,0,0,.15-.47,1.77,1.77,0,0,0-.58-1.34,2,2,0,0,0-1.44-.55,2.11,2.11,0,0,0-1.44.52,1.69,1.69,0,0,0-.6,1.33Z"/>
            <path className="cls-1" d="M192.52,563.34a1,1,0,0,1,.36-.78,1.25,1.25,0,0,1,.86-.32,1.27,1.27,0,0,1,.86.32,1,1,0,0,1,.36.78,1,1,0,0,1-.36.77,1.23,1.23,0,0,1-.86.33,1.22,1.22,0,0,1-.86-.33A1,1,0,0,1,192.52,563.34Zm.18,12.26v-8.1A.56.56,0,0,1,193,567a1.3,1.3,0,0,1,.75-.19,1.38,1.38,0,0,1,.76.19.57.57,0,0,1,.3.49v8.1a.63.63,0,0,1-.31.48,1.25,1.25,0,0,1-.75.22,1.18,1.18,0,0,1-.74-.22A.62.62,0,0,1,192.7,575.6Z"/>
            <path className="cls-1" d="M197,575.6v-8.1a.56.56,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.13,1.13,0,0,1,.67.19.6.6,0,0,1,.27.5v.73a2.64,2.64,0,0,1,1-1.1,3.08,3.08,0,0,1,1.65-.44,3.2,3.2,0,0,1,2.45,1.16,4.07,4.07,0,0,1,1,2.78v5a.6.6,0,0,1-.31.51,1.39,1.39,0,0,1-.75.19,1.34,1.34,0,0,1-.72-.19.57.57,0,0,1-.32-.51v-5a2,2,0,0,0-.6-1.43,1.88,1.88,0,0,0-1.42-.64,1.84,1.84,0,0,0-1.34.57,2,2,0,0,0-.58,1.5v5a.61.61,0,0,1-.32.48,1.26,1.26,0,0,1-.74.22,1.18,1.18,0,0,1-.74-.22A.62.62,0,0,1,197,575.6Z"/>
            <path className="cls-1" d="M206.54,578.09a2.35,2.35,0,0,1,1.56-2.17,1.37,1.37,0,0,1-.88-1.33,1.84,1.84,0,0,1,.94-1.53,3,3,0,0,1-1.2-2.52V570a3,3,0,0,1,1-2.36,3.88,3.88,0,0,1,2.69-.92,3.84,3.84,0,0,1,2.32.68c.38-.89.82-1.34,1.3-1.34a.88.88,0,0,1,.62.23.68.68,0,0,1,.24.49.57.57,0,0,1-.34.5,2.29,2.29,0,0,0-1,.8,3,3,0,0,1,.64,1.92v.56a3.07,3.07,0,0,1-1.06,2.38,4,4,0,0,1-2.72.94,3.7,3.7,0,0,1-1.38-.24.69.69,0,0,0-.38.56c0,.27.21.47.63.59a8.65,8.65,0,0,0,1.52.28c.59.07,1.18.17,1.78.3a3,3,0,0,1,1.52.82,2.2,2.2,0,0,1,.63,1.65,2.47,2.47,0,0,1-1.19,2.23,5.58,5.58,0,0,1-3,.75,5.78,5.78,0,0,1-3-.74A2.21,2.21,0,0,1,206.54,578.09Zm2-.23a1.35,1.35,0,0,0,.63,1.17,2.88,2.88,0,0,0,1.63.43,2.71,2.71,0,0,0,1.58-.42,1.36,1.36,0,0,0,.6-1.16,1.16,1.16,0,0,0-.28-.78,1.79,1.79,0,0,0-.83-.46,6.7,6.7,0,0,0-.94-.2,10.33,10.33,0,0,0-1-.09C209,576.68,208.52,577.19,208.52,577.86Zm.48-7.34a2.16,2.16,0,0,0,.46,1.44,1.69,1.69,0,0,0,2.5,0,2.19,2.19,0,0,0,.46-1.43V570a2.13,2.13,0,0,0-.46-1.39,1.55,1.55,0,0,0-1.26-.55,1.51,1.51,0,0,0-1.24.55A2.08,2.08,0,0,0,209,570Z"/>
            <path className="cls-1" d="M220.34,563.34a1,1,0,0,1,.36-.78,1.25,1.25,0,0,1,.86-.32,1.27,1.27,0,0,1,.86.32,1,1,0,0,1,.36.78,1,1,0,0,1-.36.77,1.23,1.23,0,0,1-.86.33,1.22,1.22,0,0,1-.86-.33A1,1,0,0,1,220.34,563.34Zm.18,12.26v-8.1a.56.56,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.38,1.38,0,0,1,.76.19.57.57,0,0,1,.3.49v8.1a.63.63,0,0,1-.31.48,1.25,1.25,0,0,1-.75.22,1.18,1.18,0,0,1-.74-.22A.62.62,0,0,1,220.52,575.6Z"/>
            <path className="cls-1" d="M224.82,575.6v-8.1a.56.56,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.13,1.13,0,0,1,.67.19.6.6,0,0,1,.27.5v.73a2.64,2.64,0,0,1,1-1.1,3.08,3.08,0,0,1,1.65-.44,3.2,3.2,0,0,1,2.45,1.16,4.07,4.07,0,0,1,1,2.78v5a.6.6,0,0,1-.31.51,1.39,1.39,0,0,1-.75.19,1.34,1.34,0,0,1-.72-.19.57.57,0,0,1-.32-.51v-5a2,2,0,0,0-.6-1.43,1.88,1.88,0,0,0-1.42-.64,1.84,1.84,0,0,0-1.34.57,2,2,0,0,0-.58,1.5v5a.61.61,0,0,1-.32.48,1.26,1.26,0,0,1-.74.22,1.18,1.18,0,0,1-.74-.22A.62.62,0,0,1,224.82,575.6Z"/>
            <path className="cls-1" d="M239.06,573.18v-10a.55.55,0,0,1,.32-.48,1.34,1.34,0,0,1,.72-.2,1.43,1.43,0,0,1,.73.2.56.56,0,0,1,.33.48v3.66h2.44a.51.51,0,0,1,.42.23,1,1,0,0,1,0,1.1.51.51,0,0,1-.42.23h-2.44v4.8a1.37,1.37,0,0,0,.31,1,1.54,1.54,0,0,0,1.09.3h.7a.87.87,0,0,1,.66.26,1,1,0,0,1,0,1.28.87.87,0,0,1-.66.26h-.7C240.22,576.3,239.06,575.26,239.06,573.18Z"/>
            <path className="cls-1" d="M245.68,575.6V561.88a.56.56,0,0,1,.29-.49,1.3,1.3,0,0,1,.75-.19,1.39,1.39,0,0,1,.75.19.58.58,0,0,1,.31.49v6.36a2.73,2.73,0,0,1,1-1.1,2.81,2.81,0,0,1,1.57-.44,3.2,3.2,0,0,1,2.45,1.16,4.07,4.07,0,0,1,1,2.78v5a.59.59,0,0,1-.32.51,1.41,1.41,0,0,1-.74.19,1.34,1.34,0,0,1-.72-.19.57.57,0,0,1-.32-.51v-5a2,2,0,0,0-.6-1.43,1.88,1.88,0,0,0-1.42-.64,1.84,1.84,0,0,0-1.34.57,2,2,0,0,0-.58,1.5v5a.61.61,0,0,1-.32.48,1.26,1.26,0,0,1-.74.22,1.18,1.18,0,0,1-.74-.22A.62.62,0,0,1,245.68,575.6Z"/>
            <path className="cls-1" d="M255.58,572.48v-1.9a3.61,3.61,0,0,1,1.22-2.73,4.32,4.32,0,0,1,5.75-.11,3.36,3.36,0,0,1,1.17,2.63,3.06,3.06,0,0,1-.14,1,.92.92,0,0,1-.44.52,1.81,1.81,0,0,1-.56.2,4.27,4.27,0,0,1-.68,0h-4.22v.46a2,2,0,0,0,.72,1.61,2.88,2.88,0,0,0,1.88.59,3.38,3.38,0,0,0,1.55-.38,3.12,3.12,0,0,1,.89-.39.71.71,0,0,1,.58.33,1,1,0,0,1,.24.62c0,.33-.32.66-1,1a5.12,5.12,0,0,1-2.36.5,5,5,0,0,1-3.34-1.1A3.57,3.57,0,0,1,255.58,572.48Zm2.1-1.7H261a1,1,0,0,0,.59-.12.57.57,0,0,0,.15-.47,1.8,1.8,0,0,0-.58-1.34,2,2,0,0,0-1.44-.55,2.11,2.11,0,0,0-1.44.52,1.69,1.69,0,0,0-.6,1.33Z"/>
            <path className="cls-1" d="M269.58,575.78V561.54a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.82.82,0,0,1,.47.14.43.43,0,0,1,.19.34v6.72a2.91,2.91,0,0,1,1.05-1.1,2.94,2.94,0,0,1,1.61-.44,3.39,3.39,0,0,1,2.53,1.1,3.68,3.68,0,0,1,1.05,2.64v2.18a3.8,3.8,0,0,1-1.07,2.65,3.29,3.29,0,0,1-2.47,1.15,3.1,3.1,0,0,1-1.66-.46,2.65,2.65,0,0,1-1.08-1.1v.89a.49.49,0,0,1-.18.37.63.63,0,0,1-.44.16.64.64,0,0,1-.45-.16A.47.47,0,0,1,269.58,575.78Zm1.3-2.53a1.92,1.92,0,0,0,.75,1.25,2.4,2.4,0,0,0,1.71.72,2.3,2.3,0,0,0,1.74-.8,2.56,2.56,0,0,0,.74-1.79v-2.16a2.47,2.47,0,0,0-.74-1.75,2.36,2.36,0,0,0-1.76-.78,2.46,2.46,0,0,0-2.44,2.53Z"/>
            <path className="cls-1" d="M279.26,563.79a.77.77,0,0,1-.26-.59.73.73,0,0,1,.26-.58.89.89,0,0,1,.64-.24.85.85,0,0,1,.61.24.79.79,0,0,1,.25.58.83.83,0,0,1-.25.59.82.82,0,0,1-.61.25A.86.86,0,0,1,279.26,563.79Zm0,12v-8.46a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.82.82,0,0,1,.47.14.43.43,0,0,1,.19.34v8.46a.48.48,0,0,1-.2.36.72.72,0,0,1-.46.16.64.64,0,0,1-.45-.16A.47.47,0,0,1,279.26,575.78Z"/>
            <path className="cls-1" d="M282.7,572.64v-2.18a3.57,3.57,0,0,1,1.12-2.63,3.63,3.63,0,0,1,2.66-1.11,3.76,3.76,0,0,1,2.68,1.1,3.53,3.53,0,0,1,1.14,2.64v2.18a3.93,3.93,0,0,1-3.82,3.8,3.6,3.6,0,0,1-2.64-1.15A3.66,3.66,0,0,1,282.7,572.64Zm1.3,0a2.54,2.54,0,0,0,.73,1.8,2.28,2.28,0,0,0,1.75.79,2.36,2.36,0,0,0,1.78-.79,2.55,2.55,0,0,0,.74-1.8v-2.16a2.44,2.44,0,0,0-.75-1.75,2.38,2.38,0,0,0-1.77-.78,2.3,2.3,0,0,0-1.75.78,2.46,2.46,0,0,0-.73,1.75Z"/>
            <path className="cls-1" d="M291.84,575a.65.65,0,0,1,.14-.4.4.4,0,0,1,.32-.2,1,1,0,0,1,.44.23,4.08,4.08,0,0,0,.86.47,3.38,3.38,0,0,0,1.34.24,2.49,2.49,0,0,0,1.56-.4,1.27,1.27,0,0,0,.5-1,1.43,1.43,0,0,0-.51-1.15,3.44,3.44,0,0,0-1.23-.65l-1.45-.44a2.72,2.72,0,0,1-1.24-.77,2.09,2.09,0,0,1-.51-1.47,2.64,2.64,0,0,1,.79-1.91,3.15,3.15,0,0,1,2.37-.81,5.25,5.25,0,0,1,1.83.31c.56.21.85.43.85.67a.88.88,0,0,1-.16.44.47.47,0,0,1-.38.24,5.24,5.24,0,0,1-.74-.28,3.58,3.58,0,0,0-1.38-.28,2.12,2.12,0,0,0-1.5.47,1.4,1.4,0,0,0-.5,1.06,1.12,1.12,0,0,0,.51,1,4.9,4.9,0,0,0,1.24.57l1.47.43a2.37,2.37,0,0,1,1.24.87,2.69,2.69,0,0,1,.5,1.68,2.32,2.32,0,0,1-.85,1.89,3.68,3.68,0,0,1-2.37.69,4.84,4.84,0,0,1-2.23-.47C292.14,575.66,291.84,575.33,291.84,575Z"/>
            <path className="cls-1" d="M300.14,580.44V567.32a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.72.72,0,0,1,.44.14.44.44,0,0,1,.18.35v.93a3,3,0,0,1,1.06-1.09,2.9,2.9,0,0,1,1.62-.45,3.43,3.43,0,0,1,2.54,1.1,3.69,3.69,0,0,1,1.06,2.64v2.18a3.8,3.8,0,0,1-1.07,2.65,3.29,3.29,0,0,1-2.47,1.15,3,3,0,0,1-1.62-.46,2.77,2.77,0,0,1-1.08-1.1v5.56a.48.48,0,0,1-.19.34.71.71,0,0,1-.47.16.64.64,0,0,1-.45-.16A.45.45,0,0,1,300.14,580.44Zm1.3-7.19a1.92,1.92,0,0,0,.75,1.25,2.4,2.4,0,0,0,1.71.72,2.29,2.29,0,0,0,1.73-.8,2.53,2.53,0,0,0,.75-1.79v-2.16a2.44,2.44,0,0,0-.75-1.75,2.38,2.38,0,0,0-1.77-.78,2.34,2.34,0,0,0-1.69.7,2.39,2.39,0,0,0-.73,1.83Z"/>
            <path className="cls-1" d="M309.82,575.78V561.54a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.82.82,0,0,1,.47.14.43.43,0,0,1,.19.34v6.72a2.82,2.82,0,0,1,1-1.09,2.87,2.87,0,0,1,1.61-.45,3.34,3.34,0,0,1,2.49,1.1,3.68,3.68,0,0,1,1.05,2.64v5.32a.46.46,0,0,1-.19.38.82.82,0,0,1-.47.14.77.77,0,0,1-.44-.14.42.42,0,0,1-.2-.38v-5.31a2.49,2.49,0,0,0-.74-1.75,2.34,2.34,0,0,0-1.74-.78,2.32,2.32,0,0,0-1.67.7,2.4,2.4,0,0,0-.73,1.83v5.31a.48.48,0,0,1-.2.36.72.72,0,0,1-.46.16.64.64,0,0,1-.45-.16A.47.47,0,0,1,309.82,575.78Z"/>
            <path className="cls-1" d="M319.38,572.64V570.4a3.48,3.48,0,0,1,1.12-2.59,3.7,3.7,0,0,1,2.7-1.09,3.74,3.74,0,0,1,2.63,1,3.43,3.43,0,0,1,1.11,2.6,2.68,2.68,0,0,1-.13.9.84.84,0,0,1-.42.48,2.37,2.37,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0h-4.52v.74a2.32,2.32,0,0,0,.85,1.89,3.29,3.29,0,0,0,2.19.71,3.33,3.33,0,0,0,1.19-.2,3.47,3.47,0,0,0,.78-.4.84.84,0,0,1,.43-.2.51.51,0,0,1,.39.21.69.69,0,0,1,.17.41c0,.28-.29.57-.86.87a4.61,4.61,0,0,1-2.14.45,4.53,4.53,0,0,1-3.1-1.05A3.48,3.48,0,0,1,319.38,572.64Zm1.3-1.64h4a1.62,1.62,0,0,0,.8-.13c.13-.08.2-.27.2-.55a2.47,2.47,0,0,0-.71-1.74,2.34,2.34,0,0,0-1.79-.76,2.41,2.41,0,0,0-1.77.72,2.27,2.27,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M328.9,575.78v-8.46a.42.42,0,0,1,.18-.34.74.74,0,0,1,.46-.14.72.72,0,0,1,.44.14.44.44,0,0,1,.18.35v1a3.08,3.08,0,0,1,1.06-1.13,2.83,2.83,0,0,1,1.62-.47h.7a.48.48,0,0,1,.36.18.66.66,0,0,1,0,.86.48.48,0,0,1-.36.18h-.7a2.5,2.5,0,0,0-1.84.81,2.83,2.83,0,0,0-.8,2.1v4.93a.5.5,0,0,1-.19.36.71.71,0,0,1-.47.16.64.64,0,0,1-.45-.16A.47.47,0,0,1,328.9,575.78Z"/>
            <path className="cls-1" d="M334.8,572.64V570.4a3.48,3.48,0,0,1,1.12-2.59,3.7,3.7,0,0,1,2.7-1.09,3.74,3.74,0,0,1,2.63,1,3.43,3.43,0,0,1,1.11,2.6,2.68,2.68,0,0,1-.13.9.84.84,0,0,1-.42.48,2.37,2.37,0,0,1-.53.18,3.85,3.85,0,0,1-.66,0H336.1v.74a2.32,2.32,0,0,0,.85,1.89,3.29,3.29,0,0,0,2.19.71,3.33,3.33,0,0,0,1.19-.2,3.47,3.47,0,0,0,.78-.4.84.84,0,0,1,.43-.2.51.51,0,0,1,.39.21.69.69,0,0,1,.17.41c0,.28-.29.57-.86.87a4.61,4.61,0,0,1-2.14.45,4.53,4.53,0,0,1-3.1-1.05A3.48,3.48,0,0,1,334.8,572.64Zm1.3-1.64h4a1.62,1.62,0,0,0,.8-.13c.13-.08.2-.27.2-.55a2.47,2.47,0,0,0-.71-1.74,2.34,2.34,0,0,0-1.79-.76,2.41,2.41,0,0,0-1.77.72,2.27,2.27,0,0,0-.73,1.7Z"/>
            <path className="cls-1" d="M343.86,575.43a1,1,0,0,1,.25-.72.82.82,0,0,1,.64-.29.8.8,0,0,1,.61.3,1.07,1.07,0,0,1,.26.71,1,1,0,0,1-.26.69.8.8,0,0,1-.61.3.82.82,0,0,1-.64-.29A1,1,0,0,1,343.86,575.43Z"/>
          </g>
        </g>
      </svg>
    </div>
  )
}

export default EndingContent
