import React from 'react'
import './SeerriLogo.css'

const SeerriLogo = () => {
  return (
    <div className="logo-container" >
      <img src={process.env.PUBLIC_URL + "/assets/seerri-logo.svg"} alt="Seerri Logo" />
    </div>
  )
}

export default SeerriLogo
