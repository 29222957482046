import React, { useEffect, useRef } from 'react'
import './EndingPage.css'
//import anime from 'animejs'

import EndingLogo from '../../Components/EndingLogo/EndingLogo'
import EndingContent from '../../Components/EndingContent/EndingContent'

const EndingPage = () => {

  //const pathAnimationRef = useRef()

  /* useEffect(() => {
    pathAnimationRef.current = anime({
      targets: '.seerri-content path',
      easing: 'easeInOutCubic',
      duration: 2000,
      begin: function(anim) {},
      complete: function(anim) {},
      autoplay: true
    })
  }) */

  return (
    <div id="ending-container" >
      <div className="ending-logo-container">
        <EndingLogo></EndingLogo>
      </div>
      <div className="ending-content-container" >
        <EndingContent></EndingContent>
      </div>
    </div>
  )
}

export default EndingPage
