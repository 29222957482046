import React, { Suspense, useEffect, useState, useCallback } from 'react'
import { Canvas } from '@react-three/fiber'
import { OrbitControls } from '@react-three/drei'
import { useSpring } from '@react-spring/three'
// import { DeviceOrientationControls } from 'three/examples/jsm/controls/DeviceOrientationControls'

import Shapes from '../Model/IntroShapes/Shapes'
import SeerriProgress from '../Components/SeerriProgress/SeerriProgress'
import FloatingHtml from '../Components/FloatingHtml/FloatingHtml'
import LoadingIndicator from '../Components/LoadingIndicator/LoadingIndicator'

//extend({ DeviceOrientationControls }) // this produces <deviceOrientationControls />

/*const Controls = () => {

  const cameraRef = useRef()
  const { camera, gl } = useThree()

  useFrame((state) => {
    cameraRef.current.update()
  })

  return (
    <deviceOrientationControls ref={cameraRef} args={[ camera, gl.domElement ]} />
  )
}*/

const IntroShapesPage = () => {

  const [isRotating, ] = useState(false)

  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    setIsMobile(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent))
  }, [])

  const [mouse, api] = useSpring(() => ({ mouseX: 0, mouseY: 0 }))

  const onMouseMove = useCallback(({ clientX: x, clientY: y }) => {
    api.start({
      mouseX: x - window.innerWidth / 2,
      mouseY: y - window.innerHeight / 2 // mouse movement is now relative to the center of the container
    })
  }, [api])

  const cameraDevicePosition = isMobile ? [0,0,2] : [0,0,1]

  return (
    <div onMouseMove={onMouseMove} >
      <Canvas camera={{ position: cameraDevicePosition, zoom: 1 }} id="canvas" >
        <ambientLight intensity={0.5} />
        <Suspense fallback={<LoadingIndicator />}>          
          <Shapes mouse={mouse} isMobile={isMobile} ></Shapes>
          <FloatingHtml style={{ width: "100px", position: "absolute", left: "-50px", top: "50px" }} displayText={true} >
            <SeerriProgress></SeerriProgress>
          </FloatingHtml>
        </Suspense>
          {/* isMobile && <Controls /> */}
          { /*!isMobile &&*/ <OrbitControls 
            autoRotate={isRotating} 
            minPolarAngle={Math.PI * 0.25} 
            maxPolarAngle={Math.PI * 0.75}
            minAzimuthAngle={Math.PI * -0.75}
            maxAzimuthAngle={Math.PI * 0.75}
            minZoom={250}
            maxZoom={450}
          /> }
      </Canvas>
    </div>
  )
}

export default IntroShapesPage
