import React, { useEffect, useMemo, useRef } from 'react'
import { useFrame } from "@react-three/fiber";
import { config, useSpring } from '@react-spring/three'

const FloatingLine = ({ refArray, refArrayOrder, displayLine }) => {

  const lineRef = useRef()
  const refArrayFixed = useMemo(() => [], [])

  const [spring, springAPI] = useSpring(() => ({ opacity: 0, config: config.default }))

  useEffect(() => {

    springAPI.start({ opacity: displayLine ? 1 : 0 })
    
  }, [springAPI, displayLine])

  useEffect(() => {
    // preparing material to use opacity
    lineRef.current.material.transparent = true
    lineRef.current.material.depthWrite = false

    refArrayFixed.length = 0
    refArrayOrder.forEach((v) => {
      const found = refArray.current.find((e) => e.name === v)
      if (found) refArrayFixed.push(found)
    })
        
  }, [refArray, refArrayOrder, refArrayFixed])

  useFrame(() => {
    
    const vertices = refArrayFixed.map((el) => {
      return el.position
    })
  
    lineRef.current.geometry.setFromPoints(vertices)
    lineRef.current.frustumCulled = false

    lineRef.current.material.opacity = spring.opacity.get()
  })

  return (
    <line ref={lineRef} linewidth={1} linecap={'round'} linejoin={'round'} >
      <lineBasicMaterial attach="material" color={'#215567'} />
    </line>
  )
}

export default FloatingLine