import { useMemo, useState } from 'react'

const useThreeHoverCallback = (pointerOverCallback, pointerOutCallback) => {

  const [hovered, setHovered] = useState();
  
  const eventHandlers = useMemo(() => ({
    onPointerOver(e) {
      e.stopPropagation()
      setHovered(true);
      pointerOverCallback(e)
      // console.log('pointer over', e)
    },
    onPointerOut(e) {
      e.stopPropagation()
      setHovered(false);
      pointerOutCallback(e)
      // console.log('pointer out', e)
    }
  }), [pointerOverCallback, pointerOutCallback]);
  
  return [hovered, eventHandlers]
}

export default useThreeHoverCallback
