import './FloatingHtml.css'
import React from 'react'
import {  Html } from '@react-three/drei'

/** <Html style={{ pointerEvents: "none" }}> */

const FloatingHtml = ({ children, style = { width: "190px"}, displayText, isMobile = false }) => {

  return (
    <Html >
      <div style={{ ...style, pointerEvents: !isMobile ? "none" : "auto" }} className={`responsive-font fade ${displayText ? 'fade-in' : 'fade-out'}`} >
        {children}
      </div>
    </Html>
  )
}

export default FloatingHtml