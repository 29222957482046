/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useState, useCallback, useEffect } from 'react'
import { useFrame } from '@react-three/fiber'
import { useGLTF } from '@react-three/drei'
import { a, to, useSpring, config, useChain, useSpringRef } from '@react-spring/three'

import useThreeHoverCallback from '../../utils/hooks/useThreeHoverCallback'
import FloatingHtml from '../../Components/FloatingHtml/FloatingHtml'
import useRefsArray from '../../utils/hooks/useRefsArray'
import FloatingLine from '../../Components/FloatingLine/FloatingLine'
import IntroStates from '../../State/IntroStates'

export default function Model({ mouse, isMobile }) {

  // deconstruct to apply mouse movement to indicidual pieces
  const { mouseX, mouseY } = mouse

  //managing app state
  const useIntroStates = IntroStates()

  const group = useRef()
  const { nodes, materials } = useGLTF('/intro-shapes/shapes.gltf')

  // Preparing materials
  materials['SVGMat'].transparent = true
  materials['SVGMat.001'].transparent = true
  materials['SVGMat.002'].transparent = true
  materials['SVGMat.003'].transparent = true

  materials['SVGMat'].depthWrite = false
  materials['SVGMat.001'].depthWrite = false
  materials['SVGMat.002'].depthWrite = false
  materials['SVGMat.003'].depthWrite = false

  //preparing spring effect
  const [springMat, springAPI] = useSpring(() => ({ opacity: 1, config: config.slow }))
  const [springMat1, springAPI1] = useSpring(() => ({ opacity: 1, config: config.slow }))
  const [springMat2, springAPI2] = useSpring(() => ({ opacity: 1, config: config.slow }))
  const [springMat3, springAPI3] = useSpring(() => ({ opacity: 1, config: config.slow }))

  //creating text handling state variables
  const [displaySVGMatTxt, setdisplaySVGMatTxt] = useState(false)
  const [displaySVGMatTxt1, setdisplaySVGMatTxt1] = useState(false)
  const [displaySVGMatTxt2, setdisplaySVGMatTxt2] = useState(false)
  const [displaySVGMatTxt3, setdisplaySVGMatTxt3] = useState(false)

  //creating the line component data resources
  const [refArray, refArrayFunction] = useRefsArray()
  const refsArrayOrder = ["Curve", "Curve.004", "Curve.005", "Curve.001", "Curve.002"]

  const [refArray1, refArrayFunction1] = useRefsArray()
  const refsArrayOrder1 = ["Curve.020", "Curve.015", "Curve.007", "Curve.012", "Curve.011"]

  const [refArray2, refArrayFunction2] = useRefsArray()
  const refsArrayOrder2 = ["Curve.047", "Curve.045", "Curve.044", "Curve.046"]

  const [refArray3, refArrayFunction3] = useRefsArray()
  const refsArrayOrder3 = ["Curve.052", "Curve.049", "Curve.042", "Curve.053", "Curve.051", "Curve.050", "Curve.054"]

  //grouping mesh structures
  const complexityMeshNames = ["Curve", "Curve.001", "Curve.002", "Curve.004", "Curve.005"]
  const interdependenciesMeshNames = [ "Curve.007", "Curve.011", "Curve.012", "Curve.015", "Curve.016", "Curve.017", "Curve.018", "Curve.020"]
  const specializationMeshNames = ["Curve.044", "Curve.045", "Curve.046", "Curve.047", "Curve.048"]
  const inequalitiesMeshNames = ["Curve.042", "Curve.049", "Curve.050", "Curve.051", "Curve.052", "Curve.053", "Curve.054"]

  //creating hover effect callback
  const pointerOverCallback = (e) => {

    // disabling hover function on mobile devices
    if (isMobile) {

      if (lastTouchedMeshesOnMobile.current === null) return
      if (!lastTouchedMeshesOnMobile.current.includes(e.eventObject.name)) return
      
    }

    if (e.eventObject.material.name === "SVGMat" 
        && !useIntroStates.topicsViewed.complexity
        && !useIntroStates.topicsHidden.complexity) {

      // hide all other pieces
      springAPI1.start({ opacity: 0 })
      springAPI2.start({ opacity: 0 })
      springAPI3.start({ opacity: 0 })

      // display the line and text
      setdisplaySVGMatTxt(true)

      // display cursor
      document.body.style.cursor = "pointer"
    } 

    if (e.eventObject.material.name === "SVGMat.001" 
        && !useIntroStates.topicsViewed.interdependencies
        && !useIntroStates.topicsHidden.interdependencies) {
      springAPI.start({ opacity: 0 })
      springAPI2.start({ opacity: 0 })
      springAPI3.start({ opacity: 0 })

      setdisplaySVGMatTxt1(true)

      document.body.style.cursor = "pointer"
    }

    if (e.eventObject.material.name === "SVGMat.002" 
        && !useIntroStates.topicsViewed.specialization
        && !useIntroStates.topicsHidden.specialization) {
      springAPI.start({ opacity: 0 })
      springAPI1.start({ opacity: 0 })
      springAPI3.start({ opacity: 0 })

      setdisplaySVGMatTxt2(true)

      document.body.style.cursor = "pointer"
    }

    if (e.eventObject.material.name === "SVGMat.003" 
        && !useIntroStates.topicsViewed.inequalities
        && !useIntroStates.topicsHidden.inequalities) {
      springAPI.start({ opacity: 0 })
      springAPI1.start({ opacity: 0 })
      springAPI2.start({ opacity: 0 })

      setdisplaySVGMatTxt3(true)

      document.body.style.cursor = "pointer"
    }
  }

  const pointerOutCallback = (e) => {

    // disabling hover function on mobile devices
    if (isMobile) return

    // make cursor go back to normal without worring about the pieces
    document.body.style.cursor = "auto"
    
    if (e.eventObject.material.name === "SVGMat") {

      // make all other pieces visible again
      springAPI1.start({ opacity: 1 })
      springAPI2.start({ opacity: 1 })
      springAPI3.start({ opacity: 1 })

      // hide text and lines
      setdisplaySVGMatTxt(false)
    } 

    if (e.eventObject.material.name === "SVGMat.001") {
      springAPI.start({ opacity: 1 })
      springAPI2.start({ opacity: 1 })
      springAPI3.start({ opacity: 1 })

      setdisplaySVGMatTxt1(false)
    }

    if (e.eventObject.material.name === "SVGMat.002") {
      springAPI.start({ opacity: 1 })
      springAPI1.start({ opacity: 1 })
      springAPI3.start({ opacity: 1 })

      setdisplaySVGMatTxt2(false)
    }

    if (e.eventObject.material.name === "SVGMat.003") {
      springAPI.start({ opacity: 1 })
      springAPI1.start({ opacity: 1 })
      springAPI2.start({ opacity: 1 })

      setdisplaySVGMatTxt3(false)
    }   
  }

  //creating event handlers
  const [,eventHandlers] = useThreeHoverCallback(pointerOverCallback, pointerOutCallback)

  //preparing navigation
  let history = window.appHistory

  //hold last touched mesh for mobile state handling
  const lastTouchedMeshesOnMobile = useRef(null)

  //navigating to other pages
  const openTopicPage = useCallback(e => {
    e.stopPropagation()

    // handling touch states when on mobile devices to disable hover effects when a topic is selected
    if (isMobile) {

      if (!lastTouchedMeshesOnMobile.current) {

        if (complexityMeshNames.includes(e.eventObject.name)) lastTouchedMeshesOnMobile.current = complexityMeshNames
        if (interdependenciesMeshNames.includes(e.eventObject.name)) lastTouchedMeshesOnMobile.current = interdependenciesMeshNames
        if (specializationMeshNames.includes(e.eventObject.name)) lastTouchedMeshesOnMobile.current = specializationMeshNames
        if (inequalitiesMeshNames.includes(e.eventObject.name)) lastTouchedMeshesOnMobile.current = inequalitiesMeshNames

        pointerOverCallback(e)
        //useIntroStates.toggleFirstTouch()

        return

      } else {

        if (!lastTouchedMeshesOnMobile.current.includes(e.eventObject.name)) return
        
        pointerOutCallback(e)
        lastTouchedMeshesOnMobile.current = null
        //useIntroStates.toggleFirstTouch()

      }

    }

    
    // validating which mesh was touched to load its corresponding page
    if (complexityMeshNames.includes(e.eventObject.name) && !useIntroStates.topicsHidden.complexity) {
      history.push('/complexity-is-everywhere')
    } 

    if (interdependenciesMeshNames.includes(e.eventObject.name) && !useIntroStates.topicsHidden.interdependencies) {
      history.push('/interdependencies')
    } 

    if (specializationMeshNames.includes(e.eventObject.name) && !useIntroStates.topicsHidden.specialization) {
      history.push('/smart-specialization-strategies')
    } 

    if (inequalitiesMeshNames.includes(e.eventObject.name) && !useIntroStates.topicsHidden.inequalities) {
      history.push('/social-inequalities')
    } 
    
  }, [history, useIntroStates, isMobile])

  //refs to hold the spring effects to hide pieces after a page has been seen
  const springRef = useSpringRef()
  const springRef1 = useSpringRef()
  const springRef2 = useSpringRef()
  const springRef3 = useSpringRef()
  const springEndingRef = useSpringRef()

  // state to trigger a component re-render
  const [topic1, setTopic1] = useState(false)
  const [topic2, setTopic2] = useState(false)
  const [topic3, setTopic3] = useState(false)
  const [topic4, setTopic4] = useState(false)

  // springs with the animations to be applied
  const spring = useSpring({ opacity: topic1 ? 0 : 1, ref: springRef, 
    onRest: () => {
      useIntroStates.hideTopic("complexity") 
    } })

  const spring1 = useSpring({ opacity: topic2 ? 0 : 1, ref: springRef1, 
    onRest: () => { 
      useIntroStates.hideTopic("interdependencies") 
    } })

  const spring2 = useSpring({ opacity: topic3 ? 0 : 1, ref: springRef2, 
    onRest: () => { 
      useIntroStates.hideTopic("specialization") 
    } })

  const spring3 = useSpring({ opacity: topic4 ? 0 : 1, ref: springRef3, 
    onRest: () => { 
      useIntroStates.hideTopic("inequalities") 
    } })

  // this spring will load the ending page
  const springLoadEnding = useSpring({ from: { opacity: 0 }, to: { opacity: 1 }, ref: springEndingRef, 
    onRest: () => {
      console.log('useIntroStates', useIntroStates)

      if (useIntroStates.topicsViewed.complexity &&
        useIntroStates.topicsViewed.interdependencies &&
        useIntroStates.topicsViewed.specialization &&
        useIntroStates.topicsViewed.inequalities) {
  
          history.push('/ending')
  
      }
      
    } })

  // chaining the animations
  useChain([ springRef3, springRef2, springRef, springRef1, springEndingRef ], [1, 1.5, 2, 2.5, 3])

  //animate material changes
  useFrame((state) => {

    if (useIntroStates.topicsViewed.complexity && !useIntroStates.topicsHidden.complexity) {
      // this spring is controlled by useChain
      materials['SVGMat'].opacity = spring.opacity.get()
    } else if (!useIntroStates.topicsHidden.complexity) {
      // this spring is controlled on mouse over/out
      materials['SVGMat'].opacity = springMat.opacity.get()
    }

    if (useIntroStates.topicsViewed.interdependencies && !useIntroStates.topicsHidden.interdependencies) {
      materials['SVGMat.001'].opacity = spring1.opacity.get()
    } else if (!useIntroStates.topicsHidden.interdependencies) {
      materials['SVGMat.001'].opacity = springMat1.opacity.get()
    }

    if (useIntroStates.topicsViewed.specialization && !useIntroStates.topicsHidden.specialization) {
      materials['SVGMat.002'].opacity = spring2.opacity.get()
    } else if (!useIntroStates.topicsHidden.specialization) {
      materials['SVGMat.002'].opacity = springMat2.opacity.get()
    }

    if (useIntroStates.topicsViewed.inequalities && !useIntroStates.topicsHidden.inequalities) {
      materials['SVGMat.003'].opacity = spring3.opacity.get()
    } else if (!useIntroStates.topicsHidden.inequalities) {
      materials['SVGMat.003'].opacity = springMat3.opacity.get()
    }

  })

  // listening to variable changes and forzing react components to re-render
  useEffect(() => {

    // forzing react component to re-render
    setTopic1(useIntroStates.topicsViewed.complexity)
    setTopic2(useIntroStates.topicsViewed.interdependencies)
    setTopic3(useIntroStates.topicsViewed.specialization)
    setTopic4(useIntroStates.topicsViewed.inequalities)

  }, [topic1, topic2, topic3, topic4, useIntroStates])

  return (
    <group ref={group} dispose={null}>
      <a.group 
        position={ to([mouseX, mouseY], (mx, my) => {
            return [
              - mx / 10000 + 0.61, //the 1500 represents a value that softens the movement, for the x axis mx should be negative
              my / 10000 + -0.19, //the 1500 represents a value that softens the movement, y axis is already inverted
              0.1
            ]
          }) }
        scale={0.08}
      >
        <mesh
          geometry={nodes.Curve006.geometry}
          material={nodes.Curve006.material}
          position={[-0.19, 0.22, 0]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={119.02}
        />
        <mesh
          geometry={nodes.Curve022.geometry}
          material={nodes.Curve022.material}
          position={[-0.13, 0.1, 0]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={119.02}
        />
        <mesh
          geometry={nodes.Curve023.geometry}
          material={nodes.Curve023.material}
          position={[-0.03, 0.19, 0]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={119.02}
        />
        <mesh
          geometry={nodes.Curve024.geometry}
          material={nodes.Curve024.material}
          position={[-0.16, 0.39, 0]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={119.02}
        />
        <mesh
          geometry={nodes.Curve025.geometry}
          material={nodes.Curve025.material}
          position={[-0.45, 0.24, 0]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={119.02}
        />
        <mesh
          geometry={nodes.Curve026.geometry}
          material={nodes.Curve026.material}
          position={[-0.03, 0.38, 0]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={119.02}
        />
        <mesh
          geometry={nodes.Curve027.geometry}
          material={nodes.Curve027.material}
          position={[0.07, -0.07, 0]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={119.02}
        />
        <mesh
          geometry={nodes.Curve028.geometry}
          material={nodes.Curve028.material}
          position={[-0.17, -0.14, 0]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={119.02}
        />
        <mesh
          geometry={nodes.Curve029.geometry}
          material={nodes.Curve029.material}
          position={[0.15, 0.1, 0]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={119.02}
        />
        <mesh
          geometry={nodes.Curve030.geometry}
          material={nodes.Curve030.material}
          position={[0.06, -0.2, 0]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={119.02}
        />
        <mesh
          geometry={nodes.Curve031.geometry}
          material={nodes.Curve031.material}
          position={[0.23, -0.25, 0]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={119.02}
        />
        <mesh
          geometry={nodes.Curve032.geometry}
          material={nodes.Curve032.material}
          position={[0.33, -0.06, 0]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={119.02}
        />
        <mesh
          geometry={nodes.Curve033.geometry}
          material={nodes.Curve033.material}
          position={[0.05, -0.42, 0]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={119.02}
        />
        <mesh
          geometry={nodes.Curve034.geometry}
          material={nodes.Curve034.material}
          position={[-0.22, 0.06, 0]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={119.02}
        />
        <mesh
          geometry={nodes.Curve035.geometry}
          material={nodes.Curve035.material}
          position={[-0.43, -0.15, 0]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={119.02}
        />
        <mesh
          geometry={nodes.Curve036.geometry}
          material={nodes.Curve036.material}
          position={[-0.1, -0.57, 0]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={119.02}
        />
        <mesh
          geometry={nodes.Curve037.geometry}
          material={nodes.Curve037.material}
          position={[0.27, -0.5, 0]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={119.02}
        />
        <mesh
          geometry={nodes.Curve038.geometry}
          material={nodes.Curve038.material}
          position={[0.24, 0.28, 0]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={119.02}
        />
        <mesh
          geometry={nodes.Curve039.geometry}
          material={nodes.Curve039.material}
          position={[-0.3, 0.58, 0]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={119.02}
        />
        <mesh
          geometry={nodes.Curve040.geometry}
          material={nodes.Curve040.material}
          position={[0.48, 0.48, 0]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={119.02}
        />
        <mesh
          geometry={nodes.Curve041.geometry}
          material={nodes.Curve041.material}
          position={[-0.1, 0.62, 0]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={119.02}
        />
      </a.group>
      <group>
        <a.mesh
          geometry={nodes.Curve.geometry}
          material={nodes.Curve.material}
          position={ to([mouseX, mouseY], (mx, my) => {
            return [
              - mx / 12500 + 1.12, //the 1500 represents a value that softens the movement, for the x axis mx should be negative
              my / 12500 + -0.53, //the 1500 represents a value that softens the movement, y axis is already inverted
              -0.16
            ]
          }) }
          rotation={[Math.PI / 2, 0, 0]}
          scale={10}
          {...eventHandlers}
          ref={refArrayFunction}
          name="Curve"
          onClick={openTopicPage}
        >
          <FloatingHtml isMobile={isMobile} 
            style={{ fontWeight: "500", textAlign: "right", width: "170px", position: "absolute", left: "-240px" }} 
            displayText={displaySVGMatTxt}
          >
            Uncertainty is real
          </FloatingHtml>
        </a.mesh>
        <a.mesh
          geometry={nodes.Curve001.geometry}
          material={nodes.Curve001.material}
          position={ to([mouseX, mouseY], (mx, my) => {
            return [
              - mx / 15000 + -1.05, //the 1500 represents a value that softens the movement, for the x axis mx should be negative
              my / 15000 + -0.02, //the 1500 represents a value that softens the movement, y axis is already inverted
              -0.79
            ]
          }) }
          rotation={[Math.PI / 2, 0, 0]}
          scale={10}
          {...eventHandlers}
          ref={refArrayFunction}
          name="Curve.001"
          onClick={openTopicPage}
        >
          <FloatingHtml isMobile={isMobile} 
            style={{ fontWeight: "500", textAlign: "right", width: "210px", position: "absolute", left: "-350px", top: "-30px" }} 
            displayText={displaySVGMatTxt}
          >
            Multiple trade-offs are entangled
          </FloatingHtml>
        </a.mesh>
        <a.mesh
          geometry={nodes.Curve002.geometry}
          material={nodes.Curve002.material}
          position={ to([mouseX, mouseY], (mx, my) => {
            return [
              - mx / 10000 + -0.56, //the 1500 represents a value that softens the movement, for the x axis mx should be negative
              my / 10000 + -0.47, //the 1500 represents a value that softens the movement, y axis is already inverted
              0.03
            ]
          }) }
          rotation={[Math.PI / 2, 0, 0]}
          scale={10}
          {...eventHandlers}
          ref={refArrayFunction}
          name="Curve.002"
          onClick={openTopicPage}
        >
          <FloatingHtml isMobile={isMobile} 
            style={{ fontWeight: "500", textAlign: "right", width: "210px", position: "absolute", left: "-260px", top: "-10px" }} 
            displayText={displaySVGMatTxt}
          >
            Do we have a compass?
          </FloatingHtml>
        </a.mesh>
        <a.mesh
          geometry={nodes.Curve003.geometry}
          material={nodes.Curve003.material}
          position={ to([mouseX, mouseY], (mx, my) => {
            return [
              - mx / 13000 + -0.65, //the 1500 represents a value that softens the movement, for the x axis mx should be negative
              my / 13000 + -0.32, //the 1500 represents a value that softens the movement, y axis is already inverted
              -0.39
            ]
          }) }
          rotation={[Math.PI / 2, 0, 0]}
          scale={10}
          {...eventHandlers}
        />
        <a.mesh
          geometry={nodes.Curve004.geometry}
          material={nodes.Curve004.material}
          position={ to([mouseX, mouseY], (mx, my) => {
            return [
              - mx / 12000 + 0.35, //the 1500 represents a value that softens the movement, for the x axis mx should be negative
              my / 12000 + 0.18, //the 1500 represents a value that softens the movement, y axis is already inverted
              -0.03
            ]
          }) }
          rotation={[Math.PI / 2, 0, 0]}
          scale={10}
          {...eventHandlers}
          ref={refArrayFunction}
          name="Curve.004"
          onClick={openTopicPage}
        >
          <FloatingHtml isMobile={isMobile} 
            style={{ fontWeight: "500", width: "290px", position: "absolute", left: "0px", top: "20px" }} 
            displayText={displaySVGMatTxt}
          >
            Trust in institutions is challenged
          </FloatingHtml>
        </a.mesh>
        <a.mesh
          geometry={nodes.Curve005.geometry}
          material={nodes.Curve005.material}
          position={ to([mouseX, mouseY], (mx, my) => {
            return [
              - mx / 14000 + -0.37, //the 1500 represents a value that softens the movement, for the x axis mx should be negative
              my / 14000 + 0.05, //the 1500 represents a value that softens the movement, y axis is already inverted
              -0.18
            ]
          }) }
          rotation={[Math.PI / 2, 0, 0]}
          scale={10}
          {...eventHandlers}
          ref={refArrayFunction}
          name="Curve.005"
          onClick={openTopicPage}
        >
          <FloatingHtml isMobile={isMobile} 
            style={{ fontWeight: "500", width: "240px", position: "absolute", left: "-100px", top: "-50px" }} 
            displayText={displaySVGMatTxt}
          >
            We cannot isolate contexts
          </FloatingHtml>
        </a.mesh>
        <FloatingLine refArray={refArray} refArrayOrder={refsArrayOrder} displayLine={displaySVGMatTxt} ></FloatingLine>
      </group>
      <group>
        <a.mesh
          geometry={nodes.Curve007.geometry}
          material={nodes.Curve007.material}
          position={ to([mouseX, mouseY], (mx, my) => {
            return [
              - mx / 13500 + 0.41, //the 1500 represents a value that softens the movement, for the x axis mx should be negative
              my / 13500 + 0.5, //the 1500 represents a value that softens the movement, y axis is already inverted
              -0.44
            ]
          }) }
          rotation={[Math.PI / 2, 0, 0]}
          scale={10}
          {...eventHandlers}
          ref={refArrayFunction1}
          name="Curve.007"
          onClick={openTopicPage}
        >
          <FloatingHtml isMobile={isMobile} 
            style={{ fontWeight: "500", textAlign: "right", width: "280px", position: "absolute", left: "-330px", top: "-10px" }} 
            displayText={displaySVGMatTxt1}
          >
            Communities working together
          </FloatingHtml>
        </a.mesh>
        <a.mesh
          geometry={nodes.Curve008.geometry}
          material={nodes.Curve008.material}
          position={ to([mouseX, mouseY], (mx, my) => {
            return [
              - mx / 10000 + 0.43, //the 1500 represents a value that softens the movement, for the x axis mx should be negative
              my / 10000 + 0.36, //the 1500 represents a value that softens the movement, y axis is already inverted
              -0.15
            ]
          }) }
          rotation={[Math.PI / 2, 0, 0]}
          scale={10}
          {...eventHandlers}
        />
        <a.mesh
          geometry={nodes.Curve009.geometry}
          material={nodes.Curve009.material}
          position={ to([mouseX, mouseY], (mx, my) => {
            return [
              - mx / 10000 + 0.45, //the 1500 represents a value that softens the movement, for the x axis mx should be negative
              my / 10000 + 0.35, //the 1500 represents a value that softens the movement, y axis is already inverted
              -0.15
            ]
          }) }
          rotation={[Math.PI / 2, 0, 0]}
          scale={10}
          {...eventHandlers}
        />
        <a.mesh
          geometry={nodes.Curve010.geometry}
          material={nodes.Curve010.material}
          position={ to([mouseX, mouseY], (mx, my) => {
            return [
              - mx / 10000 + 0.42, //the 1500 represents a value that softens the movement, for the x axis mx should be negative
              my / 10000 + 0.37, //the 1500 represents a value that softens the movement, y axis is already inverted
              -0.15
            ]
          }) }
          rotation={[Math.PI / 2, 0, 0]}
          scale={10}
          {...eventHandlers}
        />
        <a.mesh
          geometry={nodes.Curve011.geometry}
          material={nodes.Curve011.material}
          position={ to([mouseX, mouseY], (mx, my) => {
            return [
              - mx / 13000 + -0.6, //the 1500 represents a value that softens the movement, for the x axis mx should be negative
              my / 13000 + -0.49, //the 1500 represents a value that softens the movement, y axis is already inverted
              -0.21
            ]
          }) }
          rotation={[Math.PI / 2, 0, 0]}
          scale={10}
          {...eventHandlers}
          ref={refArrayFunction1}
          name="Curve.011"
          onClick={openTopicPage}
        >
          <FloatingHtml isMobile={isMobile} 
            style={{ fontWeight: "500", textAlign: "right", width: "150px", position: "absolute", left: "-230px", top: "-20px" }} 
            displayText={displaySVGMatTxt1}
          >
            Mutual learning
          </FloatingHtml>
        </a.mesh>
        <a.mesh
          geometry={nodes.Curve012.geometry}
          material={nodes.Curve012.material}
          position={ to([mouseX, mouseY], (mx, my) => {
            return [
              - mx / 12000 + -0.98, //the 1500 represents a value that softens the movement, for the x axis mx should be negative
              my / 12000 + 0.14, //the 1500 represents a value that softens the movement, y axis is already inverted
              0.13
            ]
          }) }
          rotation={[Math.PI / 2, 0, 0]}
          scale={10}
          {...eventHandlers}
          ref={refArrayFunction1}
          name="Curve.012"
          onClick={openTopicPage}
        >
          <FloatingHtml isMobile={isMobile} 
            style={{ fontWeight: "500", width: "280px", position: "absolute", left: "0px", top: "10px" }} 
            displayText={displaySVGMatTxt1}
          >
            Wellbeing within the biosphere
          </FloatingHtml>
        </a.mesh>
        <a.mesh
          geometry={nodes.Curve013.geometry}
          material={nodes.Curve013.material}
          position={ to([mouseX, mouseY], (mx, my) => {
            return [
              - mx / 13800 + -0.87, //the 1500 represents a value that softens the movement, for the x axis mx should be negative
              my / 13800 + 0.29, //the 1500 represents a value that softens the movement, y axis is already inverted
              -0.15
            ]
          }) }
          rotation={[Math.PI / 2, 0, 0]}
          scale={10}
          {...eventHandlers}
        />
        <a.mesh
          geometry={nodes.Curve014.geometry}
          material={nodes.Curve014.material}
          position={ to([mouseX, mouseY], (mx, my) => {
            return [
              - mx / 13200 + -0.89, //the 1500 represents a value that softens the movement, for the x axis mx should be negative
              my / 12300 + 0.29, //the 1500 represents a value that softens the movement, y axis is already inverted
              -0.11
            ]
          }) }
          rotation={[Math.PI / 2, 0, 0]}
          scale={10}
          {...eventHandlers}
        />
        <a.mesh
          geometry={nodes.Curve015.geometry}
          material={nodes.Curve015.material}
          position={ to([mouseX, mouseY], (mx, my) => {
            return [
              - mx / 13000 + 0.66, //the 1500 represents a value that softens the movement, for the x axis mx should be negative
              my / 13000 + -0.07, //the 1500 represents a value that softens the movement, y axis is already inverted
              0
            ]
          }) }
          rotation={[Math.PI / 2, 0, 0]}
          scale={10}
          {...eventHandlers}
          ref={refArrayFunction1}
          name="Curve.015"
          onClick={openTopicPage}
        >
          <FloatingHtml isMobile={isMobile} 
            style={{ fontWeight: "500", width: "170px", position: "absolute", left: "-200px", top: "-50px" }} 
            displayText={displaySVGMatTxt1}
          >
            Transformative drive
          </FloatingHtml>
        </a.mesh>
        <a.mesh
          geometry={nodes.Curve016.geometry}
          material={nodes.Curve016.material}
          position={ to([mouseX, mouseY], (mx, my) => {
            return [
              - mx / 12000 + 0.78, //the 1500 represents a value that softens the movement, for the x axis mx should be negative
              my / 12000 + -0.02, //the 1500 represents a value that softens the movement, y axis is already inverted
              0.02
            ]
          }) }
          rotation={[Math.PI / 2, 0, 0]}
          scale={10}
          {...eventHandlers}
          name="Curve.016"
          onClick={openTopicPage}
        />
        <a.mesh
          geometry={nodes.Curve017.geometry}
          material={nodes.Curve017.material}
          position={ to([mouseX, mouseY], (mx, my) => {
            return [
              - mx / 12000 + 0.63, //the 1500 represents a value that softens the movement, for the x axis mx should be negative
              my / 12000 + -0.1, //the 1500 represents a value that softens the movement, y axis is already inverted
              0.03
            ]
          }) }
          rotation={[Math.PI / 2, 0, 0]}
          scale={10}
          {...eventHandlers}
          name="Curve.017"
          onClick={openTopicPage}
        />
        <a.mesh
          geometry={nodes.Curve018.geometry}
          material={nodes.Curve018.material}
          position={ to([mouseX, mouseY], (mx, my) => {
            return [
              - mx / 12000 + 0.6, //the 1500 represents a value that softens the movement, for the x axis mx should be negative
              my / 12000 + -0.09, //the 1500 represents a value that softens the movement, y axis is already inverted
              0.03
            ]
          }) }
          rotation={[Math.PI / 2, 0, 0]}
          scale={10}
          {...eventHandlers}
          name="Curve.018"
          onClick={openTopicPage}
        />
        <a.mesh
          geometry={nodes.Curve019.geometry}
          material={nodes.Curve019.material}
          position={ to([mouseX, mouseY], (mx, my) => {
            return [
              - mx / 15000 + -0.84, //the 1500 represents a value that softens the movement, for the x axis mx should be negative
              my / 15000 + -0.46, //the 1500 represents a value that softens the movement, y axis is already inverted
              -0.62
            ]
          }) }
          rotation={[Math.PI / 2, 0, 0]}
          scale={10}
          {...eventHandlers}
        />
        <a.mesh
          geometry={nodes.Curve020.geometry}
          material={nodes.Curve020.material}
          position={ to([mouseX, mouseY], (mx, my) => {
            return [
              - mx / 15000 + 0.56, //the 1500 represents a value that softens the movement, for the x axis mx should be negative
              my / 15000 + -0.54, //the 1500 represents a value that softens the movement, y axis is already inverted
              -0.56
            ]
          }) }
          rotation={[Math.PI / 2, 0, 0]}
          scale={10}
          {...eventHandlers}
          ref={refArrayFunction1}
          name="Curve.020"
          onClick={openTopicPage}
        >
          <FloatingHtml isMobile={isMobile} 
            style={{ fontWeight: "500", textAlign: "right", width: "210px", position: "absolute", left: "-120px", top: "-60px" }} 
            displayText={displaySVGMatTxt1}
          >
            Innovation ecosystems
          </FloatingHtml>
        </a.mesh>
        <a.mesh
          geometry={nodes.Curve021.geometry}
          material={nodes.Curve021.material}
          position={ to([mouseX, mouseY], (mx, my) => {
            return [
              - mx / 14000 + -0.76, //the 1500 represents a value that softens the movement, for the x axis mx should be negative
              my / 14000 + -0.51, //the 1500 represents a value that softens the movement, y axis is already inverted
              -0.52
            ]
          }) }
          rotation={[Math.PI / 2, 0, 0]}
          scale={10}
          {...eventHandlers}
        />
        <FloatingLine refArray={refArray1} refArrayOrder={refsArrayOrder1} displayLine={displaySVGMatTxt1} ></FloatingLine>
      </group>
      <group>
        <a.mesh
          geometry={nodes.Curve043.geometry}
          material={nodes.Curve043.material}
          position={ to([mouseX, mouseY], (mx, my) => {
            return [
              - mx / 13000 + 0.57, //the 1500 represents a value that softens the movement, for the x axis mx should be negative
              my / 13000 + 0, //the 1500 represents a value that softens the movement, y axis is already inverted
              -0.15
            ]
          }) }
          rotation={[Math.PI / 2, 0, 0]}
          scale={10}
          {...eventHandlers}
        />
        <a.mesh
          geometry={nodes.Curve044.geometry}
          material={nodes.Curve044.material}
          position={ to([mouseX, mouseY], (mx, my) => {
            return [
              - mx / 13000 + -0.01, //the 1500 represents a value that softens the movement, for the x axis mx should be negative
              my / 13000 + 0.33, //the 1500 represents a value that softens the movement, y axis is already inverted
              -0.07
            ]
          }) }
          rotation={[Math.PI / 2, 0, 0]}
          scale={10}
          {...eventHandlers}
          ref={refArrayFunction2}
          name="Curve.044"
          onClick={openTopicPage}
        >
          <FloatingHtml isMobile={isMobile} 
            style={{ fontWeight: "500", width: "240px", position: "absolute", left: "-110px", top: "-50px" }} 
            displayText={displaySVGMatTxt2}
          >
            European Green Deal (EGD)
          </FloatingHtml>
        </a.mesh>
        <a.mesh
          geometry={nodes.Curve045.geometry}
          material={nodes.Curve045.material}
          position={ to([mouseX, mouseY], (mx, my) => {
            return [
              - mx / 13000 + 0.86, //the 1500 represents a value that softens the movement, for the x axis mx should be negative
              my / 13000 + 0.14, //the 1500 represents a value that softens the movement, y axis is already inverted
              -0.31
            ]
          }) }
          rotation={[Math.PI / 2, 0, 0]}
          scale={10}
          {...eventHandlers}
          ref={refArrayFunction2}
          name="Curve.045"
          onClick={openTopicPage}
        >
          <FloatingHtml isMobile={isMobile} 
            style={{ fontWeight: "500", width: "190px", position: "absolute", left: "50px", top: "10px" }} 
            displayText={displaySVGMatTxt2}
          >
            Responsible Research & Innovation (RRI)
          </FloatingHtml>
        </a.mesh>
        <a.mesh
          geometry={nodes.Curve046.geometry}
          material={nodes.Curve046.material}
          position={ to([mouseX, mouseY], (mx, my) => {
            return [
              - mx / 10000 + -0.89, //the 1500 represents a value that softens the movement, for the x axis mx should be negative
              my / 10000 + 0.12, //the 1500 represents a value that softens the movement, y axis is already inverted
              0.21
            ]
          }) }
          rotation={[Math.PI / 2, 0, 0]}
          scale={10}
          {...eventHandlers}
          ref={refArrayFunction2}
          name="Curve.046"
          onClick={openTopicPage}
        >
          <FloatingHtml isMobile={isMobile} 
            style={{ fontWeight: "500", width: "240px", position: "absolute", left: "50px", top: "-10px" }} 
            displayText={displaySVGMatTxt2}
          >
            UN Agenda 2030
          </FloatingHtml>
        </a.mesh>
        <a.mesh
          geometry={nodes.Curve047.geometry}
          material={nodes.Curve047.material}
          position={ to([mouseX, mouseY], (mx, my) => {
            return [
              - mx / 13000 + 0.5, //the 1500 represents a value that softens the movement, for the x axis mx should be negative
              my / 13000 + -0.71, //the 1500 represents a value that softens the movement, y axis is already inverted
              -0.3
            ]
          }) }
          rotation={[Math.PI / 2, 0, 0]}
          scale={10}
          {...eventHandlers}
          ref={refArrayFunction2}
          name="Curve.047"
          onClick={openTopicPage}
        >
          <FloatingHtml isMobile={isMobile} 
            style={{ fontWeight: "500", width: "190px", position: "absolute", left: "80px" }} 
            displayText={displaySVGMatTxt2}
          >
            Smart Specialization Strategies
          </FloatingHtml>
        </a.mesh>
        <a.mesh
          geometry={nodes.Curve048.geometry}
          material={nodes.Curve048.material}
          position={ to([mouseX, mouseY], (mx, my) => {
            return [
              - mx / 14000 + 0.59, //the 1500 represents a value that softens the movement, for the x axis mx should be negative
              my / 14000 + -0.63, //the 1500 represents a value that softens the movement, y axis is already inverted
              -0.42
            ]
          }) }
          rotation={[Math.PI / 2, 0, 0]}
          scale={10}
          {...eventHandlers}
          name="Curve.048"
          onClick={openTopicPage}
        />
        <FloatingLine refArray={refArray2} refArrayOrder={refsArrayOrder2} displayLine={displaySVGMatTxt2} ></FloatingLine>
      </group>
      <group>
        <a.mesh
          geometry={nodes.Curve042.geometry}
          material={nodes.Curve042.material}
          position={ to([mouseX, mouseY], (mx, my) => {
            return [
              - mx / 13500 + -1, //the 1500 represents a value that softens the movement, for the x axis mx should be negative
              my / 13500 + 0.42, //the 1500 represents a value that softens the movement, y axis is already inverted
              -0.36
            ]
          }) }
          rotation={[Math.PI / 2, 0, 0]}
          scale={10}
          {...eventHandlers}
          ref={refArrayFunction3}
          name="Curve.042"
          onClick={openTopicPage}
        >
          <FloatingHtml isMobile={isMobile} 
            style={{ fontWeight: "500", width: "160px", position: "absolute", left: "0px", top: "-80px" }} 
            displayText={displaySVGMatTxt3}
          >
            Hard competition
          </FloatingHtml>
        </a.mesh>
        <a.mesh
          geometry={nodes.Curve049.geometry}
          material={nodes.Curve049.material}
          position={ to([mouseX, mouseY], (mx, my) => {
            return [
              - mx / 13500 + -0.71, //the 1500 represents a value that softens the movement, for the x axis mx should be negative
              my / 13500 + -0.8, //the 1500 represents a value that softens the movement, y axis is already inverted
              -0.58
            ]
          }) }
          rotation={[Math.PI / 2, 0, 0]}
          scale={10}
          {...eventHandlers}
          ref={refArrayFunction3}
          name="Curve.049"
          onClick={openTopicPage}
        >
          <FloatingHtml isMobile={isMobile} 
            style={{ fontWeight: "500", width: "90px", position: "absolute", left: "0px", top: "10px" }} 
            displayText={displaySVGMatTxt3}
          >
            Covid 19
          </FloatingHtml>
        </a.mesh>
        <a.mesh
          geometry={nodes.Curve050.geometry}
          material={nodes.Curve050.material}
          position={ to([mouseX, mouseY], (mx, my) => {
            return [
              - mx / 15000 + 0.83, //the 1500 represents a value that softens the movement, for the x axis mx should be negative
              my / 15000 + 0.45, //the 1500 represents a value that softens the movement, y axis is already inverted
              -0.55
            ]
          }) }
          rotation={[Math.PI / 2, 0, 0]}
          scale={10}
          {...eventHandlers}
          ref={refArrayFunction3}
          name="Curve.050"
          onClick={openTopicPage}
        >
          <FloatingHtml isMobile={isMobile} 
            style={{ fontWeight: "500", width: "170px", position: "absolute", left: "-80px", top: "-40px" }} 
            displayText={displaySVGMatTxt3}
          >
            Social inequalities
          </FloatingHtml>
        </a.mesh>
        <a.mesh
          geometry={nodes.Curve051.geometry}
          material={nodes.Curve051.material}
          position={ to([mouseX, mouseY], (mx, my) => {
            return [
              - mx / 11800 + 0.57, //the 1500 represents a value that softens the movement, for the x axis mx should be negative
              my / 11800 + 0.23, //the 1500 represents a value that softens the movement, y axis is already inverted
              -0.26
            ]
          }) }
          rotation={[Math.PI / 2, 0, 0]}
          scale={10}
          {...eventHandlers}
          ref={refArrayFunction3}
          name="Curve.051"
          onClick={openTopicPage}
        >
          <FloatingHtml isMobile={isMobile} 
            style={{ fontWeight: "500", textAlign: "right", width: "270px", position: "absolute", left: "-290px", top: "-5px" }} 
            displayText={displaySVGMatTxt3}
          >
            Climate & nature emergencies
          </FloatingHtml>
        </a.mesh>
        <a.mesh
          geometry={nodes.Curve052.geometry}
          material={nodes.Curve052.material}
          position={ to([mouseX, mouseY], (mx, my) => {
            return [
              - mx / 12200 + 0.79, //the 1500 represents a value that softens the movement, for the x axis mx should be negative
              my / 12200 + -0.6, //the 1500 represents a value that softens the movement, y axis is already inverted
              0.01
            ]
          }) }
          rotation={[Math.PI / 2, 0, 0]}
          scale={10}
          {...eventHandlers}
          ref={refArrayFunction3}
          name="Curve.052"
          onClick={openTopicPage}
        >
          <FloatingHtml isMobile={isMobile} 
            style={{ fontWeight: "500", width: "110px", position: "absolute", left: "20px", top: "-30px" }} 
            displayText={displaySVGMatTxt3}
          >
            Polarization
          </FloatingHtml>
        </a.mesh>
        <a.mesh
          geometry={nodes.Curve053.geometry}
          material={nodes.Curve053.material}
          position={ to([mouseX, mouseY], (mx, my) => {
            return [
              - mx / 14000 + -0.66, //the 1500 represents a value that softens the movement, for the x axis mx should be negative
              my / 14000 + 0.76, //the 1500 represents a value that softens the movement, y axis is already inverted
              -0.42
            ]
          }) }
          rotation={[Math.PI / 2, 0, 0]}
          scale={10}
          {...eventHandlers}
          ref={refArrayFunction3}
          name="Curve.053"
          onClick={openTopicPage}
        >
          <FloatingHtml isMobile={isMobile} 
            style={{ fontWeight: "500", width: "150px", position: "absolute", left: "-70px", top: "-60px" }} 
            displayText={displaySVGMatTxt3}
          >
            Distressed areas
          </FloatingHtml>
        </a.mesh>
        <a.mesh
          geometry={nodes.Curve054.geometry}
          material={nodes.Curve054.material}
          position={ to([mouseX, mouseY], (mx, my) => {
            return [
              - mx / 13200 + 1.06, //the 1500 represents a value that softens the movement, for the x axis mx should be negative
              my / 13200 + 0.37, //the 1500 represents a value that softens the movement, y axis is already inverted
              -0.39
            ]
          }) }
          rotation={[Math.PI / 2, 0, 0]}
          scale={10}
          {...eventHandlers}
          ref={refArrayFunction3}
          name="Curve.054"
          onClick={openTopicPage}
        />
        <FloatingLine refArray={refArray3} refArrayOrder={refsArrayOrder3} displayLine={displaySVGMatTxt3} ></FloatingLine>
      </group>
    </group>
  )
}

useGLTF.preload('/intro-shapes/shapes.gltf')
