import React, { useState, useRef, useEffect } from 'react'
import { Link } from "react-router-dom";

import './DropdownNav.css'

const DropdownNav = () => {

  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useState(false);

  const onClick = () => setIsActive(!isActive);

  useEffect(() => {
    const pageClickEvent = (e) => {
      // If the active element exists and is clicked outside of
      //if (dropdownRef.current !== null && !dropdownRef.current.contains(e.target)) {
      if (dropdownRef.current !== null) {
        setIsActive(!isActive);
      }
    };
  
    // If the item is active (ie open) then listen for clicks
    if (isActive) {
      window.addEventListener('click', pageClickEvent);
    }
  
    return () => {
      window.removeEventListener('click', pageClickEvent);
    }
  
  }, [isActive]);

  return (
    <div className="menu-container">
      <button onClick={onClick} className="menu-trigger">
        <img src={process.env.PUBLIC_URL + "/assets/right-arrow.svg"} alt="Dropdown Arrow" className={ isActive ? 'active' : 'inactive' } />
        <span>All Concepts</span>
      </button>
      <nav ref={dropdownRef} className={`menu ${isActive ? 'active' : 'inactive'}`}>
        <ul>
          <li><Link to="/">Home</Link></li>
          <li><Link to="/social-inequalities">Big challenges</Link></li>
          <li><Link to="/smart-specialization-strategies">Policies for change</Link></li>
          <li><Link to="/complexity-is-everywhere">Complexity is everywhere</Link></li>
          <li><Link to="/interdependencies">Learning threads</Link></li>
        </ul>
      </nav>
    </div>
  )
}

export default DropdownNav
