import React, { useEffect, useRef } from 'react'
import './ComplexityIntroPage.css'
import anime from 'animejs'

import IntroStates from '../../State/IntroStates'

const ComplexityIntroPage = (props) => {

  //state handling
  const viewTopic = IntroStates(state => state.viewTopic)

  useEffect(() => {
    viewTopic("complexity")    
  }, [viewTopic])

  //animation handling
  //const lineAnimationRef = useRef()
  const pathAnimationRef = useRef()

  useEffect(() => {

    /* lineAnimationRef.current = anime({
      targets: '#the-seerri-project-container path',
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: 'easeInOutCubic',
      duration: 2000,
      begin: function(anim) {
        $('#the-seerri-project-container svg path').css("stroke", "#235666");
        $('#the-seerri-project-container svg line').css("stroke", "#235666");
      },
      complete: function(anim) {},
      autoplay: true
    }) */

    pathAnimationRef.current = anime({
      targets: '.container .background svg path',
      easing: 'easeInOutCubic',
      duration: 1000,
      opacity: 1,
      delay: anime.stagger(100),
      begin: function(anim) {},
      complete: function(anim) {},
      autoplay: true
    })

  }, [])

  const goBack = () => {
    window.appHistory.push('/')
  }

  return (
    <div className="container" >
      <div className="background" >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="-200 0 2300 1828.69"
      >
        <defs>
          <linearGradient
            id="prefix__Degradado_sin_nombre_656"
            x1={1872.11}
            y1={1604.88}
            x2={1982.33}
            y2={1604.88}
            gradientTransform="rotate(18.31 5295.935 -4632.666)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0} stopColor="#002984" />
            <stop offset={0.01} stopColor="#104194" />
            <stop offset={0.02} stopColor="#387ebe" />
            <stop offset={0.03} stopColor="#55abdd" />
            <stop offset={1} stopColor="#900280" />
          </linearGradient>
          <linearGradient
            id="prefix__Degradado_sin_nombre_656-2"
            x1={27.96}
            y1={951.95}
            x2={409.81}
            y2={951.95}
            xlinkHref="#prefix__Degradado_sin_nombre_656"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_656-3"
            x1={32.02}
            y1={949.09}
            x2={403.31}
            y2={949.09}
            xlinkHref="#prefix__Degradado_sin_nombre_656"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_656-4"
            x1={36.07}
            y1={946.23}
            x2={396.81}
            y2={946.23}
            xlinkHref="#prefix__Degradado_sin_nombre_656"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_656-5"
            x1={40.13}
            y1={943.37}
            x2={390.3}
            y2={943.37}
            xlinkHref="#prefix__Degradado_sin_nombre_656"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_656-6"
            x1={44.18}
            y1={940.51}
            x2={383.8}
            y2={940.51}
            xlinkHref="#prefix__Degradado_sin_nombre_656"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_656-7"
            x1={48.23}
            y1={937.66}
            x2={377.3}
            y2={937.66}
            xlinkHref="#prefix__Degradado_sin_nombre_656"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_656-8"
            x1={52.27}
            y1={934.8}
            x2={370.86}
            y2={934.8}
            xlinkHref="#prefix__Degradado_sin_nombre_656"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_656-9"
            x1={56.31}
            y1={931.94}
            x2={364.35}
            y2={931.94}
            xlinkHref="#prefix__Degradado_sin_nombre_656"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_656-10"
            x1={60.35}
            y1={929.08}
            x2={357.85}
            y2={929.08}
            xlinkHref="#prefix__Degradado_sin_nombre_656"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_656-11"
            x1={64.38}
            y1={926.22}
            x2={351.34}
            y2={926.22}
            xlinkHref="#prefix__Degradado_sin_nombre_656"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_656-12"
            x1={68.41}
            y1={923.37}
            x2={344.84}
            y2={923.37}
            xlinkHref="#prefix__Degradado_sin_nombre_656"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_656-13"
            x1={72.43}
            y1={920.51}
            x2={338.33}
            y2={920.51}
            xlinkHref="#prefix__Degradado_sin_nombre_656"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_656-14"
            x1={76.44}
            y1={917.65}
            x2={331.83}
            y2={917.65}
            xlinkHref="#prefix__Degradado_sin_nombre_656"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_656-15"
            x1={80.45}
            y1={914.79}
            x2={325.33}
            y2={914.79}
            xlinkHref="#prefix__Degradado_sin_nombre_656"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_656-16"
            x1={84.45}
            y1={911.94}
            x2={318.82}
            y2={911.94}
            xlinkHref="#prefix__Degradado_sin_nombre_656"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_656-17"
            x1={88.44}
            y1={909.08}
            x2={312.32}
            y2={909.08}
            xlinkHref="#prefix__Degradado_sin_nombre_656"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_656-18"
            x1={92.42}
            y1={906.22}
            x2={305.74}
            y2={906.22}
            xlinkHref="#prefix__Degradado_sin_nombre_656"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_656-19"
            x1={94.48}
            y1={903.36}
            x2={299.24}
            y2={903.36}
            xlinkHref="#prefix__Degradado_sin_nombre_656"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_656-20"
            x1={95.64}
            y1={900.51}
            x2={292.74}
            y2={900.51}
            xlinkHref="#prefix__Degradado_sin_nombre_656"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_656-21"
            x1={96.76}
            y1={897.65}
            x2={286.24}
            y2={897.65}
            xlinkHref="#prefix__Degradado_sin_nombre_656"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_656-22"
            x1={97.83}
            y1={894.79}
            x2={279.74}
            y2={894.79}
            xlinkHref="#prefix__Degradado_sin_nombre_656"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_656-23"
            x1={98.86}
            y1={891.93}
            x2={273.24}
            y2={891.93}
            xlinkHref="#prefix__Degradado_sin_nombre_656"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_656-24"
            x1={99.84}
            y1={889.08}
            x2={266.74}
            y2={889.08}
            xlinkHref="#prefix__Degradado_sin_nombre_656"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_656-25"
            x1={100.78}
            y1={886.22}
            x2={260.24}
            y2={886.22}
            xlinkHref="#prefix__Degradado_sin_nombre_656"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_656-26"
            x1={101.67}
            y1={883.36}
            x2={253.95}
            y2={883.36}
            xlinkHref="#prefix__Degradado_sin_nombre_656"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_656-27"
            x1={102.52}
            y1={880.5}
            x2={249.6}
            y2={880.5}
            xlinkHref="#prefix__Degradado_sin_nombre_656"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_656-28"
            x1={103.32}
            y1={877.65}
            x2={245.28}
            y2={877.65}
            xlinkHref="#prefix__Degradado_sin_nombre_656"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_656-29"
            x1={104.07}
            y1={874.79}
            x2={240.96}
            y2={874.79}
            xlinkHref="#prefix__Degradado_sin_nombre_656"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_656-30"
            x1={104.79}
            y1={871.93}
            x2={236.64}
            y2={871.93}
            xlinkHref="#prefix__Degradado_sin_nombre_656"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_656-31"
            x1={105.46}
            y1={869.07}
            x2={232.34}
            y2={869.07}
            xlinkHref="#prefix__Degradado_sin_nombre_656"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_656-32"
            x1={106.08}
            y1={866.22}
            x2={231.15}
            y2={866.22}
            xlinkHref="#prefix__Degradado_sin_nombre_656"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_656-33"
            x1={106.67}
            y1={863.36}
            x2={234.92}
            y2={863.36}
            xlinkHref="#prefix__Degradado_sin_nombre_656"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_656-34"
            x1={105.13}
            y1={860.5}
            x2={238.7}
            y2={860.5}
            xlinkHref="#prefix__Degradado_sin_nombre_656"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_656-35"
            x1={100.06}
            y1={857.65}
            x2={242.47}
            y2={857.65}
            xlinkHref="#prefix__Degradado_sin_nombre_656"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_656-36"
            x1={94.96}
            y1={854.79}
            x2={246.24}
            y2={854.79}
            xlinkHref="#prefix__Degradado_sin_nombre_656"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_656-37"
            x1={89.85}
            y1={851.93}
            x2={250.02}
            y2={851.93}
            xlinkHref="#prefix__Degradado_sin_nombre_656"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_656-38"
            x1={84.67}
            y1={849.08}
            x2={253.79}
            y2={849.08}
            xlinkHref="#prefix__Degradado_sin_nombre_656"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_656-39"
            x1={79.54}
            y1={846.22}
            x2={257.57}
            y2={846.22}
            xlinkHref="#prefix__Degradado_sin_nombre_656"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_656-40"
            x1={74.39}
            y1={843.36}
            x2={261.34}
            y2={843.36}
            xlinkHref="#prefix__Degradado_sin_nombre_656"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_656-41"
            x1={69.23}
            y1={840.51}
            x2={265.12}
            y2={840.51}
            xlinkHref="#prefix__Degradado_sin_nombre_656"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_656-42"
            x1={64.04}
            y1={837.65}
            x2={268.89}
            y2={837.65}
            xlinkHref="#prefix__Degradado_sin_nombre_656"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_656-43"
            x1={58.83}
            y1={834.8}
            x2={272.67}
            y2={834.8}
            xlinkHref="#prefix__Degradado_sin_nombre_656"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_656-44"
            x1={53.6}
            y1={831.94}
            x2={276.44}
            y2={831.94}
            xlinkHref="#prefix__Degradado_sin_nombre_656"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_656-45"
            x1={48.35}
            y1={829.07}
            x2={280.21}
            y2={829.07}
            xlinkHref="#prefix__Degradado_sin_nombre_656"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_656-46"
            x1={43.08}
            y1={826.21}
            x2={283.99}
            y2={826.21}
            xlinkHref="#prefix__Degradado_sin_nombre_656"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_656-47"
            x1={37.81}
            y1={823.35}
            x2={287.76}
            y2={823.35}
            xlinkHref="#prefix__Degradado_sin_nombre_656"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_656-48"
            x1={32.49}
            y1={820.49}
            x2={291.54}
            y2={820.49}
            xlinkHref="#prefix__Degradado_sin_nombre_656"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_656-49"
            x1={27.15}
            y1={817.62}
            x2={295.31}
            y2={817.62}
            xlinkHref="#prefix__Degradado_sin_nombre_656"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_656-50"
            x1={21.71}
            y1={814.76}
            x2={299.09}
            y2={814.76}
            xlinkHref="#prefix__Degradado_sin_nombre_656"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_656-51"
            x1={16.32}
            y1={811.9}
            x2={302.86}
            y2={811.9}
            xlinkHref="#prefix__Degradado_sin_nombre_656"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_656-52"
            x1={10.91}
            y1={809.04}
            x2={306.63}
            y2={809.04}
            xlinkHref="#prefix__Degradado_sin_nombre_656"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_656-53"
            x1={5.47}
            y1={806.17}
            x2={310.41}
            y2={806.17}
            xlinkHref="#prefix__Degradado_sin_nombre_656"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_656-54"
            x1={0}
            y1={803.31}
            x2={314.18}
            y2={803.31}
            xlinkHref="#prefix__Degradado_sin_nombre_656"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_29"
            x1={2608.87}
            y1={1827.65}
            x2={2635.47}
            y2={1827.65}
            gradientTransform="rotate(18.31 5295.935 -4632.666)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0} stopColor="#900280" />
            <stop offset={0.26} stopColor="#da1382" />
            <stop offset={1} stopColor="#f09600" />
          </linearGradient>
          <linearGradient
            id="prefix__Degradado_sin_nombre_29-2"
            x1={2604.18}
            y1={1826.79}
            x2={2632.26}
            y2={1826.79}
            xlinkHref="#prefix__Degradado_sin_nombre_29"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_5"
            x1={2129.3}
            y1={1608.94}
            x2={2470.55}
            y2={1608.94}
            gradientTransform="rotate(-4.95 -16428.495 24749.193)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0} stopColor="#5e2b7e" />
            <stop offset={1} stopColor="#dc768c" />
          </linearGradient>
          <linearGradient
            id="prefix__Degradado_sin_nombre_5-2"
            x1={2210.03}
            y1={1597.43}
            x2={2469.32}
            y2={1597.43}
            xlinkHref="#prefix__Degradado_sin_nombre_5"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_5-3"
            x1={297.76}
            y1={355.23}
            x2={633.71}
            y2={355.23}
            gradientTransform="rotate(-23.25 -298.354 843.056)"
            xlinkHref="#prefix__Degradado_sin_nombre_5"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_656-55"
            x1={2073.21}
            y1={1988.84}
            x2={2086.47}
            y2={1988.84}
            xlinkHref="#prefix__Degradado_sin_nombre_656"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_656-56"
            x1={2059.07}
            y1={2006.52}
            x2={2072.33}
            y2={2006.52}
            xlinkHref="#prefix__Degradado_sin_nombre_656"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_656-57"
            x1={2080.51}
            y1={2015.36}
            x2={2093.32}
            y2={2015.36}
            xlinkHref="#prefix__Degradado_sin_nombre_656"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_656-58"
            x1={2101.72}
            y1={1983.54}
            x2={2118.07}
            y2={1983.54}
            xlinkHref="#prefix__Degradado_sin_nombre_656"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_656-59"
            x1={2059.3}
            y1={1948.18}
            x2={2075.64}
            y2={1948.18}
            xlinkHref="#prefix__Degradado_sin_nombre_656"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_656-60"
            x1={2105.26}
            y1={2004.75}
            x2={2125.14}
            y2={2004.75}
            xlinkHref="#prefix__Degradado_sin_nombre_656"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_656-61"
            x1={2048.47}
            y1={2048.94}
            x2={2061.72}
            y2={2048.94}
            xlinkHref="#prefix__Degradado_sin_nombre_656"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_656-62"
            x1={2020.41}
            y1={2015.36}
            x2={2033.21}
            y2={2015.36}
            xlinkHref="#prefix__Degradado_sin_nombre_656"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_656-63"
            x1={2076.74}
            y1={2048.94}
            x2={2093.55}
            y2={2048.94}
            xlinkHref="#prefix__Degradado_sin_nombre_656"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_656-64"
            x1={2027.25}
            y1={2056.01}
            x2={2040.51}
            y2={2056.01}
            xlinkHref="#prefix__Degradado_sin_nombre_656"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_656-65"
            x1={2030.79}
            y1={2084.3}
            x2={2044.05}
            y2={2084.3}
            xlinkHref="#prefix__Degradado_sin_nombre_656"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_656-66"
            x1={2066.37}
            y1={2086.07}
            x2={2079.18}
            y2={2086.07}
            xlinkHref="#prefix__Degradado_sin_nombre_656"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_656-67"
            x1={1992.13}
            y1={2068.39}
            x2={2004.93}
            y2={2068.39}
            xlinkHref="#prefix__Degradado_sin_nombre_656"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_656-68"
            x1={2048.69}
            y1={1994.14}
            x2={2061.5}
            y2={1994.14}
            xlinkHref="#prefix__Degradado_sin_nombre_656"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_656-69"
            x1={2002.73}
            y1={1976.47}
            x2={2015.54}
            y2={1976.47}
            xlinkHref="#prefix__Degradado_sin_nombre_656"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_656-70"
            x1={1960.31}
            y1={2054.25}
            x2={1973.11}
            y2={2054.25}
            xlinkHref="#prefix__Degradado_sin_nombre_656"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_656-71"
            x1={1995.66}
            y1={2107.28}
            x2={2008.47}
            y2={2107.28}
            xlinkHref="#prefix__Degradado_sin_nombre_656"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_656-72"
            x1={2112.33}
            y1={2050.71}
            x2={2125.14}
            y2={2050.71}
            xlinkHref="#prefix__Degradado_sin_nombre_656"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_656-73"
            x1={2122.94}
            y1={1948.18}
            x2={2135.74}
            y2={1948.18}
            xlinkHref="#prefix__Degradado_sin_nombre_656"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_656-74"
            x1={2158.29}
            y1={2075.46}
            x2={2171.1}
            y2={2075.46}
            xlinkHref="#prefix__Degradado_sin_nombre_656"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_656-75"
            x1={2140.61}
            y1={1976.46}
            x2={2156.96}
            y2={1976.46}
            xlinkHref="#prefix__Degradado_sin_nombre_656"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_3"
            x1={1474.44}
            y1={1504.28}
            x2={1488.88}
            y2={1504.28}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0} stopColor="#fcec29" />
            <stop offset={1} stopColor="#f09600" />
          </linearGradient>
          <linearGradient
            id="prefix__Degradado_sin_nombre_9"
            x1={1431.26}
            y1={1603.56}
            x2={1445.7}
            y2={1603.56}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0} stopColor="#ddda3e" />
            <stop offset={1} stopColor="#53afd2" />
          </linearGradient>
          <linearGradient
            id="prefix__Degradado_sin_nombre_11"
            x1={1499.54}
            y1={1508.42}
            x2={1512.47}
            y2={1508.42}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0} stopColor="#2d307e" />
            <stop offset={0.24} stopColor="#35487a" />
            <stop offset={0.74} stopColor="#4b8571" />
            <stop offset={1} stopColor="#57a86c" />
          </linearGradient>
          <linearGradient
            id="prefix__Degradado_sin_nombre_3-2"
            x1={1509.15}
            y1={1467.07}
            x2={1525.44}
            y2={1467.07}
            xlinkHref="#prefix__Degradado_sin_nombre_3"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_3-3"
            x1={1479.97}
            y1={1420.17}
            x2={1496.26}
            y2={1420.17}
            xlinkHref="#prefix__Degradado_sin_nombre_3"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_11-2"
            x1={1490.18}
            y1={1554.23}
            x2={1509.82}
            y2={1554.23}
            xlinkHref="#prefix__Degradado_sin_nombre_11"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_9-2"
            x1={1455.87}
            y1={1675.63}
            x2={1470.31}
            y2={1675.63}
            xlinkHref="#prefix__Degradado_sin_nombre_9"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_3-4"
            x1={1421.95}
            y1={1471.18}
            x2={1434.88}
            y2={1471.18}
            xlinkHref="#prefix__Degradado_sin_nombre_3"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_9-3"
            x1={1525.54}
            y1={1679.13}
            x2={1543.05}
            y2={1679.13}
            xlinkHref="#prefix__Degradado_sin_nombre_9"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_9-4"
            x1={1587.47}
            y1={1596.27}
            x2={1601.91}
            y2={1596.27}
            xlinkHref="#prefix__Degradado_sin_nombre_9"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_9-5"
            x1={1511.58}
            y1={1794.41}
            x2={1526.02}
            y2={1794.41}
            xlinkHref="#prefix__Degradado_sin_nombre_9"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_9-6"
            x1={1508.74}
            y1={1605.69}
            x2={1521.68}
            y2={1605.69}
            xlinkHref="#prefix__Degradado_sin_nombre_9"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_11-3"
            x1={1439.49}
            y1={1526.69}
            x2={1452.42}
            y2={1526.69}
            xlinkHref="#prefix__Degradado_sin_nombre_11"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_16"
            x1={1455.47}
            y1={1459.92}
            x2={1468.4}
            y2={1459.92}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0.49} stopColor="#ddda3e" />
            <stop offset={0.53} stopColor="#d4d748" />
            <stop offset={1} stopColor="#53afd2" />
          </linearGradient>
          <linearGradient
            id="prefix__Degradado_sin_nombre_3-5"
            x1={1417.38}
            y1={1428.71}
            x2={1430.32}
            y2={1428.71}
            xlinkHref="#prefix__Degradado_sin_nombre_3"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_3-6"
            x1={1352.68}
            y1={1489.23}
            x2={1365.61}
            y2={1489.23}
            xlinkHref="#prefix__Degradado_sin_nombre_3"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_3-7"
            x1={1369.59}
            y1={1550.68}
            x2={1382.52}
            y2={1550.68}
            xlinkHref="#prefix__Degradado_sin_nombre_3"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_11-4"
            x1={1548.9}
            y1={1527.13}
            x2={1561.83}
            y2={1527.13}
            xlinkHref="#prefix__Degradado_sin_nombre_11"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_3-8"
            x1={542.12}
            y1={1243.63}
            x2={555.05}
            y2={1243.63}
            xlinkHref="#prefix__Degradado_sin_nombre_3"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_9-7"
            x1={398.5}
            y1={1498.74}
            x2={411.43}
            y2={1498.74}
            xlinkHref="#prefix__Degradado_sin_nombre_9"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_9-8"
            x1={1587.19}
            y1={1468.25}
            x2={1603.47}
            y2={1468.25}
            xlinkHref="#prefix__Degradado_sin_nombre_9"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_25"
            x1={320.07}
            y1={-6580.97}
            x2={486.25}
            y2={-6580.97}
            gradientTransform="rotate(-66.53 6896.92 -3575.45)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0} stopColor="#6baad8" />
            <stop offset={0.19} stopColor="#6994ca" />
            <stop offset={0.59} stopColor="#635ba5" />
            <stop offset={1} stopColor="#5c1c7c" />
          </linearGradient>
          <linearGradient
            id="prefix__Degradado_sin_nombre_27"
            x1={2450.4}
            y1={1672.05}
            x2={2566.06}
            y2={1672.05}
            gradientTransform="rotate(6.9 4255.707 -7521.112)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0} stopColor="#64a541" />
            <stop offset={1} stopColor="#6baad8" />
          </linearGradient>
          <linearGradient
            id="prefix__Degradado_sin_nombre_21"
            x1={193.27}
            y1={-6477.08}
            x2={202.73}
            y2={-6477.08}
            gradientTransform="rotate(-66.53 6896.92 -3575.45)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0} stopColor="#f9eb58" />
            <stop offset={1} stopColor="#e3992b" />
          </linearGradient>
          <linearGradient
            id="prefix__Degradado_sin_nombre_5-4"
            x1={2085.53}
            y1={1430.29}
            x2={2138.78}
            y2={1430.29}
            gradientTransform="rotate(18.31 5295.935 -4632.666)"
            xlinkHref="#prefix__Degradado_sin_nombre_5"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_25-2"
            x1={1851.25}
            y1={1443.27}
            x2={1993.94}
            y2={1443.27}
            gradientTransform="rotate(18.31 5295.935 -4632.666)"
            xlinkHref="#prefix__Degradado_sin_nombre_25"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_27-2"
            x1={-620.73}
            y1={-3157.38}
            x2={-571.2}
            y2={-3157.38}
            gradientTransform="rotate(-41.9 5975.567 -3780.374)"
            xlinkHref="#prefix__Degradado_sin_nombre_27"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_29-3"
            x1={2353.67}
            y1={2994.95}
            x2={2498.23}
            y2={2994.95}
            gradientTransform="rotate(-17.51 -2819.743 9682.9)"
            xlinkHref="#prefix__Degradado_sin_nombre_29"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_25-3"
            x1={126.57}
            y1={1690.11}
            x2={428.62}
            y2={1690.11}
            gradientTransform="rotate(-35.81 204.536 1724.605)"
            xlinkHref="#prefix__Degradado_sin_nombre_25"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_5-5"
            x1={1415.83}
            y1={-9169.61}
            x2={1841.09}
            y2={-9169.61}
            gradientTransform="rotate(-67 8390.882 -3186.002)"
            xlinkHref="#prefix__Degradado_sin_nombre_5"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_5-6"
            x1={1078.4}
            y1={-7658.89}
            x2={1660.98}
            y2={-7658.89}
            gradientTransform="rotate(-67 8390.882 -3186.002)"
            xlinkHref="#prefix__Degradado_sin_nombre_5"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_656-76"
            x1={1967.77}
            y1={1208.24}
            x2={3331.74}
            y2={1208.24}
            gradientTransform="rotate(-113.04 2052.482 1313.048)"
            xlinkHref="#prefix__Degradado_sin_nombre_656"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_3-9"
            x1={2365.38}
            y1={3060.44}
            x2={2427.89}
            y2={3060.44}
            gradientTransform="rotate(-17.51 -2819.743 9682.9)"
            xlinkHref="#prefix__Degradado_sin_nombre_3"
          />
          <linearGradient
            id="prefix__Degradado_sin_nombre_3-10"
            x1={-658.87}
            y1={-3206.85}
            x2={-610.87}
            y2={-3206.85}
            gradientTransform="rotate(-41.9 5975.567 -3780.374)"
            xlinkHref="#prefix__Degradado_sin_nombre_3"
          />
          <style>{".prefix__cls-104{fill:#344c47}"}</style>
        </defs>
        <title>{"complexity-background"}</title>
        <g id="prefix__Layer_2" data-name="Layer 2">
          <g id="prefix__Layer_1-2" data-name="Layer 1">
            <path
              d="M198.13 225.45a267.08 267.08 0 01-23.27 27.12c-4.27 4.34-8.76 8.59-14.14 11.44s-11.82 4.17-17.64 2.36c-7.37-2.29-12.36-8.95-18.38-13.78a42.77 42.77 0 00-41.4-6.38c-5.14-8.36-3.69-20 2.94-27.2 5.29-5.77 13.08-8.4 20.51-10.81 11.94-3.87 24.21-7.79 36.75-7.15 19.91 1.01 39.91 10.95 54.63 24.4z"
              fill="url(#prefix__Degradado_sin_nombre_656)"
            />
            <path
              d="M117.43 1781.31a713 713 0 01-34.23-99.92c-8.39-32.36-13.28-62-14.52-88.12-1.56-32.68 2.6-59.9 12.37-80.92 11.53-24.81 31.06-41.32 58.05-49.08 38.29-11 79.45-18.46 115.78-25 66.89-12.1 119.74-21.67 124.72-46.09 3.79-18.57-20.58-44.41-76.7-81.3-35.38-23.25-48.19-53.52-39.16-92.51 7.87-34 31.37-70.32 52.11-102.37 25.13-38.83 46.82-72.36 39.24-95.22-3.12-9.39-11-16.32-24.09-21.19-28.31-10.59-45.55-25.45-52.69-45.59-14.92-42 18.58-98.85 48.14-149 11.56-19.6 22.47-38.12 30-54.72 8-17.76 9.11-32 3.27-43.49-11.85-23.35-49.74-31.28-89.85-39.67-39.12-8.18-79.57-16.65-93.33-40.86-6.37-11.21-6.67-24.79-.92-41.51 11.6-33.74 15.82-49.1 12-56.87-2.55-5.12-8.68-7.12-17.95-10.16-23.17-7.54-66.18-21.61-125.67-105.83-15-21.3-19.44-38.67-13.44-53.11C37 343.33 55 331.52 87.26 321.63c28.66-8.78 64.76-14.91 103-21.4 44.35-7.53 90.21-15.32 128.72-28 43.67-14.38 70.78-33 82.9-57 6-11.85 8.38-25.3 7.13-40-1.35-15.69-6.92-33.34-16.55-52.46l.45-.22c9.66 19.18 15.24 36.89 16.59 52.64 1.27 14.77-1.14 28.3-7.18 40.24-12.17 24.09-39.38 42.81-83.18 57.23-38.55 12.69-84.43 20.49-128.8 28C115.56 313.42 45 325.41 31 359c-5.93 14.26-1.55 31.48 13.39 52.62 59.41 84.08 102.31 98.12 125.36 105.66 9.4 3.07 15.6 5.1 18.24 10.41 3.95 7.93-.28 23.37-11.93 57.25-5.7 16.58-5.41 30 .89 41.1 13.65 24 54 32.46 93 40.62 40.23 8.42 78.23 16.37 90.19 39.93 5.91 11.65 4.84 26-3.26 43.93-7.53 16.62-18.45 35.15-30 54.77-29.5 50.05-62.94 106.79-48.1 148.57 7.09 20 24.23 34.78 52.4 45.25 13.24 4.92 21.22 12 24.38 21.5 7.66 23.09-14.09 56.71-39.29 95.65-20.71 32-44.19 68.3-52 102.21-9 38.77 3.76 68.86 38.94 92 56.31 37 80.75 63 76.92 81.82-5 24.76-58 34.34-125.13 46.48-36.3 6.57-77.46 14-115.72 25-26.84 7.71-46.27 24.14-57.74 48.81-9.73 21-13.88 48.09-12.32 80.68 1.24 26.08 6.12 55.7 14.5 88a713.41 713.41 0 0034.2 99.85z"
              fill="url(#prefix__Degradado_sin_nombre_656-2)"
            />
            <path
              d="M121.2 1777.73A708.84 708.84 0 0186.12 1679c-8.69-32-13.89-61.44-15.44-87.41-2-32.49 1.83-59.66 11.23-80.76 11.09-24.91 30.18-41.72 56.75-50 39.63-12.3 81.63-20.28 118.69-27.31 62.22-11.82 111.37-21.15 116-44.15 3.66-18.1-20.57-43.77-76.27-80.81-34.68-23.08-47.1-53.08-38-91.72 8-33.71 31.37-69.83 52-101.69 25.34-39.08 47.23-72.83 39.65-96.05-3.1-9.53-11-16.6-24.13-21.62-28-10.72-45.13-25.74-52.24-45.92-14.81-42.05 18.14-98.48 47.21-148.27 11.33-19.4 22-37.73 29.37-54.15 8-17.75 9.05-32 3.29-43.44-11.45-22.82-47.68-30.86-86-39.36-37.76-8.38-76.8-17-90.65-41.13-6.51-11.33-7.05-25-1.67-41.89 18.88-59.09 16-60.16-6.75-68.47-22.13-8.11-63.34-23.2-121.25-105.58C33 388 28.63 370.66 34.58 356.21c6.3-15.27 23.83-27 55.18-37 27.85-8.83 62.89-15.08 100-21.7 43-7.68 87.49-15.61 124.89-28.32 42.4-14.41 68.79-32.9 80.7-56.55 5.95-11.81 8.36-25.2 7.16-39.81-1.28-15.62-6.72-33.18-16.18-52.21l.45-.22c9.48 19.08 14.94 36.71 16.23 52.39 1.2 14.7-1.22 28.18-7.21 40.07-12 23.77-38.46 42.35-81 56.8-37.44 12.72-81.93 20.66-125 28.34-72.53 13-141.09 25.22-154.75 58.4-5.89 14.28-1.53 31.48 13.31 52.59 57.82 82.26 98.93 97.31 121 105.4 11.63 4.25 18 6.6 19.29 15.48s-2.92 24.48-12.23 53.61c-5.34 16.72-4.81 30.29 1.63 41.49 13.73 23.9 52.67 32.54 90.32 40.89 38.47 8.53 74.81 16.59 86.37 39.63 5.83 11.62 4.76 26-3.28 43.87-7.36 16.44-18.06 34.78-29.4 54.19-29 49.7-61.9 106-47.17 147.85 7.06 20 24.05 35 52 45.62 13.28 5.08 21.27 12.25 24.43 21.93 7.65 23.45-14.3 57.3-39.71 96.49-20.63 31.82-44 67.9-52 101.52-9.07 38.42 3.29 68.25 37.77 91.19 55.88 37.17 80.19 63 76.48 81.33-4.72 23.33-54 32.69-116.42 44.54-37 7-79 15-118.63 27.3-26.43 8.2-45.42 24.91-56.44 49.68-9.37 21-13.13 48.12-11.19 80.53 1.55 25.93 6.74 55.31 15.43 87.3a707.8 707.8 0 0035.05 98.7z"
              fill="url(#prefix__Degradado_sin_nombre_656-3)"
            />
            <path
              d="M125 1774.16a703.8 703.8 0 01-35.92-97.62c-9-31.72-14.51-60.89-16.37-86.71-2.33-32.29 1.06-59.41 10.08-80.6 10.65-25 29.31-42.09 55.45-50.82 40.87-13.64 83.49-22.11 121.09-29.59 57.83-11.5 103.51-20.58 107.85-42.22 3.53-17.59-20.57-43.11-75.85-80.32-34-22.89-46-52.62-36.81-90.92 8.05-33.42 30.36-67.77 51.95-101 24.49-37.7 47.63-73.3 40.07-96.89-3.1-9.67-11-16.89-24.18-22.07-27.78-10.91-44.72-26-51.78-46.28-14.71-42.08 17.69-98.11 46.27-147.54 11.1-19.2 21.58-37.33 28.77-53.57 7.91-17.75 9-31.94 3.31-43.41-11.05-22.29-45.64-30.43-82.25-39.05-36.39-8.56-74-17.42-87.92-41.37-6.66-11.45-7.45-25.29-2.43-42.3 17.63-59.71 14.77-60.88-7.5-70-21.18-8.65-60.61-24.74-116.9-105.24-14.85-21.23-19.2-38.58-13.29-53 6.16-15.1 23.21-26.79 53.64-36.78 27-8.87 61-15.25 97-22 41.67-7.81 84.77-15.9 121.06-28.63 41.13-14.43 66.81-32.78 78.51-56.11 5.9-11.77 8.32-25.11 7.18-39.64-1.21-15.56-6.53-33-15.81-52l.45-.22c9.31 19 14.64 36.52 15.86 52.14 1.14 14.62-1.29 28.05-7.24 39.91-11.75 23.45-37.53 41.88-78.79 56.36-36.32 12.74-79.43 20.82-121.13 28.64C119 308.56 52.47 321 39.08 353.82c-5.83 14.29-1.5 31.49 13.24 52.57 56.21 80.38 95.55 96.44 116.68 105.07 22.63 9.24 25.54 10.42 7.79 70.57-5 16.88-4.2 30.59 2.38 41.91 13.81 23.77 51.32 32.6 87.6 41.13 36.74 8.65 71.43 16.82 82.59 39.32 5.75 11.61 4.67 25.94-3.31 43.83-7.19 16.27-17.68 34.41-28.79 53.62C288.74 851.18 256.4 907.1 271 949c7 20.08 23.86 35.12 51.49 46 13.32 5.23 21.33 12.55 24.48 22.38 7.63 23.81-14.5 57.87-40.13 97.32-21.56 33.18-43.86 67.49-51.88 100.83-9.16 38.07 2.81 67.63 36.6 90.39 55.47 37.33 79.64 63 76.06 80.83-4.41 22-50.23 31.08-108.24 42.61-37.59 7.48-80.2 16-121 29.57-26 8.68-44.56 25.69-55.15 50.55-9 21.11-12.37 48.15-10 80.37 1.86 25.78 7.36 54.92 16.35 86.6a703.24 703.24 0 0035.85 97.55z"
              fill="url(#prefix__Degradado_sin_nombre_656-4)"
            />
            <path
              d="M128.76 1770.59a698.39 698.39 0 01-36.76-96.5c-9.3-31.39-15.12-60.33-17.3-86-2.71-32.1.29-59.17 8.94-80.44 10.21-25.08 28.42-42.47 54.15-51.67 42-15 85-24 123-31.86 53.7-11.16 96.11-20 100.16-40.3 1.68-8.45-3.16-18.66-14.79-31.22-12.27-13.25-32.12-29.15-60.67-48.6-33.29-22.7-44.95-52.18-35.64-90.12 8.13-33.14 30.36-67.27 51.86-100.28 24.7-37.95 48-73.78 40.5-97.74-3.09-9.82-11-17.19-24.23-22.52-27.53-11.11-44.33-26.38-51.37-46.69-14.62-42.22 17.32-98 45.51-147.13 10.81-18.87 21-36.68 28-52.63 7.81-17.77 8.88-32 3.26-43.43-10.68-21.78-43.64-30-78.54-38.73s-71.09-17.76-85-41.53c-6.8-11.58-7.84-25.56-3.19-42.74 16.35-60.4 14-61.45-8.24-71.55-20.24-9.18-57.91-26.27-112.56-104.84-14.75-21.2-19.08-38.55-13.22-53 13.2-32.66 77.77-45.39 146.14-58.88 40.37-7.97 82.09-16.19 117.23-28.95 39.87-14.45 64.83-32.66 76.31-55.66 5.86-11.74 8.29-25 7.22-39.49-1.15-15.49-6.35-32.89-15.45-51.72l.45-.22c9.13 18.89 14.34 36.35 15.49 51.9 1.08 14.56-1.36 27.93-7.26 39.75-11.54 23.13-36.59 41.41-76.59 55.91-35.2 12.76-76.94 21-117.31 29-68.23 13.46-132.68 26.17-145.78 58.57-5.78 14.31-1.48 31.5 13.17 52.56 54.58 78.45 92.16 95.5 112.35 104.66 11.24 5.1 17.43 7.91 18.82 17.17s-2.21 25.1-10.3 55c-4.61 17-3.58 30.9 3.14 42.36 13.85 23.59 49.88 32.59 84.74 41.29 35 8.75 68.07 17 78.86 39 5.68 11.6 4.62 25.94-3.25 43.85-7 16-17.24 33.8-28.06 52.68-28.13 49.08-60 104.71-45.46 146.72 7 20.16 23.69 35.33 51.07 46.39 13.37 5.39 21.39 12.86 24.53 22.83 7.61 24.18-15.79 60.12-40.57 98.16-21.47 33-43.68 67.08-51.79 100.13-9.25 37.71 2.34 67 35.44 89.58 28.58 19.48 48.46 35.4 60.76 48.68 11.75 12.69 16.62 23 14.91 31.66-1.9 9.52-11.86 17.1-31.35 23.85-17.76 6.15-41.59 11.1-69.2 16.84-38 7.89-81 16.83-123 31.84-25.59 9.15-43.71 26.44-53.85 51.39-8.62 21.2-11.62 48.18-8.91 80.21 2.17 25.63 8 54.53 17.27 85.89a697.06 697.06 0 0036.74 96.43z"
              fill="url(#prefix__Degradado_sin_nombre_656-5)"
            />
            <path
              d="M132.54 1767a695 695 0 01-37.6-95.38c-9.6-31.08-15.73-59.79-18.21-85.31-3.11-31.92-.48-58.93 7.79-80.28 9.76-25.17 27.53-42.83 52.83-52.5 43-16.44 86.35-25.84 124.57-34.13 51.74-11.22 89.13-19.33 92.9-38.39 3.31-16.75-19.85-41.21-75.1-79.32-32.59-22.51-43.87-51.72-34.47-89.3 8.21-32.85 30.35-66.78 51.77-99.58 24.92-38.18 48.46-74.25 40.94-98.58-3.08-10-11-17.49-24.29-23-27.26-11.31-43.92-26.7-50.91-47.06-14.53-42.29 16.89-97.66 44.61-146.52 10.56-18.61 20.54-36.2 27.39-51.94 7.74-17.78 8.81-32 3.27-43.43-10.28-21.26-41.64-29.59-74.84-38.4-24.38-6.47-49.6-13.17-66.79-25.47C177 624.53 170.61 605.09 177 579c15-61.13 12.77-62.27-9-73.2-19.31-9.71-55.24-27.77-108.22-104.35-14.65-21.19-19-38.53-13.16-53 12.91-32.27 75.44-45.24 141.64-59 39-8.09 79.37-16.46 113.42-29.23 38.61-14.47 62.85-32.53 74.11-55.21 5.81-11.7 8.25-24.94 7.24-39.34C382 150.28 376.92 133 368 114.22l.45-.21c8.95 18.79 14 36.17 15.13 51.66 1 14.49-1.44 27.81-7.29 39.59-11.32 22.8-35.65 40.94-74.38 55.46-34.09 12.78-74.46 21.15-113.5 29.25-66.07 13.7-128.47 26.64-141.28 58.66-5.73 14.37-1.44 31.52 13.11 52.55 52.91 76.48 88.77 94.51 108 104.19 22.08 11.1 24.38 12.26 9.24 73.77-14.21 57.75 36.64 71.24 85.81 84.3 33.31 8.84 64.78 17.19 75.16 38.67 5.6 11.58 4.54 25.92-3.26 43.84-6.86 15.77-16.84 33.36-27.41 52-27.67 48.76-59 104-44.58 146.11 6.94 20.22 23.51 35.51 50.64 46.75 13.4 5.56 21.44 13.18 24.57 23.3 7.59 24.56-16 60.72-41 99-21.39 32.77-43.51 66.66-51.71 99.43-9.34 37.35 1.88 66.39 34.28 88.77 55.43 38.24 78.65 62.85 75.3 79.83-3.84 19.38-41.36 27.52-93.29 38.78-38.2 8.29-81.5 17.68-124.49 34.11-25.16 9.61-42.84 27.18-52.54 52.21-8.25 21.28-10.86 48.21-7.77 80 2.48 25.49 8.6 54.15 18.19 85.21A694.34 694.34 0 00133 1766.8z"
              fill="url(#prefix__Degradado_sin_nombre_656-6)"
            />
            <path
              d="M136.32 1763.45a689.21 689.21 0 01-38.43-94.27c-9.91-30.78-16.34-59.25-19.13-84.62-3.49-31.74-1.26-58.69 6.64-80.12 9.31-25.26 26.64-43.2 51.51-53.32 44-17.9 89.31-28.15 125.72-36.39 47.93-10.83 82.55-18.66 86.05-36.5 3.17-16.17-19.88-40.47-74.75-78.8-31.9-22.33-42.8-51.28-33.32-88.5 8.3-32.56 30.35-66.26 51.68-98.85 25.15-38.43 48.89-74.72 41.39-99.44-3.06-10.11-11-17.78-24.34-23.45-27-11.49-43.52-27-50.47-47.41-14.43-42.36 16.46-97.37 43.72-145.9 10.32-18.38 20.07-35.74 26.75-51.26 7.67-17.8 8.74-32 3.3-43.45-9.89-20.75-39.64-29.17-71.19-38.06-23.45-6.63-47.76-13.49-64.45-25.92-18.94-14.08-25.34-33.57-19.57-59.6 6.94-31.36 9.8-46.93 8.34-56.31s-7.35-12.7-18.06-18.65c-18.39-10.22-52.61-29.25-103.88-103.77-14.56-21.16-18.84-38.51-13.1-53 12.57-31.86 73.05-45.1 137.09-59.08 37.7-8.23 76.67-16.73 109.62-29.51 37.34-14.49 60.86-32.4 71.9-54.76 12.07-24.43 9.63-54-7.45-90.44l.45-.21c8.77 18.7 13.73 36 14.76 51.43.95 14.42-1.51 27.69-7.31 39.44-11.1 22.48-34.71 40.47-72.17 55-33 12.8-72 21.31-109.69 29.54C124 301.22 63.66 314.39 51.15 346c-5.67 14.35-1.4 31.54 13.05 52.56C115.4 473 149.56 492 167.91 502.19c10.85 6 16.82 9.35 18.32 19 1.46 9.46-1.4 25.07-8.36 56.49-12.77 57.69 36.28 71.54 83.71 84.94 31.64 8.93 61.53 17.37 71.51 38.33 5.51 11.57 4.44 25.92-3.29 43.85-6.69 15.56-16.45 32.92-26.77 51.31-27.21 48.44-58 103.35-43.69 145.5 6.91 20.27 23.33 35.68 50.2 47.11 13.45 5.73 21.51 13.5 24.62 23.77 7.57 24.94-16.24 61.33-41.45 99.85-42 64.17-89.59 136.91-18.49 186.67 55 38.46 78.16 62.92 74.95 79.31-3.56 18.15-38.32 26-86.43 36.89-36.39 8.23-81.69 18.47-125.64 36.36-24.73 10.07-42 27.91-51.23 53-7.87 21.36-10.1 48.23-6.62 79.89 2.79 25.34 9.22 53.77 19.11 84.52a690.75 690.75 0 0038.4 94.2z"
              fill="url(#prefix__Degradado_sin_nombre_656-7)"
            />
            <path
              d="M140.1 1759.88a685.79 685.79 0 01-39.26-93.18c-10.21-30.47-17-58.71-20-83.94-3.88-31.55-2-58.45 5.49-80 8.87-25.34 25.75-43.55 50.2-54.12 44.86-19.41 90.14-30.07 126.52-38.64 44.31-10.43 76.32-18 79.57-34.63 3.07-15.77-19.19-39.18-74.43-78.28-69.94-49.6-22.47-122 19.42-185.81 25.36-38.66 49.32-75.17 41.85-100.28-3.06-10.27-11-18.1-24.4-23.93-26.75-11.68-43.11-27.3-50-47.77-14.34-42.42 16-97.07 42.83-145.29 10.08-18.13 19.59-35.26 26.12-50.58 7.59-17.82 8.67-32.05 3.32-43.47-9.48-20.24-37.7-28.73-67.58-37.72-22.57-6.79-45.9-13.81-62.16-26.37-18.41-14.24-24.88-33.79-19.77-59.77 6.41-32.57 8.85-47.71 7.38-57.16-1.5-9.64-7.31-13.2-17.85-19.67-17.48-10.73-50-30.7-99.52-103.06-14.46-21.15-18.73-38.5-13-53.05 12.3-31.48 70.75-44.92 132.63-59.14 36.38-8.35 74-17 105.82-29.79C329.23 239.78 352 222 362.84 200c12-24.38 9.66-53.84-7.06-90.08l.45-.2c16.79 36.38 19.1 66 7.06 90.5-10.87 22.14-33.75 40-70 54.53-31.87 12.8-69.5 21.45-105.89 29.81-61.7 14.19-120.03 27.59-132.23 58.83C49.55 357.77 53.8 375 68.16 396c49.44 72.27 81.92 92.2 99.37 102.91 21.39 13.13 23.13 14.2 10.7 77.36-5.07 25.78 1.34 45.16 19.59 59.27C214 648 237.29 655 259.81 661.81c30 9 58.32 17.55 67.89 38 5.42 11.57 4.33 25.92-3.31 43.89-6.54 15.34-16.06 32.48-26.15 50.62-26.74 48.11-57.06 102.68-42.79 144.87 6.87 20.33 23.15 35.86 49.76 47.47 13.5 5.9 21.58 13.83 24.68 24.25 7.54 25.33-16.48 61.94-41.91 100.7-41.76 63.65-89.1 135.8-19.55 185.12 55.42 39.23 77.74 62.8 74.63 78.79-3.31 17-35.45 24.54-79.94 35-36.36 8.56-81.62 19.22-126.44 38.61-24.31 10.52-41.11 28.62-49.93 53.83-7.49 21.43-9.33 48.25-5.47 79.72 3.1 25.19 9.84 53.4 20 83.84a685.15 685.15 0 0039.23 93.11z"
              fill="url(#prefix__Degradado_sin_nombre_656-8)"
            />
            <path
              d="M143.88 1756.31a681.42 681.42 0 01-40.09-92.1C93.29 1634 86.23 1606 82.82 1581c-4.26-31.37-2.8-58.21 4.35-79.78 8.41-25.42 24.86-43.89 48.87-54.91 45.68-21 90.78-32 127-40.88 40.89-10 70.44-17.27 73.43-32.78 2.93-15.12-19.25-38.37-74.13-77.74-68.4-49.17-21.18-120.94 20.47-184.26 25.59-38.89 49.76-75.63 42.31-101.13-3-10.43-11-18.42-24.45-24.42-26.49-11.86-42.71-27.6-49.59-48.12-14.25-42.48 15.6-96.77 41.93-144.68 9.84-17.89 19.13-34.79 25.49-49.9 7.52-17.86 8.62-32.1 3.36-43.53-9.06-19.72-35.75-28.29-64-37.35-21.65-7-44-14.13-59.8-26.83-17.89-14.39-24.42-34-20-59.92 11-63.82 9.72-64.67-11.24-78.85-16.6-11.23-47.5-32.14-95.15-102.22-14.37-21.14-18.62-38.5-13-53.08 12-31.09 68.41-44.75 128.14-59.21 35.05-8.48 71.3-17.26 102-30.06 34.83-14.51 56.9-32.11 67.48-53.81 11.86-24.32 9.67-53.67-6.68-89.71l.45-.21c16.42 36.19 18.61 65.67 6.68 90.14-10.64 21.81-32.79 39.49-67.73 54-30.76 12.82-67 21.6-102.11 30.09-59.6 14.43-115.89 28-127.78 58.9-5.56 14.42-1.33 31.63 12.94 52.62 47.6 70 78.45 90.87 95 102.08 21.21 14.36 22.48 15.22 11.46 79.35-4.43 25.73 2 45.18 19.77 59.45 15.7 12.64 38 19.81 59.64 26.74 28.37 9.1 55.16 17.7 64.32 37.62 5.31 11.57 4.21 25.93-3.36 43.93-6.37 15.13-15.67 32.05-25.51 50-26.28 47.82-56.08 102-41.9 144.28 6.84 20.38 23 36 49.32 47.82 13.56 6.07 21.65 14.16 24.73 24.74 7.51 25.72-16.72 62.55-42.37 101.55-41.53 63.13-88.6 134.68-20.6 183.57 55.07 39.5 77.3 62.9 74.33 78.25-3.05 15.82-32.73 23.09-73.8 33.16-36.22 8.88-81.28 19.92-126.92 40.86-23.88 11-40.24 29.32-48.61 54.6-7.12 21.5-8.57 48.27-4.32 79.56 3.4 25 10.45 53 20.94 83.16a682.21 682.21 0 0040.05 92z"
              fill="url(#prefix__Degradado_sin_nombre_656-9)"
            />
            <path
              d="M147.65 1752.74a678.92 678.92 0 01-40.91-91c-10.8-29.87-18.16-57.66-21.88-82.59-4.65-31.19-3.57-58 3.19-79.62 8-25.49 24-44.22 47.55-55.67 46.44-22.53 91.21-33.95 127.19-43.13 37.66-9.6 64.88-16.54 67.64-30.94 2.8-14.63-18.6-37-73.88-77.19-66.85-48.74-19.9-119.9 21.52-182.68 25.82-39.14 50.2-76.1 42.79-102-3-10.58-11.05-18.74-24.51-24.92-26.23-12-42.3-27.88-49.15-48.45C233 892 262.36 838 288.23 790.45c9.6-17.66 18.66-34.34 24.86-49.24 7.45-17.9 8.57-32.16 3.42-43.59-8.65-19.21-33.84-27.85-60.5-37-20.72-7.1-42.14-14.45-57.43-27.27-17.34-14.56-23.92-34.21-20.12-60.08 9.55-64.94 9.15-65.24-12.06-81-16.83-12.55-45-33.54-90.73-101.19-14.28-21.13-18.52-38.51-13-53.13 11.67-30.7 66.06-44.58 123.64-59.28C220.1 270 255 261.13 284.6 248.32c33.57-14.51 54.92-32 65.26-53.33 11.75-24.27 9.69-53.5-6.3-89.36l.46-.2c16.05 36 18.11 65.36 6.29 89.78-10.4 21.48-31.83 39-65.51 53.57-29.65 12.82-64.56 21.73-98.32 30.35-57.46 14.66-111.72 28.51-123.3 59-5.5 14.45-1.28 31.68 12.91 52.67 45.67 67.58 73.8 88.54 90.61 101.07 21 15.67 21.85 16.28 12.26 81.52-3.78 25.68 2.75 45.18 19.95 59.61 15.21 12.77 36.59 20.1 57.26 27.19 26.77 9.18 52.05 17.84 60.79 37.25 5.21 11.57 4.1 26-3.41 44-6.21 14.93-15.28 31.62-24.88 49.29-25.82 47.5-55.09 101.34-41 143.67 6.81 20.43 22.79 36.18 48.89 48.16 13.6 6.24 21.71 14.5 24.78 25.24 7.48 26.12-17 63.17-42.85 102.4-41.3 62.6-88.11 133.55-21.64 182 55.46 40.32 76.92 62.83 74.07 77.69s-28.75 21.32-68 31.33c-36 9.17-80.7 20.58-127.09 43.09-23.45 11.39-39.36 30-47.29 55.37-6.74 21.57-7.81 48.28-3.17 79.39 3.71 24.9 11.06 52.65 21.85 82.49a678.25 678.25 0 0040.88 91z"
              fill="url(#prefix__Degradado_sin_nombre_656-10)"
            />
            <path
              d="M151.43 1749.17a675.94 675.94 0 01-41.73-90c-11.1-29.57-18.77-57.13-22.8-81.92-5-31-4.35-57.73 2-79.45 7.52-25.57 23.07-44.55 46.23-56.42 47.12-24.15 93.34-36.41 127.08-45.36 35.9-9.52 59.62-15.81 62.15-29.13 2.65-13.9-18.69-36.1-73.64-76.62-29.18-21.58-38.58-49.46-28.75-85.23 8.63-31.38 30.32-64.17 51.31-95.88 26-39.37 50.64-76.55 43.27-102.85-3-10.75-11-19.07-24.57-25.43-26-12.21-41.9-28.17-48.71-48.79-14.07-42.59 14.72-96.18 40.12-143.46 9.36-17.42 18.2-33.88 24.24-48.57 7.38-18 8.52-32.25 3.49-43.69-8.23-18.7-31.93-27.4-57-36.61-19.78-7.26-40.24-14.77-55-27.72-16.79-14.72-23.43-34.41-20.28-60.21 8.08-66.24 8.08-66.24-12.93-83.47-15.91-13.04-42.61-34.89-86.28-99.92-14.19-21.13-18.42-38.52-12.93-53.18 11.35-30.31 63.72-44.41 119.16-59.34 32.43-8.74 66-17.76 94.48-30.57 32.32-14.52 52.93-31.81 63-52.85 11.63-24.23 9.7-53.35-5.92-89l.46-.2c15.68 35.81 17.61 65.07 5.91 89.44-10.15 21.15-30.85 38.51-63.27 53.08C252 258.63 218.44 267.66 186 276.4c-55.32 14.89-107.57 29-118.82 59-5.43 14.5-1.22 31.75 12.88 52.73 43.62 65 70.25 86.79 86.15 99.83 21.23 17.4 21.23 17.4 13.12 83.92-3.13 25.62 3.45 45.17 20.11 59.77 14.72 12.9 35.13 20.39 54.87 27.63 25.2 9.25 49 18 57.31 36.88 5.1 11.57 4 26-3.47 44.08-6.05 14.71-14.9 31.18-24.27 48.62-25.36 47.22-54.1 100.7-40.1 143.14 6.77 20.48 22.62 36.35 48.45 48.5 13.66 6.42 21.79 14.84 24.84 25.75 7.44 26.52-17.23 63.79-43.34 103.25-21 31.68-42.63 64.44-51.24 95.75-9.77 35.54-.42 63.24 28.57 84.68 55.85 41.19 76.56 62.81 73.83 77.12-2.59 13.64-26.43 20-62.51 29.52-33.72 9-79.91 21.2-127 45.33-23 11.8-38.5 30.68-46 56.11-6.36 21.64-7 48.3-2 79.22 4 24.76 11.67 52.29 22.76 81.83a673.78 673.78 0 0041.71 89.91z"
              fill="url(#prefix__Degradado_sin_nombre_656-11)"
            />
            <path
              d="M155.21 1745.6a672.19 672.19 0 01-42.56-88.93c-11.4-29.28-19.37-56.63-23.71-81.27-5.42-30.83-5.12-57.5.89-79.28 7.07-25.64 22.18-44.86 44.9-57.14 47.76-25.82 93.4-38.41 126.72-47.59 32.9-9.08 54.64-15.07 57-27.36 2.51-13.3-18.08-34.62-73.45-76-28.49-21.39-37.53-49-27.61-84.4 8.7-31.08 30.31-63.63 51.2-95.12 26.28-39.59 51.1-77 43.77-103.7-3-10.92-11.06-19.41-24.64-25.95-25.7-12.39-41.48-28.45-48.26-49.12-14-42.65 14.27-95.88 39.21-142.85 9.13-17.19 17.75-33.43 23.63-47.92 7.31-18 8.47-32.34 3.56-43.79-7.8-18.2-30.05-27-53.6-36.23-18.85-7.42-38.34-15.09-52.62-28.17-16.23-14.88-22.91-34.62-20.42-60.34l.62-6.4c6-61.34 6-61.34-14.52-79.8-15.05-13.55-40.23-36.23-81.69-98.37-14.1-21.13-18.32-38.56-12.91-53.27 11-29.91 61.37-44.23 114.69-59.39 31.13-8.85 63.31-18 90.72-30.81 31.06-14.52 51-31.64 60.8-52.35 11.51-24.19 9.69-53.2-5.55-88.69l.46-.19c15.3 35.62 17.12 64.77 5.54 89.09-9.9 20.82-29.86 38-61 52.59-27.44 12.83-59.65 22-90.8 30.84-53.18 15.12-103.42 29.41-114.35 59.09C65.8 347.3 70 364.57 84 385.56c41.43 62.09 66.57 84.74 81.6 98.28 20.68 18.63 20.68 18.63 14.69 80.22l-.62 6.4c-2.48 25.56 4.15 45.15 20.26 59.92 14.21 13 33.66 20.67 52.46 28.08 23.65 9.31 46 18.1 53.88 36.49 5 11.58 3.8 26-3.56 44.18-5.89 14.51-14.52 30.76-23.65 48-24.89 46.88-53.1 100-39.18 142.46 6.74 20.53 22.44 36.51 48 48.82 13.72 6.61 21.86 15.21 24.9 26.28 7.4 26.93-17.49 64.42-43.83 104.11-20.87 31.45-42.46 64-51.14 95-9.85 35.18-.88 62.61 27.43 83.86 56.27 42.08 76.23 62.82 73.64 76.52-2.38 12.6-24.23 18.62-57.32 27.75-33.3 9.18-78.9 21.76-126.61 47.54-22.6 12.22-37.63 31.34-44.66 56.85-6 21.7-6.28 48.3-.88 79 4.33 24.61 12.3 51.92 23.69 81.17a671.45 671.45 0 0042.52 88.86z"
              fill="url(#prefix__Degradado_sin_nombre_656-12)"
            />
            <path
              d="M159 1742a669.38 669.38 0 01-43.38-87.9c-11.7-29-20-56.12-24.63-80.62-5.8-30.65-5.9-57.26-.27-79.1 6.63-25.71 21.29-45.18 43.58-57.86 48.34-27.51 93.29-40.4 126.11-49.81 30.07-8.63 49.94-14.33 52.05-25.61 1.23-6.57-3.56-15.11-15.08-26.89s-29.32-26.6-58.2-48.52c-27.82-21.2-36.49-48.54-26.49-83.57 8.78-30.78 30.29-63.1 51.1-94.35 26.52-39.82 51.56-77.44 44.27-104.56-3-11.09-11.06-19.76-24.7-26.49-25.44-12.55-41.08-28.72-47.83-49.44-13.9-42.69 13.84-95.58 38.3-142.24 8.9-17 17.3-33 23-47.26 9.4-23.48 8.71-40.47-2.19-53.47-9.77-11.65-26.58-18.77-44.39-26.3-35.08-14.85-74.84-31.68-70.72-89.06.34-4.8.67-9.25 1-13.41 4.13-56.06 4.13-56.06-16-75.94-14.18-14-37.92-37.48-77-96.4-14-21.14-18.23-38.59-12.89-53.35 10.67-29.53 59-44.06 110.21-59.44 29.83-9 60.68-18.24 87-31 29.82-14.52 49-31.48 58.57-51.85 11.37-24.16 9.68-53.06-5.19-88.37l.46-.19c14.92 35.44 16.62 64.48 5.18 88.77-9.65 20.48-28.88 37.52-58.8 52.09-26.34 12.81-57.21 22.14-87.07 31.06-51 15.34-99.29 29.84-109.87 59.13C69.85 344.65 74.05 362 88 383c39 58.87 62.73 82.31 76.9 96.31 20.28 20.05 20.28 20.05 16.14 76.34-.3 4.15-.63 8.61-1 13.41-1.83 25.47 4.84 45.11 20.4 60 13.7 13.14 32.16 21 50 28.52S285.19 672.28 295 684c11 13.16 11.75 30.31 2.27 54-5.73 14.3-14.13 30.33-23 47.3-24.42 46.58-52.11 99.36-38.27 141.85 6.7 20.59 22.26 36.66 47.57 49.15 13.78 6.8 21.94 15.57 25 26.81 7.34 27.34-17.76 65.05-44.34 105-20.79 31.22-42.28 63.5-51 94.2-9.94 34.82-1.33 62 26.31 83 56.68 43 75.91 62.88 73.47 75.9-2.17 11.59-22.15 17.32-52.41 26-32.79 9.4-77.71 22.28-126 49.76-22.17 12.62-36.75 32-43.34 57.56-5.61 21.76-5.52 48.3.27 78.87 4.64 24.48 12.92 51.57 24.6 80.53a667.18 667.18 0 0043.35 87.83z"
              fill="url(#prefix__Degradado_sin_nombre_656-13)"
            />
            <path
              d="M162.77 1738.46a664.66 664.66 0 01-44.2-86.89c-12-28.7-20.6-55.61-25.55-80-6.19-30.47-6.67-57-1.42-78.92 6.17-25.77 20.39-45.48 42.26-58.56 48.85-29.24 93-42.41 125.24-52 27.41-8.18 45.52-13.58 47.43-23.9 2.23-12-16.23-30.91-73.15-74.77-27.16-21-35.46-48.07-25.38-82.74 8.86-30.47 30.28-62.54 51-93.56 26.75-40.05 52-77.88 44.78-105.42-3-11.27-11.06-20.11-24.76-27-25.18-12.72-40.68-29-47.39-49.76-13.82-42.76 13.37-95.3 37.37-141.65 8.66-16.73 16.85-32.53 22.41-46.61 20-50.72-9.24-64.08-43.15-79.55-33.29-15.19-71-32.41-68.37-89.58q.54-11.61 1-20.74c2.6-50.73 2.6-50.73-17.39-72.36-13.33-14.43-35.66-38.59-72-93.82-13.91-21.15-18.13-38.63-12.88-53.47 10.32-29.13 56.67-43.87 105.75-59.48 28.54-9.07 58-18.45 83.25-31.25 28.57-14.51 47-31.3 56.33-51.34 11.24-24.13 9.65-52.93-4.83-88l.46-.19c14.54 35.26 16.12 64.19 4.82 88.45-9.38 20.14-27.88 37-56.55 51.57-25.24 12.82-54.77 22.21-83.33 31.29-49 15.57-95.19 30.27-105.43 59.17-5.19 14.67-1 32 12.84 53 36.34 55.2 58.64 79.34 72 93.76C184 495.9 184 495.9 181.42 546.83c-.31 6.1-.67 13-1 20.74-2.64 56.84 34.93 74 68.08 89.11 16.87 7.69 32.79 15 41.94 26.68 10.24 13.11 10.71 30.11 1.46 53.5-5.57 14.1-13.76 29.92-22.42 46.66-24 46.27-51.11 98.71-37.36 141.24 6.67 20.64 22.09 36.82 47.14 49.47 13.84 7 22 15.94 25 27.35 7.29 27.76-18 65.68-44.86 105.83-20.69 31-42.09 63-50.93 93.42-10 34.45-1.77 61.34 25.2 82.2 57.11 44 75.61 63 73.34 75.26-2 10.62-20.19 16.06-47.78 24.29-32.21 9.6-76.33 22.77-125.13 52-21.74 13-35.88 32.61-42 58.25-5.23 21.82-4.75 48.3 1.43 78.7 4.94 24.32 13.53 51.2 25.51 79.87a666.83 666.83 0 0044.17 86.82z"
              fill="url(#prefix__Degradado_sin_nombre_656-14)"
            />
            <path
              d="M166.55 1734.89a663.55 663.55 0 01-45-85.88c-12.3-28.43-21.21-55.12-26.47-79.34-6.58-30.29-7.44-56.78-2.58-78.73 5.73-25.84 19.51-45.78 40.95-59.25 49.32-31 94.36-45 124.16-54.22 24.89-7.73 41.34-12.83 43.07-22.23 2.3-12.59-21.72-33.92-73.07-74.1-26.49-20.81-34.42-47.6-24.27-81.9 8.94-30.17 30.27-62 50.9-92.77 27-40.27 52.49-78.32 45.31-106.28-2.95-11.45-11.07-20.47-24.83-27.59-38.07-19.68-54.42-48.26-50-87.4 3.85-34 22.78-71 39.47-103.68 8.44-16.51 16.4-32.1 21.81-46 19.52-50.1-7.95-63.65-39.75-79.34-31.5-15.54-67.19-33.15-66-90.09.23-11 .51-20.18.76-28.25 1.41-45.53 1.41-45.53-18.84-69.44-12.48-14.74-33.4-39.4-66.7-90.4-13.84-21.17-18.06-38.7-12.9-53.59 10-28.74 54.33-43.69 101.3-59.51 27.25-9.18 55.43-18.68 79.54-31.46 27.33-14.5 45-31.12 54.08-50.82 11.09-24.11 9.62-52.81-4.48-87.75l.46-.19c14.16 35.08 15.62 63.91 4.47 88.15-9.11 19.8-26.87 36.5-54.3 51.05-24.15 12.81-52.34 22.3-79.61 31.49-46.85 15.78-91.11 30.69-101 59.21-5.1 14.73-.9 32.12 12.85 53.15 33.28 50.91 54.19 75.6 66.68 90.34 20.37 24.06 20.37 24.06 19 69.79-.25 8.07-.53 17.21-.76 28.24-1.19 56.62 34.35 74.16 65.71 89.63 32 15.8 59.68 29.44 40 80-5.42 13.91-13.39 29.51-21.83 46-16.68 32.64-35.58 69.64-39.43 103.51-4.41 38.9 11.85 67.33 49.71 86.9 13.9 7.19 22.1 16.31 25.08 27.91 7.24 28.18-18.32 66.31-45.38 106.68-20.6 30.74-41.91 62.53-50.83 92.63-10.09 34.08-2.21 60.69 24.1 81.37 58.26 45.58 75.37 63 73.25 74.58-1.78 9.69-17.45 14.56-43.41 22.61-29.78 9.25-74.78 23.22-124 54.17-21.33 13.4-35 33.23-40.73 58.93-4.85 21.89-4 48.31 2.58 78.53 5.26 24.19 14.15 50.85 26.44 79.24a661.49 661.49 0 0045 85.8z"
              fill="url(#prefix__Degradado_sin_nombre_656-15)"
            />
            <path
              d="M170.33 1731.31a659.84 659.84 0 01-45.86-84.88c-12.6-28.14-21.81-54.62-27.39-78.7-7-30.12-8.22-56.55-3.73-78.55 5.28-25.91 18.62-46.07 39.63-59.92 49.73-32.77 93.74-47 122.86-56.41 23.33-7.54 37.42-12.09 39-20.61 2.1-11.54-19.81-31.09-73-73.38-25.83-20.63-33.41-47.15-23.17-81.07 9-29.86 30.25-61.43 50.79-92 27.24-40.5 53-78.75 45.84-107.14-2.92-11.63-11.06-20.84-24.89-28.16-37.65-19.91-53.87-48.58-49.6-87.64 3.7-33.88 22.25-70.67 38.61-103.13 8.21-16.29 16-31.67 21.21-45.35 8.72-22.69 8.78-39.26.19-52.14-7.72-11.59-21.75-19.1-36.59-27-29.68-15.88-63.31-33.88-63.57-90.54-.06-14.66.1-26.35.23-35.74.56-40.77.56-40.77-20.6-67.88-12.38-15.87-31.09-39.85-60.81-85.64-13.75-21.19-18-38.77-12.92-53.74 9.59-28.36 52-43.5 96.85-59.54 26-9.28 52.83-18.88 75.85-31.65 26.09-14.48 43-30.93 51.83-50.29 10.92-24.09 9.57-52.7-4.15-87.46l.47-.18c13.77 34.89 15.12 63.63 4.13 87.84-8.83 19.47-25.85 36-52 50.52-23.06 12.8-49.93 22.4-75.92 31.69-44.76 16-87 31.1-96.54 59.23-5 14.81-.8 32.25 12.86 53.31 29.71 45.77 48.41 69.74 60.79 85.6C181.9 488 181.9 488 181.34 529c-.13 9.39-.29 21.07-.23 35.73.26 56.36 33.75 74.28 63.3 90.1 30 16 55.81 29.87 36.64 79.81-5.26 13.7-13 29.09-21.23 45.39C243.47 812.4 225 849.15 221.26 883c-4.25 38.83 11.89 67.33 49.33 87.14 14 7.38 22.19 16.7 25.14 28.48 7.19 28.6-18.6 66.94-45.91 107.54-20.51 30.49-41.73 62-50.72 91.83-10.17 33.7-2.65 60 23 80.53 55.85 44.4 75.3 62.23 73.19 73.86-1.59 8.81-15.79 13.4-39.3 21-29.09 9.41-73.06 23.62-122.73 56.36-20.9 13.78-34.17 33.83-39.42 59.59-4.47 21.94-3.22 48.3 3.73 78.34 5.56 24.05 14.77 50.5 27.35 78.61a662 662 0 0045.82 84.72z"
              fill="url(#prefix__Degradado_sin_nombre_656-16)"
            />
            <path
              d="M174.11 1727.74c-.39-.56-38.44-56.76-62.45-121.6-14.1-38.09-20.67-71.68-19.52-99.82 1.44-35.22 15-62 40.41-79.49 50.07-34.58 93-49 121.34-58.59 21-7.08 33.74-11.36 35.11-19 1.95-10.9-18.47-28.57-73-72.64-25.17-20.43-32.39-46.68-22.08-80.22 9.09-29.56 30.23-60.87 50.68-91.16 27.49-40.72 53.45-79.17 46.38-108-2.9-11.81-11.06-21.21-25-28.73-83.6-45.29-43.59-125.8-11.44-190.49 8-16.07 15.53-31.24 20.63-44.73 8.49-22.46 8.8-38.9 1-51.74-7.07-11.59-20.19-19.23-34.07-27.33-27.85-16.23-59.4-34.63-61.1-91-.55-18.55-.55-32.1-.54-43 0-36.73 0-36.73-23.14-68.67-11.33-15.64-28.46-39.28-53.9-78.77-13.67-21.22-17.9-38.85-13-53.9 9.2-28 49.62-43.32 92.41-59.57 24.7-9.37 50.24-19.07 72.17-31.82 24.86-14.46 41.08-30.74 49.58-49.76 10.75-24.07 9.5-52.59-3.82-87.17l.47-.18c13.37 34.71 14.61 63.35 3.8 87.55-8.54 19.13-24.83 35.48-49.78 50-22 12.77-47.52 22.48-72.24 31.86-42.68 16.21-83 31.51-92.12 59.26-4.9 14.89-.68 32.39 12.9 53.47 25.43 39.48 42.56 63.11 53.89 78.75 23.25 32.07 23.25 32.07 23.23 69 0 10.89 0 24.44.54 43 1.69 56 33.12 74.37 60.85 90.54 13.94 8.13 27.11 15.81 34.25 27.5 7.91 13 7.62 29.56-.94 52.18C270.53 747 263 762.14 255 778.22c-16 32.21-34.15 68.71-37.69 102.43-4.07 38.76 11.94 67.34 49 87.37 14 7.59 22.27 17.09 25.21 29.06 7.12 29-18.91 67.58-46.46 108.39-20.42 30.25-41.54 61.54-50.61 91-10.25 33.33-3.08 59.4 21.91 79.69 54.7 44.2 75.18 62 73.18 73.12-1.42 8-14.23 12.27-35.44 19.42-28.36 9.55-71.21 24-121.22 58.52-25.24 17.43-38.76 44-40.19 79.1-1.15 28.07 5.41 61.59 19.49 99.62 24 64.79 62 120.94 62.39 121.5z"
              fill="url(#prefix__Degradado_sin_nombre_656-17)"
            />
            <path
              d="M177.89 1724.17c-.39-.55-38.94-56-63.71-120.29-14.55-37.79-21.57-71.21-20.87-99.34.89-35.2 13.94-62.16 38.8-80.14 50.35-36.4 92-51.05 119.62-60.75 18.9-6.64 30.31-10.65 31.52-17.51 1.83-10.34-19.58-27.92-71.28-70.4l-1.77-1.45c-24.51-20.25-31.37-46.22-21-79.39 9.16-29.25 30.2-60.3 50.56-90.33 27.74-40.94 53.94-79.6 46.94-108.86-2.88-12-11.06-21.6-25-29.33-82.7-45.76-43.49-125.81-12-190.13 7.77-15.85 15.1-30.82 20.05-44.11 8.28-22.26 8.84-38.59 1.75-51.39-6.42-11.58-18.64-19.38-31.59-27.63-12.47-8-26.6-17-37.67-30.77-12.68-15.81-19.52-35.61-20.91-60.54-1.26-22.6-1.38-38.34-1.46-49.83-.26-33.71-.26-33.71-27.3-73.43-10.56-15.51-25-36.76-45.22-68.34-13.58-21.26-17.84-38.95-13-54.09 8.8-27.59 47.27-43.13 88-59.58 71.4-28.86 152.33-61.54 112.32-168.1l.47-.17C313.07 136 308.3 173.2 280.58 202c-24.27 25.2-61.78 40.36-98 55-40.61 16.41-79 31.91-87.71 59.27-4.78 15-.55 32.55 13 53.67 20.13 31.58 34.55 52.82 45.13 68.33 27.13 39.84 27.13 39.84 27.39 73.7.08 11.49.2 27.22 1.46 49.81 1.39 24.82 8.19 44.53 20.8 60.26 11 13.74 25.11 22.72 37.55 30.65 13 8.29 25.28 16.13 31.75 27.82 7.17 12.94 6.62 29.4-1.71 51.8-5 13.32-12.3 28.3-20.07 44.16-31.43 64.13-70.52 143.94 11.76 189.53 14.1 7.79 22.36 17.49 25.27 29.65 7.06 29.47-19.21 68.22-47 109.25-20.33 30-41.36 61-50.5 90.2-10.32 33-3.51 58.75 20.83 78.85l1.77 1.45c51.86 42.61 73.33 60.25 71.45 70.88-1.26 7.15-12.77 11.19-31.84 17.89-27.56 9.69-69.2 24.33-119.49 60.69-24.73 17.88-37.72 44.71-38.6 79.74-.7 28.06 6.3 61.42 20.83 99.14 24.75 64.26 63.27 119.64 63.65 120.2z"
              fill="url(#prefix__Degradado_sin_nombre_656-18)"
            />
            <path
              d="M181.66 1720.6c-.39-.54-39.43-55.2-65-119-15-37.48-22.47-70.74-22.21-98.85.33-35.19 12.85-62.36 37.2-80.77 50.56-38.25 92.59-53.68 117.7-62.9 16.91-6.21 27.11-10 28.16-16.06 1.53-8.84-15.61-23.11-57-57.58l-16.1-13.44c-23.86-20-30.37-45.74-19.91-78.54 9.24-28.94 30.19-59.73 50.44-89.51 28-41.14 54.44-80 47.51-109.71-2.85-12.2-11.06-22-25.09-29.93-81.76-46.25-43.36-125.84-12.5-189.79 7.55-15.64 14.68-30.42 19.47-43.52 17.59-48-1.92-61.73-26.62-79-11.54-8.09-24.61-17.26-35.12-31.16-12.05-15.95-18.88-35.71-20.9-60.41-2.17-26.67-2.33-43.68-2.44-56.1-.3-32-.3-32-34.68-85-8.74-13.45-19.6-30.18-33.26-51.72-13.5-21.29-17.78-39.05-13.07-54.29 8.4-27.21 44.92-42.94 83.58-59.6 68-29.31 145.1-62.52 106.74-167.41l.47-.18c17.19 47 12.72 83.83-13.66 112.59-23.09 25.18-58.81 40.57-93.35 55.46-38.56 16.61-75 32.3-83.31 59.29-4.65 15.09-.39 32.71 13 53.88C125.39 388.87 136.25 405.6 145 419c34.46 53.07 34.46 53.07 34.76 85.24.11 12.41.27 29.41 2.44 56.06 2 24.6 8.81 44.28 20.8 60.15 10.46 13.84 23.5 23 35 31 24.87 17.45 44.52 31.22 26.8 79.64-4.8 13.12-11.94 27.9-19.49 43.55-30.77 63.77-69.06 143.12 12.3 189.14 14.16 8 22.44 17.91 25.33 30.26 7 29.91-19.52 68.87-47.58 110.11-20.24 29.74-41.17 60.5-50.38 89.37-10.4 32.58-3.94 58.1 19.75 78q8.6 7.19 16.11 13.43c41.57 34.61 58.77 48.93 57.2 58-1.11 6.39-11.41 10.18-28.48 16.45-25.09 9.21-67.08 24.63-117.58 62.82-24.22 18.32-36.67 45.36-37 80.38-.26 28 7.2 61.24 22.18 98.67 25.51 63.74 64.52 118.35 64.91 118.89z"
              fill="url(#prefix__Degradado_sin_nombre_656-19)"
            />
            <path
              d="M185.44 1717c-.39-.54-39.94-54.44-66.24-117.72-15.46-37.18-23.38-70.28-23.56-98.38-.22-35.16 11.76-62.54 35.6-81.38 50.7-40.08 91.33-55.7 115.6-65 15.06-5.79 24.15-9.28 25-14.68 1.26-7.5-12.48-19-45.65-46.84-8.08-6.77-17.24-14.45-27.62-23.28-23.22-19.87-29.38-45.28-18.84-77.7 9.31-28.62 30.16-59.15 50.32-88.67 28.25-41.35 54.94-80.42 48.08-110.56-2.82-12.4-11.05-22.4-25.15-30.55-80.82-46.74-43.23-125.87-13-189.45 7.33-15.43 14.26-30 18.9-42.92 17.16-47.69-.76-61.59-23.45-79.19-21.17-16.42-47.51-36.85-53.41-91.74a536.28 536.28 0 01-3.4-61.68c-.12-31.95-.12-31.95-48.4-108.48l-15-23.78c-13.42-21.34-17.72-39.18-13.16-54.52 8-26.84 42.57-42.76 79.19-59.62 64.62-29.75 137.86-63.46 101.15-166.71l.47-.17c16.45 46.25 12.27 82.7-12.76 111.45-21.9 25.16-55.84 40.78-88.65 55.88-36.52 16.82-71 32.69-78.92 59.31-4.52 15.2-.23 32.9 13.11 54.11 5.45 8.67 10.43 16.56 15 23.78 48.35 76.65 48.35 76.65 48.5 108.75a535.46 535.46 0 003.4 61.63c5.88 54.68 32.12 75 53.22 91.39C258.62 668 276.67 682 259.38 730c-4.65 12.93-11.58 27.52-18.92 43-30.12 63.4-67.6 142.3 12.82 188.8 14.23 8.23 22.54 18.33 25.39 30.88 6.9 30.36-19.84 69.51-48.15 111-20.15 29.49-41 60-50.26 88.54-5 15.39-6.25 28.81-3.79 41 2.77 13.75 10.12 25.58 22.47 36.15 10.38 8.83 19.54 16.51 27.62 23.28 33.33 28 47.13 39.52 45.83 47.3-.95 5.68-9.62 9-25.37 15.07-24.25 9.32-64.83 24.92-115.47 65-23.71 18.74-35.63 46-35.41 81 .18 28 8.09 61.06 23.52 98.18 26.27 63.23 65.79 117.09 66.19 117.62z"
              fill="url(#prefix__Degradado_sin_nombre_656-20)"
            />
            <path
              d="M189.22 1713.46c-.4-.53-40.45-53.69-67.52-116.46-15.91-36.88-24.29-69.82-24.9-97.89-.78-35.14 10.67-62.72 34-82 50.76-41.9 89.92-57.69 113.31-67.13 13.37-5.39 21.44-8.64 22.19-13.39 1-6.33-10.77-16.2-36.58-37.84-9.94-8.33-22.31-18.7-36.9-31.33-22.57-19.67-28.38-44.81-17.77-76.85 9.38-28.31 30.13-58.56 50.2-87.82 28.51-41.56 55.44-80.82 48.66-111.42-2.79-12.6-11-22.8-25.21-31.18-79.88-47.2-43.1-125.87-13.55-189.09 7.12-15.23 13.85-29.61 18.34-42.33 7.73-21.86 9-38 4.08-50.66-4.52-11.64-13.74-19.6-24.42-28.82-19.38-16.74-43.51-37.57-50.74-91.75-4.55-34.16-4.39-52.91-4.27-66.6.28-31 .3-33.56-58.91-128.52-13.34-21.38-17.68-39.3-13.23-54.76 7.54-26.46 40.22-42.57 74.81-59.63C242 217.8 311.4 183.59 276.34 82l.47-.16c15.71 45.5 11.83 81.57-11.85 110.29-20.73 25.13-52.88 41-84 56.31-34.49 17-67.07 33.07-74.55 59.32-4.37 15.32 0 33.1 13.22 54.37 59.28 95.08 59.26 97.63 59 128.78-.12 13.68-.28 32.41 4.27 66.53 7.2 54 31.25 74.76 50.57 91.45 10.72 9.26 20 17.26 24.56 29 5 12.82 3.69 29-4.07 51-4.51 12.75-11.24 27.14-18.36 42.38-29.47 63-66.14 141.48 13.34 188.45 14.3 8.45 22.63 18.76 25.45 31.5 6.82 30.82-20.17 70.16-48.74 111.82-20 29.22-40.78 59.45-50.13 87.69-5 15.23-6.38 28.49-4.08 40.56 2.6 13.59 9.69 25.29 21.7 35.75 14.58 12.63 26.95 23 36.89 31.33 26.74 22.41 37.8 31.69 36.76 38.3-.8 5-8.51 8.13-22.5 13.78-23.37 9.42-62.48 25.19-113.18 67-23.21 19.17-34.59 46.62-33.82 81.59.61 28 9 60.89 24.85 97.71 27 62.71 67.07 115.83 67.47 116.36z"
              fill="url(#prefix__Degradado_sin_nombre_656-21)"
            />
            <path
              d="M193 1709.89c-.4-.52-41-53-68.81-115.21-16.36-36.58-25.19-69.36-26.25-97.42-1.32-35.11 9.59-62.89 32.43-82.57 50.72-43.71 89.83-60.29 110.84-69.2 12.22-5.18 19-8 19.56-12.19.78-5.3-8.66-13.15-29.35-30.33-11.59-9.63-26-21.61-44.39-37.83-21.93-19.47-27.39-44.33-16.72-76 9.45-28 30.1-58 50.07-87 28.78-41.76 55.95-81.21 49.26-112.27-2.75-12.81-11-23.22-25.27-31.82-35.1-21.19-50.6-50.26-47.41-88.86 2.77-33.4 19-68.71 33.33-99.87 6.91-15 13.44-29.22 17.79-41.76 16.4-47.29 2.27-61-17.29-80-17.61-17-39.51-38.28-48-91.58-6-37.33-5.41-56.73-5-70.9.8-28.66 1-35.48-54.57-125.38-13.25-21.45-17.64-39.46-13.41-55.05 7.09-26.1 37.87-42.4 70.46-59.65 57.84-30.62 123.39-65.33 90-165.24l.48-.16c15 44.75 11.37 80.44-11 109.12-19.55 25.1-49.91 41.18-79.27 56.72C148 262.73 117.3 279 110.27 304.87c-4.2 15.45.17 33.33 13.35 54.65 55.64 90 55.45 96.88 54.64 125.66-.4 14.15-.94 33.53 5 70.82 8.47 53.12 30.31 74.29 47.86 91.3 19.7 19.08 33.93 32.87 17.42 80.47-4.36 12.56-10.89 26.77-17.8 41.8-28.82 62.67-64.68 140.66 13.87 188.09 14.38 8.68 22.72 19.2 25.51 32.15 6.74 31.26-20.5 70.8-49.33 112.66-40.35 58.56-82.06 119.11-33.44 162.3 18.37 16.21 32.79 28.19 44.37 37.81 21.47 17.83 30.36 25.21 29.53 30.8-.65 4.42-7.47 7.32-19.86 12.57-21 8.9-60 25.46-110.71 69.12-22.71 19.57-33.57 47.22-32.25 82.17 1.06 28 9.87 60.7 26.2 97.23 27.81 62.21 68.35 114.59 68.76 115.11z"
              fill="url(#prefix__Degradado_sin_nombre_656-22)"
            />
            <path
              d="M196.78 1706.32c-.41-.52-41.5-52.21-70.11-114-16.81-36.29-26.1-68.91-27.59-96.94-1.88-35.09 8.51-63.06 30.85-83.15C180.52 1366.8 218 1350 238.12 1341c10.77-4.82 16.71-7.47 17.18-11.07.59-4.42-7-10.6-23.59-24.12-12.11-9.86-28.68-23.37-50.48-43-47.51-43-5.93-103.13 34.28-161.24 29-42 56.46-81.61 49.87-113.12-2.73-13-11-23.65-25.35-32.48-34.66-21.39-50.05-50.51-47-89 2.6-33.31 18.45-68.38 32.43-99.32 6.7-14.83 13-28.84 17.24-41.2 16.1-47.34 4-60.61-14.31-80.7-15.83-17.37-35.53-39-45.21-91.13-7.45-40.08-6.4-60.08-5.63-74.69 1.43-27.16 2-37.32-50.36-122.77-13.17-21.51-17.61-39.61-13.57-55.34 6.62-25.75 35.52-42.23 66.12-59.68 54.45-31 116.17-66.24 84.42-164.46l.48-.15c14.21 44 10.92 79.28-10.07 107.92-18.37 25.07-46.95 41.37-74.59 57.13-30.54 17.42-59.35 33.84-65.92 59.42-4 15.59.42 33.57 13.52 55C180 442.5 179.45 452.71 178 480c-.77 14.58-1.82 34.55 5.62 74.57 9.66 52 29.3 73.56 45.08 90.88 9 9.85 16.74 18.36 20.1 30.23 3.66 12.94 1.91 28.66-5.68 51-4.21 12.39-10.54 26.4-17.25 41.25-28.16 62.3-63.2 139.83 14.42 187.71 14.45 8.91 22.81 19.64 25.56 32.8 6.65 31.73-20.84 71.46-49.94 113.51-40.09 57.93-81.54 117.84-34.35 160.59 21.78 19.59 38.35 33.09 50.45 43 16.75 13.65 24.4 19.87 23.78 24.57-.51 3.88-6.54 6.57-17.48 11.47-20.1 9-57.53 25.74-108 71.15-22.23 20-32.56 47.82-30.69 82.75 1.49 28 10.76 60.52 27.54 96.75 28.59 61.71 69.64 113.36 70 113.88z"
              fill="url(#prefix__Degradado_sin_nombre_656-23)"
            />
            <path
              d="M200.56 1702.75c-.42-.51-42-51.48-71.42-112.75-17.27-36-27-68.45-28.94-96.46-2.42-35.06 7.44-63.22 29.3-83.71 50.32-47.16 86.12-64.12 105.35-73.23 9.48-4.49 14.7-7 15.05-10.07.42-3.66-5.67-8.47-19-19-12.18-9.62-30.58-24.15-55.44-47-46.09-42.6-4.78-102 35.18-159.51 29.3-42.16 57-82 50.48-114-2.69-13.23-11-24.07-25.4-33.15-34.24-21.57-49.51-50.74-46.69-89.18 2.43-33.23 17.89-68 31.53-98.77 6.5-14.64 12.64-28.47 16.7-40.66 7.47-22.41 9.47-38.13 6.51-50.94-2.75-11.87-9.45-20.28-17.94-30.92-14.07-17.64-31.57-39.6-42.31-90.26-9-42.42-7.39-62.18-6.1-78.06 2.11-26 3.18-39-46.29-120.57-37.88-62.44 1.94-87 48-115.38 51.08-31.48 109-67.15 78.87-163.65l.48-.15c13.52 43.28 10.52 78.18-9.16 106.78-17.2 25-44 41.54-69.91 57.51-45.87 28.27-85.49 52.68-47.87 114.7C181.09 436 180 449.09 177.9 475.19c-1.29 15.81-2.9 35.57 6.1 77.91 10.72 50.55 28.19 72.46 42.22 90.06 16.54 20.74 27.47 34.44 11.52 82.33-4.07 12.21-10.21 26-16.72 40.7-13.62 30.69-29.06 65.47-31.49 98.61-2.83 38.2 12.37 67.25 46.47 88.72 14.52 9.15 22.91 20.1 25.62 33.47 6.55 32.19-21.19 72.1-50.56 114.36-39.84 57.3-81 116.55-35.25 158.85 24.84 22.8 43.24 37.32 55.41 46.94 13.5 10.65 19.66 15.51 19.21 19.44-.38 3.39-5.69 5.9-15.33 10.47-19.21 9.1-55 26-105.23 73.15-21.74 20.38-31.55 48.4-29.14 83.3 1.93 28 11.65 60.34 28.89 96.28C159 1651 200.53 1701.92 201 1702.43z"
              fill="url(#prefix__Degradado_sin_nombre_656-24)"
            />
            <path
              d="M204.34 1699.18c-.42-.5-42.56-50.76-72.73-111.54-17.73-35.7-27.92-68-30.29-96-3-35 6.36-63.37 27.74-84.24 49.93-48.76 85.34-66.61 102.36-75.19 8.33-4.2 12.92-6.51 13.16-9.18.27-3-5-7-15.4-14.82a676 676 0 01-59.53-49.93c-44.68-42.16-3.63-100.94 36.06-157.78 29.58-42.35 57.52-82.35 51.12-114.81-2.65-13.45-11-24.52-25.46-33.83-33.82-21.76-49-51-46.35-89.33 2.27-33.14 17.34-67.71 30.64-98.22 6.3-14.45 12.25-28.11 16.16-40.12 15.74-48.29 7.07-60.85-8.67-83.65-11.69-16.94-27.69-40.12-39.29-88.78-10.59-44.39-8.29-64.75-6.45-81.1 2.85-25.32 4.58-40.6-42.34-118.7-37.15-61.86.23-86.85 43.51-115.78 47.7-31.89 101.77-68 73.32-162.79l.48-.14c12.73 42.42 10 76.92-8.28 105.46-16 25-41 41.71-65.24 57.88-43.07 28.8-80.27 53.67-43.36 115.11 47 78.25 45.27 93.6 42.41 119-1.84 16.31-4.13 36.62 6.43 80.93 11.58 48.56 27.56 71.7 39.22 88.6 7.79 11.28 13.93 20.18 16.15 32.34 2.34 12.88.06 28.84-7.41 51.75-3.92 12-9.88 25.71-16.18 40.17-13.28 30.47-28.33 65-30.6 98.05-2.62 38.16 12.47 67.23 46.12 88.88 14.6 9.39 23 20.56 25.68 34.16 6.44 32.66-21.55 72.75-51.2 115.19-39.57 56.67-80.5 115.26-36.13 157.12a675.61 675.61 0 0059.49 49.9c10.91 8.23 15.89 12 15.6 15.27-.27 2.94-4.67 5.16-13.44 9.58-17 8.56-52.36 26.39-102.23 75.1-21.26 20.76-30.55 49-27.59 83.84 2.36 27.92 12.53 60.16 30.23 95.81 30.15 60.71 72.25 110.93 72.67 111.43z"
              fill="url(#prefix__Degradado_sin_nombre_656-25)"
            />
            <path
              d="M208.12 1695.61c-.43-.5-43.1-50-74.06-110.33-18.18-35.42-28.83-67.55-31.64-95.52-3.51-35 5.31-63.52 26.21-84.78 49.36-50.22 83-68.35 99.18-77.07 7.34-4 11.38-6.13 11.52-8.41.15-2.46-4.09-5.47-12.55-11.47-12.33-8.74-32.95-23.36-62.93-52-43.27-41.72-2.49-99.83 37-156 29.85-42.54 58.05-82.72 51.76-115.65-2.61-13.68-11-25-25.52-34.54-33.39-21.93-48.44-51.2-46-89.46 2.1-33 16.78-67.37 29.73-97.66 6.1-14.28 11.86-27.76 15.64-39.6 15.77-49.51 8.45-61.86-6.12-86.47-10.1-17.06-23.93-40.42-36.07-86.26-12.21-46.08-9.15-67.05-6.69-83.9 3.56-24.39 6.13-42-38.5-117.07-36.44-61.3-1.49-86.76 39-116.24 22.44-16.34 45.64-33.24 60.44-58.09 16.89-28.36 19.29-62.31 7.34-103.79l.48-.14c12 41.62 9.57 75.7-7.39 104.19-14.85 24.93-38.1 41.86-60.57 58.23-40.28 29.34-75.06 54.68-38.85 115.58C184.18 424.32 181.6 442 178 466.52c-2.45 16.8-5.5 37.71 6.68 83.69 12.13 45.78 25.94 69.1 36 86.14 14.65 24.74 22 37.15 6.16 86.87-3.78 11.87-9.55 25.36-15.65 39.65-12.94 30.25-27.6 64.53-29.7 97.49-2.42 38.07 12.55 67.19 45.78 89 14.68 9.64 23.1 21 25.74 34.86 6.33 33.13-21.93 73.4-51.84 116-39.32 56-80 114-37 155.38 30 28.65 50.55 43.25 62.86 52 8.89 6.3 12.94 9.17 12.77 11.91-.16 2.56-4 4.65-11.78 8.82-16.15 8.71-49.76 26.81-99.07 77-20.79 21.15-29.56 49.54-26.06 84.38 2.8 27.9 13.43 60 31.58 95.33 30.93 60.23 73.57 109.75 74 110.24z"
              fill="url(#prefix__Degradado_sin_nombre_656-26)"
            />
            <path
              d="M211.9 1692c-.43-.49-43.65-49.34-75.39-109.14-18.65-35.12-29.75-67.1-33-95-4.06-35 4.24-63.66 24.67-85.31 48.63-51.51 80.49-70 95.81-78.81 6.46-3.74 10-5.81 10.08-7.75s-3.4-4.26-10.27-8.75c-11.77-7.71-33.68-22-65.75-53.53-42-41.19-1.44-98.64 37.75-154.21 14.48-20.52 29.45-41.74 39.65-61.48C246.85 1016 251 998 248.27 983c-2.57-13.9-10.93-25.43-25.56-35.24-33-22.11-47.91-51.41-45.67-89.59 1.94-33 16.22-67 28.83-97.1 5.9-14.1 11.48-27.42 15.12-39.1 16-51.59 10.61-62.33-3.9-91-8.58-17-20.34-40.23-32.51-82-13.86-47.52-10.13-68.23-6.84-86.5 4.36-24.2 7.8-43.32-34.75-115.64-35.75-60.83-3.22-86.77 34.43-116.83 20.73-16.53 42.16-33.63 55.79-58.41 15.57-28.3 17.68-61.82 6.47-102.5l.48-.13c11.25 40.8 9.12 74.45-6.51 102.87-13.68 24.87-35.15 42-55.91 58.56-37.48 29.9-69.84 55.73-34.32 116.11 42.65 72.48 39.19 91.67 34.81 116-3.28 18.22-7 38.86 6.83 86.27 12.16 41.71 23.9 64.94 32.48 81.9 14.58 28.84 20 39.64 3.93 91.42-3.64 11.71-9.23 25-15.14 39.14-12.59 30-26.86 64.08-28.79 96.94-2.23 38 12.64 67.14 45.44 89.14 14.75 9.9 23.19 21.53 25.78 35.57 2.79 15.1-1.41 33.22-12.86 55.38-10.21 19.77-25.19 41-39.68 61.54-19.46 27.59-39.59 56.13-49.32 82.45-5.24 14.17-7.1 26.52-5.7 37.76 1.58 12.63 7.21 23.54 17.21 33.36 32 31.44 53.92 45.76 65.68 53.46 7.24 4.74 10.55 6.9 10.49 9.18s-3.5 4.21-10.33 8.17c-15.3 8.85-47.12 27.26-95.7 78.72-20.32 21.53-28.55 50.1-24.55 84.9 3.24 27.87 14.32 59.79 32.94 94.86 31.71 59.74 74.89 108.56 75.32 109z"
              fill="url(#prefix__Degradado_sin_nombre_656-27)"
            />
            <path
              d="M215.68 1688.47c-.44-.48-44.21-48.64-76.74-108-19.11-34.84-30.67-66.66-34.34-94.57-4.6-34.93 3.19-63.81 23.16-85.83 47.62-52.52 77.78-71.42 92.28-80.5 6-3.74 9-5.63 9-7.31s-3.06-3.43-8.58-6.69c-11.15-6.57-34.35-20.25-68.16-54.19-9.75-9.8-15.19-20.67-16.61-33.26-1.27-11.19.69-23.46 6-37.52 9.81-26.07 29.85-54.33 49.23-81.66 14.6-20.59 29.69-41.88 40-61.75 11.54-22.23 15.83-40.42 13.11-55.63-2.52-14.13-10.9-25.89-25.62-36C185.83 923.37 171 894 173.05 856c1.76-32.85 15.65-66.69 27.91-96.54 5.72-13.93 11.12-27.09 14.61-38.61 16.71-55.11 13-63.53-2.41-99a704.08 704.08 0 01-28.22-74.35c-15.53-48.78-10.94-70.13-6.9-89 5.13-23.88 9.56-44.5-31.09-114.34-35.06-60.25-5-86.71 29.87-117.34 19-16.72 38.69-34 51.15-58.71C242.21 139.89 244 106.8 233.57 67l.48-.12c10.51 40 8.66 73.17-5.63 101.5-12.51 24.8-32.21 42.12-51.27 58.87-17.29 15.2-33.62 29.56-41.05 47.41-8.27 19.84-4.68 41.86 11.29 69.29 40.74 70 36.29 90.73 31.14 114.71-4 18.77-8.6 40.06 6.89 88.71a702.66 702.66 0 0028.2 74.3c15.51 35.58 19.19 44 2.43 99.32-3.5 11.55-8.91 24.72-14.63 38.66-12.24 29.81-26.11 63.61-27.87 96.38-2 37.89 12.72 67.08 45.11 89.25 14.83 10.15 23.27 22 25.82 36.29 2.74 15.32-1.56 33.62-13.16 56-10.33 19.9-25.44 41.2-40 61.8-19.36 27.31-39.38 55.55-49.18 81.55-5.26 14-7.21 26.19-6 37.29 1.42 12.47 6.81 23.25 16.48 33 33.76 33.9 56.92 47.56 68 54.12 5.86 3.46 8.81 5.2 8.83 7.12s-2.86 3.77-9.19 7.74c-14.48 9.06-44.6 27.93-92.18 80.41-19.87 21.91-27.61 50.65-23 85.42 3.67 27.85 15.21 59.61 34.29 94.4 32.5 59.26 76.23 107.37 76.67 107.85z"
              fill="url(#prefix__Degradado_sin_nombre_656-28)"
            />
            <path
              d="M219.46 1684.89c-.45-.47-44.77-47.93-78.09-106.76-19.58-34.56-31.59-66.22-35.7-94.1-5.14-34.9 2.14-63.94 21.65-86.33 46.35-53.2 75.94-73.41 88.58-82 5.47-3.73 8.21-5.61 8.12-7.08s-2.66-2.7-7.34-5.13c-11.36-5.88-35-18.12-70.21-54.31-9.43-9.69-14.62-20.44-15.88-32.86-1.13-11 .92-23.17 6.24-37.06 9.88-25.73 29.81-53.7 49.08-80.74 29.37-41.2 59.73-83.81 53.8-118.2-2.48-14.36-10.87-26.37-25.67-36.71-32.11-22.44-46.83-51.81-45-89.79 1.59-32.75 15.09-66.34 27-96 5.53-13.82 10.76-26.82 14.11-38.15 18-60.92 16.43-64.91-2.67-113.81C201.6 590.78 194.27 572 185.3 546c-17.21-49.87-11.72-71.89-6.88-91.32 6.1-24.46 11.37-45.59-27.51-113.16-34.39-59.78-6.74-86.77 25.29-118 17.31-16.89 35.22-34.36 46.52-59 12.92-28.13 14.47-60.76 4.74-99.75l.48-.13c9.76 39.11 8.21 71.85-4.76 100.09-11.35 24.7-29.28 42.21-46.63 59.13-15.91 15.52-30.94 30.18-37.5 48.18-7.28 20-3.37 42 12.3 69.22 39 67.75 33.69 89 27.56 113.53-4.83 19.36-10.3 41.31 6.86 91 9 26 16.29 44.74 22.18 59.81 19.15 49 20.72 53.05 2.68 114.13-3.36 11.47-8.63 24.48-14.13 38.27-11.89 29.6-25.36 63.15-26.95 95.81-1.83 37.8 12.81 67 44.78 89.36 14.91 10.42 23.37 22.53 25.87 37 2.68 15.54-1.72 34-13.46 56.51-10.47 20-25.69 41.41-40.42 62.07-19.26 27-39.17 55-49 80.63-5.3 13.81-7.33 25.86-6.22 36.83 1.25 12.31 6.4 23 15.75 32.56 35.16 36.13 58.74 48.35 70.08 54.22 5 2.59 7.51 3.89 7.61 5.54s-2.54 3.56-8.34 7.52c-12.62 8.62-42.18 28.8-88.48 82-19.41 22.28-26.65 51.19-21.54 85.93 4.1 27.83 16.09 59.43 35.64 93.94 33.3 58.77 77.58 106.19 78 106.66z"
              fill="url(#prefix__Degradado_sin_nombre_656-29)"
            />
            <path
              d="M223.24 1681.32c-.45-.46-45.34-47.24-79.46-105.58-20-34.28-32.51-65.78-37-93.64-5.68-34.86 1.1-64.07 20.16-86.83 44.73-53.43 72.72-74.41 84.68-83.37 5.14-3.85 7.73-5.79 7.57-7.12-.13-1.16-2.38-2.16-6.47-4-10.69-4.72-35.72-15.77-72-54-9.11-9.58-14.07-20.2-15.16-32.47-1-10.91 1.15-22.87 6.5-36.58 9.93-25.41 29.75-53.07 48.92-79.82 29.64-41.36 60.29-84.13 54.49-119-2.43-14.61-10.84-26.87-25.72-37.47-31.69-22.6-46.3-52-44.66-89.88 1.42-32.65 14.51-66 26.07-95.4 5.34-13.61 10.4-26.47 13.61-37.69q1.83-6.36 3.45-11.93C225 648.76 225 648.76 197 574.79c-3.35-8.86-7.15-18.9-11.35-30.19-18.91-50.85-12.75-72.58-6.78-93.6 3.48-12.26 6.76-23.84 4.55-40.6-2.42-18.84-11.54-41.55-28.55-71.4-33.75-59.37-8.53-86.91 20.68-118.8 15.62-17.05 31.77-34.69 41.92-59.21 11.6-28 12.87-60.17 3.88-98.28l.48-.12c9 38.22 7.75 70.47-3.9 98.59-10.18 24.6-26.36 42.27-42 59.36-14.53 15.86-28.25 30.85-33.93 49-6.3 20.16-2.07 42.15 13.32 69.21 17 30 26.13 52.73 28.62 71.63 2.22 16.87-1.08 28.49-4.57 40.81-5.94 20.94-12.09 42.59 6.77 93.29q6.3 16.92 11.34 30.18c28 74.13 28 74.13 11.19 132.13q-1.62 5.58-3.44 11.93c-3.23 11.24-8.29 24.11-13.64 37.73-11.54 29.38-24.62 62.68-26 95.24-1.63 37.7 12.91 67 44.45 89.45 15 10.69 23.47 23 25.92 37.79 5.84 35.09-24.87 77.95-54.57 119.39-19.15 26.73-39 54.36-48.86 79.71-5.33 13.63-7.45 25.53-6.48 36.36 1.09 12.15 6 22.67 15 32.17 36.2 38.12 61.17 49.14 71.83 53.85 4.4 1.94 6.61 2.91 6.77 4.36.19 1.62-2.31 3.49-7.77 7.59-11.94 8.94-39.91 29.89-84.6 83.28-19 22.64-25.7 51.72-20 86.43 4.53 27.79 17 59.24 37 93.46 34.09 58.29 78.94 105 79.39 105.5z"
              fill="url(#prefix__Degradado_sin_nombre_656-30)"
            />
            <path
              d="M227 1677.75c-.46-.46-45.91-46.55-80.83-104.41-20.52-34-33.44-65.34-38.41-93.17-6.22-34.82.06-64.2 18.67-87.33 42.74-53.11 69.25-75 80.57-84.4 5-4.17 7.57-6.26 7.35-7.51-.17-1-2.34-1.82-5.93-3.13-10.94-4-36.58-13.31-73.56-53.23-8.78-9.48-13.5-20-14.44-32.08-.83-10.77 1.38-22.59 6.76-36.11 10-25.08 29.69-52.43 48.75-78.89 20.87-29 42.45-58.9 51.42-86 4.83-14.59 5.73-27.22 2.76-38.61-3.34-12.79-11.43-23.72-24.75-33.41-31.27-22.75-45.77-52.17-44.33-89.94 1.24-32.55 13.93-65.63 25.14-94.83 5.16-13.46 10-26.18 13.13-37.25 3.16-11.36 6-20.65 8.57-28.85 7.2-23.23 11.16-36 8.6-54.73S205 590.82 186 543.17c-20.64-51.72-13.51-74.14-6.62-95.82 7.76-24.43 15.09-47.51-20.57-111-15.18-27-19.76-49.16-14.41-69.63 4.82-18.44 17.27-33.82 30.45-50.09 13.93-17.2 28.34-35 37.34-59.38 10.29-27.89 11.28-59.54 3-96.73l.48-.11c8.28 37.29 7.28 69-3 97-9 24.47-24.11 43.09-37.42 59.52-13.15 16.23-25.56 31.57-30.35 49.9-5.32 20.35-.75 42.36 14.36 69.27 35.77 63.68 28.4 86.86 20.6 111.4-6.86 21.6-14 43.93 6.62 95.48 37.72 94.54 36.23 99.38 21.88 145.72-2.53 8.19-5.41 17.48-8.57 28.83-3.09 11.1-8 23.82-13.15 37.3C175.46 784 162.78 817 161.55 849.5c-1.43 37.59 13 66.88 44.12 89.52 13.42 9.76 21.57 20.78 24.94 33.68 3 11.49 2.1 24.22-2.76 38.9-9 27.22-30.6 57.2-51.49 86.19-19.05 26.42-38.74 53.75-48.7 78.78-5.35 13.45-7.55 25.18-6.72 35.88.93 12 5.61 22.38 14.31 31.78 36.89 39.83 62.45 49.13 73.36 53.1 4 1.46 6 2.19 6.26 3.51.26 1.54-2 3.43-7.53 8-11.31 9.35-37.79 31.26-80.5 84.32-18.51 23-24.76 52.26-18.57 86.93 5 27.77 17.86 59.06 38.35 93 34.89 57.81 80.3 103.86 80.75 104.32z"
              fill="url(#prefix__Degradado_sin_nombre_656-31)"
            />
            <path
              d="M230.8 1674.18c-.46-.45-46.49-45.87-82.22-103.25-21-33.71-34.36-64.9-39.76-92.7-6.76-34.78-1-64.33 17.2-87.82 40.31-52.11 64.56-74.33 76.2-85 5.19-4.75 7.79-7.13 7.49-8.38-.22-1-2.28-1.55-5.68-2.53-11.26-3.25-37.61-10.84-74.94-52.21-8.46-9.37-13-19.74-13.73-31.68-.69-10.64 1.6-22.29 7-35.64 10-24.75 29.63-51.79 48.58-77.94 21.06-29.07 42.84-59.12 52-86.53 4.91-14.73 5.87-27.51 2.92-39.08-3.31-13-11.42-24.13-24.79-34.06-30.84-22.9-45.23-52.33-44-90 1.06-32.43 13.36-65.27 24.21-94.25 5-13.32 9.7-25.9 12.65-36.84 4.31-16 8.49-27.81 12.18-38.25 13.37-37.82 17.41-49.23-19.69-136.28-22.38-52.51-14.26-75.63-6.41-98 8.74-24.87 17-48.36-17.19-110C147.87 306.83 143 284.66 147.3 264c3.92-18.64 15.06-34.4 26.85-51.08 12.26-17.32 24.93-35.24 32.78-59.48 9-27.74 9.71-58.83 2.2-95.06l.48-.11c7.53 36.33 6.81 67.5-2.2 95.32-7.88 24.32-21.14 43.07-32.85 59.62s-22.87 32.35-26.77 50.89c-4.32 20.56.58 42.61 15.44 69.41 34.29 61.84 26 85.44 17.22 110.42-7.82 22.27-15.91 45.3 6.4 97.62 37.15 87.21 33.15 98.67 19.7 136.63-3.68 10.43-7.86 22.25-12.17 38.21-2.95 11-7.67 23.56-12.66 36.89-10.84 28.94-23.12 61.74-24.18 94.09-1.22 37.49 13.11 66.79 43.81 89.58 13.46 10 21.63 21.23 25 34.33 3 11.67 2 24.55-2.92 39.37-9.16 27.48-31 57.57-52.05 86.66-18.93 26.13-38.51 53.14-48.52 77.84-5.38 13.27-7.66 24.85-7 35.41.77 11.83 5.22 22.1 13.6 31.39 37.23 41.25 63.49 48.82 74.71 52.06 3.7 1.06 5.74 1.65 6 2.9.36 1.53-2 3.67-7.64 8.86-11.64 10.67-35.86 32.87-76.15 85-18.08 23.37-23.83 52.78-17.1 87.41 5.38 27.74 18.74 58.87 39.69 92.53 35.7 57.33 81.69 102.71 82.15 103.16z"
              fill="url(#prefix__Degradado_sin_nombre_656-32)"
            />
            <path
              d="M234.58 1670.61c-.47-.45-47.08-45.18-83.61-102.09-21.46-33.43-35.3-64.46-41.13-92.23-7.29-34.75-2-64.46 15.74-88.31 37.45-50.34 60.48-73.77 71.54-85 5.79-5.89 8.44-8.58 8-9.89-.29-1-2.33-1.4-5.72-2.13-11.61-2.53-38.81-8.44-76.16-51-8.14-9.26-12.4-19.5-13-31.29-.55-10.49 1.82-22 7.26-35.15 10.08-24.43 29.56-51.16 48.4-77 21.26-29.17 43.24-59.32 52.53-87 5-14.88 6-27.82 3.09-39.56-3.28-13.2-11.41-24.55-24.83-34.72-30.41-23-44.7-52.49-43.69-90 .88-32.32 12.77-64.91 23.27-93.66 4.82-13.19 9.36-25.65 12.18-36.45 4.92-18.85 10.38-32 15.2-43.68 6.93-16.73 11.93-28.82 10.65-47.18-1.35-19.2-9.6-44.31-27.58-84-24.14-53.23-15-77.07-6.12-100.13 9.71-25.28 18.88-49.16-13.88-109-14.68-26.84-20-49.08-16.62-70 3-18.89 12.85-35.06 23.26-52.19 10.09-16.62 21.53-35.45 28.25-59.51 7.69-27.53 8.14-58 1.37-93.23l.5-.1c6.78 35.29 6.33 65.86-1.39 93.47-6.74 24.12-18.2 43-28.31 59.63-10.38 17.08-20.18 33.22-23.18 52-3.33 20.79 1.94 42.93 16.56 69.67 15 27.35 22.23 48.92 22.87 67.88.57 16.74-4.28 29.37-9 41.58-8.82 23-17.94 46.7 6.11 99.74 18 39.72 26.27 64.88 27.62 84.15 1.3 18.47-3.73 30.61-10.68 47.4-4.81 11.63-10.27 24.8-15.18 43.62-2.82 10.82-7.37 23.29-12.19 36.49-10.48 28.72-22.37 61.26-23.24 93.5-1 37.38 13.22 66.7 43.49 89.63 13.52 10.24 21.7 21.69 25 35 2.95 11.84 1.94 24.87-3.09 39.84-9.32 27.74-31.33 57.93-52.6 87.12-18.83 25.82-38.29 52.53-48.35 76.9-5.41 13.09-7.77 24.52-7.22 34.94.61 11.67 4.83 21.8 12.9 31 37.23 42.39 64.32 48.28 75.89 50.79 3.69.8 5.72 1.24 6.08 2.48.48 1.59-1.87 4-8.16 10.38-11.06 11.25-34.07 34.65-71.5 85-17.64 23.73-22.91 53.3-15.65 87.9 5.81 27.71 19.63 58.68 41.06 92.07 36.49 56.85 83.06 101.55 83.53 102z"
              fill="url(#prefix__Degradado_sin_nombre_656-33)"
            />
            <path
              d="M238.36 1667c-.48-.44-47.68-44.5-85-100.93-21.93-33.15-36.23-64-42.48-91.77-7.83-34.71-3-64.58 14.29-88.78 34.11-47.71 55.17-71.43 66.48-84.17 6.61-7.45 9.63-10.85 9.1-12.31-.36-1-2.48-1.32-6-1.86-12-1.84-40.2-6.15-77.24-49.51a49 49 0 01-12.32-30.9c-.42-10.36 2-21.7 7.49-34.68 10.14-24.1 29.5-50.51 48.23-76.05 21.45-29.26 43.64-59.51 53.1-87.44 5.08-15 6.15-28.12 3.26-40-3.26-13.4-11.39-25-24.88-35.4-30-23.17-44.16-52.62-43.36-90.06.69-32.2 12.18-64.53 22.33-93.06 4.64-13.07 9-25.41 11.71-36.08 5.18-20.58 12-34.59 18-47C216 636.22 225 617.61 187.11 538.86c-25.91-53.89-15.68-78.47-5.78-102.24 5.12-12.3 10.43-25 10.39-41.62 0-18.6-6.91-40.34-21-66.48-14.47-26.83-20.13-49.2-17.8-70.39 2.11-19.16 10.63-35.82 19.64-53.46 8.52-16.68 18.18-35.58 23.78-59.41 6.41-27.27 6.6-57.11.57-91.2l.5-.09c6 34.17 5.84 64.07-.58 91.41-5.62 23.88-15.29 42.81-23.83 59.51-9 17.6-17.48 34.22-19.58 53.3-2.32 21.08 3.32 43.35 17.74 70.1 14.14 26.2 21 48 21.07 66.7 0 16.7-5.28 29.47-10.43 41.83-9.85 23.66-20 48.13 5.77 101.82 38 79 28.91 97.66 13.91 128.63-6 12.34-12.76 26.33-17.92 46.85-2.69 10.69-7.08 23-11.73 36.13-10.13 28.49-21.61 60.78-22.3 92.9-.8 37.27 13.32 66.59 43.17 89.66 56.52 43.67 11.83 104.62-31.39 163.57-18.71 25.52-38.06 51.9-48.17 75.94-5.42 12.91-7.86 24.19-7.45 34.47a48.54 48.54 0 0012.2 30.59c36.92 43.23 65 47.51 76.93 49.35 3.83.58 5.93.9 6.39 2.18.63 1.74-2 4.76-9.19 12.81-11.31 12.74-32.36 36.44-66.46 84.13-17.22 24.08-22 53.82-14.21 88.38 6.25 27.68 20.52 58.5 42.42 91.6 37.31 56.39 84.46 100.4 84.94 100.84z"
              fill="url(#prefix__Degradado_sin_nombre_656-34)"
            />
            <path
              d="M242.14 1663.47c-.48-.43-48.28-43.82-86.43-99.77-22.42-32.88-37.17-63.6-43.85-91.3-8.36-34.68-4-64.71 12.85-89.27 30.33-44.11 50.22-68.79 60.9-82.06 7.91-9.82 11.52-14.3 10.8-16-.46-1.1-2.87-1.33-6.53-1.68-12.48-1.2-41.71-4-78.18-48a48.25 48.25 0 01-11.62-30.5c-.28-10.22 2.25-21.41 7.73-34.21 10.18-23.76 29.42-49.85 48-75.08 43.54-59 88.57-120.07 32.2-164.5-29.55-23.3-43.63-52.76-43.05-90.07.51-32.08 11.6-64.16 21.39-92.46 4.47-13 8.7-25.18 11.26-35.74 5.21-21.57 13.37-36.13 20.57-49 16.3-29.08 28.08-50.08-10.75-126.46-27.71-54.51-16.36-79.84-5.38-104.34 5.58-12.45 11.34-25.32 11.83-41.85.55-18.56-5.75-39.92-19.25-65.32-31.18-58.66-18.77-88.38-3-126 14.54-34.8 31-74.25 19.17-148l.5-.08c5.28 32.93 5.35 62.06.19 89.06-4.5 23.57-12.42 42.51-19.4 59.23-15.66 37.49-28 67.11 3 125.56 13.55 25.48 19.86 46.93 19.31 65.57-.49 16.64-6.28 29.56-11.87 42.05-5.47 12.19-11.12 24.8-11.84 40.87-.8 18.05 4.82 38.67 17.21 63 38.94 76.62 27.11 97.72 10.74 126.93-7.17 12.95-15.31 27.42-20.51 48.95-2.56 10.58-6.79 22.82-11.27 35.79-9.78 28.26-20.85 60.3-21.36 92.3-.58 37.14 13.44 66.47 42.86 89.66 56.75 44.74 11.58 106-32.11 165.2-18.6 25.21-37.82 51.27-48 75-5.45 12.73-8 23.85-7.69 34a47.71 47.71 0 0011.51 30.2c36.34 43.8 65.42 46.59 77.84 47.78 4 .39 6.37.62 6.94 2 .84 2-2.35 6-10.87 16.53-10.68 13.25-30.56 37.93-60.88 82-16.8 24.44-21.1 54.34-12.77 88.87 6.66 27.65 21.39 58.31 43.77 91.14 38.12 55.9 85.87 99.25 86.35 99.68z"
              fill="url(#prefix__Degradado_sin_nombre_656-35)"
            />
            <path
              d="M245.91 1659.9c-.48-.43-48.88-43.14-87.85-98.62-22.89-32.59-38.1-63.15-45.21-90.83-8.89-34.64-5-64.83 11.43-89.75 26.09-39.47 43.92-63.68 54.58-78.15 10.14-13.77 14.33-19.47 13.35-21.54-.6-1.24-3.27-1.36-7.31-1.55a81.7 81.7 0 01-31.18-6.78c-16.39-7.19-32.48-20.5-47.81-39.55A47.37 47.37 0 0195 1203c-.14-10.08 2.46-21.11 8-33.72 10.22-23.43 28.49-48 47.84-74.11 44-59.31 89.54-120.63 32.94-166.14-29.12-23.41-43.1-52.87-42.73-90 .31-32 11-63.78 20.43-91.86 4.31-12.85 8.39-25 10.81-35.42 5.13-22.11 14.68-37.05 23.11-50.23 18.16-28.41 32.51-50.84-7.51-125.51-13.24-24.71-19.17-45.72-18.15-64.22.92-16.51 7.18-29.57 13.23-42.2s12.2-25.59 13.2-42.09c1.12-18.5-4.61-39.49-17.54-64.19-30.93-59.09-20.85-89.86-8.08-128.81 11.39-34.73 24.29-74.1 14.13-144.75l.5-.07c10.18 70.77-2.75 110.19-14.15 145-12.73 38.84-22.79 69.51 8.05 128.42 28.92 55.26 16.39 81.41 4.28 106.7-6 12.59-12.27 25.6-13.18 42-1 18.41 4.9 39.33 18.09 63.95 40.15 74.93 25.74 97.48 7.49 126-8.41 13.14-17.94 28.05-23 50.07-2.42 10.47-6.5 22.61-10.82 35.47-9.41 28-20.09 59.82-20.4 91.7-.37 37 13.55 66.34 42.54 89.65 57 45.81 11.31 107.33-32.85 166.82-19.33 26-37.59 50.64-47.79 74-11.52 26.41-10.6 46.54 2.91 63.32 15.28 19 31.3 32.24 47.62 39.4a81.36 81.36 0 0031 6.74c4.38.2 7 .32 7.74 1.83 1.11 2.35-2.84 7.71-13.4 22.06s-28.48 38.67-54.56 78.13c-16.39 24.79-20.22 54.85-11.36 89.34 7.09 27.62 22.27 58.12 45.13 90.67 38.93 55.43 87.29 98.11 87.77 98.53z"
              fill="url(#prefix__Degradado_sin_nombre_656-36)"
            />
            <path
              d="M249.7 1656.33c-.5-.42-49.5-42.46-89.29-97.46-23.37-32.32-39-62.73-46.57-90.37-9.43-34.6-6.06-65 10-90.23 21.43-33.71 36.95-56.56 47.22-71.68 13.39-19.71 18.4-27.08 17.05-29.65-.76-1.43-3.77-1.44-8.32-1.46-7.34 0-18.45-.06-32.24-5.66-16.54-6.71-32.5-19.83-47.43-39a46.74 46.74 0 01-10.27-29.72c0-9.94 2.68-20.82 8.2-33.25 10.27-23.09 28.42-47.4 47.65-73.13 44.5-59.57 90.52-121.17 33.69-167.77-28.69-23.53-42.57-53-42.42-90 .12-31.83 10.4-63.39 19.47-91.24 4.16-12.76 8.08-24.8 10.37-35.13 4.95-22.34 15.43-36.91 25.57-51 10-13.85 19.39-26.94 21-45.5 1.79-20.62-6.22-45.9-25.18-79.55-14.06-24.95-20.29-46.26-19-65.14 1.12-16.87 8-30.34 14.62-43.36 13-25.47 26.39-51.8-1.19-105.34-30.88-60-23.13-92-13.31-132.68 7.88-32.71 17.68-73.45 9.25-140.41l.5-.06c8.48 67-1.38 107.83-9.3 140.61-9.77 40.53-17.53 72.54 13.23 132.33 27.69 53.76 14.22 80.21 1.19 105.79-6.62 13-13.46 26.41-14.57 43.17-1.25 18.78 5 40 19 64.87 19 33.73 27 59.1 25.24 79.84-1.61 18.69-11.07 31.83-21.08 45.75s-20.56 28.57-25.49 50.81c-2.3 10.35-6.22 22.41-10.38 35.17-9.06 27.82-19.33 59.34-19.45 91.09-.15 36.88 13.67 66.19 42.24 89.62 57.19 46.91 11 108.7-33.61 168.46-19.21 25.71-37.35 50-47.59 73-11.57 26-10.93 45.86 2 62.45 34.54 44.3 64.76 44.4 79.29 44.44 4.89 0 7.85 0 8.75 1.73 1.49 2.83-3.19 9.72-17.07 30.16-10.27 15.12-25.79 38-47.21 71.67-16 25.15-19.33 55.37-10 89.82 7.51 27.59 23.15 57.94 46.49 90.21 39.75 55 88.72 97 89.21 97.38z"
              fill="url(#prefix__Degradado_sin_nombre_656-37)"
            />
            <path
              d="M253.47 1652.75c-.49-.41-50.11-41.77-90.72-96.3-23.86-32-40-62.29-47.94-89.9-10-34.56-7.06-65.08 8.6-90.7 16.33-26.73 29.07-46.87 38.38-61.57 17.59-27.79 24.17-38.19 22.32-41.41-1-1.68-4.37-1.57-9.54-1.39-15.15.51-46.68 1.58-80.25-42.92-27.38-36.29 8.4-83.87 46.27-134.24 45-59.83 91.52-121.7 34.47-169.42-28.26-23.63-42-53.06-42.11-90-.07-31.68 9.8-63 18.51-90.61 4-12.67 7.77-24.63 9.93-34.86 4.72-22.36 16.54-37.13 28-51.41 11.13-13.91 21.63-27 24-45.72 2.66-20.93-5.23-46.1-24.81-79.22-14.9-25.2-21.42-46.81-19.92-66.07 1.34-17.21 8.83-31.1 16.07-44.52 13.87-25.74 28.22-52.35 1.84-104.43-31.16-61.51-26-93.72-18.86-138.29 5.14-32.09 11.54-72 4.77-134.36l.5-.05c6.77 62.38.37 102.36-4.78 134.49-7.12 44.48-12.27 76.61 18.82 138 26.5 52.32 12.08 79.05-1.85 104.89-7.22 13.38-14.68 27.22-16 44.33-1.49 19.15 5 40.67 19.85 65.77 19.64 33.22 27.55 58.5 24.87 79.54-2.39 18.82-12.94 32-24.11 46-11.39 14.24-23.18 29-27.87 51.2-2.16 10.25-5.94 22.23-9.94 34.91-8.7 27.59-18.56 58.85-18.49 90.46.07 36.74 13.79 66 41.93 89.58 13.78 11.53 22 24.11 25.15 38.48 2.79 12.75 1.48 26.58-4 42.27-10.17 29-33.22 59.7-55.52 89.35-37.76 50.22-73.42 97.65-46.27 133.64 33.42 44.29 64.77 43.23 79.83 42.72 5.35-.18 8.88-.3 10 1.64 2 3.48-4.14 13.17-22.34 41.93-9.3 14.7-22 34.83-38.37 61.56-15.58 25.5-18.46 55.88-8.55 90.29 7.93 27.56 24 57.76 47.85 89.74 40.58 54.49 90.16 95.82 90.65 96.23z"
              fill="url(#prefix__Degradado_sin_nombre_656-38)"
            />
            <path
              d="M257.25 1649.18c-.5-.4-50.74-41.09-92.17-95.15-24.35-31.75-40.94-61.85-49.31-89.43-10.48-34.52-8-65.2 7.21-91.18 10.79-18.38 20-33.84 27.39-46.26 24.19-40.62 32.34-54.31 29.85-58.37-1.21-2-5.1-1.72-11-1.33-16.9 1.12-48.35 3.2-80.7-41.19-26.13-35.84 9.37-82.73 46.94-132.37 22.49-29.7 45.74-60.41 56.06-89.64 5.56-15.75 6.94-29.66 4.2-42.5-3.08-14.47-11.26-27.21-25-38.93C108 869.29 130.23 796.28 146.48 743c3.84-12.6 7.47-24.5 9.49-34.64 4.45-22.21 17.59-37.14 30.31-51.58 12.32-14 23.95-27.2 27.11-46 3.56-21.22-4.21-46.33-24.47-79.05-15.74-25.42-22.54-47.33-20.79-67 1.57-17.57 9.7-31.87 17.56-45.7 14.77-26 30-52.84 4.82-103.51-14.79-29.73-23.07-54.91-26-79.26-2.77-22.61-1-43.58 1.07-67.85 2.61-30.77 5.86-69.07.82-125.08h.5c5 56.05 1.79 94.37-.82 125.16-4.22 49.78-7 82.67 24.93 146.85 25.33 50.91 10 77.89-4.83 104-7.84 13.78-15.94 28-17.5 45.49-1.74 19.54 5 41.35 20.71 66.68 44.58 72 21.62 98.06-2.69 125.66-12.67 14.39-25.77 29.27-30.19 51.35-2 10.16-5.66 22.08-9.5 34.69-8.34 27.35-17.8 58.35-17.53 89.82.3 36.59 13.91 65.87 41.62 89.51 13.83 11.8 22.06 24.62 25.17 39.2 2.76 12.94 1.38 26.93-4.22 42.78-10.35 29.3-33.62 60-56.13 89.77-37.51 49.4-72.92 96.14-46.99 131.71 32.19 44.16 63.46 42.09 80.27 41 6.07-.4 10.08-.67 11.44 1.57 2.65 4.32-4.88 17-29.84 58.89-7.4 12.42-16.6 27.88-27.39 46.25-15.19 25.86-17.6 56.4-7.16 90.78 8.35 27.53 24.91 57.56 49.22 89.27 41.4 54 91.6 94.67 92.1 95.07z"
              fill="url(#prefix__Degradado_sin_nombre_656-39)"
            />
            <path
              d="M261 1645.61c-.5-.4-51.37-40.41-93.63-94-24.83-31.48-41.88-61.41-50.68-89-11-34.48-9-65.32 5.82-91.66q7.17-12.71 13.29-23.5c32.85-58.07 43.92-77.64 40.59-82.79-1.5-2.31-6.17-1.87-12.64-1.26-17.47 1.65-50 4.72-81-39.46-24.88-35.39 10.33-81.59 47.61-130.49 22.69-29.78 46.16-60.57 56.67-90.06 5.66-15.9 7.1-30 4.4-43-3-14.69-11.23-27.67-25-39.66-61.78-53.76-40.46-126.21-24.9-179.12 3.69-12.54 7.17-24.38 9.07-34.44 4.14-22 18.6-37 32.58-51.57 13.54-14.1 26.33-27.41 30.29-46.4 4.5-21.49-3.18-46.59-24.15-79-16.59-25.64-23.67-47.85-21.65-67.9 1.82-17.93 10.6-32.65 19.1-46.88 15.64-26.2 31.81-53.3 7.74-102.58-34-69.56-33.29-104.65-32.17-162.78.54-27.87 1.22-62.56-2.06-109h.5c3.28 46.41 2.6 81.12 2.06 109-1.12 58.06-1.8 93.09 32.12 162.55 24.19 49.53 8 76.74-7.76 103.05-8.47 14.19-17.23 28.86-19 46.68-2 19.94 5 42 21.57 67.57 21.05 32.52 28.74 57.74 24.22 79.37-4 19.12-16.83 32.48-30.42 46.63-13.93 14.51-28.34 29.5-32.45 51.32-1.9 10.08-5.39 21.94-9.08 34.49-15.53 52.78-36.79 125.05 24.75 178.6 13.88 12.07 22.11 25.14 25.18 39.94 2.72 13.13 1.28 27.28-4.42 43.27-10.53 29.57-34 60.39-56.74 90.2-37.17 48.76-72.27 94.81-47.6 129.91 30.88 43.94 63.22 40.89 80.59 39.24 6.89-.65 11.44-1.08 13.11 1.49 3.5 5.41-6.72 23.46-40.58 83.3L123 1371.24c-14.8 26.21-16.74 56.91-5.78 91.25 8.77 27.5 25.79 57.38 50.58 88.81 42.23 53.54 93 93.52 93.56 93.92z"
              fill="url(#prefix__Degradado_sin_nombre_656-40)"
            />
            <path
              d="M264.81 1642c-.51-.39-52-39.73-95.1-92.83-25.32-31.2-42.83-61-52.05-88.51-11.52-34.44-10-65.44 4.45-92.14 41.79-77.06 54.81-101.73 50.6-107.94-1.84-2.7-7.15-2.07-14.49-1.19-9.4 1.12-22.28 2.66-36.55-1.58-16.82-5-31.87-17.15-44.73-36.15-23.65-35 11.27-80.45 48.24-128.62 22.91-29.85 46.6-60.72 57.3-90.47 5.76-16.05 7.27-30.28 4.61-43.51-3-14.92-11.19-28.14-25-40.42-60.8-53.95-40.44-125.84-25.57-178.33 3.54-12.5 6.88-24.3 8.65-34.28 3.82-21.64 19.57-36.78 34.79-51.43 14.15-13.59 28.77-27.66 33.56-46.81 5.37-21.47-2.43-47.32-23.85-79-17.45-25.84-24.81-48.34-22.5-68.8 2.07-18.3 11.53-33.44 20.69-48.08 16.5-26.41 33.56-53.71 10.6-101.65-38.53-80.44-39.49-116.77-41.61-196.36-.57-21.43-1.21-45.72-2.69-74.92h.5c1.48 29.21 2.12 53.5 2.69 74.94 2.12 79.52 3.08 115.81 41.56 196.16 23.08 48.18 5.94 75.6-10.63 102.12-9.12 14.6-18.56 29.69-20.61 47.87-2.3 20.34 5 42.73 22.42 68.47 21.5 31.83 29.32 57.81 23.91 79.42-4.82 19.29-19.5 33.41-33.69 47-15.17 14.59-30.85 29.68-34.65 51.15-1.77 10-5.11 21.83-8.66 34.33-14.83 52.37-35.14 124.08 25.42 177.83 26 23.05 32.7 50.68 20.56 84.47-10.72 29.82-34.44 60.72-57.37 90.6-36.86 48-71.67 93.38-48.23 128 12.8 18.9 27.76 31 44.46 36 14.17 4.19 27 2.67 36.35 1.55 7.52-.9 13-1.54 15 1.41 4.39 6.47-7.76 29.52-50.57 108.46-14.41 26.57-15.89 57.43-4.41 91.74 9.19 27.47 26.67 57.19 52 88.34 43.06 53.06 94.51 92.37 95 92.76z"
              fill="url(#prefix__Degradado_sin_nombre_656-41)"
            />
            <path
              d="M268.6 1638.47c-.53-.38-52.66-39-96.59-91.68-25.81-30.91-43.78-60.53-53.41-88-12-34.41-11-65.57 3.07-92.62 40.41-77.56 52.22-102.79 47.41-109.59-2.22-3.14-8.22-2.3-16.53-1.12-19.86 2.81-53.1 7.51-81.41-36-22.43-34.5 12.2-79.3 48.87-126.73 23.12-29.92 47-60.86 57.92-90.88 5.88-16.19 7.46-30.59 4.83-44-3-15.15-11.15-28.62-25-41.18-59.81-54.13-40.41-125.45-26.24-177.52 3.4-12.47 6.6-24.24 8.23-34.16 3.49-21.24 20.5-36.45 36.95-51.16 15.3-13.83 31.21-28 36.84-47.33 6.33-21.74-1.37-47.62-23.54-79.13-18.32-26-25.95-48.84-23.34-69.71C169 439 179.15 423.43 189 408.37c17.34-26.59 35.28-54.08 13.41-100.69C154.73 206.09 152 170.64 148 36.85h.5c4 133.71 6.71 169.14 54.32 270.63 22 46.87 4 74.48-13.44 101.18-9.8 15-19.92 30.53-22.25 49.08-2.59 20.75 5 43.43 23.26 69.37 49.7 70.62 17.62 99.31-13.41 127-17.14 15.33-33.32 29.8-36.79 50.88-1.63 9.94-4.84 21.72-8.23 34.2-14.14 51.95-33.5 123.09 26.09 177 26.05 23.58 32.71 51.62 20.34 85.74-10.92 30.09-34.86 61-58 91-36.55 47.29-71.08 92-48.84 126.16 28.13 43.26 61.18 38.58 80.92 35.79 8.49-1.2 14.61-2.06 17 1.33 5 7-6.86 32.35-47.37 110.11-14 26.92-15.06 58-3.05 92.22 9.61 27.44 27.55 57 53.32 87.88 43.89 52.58 96 91.21 96.5 91.6z"
              fill="url(#prefix__Degradado_sin_nombre_656-42)"
            />
            <path
              d="M272.37 1634.9c-.52-.38-53.3-38.36-98.06-90.51-26.3-30.64-44.74-60.11-54.79-87.57-12.57-34.37-12-65.7 1.72-93.11 39.6-79.15 49.53-104.1 44.29-111.24-2.65-3.61-9.4-2.53-18.75-1-10.75 1.73-24.14 3.89-38.51.28-17-4.29-31.08-15.61-42.92-34.61-21.23-34 13.11-78.15 49.46-124.84 23.35-30 47.49-61 58.57-91.26 6-16.35 7.63-30.91 5.05-44.54-2.92-15.37-11.1-29.09-25-42-58.82-54.29-40.38-125-26.91-176.66 3.25-12.45 6.32-24.21 7.81-34.07 3.16-20.8 20.59-35.38 39-50.81 33.55-28.06 68.25-57.08 17-127-19.2-26.23-27.11-49.33-24.19-70.63 2.62-19 13.49-35 24-50.51 18.17-26.75 37-54.41 16.16-99.73l-.64-1.39C161.32 207 143 166.94 141.91 34.7h.5c1 132.14 19.4 172.14 63.75 268.78l.64 1.39c20.92 45.58 2 73.36-16.2 100.22-10.48 15.43-21.32 31.38-23.92 50.3-2.91 21.16 5 44.14 24.09 70.26 23 31.49 30.67 57.57 23.3 79.75-6.54 19.65-23.76 34.06-40.41 48-18.38 15.37-35.74 29.89-38.86 50.51-1.5 9.88-4.57 21.65-7.82 34.11-13.44 51.53-31.84 122.09 26.76 176.18 26.13 24.12 32.71 52.58 20.11 87-11.11 30.35-35.27 61.39-58.64 91.4-36.21 46.54-70.48 90.5-49.44 124.25 11.78 18.89 25.72 30.14 42.62 34.39 14.27 3.59 27.6 1.44 38.31-.28 9.52-1.54 16.4-2.65 19.23 1.22 5.39 7.33-4.54 32.39-44.24 111.75-13.65 27.29-14.22 58.48-1.7 92.71 10 27.42 28.43 56.83 54.69 87.42 44.73 52.1 97.46 90 98 90.43z"
              fill="url(#prefix__Degradado_sin_nombre_656-43)"
            />
            <path
              d="M276.16 1631.33c-.54-.37-54-37.68-99.57-89.35-26.79-30.36-45.68-59.66-56.15-87.1-13.1-34.33-13-65.82.37-93.6 36.78-76.58 47.19-105.06 41.24-112.88-3.14-4.13-11.08-2.71-21.14-.91-22.26 4-55.9 10-81.36-32.66-20-33.6 14-77 50.05-122.93 23.57-30.05 47.94-61.12 59.22-91.66 12.76-34.55 6.3-63.26-19.75-87.78C91.24 858 108.73 787.9 121.49 736.69c3.11-12.45 6-24.21 7.4-34 2.81-20.33 21.39-34.93 41.06-50.39 17.86-14 36.32-28.55 43.69-48.26 8.32-22.29.83-48.3-22.91-79.52-20.09-26.41-28.27-49.82-25-71.55 2.91-19.41 14.51-35.84 25.73-51.73 19-26.89 38.62-54.7 18.86-98.76-7.19-16-14-30.42-20.62-44.33-16.24-34.23-30.26-63.79-39.84-98-10.33-36.87-14.79-77.42-14-127.61h.5c-.74 50.14 3.72 90.65 14 127.47 9.57 34.14 23.59 63.69 39.82 97.9 6.59 13.91 13.42 28.29 20.62 44.34 19.87 44.32.16 72.24-18.91 99.24-11.18 15.85-22.75 32.23-25.65 51.53-3.23 21.59 4.91 44.87 24.92 71.17 23.85 31.36 31.37 57.53 23 80-7.41 19.85-25.93 34.41-43.84 48.48-19.59 15.4-38.1 29.94-40.88 50.07-1.36 9.84-4.3 21.61-7.4 34.07-12.8 51.09-30.24 121.06 27.37 175.29 26.21 24.67 32.71 53.56 19.88 88.31-11.31 30.62-35.71 61.72-59.3 91.8-35.93 45.8-69.87 89.06-50 122.36C85.26 1257 118.7 1251 140.83 1247c10.62-1.9 18.29-3.28 21.62 1.1 6.16 8.1-3.85 35.65-41.19 113.4-13.28 27.64-13.4 59-.35 93.19 10.44 27.39 29.3 56.64 56.05 87 45.57 51.63 99 88.91 99.48 89.28z"
              fill="url(#prefix__Degradado_sin_nombre_656-44)"
            />
            <path
              d="M279.94 1627.76c-.55-.37-54.62-37-101.07-88.18-27.28-30.08-46.64-59.23-57.52-86.64-13.62-34.29-13.95-66-1-94.09 35-75.95 45-105.92 38.27-114.52-3.68-4.68-12.51-2.95-23.69-.76-22.77 4.45-57.18 11.19-81.19-31-18.86-33.15 14.88-75.83 50.6-121 23.8-30.11 48.4-61.23 59.88-92 13-34.87 6.62-64-19.5-89.09-56.82-54.55-40.31-124.07-28.25-174.83 3-12.47 5.76-24.24 7-34 2.46-19.82 22.16-34.43 43-49.9 23.17-17.19 47.12-35 50.69-61.75 2.58-19.45-6-41.31-26.09-66.83-21-26.59-29.43-50.3-25.82-72.47 3.22-19.8 15.57-36.67 27.51-53 19.77-27 40.22-54.94 21.49-97.75-10.34-23.63-21-44.12-31.27-63.93-30.58-58.88-57-109.73-53.31-205.54h.5c-3.67 95.68 22.71 146.47 53.25 205.28 10.3 19.83 20.94 40.32 31.29 64 18.85 43.07-1.69 71.12-21.55 98.25-11.89 16.16-24.16 32.99-27.4 52.66-3.59 22 4.83 45.61 25.72 72.08 20.23 25.63 28.8 47.62 26.19 67.2C214.08 617 190 634.79 166.78 652 146 667.44 126.4 682 124 701.6c-1.22 9.81-4 21.59-7 34.07-12 50.65-28.51 120 28.1 174.35 26.28 25.24 32.7 54.55 19.63 89.64-11.51 30.88-36.14 62-60 92.17-35.61 45.05-69.25 87.6-50.56 120.46 10.54 18.53 23.82 29.26 40.59 32.81 14.29 3 28.59.22 40.07-2s20.31-4 24.18.94c6.9 8.78-3.09 38.89-38.2 115-12.91 28-12.58 59.53 1 93.7 10.87 27.35 30.19 56.45 57.43 86.48 46.41 51.15 100.43 87.74 101 88.1z"
              fill="url(#prefix__Degradado_sin_nombre_656-45)"
            />
            <path
              d="M283.72 1624.18c-.55-.35-55.28-36.29-102.57-87-27.78-29.8-47.6-58.79-58.9-86.17-14.14-34.26-14.92-66.08-2.31-94.59 32.9-74.38 42.81-106.94 35.34-116.15-4.26-5.27-14-3.21-26.39-.6-11.89 2.51-26.69 5.62-41.06 2.85C71 1239.27 58 1228.72 48 1210.25c-17.69-32.7 15.75-74.67 51.15-119.11 24-30.15 48.86-61.33 60.54-92.4 13.25-35.2 6.95-64.78-19.24-90.43-55.81-54.64-40.27-123.54-28.92-173.84 2.82-12.5 5.49-24.31 6.56-34.06C120.17 681.12 141 666.51 163 651c25.12-17.66 51.1-35.92 54.89-63.56 2.66-19.35-6-40.89-26.4-65.85-21.89-26.77-30.6-50.78-26.63-73.41 3.55-20.19 16.66-37.5 29.34-54.23 20.55-27.11 41.8-55.15 24.07-96.74-11.75-27.56-25.52-51.46-38.83-74.56-31.6-54.73-61.44-106.46-55.86-194.41h.5c-5.53 87.8 24.23 139.47 55.75 194.17 13.32 23.12 27.1 47 38.86 74.62 17.84 41.85-3.5 70-24.13 97.23-12.65 16.69-25.72 33.94-29.25 54-3.94 22.47 4.73 46.35 26.53 73 20.51 25.08 29.18 46.74 26.51 66.24-3.82 27.85-29.89 46.18-55.11 63.9-21.9 15.4-42.59 29.95-44.69 49-1.08 9.78-3.75 21.6-6.57 34.12-11.32 50.25-26.83 118.99 28.78 173.48 26.35 25.8 32.68 55.56 19.36 91-11.71 31.15-36.58 62.36-60.62 92.54-35.3 44.22-68.63 86.06-51.1 118.46 22.4 41.39 55.91 34.33 80.38 29.17 12.54-2.64 22.44-4.73 26.89.77 3.82 4.72 3.23 14.93-1.85 32.15-5.43 18.38-16 45.24-33.42 84.53-12.55 28.37-11.77 60.06 2.31 94.18 11.28 27.33 31.06 56.27 58.8 86 47.24 50.67 101.93 86.58 102.48 86.94z"
              fill="url(#prefix__Degradado_sin_nombre_656-46)"
            />
            <path
              d="M287.5 1620.61c-.56-.35-55.95-35.6-104.08-85.83-28.28-29.51-48.56-58.34-60.28-85.7-14.65-34.22-15.88-66.21-3.63-95.08 31.31-73.84 40.73-108 32.51-117.83-4.9-5.87-15.63-3.46-29.22-.4-25.08 5.63-59.42 13.35-80.63-27.81-16.48-32.27 16.63-73.52 51.69-117.19 24.25-30.2 49.32-61.43 61.21-92.76 13.49-35.52 7.27-65.54-19-91.77-54.78-54.71-40.21-123-29.58-172.81 2.8-13.1 5.22-24.42 6.14-34.16 1.78-18.74 23.61-33.33 46.73-48.77 27.14-18.14 55.2-36.89 59.2-65.38 2.7-19.24-6-40.48-26.75-64.93-22.81-26.94-31.81-51.26-27.42-74.35 3.88-20.59 17.78-38.33 31.22-55.5 10.65-13.59 21.66-27.65 27.69-43.08 6.76-17.3 6.4-34.5-1.11-52.62-12.33-29.73-29-55.85-45.1-81.11-17.32-27.16-33.69-52.82-45.27-82.72-13.12-33.88-17.68-67.16-14.35-104.72h.5c-3.33 37.48 1.22 70.69 14.32 104.5 11.56 29.86 27.91 55.49 45.22 82.63 16.13 25.28 32.8 51.42 45.14 81.19 16.85 40.66-5.26 68.89-26.65 96.2-13.4 17.12-27.26 34.81-31.12 55.28-4.33 22.93 4.61 47.12 27.32 73.94 20.79 24.56 29.58 45.93 26.86 65.32-4 28.71-33.46 48.37-59.42 65.72-23 15.38-44.75 29.91-46.51 48.41-.93 9.77-3.35 21.1-6.15 34.21-10.61 49.73-25.14 117.84 29.45 172.35 26.42 26.39 32.67 56.58 19.11 92.31-11.92 31.4-37 62.66-61.29 92.9-34.95 43.54-68 84.66-51.63 116.65 21 40.8 55.15 33.14 80.07 27.54 13.76-3.09 24.62-5.53 29.71.57 4.17 5 4 15.5-.68 33-4.95 18.7-15.34 46.6-31.75 85.31-12.19 28.74-11 60.6 3.63 94.69 11.69 27.3 31.94 56.08 60.17 85.55 48.09 50.19 103.44 85.41 104 85.76z"
              fill="url(#prefix__Degradado_sin_nombre_656-47)"
            />
            <path
              d="M291.28 1617c-.56-.34-56.62-34.9-105.6-84.65-28.78-29.23-49.52-57.9-61.65-85.23-15.18-34.19-16.84-66.35-5-95.59 29.79-73.27 38.67-109 29.69-119.46-5.58-6.5-17.92-3.57-32.21-.17-13.2 3.14-28.15 6.7-42.45 4.44-16.6-2.63-28.94-12.67-37.72-30.71-15.36-31.81 17.45-72.35 52.18-115.26 24.48-30.24 49.79-61.52 61.9-93.11 13.74-35.85 7.61-66.32-18.72-93.14-53.7-54.71-40.12-122.31-30.2-171.68 2.65-13.18 4.94-24.56 5.71-34.3 1.45-18.14 24.29-32.73 48.48-48.14 27.84-17.75 59.41-37.86 63.6-67.19 2.74-19.14-6.13-40.09-27.11-64.06-23.74-27.11-33-51.74-28.22-75.3 4.24-21 18.94-39.18 33.16-56.77 11-13.65 22.42-27.75 28.85-43.05 7.19-17.14 7.25-34 .18-51.59-12.46-31-31.91-58.7-50.73-85.53-17.95-25.6-36.51-52.07-49.1-81.42-14.13-32.94-19-66-15-101.16l.5.06c-4 35 .83 68 14.92 100.9 12.56 29.3 31.11 55.75 49.05 81.34 18.83 26.85 38.3 54.62 50.78 85.62 15.88 39.47-7 67.77-29.1 95.14-14.18 17.55-28.84 35.69-33.06 56.56-4.72 23.4 4.47 47.89 28.1 74.87 21.09 24.08 30 45.17 27.24 64.47-4.23 29.54-35.9 49.72-63.84 67.53-24.07 15.34-46.81 29.83-48.24 47.76-.77 9.77-3.06 21.16-5.72 34.36-9.9 49.26-23.46 116.71 30.11 171.27 26.48 27 32.64 57.61 18.83 93.67-12.14 31.66-37.48 63-62 93.25-34.63 42.78-67.34 83.2-52.12 114.73 8.7 17.87 20.92 27.82 37.35 30.42 14.2 2.24 29.1-1.3 42.25-4.42 14.45-3.44 26.93-6.41 32.71.32 9.24 10.77.66 45.53-29.6 120-11.84 29.11-10.17 61.13 4.95 95.19 12.1 27.27 32.81 55.9 61.54 85.08 48.94 49.71 105 84.24 105.51 84.59z"
              fill="url(#prefix__Degradado_sin_nombre_656-48)"
            />
            <path
              d="M295.06 1613.47c-.57-.34-57.3-34.21-107.13-83.47-29.27-28.94-50.47-57.46-63-84.76-15.69-34.15-17.8-66.49-6.27-96.1 28.7-73.7 36.75-109.91 26.91-121.08-6.31-7.17-19.76-3.8-35.34.09-27.3 6.83-61.27 15.33-79.63-24.76-14.24-31.36 18.25-71.18 52.66-113.34 24.71-30.28 50.27-61.59 62.59-93.45 14-36.18 8-67.09-18.42-94.51-52.71-54.77-40.1-121.7-30.89-170.58 2.5-13.28 4.66-24.75 5.28-34.5 1.1-17.61 24.92-32.12 50.14-47.48 29.87-18.2 63.73-38.83 68.1-69 2.77-19-6.23-39.72-27.5-63.24-24.68-27.27-34.16-52.22-29-76.26 4.61-21.4 20.13-40 35.14-58.07 11.39-13.67 23.17-27.81 30-43 7.62-17 8.09-33.51 1.44-50.57-12.32-31.61-33.53-59.33-56-88.68C135 149.58 94.48 96.67 105.26 21.78l.49.07C95 96.53 135.43 149.36 174.52 200.45c22.48 29.37 43.71 57.12 56 88.8 6.7 17.19 6.23 33.86-1.45 51-6.83 15.22-18.63 29.39-30 43.09-15 18-30.45 36.57-35 57.86-5.17 23.8 4.28 48.63 28.84 75.8 21.38 23.63 30.42 44.45 27.63 63.65C216.13 611 182.17 631.7 152.2 650c-25.1 15.29-48.81 29.74-49.9 47.08-.62 9.78-2.78 21.26-5.29 34.56-9.19 48.77-21.77 115.56 30.76 170.14 26.54 27.57 32.6 58.66 18.53 95-12.36 31.93-37.94 63.28-62.68 93.59-34.3 42-66.7 81.73-52.58 112.81 18.18 39.71 51.93 31.27 79.05 24.49 15.73-3.94 29.32-7.34 35.83.06 10 11.33 2 47.69-26.81 121.59-11.48 29.48-9.38 61.68 6.25 95.71 12.52 27.24 33.69 55.71 62.92 84.61 49.79 49.22 106.46 83.06 107 83.4z"
              fill="url(#prefix__Degradado_sin_nombre_656-49)"
            />
            <path
              d="M298.84 1609.9c-.58-.33-58-33.51-108.66-82.28-29.77-28.66-51.44-57-64.39-84.29-16.21-34.12-18.76-66.63-7.58-96.62 27.28-73.17 34.73-111 24.17-122.7-7.1-7.85-21.69-4-38.59.39-27.65 7.24-62.06 16.24-79-23.3-6-14.13-3.26-30.51 8.67-51.58 10.59-18.71 27-38.68 44.43-59.82 25-30.32 50.75-61.67 63.3-93.78 14.26-36.51 8.34-67.88-18.11-95.9-51.67-54.76-40-121-31.54-169.39 2.36-13.41 4.39-25 4.84-34.75.78-17 24.39-30.82 51.74-46.78 31.95-18.66 68.16-39.8 72.69-70.76 2.78-19-6.35-39.38-27.91-62.46-13.72-14.7-22.7-28.4-27.45-41.88a61.86 61.86 0 01-2.29-35.36c5-21.82 21.35-40.91 37.18-59.37 11.74-13.71 23.88-27.87 31.06-42.9 8-16.79 8.9-33 2.67-49.55-12-31.92-35.83-60.64-61-91-42.49-51.27-86.39-104.25-73.92-176.19l.49.08C87.21 91.43 131 144.31 173.43 195.46c25.22 30.44 49 59.19 61.11 91.19 6.28 16.68 5.4 33-2.69 49.94-7.2 15.08-19.37 29.28-31.14 43-15.78 18.42-32.1 37.46-37.07 59.16-5.57 24.36 4.12 49.47 29.63 76.79 21.67 23.2 30.84 43.76 28 62.87-4.57 31.2-40.89 52.41-72.94 71.12-27.22 15.89-50.73 29.62-51.49 46.37-.45 9.8-2.49 21.39-4.84 34.82C83.56 779 72 845.1 123.44 899.67c59.56 63.11 3.92 130.71-45.17 190.33-17.38 21.12-33.8 41.08-44.37 59.75-11.85 20.91-14.6 37.16-8.65 51.13 7.46 17.42 18.92 26.88 35 28.92 14 1.78 29.63-2.3 43.38-5.9 17.06-4.47 31.79-8.32 39.09-.25 5.2 5.76 6.13 17.39 2.85 35.58-3.63 20.11-12.43 48.78-26.92 87.64-11.14 29.85-8.59 62.23 7.56 96.22 12.93 27.22 34.56 55.52 64.29 84.14 50.63 48.74 108 81.89 108.56 82.22z"
              fill="url(#prefix__Degradado_sin_nombre_656-50)"
            />
            <path
              d="M302.62 1606.33c-.58-.32-58.66-32.8-110.19-81.09-30.28-28.38-52.41-56.58-65.78-83.82-16.72-34.09-19.71-66.77-8.87-97.14 26.27-73.64 32.89-112 21.46-124.32-7.93-8.56-23.69-4.26-41.95.72-14.07 3.84-30 8.19-44.05 6.62-16.11-1.8-27.32-11.12-34.25-28.5-5.51-13.91-2.53-30 9.4-50.69 10.6-18.37 26.88-38 44.13-58.76 49.5-59.63 105.61-127.22 46.23-191.41-50.61-54.71-39.95-120.26-32.17-168.13 2.21-13.56 4.11-25.27 4.38-35.06.46-16.44 24.93-30.17 53.26-46.06 34.07-19.11 72.69-40.76 77.38-72.53 2.78-18.85-6.49-39-28.34-61.72-59.34-61.61-24.71-100.91 8.78-138.92 12.09-13.71 24.59-27.9 32.13-42.77 8.42-16.62 9.68-32.49 3.86-48.53-11.62-32-38-61.57-65.92-92.89C126.27 139.94 78.88 86.79 93 17.47l.49.1C79.42 86.65 126.73 139.7 172.48 191c28 31.35 54.37 61 66 93.05 5.87 16.17 4.6 32.18-3.89 48.92-7.56 14.93-20.08 29.14-32.19 42.88-16.61 18.86-33.79 38.36-39.16 60.48a60.68 60.68 0 002.11 35.67c4.84 13.6 14.08 27.37 28.27 42.1 22 22.8 31.28 43.13 28.47 62.14-4.72 32-43.45 53.73-77.62 72.9-28.21 15.81-52.56 29.47-53 45.63-.27 9.83-2.18 21.56-4.38 35.13-7.77 47.77-18.4 113.18 32 167.71 59.67 64.51 3.42 132.28-46.22 192.07-17.23 20.76-33.5 40.36-44.08 58.69-11.84 20.53-14.82 36.5-9.36 50.26 6.85 17.19 17.92 26.41 33.84 28.19 13.93 1.56 29.83-2.78 43.86-6.61 18.41-5 34.32-9.36 42.45-.58 11.57 12.49 5 51-21.36 124.83-10.79 30.24-7.81 62.78 8.85 96.74 13.34 27.2 35.44 55.35 65.67 83.68 51.48 48.25 109.51 80.7 110.09 81z"
              fill="url(#prefix__Degradado_sin_nombre_656-51)"
            />
            <path
              d="M306.4 1602.76c-.59-.32-59.34-32.09-111.73-79.9-30.78-28.08-53.37-56.13-67.15-83.35-17.24-34-20.67-66.91-10.18-97.65 13.35-39.12 21.15-68.28 23.87-89.16 2.42-18.6.77-30.64-5.07-36.79-8.81-9.28-26.6-4.24-45.44 1.1-29.68 8.41-63.33 17.94-77.5-20.5-5-13.7-1.82-29.53 10.1-49.81 10.6-18 26.74-37.29 43.82-57.69 50.06-59.78 106.8-127.52 47.3-193.14-49.54-54.63-39.85-119.47-32.78-166.81 2-13.74 3.82-25.6 3.9-35.44.14-15.86 25.43-29.5 54.7-45.3 36.25-19.56 77.34-41.74 82.16-74.3 2.78-18.75-6.63-38.71-28.77-61-61.54-62-25.07-102.28 10.19-141.25 12.42-13.77 25.27-27.94 33.18-42.65 8.92-16.66 10.51-31.76 5-47.51-11.13-31.89-40.07-62.23-70.71-94.35-24.27-25.44-49.37-51.74-66.35-80-19.2-31.93-25.09-62-18-92l.49.11c-7.06 29.83-1.19 59.79 17.94 91.6 17 28.18 42 54.46 66.28 79.88 30.68 32.16 59.66 62.54 70.82 94.53 5.47 15.68 3.81 31.35-5.05 47.91-7.91 14.77-20.78 29-33.22 42.74-17.46 19.3-35.52 39.25-41.31 61.81a60.13 60.13 0 001.89 36.24c4.95 13.79 14.5 27.69 29.21 42.51 22.26 22.42 31.72 42.52 28.91 61.45-4.86 32.81-46.06 55-82.41 74.66-29.14 15.72-54.3 29.3-54.44 44.86-.08 9.88-1.86 21.75-3.91 35.51-7.06 47.24-16.72 112 32.66 166.4 59.79 65.94 2.91 133.87-47.28 193.8-17.08 20.44-33.21 39.73-43.79 57.73-11.84 20.14-15 35.83-10.06 49.38 14 38 47.42 28.54 76.89 20.19 19-5.38 36.93-10.46 45.94-1 12.44 13.11 6.68 52.11-18.69 126.45-10.44 30.61-7 63.34 10.15 97.26 13.75 27.17 36.31 55.16 67 83.21 52.34 47.76 111.05 79.51 111.63 79.82z"
              fill="url(#prefix__Degradado_sin_nombre_656-52)"
            />
            <path
              d="M310.18 1599.18c-.6-.31-60-31.37-113.27-78.69-31.28-27.8-54.34-55.68-68.53-82.87-17.76-34-21.62-67.06-11.47-98.19 24-73.66 29.14-114.19 16.16-127.56-9.73-10-28.82-4.43-49 1.5-29.92 8.78-63.82 18.73-76.64-19.15-4.55-13.49-1.12-29 10.8-48.93 10.6-17.69 26.58-36.6 43.5-56.63 50.62-59.9 108-127.79 48.38-194.87-48.48-54.52-39.76-118.62-33.4-165.43 1.89-13.94 3.53-26 3.42-35.87C80 677.22 106 663.66 136.19 648c38.47-20 82.07-42.69 87-76 2.77-18.67-6.79-38.41-29.22-60.36-63.74-62.44-25.39-103.74 11.7-143.64 26.47-28.5 51.47-55.43 40.28-88.95-10.58-31.67-42.08-62.7-75.44-95.55-25.92-25.53-52.73-51.94-70.85-80C79.14 71.65 73 42.12 80.82 13.16l.48.14c-7.8 28.81-1.66 58.2 18.78 89.86 18.09 28 44.88 54.42 70.79 79.94 33.39 32.89 64.93 64 75.55 95.74 5.07 15.19 3.05 30.53-6.18 46.89C232 340.34 218.8 354.55 206 368.3c-36.93 39.76-75.11 80.88-11.7 142.91 22.55 22.06 32.16 41.95 29.37 60.79-5 33.59-48.72 56.34-87.29 76.41-30 15.62-56 29.11-55.79 44.07.11 9.93-1.53 22-3.43 35.95-6.35 46.71-15 110.68 33.26 165 26.73 30.07 32.35 63 17.18 100.68-13.27 33-39.85 64.43-65.56 94.85-16.9 20-32.87 38.9-43.45 56.56-11.84 19.75-15.26 35.17-10.75 48.51 5.65 16.71 15.93 25.47 31.42 26.76 13.68 1.15 29.4-3.47 44.6-7.93 20.36-6 39.58-11.62 49.53-1.37 6.32 6.51 8.41 18.94 6.38 38-2.27 21.28-9.61 50.74-22.43 90.07-10.1 31-6.25 63.9 11.44 97.8 14.16 27.14 37.18 55 68.41 82.73 53.2 47.28 112.58 78.32 113.18 78.63z"
              fill="url(#prefix__Degradado_sin_nombre_656-53)"
            />
            <path
              d="M314 1595.61c-.6-.3-60.72-30.66-114.82-77.48-31.79-27.51-55.31-55.24-69.91-82.41-18.27-34-22.56-67.2-12.75-98.72 23.08-74.19 27.39-115.23 13.55-129.18-10.71-10.79-31.12-4.6-52.74 2-15.45 4.69-31.42 9.53-45 8.58-15.49-1.09-25.51-9.73-30.64-26.42-4.09-13.28-.44-28.55 11.47-48 10.6-17.35 26.42-35.91 43.18-55.55 25.93-30.41 52.74-61.84 66.22-95 15.37-37.8 9.9-71-16.73-101.61C58.39 837.35 66.14 774 71.8 727.74c1.73-14.18 3.23-26.42 2.91-36.38-.47-14.69 26.29-28.14 57.29-43.72 19.56-9.83 41.73-21 59.11-33.65 19.89-14.51 30.67-28.94 32.89-44.14 2.76-18.58-6.95-38.11-29.68-59.72-15.83-15.05-26-29.29-31.21-43.53a59.4 59.4 0 01-1.44-37.76c6.72-23.56 26.63-44.48 45.89-64.71 27.07-28.44 52.64-55.3 42.32-87.74-10-31.36-44-63-80.1-96.54-27.6-25.66-56.14-52.19-75.41-80.15C72.56 68 66.12 39 74.71 11l.48.15C66.65 39 73.07 67.88 94.81 99.42c19.24 27.91 47.76 54.43 75.34 80.07 36.1 33.56 70.2 65.27 80.23 96.75C260.8 309 235.13 335.92 208 364.47c-19.22 20.19-39.09 41.06-45.78 64.51a58.9 58.9 0 001.43 37.44c5.15 14.18 15.31 28.35 31.08 43.34 22.86 21.73 32.62 41.41 29.84 60.17-2.28 15.33-13.1 29.87-33.09 44.46-17.41 12.72-39.6 23.86-59.17 33.69-30.91 15.51-57.56 28.92-57.1 43.26.32 10-1.18 22.26-2.91 36.46-5.65 46.2-13.38 109.39 33.83 163.59 26.77 30.73 32.27 64.13 16.81 102.13-13.51 33.23-40.35 64.7-66.3 95.13-16.74 19.63-32.56 38.18-43.14 55.49-11.82 19.37-15.45 34.51-11.41 47.65 5.06 16.46 14.94 25 30.19 26.06 13.53 1 29.46-3.88 44.87-8.55 21.76-6.6 42.31-12.83 53.23-1.83 14 14.09 9.72 55.3-13.43 129.68-9.76 31.38-5.49 64.46 12.72 98.33 14.57 27.12 38.05 54.79 69.79 82.26 54 46.78 114.12 77.12 114.72 77.42z"
              fill="url(#prefix__Degradado_sin_nombre_656-54)"
            />
            <path
              d="M754.83 620.84q-9.6 14.1-19.21 28.22-9.68 14.28-19.34 28.55-7.6 11.19-15.2 22.39l.45.15q6.38-9.39 12.76-18.76 9.69-14.26 19.4-28.51T753 624.34l2.14-3.16z"
              fill="url(#prefix__Degradado_sin_nombre_29)"
            />
            <path
              d="M752.22 618.16q-9.4 13.05-18.67 26.2-10.11 14.37-20 28.92-8.53 12.56-16.91 25.25l.85.28q7.07-10.77 14.23-21.47 9.82-14.6 19.86-29t20.34-28.69l.74-1z"
              fill="url(#prefix__Degradado_sin_nombre_29-2)"
            />
            <path
              d="M228.22 78.05q54.66 11 109.28 22.17 35.16 7.19 70.31 14.43l-.25-1.6q-27.87-6.22-55.75-12.32Q297 88.79 242.16 77.3q-54.61-11.46-109.28-22.63-30.25-6.18-60.58-12l-13.08 2q29.77 6 59.59 11.79 54.74 10.66 109.41 21.59z"
              fill="url(#prefix__Degradado_sin_nombre_5)"
            />
            <path
              d="M139.17 32.31l32.9 8q78.52 19 157 38 38 9.24 75.93 18.47l-.26-1.62q-28.06-6.85-56.14-13.72Q270.12 62.32 191.65 43l-47-11.52z"
              fill="url(#prefix__Degradado_sin_nombre_5-2)"
            />
            <path
              d="M293.38 110.61c39 8.44 78.1 17.15 117.5 23.8l-.46-2.94c-31.23-5.33-62.28-11.94-93.22-18.68-65.34-14.23-130.73-28.24-196.26-41.62Q70.39 60.85 19.76 50.88l-9 1.41q42.85 8.43 85.66 17.08 98.58 20 196.96 41.24z"
              fill="url(#prefix__Degradado_sin_nombre_5-3)"
            />
            <path
              d="M168.39 646.94l1.11-3.36c2.68-8.1-9.91-12.28-12.59-4.16l-1.11 3.35c-2.68 8.1 9.9 12.28 12.59 4.17z"
              fill="url(#prefix__Degradado_sin_nombre_656-55)"
            />
            <path
              d="M149.41 659.28l1.11-3.36c2.68-8.1-9.91-12.27-12.59-4.16l-1.11 3.35c-2.68 8.1 9.9 12.28 12.59 4.17z"
              fill="url(#prefix__Degradado_sin_nombre_656-56)"
            />
            <path
              d="M159 676.87c8.1 2.68 12.28-9.91 4.16-12.59s-12.27 9.9-4.16 12.59z"
              fill="url(#prefix__Degradado_sin_nombre_656-57)"
            />
            <path
              d="M196.6 641.84l-3.35-1.11c-8.1-2.68-12.28 9.91-4.17 12.59l3.36 1.11c8.1 2.68 12.28-9.9 4.16-12.59z"
              fill="url(#prefix__Degradado_sin_nombre_656-58)"
            />
            <path
              d="M159.91 606.43l3.36 1.11c8.1 2.68 12.27-9.9 4.16-12.59l-3.36-1.11c-8.1-2.68-12.27 9.9-4.16 12.59z"
              fill="url(#prefix__Degradado_sin_nombre_656-59)"
            />
            <path
              d="M196.66 664.2l-6.72-2.2c-8.1-2.68-12.27 9.91-4.16 12.59l6.71 2.22c8.1 2.68 12.28-9.9 4.17-12.59z"
              fill="url(#prefix__Degradado_sin_nombre_656-60)"
            />
            <path
              d="M114.54 688.71l-1.11 3.35c-2.68 8.1 9.9 12.28 12.58 4.17l1.11-3.36c2.68-8.1-9.9-12.28-12.58-4.16z"
              fill="url(#prefix__Degradado_sin_nombre_656-61)"
            />
            <path
              d="M101.89 658c8.1 2.68 12.28-9.9 4.16-12.59s-12.27 9.91-4.16 12.59z"
              fill="url(#prefix__Degradado_sin_nombre_656-62)"
            />
            <path
              d="M149.55 709l4.45-2.3c7.63-3.83 1.68-15.68-6-11.84l-4.47 2.25c-7.63 3.83-1.68 15.68 6 11.84z"
              fill="url(#prefix__Degradado_sin_nombre_656-63)"
            />
            <path
              d="M92.18 688.76l-1.11 3.35c-2.68 8.1 9.9 12.28 12.58 4.17l1.11-3.36c2.68-8.1-9.9-12.28-12.58-4.16z"
              fill="url(#prefix__Degradado_sin_nombre_656-64)"
            />
            <path
              d="M98.13 724.24l1.11-3.36c2.68-8.09-9.91-12.27-12.59-4.16l-1.11 3.36c-2.68 8.1 9.9 12.27 12.59 4.16z"
              fill="url(#prefix__Degradado_sin_nombre_656-65)"
            />
            <path
              d="M123.32 739.56c8.1 2.68 12.27-9.91 4.16-12.59s-12.28 9.9-4.16 12.59z"
              fill="url(#prefix__Degradado_sin_nombre_656-66)"
            />
            <path
              d="M58.38 699.45c8.1 2.68 12.28-9.9 4.16-12.58s-12.27 9.9-4.16 12.58z"
              fill="url(#prefix__Degradado_sin_nombre_656-67)"
            />
            <path
              d="M135.4 646.73c8.1 2.68 12.28-9.9 4.17-12.58s-12.28 9.9-4.17 12.58z"
              fill="url(#prefix__Degradado_sin_nombre_656-68)"
            />
            <path
              d="M97.32 615.51c8.1 2.68 12.28-9.9 4.16-12.58s-12.27 9.9-4.16 12.58z"
              fill="url(#prefix__Degradado_sin_nombre_656-69)"
            />
            <path
              d="M32.61 676c8.1 2.68 12.28-9.9 4.17-12.58S24.5 673.35 32.61 676z"
              fill="url(#prefix__Degradado_sin_nombre_656-70)"
            />
            <path
              d="M49.52 737.49c8.1 2.68 12.28-9.91 4.17-12.59s-12.28 9.9-4.17 12.59z"
              fill="url(#prefix__Degradado_sin_nombre_656-71)"
            />
            <path
              d="M178.06 720.43c8.1 2.68 12.27-9.91 4.16-12.59s-12.28 9.9-4.16 12.59z"
              fill="url(#prefix__Degradado_sin_nombre_656-72)"
            />
            <path
              d="M220.33 626.42c8.1 2.68 12.27-9.91 4.16-12.59s-12.27 9.9-4.16 12.59z"
              fill="url(#prefix__Degradado_sin_nombre_656-73)"
            />
            <path
              d="M213.92 758.36c8.1 2.68 12.28-9.91 4.16-12.59s-12.27 9.9-4.16 12.59z"
              fill="url(#prefix__Degradado_sin_nombre_656-74)"
            />
            <path
              d="M228.23 658.82l3.35 1.11c8.1 2.68 12.28-9.9 4.17-12.59l-3.36-1.11c-8.1-2.68-12.28 9.91-4.16 12.59z"
              fill="url(#prefix__Degradado_sin_nombre_656-75)"
            />
            <path
              d="M1487.4 1508l1.11-3.35c2.68-8.1-9.9-12.28-12.59-4.17l-1.11 3.36c-2.68 8.1 9.91 12.28 12.59 4.16z"
              fill="url(#prefix__Degradado_sin_nombre_3)"
            />
            <path
              d="M1444.22 1607.32l1.11-3.35c2.68-8.1-9.91-12.28-12.59-4.17l-1.11 3.36c-2.68 8.1 9.9 12.28 12.59 4.16z"
              fill="url(#prefix__Degradado_sin_nombre_9)"
            />
            <path
              d="M1503.93 1514.72c8.1 2.68 12.27-9.91 4.16-12.59s-12.28 9.9-4.16 12.59z"
              fill="url(#prefix__Degradado_sin_nombre_11)"
            />
            <path
              d="M1521.05 1461.33l-3.35-1.11c-8.1-2.68-12.28 9.9-4.17 12.58l3.36 1.11c8.1 2.68 12.28-9.9 4.16-12.58z"
              fill="url(#prefix__Degradado_sin_nombre_3-2)"
            />
            <path
              d="M1484.36 1425.91l3.35 1.11c8.1 2.68 12.28-9.9 4.17-12.58l-3.36-1.11c-8.1-2.68-12.28 9.9-4.16 12.58z"
              fill="url(#prefix__Degradado_sin_nombre_3-3)"
            />
            <path
              d="M1505.44 1549.05l-6.72-2.22c-8.09-2.68-12.27 9.9-4.16 12.59l6.71 2.22c8.1 2.68 12.28-9.9 4.17-12.59z"
              fill="url(#prefix__Degradado_sin_nombre_11-2)"
            />
            <path
              d="M1457.35 1671.87l-1.11 3.36c-2.68 8.1 9.9 12.27 12.59 4.16l1.11-3.35c2.68-8.1-9.91-12.28-12.59-4.17z"
              fill="url(#prefix__Degradado_sin_nombre_9-2)"
            />
            <path
              d="M1426.34 1477.47c8.1 2.68 12.27-9.9 4.16-12.58s-12.28 9.9-4.16 12.58z"
              fill="url(#prefix__Degradado_sin_nombre_3-4)"
            />
            <path
              d="M1535 1686.17l4.47-2.24c7.63-3.84 1.68-15.69-6-11.85l-4.46 2.25c-7.64 3.84-1.68 15.68 6 11.84z"
              fill="url(#prefix__Degradado_sin_nombre_9-3)"
            />
            <path
              d="M1589 1592.51l-1.11 3.36c-2.68 8.1 9.9 12.28 12.58 4.16l1.11-3.35c2.68-8.1-9.9-12.28-12.58-4.17z"
              fill="url(#prefix__Degradado_sin_nombre_9-4)"
            />
            <path
              d="M1524.54 1798.17l1.11-3.35c2.68-8.1-9.9-12.28-12.58-4.17L1512 1794c-2.68 8.1 9.9 12.28 12.58 4.16z"
              fill="url(#prefix__Degradado_sin_nombre_9-5)"
            />
            <path
              d="M1513.13 1612c8.1 2.68 12.28-9.9 4.16-12.59s-12.27 9.91-4.16 12.59z"
              fill="url(#prefix__Degradado_sin_nombre_9-6)"
            />
            <path
              d="M1443.87 1533c8.1 2.68 12.28-9.9 4.16-12.58s-12.27 9.9-4.16 12.58z"
              fill="url(#prefix__Degradado_sin_nombre_11-3)"
            />
            <path
              d="M1459.85 1466.22c8.1 2.68 12.28-9.91 4.17-12.59s-12.28 9.9-4.17 12.59z"
              fill="url(#prefix__Degradado_sin_nombre_16)"
            />
            <path
              d="M1421.77 1435c8.1 2.68 12.28-9.9 4.16-12.59s-12.27 9.91-4.16 12.59z"
              fill="url(#prefix__Degradado_sin_nombre_3-5)"
            />
            <path
              d="M1357.06 1495.52c8.1 2.68 12.28-9.9 4.16-12.59s-12.27 9.91-4.16 12.59z"
              fill="url(#prefix__Degradado_sin_nombre_3-6)"
            />
            <path
              d="M1374 1557c8.1 2.68 12.28-9.9 4.16-12.59s-12.27 9.91-4.16 12.59z"
              fill="url(#prefix__Degradado_sin_nombre_3-7)"
            />
            <path
              d="M1553.28 1533.43c8.1 2.68 12.28-9.91 4.17-12.59s-12.28 9.9-4.17 12.59z"
              fill="url(#prefix__Degradado_sin_nombre_11-4)"
            />
            <path
              d="M546.5 1249.93c8.1 2.68 12.28-9.91 4.17-12.59s-12.28 9.9-4.17 12.59z"
              fill="url(#prefix__Degradado_sin_nombre_3-8)"
            />
            <path
              d="M402.88 1505c8.1 2.68 12.28-9.9 4.17-12.58s-12.28 9.9-4.17 12.58z"
              fill="url(#prefix__Degradado_sin_nombre_9-7)"
            />
            <path
              d="M1591.57 1474l3.36 1.11c8.1 2.68 12.27-9.9 4.16-12.58l-3.36-1.11c-8.09-2.68-12.27 9.9-4.16 12.58z"
              fill="url(#prefix__Degradado_sin_nombre_9-8)"
            />
            <path
              d="M1518.38 1221c4.08 12.73 5.33 26.62 12.33 38 3.43 5.58 8.65 10.59 15.14 11.48 9.56 1.32 18.07-6.73 22.08-15.51s5.17-18.65 9.24-27.4 12.69-16.73 22.22-15.25c8.55 1.32 14.11 9.38 19 16.55.55.8 1.12 1.62 1.71 2.43 6.39 8.75 20.18 4.88 21.25-5.91a155.2 155.2 0 00-16.29-85.92c-5.75-11.12-13.72-22.21-25.6-26.15-22.36-7.4-44.92 13.65-68.44 12.32-9-.51-17.54-4.33-26.28-6.74s-18.72-3.24-26.32 1.7c-8.79 5.72-11.67 17.74-9.6 28 3 15.11 11.51 23 22.53 32.2a87.76 87.76 0 0127.03 40.2z"
              fill="url(#prefix__Degradado_sin_nombre_25)"
            />
            <path
              d="M1373.61 1360.77c-3.45 1.83-7 3.64-9.63 6.55s-4 7.29-2.32 10.81c1.94 4.07 6.89 5.46 11.17 6.86 12.29 4 24.14 12.6 27.44 25.1 2.23 8.46.27 17.44-2.4 25.78s-6.06 16.57-6.75 25.29 1.89 18.33 9 23.43c4.9 3.52 11.34 4.42 17.31 3.51s11.56-3.5 16.83-6.44c8-4.44 16-10.39 18.7-19.1 2.11-6.69.79-13.92-.82-20.74-2.44-10.32-5.52-20.51-6.87-31s-.85-21.55 3.62-31.16c6.87-14.76 22-24.13 30-38.33 4.23-7.53 6.1-17.38 1.34-24.6-3.87-5.89-11.19-8.58-18.21-9.25-8-.77-18.33.33-25.21 4.85-6.54 4.31-12 11.94-18 17.23a234.07 234.07 0 01-45.2 31.21z"
              fill="url(#prefix__Degradado_sin_nombre_27)"
            />
            <path
              className="prefix__cls-104"
              d="M1529.12 1425l-.54-.24a1.11 1.11 0 00-.89 2l.55.24a1.11 1.11 0 00.88-2zM1530.9 1428.4l-.55-.24a1.11 1.11 0 00-.88 2l.54.24a1.11 1.11 0 00.89-2zM1534 1427.07a1.11 1.11 0 00-2-.89 1.11 1.11 0 002 .89zM1528.7 1420.25l-.24.54a1.11 1.11 0 002 .89l.24-.55a1.11 1.11 0 00-2-.88zM1522.22 1425.84l.24-.54a1.11 1.11 0 00-2-.89l-.24.55a1.11 1.11 0 002 .88zM1532.44 1420.57l-.48 1.09a1.11 1.11 0 002 .89l.48-1.09a1.11 1.11 0 00-2-.89zM1535.29 1434.67l.54.24a1.11 1.11 0 00.89-2l-.54-.24a1.11 1.11 0 00-.89 2zM1530 1436.32a1.11 1.11 0 00-2-.88 1.11 1.11 0 002 .88zM1539.2 1429.13l-.3-.79a1.11 1.11 0 00-2.07.82l.3.78a1.11 1.11 0 002.07-.81zM1535 1438.41l.54.24a1.11 1.11 0 00.89-2l-.54-.24a1.11 1.11 0 00-.89 2zM1541 1438l-.54-.23a1.11 1.11 0 00-.89 2l.55.23a1.11 1.11 0 00.88-2zM1543.92 1434a1.11 1.11 0 00-2-.88 1.11 1.11 0 002 .88zM1536.24 1444.22a1.11 1.11 0 00-2-.88 1.11 1.11 0 002 .88zM1528.59 1430.55a1.11 1.11 0 00-2-.89 1.11 1.11 0 002 .89zM1522.79 1436.44a1.11 1.11 0 00-2-.88 1.11 1.11 0 002 .88zM1531.93 1448.17a1.11 1.11 0 00-2-.88 1.11 1.11 0 002 .88zM1542.46 1446.28a1.11 1.11 0 00-2-.89 1.11 1.11 0 002 .89zM1541.55 1424.53a1.11 1.11 0 00-2-.88 1.11 1.11 0 002 .88zM1526.48 1416.05a1.11 1.11 0 00-2-.89 1.11 1.11 0 002 .89zM1548.43 1419.11a1.11 1.11 0 00-2-.88 1.11 1.11 0 002 .88zM1532 1415.22l.24-.55a1.11 1.11 0 00-2-.88l-.24.54a1.11 1.11 0 002 .89z"
            />
            <path
              d="M1569.68 1409.38a4.85 4.85 0 011.41 6.82 5 5 0 01-6.93.89 5.07 5.07 0 01-.54-7s2.19-2.8 6.06-.71z"
              fill="url(#prefix__Degradado_sin_nombre_21)"
            />
            <path
              className="prefix__cls-104"
              d="M1486.55 1380.82a30.08 30.08 0 0018.35 8.46 30.76 30.76 0 0021.66-7c8.4-7 13.12-19.23 12-31.2a40.17 40.17 0 00-4.51-15.45l.74-.48a40.84 40.84 0 014.58 15.69 40.31 40.31 0 01-2.2 17 34.44 34.44 0 01-10.21 15 31.83 31.83 0 01-22.35 7.22 30.74 30.74 0 01-18.74-8.63z"
            />
            <path
              d="M342 119.33c-4.48 10.94-3.11 22.73 9 29 7.35 3.81 16.52 2.75 23.87-1.07 9.92-5.17 19.53-15.28 22.55-26.21 2.64-9.54-3-18.24-11.36-23.16-16.78-9.89-37.2 4.78-44.06 21.44z"
              fill="url(#prefix__Degradado_sin_nombre_5-4)"
            />
            <path
              d="M196.71.05a61.16 61.16 0 0134.06 8.58c25 14.71 36.94 39.3 30.5 62.63-7.4 26.78-31.38 54.68-59.68 69.41a80.37 80.37 0 01-33.52 9.17 60 60 0 01-30.49-6.38c-29.07-15.09-38.16-44.28-24.32-78.08C128 29.49 163.85 1.41 196.71.05zM167.9 145.43a75.84 75.84 0 0031.66-8.67c27.28-14.21 50.37-41 57.47-66.67 7.15-25.92-10-46.78-28.49-57.66a56.77 56.77 0 00-31.64-8c-31.26 1.29-65.45 28.19-79.55 62.59-12.88 31.47-4.56 58.57 22.27 72.49a55.59 55.59 0 0028.28 5.92z"
              fill="url(#prefix__Degradado_sin_nombre_25-2)"
            />
            <path
              d="M1495 1088.61c6.55 8.4 16.39 12.61 26.7 6 6.27-4 9.56-11.7 9.85-19.15.4-10.08-3.2-22.13-10.4-29.38-6.28-6.34-15.62-5.81-23.2-1.49-15.23 8.68-12.9 31.22-2.95 44.02z"
              fill="#fadf9e"
              opacity={0.3}
            />
            <path
              d="M1479.39 1055.41a21.14 21.14 0 018.46-8.79c8.74-5 18.21-4.35 24.12 1.62 6.8 6.84 11.06 18.88 10.62 30a27.85 27.85 0 01-3 11.68 20.79 20.79 0 01-7.18 8.08c-9.56 6.16-19.92 3.86-27.72-6.14-8.29-10.67-10.57-26.32-5.3-36.45zm38.82 33.75a26.24 26.24 0 002.85-11c.43-10.67-3.66-22.25-10.17-28.82s-15.82-5-22.29-1.36a19.72 19.72 0 00-7.85 8.16c-5 9.64-2.8 24.58 5.13 34.76 7.26 9.31 16.86 11.48 25.68 5.79a19.31 19.31 0 006.65-7.53z"
              fill="url(#prefix__Degradado_sin_nombre_27-2)"
            />
            <path
              d="M230.1 1675.37a353.06 353.06 0 01-3.95 46.71c-1.21 7.89-2.72 15.86-6.26 23s-9.38 13.5-16.95 16.05c-9.6 3.22-20 0-30.13-.56a56.12 56.12 0 00-48.93 25c-11.88-4.95-19.25-18.41-17.74-31.2 1.19-10.18 7.46-19 13.52-27.23 9.73-13.28 19.77-26.87 33.6-35.81 21.95-14.19 50.86-18.96 76.84-15.96z"
              fill="url(#prefix__Degradado_sin_nombre_29-3)"
            />
            <path
              d="M280 1538.82c15.63-6.82 30.19-10 41-8.81 13.87 1.46 26.17 9 34.63 21.13 9.62 13.81 13.09 32.19 9.07 48-4.73 18.57-14.86 35.65-23.15 48.45-23.63 36.49-64.08 85.34-122.53 110.85-2.91 1.27-5.87 2.49-8.81 3.62-20.16 7.73-37.14 10.17-51.92 7.46-15.81-2.91-33.43-14.07-37.16-32.47-3.57-17.61 6.94-38.08 28.13-54.76 5.44-4.28 11.28-8.29 16.94-12.17 11.89-8.16 24.18-16.59 33.12-27.91 8.48-10.75 13.64-23.76 18.63-36.33 5.21-13.14 10.6-26.72 19.81-37.89 8.51-10.34 24.7-21.5 42.24-29.17zm-61.74 217.84c58-25.32 98.2-73.87 121.69-110.13 8.21-12.69 18.24-29.59 22.9-47.9 3.89-15.25.52-33-8.78-46.4-8.15-11.7-20-18.92-33.27-20.32-10.49-1.11-24.71 2-40 8.66-17.32 7.55-33.23 18.51-41.54 28.6-9 10.94-14.35 24.38-19.51 37.38-5 12.71-10.25 25.84-18.91 36.81-9.11 11.54-21.53 20.06-33.53 28.3-5.63 3.86-11.45 7.85-16.84 12.1-20.61 16.22-30.87 36-27.44 52.88 3.55 17.53 20.45 28.18 35.63 31 14.43 2.65 31.08.24 50.89-7.37 2.92-1.14 5.85-2.35 8.74-3.61z"
              fill="url(#prefix__Degradado_sin_nombre_25-3)"
            />
            <path
              d="M166 522.13c7.53-24.54 18.24-44.24 30.15-55.48 15.27-14.42 35.91-21.11 58.1-18.86 25.22 2.57 49.64 16.62 63.72 36.69 16.58 23.62 26.16 51.95 32.66 74 18.51 62.79 34.67 156.91 6.49 248.73-1.4 4.57-2.92 9.15-4.51 13.62-10.94 30.61-24.8 52.41-42.4 66.64-18.83 15.22-48.87 24.41-73.55 10.63-23.63-13.2-36.71-45.3-35-85.86.44-10.42 1.59-21 2.7-31.29 2.34-21.59 4.76-43.92.59-65.23-4-20.24-13.76-38.89-23.24-56.92-9.89-18.84-20.13-38.33-23.87-59.8-3.47-19.83-.35-49.28 8.16-76.87zm188.34 284.22c28-91.14 11.9-184.67-6.51-247.09-6.43-21.82-15.91-49.86-32.25-73.14-13.62-19.4-37.24-33-61.66-35.48-21.35-2.17-41.18 4.26-55.84 18.09-11.55 10.9-22 30.16-29.37 54.24-8.35 27.19-11.43 56.13-8.06 75.52 3.66 21 13.79 40.31 23.59 59 9.57 18.22 19.47 37.06 23.51 57.71 4.26 21.74 1.81 44.28-.55 66.08-1.11 10.22-2.26 20.78-2.69 31.11-1.68 39.46 10.85 70.57 33.51 83.23 23.51 13.13 52.27 4.26 70.34-10.35 17.18-13.89 30.75-35.27 41.5-65.38 1.58-4.47 3.14-9.01 4.48-13.54z"
              fill="url(#prefix__Degradado_sin_nombre_5-5)"
            />
            <path
              d="M1419.86 1296.79c10.32-33.62 25-60.62 41.3-76 20.93-19.75 49.2-28.92 79.6-25.83 34.55 3.51 68 22.77 87.29 50.25 22.71 32.37 35.84 71.17 44.74 101.36 25.36 86 47.49 215 8.89 340.75-1.92 6.26-4 12.53-6.18 18.65-15 41.94-34 71.8-58.08 91.3-25.79 20.85-67 33.43-100.76 14.55-32.37-18.08-50.28-62-47.92-117.61.6-14.28 2.18-28.82 3.7-42.87 3.21-29.58 6.53-60.16.81-89.35-5.43-27.73-18.85-53.28-31.83-78-13.56-25.81-27.59-52.5-32.71-81.91-4.71-27.15-.45-67.49 11.15-105.29zm258.06 389.35c38.31-124.85 16.3-253-8.91-338.48-8.81-29.9-21.8-68.31-44.19-100.21-18.65-26.58-51-45.2-84.46-48.6-29.25-3-56.41 5.83-76.5 24.78-15.82 14.93-30.11 41.32-40.24 74.3-11.43 37.25-15.66 76.9-11 103.46 5 28.81 18.89 55.22 32.31 80.76 13.12 25 26.67 50.77 32.21 79.06 5.83 29.78 2.48 60.66-.76 90.53-1.51 14-3.08 28.46-3.68 42.62-2.3 54.05 14.87 96.66 45.91 114 32.21 18 71.6 5.84 96.36-14.17 23.53-19 42.12-48.33 56.85-89.56 2.18-6.06 4.18-12.29 6.1-18.49z"
              fill="url(#prefix__Degradado_sin_nombre_5-6)"
            />
            <path
              d="M2042.85 1255.18c-20.78 76.35-51.82 138.12-87.4 173.9-45.64 45.92-108.55 68.58-177.15 63.82-77.94-5.41-154.54-46.31-199.9-106.73-53.4-71.15-85.72-157.46-107.9-224.71-63.23-191.61-122.28-480-44.52-765.63a982.43 982.43 0 0112.57-42.39c30.66-95.39 71.23-163.92 124-209.49 56.49-48.74 148.13-80 225.53-40 74.09 38.33 117.54 135.91 116.23 261-.33 32.15-2.83 65-5.25 96.67-5.08 66.74-10.34 135.75 4.61 201 14.2 62 46.23 118.46 77.2 173.09 32.35 57.06 65.81 116.07 79.44 181.85 12.61 60.74 5.9 151.77-17.46 237.62zm-608.34-857c-77.17 283.52-18.46 570.08 44.38 760.54 22 66.6 54 152 106.59 222.16 43.86 58.43 118 98 193.42 103.22 66 4.57 126.46-17.17 170.26-61.23 34.51-34.72 64.75-95.08 85.14-170 23-84.59 29.68-174 17.37-233.45-13.35-64.42-46.46-122.82-78.47-179.29-31.29-55.18-63.64-112.24-78.13-175.48-15.25-66.53-9.94-136.22-4.81-203.62 2.41-31.58 4.9-64.24 5.23-96.1 1.27-121.72-40.39-216.32-111.45-253.08-73.72-38.13-161.44-8-215.68 38.81-51.54 44.48-91.25 111.7-121.39 205.49a973.263 973.263 0 00-12.46 42.01z"
              fill="url(#prefix__Degradado_sin_nombre_656-76)"
            />
            <path
              d="M148.59 1823.15a31.43 31.43 0 0028 1.56 28.89 28.89 0 0016.67-22.31 36.63 36.63 0 00-1.21-13.61c-2.37-9.06-7.8-18.11-16.63-21.23-7.88-2.79-16.64-.25-24.19 3.33-10.36 4.92-20.69 13.46-21.17 24.91-.33 8 4.32 15.37 9.32 21.67s12 12.66 19.82 10.94"
              fill="url(#prefix__Degradado_sin_nombre_3-9)"
            />
            <path
              d="M1423.28 1080.51c6.55 8.4 16.39 12.61 26.7 6 6.28-4 9.56-11.7 9.85-19.15.41-10.08-3.2-22.13-10.39-29.38-6.29-6.34-15.63-5.82-23.21-1.49-15.23 8.67-12.92 31.22-2.95 44.02z"
              fill="url(#prefix__Degradado_sin_nombre_3-10)"
            />
          </g>
        </g>
      </svg>
      </div>
      <div className="wrapper" >
        <div className="page-title" >… and the task may look daunting</div>
        <div className="content" >
          <h2 className="content-title" >Complexity is everywhere:</h2>
          <p>The evolution of societies in recent decades has given more autonomy to people and at the same time globalization and technologies have increased the interconnections and interdependencies. As a result, not only the world at large but also every place is in a more complex situation: it can be affected, almost instantly, by events happening thousands of kilometers away. Our ways of thinking, tributary to mechanistic perspectives, are not prepared for this explosion of complexity. But this could also provide new opportunities, if we start thinking differently.</p>
          <h2 className="content-title" >Uncertainty is real: </h2>
          <p>With complexity comes uncertainty. For all our recognition of complexity we would still like to have a well determined plan to achieve our goals in a straightforward, purposeful manner. But all living systems (individuals, ecosystems, societies) are complex, and they do not obey to plans. In a radically unpredictable world in which uncertainty cannot be avoided, rather than betting on simple problem-solution schemes, we have to learn how to create the conditions for living systems to learn new patterns of behavior. In that sense, the absence of predictability is actually good news, it opens the space of possibilities.</p>
          <h2 className="content-title" >Trust in institutions is challenged:</h2>
          <p>In many countries across Europe the trust in existing institutions has been declining since long, and the confusion generated by the Covid pandemic contributed to increase a feeling of uncertainty. The massive use of social media and the fabrication of "fake news" do not help, but they are mostly symptoms of something deeper. Social fragmentation and political polarization do happen at times of high societal stress, in which the perception emerges that next generations will have harder times, and progress and modernity are no longer synonyms.</p>
          <h2 className="content-title" >We cannot isolate contexts: </h2>
          <p>As shown by the Covid pandemic, a sanitary disruption has important consequences in the economy, education, social life and politics. More generally, health, nature, economy, politics, science, technology, culture, society are all intertwined, so it is difficult to discern the appropriate boundary for a specific policy analysis. Additionally, when analyzed from the perspective of multiple contexts, the same policy can deliver different and even contradictory results. Conversely, learning from different contexts can help to initiate new ways of thinking and even defining policy goals.</p>
          <h2 className="content-title" >Multiple trade-offs are entangled: </h2>
          <p>Priority setting could reveal a nightmare of entangled trade-offs: the inclusion of all kinds of stakeholders and perspectives creates a multiplicity of goals and makes frequently appear diverging interests. Economic growth used to be the priority "par excellence", but now (and for good reasons) it is only 1 of the 17 Sustainable Development Goals (SDGs). The SDGs Monitoring Framework includes 232 indicators, and the underlying processes are not independent from each other, so the impact of a specific policy, deemed to be positive on one or more goals, might be negative for others.  </p>
          <h2 className="content-title" >Do we have a compass?: </h2>
          <p>Formulating an appropriate strategy is a challenge in itself since our purpose is to transform R&I ecosystems so that they align with societal values, expectations and needs. Both elements of this alignment exercise are complex systems and moving targets. Policies to be implemented are also multiple and intersecting: RIS3, RRI and the Agenda 2030 can lead to contradictory injuctions, not to talk about other ongoing policies. Monitoring and evaluation, if done in a conventional way, is increasingly difficult since it has to address many dimensions. How do we ensure that we are going in the right direction?</p>
        </div>
        <div className="back-button" onClick={goBack} > 
          <img src={process.env.PUBLIC_URL + "/assets/back-arrow.svg"} alt="back" /> BACK
        </div>
      </div>
    </div>
  )
}

export default ComplexityIntroPage
